import React, { useEffect } from 'react'
import { ERC20_PoolGroup, PRED_PoolGroup, SV_PoolGroup } from '../../A_Components/PoolGroup'
import { Empty_PoolPage } from '../../A_Components/PoolGroup'
import { Loading_PoolPage } from '../../A_Components/PoolGroup'

import { useFS_List_ERC20 } from '../useHook/FS_ERC20'
import { useSB_List_ERC20 } from '../useHook/SB_ERC20'
import { useDA_List_ERC20 } from '../useHook/DA_ERC20'
import { useLT_List_ERC20 } from '../useHook/LT_ERC20'
import { useLL_List_ERC20 } from '../useHook/LL_ERC20'
import { usePRED_List_ERC20 } from '../useHook/PRED_ERC20'
import { useSV_FS_ERC20 } from '../useHook/SV_FS_ERC20'
import { useSV_SB_ERC20 } from '../useHook/SV_SB_ERC20'


export function FS_ERC20({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useFS_List_ERC20(queryListArr)

    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC20_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='FS' />
    )
}

export function LL_ERC20({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useLL_List_ERC20(queryListArr)

    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC20_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='LL' />
    )
}


export function SB_ERC20({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useSB_List_ERC20(queryListArr)

    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC20_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='SB' />
    )
}


export function DA_ERC20({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useDA_List_ERC20(queryListArr)

    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC20_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='DA' />
    )
}


export function LT_ERC20({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useLT_List_ERC20(queryListArr)

    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC20_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='LT' />
    )
}

export function PRED_ERC20({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = usePRED_List_ERC20(queryListArr)

    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <PRED_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='ERC20' />
    )
}

export function SV_FS_ERC20({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useSV_FS_ERC20(queryListArr)

    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <SV_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='FS' />
    )
}

export function SV_SB_ERC20({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useSV_SB_ERC20(queryListArr)

    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <SV_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='SB' />
    )
}

