import styled from 'styled-components'
import React, {useState} from "react";

const Helper = styled.div`
  width: 100%;
  cursor: pointer;
  margin:  12px 0;
  display: flex;
  position: absolute;
  z-index: 2;
  
  
  img{
    margin-left: 8px;
  }
`

Helper.Content = styled.div`
  position: absolute;
  bottom: 100%;
  text-align: left;
  z-index: 9;
  background: #FFFFFF;
border: 1px solid #000000;
box-sizing: border-box;
box-shadow: 0px 21px 21px -16px rgba(0, 0, 0, 0.1);
padding: 10px;
color: #000;
font-family: Helvetica Neue;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
`

export const HelperLayout = ({children, content}) => {
    const [show, setShow] = useState(false)
    return (
        <Helper
            onMouseLeave={()=>{
                setShow(false)
            }}
            onMouseEnter={() => {
                setShow(true)
        }}>
            {show && <Helper.Content>{content}</Helper.Content>}
            {children}
        </Helper>
    )
}

