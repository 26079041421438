import React, { useContext } from 'react'
import styled from 'styled-components'
import { myContext } from '../../../reducer'
import Grow from '@material-ui/core/Grow';


const getColorByStatus = (status) => {
    switch (status) {
        case 'Live':
            return '#2DAB50'
        case 'Filled':
            return '#728AE0'
        case 'Closed':
            return '#8f8f8f8f'
        default:
            return '#000000'
    }
}

const ERC_NFT_Card = styled.div`
        width: 240px;
        height: 440px;
        margin-top: 20px;
        box-sizing: border-box;
        background-color: #fff;
        font-family: 'IBM Plex Mono';
        position: relative;
        @media (max-width: 767px) {
            margin: 20px 20px 20px !important;
            width: 100vw;
            height: 365px;
        }
        
        .wrapper{
            width: 100%;
            height: 100%;
            padding: 15px 20px;
            .top{
                display: flex;
                justify-content: space-between;
                p{
                    font-size: 12px;
                    font-weight: bold;
                    color: #000;
                    opacity: .5;
                }
            }
            .cover{
                width: 100%;
                height: 200px;
                margin-top: 16px;
                img{
                    width: 200px;
                    height: 200px;
                }
            }

            .title{
                height: 68px;
                line-height: 68px;
                border-bottom: 3px solid #000;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                font-weight: bold;
                font-size: 22px;
                font-family: 'Optima'
            }
        }
        &:hover .join_btn{
           display: block;
        }
        

        .join_btn{
            display: none;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 45px;
            line-height: 45px;
            text-align: center;
            border-top: 1px solid rgba(0,0,0,.2);
            background-color: #fff;
            z-index: 1;
            font-family: 'Helvetica Neue';
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
            @media (max-width: 767px) {
                display: block;
            }
            &:hover{
                color: #fff;
                background-color: #000;
            }
        }


        
    `

const checkImgExists = (imgurl) => {
    let cookie_yes_icon = JSON.parse(window.localStorage.getItem('cookie_yes_icon')) || []
    let cookie_no_icon = JSON.parse(window.localStorage.getItem('cookie_no_icon')) || []
    if (cookie_yes_icon.includes(imgurl)) {
        return true;
    } else if (cookie_no_icon.includes(imgurl)) {
        return false
    } else {
        var ImgObj = new Image(); //判断图片是否存在
        ImgObj.src = imgurl;
        //存在图片
        ImgObj.onload = () => {
            cookie_yes_icon.push(imgurl)
            window.localStorage.setItem('cookie_yes_icon', JSON.stringify(cookie_yes_icon))
            return true;
        }

        ImgObj.onerror = () => {
            cookie_no_icon.push(imgurl)
            window.localStorage.setItem('cookie_no_icon', JSON.stringify(cookie_no_icon))
            return false;
        }
    }
}


export const NFT_CardItem = ({ data, detailsRoute, setIsShowTip, setTipInfo, poolType }) => {
    return (
        <Grow in={true} timeout={500}>
            <ERC_NFT_Card>
                <div className='wrapper'>
                    <div className='top'>
                        <Status status={data.status} />
                        <p>{`# ${data.poolID}`}</p>
                    </div>
                    <div className='title'>
                        {data.poolName}
                    </div>
                    <div className='cover'>
                        <img src={data.fromToken.cover} alt="" />
                    </div>
                    <InfoBox
                        // account 
                        address={data.fromToken.adress}
                        Participants={data.Participant}
                        Price={data.price}
                        toToken={data.toToken}
                        // LT NFT
                        ticket={data.ticket}
                    />
                    <Progress status={data.status} plan={data.progress} />
                </div>
                <div className='join_btn' onClick={() => {
                    setIsShowTip(true)
                    setTipInfo({
                        index: data.poolID,
                        fromSymbol: data.fromToken.symbol,
                        adress: data.fromToken.adress,
                        poolType: poolType,
                        detailsRoute: `${detailsRoute}${data.poolID}`,
                    })
                }}>
                    {data.status === 'Live' ? 'Join' : 'Show Result'}
                </div>
            </ERC_NFT_Card>
        </Grow>
    )
}

const ProgressStyled = styled.div`
        position: relative;
        width: 100%;
        height: 4px;
        margin-top: 20px;
        overflow: hidden;
        

        &>div{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: ${({ color }) => { return color }};
            opacity: .1;

            &:nth-child(2){
                width: ${({ plan }) => { return plan || 0 }};
                opacity: 1;
            }
        }

`

const Progress = ({ status, plan }) => {
    // console.log('useFS_List_ERC20', status, plan)
    return <ProgressStyled color={getColorByStatus(status)} plan={plan}>
        <div></div>
        <div></div>
    </ProgressStyled>
}

const InfoBoxStyled = styled.div`
        margin-top: 15px;
        &>div{
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            span{
                font-size: 12px;
                color: #000;
                opacity: .5; 
            }
            p{
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #1F191B;
                img{
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                }
            }
        }
    `

const InfoBox = ({ address, Price, Participants, toToken, ticket }) => {

    const simpAdress = (adress) => {
        const start = String(address).substr(0, 6)
        const end = String(address).substr(-4)
        return `${start}...${end}`
    }

    return <InfoBoxStyled>
        {address && <div><span>Address</span><p>{simpAdress(address)}</p></div>}
        {Price && <div><span>Price</span><p>{Price} {toToken.symbol}</p></div>}
        {ticket && <div><span>Ticket Price</span><p>{ticket} {toToken.symbol}</p></div>}
        {Participants && <div><span>Participants</span><p>{Participants}</p></div>}
    </InfoBoxStyled>
}

const StatusStyled = styled.div`
    display: flex;
    align-items: center;
        div{
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: ${({ color }) => { return color || '#f0f0f0' }}
        }
        span{
            font-size: 12px;
            margin-left: 5px;
            color: ${({ color }) => { return color || '#f0f0f0' }}
        }
    `

const Status = ({ status }) => {

    return <StatusStyled color={getColorByStatus(status)}>
        <div></div>
        <span>{status}</span>
    </StatusStyled>
}

