import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useActiveWeb3React } from "../../web3";

import icon_search from '../../static/image/icon-search.svg'
import icon_down from '../../static/image/icon-arrow-down.svg'
import icon_up from '../../static/image/icon-arrow-up.svg'
import icon_grid_active from '../../static/image/icon-grid-active.svg'
import icon_line_active from '../../static/image/icon-line-active.svg'

import CMC from './assets/CMC.svg'
import CG from './assets/CG.svg'
import ALL from './assets/ALL.svg'
import CMC_select from './assets/CMC_select.svg'
import CG_select from './assets/CG_select.svg'
import ALL_select from './assets/ALL_select.svg'

import icon_check_no from './assets/icon_check_no.svg'
import icon_check_yes from './assets/icon_check_yes.svg'

import { Select, SelectFrame, Select_img, Select_Tabbar } from "../../components/common/Select";

import { requestSearch } from './useHook/Request_API'

const getColorByStatus = (status) => {
  switch (status) {
    case 'Live&Filled':
      return '#2DAB50'
    case 'Closed':
      return 'rgba(0,0,0,.5)'
    default:
      return '#000'
  }
}

const FilterFrame = styled.div`
  width: 1280px;
  height: 84px;
  margin: 0 auto;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  font-family: Optima;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  display: flex;
  align-items: center;
  .search_title{
      cursor: pointer;
      &:hover{
          opacity: .7;
      }
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

const Mode = styled.img`
  margin-right: 20px;
  cursor: pointer;
  opacity: .2;

  &:hover{
      opacity: 1;
  }

  &.active{
    opacity: 1;
  }

  &.display{
    opacity: .1;
  }
`

export default function HeaderFilter({ setIsLoading, createOptions, setRequestData, setIsEmpty, setQueryListArr, setPagenum }) {
  const { chainId } = useActiveWeb3React()
  const defaultFilter = JSON.parse(window.localStorage.getItem('A_FilterOption')) || {}
  const [poolType, setPoolType] = useState(defaultFilter.poolType || createOptions[0] || { key: 0, value: 'Fixed Swap Auction', route: 'fixed-swap', method: "query_fixed_pools" })
  const [antiFake, setAntiFake] = useState(defaultFilter.antiFake || { key: 0, value: 'ALL', icon: ALL, icon_select: ALL_select })
  const [filterStatus, setFilterStatus] = useState(defaultFilter.filterStatus || { key: 0, value: 'All' })
  const [displayMode, setDisplayMode] = useState(defaultFilter.displayMode === false ? false : true)

  const history = useHistory()

  useEffect(() => {
    if (!poolType || createOptions.length === 0) return
    const keyArr = createOptions.map(item => {
      return item.key
    })
    if (keyArr.includes(poolType.key)) return

    setPoolType(createOptions[0])
  }, [createOptions])

  useEffect(() => {
    if (!poolType) return
    history.push(`/application/${poolType.route}`)
  }, [poolType])

  useEffect(() => {
    if (!poolType || !chainId) return
    const FilterObj = {
      poolType, antiFake, filterStatus, displayMode
    }
    window.localStorage.setItem('A_FilterOption', JSON.stringify(FilterObj))
    request_init()
  }, [poolType, antiFake, filterStatus, displayMode, chainId])

  const request_init = (options = {}) => {
    setRequestData([])
    setQueryListArr([])
    setPagenum(1)
    const A_FilterOption = JSON.parse(window.localStorage.getItem('A_FilterOption')) || {
      key: 0,
      value: 'Fixed Swap Auction',
      route: 'fixed-swap',
      method: "query_fixed_pools"
    }
    // 如果接口方法未实现，则是用传统方法获取poolID

    if (!A_FilterOption.poolType.method) {
      // setIsLoading(true)
      // console.log('A_console', A_FilterOption)
      return
    }


    const params = {
      method: A_FilterOption.poolType.method,
      filter: A_FilterOption.antiFake.key,
      status: A_FilterOption.filterStatus.value,
      ...options
    }
    setIsLoading(true)
    requestSearch(chainId, params).then(res => {
      const pool_arr = res.data.data.pool_ids || []

      setIsLoading(false)
      if (pool_arr.length === 0) {
        setIsEmpty(true)
      } else {
        setIsEmpty(false)
        setRequestData(pool_arr.reverse())
      }
    })
  }


  return (
    <FilterFrame>
      <Select_Tabbar
        width={'280px'}
        extra={'Pool Type:'}
        onSelect={(option) => {
          return setPoolType(option)
        }}
        defaultValue={poolType}
        options={createOptions} />
      <SelectFrame.Divider />
      <Select_img
        // style={{ overflow: "hidden", width: '220px', whiteSpace: "nowrap", textOverflow: 'ellipsis' }}
        extra={'Token Filter:'}
        width={'300px'}
        onSelect={(option) => {
          return setAntiFake(option)
        }}
        defaultValue={antiFake}
        options={[
          {
            key: 1,
            value: 'CMC',
            icon: CMC,
            icon_select: CMC_select
          },
          {
            key: 2,
            value: 'CG',
            icon: CG,
            icon_select: CG_select
          },
          {
            key: 0,
            value: 'ALL',
            icon: ALL,
            icon_select: ALL_select
          }
        ]} />
      <SelectFrame.Divider />
      <Select_Tabbar
        width={'250px'}
        onSelect={item => {
          setFilterStatus(item)
        }}
        selectColor={getColorByStatus(filterStatus.value)}
        extra={'Status: '}
        defaultValue={filterStatus}
        options={[
          { key: 0, value: 'All' },
          { key: 1, value: 'Live&Filled' },
          { key: 2, value: 'Closed' }
        ]} />
      <SelectFrame.Divider />

      <SearchPull request_init={request_init} />

      <SelectFrame.Divider />
      <Mode onClick={() => {
        setDisplayMode(true)
      }} src={icon_grid_active}
        className={displayMode ? 'active' : ''}
      />
      <Mode onClick={() => {
        setDisplayMode(false)
      }} src={icon_line_active}
        className={displayMode ? '' : 'active'}
      />

    </FilterFrame>
  )
}

const SearchPullStyled = styled.div`
    position: relative;

    .pullBar{
      display: flex;
      justify-content: space-between;
      width: 240px;
      cursor: pointer;
      user-select: none;
      div{
          display: flex;
          align-items: center;
          &.left{

            img{
              width: 16px;
              height: 16px;
              margin-right: 16px;
            }

            span{
              font-family: 'Helvetica Neue';
              font-size: 14px;
              font-weight: bold;
            }
          }

          &.right{
            width: 12px;
          }
      }
    }
  `

const PullBoxStyled = styled.div`
  position: absolute;
  top: 53px;
  left: -20px;
  width: 350px;
  min-height: 254px;
  padding: 20px 24px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #EAEAEA;
  box-shadow: 4px 14px 20px -8px rgba(0, 0, 0, 0.17);
  z-index: 10;
  .btn_box{
    display: flex;
    justify-content: space-between;
    margin-top:16px;
    button{
      width: 144px;
      height: 48px;
      box-sizing: border-box;
      border: 1px solid rgba(0,0,0,.2);
      font-family: 'Helvetica Neue';
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;

      &.black{
        background-color: #000;
        color: #fff;
      }

      &.gray{
        background-color: rgba(0,0,0,.14);
        color: #fff;
      }
    }
  }
`

const SearchPull = ({ request_init }) => {
  const [isPull, setIsPull] = useState(false)
  const [isClearAll, setIsClearAll] = useState(false)

  const renderPullBox = () => {

    const handelChickSearch = async () => {
      let A_SearchParams = JSON.parse(window.localStorage.getItem('A_SearchParams')) || {}
      let params = {}
      for (const key in A_SearchParams) {
        if (A_SearchParams.hasOwnProperty(key)) {
          const element = A_SearchParams[key];
          if (element) {
            if (key === 'pool_id') {
              params[key] = parseInt(element)
            } else {
              params[key] = element
            }
          }
        }
      }

      request_init(params)
    }

    return <PullBoxStyled>
      <SearchItem itemName='Pool ID' placeholder='Enter Pool ID ' requestKey='pool_id' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
      <SearchItem itemName='Pool Name' placeholder='Enter Pool Name ' requestKey='pool_name' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
      <SearchItem itemName='Token Contract Address ' placeholder='Enter Token Contract Address' requestKey='token_address' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
      <SearchItem itemName='Token Symbol' placeholder='Enter Token Symbol ' requestKey='token_symbol' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
      <SearchItem itemName='Auction creator wallet address' placeholder='Enter Token Contract Address' requestKey='creator_address' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
      <div className='btn_box'>
        <button className='white' onClick={() => {
          setIsClearAll(true)
          setTimeout(() => {
            setIsClearAll(false)
          }, 500)
        }}>Сlear all</button>
        <button className='black' onClick={handelChickSearch}>
          Show Results
        </button>
      </div>
    </PullBoxStyled>
  }

  return (
    <SearchPullStyled>
      <div className='pullBar' onClick={() => {
        setIsPull(!isPull)
      }}>
        <div className='left'>
          <img src={icon_search} alt="" />
          <span>Search by</span>
        </div>
        <div className='right'>
          {isPull ?
            <img src={icon_up} alt="" />
            :
            <img src={icon_down} alt="" />
          }
        </div>
      </div>

      {isPull && renderPullBox()}
    </SearchPullStyled>
  )
}

const SearchItemStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    label{
        display: block;
      width: 14px;
      height: 14px;
      cursor: pointer;
      margin-right: 8px;
      background: url(${icon_check_no}) no-repeat;
      background-position: 0 0;
      background-size: contain;

      &.checked{
        background: url(${icon_check_yes}) no-repeat;
        background-position: 0 0;
        background-size: contain;
      }
    }

    &>input{
        /* display: none; */

      }
    &>input:checked + label{
      background: url(${icon_check_yes}) no-repeat;
      background-position: 0 0;
      background-size: contain;
    }

    p{
      font-family: 'Helvetica Neue';
      font-size: 14px;
      font-weight: 500;
    }

    .implicit{
        /* display: none; */
        width: 100%;

      &.hidden{
        display:none;
      }

      input{
        border: none;
        border-bottom: 1px solid rgba(0,0,0,.3);
        width: 100%;
        line-height: 36px;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Helvetica Neue';
        margin-top: 5px;
      }
    }
`

const SearchItem = ({ itemName, requestKey, placeholder, isClearAll }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [inputVal, setInputVal] = useState('')
  useEffect(() => {
    if (!isClearAll) return
    setIsChecked(false)
    setInputVal('')
  }, [isClearAll])

  useEffect(() => {
    if (!isChecked) {
      let A_SearchParams = JSON.parse(window.localStorage.getItem('A_SearchParams')) || {}
      A_SearchParams[requestKey] = null
      window.localStorage.setItem('A_SearchParams', JSON.stringify(A_SearchParams))
    }
  }, [isChecked])

  return <SearchItemStyled>
    <label for={itemName} className={isChecked ? 'checked' : ''} onClick={() => { setIsChecked(!isChecked) }}></label>
    <p>{itemName}</p>
    <div className={isChecked ? 'implicit' : 'implicit hidden'}>
      <input type="text"
        placeholder={placeholder || ''}
        value={inputVal}
        onChange={(e) => {
          const val = String(e.target.value).trim()
          setInputVal(val)
        }}
        onBlur={(e) => {
          const val = String(e.target.value).trim()
          let A_SearchParams = JSON.parse(window.localStorage.getItem('A_SearchParams')) || {}
          A_SearchParams[requestKey] = val
          window.localStorage.setItem('A_SearchParams', JSON.stringify(A_SearchParams))
        }} />
    </div>
  </SearchItemStyled>
}
