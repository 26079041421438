import {getContract, useActivePlatform, useActiveWeb3React} from "../../web3";
import {useContext, useEffect, useState} from "react";
import {myContext} from "../../reducer";
import sealedBid from "../../web3/abi/bounceSealedBidNFT.json";
import {getFixSwapAddress, getSealedBidAddress, getSealedBidNFTAddress} from "../../web3/contractAddress";
import bounceERC721 from "../../web3/abi/bounceERC721.json";
import bounceERC1155 from "../../web3/abi/bounceERC1155.json";
import {HANDLE_SHOW_CONNECT_MODAL} from "../../const";
import {isEqualTo, isGreaterThan} from "../../utils/common";

const BigNumber = require('bignumber.js');


export const usePoolDetail = (id = 0) => {
    const {active, account, library, chainId} = useActiveWeb3React();
    const {state, dispatch} = useContext(myContext);

    const [joinStatus, setJoinStatus] = useState()
    const [name, setName] = useState(null)
    const [symbol, setSymbol] = useState(null)
    const [address, setAddress] = useState(null)
    const [limit, setLimit] = useState(null)
    const [isMine, setIsMine] = useState(false)
    const [password, setPassword] = useState()
    const [time, setTime] = useState()
    const [fromBidAmount, setFromBidAmount] = useState()
    const [toBidAmount, setToBidAmount] = useState()
    const [fromAmount, setFromAmount] = useState()
    const [claimButtonText, setClaimButtonText] = useState()
    const [biddenStatus, setBiddenStatus] = useState()
    const [claimStatus, setClaimStatus] = useState()
    const [myBid, setMyBid] = useState()
    const [floor, setFloor] = useState()
    const [bidden, setBidden] = useState()
    const [totalBid, setTotalBid] = useState()
    const [startPrice, setStartPrice] = useState()
    const [NFT, setNFT] = useState()
    const [joined, setJoined] = useState()
    const [myBidden, setMyBidden] = useState()
    const [tokenId, setTokenId] =  useState()
    const [winnerAmount, setWinnerAmount] =  useState()
    const [win, setWin] =  useState(false)

    const {Psymbol} = useActivePlatform()


    const [status, setStatus] = useState()

    async function queryWinnerBidAmount() {
        const contract = getContract(library, sealedBid.abi, getSealedBidNFTAddress(chainId))
        contract.methods.bidderListHeaderP(id).call().then((header) => {
            if (new BigNumber(header).isGreaterThan(new BigNumber('10000000000'))) {
                console.log('queryWinnerBidAmount no winner')
                setWinnerAmount('0')
            } else {
                contract.methods.bidderListP(id, header).call().then((biddenAddress) => {
                    contract.methods.myAmountBid1P(biddenAddress, id).call().then((amount) => {
                        console.log('queryWinnerBidAmount winner', amount)
                        setWinnerAmount(amount)
                    })
                })
            }
        })
    }


    async function getSBNFTPoolDetail() {
        try {
            console.log('account:', account)

            const contract = getContract(library, sealedBid.abi, getSealedBidNFTAddress(chainId))
            console.log('sb nft starting price:', contract.methods)
            contract.methods.pools(id).call().then((res) => {
                console.log('sb nft starting price:', res)
                setStartPrice(res.amountMin1)
                setName(res.name)
                setTokenId(res.tokenId)
                setTime(res.closeAt)
                const nftAddress = res.token0
                setAddress(nftAddress)

                const time = res.closeAt;
                //setTime(time)
                const date = new Date(time * 1000);
                const now = new Date();
                const leftTime = date - now;
                const status = leftTime > 0 ? 'Live' : 'Closed'
                setStatus(status);

                const mine = res.creator.toLowerCase() === account.toLowerCase();
                setIsMine(mine)

              console.log('is mine',mine)

                contract.methods.myAmountBid1P(account, id).call().then(bid =>{
                    if (isGreaterThan(bid, '0')) {
                        setJoined(true)
                        setMyBid(bid)
                    } else {
                        setJoined(false)
                    }
                })

                if (status === 'Closed') {
                    contract.methods.bidderListHeaderP(id).call().then((header) => {
                        console.log('header:', header)
                        if (new BigNumber(header).isGreaterThan(new BigNumber('10000000000'))) {
                            setBidden(false)
                            if (mine) {
                              contract.methods.creatorClaimedP(id).call().then(claimed => {
                                console.log('creatorClaimedP-->',claimed)
                                if (!claimed) {
                                  setClaimButtonText('Claim your unswapped token')
                                }
                              })
                            }
                        } else {
                            contract.methods.bidderListP(id, header).call().then((biddenAddress) => {
                                console.log('biddenAddress', biddenAddress)
                                setBidden(biddenAddress)
                                if (mine) {
                                    contract.methods.creatorClaimedP(id).call().then(claimed => {
                                        console.log('creatorClaimedP-->',claimed)
                                        if (!claimed) {
                                            setClaimButtonText(`Claim your swapped ${Psymbol}`)
                                        }
                                    })

                                } else {

                                    if (biddenAddress.toLowerCase() === account.toLowerCase()) {
                                        setWin(true)
                                        contract.methods.myClaimedP( account,id).call().then(claimed => {
                                            if (!claimed) {
                                                setClaimButtonText('Claim your swapped token')
                                            }
                                        })

                                    } else {
                                        contract.methods.myClaimedP(account,id).call().then(claimed => {
                                            if (!claimed) {
                                                setClaimButtonText(`Claim your swapped ${Psymbol}`)
                                            }
                                        })
                                    }
                                }
                                setMyBidden(true)
                            })
                        }
                    })
                }


                const nftType = res.nftType
                if (nftType === '0') {
                    try {
                        const tokenContract = getContract(library, bounceERC721.abi, nftAddress)
                        tokenContract.methods.tokenURI(res.tokenId).call().then(uriResult => {
                            console.log('uriResult', uriResult.toString())
                            fetch(uriResult).then(response => {
                                response.json().then(nft => {
                                    try {
                                        setNFT({
                                            title: nft.title,
                                            name: nft.name? nft.name: nft.properties.name.description,
                                            image: nft.image? nft.image: nft.properties.image.description,
                                            description: nft.description? nft.description: nft.properties.description.description
                                        })
                                    }catch (e) {

                                    }

                                })
                            })
                        })
                    } catch (e) {
                        console.log('nft detail')
                    }

                } else if (nftType === '1') {
                    try {
                        const tokenContract = getContract(library, bounceERC1155.abi, nftAddress)
                        tokenContract.methods.uri(res.tokenId).call().then(uriResult => {
                            console.log('uriResult', uriResult)
                            fetch(uriResult, {method: "get"}).then(response => {
                                response.json().then(nft => {
                                    console.log('nft result---->',nft)
                                    try {
                                        setNFT({
                                            title: nft.title,
                                            name: nft.name? nft.name: nft.properties.name.description,
                                            image: nft.image? nft.image: nft.properties.image.description,
                                            description: nft.description? nft.description: nft.properties.description.description
                                        })
                                    }catch (e) {

                                    }


                                })
                            })
                        })
                    } catch (e) {
                        console.log('nft detail')
                    }

                }
            })


            contract.methods.passwordP(id).call().then((res) => {
                console.log('query fs password:', res)
                setPassword(res)
            })


        } catch (e) {
            console.log('get sb nft Info :', e)
        }
    }

    // async function queryBiddenStatus(){
    //     try {
    //         const contract = getContract(library, sealedBid.abi, getSealedBidNFTAddress(chainId))
    //         const creator = await contract.methods.creatorP(id).call();
    //         const mine = creator.toLowerCase() === account.toLowerCase();
    //         setIsMine(mine)
    //
    //         const time = await contract.methods.closeAtP(id).call();
    //         //setTime(time)
    //         const date = new Date(time * 1000);
    //         const now = new Date();
    //         const leftTime = date - now;
    //         const status = leftTime > 0 ? 'Live': 'Closed'
    //         setStatus(status);
    //
    //         if(status === 'Live'){
    //             if(mine){
    //                const total = await sbContract.methods.creatorFilledAmount(id).call()
    //                 setTotalBid({from: total[0], to: total[1]})
    //             }else {
    //                 const from = await sbContract.methods.myAmountBid0P(account, id).call()
    //                 const to = await sbContract.methods.myAmountBid1P(account, id).call()
    //                 console.log('sb my bid', from, to)
    //                 setMyBid({from, to})
    //             }
    //         }else {
    //             let claimed;
    //             if(mine){
    //                 claimed = await sbContract.methods.creatorClaimedP(id).call();
    //             }else {
    //                 claimed = await sbContract.methods.myClaimedP(account, id).call();
    //             }
    //             if(!claimed){
    //                 setClaimStatus('unClaim');
    //                 let biddenAmount = ''
    //                 let bidAmount = ''
    //                 if(mine){
    //                     biddenAmount = await sbContract.methods.creatorFilledAmount(id).call()
    //                     bidAmount = fromTotal;
    //                 }else {
    //                     biddenAmount = await sbContract.methods.bidderFilledAmount(account, id).call()
    //                     bidAmount = await sbContract.methods.myAmountBid0P(account, id).call()
    //
    //                 }
    //                 setBidden({from: biddenAmount[0],to: biddenAmount[1]})
    //                 if(isEqualTo(biddenAmount[0], '0')){
    //                     setBiddenStatus('Unfilled')
    //                     setClaimButtonText('Claim your unswapped tokens')
    //                 } else if (isEqualTo(biddenAmount[0], bidAmount)) {
    //                     setBiddenStatus('Filled')
    //                     setClaimButtonText('Claim your swapped tokens')
    //                 } else if (isGreaterThan(bidAmount, biddenAmount[0])) {
    //                     setBiddenStatus('Partial')
    //                     setClaimButtonText('Claim your tokens and unswapped ETH')
    //                 }
    //             }
    //         }
    //
    //         if(!isMine ){
    //
    //         }else {
    //
    //         }
    //     }catch (e) {
    //         console.log('queryBiddenStatus')
    //     }
    // }

    useEffect(() => {

        if (active) {
            //queryBiddenStatus()
            queryWinnerBidAmount()
            getSBNFTPoolDetail()
        } else {
            dispatch({type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true});
        }

    }, [active])


    return {
        name,
        address,
        symbol,
        floor,
        limit,
        time,
        bidden,
        totalBid,
        password,
        fromBidAmount,
        toBidAmount,
        fromAmount,
        status,
        isMine,
        claimButtonText,
        myBid,
        startPrice,
        NFT,
        joined,
        tokenId,
        winnerAmount,
        win
    }
}
