import React, { useContext, useState, useEffect } from "react";
import SearchModal, { ModalContent, ModalTitle } from "./SearchModal";
import { myContext } from '../../reducer';
import { HANDLE_SHOW_MENU_MODAL, HANDLE_SHOW_SEARCH_DETAIL } from '../../const';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import icon_search from '../../static/image/icon-search.svg';
import Checkbox from '@material-ui/core/Checkbox';
import CMC from '../../pages/A_poolList/assets/CMC.svg';
import CG from '../../pages/A_poolList/assets/CG.svg';
import ALL from '../../pages/A_poolList/assets/ALL.svg';
import CMC_select from '../../pages/A_poolList/assets/CMC_select.svg';
import CG_select from '../../pages/A_poolList/assets/CG_select.svg';
import ALL_select from '../../pages/A_poolList/assets/ALL_select.svg';
import icon_check_no from '../../pages/A_poolList/assets/icon_check_no.svg';
import icon_check_yes from '../../pages/A_poolList/assets/icon_check_yes.svg';
import { requestSearch } from '../../pages/A_poolList/useHook/Request_API';
import { useActiveWeb3React } from "../../web3";


const SearchBox = styled.div`
display:flex;
width: calc(100vw - 40px);
position: relative;
padding: 50px 5vw 0;
margin-bottom: 30px;
`
const SearchIcon = styled.img`
  width: 18px;
  position: absolute;
  top: 28px;
  left: 40px;
`
const SearchInput = styled.input`
  width: calc(100vw - 90px);
  flex: 1;
  margin-left: 10px;
  border: none;
  border-bottom: 3px solid #000;
  padding: 5vw;
  text-indent: 30px;
  font-size: 23px;
  position: absolute;
  top:0;
`
const TypeStatus = styled.div`
    padding:24px 20px;
    width: calc(100vw - 40px);
`
const TypeList = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
`
const TokenList = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  >label{
    display: flex;
  }
`

const StatusList = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
`
const Titles = styled.div`
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    display: flex;
    color: #000000;
    opacity: 0.2;
`


const Breadcrumb = styled.a`
  font-family: 'Optima';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  margin-left: 45px;
  margin-top: 30px;
  color: ${({ theme }) => theme.black};
  opacity: ${({ active }) => active ? 1 : 0.2};
  cursor: pointer;
  align-items: self-start;
  &:hover{
    color: ${({ active, theme }) => active ? theme.black : rgb(101, 101, 101)};;
    opacity: 1;
  };
`
const ApplyBtn = styled.div`
    margin: 30px 5vw 0;
    width: calc(100vw - 90px);
    height: 48px;
    line-height: 48px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background-color: #000;
    border-radius: 4px;
`
const CancelBtn = styled.div`
    margin: 12px 5vw 0;
    width: calc(100vw - 90px);
    height: 48px;
    line-height: 48px;
    color: #000;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
`

const SearchItemStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    label{
      display: block;
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin-right: 8px;
      background: url(${icon_check_no}) no-repeat;
      background-position: 0 0;
      background-size: contain;
      opacity:0.5;
      &.checked{
        background: url(${icon_check_yes}) no-repeat;
        background-position: 0 0;
        background-size: contain;
      }
    }

    &>input{
        /* display: none; */

      }
    &>input:checked + label{
      background: url(${icon_check_yes}) no-repeat;
      background-position: 0 0;
      background-size: contain;
    }

    p{
      font-family: 'Helvetica Neue';
      font-size: 14px;
      font-weight: 500;
    }

    .implicit{
        /* display: none; */
        width: 100%;

      &.hidden{
        display:none;
      }

      input{
        border: none;
        border-bottom: 1px solid rgba(0,0,0,.3);
        width: 100%;
        line-height: 36px;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Helvetica Neue';
        margin-top: 5px;
      }
    }
`
const CheckedBox= styled.div`
  display: flex;
`
const PullBoxStyled = styled.div`
  top: 53px;
  left: -20px;
  width: 100%;
  min-height: 254px;
  padding: 20px 16px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 10;
  .btn_box{
    display: flex;
    justify-content: space-between;
    margin-top:16px;
    button{
      width: 144px;
      height: 48px;
      box-sizing: border-box;
      border: 1px solid rgba(0,0,0,.2);
      font-family: 'Helvetica Neue';
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;

      &.black{
        background-color: #000;
        color: #fff;
      }

      &.gray{
        background-color: rgba(0,0,0,.14);
        color: #fff;
      }
    }
  }
`


export const MobileSelectModal = ({
    createOptions,
    setIsLoading,
    setIsEmpty,
    setRequestData,
    // onDismiss,
}) => {
    const { state, dispatch } = useContext(myContext);
    const history = useHistory();
    const [crumb, setCrumb] = useState();
    const path = history.location.pathname;
   
    const { chainId } = useActiveWeb3React()
    const tokenTypes = [
      { key: 1, value: 'CMC', icon: CMC,icon_select: CMC_select },
      { key: 2, value: 'CG',icon: CG, icon_select: CG_select},
      { key: 0, value: 'ALL', icon: ALL,icon_select: ALL_select }
    ];
    const statusTypes = [
      { key: 0, value: 'All' },
      { key: 1, value: 'Live&Filled' },
      { key: 2, value: 'Closed' }
    ];
    const defaultFilter = JSON.parse(window.localStorage.getItem('A_FilterOption')) || {}
    const [isClearAll, setIsClearAll] = useState(false);
    const [poolType, setPoolType] = React.useState(defaultFilter.poolType || createOptions[0] || { key: 0, value: 'Fixed Swap Auction', route: 'fixed-swap', method: "query_fixed_pools" });
    const [antiFake, setAntiFake] = React.useState(defaultFilter.antiFake || { key: 0, value: 'ALL', icon: ALL, icon_select: ALL_select});
    const [filterStatus, setFilterStatus] = React.useState(defaultFilter.filterStatus || { key: 0, value: 'All' });
    const [displayMode, setDisplayMode] = useState(defaultFilter.displayMode === false ? false : true)

    useEffect(() => {
      if (!poolType || createOptions.length === 0) return
      const keyArr = createOptions.map(item => {
        return item.key
      })
      
      if (keyArr.includes(poolType.key)) return
      
      setPoolType(createOptions[0])
    }, [createOptions])

    useEffect(() => {
      if (!poolType) return
      history.push(`/application/${poolType.route}`);
    }, [poolType])

    useEffect(() => {
      if (!poolType || !chainId) return
      const FilterObj = {
        poolType, antiFake, filterStatus, displayMode
      }
      window.localStorage.setItem('A_FilterOption', JSON.stringify(FilterObj))
  
      request_init()
    }, [poolType, antiFake, filterStatus, displayMode, chainId])

    const handelChickSearch = async () => {
      let A_SearchParams = JSON.parse(window.localStorage.getItem('A_SearchParams')) || {}
      let params = {}
      for (const key in A_SearchParams) {
        if (A_SearchParams.hasOwnProperty(key)) {
          const element = A_SearchParams[key];
          if (element) {
            if (key === 'pool_id') {
              params[key] = parseInt(element)
            } else {
              params[key] = element
            }
          }
        }
      }
      request_init(params);
      dispatch({ type: HANDLE_SHOW_SEARCH_DETAIL, showSearchDetail: false });
    }

    const request_init = (options = {}) => {

      const A_FilterOption = JSON.parse(window.localStorage.getItem('A_FilterOption')) || {
        key: 0,
        value: 'Fixed Swap Auction',
        route: 'fixed-swap',
        method: "query_fixed_pools"
      }
      // 如果接口方法未实现，则是用传统方法获取poolID
      console.log('A_console_request：'+JSON.stringify(A_FilterOption))
  
      if (!A_FilterOption.poolType.method) {
        // setIsLoading(true)
        // console.log('A_console', A_FilterOption)
        return
      }
  
  
      const params = {
        method: A_FilterOption.poolType.method,
        filter: A_FilterOption.antiFake.key,
        status: A_FilterOption.filterStatus.value,
        ...options
      }
      setIsLoading(true)
      requestSearch(chainId, params).then(res => {
        const pool_arr = res.data.data.pool_ids || []
        setIsLoading(false)
        if (pool_arr.length === 0) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
          setRequestData(pool_arr.reverse())
        }
      })
    }

    return (
        <SearchModal 
        isOpen={false}
        isOpen={state.showSearchDetail} 
        onDismiss={() => {
            dispatch({ type: HANDLE_SHOW_SEARCH_DETAIL, showSearchDetail: false });
        }}
        >
            <TypeStatus>
                <TypeList>
                  <Titles>Pool Type:</Titles>
                  {
                    createOptions.map((item) => {
                      return <label onClick={() =>{setPoolType(item)}}  key={item.key}>
                          <Checkbox
                              checked={poolType === item}
                              value={item.value}
                              color="default"
                              inputProps={{ 'aria-label': 'primary checkbox' }}/>
                          {item.value}
                      </label>
                    })
                  }
                </TypeList>
                <TokenList>
                  <Titles>Token:</Titles>
                  {
                    tokenTypes.map((item) => {
                      return <label  onClick={() =>{setAntiFake(item)}}  key={item.key}>
                          <Checkbox
                              checked={antiFake.value == item.value}
                              value={item}
                              color="default"
                              inputProps={{ 'aria-label': 'primary checkbox' }}/>
                          <img src={item.icon}/>
                      </label>
                    })
                  }
                </TokenList>
                <StatusList>
                    <Titles>State:</Titles>
                    {
                      // setFilterStatus
                      statusTypes.map((item) => {
                        return <label  onClick={() =>{setFilterStatus(item)}}  key={item.key}>
                            <Checkbox
                              checked={filterStatus.value == item.value}
                              value={item}
                              color="default"
                              inputProps={{ 'aria-label': 'primary checkbox' }}/>
                            {item.value}
                        </label>
                      })
                    }
                </StatusList>

                <PullBoxStyled>
                  <Titles>Search by:</Titles>
                  <SearchItem itemName='Pool ID' placeholder='Enter Pool ID ' requestKey='pool_id' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
                  <SearchItem itemName='Pool Name' placeholder='Enter Pool Name ' requestKey='pool_name' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
                  <SearchItem itemName='Token Contract Address ' placeholder='Enter Token Contract Address' requestKey='token_address' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
                  <SearchItem itemName='Token Symbol' placeholder='Enter Token Symbol ' requestKey='token_symbol' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
                  <SearchItem itemName='Auction creator wallet address' placeholder='Enter Token Contract Address' requestKey='creator_address' isClearAll={isClearAll} setIsClearAll={setIsClearAll} />
                  <div className='btn_box'>
                    <button className='white' onClick={() => {
                      setIsClearAll(true);
                      setTimeout(() => {
                        setIsClearAll(false)
                      }, 500)
                      dispatch({ type: HANDLE_SHOW_SEARCH_DETAIL, showSearchDetail: false });
                    }}>Сlear all</button>
                    <button className='black' onClick={handelChickSearch}>
                      Show Results
                    </button>
                  </div>
                </PullBoxStyled>

            </TypeStatus>
            
        </SearchModal>
    )
}

const SearchItem = ({ itemName, requestKey, placeholder, isClearAll }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [inputVal, setInputVal] = useState('')
  useEffect(() => {
    if (!isClearAll) return
    setIsChecked(false)
    setInputVal('')
  }, [isClearAll])

  useEffect(() => {
    if (!isChecked) {
      let A_SearchParams = JSON.parse(window.localStorage.getItem('A_SearchParams')) || {}
      A_SearchParams[requestKey] = null
      window.localStorage.setItem('A_SearchParams', JSON.stringify(A_SearchParams))
    }
  }, [isChecked])

  return <SearchItemStyled>
    <CheckedBox onClick={() => { setIsChecked(!isChecked) }}>
      <label for={itemName} className={isChecked ? 'checked' : ''}></label>
      <p>{itemName}</p>
    </CheckedBox>
    <div className={isChecked ? 'implicit' : 'implicit hidden'}>
      <input type="text"
        placeholder={placeholder || ''}
        value={inputVal}
        onChange={(e) => {
          const val = String(e.target.value).trim()
          setInputVal(val)
        }}
        onBlur={(e) => {
          const val = String(e.target.value).trim()
          let A_SearchParams = JSON.parse(window.localStorage.getItem('A_SearchParams')) || {}
          A_SearchParams[requestKey] = val
          window.localStorage.setItem('A_SearchParams', JSON.stringify(A_SearchParams))
        }} />
    </div>
  </SearchItemStyled>
}