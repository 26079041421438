import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const AccountFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
AccountFrame.Background = styled.div`
  background-color: #121212;
  /* height: 95vh; */
`

AccountFrame.SelectFrame = styled.div`
  width: 1280px;
  margin: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const AccountHeader = styled.div`
  background-color: #121212;
  width: 100%;
  display: flex;
`

export const AuctionFrame = styled.div`
  width: 1280px;
  height: 91px;
  line-height: 91px;
  line-height: 91px;
  margin: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  overflow-y: scroll;
  position: relative;
  box-sizing: border-box;
  padding-right: 50px;
  @media (max-width: 767px) {
    width: 100%;
    overflow-x:scroll;
  }

  img{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 35px;
    right: 20px;
    user-select: none;
  }
`

export const Auction = styled(Link)`
  display: inline-block;
  height: 100%;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 25px;
  line-height: 25px;
  padding: 0 9px;
  margin-right: 32px;
  background-color: ${({ active, theme }) => (active ? theme.white : '')};
  color: ${({ active, theme }) => (active ? theme.black : theme.white)};
  cursor: pointer;

  &:hover{
    opacity: ${({ active }) => (active ? 1 : 0.7)};
  };
  @media (max-width: 767px) {
    white-space:nowrap;
  }
`
