import React, {useState} from 'react'
import {CVCreateFrame} from "../../components/cvPools/CVCreateFrame";
import {Step1} from "../../components/cvPools/Step1";
import {Step2} from "../../components/cvPools/Step2";
import {Step3} from "../../components/cvPools/Step3";

export const SVFSCreate =() =>{
    const [curTab, setCurTab] = useState(0)
    const [projectInfo, setProjectInfo] = useState()
    const [poolInfo, setPoolInfo] = useState()


    const steps = [
        {key: 0, title: '01. Project Description',view: (<Step1 visible={curTab === 0} next={(projectInfo)=>{
            setCurTab(1)
                console.log('project info', projectInfo)
                setProjectInfo(projectInfo)
        }}/>)},
        {key: 1, title: '02. Pool Creation', view: <Step2 visible={curTab === 1} next={(poolInfo)=>{
                setCurTab(2)
                console.log('pool info', poolInfo)
                setPoolInfo(poolInfo)
            }}/>},
        {key: 2, title: '03. Review & Confirmation', view: <Step3 projectInfo={projectInfo} poolInfo={poolInfo} visible={curTab === 2}/>}]


    return (
        <CVCreateFrame steps={steps} curTab={curTab} setCurTab={setCurTab} projectInfo={projectInfo} poolInfo={poolInfo}/>
    )
}