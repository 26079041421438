import React, { useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { PoolCard } from "../../components/PoolCard";
import { myContext } from "../../reducer";
import rect_logo from "../../static/image/rect-logo.svg";
import { weiToNumber } from "../../utils/numberTransform";
import { LoadingPage } from "../../components/common/LoadingPage";

import icon_loading from '../../static/image/icon-loading.svg';


const CardFrame = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1280px;
  min-height: 70vh;
  margin: auto;
  /* margin-top: -158px; */
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const EnglishAuctionNFT = ({ pools, isLoading }) => {
    const { state, dispatch } = useContext(myContext);
    const history = useHistory()
    // if(!pools){
    //     return <></>
    // }
    return (
        <CardFrame>
            {pools && pools.length !== 0 ?
                pools.map(item => {
                    return (
                        <PoolCard
                            height='455px'
                            onJoin={() => {
                                history.push(`/english-auction-nft/${item.index}`, { id: item.index });
                            }} cover={item.cover ? item.cover : rect_logo} pool={item}
                            poolMeta={[{
                                title: 'Starting Price',
                                content: `${weiToNumber(item.startingPrice)} ETH`,
                            }, {
                                title: 'Participants',
                                content: item.onlyBot ? 'BOT Holder' : 'Public'
                            }]} />
                    )
                }) : <div style={{
                    fontFamily: 'IBM Plex Mono',
                    fontSize: 16,
                    marginTop: 400,
                    color: '#000',
                    width: '100%',
                    textAlign: "center"
                }}>You have no live auctions at the moment</div>}
        </CardFrame>
    )


}