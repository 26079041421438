import { useEffect, useState } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import { getLotteryNFTAddress } from "../../../web3/contractAddress";

import { queryPoolItem_pools, queryERC20Token, queryERC721Token, queryERC1155Token } from './Request_CT'
import { getNFTPoolStatus, getParticipant, getLTParticipants, getNFTPrice } from './Request_FUN'

import BounceLTNFT from '../../../web3/abi/bounceLotteryNFT.json'

export const useLT_List_ERC_NFT = (queryListArr = []) => {
    const { active, library, chainId } = useActiveWeb3React()
    const [poolsDate, setPoolsDate] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    // const noConnect = new Web3(new Web3.providers.HttpProvider("https://api.infura.io/v1/jsonrpc/mainnet")).currentProvider
    // console.log('A_console_noConnect', noConnect)
    useEffect(() => {
        if (!active || !queryListArr) return
        setIsLoad(true)
        queryPoolDate(queryListArr)
    }, [active, queryListArr, chainId])

    const queryPoolDate = async (queryListArr) => {

        const LT_ERC_NFT_CT = getContract(library, BounceLTNFT.abi, getLotteryNFTAddress(chainId))

        const dataList = await Promise.all(queryListArr.map(async (poolID) => {
            const poolItemInfo = await queryPoolItem_pools(LT_ERC_NFT_CT, poolID - 1)
            if (poolID === 0) return false
            // poolItemInfo.currentPrice = await LT_ERC_NFT_CT.methods.currentBidderAmount1P(poolID).call()

            // 通过获取到的池子信息筛选出列表需要的信息
            // console.log('LT_console', poolItemInfo)
            const poolItemData = {
                poolID: poolItemInfo.poolId,
                status: getNFTPoolStatus(poolItemInfo.closeAt),
                poolName: poolItemInfo.name,
                creator: poolItemInfo.creator,
                progress: '100%',
                nftType: 'ERC721',
                toToken: await queryERC20Token(library, poolItemInfo.token1, chainId),
                Participant: getLTParticipants(poolItemInfo.curPlayer, poolItemInfo.maxPlayer)
            }
            poolItemData.fromToken = await queryERC721Token(library, poolItemInfo.token0, poolItemInfo.tokenId0)
            poolItemData.ticket = getNFTPrice(poolItemInfo.amount1, poolItemData.toToken.decimals)

            return poolItemData
        }))

        const dataList_2 = dataList.filter(item => item)
        // console.log('LT_console', dataList)
        setPoolsDate(dataList_2)
        setIsLoad(false)
    }

    return { poolsDate, isLoad }
}
