import React from 'react';
import { t } from '../../utils/intl';
import rotate from '../../static/image/rotate.svg';
import './content-box.scss';
import { TO } from '../const';

export const WaitContent = ({
  symbol,
  isCommon,
  from,
  to,
}) => {
  return (
    <div className='content-box wait-content'>
      <img src={rotate} className='waiting' alt='waiting' />
      <p>{t('waiting.title')}</p>
      {!isCommon && <p>{t('waiting.swap', {from, symbol, to, TO})}</p> }
      <p>{t('waiting.message')}</p>
    </div>
  )
}
