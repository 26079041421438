import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import rect_logo from "../../../static/image/rect-logo.svg";
import { weiToNum } from "../../../utils/numberTransform";
import { PoolCard } from "../../../components/PoolCard";
import { HANDLE_SHOW_CONNECT_MODAL } from "../../../const";
import { useActivePlatform, useActiveWeb3React } from "../../../web3";
import { myContext } from "../../../reducer";
import { LayoutFrame } from "../../../components/common/Layout";
import { JoinTipModal } from "../../../components/common/JoinTipModal";
import Modal from "../../../components/common/Modal";
import { useYGiftFSNFTList } from "../../../web3/storeFixswap";

import { EmptyPoolPage, LoadingPage } from "../../../components/common/LoadingPage";
import icon_loading from '../../../static/image/icon-loading-white.svg';
import empty_pool from '../../../static/image/empty-pool-white.svg';

export const YGiftFSNFT = ({ searchText }) => {

  const history = useHistory()
  const { active, library } = useActiveWeb3React()
  const { FSNFTPoolList, setLoadFSNFTed, loadFSNFTed } = useYGiftFSNFTList()
  const { state, dispatch } = useContext(myContext);
  const { Psymbol } = useActivePlatform()
  const [isJoinTip, setIsJoinTip] = useState(false)
  const [onJoinInfo, setOnJoinInfo] = useState({})

  useEffect(() => {
    setLoadFSNFTed(false)
  }, [])



  const onJoin = (index, fromSymbol, adress) => {
    console.log('onJoin', index, isJoinTip)
    if (isJoinTip) {
      if (active) {
        history.push(`/store/ygift/fixed-swap-nft/${index}`, { id: index });
      } else {
        dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
      }
      return
    }
    setTimeout(() => {
      setOnJoinInfo({
        index,
        fromSymbol,
        adress
      })
    }, 50);
    setIsJoinTip(true)

  }


  return (
    <LayoutFrame style={{ backgroundColor: '#121212', flexDirection: 'row', display: 'flex', paddingBottom: 64 }}>

      {!FSNFTPoolList ? <LoadingPage style={{ marginTop: 200 }} src={icon_loading} /> : FSNFTPoolList.length === 0 ? <EmptyPoolPage src={empty_pool} /> : (
        FSNFTPoolList.filter(item => {
          return (searchText === '' || item.name.includes(searchText) || item.index.toString() == searchText)
        }).map(item => {
          return (
            <PoolCard onJoin={() => {
              if (active) {
                history.push(`/store/ygift/fixed-swap-nft/${item.index}`, { id: item.index });
              } else {
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
              }
            }} cover={item.cover ? item.cover : rect_logo} pool={item}
              poolMeta={[{
                title: 'Price',
                content: `${weiToNum(item.amountTotal1)} ${Psymbol}`,
              }, {
                title: 'Token ID',
                content: item.tokenId
              }]} />
          )
        })
      )}

      <Modal
        closeable
        isOpen={isJoinTip}
        onDismiss={() => {
          setIsJoinTip(false)
        }}
        maxWidth={'450px'}

      >

        {/* <ModalTitle
                    style={{ textAlign: 'center', border: 'none' }}
                >Token imported</ModalTitle> */}
        <JoinTipModal
          onJoin={onJoin}
          onJoinInfo={onJoinInfo}
        />
      </Modal>
    </LayoutFrame>
  )


}


export const MyYGiftFSNFT = ({ searchText }) => {

  const history = useHistory()
  const { active, library, account } = useActiveWeb3React()
  const { FSNFTPoolList, setLoadFSNFTed } = useYGiftFSNFTList()
  const { state, dispatch } = useContext(myContext);
  const { Psymbol } = useActivePlatform()
  const [isJoinTip, setIsJoinTip] = useState(false)
  const [onJoinInfo, setOnJoinInfo] = useState({})

  useEffect(() => {
    setLoadFSNFTed(false)
  }, [])



  const onJoin = (index, fromSymbol, adress) => {
    console.log('onJoin', index, isJoinTip)
    if (isJoinTip) {
      if (active) {
        history.push(`/store/ygift/fixed-swap-nft/${index}`, { id: index });
      } else {
        dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
      }
      return
    }
    setTimeout(() => {
      setOnJoinInfo({
        index,
        fromSymbol,
        adress
      })
    }, 50);
    setIsJoinTip(true)

  }


  return (
    <LayoutFrame style={{ backgroundColor: '#121212', flexDirection: 'row', display: 'flex', paddingBottom: 64 }}>
      {!FSNFTPoolList ? <LoadingPage style={{ marginTop: 200 }} src={icon_loading} /> : (
        FSNFTPoolList.filter(item => {
          return ((item[0] === account) && (searchText === '' || item.name.includes(searchText) || item.index.toString() == searchText))
        }).map(item => {
          return (
            <PoolCard onJoin={() => {
              if (active) {
                history.push(`/store/ygift/fixed-swap-nft/${item.index}`, { id: item.index });
              } else {
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
              }
            }} cover={item.cover ? item.cover : rect_logo} pool={item}
              poolMeta={[{
                title: 'Price',
                content: `${weiToNum(item.amountTotal1)} ${Psymbol}`,
              }, {
                title: 'Token ID',
                content: item.tokenId
              }]} />
          )
        })
      )}

      {FSNFTPoolList && FSNFTPoolList.filter(item => {
        return ((item[0] === account) && (searchText === '' || item.name.includes(searchText) || item.index.toString() == searchText))
      }).length === 0 ? <EmptyPoolPage src={empty_pool} /> : ''
      }


      <Modal
        closeable
        isOpen={isJoinTip}
        onDismiss={() => {
          setIsJoinTip(false)
        }}
        maxWidth={'450px'}

      >

        {/* <ModalTitle
                    style={{ textAlign: 'center', border: 'none' }}
                >Token imported</ModalTitle> */}
        <JoinTipModal
          onJoin={onJoin}
          onJoinInfo={onJoinInfo}
        />
      </Modal>
    </LayoutFrame>
  )


}