import React from 'react';
import {t, tHTML} from '../../utils/intl';
import rotate from '../../static/image/rotate.svg';
import './content-box.scss';

export const Pending = ({
                          content,
                          link,
                        }) => {
  return (
      <div className='content-box'>
        <img src={rotate} className='waiting' alt='waiting'/>
        {tHTML('pending.title')}
        <p>{content}</p>
        <a href={link} target='_blank' rel='noopener noreferrer'>{t('view-on-etherscan')}</a>
      </div>
  )
}
