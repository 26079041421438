import React, {useState, useCallback, useEffect, useContext} from 'react';
import {Cover, Pool, PoolFrame} from "../../components/common/Layout";
import icon_close from '../../static/image/icon-close.svg'
import {Form, Input} from "../../components/common/Form";
import {useParams} from 'react-router-dom';
import {Button} from "../../components/common/Button";
import md5 from "js-md5";
import Web3 from 'web3'
import {useHistory} from 'react-router-dom'
import {formatAddress, getTime} from "../../utils/common";
import {getContract, useActiveWeb3React} from "../../web3";
import bounceERC721 from "../../web3/abi/bounceERC721.json";
import bounceEnglishAuctionNFT from "../../web3/abi/bounceEnglishAuctionNFT.json";

import {
  getAlpacasNFTAddress, getBounceYGiftEAAddress, getYGiftNFTAddress
} from "../../web3/contractAddress";
import icon_helper from '../../static/image/icon-helper.svg';
import {useIsXSDown} from '../../components/utils/themeHooks';

import {
  CreateModal,
  initStatus,
  approveStatus,
  pendingStatus,
  confirmStatus,
  successStatus,
  errorStatus,
  cancelStatus
} from "../../components/common/CreateModal";
import {HelperLayout} from "../../components/common/HelperLayout";
import {PoolCover} from "../../components/DetailCover";
import rect_logo from "../../static/image/rect-logo.svg";
import styled from "styled-components";
import {useTokenList} from "../../web3/common";

const {toWei, BN} = Web3.utils

const FormFrame = styled.form`
  width: 100%;
`


export const YGiftCreateEANFT = () => {
  const history = useHistory()
  const {type} = useParams();
  console.log('create type :', type)
  const isXSDown = useIsXSDown();
  const {active, account, library, chainId} = useActiveWeb3React();
  const tokenOptions = useTokenList()
  const [modalStatus, setModalStatus] = useState(initStatus)

  const [hasLimit, setHasLimit] = useState(false)
  const [selectedToken, setSelectedToken] = useState(tokenOptions[0])

  const [name, setName] = useState()
  const [amount, setAmount] = useState('1')
  const [days, setDays] = useState()
  const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  const [nftID, setNFTID] = useState()
  const [onlyBot, setOnlyBot] = useState(false)
  const [password, setPassword] = useState()
  const [cover, setCover] = useState()
  const [price, setPrice] = useState()
  const [balance, setBalance] = useState()
  const [increment, setIncrement] = useState('0')
  const [errors, setErrors] = useState({id: ''})
  const [idError, setIDError] = useState()

  const handleChange = async event => {
    event.preventDefault();
    const {name, value} = event.target;

    switch (name) {
      case "nftID":
        setNFTID(value)
        setIDError(false)
        setCover(null)
        try {
          const contract = getContract(library, bounceERC721.abi, getYGiftNFTAddress(chainId))
          const owner = await contract.methods.ownerOf(value).call()
          if(owner.toLowerCase() !== account.toLowerCase()){
            setIDError(`Token ID is invalid, owner is ${formatAddress(owner)}`)
          }else {
            contract.methods.gifts(value).call().then(uriResult => {
              setCover(uriResult.url)
            })
          }
        } catch (e) {
          console.log('query nft error', e)
          setIDError("Token ID is invalid")
        }
        break
      case "name":
        setName(value)
        break;
      case 'amount':
        const amount = value.replace(/[^\d.]/g, '')
        setAmount(amount)
        break
      case 'price':
        const price = value.replace(/[^\d.]/g, '')
        setPrice(price)
        break
      case 'increment':
        const increment = value.replace(/[^\d.]/g, '')
        setIncrement(increment)
        break
      case 'password':
        setPassword(value)
        break
      case 'days':
        let days = value.replace(/^0(0+)|[^\d]+/g, '')
        if (days > 20) {
          days = 20
        }
        setDays(days)
        break
      case 'hours':
        let hours = value.replace(/^0(0+)|[^\d]+/g, '')
        if (hours > 24) {
          hours = 24
        }
        setHours(hours)
        break
      case 'minutes':
        let minutes = value.replace(/^0(0+)|[^\d]+/g, '')
        if (minutes > 60) {
          minutes = 60
        }
        setMinutes(minutes)
        break
      default:
    }

  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const bounceAddress = getBounceYGiftEAAddress(chainId)
    const tokenContract = getContract(library, bounceERC721.abi, getYGiftNFTAddress(chainId))
    const bounceContract = getContract(library, bounceEnglishAuctionNFT.abi, bounceAddress)
    const weiPrice = toWei(price)
    const time = getTime(days, hours, minutes);
    const incrementData = (increment || increment == 0) ? 1 : toWei(increment);
    setModalStatus(approveStatus);
    try {
      const result = await tokenContract.methods.approve(
          bounceAddress,
          nftID,
      )
          .send({from: account});
      setModalStatus(confirmStatus);
      if (result.status) {
        await bounceContract.methods.createErc721V2(
            name,
            getYGiftNFTAddress(chainId),
            selectedToken.key,
            nftID,
            weiPrice,
            incrementData,
            time,
            onlyBot,
            !password || password === '' ? 0 : new BN(md5(password)).toString(),
        )
            .send({from: account})
            .on('transactionHash', hash => {
              setModalStatus(pendingStatus)
            })
            .on('receipt', (_, receipt) => {
              setModalStatus(successStatus)
            })
            .on('error', (err, receipt) => {
              setModalStatus(errorStatus)
            })
      } else {
        setModalStatus(errorStatus)
      }
    } catch (err) {
      if (err.code === 4001) {
        setModalStatus(cancelStatus)
      } else {
        setModalStatus(errorStatus)
      }
      console.log('err', err);
    }
  };

  console.log('errors', errors.id)
  return (
      <FormFrame id='pool-create' onSubmit={handleSubmit}>
        <PoolFrame style={{marginTop: 32, padding: isXSDown ? '40px 20px' : '40px 100px'}}>
          <Pool.Close onClick={() => {
            history.push('/store/detail/nft-english-auction')
          }} src={icon_close}/>
          <Pool>
            <Pool.Mode style={{textAlign: 'left'}}>Initial Token Offering</Pool.Mode>
            <Pool.Header style={{
              justifyContent: 'flex-start',
              borderBottom: '4px #000 solid',
              fontSize: isXSDown ? '26px' : '36px',
            }}>{'Create a english NFT auction'}</Pool.Header>
            <Pool.Frame style={{
              flexDirection: isXSDown ? 'column' : 'initial',
            }}>
              <div width={isXSDown ? '100%' : '480px'} style={{height: '100%'}}>
                Contract information
              </div>

              <Pool.Content width={isXSDown ? '100%' : '480px'}>
                <Form error={idError} input={<Input
                    name={'nftID'}
                    required
                    onBlur={handleChange}/>} name={'ERC-721 Token ID'}/>
              </Pool.Content>

            </Pool.Frame>

            {/*<Pool.Divider/>*/}

            <Pool.Frame style={{
              flexDirection: isXSDown ? 'column' : 'initial',
            }}>
              <div style={{width: isXSDown ? '100%' : 480, height: '100%'}}>
                Pool settings

                <Pool.Content width={isXSDown ? '100%' : '512px'} style={{marginTop: 48}}>
                  <PoolCover cover={cover ? cover : rect_logo}></PoolCover>
                </Pool.Content>

              </div>

              <Pool.Content width={isXSDown ? '100%' : '480px'}>
                <Pool.Content style={{marginTop: 0}} width={isXSDown ? '100%' : '480px'}>

                </Pool.Content>
                <Form top={'38px'} error={''} input={<Input
                    name={'price'}
                    required
                    type='number'
                    value={price}
                    onChange={handleChange}
                />}
                      name={'Starting price (Floor price)'}
                      suffix={selectedToken.symbol}/>

                {/*<Form top={'43px'} error={''} input={<Input*/}
                {/*    name='amount'*/}
                {/*    required*/}
                {/*    type='number'*/}
                {/*    value={amount}*/}
                {/*    onChange={handleChange}*/}
                {/*    onBlur={() => {*/}
                {/*      if (amount && balance && isGreaterThan(amount, balance)) {*/}
                {/*        console.log('check--->')*/}
                {/*        setAmount(balance)*/}
                {/*      }*/}
                {/*    }}*/}
                {/*/>} name={'Amount'} addonAfter={(<img onClick={() => {*/}
                {/*  balance && setAmount(balance)*/}
                {/*}} src={icon_max}/>)}*/}
                {/*      extra={*/}
                {/*        <span>{`Balance: ${balance ? balance : '--'}`}</span>}/>*/}


                <Pool.Content width={isXSDown ? '100%' : '480px'}>

                  <Form
                      disabled
                      type='radio'
                      top={'48px'}
                      name={<HelperLayout
                          content={'The minimum price increase to bid from the last highest bid'}>
                        {'Minimal Bid price increment'}
                        <img src={icon_helper}/></HelperLayout>} prefix={(<div style={{marginTop: 9}}>
                    <label>
                      <input
                          onChange={(e) => {
                            setHasLimit(false)
                            setIncrement('0')
                          }} type='radio' name='limit' defaultChecked={true}/>
                      <i></i>
                      {` No limits`}
                    </label>
                    <label style={{marginLeft: 80, marginRight: 34}}>
                      <input
                          onChange={() => {
                            setHasLimit(true)
                          }} type='radio' name='limit'/>
                      <i></i>
                      {` ${selectedToken.symbol}`}
                    </label>
                  </div>)}/>

                  {hasLimit && (
                      <Form
                          top={'38px'}
                          input={<Input type='number' value={increment} onChange={(e) => {
                            setIncrement(e.target.value.replace(/[^\d.]/g, ''))
                          }}
                          />} name={'amount'}/>
                  )}
                </Pool.Content>

                {/*<Pool.Divider/>*/}

                {/*<Pool.Content style={{marginTop: 0}} width={isXSDown ? '100%' : '480px'}>*/}
                {/*  <Form*/}
                {/*      type='radio'*/}
                {/*      top={'38px'}*/}
                {/*      name={<span style={{color: '#000'}}>Participant</span>}*/}
                {/*      prefix={(<div style={{marginTop: 9}}>*/}
                {/*        <label>*/}
                {/*          <input onChange={() => {*/}
                {/*            setOnlyBot(true)*/}
                {/*            setPassword(null)*/}
                {/*            setIsPrivate(false)*/}
                {/*          }} type='radio' name='participant' defaultChecked={true}/>*/}
                {/*          <i></i>*/}
                {/*          {`  BOT holders`}*/}
                {/*        </label>*/}
                {/*        <label style={{*/}
                {/*          marginLeft: isXSDown ? '14px' : 60,*/}
                {/*          marginRight: isXSDown ? '14px' : 34*/}
                {/*        }}>*/}
                {/*          <input onChange={() => {*/}
                {/*            setOnlyBot(false)*/}
                {/*            setPassword(null)*/}
                {/*            setIsPrivate(false)*/}
                {/*          }} type='radio' name='participant'/>*/}
                {/*          <i></i>*/}
                {/*          {` Public`}*/}
                {/*        </label>*/}
                {/*        <label style={{*/}
                {/*          marginLeft: isXSDown ? '14px' : 60,*/}
                {/*          marginRight: isXSDown ? '14px' : 34*/}
                {/*        }}>*/}
                {/*          <input onChange={() => {*/}
                {/*            setOnlyBot(false)*/}
                {/*            setIsPrivate(true)*/}
                {/*          }} type='radio' name='participant'/>*/}
                {/*          <i></i>*/}
                {/*          {` Private`}*/}
                {/*        </label>*/}
                {/*      </div>)}/>*/}
                {/*  {isPrivate && (*/}
                {/*      <Form*/}
                {/*          top={'38px'}*/}
                {/*          addonAfter={(<img onClick={() => {*/}
                {/*            setShowPassword(!showPassword)*/}
                {/*          }} src={showPassword ? icon_eye_open : icon_eye_close}/>)}*/}
                {/*          input={<Input name={'password'} type={!showPassword && 'password'} onChange={handleChange}*/}
                {/*          />} name={'Password'}/>*/}
                {/*  )}*/}

                {/*</Pool.Content>*/}

                <Pool.Divider/>

                <Form top={'38px'} error={''} input={<Input
                    name={'name'}
                    required
                    maxLength={15}
                    onBlur={handleChange}
                />} name={'Pool Name'}/>

                <Form top={'38px'}
                      disabled
                      name={<><HelperLayout
                          content={'it is the time which the highest bid gets confirmation. The confirmation time refreshes for each new bid.'}>
                        {'Bid confirmation time'}
                        <img src={icon_helper}/></HelperLayout></>} hidden
                      prefix={(<div style={{marginTop: 9}}>
                        <Pool.Content width={isXSDown ? '100%' : '480px'}>
                          <Form input={<Input
                              onChange={(e) => {
                                setDays(e.target.value)
                              }}
                              type='number'/>} name={'Days'} width={isXSDown ? '30%' : '132px'}/>
                          <Form input={<Input
                              value={hours}
                              onChange={(e) => {
                                let hours = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                if (hours > 24) {
                                  hours = 24
                                }
                                setHours(hours);
                              }}
                              type='number'/>} name={'Hours'} width={isXSDown ? '30%' : '132px'}/>
                          <Form input={<Input
                              value={minutes}
                              onChange={(e) => {
                                let minutes = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                if (minutes > 60) {
                                  minutes = 60
                                }
                                setMinutes(minutes);
                              }}
                              type='number'/>} name={'Minutes'} width={isXSDown ? '30%' : '132px'}/>
                        </Pool.Content>
                      </div>)}/>

                <Form top={'38px'}
                      disabled
                      name={<><HelperLayout
                          content={`bounce charge 2% fee to pool creator based on the amount of the successfully swapped tokens`}>
                        {`Transaction Fee `}
                        <img src={icon_helper}/>
                      </HelperLayout></>}
                      hidden
                      prefix={(<div
                          style={{width: isXSDown ? '100%' : '480px'}}>
                        <Button type="submit" form="pool-create" style={{marginTop: 12}} black
                                width={isXSDown ? '100%' : '480px'}>Launch</Button>
                        {type === 'dutch-auction-nft' ? <p style={{
                          fontFamily: 'Helvetica Neue',
                          fontSize: 12,
                          lineHeight: '16.8px',
                          color: 'rgba(0, 0, 0, .4)',
                          marginTop: 12,
                          textAlign: "left"
                        }}>
                          Bounce contract does not accept Inflationary and deflationary tokens, please don’t create a
                          pool
                          with
                          special token forms.
                        </p> : ''}
                      </div>)}/>
              </Pool.Content>
            </Pool.Frame>
          </Pool>

          <CreateModal onOK={() => {
            setModalStatus(initStatus)
            history.push('/store/ygift/detail/nft-english-auction')
          }} onDismiss={() => {
            setModalStatus(initStatus)
          }} modalStatus={modalStatus}/>

        </PoolFrame>
      </FormFrame>
  )
}
