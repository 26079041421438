import React, { useState, useEffect } from 'react';
import EANFTAbi from '../web3/abi/bounceEnglishAuctionNFT.json'
import AlpacaERC1155 from '../web3/abi/AlpacaERC1155.json'
import yGift from '../web3/abi/yGift.json'
import AlpacaEnglishAuctionNFT from "../web3/abi/AlpacaEnglishAuctionNFT.json";

import { useActiveWeb3React } from "./index";
import { getContract } from "../web3";
import {
  getBounceAlpacasNFTAddress, getBounceYGiftEAAddress, getYGiftNFTAddress
} from "./contractAddress";
import { getIndexList, queryData } from "./common";
import { englishAuctionKeyMap, getContractLink } from "../Content/const";
import Web3 from "web3";
import axios from "axios";


export const keyMap = [{
  key: 'creatorP',
  name: 'creator',
  needTrans: false,
}, {
  key: 'nameP',
  name: 'name',
  needTrans: false,
}, {
  key: 'token0P',
  name: 'fromAddress',
  needTrans: false,
}, {
  key: 'passwordP',
  name: 'password',
  needTrans: false,
}, {
  key: 'amountTotal0P',
  name: 'fromAmount',
  needTrans: false,
}, {
  key: 'amountMin1P',
  name: 'toAmount',
  needTrans: true,
}, {
  key: 'closeAtP',
  name: 'time',
}, {
  key: 'onlyBotHolder',
  name: 'onlyBot',
  needTrans: false
}, {
  key: 'minEthBidP',
  name: 'minEthPerWallet',
  needTrans: true
}];


export const useEANFTList = () => {
  console.log('load---.')
  const [loading, setLoading] = useState(true)
  const [poolList, setPoolList] = useState()
  const [loaded, setLoaded] = useState(true)

  const { active, account, library, chainId } = useActiveWeb3React();

  const queryPoolItem = async (library, bounceContract, index) => {
    let data = await queryData(bounceContract, 'pools', index);
    console.log('querySBNFTAuctionPoolItem:', data)
    data = JSON.parse(JSON.stringify(data));
    data.index = index;
    englishAuctionKeyMap.forEach(item => {
      data[item.name] = data[item.key]
    })
    try {
      const alpaca = await axios.get(`${chainId === 56 ? 'https://apibsc.alpaca.city/metadata/' : 'https://api.alpaca.city/metadata/'}${data.tokenId}`)
      data.cover = alpaca.data ? alpaca.data.image : ''
    } catch (e) {

    }

    data['fromSymbol'] = 'NFT1155';

    data.type = 'englishAuction';
    let status = 'Closed';
    const isExpired = new Date(data.closeAt * 1000) <= Date.now();
    data.password = await queryData(bounceContract, 'passwordP', index);
    if (!isExpired) {
      status = 'Live';
    } else {
      status = 'Closed';
    }
    data.status = status
    return data;
  };


  useEffect(() => {

    async function queryEANFTPollList() {
      setLoaded(true)
      try {
        let noConnect = {};
        let networkID = 1
        if (!active) {
          const web3 = await new Web3(Web3.givenProvider);
          noConnect.provider = web3.eth.currentProvider
          networkID = await web3.eth.getChainId();
        }
        const curLibrary = active ? library : noConnect
        const bounceContract = getContract(curLibrary, AlpacaEnglishAuctionNFT.abi, getBounceAlpacasNFTAddress(active ? chainId : networkID))
        const poolCount = await bounceContract.methods.getPoolCount().call();
        console.log('store EA NFT poolCount', poolCount)
        let poolIdList = [];
        for (let i = 0; i < poolCount; i++) {
          poolIdList[i] = poolCount - i - 1;
        }

        let i = 0;
        let poolList;
        let queryIdList;
        let allPools = [];

        while (poolIdList.length !== 0) {
          queryIdList = poolIdList.slice(0, 15);
          poolIdList.splice(0, 15);
          poolList = await Promise.all(queryIdList.map(async (item) => {
            const pool = await queryPoolItem(curLibrary, bounceContract, item);
            if (pool.fromAddress) {
              pool.address = await getContractLink(pool.fromAddress)
            }
            console.log('pool', pool)
            pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name


            try {
              const contract = getContract(curLibrary, AlpacaEnglishAuctionNFT.abi, getBounceAlpacasNFTAddress(chainId))
              const address = await contract.methods.currentBidderP(item).call()
              let amount = '0'
              if (address != 0) {
                amount = await contract.methods.myBidderAmount1P(address, item).call()
              }
              pool.amount = amount
              console.log('curren bidden address', amount, item)
            } catch (error) {
              console.log('curren bidden address', error)
            }


            // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
            //     poolIdList.unshift(item)
            // }
            return pool
          }));
          allPools = allPools.concat(poolList)
          setLoading(false)
          console.log('EA POOL LIST', allPools)
          setPoolList(allPools);
          i++
        }
      } catch (e) {
        console.log('query EA nft pool list error', e)
      }

    }

    if (!loaded && active) {
      console.log('load store data')
      queryEANFTPollList()
    }
  }, [loaded, library])



  return { loading, poolList, setLoaded }
}

export const useMyEANFTList = () => {
  console.log('load---.')
  const [loading, setLoading] = useState(true)
  const [poolList, setPoolList] = useState()
  const [loaded, setLoaded] = useState(true)

  const { active, account, library, chainId } = useActiveWeb3React();

  const queryPoolItem = async (library, bounceContract, index) => {
    let data = await queryData(bounceContract, 'pools', index);
    console.log('querySBNFTAuctionPoolItem:', data)
    data = JSON.parse(JSON.stringify(data));
    data.index = index;
    englishAuctionKeyMap.forEach(item => {
      data[item.name] = data[item.key]
    })
    try {
      const alpaca = await axios.get(`${chainId === 56 ? 'https://apibsc.alpaca.city/metadata/' : 'https://api.alpaca.city/metadata/'}${data.tokenId}`)
      data.cover = alpaca.data ? alpaca.data.image : ''
    } catch (e) {

    }

    data['fromSymbol'] = 'NFT1155';

    data.type = 'englishAuction';
    let status = 'Closed';
    const isExpired = new Date(data.closeAt * 1000) <= Date.now();
    data.password = await queryData(bounceContract, 'passwordP', index);
    if (!isExpired) {
      status = 'Live';
    } else {
      status = 'Closed';
    }
    data.status = status
    return data;
  };


  useEffect(() => {

    async function queryEANFTPollList() {
      setLoaded(true)
      try {
        let noConnect = {};
        let networkID = 1
        if (!active) {
          const web3 = await new Web3(Web3.givenProvider);
          noConnect.provider = web3.eth.currentProvider
          networkID = await web3.eth.getChainId();
        }
        const curLibrary = active ? library : noConnect
        const bounceContract = getContract(curLibrary, AlpacaEnglishAuctionNFT.abi, getBounceAlpacasNFTAddress(active ? chainId : networkID))
        const poolCount = await bounceContract.methods.getPoolCount().call();
        console.log('store EA NFT poolCount', poolCount)
        let poolIdList = [];
        for (let i = 0; i < poolCount; i++) {
          poolIdList[i] = poolCount - i - 1;
        }

        let i = 0;
        let poolList;
        let queryIdList;
        let allPools = [];

        while (poolIdList.length !== 0) {
          queryIdList = poolIdList.slice(0, 15);
          poolIdList.splice(0, 15);
          poolList = await Promise.all(queryIdList.map(async (item) => {
            const pool = await queryPoolItem(curLibrary, bounceContract, item);
            if (pool.fromAddress) {
              pool.address = await getContractLink(pool.fromAddress)
            }
            console.log('pool', pool)
            pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name


            try {
              const contract = getContract(curLibrary, AlpacaEnglishAuctionNFT.abi, getBounceAlpacasNFTAddress(chainId))
              const address = await contract.methods.currentBidderP(item).call()
              let amount = '0'
              if (address != 0) {
                amount = await contract.methods.myBidderAmount1P(address, item).call()
              }
              pool.amount = amount
              console.log('curren bidden address', amount, item)
            } catch (error) {
              console.log('curren bidden address', error)
            }


            // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
            //     poolIdList.unshift(item)
            // }
            return pool
          }));

          poolList = poolList.filter(item => {
            return item[0] === account
          })

          allPools = allPools.concat(poolList)
          console.log('EA POOL LIST', allPools)
          i++
        }
        setLoading(false)
        setPoolList(allPools);
      } catch (e) {
        console.log('query EA nft pool list error', e)
      }


    }

    if (!loaded) {
      console.log('load store data')
      queryEANFTPollList()
    }
  }, [loaded, library])



  return { loading, poolList, setLoaded }
}

export const useYGiftEANFTList = () => {
  const [loading, setLoading] = useState(true)
  const [poolList, setPoolList] = useState()
  const [loaded, setLoaded] = useState(true)

  const { active, account, library, chainId } = useActiveWeb3React();

  const queryPoolItem = async (library, bounceContract, index) => {
    let data = await queryData(bounceContract, 'pools', index);
    console.log('querySBNFTAuctionPoolItem:', data)
    data = JSON.parse(JSON.stringify(data));
    data.index = index;
    englishAuctionKeyMap.forEach(item => {
      data[item.name] = data[item.key]
    })
    try {
      const tokenContract = getContract(library, yGift.abi, data.fromAddress)
      const gift = await tokenContract.methods.gifts(data.tokenId).call()
      data.cover = gift.url
    } catch (e) {

    }

    data['fromSymbol'] = 'NFT1155';

    data.type = 'englishAuction';
    let status = 'Closed';
    const isExpired = new Date(data.closeAt * 1000) <= Date.now();
    data.password = await queryData(bounceContract, 'passwordP', index);
    if (!isExpired) {
      status = 'Live';
    } else {
      status = 'Closed';
    }
    data.status = status
    return data;
  };


  useEffect(() => {

    async function queryEANFTPollList() {
      setLoaded(true)
      try {
        let noConnect = {};
        let networkID = 1
        if (!active) {
          const web3 = await new Web3(Web3.givenProvider);
          noConnect.provider = web3.eth.currentProvider
          networkID = await web3.eth.getChainId();
        }
        const curLibrary = active ? library : noConnect
        const bounceContract = getContract(curLibrary, EANFTAbi.abi, getBounceYGiftEAAddress(active ? chainId : networkID))
        const poolCount = await bounceContract.methods.getPoolCount().call();
        console.log('store EA NFT poolCount', poolCount)
        let poolIdList = [];
        for (let i = 0; i < poolCount; i++) {
          poolIdList[i] = poolCount - i - 1;
        }

        let i = 0;
        let poolList;
        let queryIdList;
        let allPools = [];

        while (poolIdList.length !== 0) {
          queryIdList = poolIdList.slice(0, 15);
          poolIdList.splice(0, 15);
          poolList = await Promise.all(queryIdList.map(async (item) => {
            const pool = await queryPoolItem(curLibrary, bounceContract, item);
            if (pool.fromAddress) {
              pool.address = await getContractLink(pool.fromAddress)
            }
            console.log('pool', pool)
            pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name


            try {
              const contract = getContract(curLibrary, EANFTAbi.abi, getBounceYGiftEAAddress(chainId))
              const address = await contract.methods.currentBidderP(item).call()
              let amount = '0'
              if (address != 0) {
                amount = await contract.methods.myBidderAmount1P(address, item).call()
              }
              pool.amount = amount
              console.log('curren bidden address', amount, item)
            } catch (error) {
              console.log('curren bidden address', error)
            }


            // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
            //     poolIdList.unshift(item)
            // }
            return pool
          }));
          allPools = allPools.concat(poolList)
          setLoading(false)
          console.log('EA POOL LIST', allPools)
          setPoolList(allPools);
          i++
        }
      } catch (e) {
        console.log('query EA nft pool list error', e)
      }

    }

    if (!loaded) {
      console.log('load store data')
      queryEANFTPollList()
    }
  }, [loaded, library])



  return { loading, poolList, setLoaded }
}

// export const useMyEANFTList = () => {

//   const [loading, setLoading] = useState(true)
//   const [EANFTPoolList, setEANFTPoolList] = useState()
//   const [loadEANFTed, setLoadEANFTed] = useState(true)
//   const { active, account, library, chainId } = useActiveWeb3React();

//   const queryPoolItem = async (library, bounceContract, index) => {
//     let data = await queryData(bounceContract, 'pools', index);
//     console.log('querySBNFTAuctionPoolItem:', data)
//     data = JSON.parse(JSON.stringify(data));
//     data.index = index;
//     englishAuctionKeyMap.forEach(item => {
//       data[item.name] = data[item.key]
//     })
//     data.cover = `https://d1wo2tfj2enqpq.cloudfront.net/alpacas/svg/${data.tokenId}.svg`
//     data['fromSymbol'] = 'NFT1155';
//     data.type = 'englishAuction';
//     let status = 'Closed';
//     const isExpired = new Date(data.closeAt * 1000) <= Date.now();
//     data.password = await queryData(bounceContract, 'passwordP', index);
//     if (!isExpired) {
//       status = 'Live';
//     } else {
//       status = 'Closed';
//     }
//     data.status = status
//     return data;
//   };


//   useEffect(() => {

//     async function queryEANFTPollList() {
//       setLoadEANFTed(true)
//       let noConnect = {};
//       let networkID = 1
//       if (!active) {
//         const web3 = await new Web3(Web3.givenProvider);
//         noConnect.provider = web3.eth.currentProvider
//         networkID = await web3.eth.getChainId();
//       }
//       const curLibrary = active ? library : noConnect
//       const bounceContract = getContract(curLibrary, EANFTAbi.abi, getBounceAlpacasNFTAddress(active ? chainId : networkID))


//       let poolIdList = [];


//       const config = {
//         headers: {
//           "Access-Control-Allow-Origin": "*",
//           "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
//         },
//       };

//       const res = await axios.post(`https://account.bounce.finance:16000/${chainId === 4 ? 'test/' : ''}api/boudata`,
//         {
//           "method": "query_address_poolindexs",
//           "id": 1,
//           "jsonrpc": "2.0",
//           "params": [account.toLowerCase()]
//         },
//         config);
//       const joinPools = await res.data.result


//       const crateRes = await axios.post(`https://account.bounce.finance:16000/${chainId === 4 ? 'test/' : ''}api/boudata`,
//         {
//           "method": "query_address_createpindexs",
//           "id": 1,
//           "jsonrpc": "2.0",
//           "params": [account.toLowerCase()]
//         },
//         config);
//       const createPools = await crateRes.data.result

//       console.log('useMyEANFTList', createPools)

//       let createPoolsId = createPools.filter(item => {
//         return item.contractid === 4
//       })
//       let joinPoolIds = joinPools.filter(item => {
//         return item.contractid === 4
//       })


//       createPoolsId = createPoolsId.length !== 0 ? createPoolsId[0] : []
//       joinPoolIds = joinPoolIds.length !== 0 ? joinPoolIds[0] : []

//       poolIdList = (createPoolsId.createindex ? createPoolsId.createindex : []).concat(joinPoolIds.joinpoolindex ? joinPoolIds.joinpoolindex : [])
//       poolIdList = poolIdList.filter(item => {
//         return item !== null
//       })

//       let i = 0;
//       let poolList;
//       let queryIdList;
//       let allPools = [];

//       while (poolIdList.length !== 0) {
//         queryIdList = poolIdList.slice(0, 15);
//         poolIdList.splice(0, 15);
//         poolList = await Promise.all(queryIdList.map(async (item) => {
//           const pool = await queryPoolItem(curLibrary, bounceContract, item);
//           if (pool.fromAddress) {
//             pool.address = await getContractLink(pool.fromAddress)
//           }
//           console.log('pool', pool)
//           pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
//           return pool
//         }));
//         allPools = allPools.concat(poolList)
//         setLoading(false)
//         console.log('EA POOL LIST', allPools)
//         setEANFTPoolList(allPools);
//         i++
//       }


//     }

//     if (!loadEANFTed) {
//       queryEANFTPollList()
//     }

//   }, [loadEANFTed, library])

//   return { loading, EANFTPoolList, setLoadEANFTed }
// }



