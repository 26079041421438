import React, {useState, useCallback, useEffect} from 'react';
import {t, tHTML} from '../../utils/intl';
import {TO, getRatio} from '../const';
import {getContract} from '../../components/utils/web3';

export const PoolItem = ({
                           symbol,
                           pool,
                           onEnd,
                           web3
                         }) => {
  const [type, setType] = useState(symbol);
  const [left, setLeft] = useState(null);
  const ratio = parseInt(pool.swap / pool.amount * 100);
  let timer;
  const style = {
    width: `${ratio}%`
  }

  const renderTime = useCallback(() => {
    if (pool.time) {
      const time = pool.time * 1000;
      const date = new Date(time);
      const now = new Date();
      const lefttime = date - now;
      let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
      let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
      let leftm = Math.floor(lefttime / (1000 * 60) % 60);
      let lefts = Math.floor(lefttime / 1000 % 60);
      const left = {
        days: leftd < 0 ? 0 : leftd,
        hours: lefth < 0 ? 0 : lefth,
        minutes: leftm < 0 ? 0 : leftm,
        seconds: lefts < 0 ? 0 : lefts,
      };
      setLeft(left);
      if (left < 0) {
        setLeft(null);
      }
    } else {
      setLeft(null);
    }
  }, [pool, setLeft]);

  const renderLeft = useCallback(() => {
    if (left) {
      return <>
        <span>{left.days}</span>
        {` ${t('create-pool.placeholder.days')} : `}
        <span>{left.hours}</span>
        {` ${t('create-pool.placeholder.hours')} : `}
        <span>{left.minutes}</span>
        {` ${t('create-pool.placeholder.minuts')} : `}<span>{left.seconds}</span>
        {` ${t('create-pool.placeholder.seconds')}`}
      </>
    } else {
      return '';
    }
  }, [left]);

  useEffect(() => {
    timer = setInterval(() => {
      renderTime();
    }, (1000));
    return ()=>{clearInterval(timer)}
  }, [renderTime]);

  const getSymobal = async (web3, pool) => {
    console.log('getSymobal--->',web3,pool)
    if (pool.fromAddress && web3) {
      const contract = await getContract(web3, pool.fromAddress);
      contract.methods.symbol()
          .call()
          .then(r => {
            console.log('symbol result--->',r)
            setType(r);
          })
          .catch(e => {
            console.log(e)
          })
    }
  };

  useEffect(() => {
    getSymobal(web3, pool);
  }, [web3]);

  const renderRatio = useCallback(() => {
    if (pool) {
      return getRatio(pool);
    }
  }, [pool]);

  return (
      <>
        <dl className='define'>
          <dt>{t('pool-item.pool-type')}</dt>
          <dd className='weight-grey'>{t('pool-item.fixed-swap')}</dd>
        </dl>
        <dl className='define'>
          <dt>{t('pool-item.fixed-swap-ratio')}</dt>
          <dt style={{display: 'flex'}}>
            <dd><span className='weight-grey'>1 {TO} = {renderRatio()} {pool.fromSymbol}</span></dd>
          </dt>
        </dl>
        <dl className='define'>
          <dt>{t('pool-item.participant')}</dt>
          <dt style={{display: 'flex'}}>
            <dd><span className='weight-grey'>{pool.password && pool.password != 0 ? 'Private' : pool.onlyBot ? 'BOT holder' : "Public"}</span></dd>
          </dt>
        </dl>
        <dl className='define'>
          <dt>{'Maximum Allocation per wallet'}</dt>
          <dt style={{display: 'flex'}}>
            <dd>
              <span className='weight-grey'>{ pool.maxEthPreWallet === '0'? 'No limits' : `${pool.maxEthPreWallet} ETH`}</span>
            </dd>
          </dt>
        </dl>
        <dl className='define'>
          <dt>{tHTML('pool-item.bounce-level', {'to': TO})}</dt>
          <dd className='define-row'>
            <div className='ratio'>
              <span className='back' style={style}/>
              <span className='text'>{`${pool.swap ? pool.swap : ''} ${TO}`}</span></div>
            {t('pool-item.max-amount')} {pool.amount} {TO}</dd>
        </dl>
        <dl className='define'>
          <dt>{t('pool-item.time-left')}</dt>
          <dd className='define-line'>{renderLeft()}</dd>
        </dl>
      </>
  )
}
