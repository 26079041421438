import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components'

import { Header } from "./Header";
import { ViewMyNFT } from "./ViewMyNFT";

import { useMyFixSwapList } from "../../web3/fixswap";
import { useMySealedList } from "../../web3/sealedBid";
import { useMySBNFTList } from "../../web3/sealedDidFNT";
import { useMyEANFTList } from "../../web3/EAFNT";
import { useMyLotteryErc20PoolList } from "../../web3/LotteryErc20";
import { useMyLotteryNFTList } from "../../web3/lottery";

import { FixedSwap } from "./FixedSwap";
import { SealedBid } from "./SealedBid";
import { Lottery } from "./Lottery";
import { LotteryNFT } from "./LotteryNFT";
import { SealedBidNFT } from "./SealedBidNFT";
import { EnglishAuctionNFT } from "./EnglishAuctionNFT";

import { LoadingPage } from "../../components/common/LoadingPage";

import icon_loading from '../../static/image/icon-loading.svg';
import { Loading } from '../../components/Loading';
import { AccountFrame } from './styled'

// new Account
import { FS_ERC20 } from '../A_poolList//usePage/ERC20'



export const Account = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { poolList, setLoadFSed } = useMyFixSwapList(setIsLoading)
  const { SBPoolList, setLoadSBed } = useMySealedList(setIsLoading)
  const { myLotteryErc20PoolList, setLoadListed } = useMyLotteryErc20PoolList(setIsLoading)
  const { lotteryNFTs, setLoadLotteryNFTed } = useMyLotteryNFTList(setIsLoading)
  const { SBNFTPoolList, setLoadSBNFTed } = useMySBNFTList(setIsLoading)
  const { EANFTPoolList, setLoadEANFTed } = useMyEANFTList(setIsLoading)
  // new Account

  useEffect(() => {
    setLoadFSed(false)
    setLoadSBed(false)
    setLoadListed(false)
    setLoadLotteryNFTed(false)
    setLoadSBNFTed(false)
    setLoadEANFTed(false)
  }, [])

  return (
    <AccountFrame>
      <Header />
      <AccountFrame.Background />
      {isLoading || poolList === undefined ? <LoadingPage top='315px' src={icon_loading} /> :
        <Switch>
          <Route path={`/account/fixed-swap`}>
            <FixedSwap pools={poolList} />
          </Route>
          <Route path={`/account/sealed-bid`}>
            <SealedBid pools={SBPoolList} />
          </Route>
          <Route path={`/account/lottery`}>
            <Lottery pools={myLotteryErc20PoolList} />
          </Route>
          <Route path={`/account/lottery-nft`}>
            <LotteryNFT pools={lotteryNFTs} />
          </Route>
          <Route path={`/account/english-auction-nft`}>
            <EnglishAuctionNFT pools={EANFTPoolList} />
          </Route>
          <Route path={`/account/sealed-bid-nft`}>
            <SealedBidNFT pools={SBNFTPoolList} />
          </Route>
          <Route path={`/account/view-my-nft`}>
            <ViewMyNFT pools={[]} />
          </Route>
        </Switch>}

    </AccountFrame>
  )
}
