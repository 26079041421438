import React, {useState, useCallback, useEffect} from 'react';
import {t, tHTML} from '../../utils/intl';
import {weiToNumber} from '../../utils/numberTransform';
import {
    loadRatio,
    queryNFTCurTime,
    queryNFTCurPrice,
    queryNFTBiddenPrice,
    queryNFTCurBiddenAddress,
    queryNFT1155CurTime,
    queryNFT1155CurBiddenAddress,
    queryNFT1155CurPrice,
    queryNFT1155BiddenPrice
} from '../const'
import rectLogo from '../../static/image/rect-logo.svg'

import {TO} from "../const";

const BigNumber = require('bignumber.js');


export const NFTPoolItem = ({
                                is1155,
                                symbol,
                                pool,
                                onEnd,
                                closed,
                                onRoundChange,
                                web3
                            }) => {
    const [left, setLeft] = useState(null);
    const [leftReducer, setLeftReducer] = useState(null);
    const [curTime, setCurTime] = useState();
    const [curPrice, setCurPrice] = useState();
    const [coverHover, setCoverHover] = useState(false);


    const ratio = parseInt(pool.biddenAmount / pool.tokenTotal * 100);
    const style = {
        width: `${ratio}%`
    }


    let timer;
    let reducerTimer;


    const renderTime = useCallback(() => {
        if (pool.time) {
            const time = pool.time * 1000;
            const date = new Date(time);
            const now = new Date();
            const lefttime = date - now;
            let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
            let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
            let leftm = Math.floor(lefttime / (1000 * 60) % 60);
            let lefts = Math.floor(lefttime / 1000 % 60);
            const left = {
                days: leftd < 0 ? 0 : leftd,
                hours: lefth < 0 ? 0 : lefth,
                minutes: leftm < 0 ? 0 : leftm,
                seconds: lefts < 0 ? 0 : lefts,
            };
            setLeft(left);
            if (left < 0) {
                onRoundChange();
                setLeft(null);
            }
        } else {
            setLeft(null);
        }
    }, [pool, setLeft, left]);


    const renderNextTime = useCallback(() => {
        if (curTime !== 0) {
            const time = curTime;
            let lefth = Math.floor(time / (60 * 60) % 24);
            let leftm = Math.floor(time / (60) % 60);
            let lefts = Math.floor(time % 60);
            console.log('setLeftReducer', lefth, leftm, lefts)
            const left = {
                hours: lefth < 0 ? 0 : lefth,
                minutes: leftm < 0 ? 0 : leftm,
                seconds: lefts < 0 ? 0 : lefts,
            };
            setLeftReducer(left);
            if (left < 0) {
                setLeftReducer(null);
            }
        } else {
            setLeft(null);
        }
    }, [curTime, setLeftReducer, leftReducer]);

    const renderLeftReducer = useCallback(() => {
        console.log('leftReducer', leftReducer)
        if (leftReducer) {
            return <>
                {leftReducer.hours}
                {` H : `}
                {leftReducer.minutes}
                {` min : `}
                {leftReducer.seconds}
                {` sec `}
            </>
        } else {
            return '';
        }
    }, [leftReducer]);

    useEffect(() => {
        timer = setInterval(() => {
            renderTime();
        }, (1000));
        return () => {
            clearInterval(timer)
        }
    }, [renderTime]);

    useEffect(() => {
        if (web3 && pool.index && !closed) {
            reducerTimer = setInterval(() => {
                const time = curTime - 1;
                if (time === 0) {
                    onRoundChange()
                    setCurTime(curTime - 1);
                    if(is1155){
                        queryNFT1155CurTime(web3, pool.index)
                            .then(r => {
                                setCurTime(r)
                            })
                        queryNFT1155CurBiddenAddress(web3, pool.index)
                            .then(address => {
                                console.log('pool item bidden address', address)
                                if (address == 0) {
                                    queryNFT1155CurPrice(web3, pool.index)
                                        .then(r => {
                                            console.log('queryNFTCurPrice', r)
                                            setCurPrice(r)
                                        });
                                } else {
                                    queryNFT1155BiddenPrice(web3, pool.index)
                                        .then(r => {
                                            console.log('queryNFTBiddenPrice', r)
                                            setCurPrice(r)
                                        });
                                }
                            })
                    }else {
                        queryNFTCurTime(web3, pool.index)
                            .then(r => {
                                setCurTime(r)
                            })
                        queryNFTCurBiddenAddress(web3, pool.index)
                            .then(address => {
                                console.log('pool item bidden address', address)
                                if (address == 0) {
                                    queryNFTCurPrice(web3, pool.index)
                                        .then(r => {
                                            console.log('queryNFTCurPrice', r)
                                            setCurPrice(r)
                                        });
                                } else {
                                    queryNFTBiddenPrice(web3, pool.index)
                                        .then(r => {
                                            console.log('queryNFTBiddenPrice', r)
                                            setCurPrice(r)
                                        });
                                }
                            })
                    }


                    clearInterval(reducerTimer)
                } else {
                    setCurTime(curTime - 1)
                    renderNextTime();
                }

            }, (1000));
        }

        return () => {
            clearInterval(reducerTimer)
        }
    }, [renderNextTime, curTime, curPrice, pool, closed]);


    useEffect(() => {
        if (web3 && pool.index) {
            if(is1155){
                queryNFT1155CurTime(web3, pool.index)
                    .then(r => {
                        setCurTime(r)
                        console.log('queryDutchCurTime', r)
                    })
                queryNFT1155CurBiddenAddress(web3, pool.index)
                    .then(address => {
                        console.log('pool item bidden address', address)
                        if (address == 0) {
                            queryNFT1155CurPrice(web3, pool.index)
                                .then(r => {
                                    console.log('queryNFTCurPrice', r)
                                    setCurPrice(r)
                                });
                        } else {
                            queryNFT1155BiddenPrice(web3, pool.index)
                                .then(r => {
                                    console.log('queryNFTBiddenPrice', r)
                                    setCurPrice(r)
                                });
                        }
                    })
            }else {
                queryNFTCurTime(web3, pool.index)
                    .then(r => {
                        setCurTime(r)
                        console.log('queryDutchCurTime', r)
                    })
                queryNFTCurBiddenAddress(web3, pool.index)
                    .then(address => {
                        console.log('pool item bidden address', address)
                        if (address == 0) {
                            queryNFTCurPrice(web3, pool.index)
                                .then(r => {
                                    console.log('queryNFTCurPrice', r)
                                    setCurPrice(r)
                                });
                        } else {
                            queryNFTBiddenPrice(web3, pool.index)
                                .then(r => {
                                    console.log('queryNFTBiddenPrice', r)
                                    setCurPrice(r)
                                });
                        }
                    })
            }

        }
    }, [pool]);

    console.log('pool.minEthPerWallet', pool.minEthPerWallet)
    return (
        <div className='nft-item'>
            <div className='cover-box'
                 onMouseLeave={() => {
                     if(pool.cover){
                         setCoverHover(false)
                     }
                 }}
                 onMouseEnter={() => {
                     if(pool.cover){
                         setCoverHover(true)
                     }
                 }}>
                <img className='nft-cover' src={pool.cover ? pool.cover : rectLogo}/>
                <a target='_Blank' href={pool.cover} style={{display: coverHover? 'flex':'none'}} className='cover-mask'>{'Click to see the original content of the NFT'}</a>
            </div>
            <div className='nft-content'>
                <dl className='define'>
                    <dt>{t('pool-item.pool-type')}</dt>
                    <dd>{'NFT dutch auction'}</dd>
                </dl>

                {/*<dl className='define'>*/}
                {/*  <dt>{t('pool-item.participant')}</dt>*/}
                {/*  <dt style={{display: 'flex'}}>*/}
                {/*    <dd><span*/}
                {/*        className='weight-grey'>{pool.password && pool.password != 0 ? 'Private' : pool.onlyBot ? 'BOT holder' : "Public"}</span>*/}
                {/*    </dd>*/}
                {/*  </dt>*/}
                {/*</dl>*/}


                <dl className='define'>
                    <dt>{'Starting price:'}</dt>
                    <dt style={{display: 'flex'}}>
                        <dd><span>{`1 ${pool && pool.fromSymbol} = ${pool && web3.utils.fromWei(pool.maxPrice)} ETH`}</span>
                        </dd>
                    </dt>
                </dl>

                <dl className='define'>
                    <dt>{'Reserve price:'}</dt>
                    <dt style={{display: 'flex'}}>
                        <dd><span>{`1 ${pool && pool.fromSymbol} = ${web3.utils.fromWei(pool && pool.minPrice)} ETH`}</span>
                        </dd>
                    </dt>
                </dl>

                <dl className='define'>
                    <dt>{'Current auction price'}</dt>
                    <dt style={{display: 'flex', alignItems: 'center'}}>
                        <span>{curPrice && `${weiToNumber(curPrice)} ETH`}</span>
                        <dd style={{marginLeft: 20}}
                        ><span className='weight-grey' style={{flexDirection: 'row',padding:0, background: '#fff'}}>
              {renderLeftReducer()}
              </span>
                        </dd>
                    </dt>
                </dl>
            </div>
        </div>
    )
}
