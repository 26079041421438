import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import xiahua from '../../static/image/xiahua.svg'

const AccountHeader = styled.div`
  background-color: #121212;
  width: 100%;
  display: flex;
`
// const AuctionFrame = styled.div`
//   width: 1280px;
//   display: flex;
//   flex-wrap: nowrap;
//   height: 91px;
//   align-items: center;
//   margin: auto;
//   justify-content: left;
//   border-bottom: 1px solid rgba(255, 255, 255, 0.4);
//   overflow-x:scroll;
//   @media (max-width: 767px) {
//     width: 100%;
//     overflow-x:scroll;
//   }
// `

const AuctionFrame = styled.div`
  width: 1280px;
  height: 91px;
  line-height: 91px;
  line-height: 91px;
  margin: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  overflow-y: scroll;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    overflow-x:scroll;
  }

  img{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 35px;
    right: 20px;
    user-select: none;
  }
`

const Auction = styled(Link)`
  display: inline-block;
  height: 100%;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 25px;
  line-height: 25px;
  padding: 0 9px;
  margin-right: 32px;
  background-color: ${({ active, theme }) => (active ? theme.white : '')};
  color: ${({ active, theme }) => (active ? theme.black : theme.white)};
  cursor: pointer;

  &:hover{
    opacity: ${({ active }) => (active ? 1 : 0.7)};
  };
  @media (max-width: 767px) {
    white-space:nowrap;
  }
`

// const Auction = styled(Link)`
//   display: inline-block;
//   font-family: 'Helvetica Neue';
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   height: 25px;
//   line-height: 25px;
//   padding: 2px 9px;
//   margin-right: 32px;
//   background-color: ${({ active, theme }) => (active ? theme.white : '')};
//   color: ${({ active, theme }) => (active ? theme.black : theme.white)};
//   cursor: pointer;

//   &:hover{
//     opacity: ${({ active }) => (active ? 1 : 0.7)};
//   };
//   @media (max-width: 767px) {
//     white-space:nowrap;
//   }
// `

export const Header = () => {
  const [auction, setAuction] = useState()
  const path = window.location.pathname
  return (
    <AccountHeader>
      <AuctionFrame>
        <Auction onClick={() => { setAuction(0) }} active={path === '/account/fixed-swap' || auction === 0} to='/account/fixed-swap'>Fixed Swap</Auction>
        <Auction onClick={() => { setAuction(1) }} active={path === '/account/sealed-bid' || auction === 1} to='/account/sealed-bid'>Sealed Bid</Auction>
        <Auction onClick={() => { setAuction(2) }} active={path === '/account/lottery' || auction === 2} to='/account/lottery'>Lottery</Auction>
        <Auction onClick={() => { setAuction(3) }} active={path === '/account/lottery-nft' || auction === 3} to='/account/lottery-nft'>NFT Lottery</Auction>
        <Auction onClick={() => { setAuction(4) }} active={path === '/account/sealed-bid-nft' || auction === 4} to='/account/sealed-bid-nft'>NFT Sealed bid</Auction>
        <Auction onClick={() => { setAuction(5) }} active={path === '/account/english-auction-nft' || auction === 5} to='/account/english-auction-nft'>NFT English Auction</Auction>
        <Auction onClick={() => { setAuction(6) }} active={path === '/account/view-my-nft' || auction === 6} to='/account/view-my-nft'>View My NFT</Auction>
        <img src={xiahua} alt="" />
      </AuctionFrame>
    </AccountHeader>
  )
}


