import React from 'react';
import { t, tHTML } from '../../utils/intl';
import failedIcon from '../../static/image/error.svg';

export const ClaimFailed = ({
  onClose,
}) => {
  return (
    <div className='content-box'>
      <img src={failedIcon} alt='failed' />
      {tHTML('message.fail-message')}
      <span className='button' onClick={onClose}>{t('buttons.try-again')}</span>
    </div>
  )
}