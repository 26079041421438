import React, { useState, useEffect } from "react";
import { StakingFrame } from "../../components/Staking";
import axios from 'axios'
import { useRequestUrl, useRequestParams } from './useRequest'
import { OText1, OText2, OSubTitle, OText4, OText5 } from "../../components/common/Layout";
import Bignumber from 'bignumber.js'
import { useActivePlatform } from "../../web3";
import { useIsXSDown } from '../../components/utils/themeHooks';

function Overview() {
    const { Psymbol } = useActivePlatform()
    const URL = useRequestUrl()
    const params = useRequestParams('query_eth_info');
    const isXSDown = useIsXSDown();

    const [data, setData] = useState({
        number: '--',
        // numberRose: '16%',
        volume: '--',
        // volumeRose: '16%',
        pool: '--',
        // poolRose: '16%',
    })
    useEffect(() => {
        if (URL === null) return
        try {
            axios.post(URL, params).then(res => {
                res = res.data.result[0]
                setData({
                    ...data,
                    number: addComma(res.txcount),
                    volume: addComma(new Bignumber(res.totalamount).toFixed(2)),
                    pool: addComma(6012)
                })
            })
        } catch (error) {

        }
    }, [URL])

    const addComma = (num) => {
        const str = String(num)
        let index = str.indexOf('.')
        const arr = []
        let s_1 = null
        let s_2 = null
        if (index === -1) {
            // 整数
            index = str.length
        }

        if (index <= 3) return str

        while (index > 3) {
            s_2 = s_2 || str
            s_1 = s_2.substr(index - 3)
            s_2 = s_2.substr(0, index - 3)
            arr.unshift(s_1)
            index = index - 3
        }

        arr.unshift(s_2)
        // console.log('O_console', arr.join(','))
        return arr.join(',')
    }

    return (
        <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: isXSDown ? 'column' : 'row' }}>
            <StakingFrame padding={'44px'} width={isXSDown ? '100%' : '460px'} style={{ flexDirection: 'column', marginBottom: isXSDown ? '10px' : '0' }}>
                <div>
                    <OText5>Total transactions</OText5><br />
                    <OText1 style={{ display: "block", marginTop: isXSDown ? '30px' : '132px' }}>{data.number}</OText1>
                    {/* <OTip style={{ display: "block", marginTop: '20px' }}>↑ {data.numberRose} Per Month</OTip> */}
                </div>
            </StakingFrame>
            <StakingFrame width={isXSDown ? '100%' : '772px'} style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                <StakingFrame padding={'44px 44px 20px'} height={'194px'} style={{ flexDirection: 'row', marginBottom: isXSDown ? '10px' : '0' }}>
                    <div>
                        <OText5>Total volume</OText5><br />
                        <OText2 style={{ display: "block", marginTop: 30 }}>{data.volume} <OText4>{Psymbol}</OText4></OText2>
                        {/* <OTip>↑ {data.volumeRose} Per Week</OTip> */}
                    </div>
                </StakingFrame>

                <StakingFrame padding={'44px 44px 20px'} height={'194px'} style={{ flexDirection: 'row' }}>
                    <div>
                        <OText5>Total pools</OText5><br />
                        <OSubTitle>across all types of auctions</OSubTitle>
                        <OText2 style={{ display: "block", marginTop: 18 }}>{data.pool}</OText2>
                        {/* <OTip>↑ {data.poolRose} Per Week</OTip> */}
                    </div>
                </StakingFrame>
            </StakingFrame>
        </StakingFrame>
    )
}

export default Overview