import React, {useState, useCallback, useEffect} from 'react';
import {t} from '../../utils/intl';
import {
  TO, BOUNCE_ERC20_ADDRESS, getContractLink, queryReward, queryWithdraw, queryStake, ZERO_ADDRESS,
  queryBotRewardsPerCycle, LIQUIDITY_POOL_ADDRESS, queryTotalLPStaked, queryLPTokenBalance, queryLPStakedAmount, queryHarvestCapacity
} from '../const';
import {
  getLiquidity,
  getLPToken
} from '../../components/utils/web3';
import {weiToNumber} from '../../utils/numberTransform'
import close_icon from '../../static/image/close.svg'
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from "@material-ui/core/styles/index";
import {isGreaterThan} from "../../utils/common";

const useStyles = makeStyles(() => ({
  root: {
    width: 480,
    margin: 'auto',
    position: 'absolute',
    borderRadius: 10,
    border: '1px solid #000000',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.12)',
    padding: 20,
    top: '20%',
    left: 0,
    right: 0
  },
}));


export const UniswapForm = ({
                              account,
                              symbol,
                              poolIndex,
                              onStatusChange,
                              onHashChange,
                              onAmountChange,
                              onSuccessContentChange,
                              onLoadingStatusChange,
                              onFailedContentChange,
                              web3
                            }) => {
  const [hash, setHash] = useState('');
  const [hasError, setHasError] = useState(false);
  const [address, setAddress] = useState('');
  const [tab, setTab] = useState(0);
  const [rewardCount, setRewardCount] = useState('0');
  const [unStackAmount, setUnStackAmount] = useState('');
  const [stakeCount, setStakeCount] = useState('0');
  const [balance, setBalance] = useState('0');
  const [totalLPTokenStaked, setTotalLPTokenStaked] = useState('0');
  const [botRewardsPerCycle, setBotRewardsPerCycle] = useState('0');
  const [inputModal, setInputModal] = useState(false)
  const [inputAmount, setInputAmount] = useState();
  const [inputError, setInputError] = useState();
  const [actionType, setActionType] = useState(0);
  const [botAddress, setBotAddress] = useState('');




  const BN = web3.utils.BN;
  const classes = useStyles();


  useEffect(() => {
    async function loadInitData() {
      onLoadingStatusChange(true)
      const link = await getContractLink(hash);
      setAddress(link);
      const balance = await queryLPTokenBalance(web3, account);
      setBalance(balance);
      console.log('Balance:', weiToNumber(balance))

      const reward = await queryHarvestCapacity(web3, account);
      setRewardCount(reward);
      console.log('reward--->', weiToNumber(reward))

      const stake = await queryLPStakedAmount(web3, account);
      setStakeCount(stake)
      console.log('staked amount:',weiToNumber(stake))

      const totalLPStaked = await queryTotalLPStaked(web3);
      setTotalLPTokenStaked(totalLPStaked)
      console.log('staked amount:',weiToNumber(totalLPStaked))

      const  botRewardsPerCycle = await queryBotRewardsPerCycle(web3);
      setBotRewardsPerCycle(botRewardsPerCycle)
      console.log('staked amount:',weiToNumber(botRewardsPerCycle))

      const address = await BOUNCE_ERC20_ADDRESS(web3);
      setBotAddress(address)
      onLoadingStatusChange(false)

    }

    loadInitData()
  }, []);


  const handleStack = async () => {
    console.log('start stake:')
    if (!inputAmount) {
      return;
    }
    setInputModal(false)
    const stack = await getLiquidity(web3);
    const lpERC20 = await getLPToken(web3);
    const weiAmount = web3.utils.toWei(inputAmount, 'ether');
    console.log(`weiTo: ${weiAmount}`);
    onStatusChange('waiting');
    try {
      const result = await lpERC20.methods.approve(await LIQUIDITY_POOL_ADDRESS(web3), weiAmount).send({from: account});
      console.log('weiAmount:', account, weiAmount)
      if (result.status) {
        await stack.methods.farming(weiAmount)
            .send({from: account})
            .then(r => {
              onAmountChange(inputAmount);
              console.log('stake success', r)
              onSuccessContentChange(t('stake.success.content-stake', {amount: inputAmount, symbol: 'LP'}));
              onStatusChange('success');
              onHashChange(r.transactionHash);
            })
        // .on('transactionHash', hash => {
        //   console.log('transactionHash---->')
        //   //onStatusChange('waiting')
        //   onHashChange(hash);
        // })
        // .on('confirmation', (_, receipt) => {
        //   console.log('confirmation---->')
        //   onStatusChange('success');
        // })
        // .on('error', (err, receipt) => {
        //   onStatusChange('failed');
        //   console.log('error1', err);
        // })
      }
    } catch (err) {
      if (err.code === 4001) {
        onStatusChange('denied');
      } else {
        onFailedContentChange(t('stake.fail.stake'))
        onStatusChange('failed');
      }
      console.log('err', err);
    }
  }

  const handleUnStack = async () => {
    if (!inputAmount) {
      return;
    }
    setInputModal(false)
    console.log('handleUnStack--->', unStackAmount)
    const stack = await getLiquidity(web3);
    const weiAmount = web3.utils.toWei(inputAmount, 'ether');
    console.log(`weiTo: ${weiAmount}`);
    onStatusChange('waiting');
    try {
      //onChangeAmount(amount, to.toFixed(4));
      console.log('unStaking weiAmount--->', account, weiAmount)
      await stack.methods.unfarming(weiAmount)
          .send({from: account})
          .then(r => {
            console.log('unstake success')
            onAmountChange(inputAmount);
            onSuccessContentChange(t('stake.success.content-un-stake', {amount: inputAmount, symbol: 'LP'}));
            onStatusChange('success');
            onHashChange(r.transactionHash);
          })
      // .on('transactionHash', hash => {
      //   onStatusChange('pending')
      //   //onChangeHash(hash);
      // })
      // .on('confirmation', (_, receipt) => {
      //   onStatusChange('success');
      //   onStatusChange('finish');
      //   //onReceiptChange(receipt);
      // })
      // .on('error', (err, receipt) => {
      //   onStatusChange('failed');
      //   console.log('error1', err);
      //   //onReceiptChange(receipt);
      // })
    } catch (err) {
      if (err.code === 4001) {
        onStatusChange('denied');
      } else {
        onFailedContentChange(t('stake.fail.un-stake'))
        onStatusChange('failed');
      }
      console.log('err', err);
    }
  }


  const handleClaimReward = async () => {
    const stack = await getLiquidity(web3);
    onStatusChange('waiting');
    try {
      //onChangeAmount(amount, to.toFixed(4));
      await stack.methods.harvest()
          .send({from: account})
          .then(r => {
            onHashChange(r.transactionHash);
            onSuccessContentChange(t('stake.success.content-reward'));
            onStatusChange('success');
          })
    } catch (err) {
      if (err.code === 4001) {
        onStatusChange('denied');
      } else {
        onFailedContentChange(t('stake.fail.claim-reward'))
        onStatusChange('failed');
      }
      console.log('err', err);
    }
  }


  return (
      <>
        <ul className='form-head stake-title'>
          <li>{'Uniswap'}</li>
        </ul>
        <form className='form-content' style={{paddingBottom: 20}}>
          <div className="weight-grey" style={{display: 'flex', flexDirection: 'row'}}>
            <div className="item" style={{flex: 1}}><span>Total LP tokens staked:</span> {`${weiToNumber(totalLPTokenStaked)} LPT`}</div>
            <div className="item" style={{flex: 1}}><span>Total network rewards per cycle:</span> {`${weiToNumber(botRewardsPerCycle)} BOT`}</div>

          </div>

          <div className="weight-grey" style={{display: 'flex', flexDirection: 'row',paddingTop:20,paddingBottom: 40}}>
            <div className="item" style={{flex: 1.2}}><span>Your balance:</span> {`${weiToNumber(balance)} LPT`}</div>
            <div className="item" style={{flex: 2}}><span>Your currently staked:</span> {`${weiToNumber(stakeCount)} LPT`}</div>
            <div className="item" style={{flex: 2}}><span>Your rewards estimation:</span> {`${weiToNumber(rewardCount)} BOT`}</div>

          </div>
          <div className="stake-button-layout bottom" style={{position: 'static'}}>
            <span className={`stake-button ${balance == 0 ? 'button-unable' : ''}`}
                  onClick={_=>{
                    setActionType(0);
                    setInputAmount();
                    setInputModal(true)
                  }}>{'Stake LPT'}</span>
            <span style={{width: 40}}></span>
            <span className={`stake-button`}
                  onClick={handleClaimReward}>{t('stake.claim-reward')}</span>

          </div>
          <div className="stake-button-layout bottom" style={{position: 'static', marginTop: 20}}>
            <span className={`stake-button`}
                  onClick={_=>{
                    setActionType(1);
                    setInputAmount();
                    setInputModal(true)
                  }}>{t('Unstake LPT')}</span>
          </div>

          <a target='_blank' href={`https://app.uniswap.org/#/add/ETH/${botAddress}`} style={{textAlign: 'center',marginTop:20}}>Add liquidity to uniswap’s BOT/ETH pool -></a>
        </form>

        <Modal open={inputModal}>
          <Card className={classes.root}>
            <CardHeader
                action={
                  <img className="modal-close" src={close_icon} alt='close' onClick={_ => {
                    setInputModal(false)
                  }}/>
                }
            />
            <CardContent>
              <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
              }}>

                    <span className="modal-title">{actionType === 0? 'Stake LPT': 'Unstake LPT'}</span>
              </div>
              {/*<Typography style={{textAlign:'center'}}>*/}
              {/*{'Enter the amount of BOT you want to stake'}*/}
              {/*</Typography>*/}

              <dl className='password'>
                <dt style={{display: 'flex'}}>
                  {'Amount'}
                  <span style={{flex: 1, textAlign: 'right'}}>
                    {`${t('balance')}: ${actionType === 0 ?web3.utils.fromWei(balance): web3.utils.fromWei(stakeCount)} LPT`}
                    </span>
                  </dt>
                <dd style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                  <input
                      placeholder={`Enter the amount of LPT you want to ${actionType === 0?'stake': 'unstake'}`}
                      onChange={(e) => {
                        let amount = e.target.value.replace(/[^\d.]/g, '');
                        if(actionType === 0){
                          amount = isGreaterThan(amount, web3.utils.fromWei(balance)) ? web3.utils.fromWei(balance) : amount;
                        }else if (actionType === 1){
                          amount = isGreaterThan(amount, web3.utils.fromWei(stakeCount)) ? web3.utils.fromWei(stakeCount) : amount;
                        }
                        setInputAmount(amount)
                      }} value={inputAmount}/>
                  <span onClick={() => {
                    if(actionType === 0){
                      setInputAmount(web3.utils.fromWei(balance))
                    }else {
                      setInputAmount(web3.utils.fromWei(stakeCount))
                    }
                  }} className="balance-max">MAX</span>
                </dd>
                {inputError ? <dd className='error'>{inputError.content}</dd> : null}
                {/*<span className='weight-grey'>Transaction fee:ETH</span>*/}
              </dl>

              <span className='dark-button modal-button' onClick={() => {
                if(actionType === 0){
                  handleStack()
                }else if(actionType === 1){
                  handleUnStack()
                }
              }}>{'Go'}</span>
            </CardContent>
          </Card>
        </Modal>
      </>
  )
}
