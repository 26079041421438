import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Address, LayoutFrame, Pool } from "../../../components/common/Layout";
import icon_return from "../../../static/image/icon_return_light.svg";
import icon_search from "../../../static/image/icon_search_light.svg";
import { Select, Select1, Select3, SelectFrame } from "../../../components/common/Select";
import { useIsXSDown } from '../../../components/utils/themeHooks';
import { HANDLE_SHOW_CONNECT_MODAL } from "../../../const";
import { Button } from "../../../components/common/Button";
import { useActiveWeb3React } from "../../../web3";
import { myContext } from "../../../reducer";
import { PoolTypeModal } from "../../../components/common/PoolTypeMpdal";

const AccountHeader = styled.div`
  background-color: #121212;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const AuctionFrame = styled.div`
  width: 1280px;
  display: flex;
  height: 91px;
  align-items: center;
  margin: auto;
  justify-content: left;
  @media (max-width: 767px) {
    width: 100%;
    overflow-x:scroll;
  }
`

const Auction = styled(Link)`
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 25px;
  line-height: 25px;
  padding: 2px 9px;
  margin-right: 32px;
  background-color: ${({ active, theme }) => (active ? theme.white : '')};
  color: ${({ active, theme }) => (active ? theme.black : theme.white)};
  cursor: pointer;

  &:hover{
    opacity: ${({ active }) => (active ? 1 : 0.7)};
  };
  @media (max-width: 767px) {
    white-space:nowrap;
  }
`
export const FilterFrame = styled.div`
  width: 1280px;
  height: 84px;
  border-bottom: 2px solid rgba(255,255,255,0.6);
  border-top: 2px solid rgba(255,255,255,0.6);
  font-family: Optima;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  display: flex;
  align-items: center;
  .search_title{
      cursor: pointer;
      &:hover{
          opacity: .7;
      }
  }
  @media (max-width: 767px) {
    width: 100%;
  } 
`
export const SearchInput = styled.input`
  width: 18px;
  margin: 24px;
  flex: 1;
  margin-left: 10px;
  border: none;
  color: #FFF;
  &::placeholder{
    font-family: Optima;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255,255,255, 0.4);
  }
`
export const SearchIcon = styled.img`
  width: 18px;
  margin: 24px;
`

const CreateButton = styled(Button)`
  background-color: #121212;
  color: #FFF;
  border: 1px solid #FFFFFF;
box-sizing: border-box;
  @media (max-width: 767px) {
    display:none;
  }
`

export const Website = styled.a`
height: fit-content;
font-family: IBM Plex Mono;
font-style: normal;
font-weight: normal;
width: fit-content;
font-size: 14px;
line-height: 18px;
margin: auto;
color: ${({ theme }) => (theme.grey4)};
text-align: center;
cursor: pointer;
margin-top: 16px;
/* margin-bottom: 40px; */
&:hover{
    color: ${({ theme }) => (theme.black)};
};
@media (max-width: 767px) {
  margin-top: 0px;
}

`


export const DetailHeader = ({ title, website, onSearchChange, onQueryStatusChange, options, setPoolType, poolTypeArr }) => {
  const history = useHistory()
  const { id } = useParams()
  const { active, library } = useActiveWeb3React()
  const { state, dispatch } = useContext(myContext);
  const [auction, setAuction] = useState(1)
  const [showSelectModal, setShowSelectModal] = useState(false)
  const isXSDown = useIsXSDown();
  const [poolTypes, setPoolTypes] = useState([])

  useEffect(() => {
    if (!poolTypeArr) return

    const getValue = (index) => {
      switch (index) {
        case 0:
          return 'fixed-swap'
        case 1:
          return 'fixed-swap-nft'
        case 2:
          return 'english-auction-nft'
        default:
          break;
      }
    }

    const tempArr = poolTypeArr.map((item, index) => {
      return {
        key: index,
        value: getValue(item)
      }
    })

    console.log('G_console', tempArr)
    setPoolTypes(tempArr)



    // setPoolTypes()
  }, [poolTypeArr])

  const renderSelect = () => {
    return <Select
      white
      width={'220px'}
      extra={'Pool Type:'}
      defaultValue={{ key: 0, value: 'fixed-swap-nft' }}
      onSelect={item => {
        setPoolType(item.key)
        history.push(`/store/custom/${item.value}/${id}`);
      }}
      defaultContent={'types of auctions'}
      options={poolTypes} />
  }

  return (
    <AccountHeader>

      <LayoutFrame width={'1181px'}
        style={{ padding: '40px 0', margin: 'auto', marginTop: 32, backgroundColor: '#121212' }}>
        <Pool.Return src={icon_return} onClick={() => {
          history.push('/store')
        }} />
        <Pool.Mode style={{ color: '#FFF' }}>Store</Pool.Mode>
        <Pool.Header
          style={{ justifyContent: 'center', color: '#FFF' }}><span>{title}</span></Pool.Header>
        <Website style={{
          wordBreak: isXSDown ? 'break-all' : 'normal',
          marginTop: 16,
          color: '#FFF'
        }}>{website}</Website>
      </LayoutFrame>

      <AuctionFrame>
        <CreateButton
          width='160px'
          onClick={() => {
            if (active) {
              setShowSelectModal(true)
              // history.push(createURL)
            } else {
              dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
            }
          }} style={{ marginLeft: 'auto', marginRight: 0 }} width={'160px'}>Create
            auction</CreateButton>
      </AuctionFrame>
      {
        (auction === 0 || auction === 1) &&
        <FilterFrame>
          <div className='search_title' style={{ display: "flex", alignItems: "center", color: '#fff' }}>
            <SearchIcon src={icon_search} />
            Search by
          </div>
          <SearchInput onChange={(e) => {
            onSearchChange(e.target.value)
          }} placeholder={'Pool ID, Pool Name'} />
          <SelectFrame.Divider />
          {renderSelect()}
          <SelectFrame.Divider style={{ backgroundColor: 'rgba(255,255,255,0.4)' }} />
          <Select
            white
            theme={'white'}
            width={'148px'}
            onSelect={item => {
              onQueryStatusChange(item.value)
            }}
            extra={'Status:'}
            defaultContent={'types of auctions'}
            defaultValue={{ key: 0, value: 'All' }}
            options={[
              { key: 0, value: 'All' },
              { key: 1, value: 'Live' },
              { key: 2, value: 'Filled' },
              { key: 3, value: 'Closed' },
            ]} />
          <SelectFrame.Divider style={{ backgroundColor: 'rgba(255,255,255,0.4)', marginRight: 0 }} />
        </FilterFrame>
      }

      <PoolTypeModal
        options={options}
        onConfirm={(poolType) => {
          if (!poolType) {
            return
          }
          console.log('F_console')
          history.push(poolType.createUrl)

        }} show={showSelectModal} onDismiss={() => {
          setShowSelectModal(false)
        }} />
    </AccountHeader>
  )
}


