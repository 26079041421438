import React,{useContext, useState, useEffect} from "react";
import MobileModal, {ModalContent, ModalTitle} from "./MobileModal";
import { myContext } from '../../reducer';
import { HANDLE_SHOW_MENU_MODAL } from '../../const';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { rgb } from 'polished';

const Breadcrumb = styled.a`
  font-family: 'Optima';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  margin-left: 45px;
  margin-top: 30px;
  color: ${({ theme }) => theme.black};
  opacity: ${({ active }) => active ? 1 : 0.2};
  cursor: pointer;
  align-items: self-start;
  &:hover{
    color: ${({ active, theme }) => active ? theme.black : rgb(101, 101, 101)};;
    opacity: 1;
  };
`
const Nav = styled.div`
  display:flex;
  flex-direction: column;
  width:100vw;
  padding-top:10vh;
  align-items: baseline;

`


export const MenuModal = ({
  modalStatus,
  onDismiss,
  onOK
}) =>{
  const { state, dispatch } = useContext(myContext);
  const history = useHistory();
  const [crumb, setCrumb] = useState();
  const path = history.location.pathname;


  return (
    <MobileModal isOpen={state.showMenuModal} onDismiss={() => {
        dispatch({ type: HANDLE_SHOW_MENU_MODAL, showMenuModal: false });
      }}
      >
        <Nav>
          <Breadcrumb active={path === '/' || path === '' || crumb === 0} onClick={() => {
            setCrumb(0)
            dispatch({ type: HANDLE_SHOW_MENU_MODAL, showMenuModal: false });
            history.push('/')
          }}>/Auctions</Breadcrumb>
          <Breadcrumb active={path === '/earning' || crumb === 1} onClick={() => {
            setCrumb(1)
            dispatch({ type: HANDLE_SHOW_MENU_MODAL, showMenuModal: false });
            history.push('/earning')
          }}>/Earning</Breadcrumb>

          <Breadcrumb active={path === '/statistic' || crumb === 3} onClick={() => {
            setCrumb(3)
            dispatch({ type: HANDLE_SHOW_MENU_MODAL, showMenuModal: false });
            history.push('/statistic')
          }}>/Statistic</Breadcrumb>

          <Breadcrumb active={path === '/governance' || crumb === 2} onClick={() => {
            setCrumb(2)
            dispatch({ type: HANDLE_SHOW_MENU_MODAL, showMenuModal: false });
            history.push('/governance')
          }}>/Governance</Breadcrumb>

          <Breadcrumb onClick={() => {
            setCrumb(4)
            dispatch({ type: HANDLE_SHOW_MENU_MODAL, showMenuModal: false });
            history.push('/account/fixed-swap')
          }} active={path.indexOf('/account')!==-1 || crumb === 4}>/Account</Breadcrumb>
        </Nav>
    </MobileModal>
    )
}