import { useEffect, useState } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import { getSealedBidNFTAddress } from "../../../web3/contractAddress";

import { queryPoolItem_pools, queryERC20Token, queryERC721Token, queryERC1155Token } from './Request_CT'
import { getNFTPoolStatus, getParticipant, getPoolType, getNFTPrice } from './Request_FUN'

import BounceSBNFT from '../../../web3/abi/bounceSealedBidNFT.json'


export const useSB_List_ERC_NFT = (queryListArr = []) => {
    const { active, library, chainId } = useActiveWeb3React()
    const [poolsDate, setPoolsDate] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    // const noConnect = new Web3(new Web3.providers.HttpProvider("https://api.infura.io/v1/jsonrpc/mainnet")).currentProvider
    // console.log('A_console_noConnect', noConnect)
    useEffect(() => {
        if (!active || !queryListArr) return
        setIsLoad(true)
        queryPoolDate(queryListArr)
    }, [active, queryListArr, chainId])

    const queryPoolDate = async (queryListArr) => {

        const SB_ERC_NFT_CT = getContract(library, BounceSBNFT.abi, getSealedBidNFTAddress(chainId))

        const dataList = await Promise.all(queryListArr.map(async (poolID) => {
            const poolItemInfo = await queryPoolItem_pools(SB_ERC_NFT_CT, poolID)
            // poolItemInfo.token1P = await SB_ERC_NFT_CT.methods.token1P(poolID).call()
            poolItemInfo.onlyBot = await SB_ERC_NFT_CT.methods.onlyBotHolderP(poolID).call()
            poolItemInfo.password = await SB_ERC_NFT_CT.methods.passwordP(poolID).call()

            // 通过获取到的池子信息筛选出列表需要的信息
            const poolItemData = {
                poolID: poolItemInfo.poolID,
                status: getNFTPoolStatus(poolItemInfo.closeAt, poolItemInfo.swappedP),
                poolName: poolItemInfo.name,
                creator: poolItemInfo.creator,
                progress: '100%',
                nftType: getPoolType(poolItemInfo.nftType),
                toToken: await queryERC20Token(library, '0x00', chainId),
                Participant: getParticipant(poolItemInfo.password, poolItemInfo.onlyBot),
            }
            poolItemData.fromToken = poolItemData.nftType === 'ERC721' ? await queryERC721Token(library, poolItemInfo.token0, poolItemInfo.tokenId) : await queryERC1155Token(library, poolItemInfo.token0, poolItemInfo.tokenId)
            poolItemData.price = getNFTPrice(poolItemInfo.amountMin1, poolItemData.toToken.decimals)

            return poolItemData
        }))
        setPoolsDate(dataList)
        setIsLoad(false)
    }

    return { poolsDate, isLoad }
}
