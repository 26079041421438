import React, {useState, useCallback, useEffect, useContext} from 'react';
import {Pool, PoolFrame} from "../../components/common/Layout";
import icon_close from '../../static/image/icon-close.svg'
import {Form, Input} from "../../components/common/Form";
import {useParams} from 'react-router-dom';
import {useHandleForm} from '../../web3/common'
import {Button} from "../../components/common/Button";
import {ZERO_ADDRESS} from "../../const";
import Web3 from 'web3'
import {useHistory} from 'react-router-dom'
import {formatAddress, getTime} from "../../utils/common";
import {getContract, useActiveWeb3React} from "../../web3";
import yGift from "../../web3/abi/yGift.json";

import {
  getBounceYGiftFSAddress, getYGiftNFTAddress,
} from "../../web3/contractAddress";
import BounceFSNFT from "../../web3/abi/BounceFSNFT.json";
import {useIsXSDown} from '../../components/utils/themeHooks';

import {
  CreateModal,
  initStatus,
  approveStatus,
  pendingStatus,
  confirmStatus,
  successStatus,
  errorStatus,
  cancelStatus
} from "../../components/common/CreateModal";
import {PoolCover} from "../../components/DetailCover";

const {toWei} = Web3.utils


export const CreateYGiftFSNFTPool = () => {
  const history = useHistory()
  const {type} = useParams();
  console.log('create type :', type)
  const isXSDown = useIsXSDown();
  const {active, account, library, chainId} = useActiveWeb3React();
  const [modalStatus, setModalStatus] = useState(initStatus)
  const [cover, setCover] = useState()
  const [price, setPrice] = useState()
  const [idError, setIDError] = useState()
  const [name, setName] = useState()
  const [days, setDays] = useState()
  const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  const [nftID, setNFTID] = useState()





  const {
    timeError,
  } = useHandleForm()

  const handleChange = async event => {
    event.preventDefault();
    const {name, value} = event.target;

    switch (name) {
      case "nftID":
        setNFTID(value)
        setIDError(false)
        setCover(null)
        try {

          const contract = getContract(library, yGift.abi, getYGiftNFTAddress(chainId))
          const owner = await contract.methods.ownerOf(value).call()
          if(owner.toLowerCase() !== account.toLowerCase()){
            setIDError(`Token ID is invalid, owner is ${formatAddress(owner)}`)
          }else {
            contract.methods.gifts(value).call().then(uriResult => {
              setCover(uriResult.url)
            })
          }
        } catch (e) {
          console.log('query nft error', e)
          setIDError("Token ID is invalid")
        }
        break
      case "name":
        setName(value)
        break;
      case 'price':
        const price = value.replace(/[^\d.]/g, '')
        setPrice(price)
        break

      case 'days':
        let days = value.replace(/^0(0+)|[^\d]+/g, '')
        if (days > 20) {
          days = 20
        }
        setDays(days)
        break
      case 'hours':
        let hours = value.replace(/^0(0+)|[^\d]+/g, '')
        if (hours > 24) {
          hours = 24
        }
        setHours(hours)
        break
      case 'minutes':
        let minutes = value.replace(/^0(0+)|[^\d]+/g, '')
        if (minutes > 60) {
          minutes = 60
        }
        setMinutes(minutes)
        break
      default:
    }

  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    const abi = BounceFSNFT.abi;
    const bounceAddress = getBounceYGiftFSAddress(chainId)
    const tokenContract = getContract(library, yGift.abi, getYGiftNFTAddress(chainId))
    const bounceContract = getContract(library, abi, bounceAddress)
    const time = getTime(days, hours, minutes);
    setModalStatus(approveStatus);
    try {
      const result = await tokenContract.methods.approve(
          bounceAddress,
          nftID,
      )
          .send({from: account});
      setModalStatus(confirmStatus);

      if (result.status) {
        await bounceContract.methods.createErc721(
            name,
            getYGiftNFTAddress(chainId),
            ZERO_ADDRESS,
            nftID,
            toWei(price),
            time,
            false,
            0
        )
            .send({from: account})
            .on('transactionHash', hash => {
              setModalStatus(pendingStatus)
            })
            .on('receipt', (_, receipt) => {
              setModalStatus(successStatus)
            })
            .on('error', (err, receipt) => {
              setModalStatus(errorStatus)
            })
      } else {
        setModalStatus(errorStatus)
      }
    } catch (err) {
      if (err.code === 4001) {
        setModalStatus(cancelStatus)
      } else {
        setModalStatus(errorStatus)
      }
      console.log('err', err);
    }
  };


  return (
      <form id='pool-create' onSubmit={handleSubmit}>
        <PoolFrame style={{marginTop: 32, padding: isXSDown ? '40px 20px' : '40px 100px'}}>
          <Pool.Close onClick={() => {
            history.push('/store/detail/nft-fixed-swap')
          }} src={icon_close}/>
          <Pool>
            <Pool.Mode style={{textAlign: 'left'}}>Initial Token Offering</Pool.Mode>
            <Pool.Header style={{
              justifyContent: 'flex-start',
              borderBottom: '4px #000 solid',
              fontSize: isXSDown ? '26px' : '36px',
            }}>Create a Fixed-Swap NFT Pool</Pool.Header>
            <Pool.Frame style={{
              flexDirection: isXSDown ? 'column' : 'initial',
            }}>
              <div width={isXSDown ? '100%' : '480px'} style={{height: '100%'}}>
                Contract information
              </div>
              <Pool.Content width={isXSDown ? '100%' : '480px'}>
                <Form top={'38px'} error={idError} input={<Input
                    required
                    name={'nftID'}
                    onBlur={handleChange}/>} name={'ERC-721 Token ID'}/>

              </Pool.Content>
            </Pool.Frame>

            <Pool.Divider/>

            <Pool.Frame style={{
              flexDirection: isXSDown ? 'column' : 'initial',
            }}>
              <div style={{width: isXSDown ? '100%' : 480, height: '100%'}}>
                Pool settings
                <Pool.Content width={isXSDown ? '100%' : '512px'} style={{marginTop: 48}}>
                  <PoolCover cover={cover}/>
                </Pool.Content>


              </div>
              <Pool.Content width={isXSDown ? '100%' : '480px'}>

                <Form top={'38px'} input={<Input
                    required
                    name={'price'}
                    type='number'
                    value={price}
                    // onBlur={() => {
                    //   if (type === 'sealed-bid' && amount) {
                    //     console.log('limit--->', new BigNumber(new BigNumber(amount).dividedBy(ratio).dividedBy(1000).toFixed(2, BigNumber.ROUND_UP)).toNumber().toString())
                    //     setLimit(new BigNumber(new BigNumber(amount).dividedBy(ratio).dividedBy(1000).toFixed(2, BigNumber.ROUND_UP)).toNumber().toString())
                    //   }
                    // }}
                    onChange={handleChange}
                />}
                      name='Fixed-Swap Price'
                      suffix={'ETH'}/>


                <Form top={'38px'} input={<Input
                    required
                    name={'name'}
                    maxLength={15}
                    onBlur={handleChange}
                />} name={'Pool Name'}/>

                <Form top={'49px'} error={timeError}
                      name={<span style={{color: '#000', marginBottom: 9}}>Pool running time</span>} hidden
                      prefix={(<div>
                        <Pool.Content width={isXSDown ? '100%' : '480px'}>
                          <Form input={<Input
                              name={'days'}
                              onChange={handleChange}
                              type='number'/>} name={'Days'} width={isXSDown ? '30%' : '132px'}/>
                          <Form input={<Input
                              name={'hours'}
                              value={hours}
                              onChange={handleChange}
                              type='number'/>} name={'Hours'} width={isXSDown ? '30%' : '132px'}/>
                          <Form input={<Input
                              name={'minutes'}
                              value={minutes}
                              onChange={handleChange}
                              type='number'/>} name={'Minutes'} width={isXSDown ? '30%' : '132px'}/>
                        </Pool.Content>
                      </div>)}/>

                <Form top={'38px'} disabled hidden prefix={(<div
                    style={{width: isXSDown ? '100%' : '480px'}}>
                  <Button type="submit" form="pool-create" style={{marginTop: 12}} black width={isXSDown ? '100%' : '480px'}>Launch</Button>
                  {type === 'dutch-auction-nft' ? <p style={{
                    fontFamily: 'Helvetica Neue',
                    fontSize: 12,
                    lineHeight: '16.8px',
                    color: 'rgba(0, 0, 0, .4)',
                    marginTop: 12,
                    textAlign: "left"
                  }}>
                    Bounce contract does not accept Inflationary and deflationary tokens, please don’t create a pool
                    with
                    special token forms.
                  </p> : ''}
                </div>)}/>
              </Pool.Content>
            </Pool.Frame>
          </Pool>

          <CreateModal onOK={() => {
            setModalStatus(initStatus)
            history.push('/store/ygift/detail/nft-fixed-swap')
          }} onDismiss={() => {
            setModalStatus(initStatus)
          }} modalStatus={modalStatus}/>

        </PoolFrame>
      </form>
  )
}
