import React,{useContext, useState, useEffect} from "react";
import MobileModal, {ModalContent, ModalTitle} from "./MobileModal";
import { myContext } from '../../reducer';
import { HANDLE_SHOW_USER_DETAIL } from '../../const';
import styled from 'styled-components';
import { rgb } from 'polished';
import icon_eth_logo from '../../static/image/icon-eth-logo.svg';
import { weiToNumber } from "../../utils/numberTransform";
import { useTokenBalance } from "../../web3/common";
import { formatAddress } from '../../utils/common';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useActiveWeb3React } from "../../web3";
import icon_copy from "../../static/image/icon-copy.svg";

const Breadcrumb = styled.a`
  font-family: 'Optima';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  margin-left: 45px;
  margin-top: 30px;
  color: ${({ theme }) => theme.black};
  opacity: ${({ active }) => active ? 1 : 0.2};
  cursor: pointer;
  align-items: self-start;
  &:hover{
    color: ${({ active, theme }) => active ? theme.black : rgb(101, 101, 101)};;
    opacity: 1;
  };
`
const ETHLogo = styled.img`
  width: 80px;
  height: 80px;
  margin-top: 24px;
`
const BotBalance = styled.span`
  font-family: 'Optima';
  font-style: normal;
  font-weight: bold;
  height: 40px;
  box-sizing: border-box;
  line-height: 36px;
  font-size: 30px;
  margin-top:15px;
`
const Chain = styled.span`
  font-family: Optima;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  margin-top:16px;
`

const Address = styled.span`
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  margin-top: 12px;
`
const CopyIcon = styled.img`
  width: 16px;
  margin-left: 7px;
  z-index: 1;
  opacity: 0.2;
  &:hover{
    opacity: 1;
  }
`
export const LineDivider = styled.div`
  height: 1px;
  background-color: #000;
  width: 100vw;
  opacity: 0.1;
`


export const UserDetailModal = ({
  modalStatus,
  onDismiss,
  onOK
}) =>{
  const { state, dispatch } = useContext(myContext);

  const { balance } = useTokenBalance()
  const { active, account } = useActiveWeb3React();

  return (
    <MobileModal isOpen={state.showUserDetail} onDismiss={() => {
        dispatch({ type: HANDLE_SHOW_USER_DETAIL, showUserDetail: false });
      }}
      >
      <ETHLogo src={icon_eth_logo}/>
      <Chain>Ethereum</Chain>
      <Address>
        {formatAddress(account)}
      <CopyToClipboard
          text={account}
          onCopy={() => {

          }}>
          <CopyIcon
          onClick={(e) => {
              e.stopPropagation();
          }}
          src={icon_copy} />
      </CopyToClipboard>
      </Address>
      <BotBalance>{balance ? `${weiToNumber(balance)} BOT` : ''} </BotBalance>
      <LineDivider/>
    </MobileModal>
    )
}