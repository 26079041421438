import React from 'react';
import {t} from '../../utils/intl';
import sucIcon from '../../static/image/success.svg';

export const StakeSuccess = ({
                               onClose,
                               amount,
                               link,
                               content
                             }) => {
  return (
      <div className='content-box'>
        <img src={sucIcon} alt='success'/>
        <p>{t('stake.success.title')}</p>
        <p>{content}</p>
        <a className='link' href={link} target='_blank' rel="noopener noreferrer">{t('view-on-etherscan')}</a>
        <span className='button' onClick={onClose}>{t('buttons.close')}</span>
      </div>
  )
}
