import React, { useState } from 'react';
import Web3 from 'web3';
import { useHistory, useParams } from 'react-router-dom';
import { Pool, PoolFrame } from '../../components/common/Layout';
import icon_close from '../../static/image/icon-close.svg';
import icon_eth from '../../static/image/icon-eth-logo.svg'
import icon_helper from '../../static/image/icon-helper.svg'
import { Form, Input } from "../../components/common/Form";
import { Select } from "../../components/common/Select";
import { useActivePlatform, useActiveWeb3React } from '../../web3';
import { useHandleForm, useTokenList } from '../../web3/common';
import { getContract } from '../../web3';
import { HelperLayout } from "../../components/common/HelperLayout";
import { Button } from "../../components/common/Button";
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import lotteryErc20ABI from '../../web3/abi/BounceLotteryERC20.json';
import { getLotteryErc20Address } from '../../web3/contractAddress';
import {
    CreateModal,
    initStatus,
    approveStatus,
    pendingStatus,
    confirmStatus,
    successStatus,
    errorStatus,
    cancelStatus
} from "../../components/common/CreateModal";
import { getTime, isGreaterThan } from '../../utils/common';
import { weiToNumber, numToWei } from '../../utils/numberTransform';
import { ZERO_ADDRESS } from '../../Content/const';
import { useIsXSDown } from '../../components/utils/themeHooks';

const BigNumber = require('bignumber.js');
// const { toWei, BN } = Web3.utils;


export const CreateLotteryErc20Pool = () => {
    const history = useHistory();
    const { type } = useParams();
    const isXSDown = useIsXSDown();
    const { active, account, library, chainId } = useActiveWeb3React();
    const [modalStatus, setModalStatus] = useState(initStatus)

    const [share, setShare] = useState();
    const [participant, setParticipant] = useState();
    const [toAmount, setToAmount] = useState();
    // const { Psymbol } = useActivePlatform()

    const tokenOptions = useTokenList()
    const [selectedToken, setSelectedToken] = useState(tokenOptions[0])

    const {
        address, setAddress, addressError,
        token,
        amount, setAmount, amountError, setAmountError,
        name, setName, nameError,
        days, setDays,
        hours, setHours,
        minutes, setMinutes,
        timeError,
        check, setCheck,
    } = useHandleForm();

    const onLaunch = async () => {
        setCheck(!check)
        const LotteryAddress = getLotteryErc20Address(chainId);
        const contract = getContract(library, bounceERC20.abi, address);
        const lotteryContract = getContract(library, lotteryErc20ABI.abi, LotteryAddress);
        const time = getTime(days, hours, minutes);
        setModalStatus(approveStatus);
        try {
            const result = await contract.methods.approve(
                LotteryAddress,
                numToWei(amount, token.decimals),
            )
                .send({ from: account })
            setModalStatus(confirmStatus);
            if (result.status) {
                await lotteryContract.methods.create(
                    name,
                    address,
                    numToWei(amount, token.decimals), // usdt
                    selectedToken.key,
                    numToWei(toAmount, selectedToken.decimals),   // bot
                    participant,
                    share,
                    time,
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            } else {
                setModalStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    }

    const wrapperSpace = (str) => {
        if (!str) return false
        if (String(str).trim().length === 0) return false
        return true
    }

    return (
        <PoolFrame style={{ marginTop: 32, padding: isXSDown ? '40px 20px' : '40px 100px' }}>
            <Pool.Close onClick={() => {
                history.goBack();
            }} src={icon_close} />
            <Pool>
                <Pool.Mode style={{ textAlign: 'left' }}>Lotteries</Pool.Mode>
                <Pool.Header style={{
                    justifyContent: 'flex-start',
                    borderBottom: '4px #000 solid',
                    fontSize: isXSDown ? '26px' : '36px',
                }}>Create a ERC 20 Lottery </Pool.Header>
                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div width={isXSDown ? '100%' : '560px'} style={{ height: '100%' }}>
                        Contract information
                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '560px'}>
                        <Form top={'38px'} width={isXSDown ? '100%' : '560px'} style={{ marginBottom: 38 }}
                            error={addressError} input={<Input
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                }} />} name={'Token Contract address'} />
                        <Pool.Content style={{ marginTop: 38 }} width={'560px'}>
                            <Form disabled input={<Input disabled value={token.symbol} />} name={'Token Symbol'}
                                width={isXSDown ? '100%' : '260px'} />
                            <Form disabled input={<Input disabled value={token.decimals} />} name={'Token decimals'}
                                width={isXSDown ? '100%' : '260px'} />
                        </Pool.Content>
                    </Pool.Content>
                </Pool.Frame>
                <Pool.Divider />
                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div style={{ width: isXSDown ? '100%' : 560, height: '100%' }}>
                        Pool settings
                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '560px'}>
                        <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '560px'}>
                            <Form disabled input={<Input disabled value={token.symbol} />} name={'From'}
                                width={isXSDown ? '100%' : '260px'} />
                            {/* <Form disabled input={<Input disabled value={Psymbol} style={{ paddingLeft: 24 }} />} name={'To'}
                                width={isXSDown ? '100%' : '260px'}
                                addonBefore={(<img src={icon_eth} />)} /> */}
                            <Form name={'To'}
                                width={isXSDown ? '100%' : '260px'}
                                input={<Select
                                    // disabled
                                    width={isXSDown ? '100%' : '260px'}
                                    options={tokenOptions}
                                    onSelect={(value) => {
                                        setSelectedToken(value)
                                    }}
                                    defaultValue={tokenOptions[0]} />} />
                        </Pool.Content>
                        <Pool.Content width={isXSDown ? '100%' : '560px'}>
                            <Form top={'38px'} width={isXSDown ? '100%' : '560px'} style={{ marginBottom: 38 }}
                                error={amountError}
                                input={<Input
                                    type='number'
                                    value={amount}
                                    onChange={e => {
                                        setAmount(e.target.value.replace(/[^\d]+/g, ''));

                                    }}
                                    onBlur={(e) => {
                                        if (amount && token.balance && isGreaterThan(numToWei(amount, token.decimals), token.balance)) {
                                            // setAmount(weiToNumber(token.balance))
                                            setAmount(weiToNumber(token.balance))
                                        }
                                    }} />} name={'Lottery Tickets'}
                                suffix={`${token.symbol ? token.symbol : ''} Per Ticket`} />

                            <Form top={'38px'} width={isXSDown ? '100%' : '560px'} style={{ marginBottom: 38 }}
                                input={<Input
                                    type='number'
                                    onChange={(e) => {
                                        setToAmount(e.target.value)
                                    }} />}
                                prefix={'Ticket Price ='}
                                suffix={selectedToken.symbol}
                                name={<> <HelperLayout
                                    content={`this is ticket price, not price per token`}> {`Price `}<img
                                        src={icon_helper} /></HelperLayout></>} />
                            <Form top={'38px'} width={isXSDown ? '100%' : '560px'} style={{ marginBottom: 38 }}
                                input={<Input
                                    type='number'
                                    value={share}
                                    onChange={e => {
                                        setShare(e.target.value.replace(/[^\d]+/g, ''))
                                    }}
                                    onBlur={() => {
                                        if (participant && isGreaterThan(share, participant)) {
                                            setShare(participant);
                                        }
                                    }} />} name={'Number of Winners'} />
                            <Form top={'38px'} width={isXSDown ? '100%' : '560px'} style={{ marginBottom: 38 }}
                                input={<Input
                                    type='number'
                                    value={participant}
                                    onChange={(e) => {
                                        setParticipant(e.target.value.replace(/[^\d]+/g, ''))
                                    }} />} name={'Max Participant Allowed'} />
                        </Pool.Content>
                        <Pool.Divider />
                        <Pool.Content>
                            <Form top={'38px'} width={isXSDown ? '100%' : '560px'} style={{ marginBottom: 38 }}
                                error={nameError}
                                input={<Input
                                    maxLength={15}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }} />} name={'Pool Name'} />
                            <Form top={'49px'} error={timeError}
                                name={<span style={{ color: '#000', marginBottom: 9 }}>Lottery running time</span>}
                                hidden
                                prefix={(<div>
                                    <Pool.Content width={isXSDown ? '100%' : '560px'}>
                                        <Form input={<Input
                                            onChange={(e) => {
                                                setDays(e.target.value)
                                            }}
                                            type='number' />} name={'Days'} width={'160px'} />
                                        <Form input={<Input
                                            value={hours}
                                            onChange={(e) => {
                                                let hours = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                                if (hours > 24) {
                                                    hours = 24
                                                }
                                                setHours(hours);
                                            }}
                                            type='number' />} name={'Hours'} width={'160px'} />
                                        <Form input={<Input
                                            value={minutes}
                                            onChange={(e) => {
                                                let minutes = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                                if (minutes > 60) {
                                                    minutes = 60
                                                }
                                                setMinutes(minutes);
                                            }}
                                            type='number' />} name={'Minutes'} width={'160px'} />
                                    </Pool.Content>
                                </div>)} />
                            <Form top={'38px'} disabled name={<><HelperLayout
                                content={`bounce charge ${type === 'fixed-swap' ? '1%' : '1.5%'} fee to pool creator based on the amount of the successfully swapped tokens`}> {`Transaction Fee `}<img
                                    src={icon_helper} /></HelperLayout></>} hidden prefix={(<div
                                        style={{ width: isXSDown ? '100%' : '560px' }}>
                                        <Button
                                            disabled={wrapperSpace(name) && wrapperSpace(amount) && wrapperSpace(toAmount) && wrapperSpace(share) && wrapperSpace(participant) && (wrapperSpace(days) || wrapperSpace(hours) || wrapperSpace(minutes)) ? false : true}
                                            black width={isXSDown ? '100%' : '560px'} style={{ marginTop: 20 }} onClick={() => {
                                                onLaunch()
                                            }}>Launch</Button>
                                    </div>)} />
                        </Pool.Content>
                    </Pool.Content>
                </Pool.Frame>
            </Pool>
            <CreateModal onOK={() => {
                setModalStatus(initStatus)
                history.goBack()
            }} onDismiss={() => {
                setModalStatus(initStatus)
            }} modalStatus={modalStatus} />
        </PoolFrame>
    )
}