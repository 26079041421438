import React, { useState, useEffect } from 'react';
import { Pool, PoolFrame } from "../../components/common/Layout";
import icon_close from '../../static/image/icon-close.svg'
import { Form, Input } from "../../components/common/Form";
import icon_max from '../../static/image/icon-max.svg'

import icon_eye_open from '../../static/image/icon-eye-open.svg'
import icon_eye_close from '../../static/image/icon-eye-close.svg'
import { useHandleForm, useTokenList } from '../../web3/common'
import { Button } from "../../components/common/Button";
import { numToWei, weiDiv, weiToNum } from "../../utils/numberTransform";
import md5 from "js-md5";
import Web3 from 'web3'
import { useHistory } from 'react-router-dom'
import { getTime, isEqualTo, isGreaterThan } from "../../utils/common";
import { getContract, useActiveWeb3React } from "../../web3";
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import { getFixSwapAddress } from "../../web3/contractAddress";
import fixSwap from "../../web3/abi/bounce.json";
import icon_helper from '../../static/image/icon-helper.svg';
import { useIsXSDown } from '../../components/utils/themeHooks';
import {
    CreateModal,
    initStatus,
    approveStatus,
    pendingStatus,
    confirmStatus,
    successStatus,
    errorStatus,
    cancelStatus
} from "../../components/common/CreateModal";
import { HelperLayout } from "../../components/common/HelperLayout";
import { Select } from "../../components/common/Select";
import { AuctionTipModal } from "../../components/common/AuctionTipModal";
import Modal from "../../components/common/Modal";
import { validateForm } from "../../utils/form";
import { isAddress } from "../../utils/address";
import { useMyFSPoolStatus } from "../../hooks/poolStatus";
import { PoolErrorModal } from "../../components/modal/PoolErrorModal";
import { red } from '@material-ui/core/colors';

const BigNumber = require('bignumber.js');
const { toWei, BN } = Web3.utils


export const CreateFSPool = () => {
    const history = useHistory()
    const isXSDown = useIsXSDown();
    const { account, library, chainId, active } = useActiveWeb3React()
    const { isLive, poolIndex } = useMyFSPoolStatus()
    const tokenOptions = useTokenList()
    const [modalStatus, setModalStatus] = useState(initStatus)

    const [isPrivate, setIsPrivate] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [hasLimit, setHasLimit] = useState(false)
    const [selectedToken, setSelectedToken] = useState(tokenOptions[0])
    const [showTip, setShowTip] = useState(false)


    const [address, setAddress] = useState()

    const [symbol, setSymbol] = useState()

    const [balance, setBalance] = useState()
    const [decimals, setDecimals] = useState('18')
    const [ratio, setRatio] = useState()
    const [amount, setAmount] = useState()
    const [level, setLevel] = useState()
    const [limit, setLimit] = useState()

    const [errors, setErrors] = useState({ address: '', ratio: '', amount: '', limit: '' })

    const {
        name, setName, nameError,
        onlyBot, setOnlyBot,
        password, setPassword,
        days, setDays,
        hours, setHours,
        minutes, setMinutes,
        timeError,
    } = useHandleForm()

    useEffect(() => {
        if (amount && ratio) {
            setLevel(weiDiv(amount, ratio))
        }
    }, [amount, ratio])


    const handleChange = async event => {
        event.preventDefault();
        const { name, value } = event.target;
        switch (name) {
            case "address":
                errors.address = ''
                setAddress(value)
                if (!isAddress(value)) {
                    errors.address = "Address is invalid"
                } else {
                    const tokenContract = getContract(library, bounceERC20.abi, value)
                    try {
                        setSymbol('Loading...')
                        const symbol = await tokenContract.methods.symbol().call()
                        setSymbol(symbol)
                        console.log('symbol:', symbol)
                    } catch (e) {
                        errors.address = 'Address is invalid'
                        console.log('get token symbol error:', e)
                    }
                    console.log('address error:')

                    try {
                        console.log('tokenContract:', tokenContract)
                        const balance = await tokenContract.methods.balanceOf(account).call()
                        if (!balance || isEqualTo(balance, '0')) {
                            errors.address = 'you have 0 balance under this token contract address'
                        }
                        setBalance(balance)
                        console.log('balance:', balance)
                        const decimals = await tokenContract.methods.decimals().call()
                        setDecimals(decimals)
                        console.log('decimals:', decimals)
                    } catch (e) {
                        console.log('getTokenInfo error:', e)
                    }
                }
                try {

                } catch (e) {
                    console.log('confirm token', e)
                    errors.tokenId = "Token ID is invalid"
                }
                break;
            case "ratio":
                errors.ratio = ''
                const ratioValue = value.replace(/[^\d.]/g, '')
                setRatio(ratioValue)
                break
            case "amount":
                errors.amount = ''
                const amountValue = value.replace(/[^\d.]/g, '')
                setAmount(amountValue)
                console.log('wei amount', numToWei(amountValue, decimals))
                if (!balance || (balance && isGreaterThan(numToWei(amountValue, decimals), balance))) {
                    errors.amount = 'you do not have enough balance'
                }
                break
            case "limit":
                errors.limit = ''
                const limitValue = value.replace(/[^\d.]/g, '')
                setLimit(limitValue)
                if (!level || (level && isGreaterThan(limitValue, level))) {
                    errors.limit = 'You need to enter a number that is smaller than your Bounce level'
                }
                break
            default:
        }
        console.log('final errors', errors)
        setErrors(errors)
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('handleSubmit', event)
        if (validateForm(errors)) {
            setShowTip(true)
        }
    }


    const onFSCreate = async () => {
        console.log('limit:', limit)
        const abi = fixSwap.abi;
        const bounceAddress = getFixSwapAddress(chainId)
        const createFunc = 'fixPoolCreateV3'

        const tokenContract = getContract(library, bounceERC20.abi, address)
        const bounceContract = getContract(library, abi, bounceAddress)

        const fromAmount = numToWei(amount, decimals);
        const toAmount = numToWei((new BigNumber(amount).div(new BigNumber(ratio))), selectedToken.decimals)
        const time = getTime(days, hours, minutes);
        setModalStatus(approveStatus);
        try {
            const result = await tokenContract.methods.approve(
                bounceAddress,
                fromAmount,
            )
                .send({ from: account });
            setModalStatus(confirmStatus);
            if (result.status) {
                await bounceContract.methods[createFunc](
                    name,
                    address,
                    selectedToken.key,
                    fromAmount,
                    toAmount,
                    time,
                    onlyBot,
                    (!password || password === '') ? 0 : new BN(md5(password)).toString(),
                    hasLimit ? (toWei(limit)) : 0
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        window.localStorage.setItem('BOUNCE_POOL_AUCTION', '1')
                        window.localStorage.setItem('BOUNCE_POOL_TYPE', '0')
                        window.localStorage.setItem('BOUNCE_SALE_TYPE', '0')
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            } else {
                setModalStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    };


    return (
        <form id="fs-form" onSubmit={handleSubmit}>
            <PoolFrame style={{ marginTop: 32, padding: isXSDown ? '40px 20px' : '40px 100px' }}>
                <Pool.Close onClick={() => {
                    history.goBack()
                }} src={icon_close} />
                <Pool>
                    <Pool.Mode style={{ textAlign: 'left' }}>Initial Token Offering</Pool.Mode>
                    <Pool.Header style={{
                        justifyContent: 'space-between',
                        borderBottom: '4px #000 solid',
                        fontSize: isXSDown ? '26px' : '36px',
                    }}>{'Create a Fixed-swap Pool'}
                        <Button
                            height={'40px'}
                            width={'184px'}
                            onClick={() => {
                                window.open('https://docs.bounce.finance', '_blank')
                            }}
                        >How to Create a pool</Button>
                    </Pool.Header>
                    <Pool.Frame style={{
                        flexDirection: isXSDown ? 'column' : 'initial',
                    }}>
                        <div width={isXSDown ? '100%' : '480px'} style={{ height: '100%' }}>
                            Contract information
                        </div>
                        <Pool.Content width={isXSDown ? '100%' : '480px'}>
                            {/*address form*/}
                            <Form error={errors.address}
                                name={'Token Contract address'}
                                input={<Input
                                    required
                                    value={address}
                                    name='address'
                                    onChange={handleChange} />}
                            />
                        </Pool.Content>
                    </Pool.Frame>

                    <Pool.Divider />

                    <Pool.Frame style={{
                        flexDirection: isXSDown ? 'column' : 'initial',
                    }}>
                        <div style={{ width: isXSDown ? '100%' : 480, height: '100%' }}>
                            Pool settings
                        </div>
                        <Pool.Content width={isXSDown ? '100%' : '480px'}>
                            <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '480px'}>
                                <Form disabled
                                    input={<Input disabled value={symbol} />}
                                    name={'From'}
                                    width={isXSDown ? '100%' : '213px'} />
                                <Form name={'To'}
                                    width={isXSDown ? '100%' : '213px'}
                                    input={<Select
                                        border width={'213px'}
                                        options={tokenOptions} onSelect={(value) => {
                                            setSelectedToken(value)
                                        }} defaultValue={tokenOptions[0]} />} />
                            </Pool.Content>

                            {/*ratio form*/}
                            <Form
                                top={'38px'}
                                error={errors.ratio}
                                input={<Input
                                    style={{ marginLeft: 9 }}
                                    required
                                    name='ratio'
                                    type='number'
                                    value={ratio}
                                    onChange={handleChange}
                                />}
                                name={'Swap Ratio'}
                                prefix={`1 ${selectedToken.symbol} =  `}
                                suffix={symbol} />

                            {/*amount form*/}
                            <Form top={'43px'}
                                error={errors.amount}
                                disabled={!address || errors.address}
                                input={<Input
                                    disabled={!address || errors.address}
                                    required
                                    name='amount'
                                    type='number'
                                    value={amount}
                                    onChange={handleChange}
                                />} name={'Amount'} addonAfter={(<img onClick={() => {
                                    balance && setAmount(weiToNum(balance, decimals, parseInt(decimals)))
                                }} src={icon_max} />)}
                                extra={
                                    <span>{`Balance: ${balance ? `${weiToNum(balance, decimals)} ${symbol}` : '--'}`}</span>} />


                            <Pool.Content width={isXSDown ? '100%' : '480px'}>
                                <Form top={'38px'}
                                    disabled
                                    suffix={selectedToken.symbol}
                                    input={<Input value={level} disabled />} name={<HelperLayout
                                        content={'This is the total allocation in the pool'}> {`Bounce level `}<img
                                            style={{ marginLeft: 8 }}
                                            src={icon_helper} /></HelperLayout>} />
                                <Form
                                    type='radio'
                                    top={'48px'}
                                    name={<HelperLayout
                                        content={'You can set a maximum allocation per wallet to prevent monopoly activities during the token swap.'}>
                                        {`Maximum Allocation per Wallet `}<img
                                            src={icon_helper} /></HelperLayout>} prefix={(<>
                                                <label>
                                                    <input
                                                        onChange={(e) => {
                                                            setHasLimit(false)
                                                        }} type='radio' name='limit' defaultChecked={true} />
                                                    <i></i>
                                                    {` No limits`}
                                                </label>
                                                <label style={{ marginLeft: 80, marginRight: 34 }}>
                                                    <input
                                                        onChange={() => {
                                                            setHasLimit(true)
                                                        }} type='radio' name='limit' />
                                                    <i></i>
                                                    {` ${selectedToken.symbol}`}
                                                </label>
                                            </>)} />

                                {/*limit amount*/}
                                {(hasLimit) && (
                                    <Form
                                        error={errors.limit}
                                        top={'38px'}
                                        suffix={selectedToken.symbol}
                                        input={<Input
                                            name='limit'
                                            required={hasLimit}
                                            disabled={!amount}
                                            value={limit}
                                            onChange={handleChange}
                                        />} name={'Allocation'} />
                                )}
                            </Pool.Content>

                            <Pool.Divider />

                            <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '480px'}>
                                <Form
                                    type='radio'
                                    top={'38px'}
                                    name={<span style={{ color: '#000' }}>Participant</span>}
                                    prefix={(<>
                                        <label>
                                            <input onChange={() => {
                                                setOnlyBot(true)
                                                setPassword(null)
                                                setIsPrivate(false)
                                            }} type='radio' name='participant' defaultChecked={true} />
                                            <i></i>
                                            {`  BOT holders`}
                                        </label>
                                        <label style={{
                                            marginLeft: isXSDown ? '14px' : 60,
                                            marginRight: isXSDown ? '14px' : 34
                                        }}>
                                            <input onChange={() => {
                                                setOnlyBot(false)
                                                setPassword(null)
                                                setIsPrivate(false)
                                            }} type='radio' name='participant' />
                                            <i></i>
                                            {` Public`}
                                        </label>
                                        <label style={{
                                            marginLeft: isXSDown ? '14px' : 60,
                                            marginRight: isXSDown ? '14px' : 34
                                        }}>
                                            <input onChange={() => {
                                                setOnlyBot(false)
                                                setIsPrivate(true)
                                            }} type='radio' name='participant' />
                                            <i></i>
                                            {` Private`}
                                        </label>
                                    </>)} />
                                {isPrivate && (
                                    <Form
                                        top={'38px'}
                                        addonAfter={(<img onClick={() => {
                                            setShowPassword(!showPassword)
                                        }} src={showPassword ? icon_eye_open : icon_eye_close} />)}
                                        input={<Input type={!showPassword && 'password'} onChange={(e) => {
                                            setPassword(e.target.value)
                                            console.log('password', new BN(md5(e.target.value)).toString())
                                        }}
                                        />} name={'Password'} />
                                )}

                            </Pool.Content>

                            <Pool.Divider />

                            <Form top={'38px'} error={nameError} input={<Input
                                maxLength={15}
                                onBlur={(e) => {
                                    setName(e.target.value)
                                }}
                            />} name={'Pool Name'} />

                            <Form top={'49px'} error={timeError}
                                name={<span style={{ color: '#000', marginBottom: 9 }}>Pool running time</span>} hidden
                                prefix={(<div>
                                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                                        <Form input={<Input
                                            onChange={(e) => {
                                                setDays(e.target.value)
                                            }}
                                            type='number' />} name={'Days'} width={isXSDown ? '30%' : '132px'} />
                                        <Form input={<Input
                                            value={hours}
                                            onChange={(e) => {
                                                let hours = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                                if (hours > 24) {
                                                    hours = 24
                                                }
                                                setHours(hours);
                                            }}
                                            type='number' />} name={'Hours'} width={isXSDown ? '30%' : '132px'} />
                                        <Form input={<Input
                                            value={minutes}
                                            onChange={(e) => {
                                                let minutes = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                                if (minutes > 60) {
                                                    minutes = 60
                                                }
                                                setMinutes(minutes);
                                            }}
                                            type='number' />} name={'Minutes'} width={isXSDown ? '30%' : '132px'} />
                                    </Pool.Content>
                                </div>)} />

                            <Form top={'38px'} disabled name={<><HelperLayout
                                content={`bounce charge 1% fee to pool creator based on the amount of the successfully swapped tokens`}> {`Transaction Fee `}<img
                                    src={icon_helper} /></HelperLayout></>} hidden prefix={(<div
                                        style={{ width: isXSDown ? '100%' : '480px' }}>
                                        <Button disabled={!validateForm(errors) || !name || !amount || !ratio || !address} type="submit" form="fs-form" style={{ marginTop: 12 }} black
                                            width={isXSDown ? '100%' : '480px'}>Launch</Button>
                                        {<p style={{
                                            color: 'red',
                                            marginTop: 10
                                        }}>warning: Bounce does not support deflationary tokens</p>}
                                    </div>)} />
                        </Pool.Content>
                    </Pool.Frame>
                </Pool>

                <CreateModal onOK={() => {
                    setModalStatus(initStatus)
                    history.goBack()
                }} onDismiss={() => {
                    setModalStatus(initStatus)
                }} modalStatus={modalStatus} />

                <Modal
                    closeable
                    isOpen={showTip}
                    onDismiss={() => {
                        setShowTip(false)
                    }}
                    maxWidth={'450px'}

                >
                    <AuctionTipModal type={1} auction={() => {
                        setShowTip(false)
                        onFSCreate()
                    }} />

                </Modal>

            </PoolFrame>

            <PoolErrorModal show={isLive} onDismiss={() => {
                history.push(`/fixed-swap/${poolIndex}`)
            }} />
        </form>
    )
}
