
export function getBotAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x5bEaBAEBB3146685Dd74176f68a0721F91297D37'
        case 4:
            return '0xAbF690E2EbC6690c4Fdc303fc3eE0FBFEb1818eD'
        case 56:
            return '0x48DC0190dF5ece990c649A7A07bA19D3650a9572'
        default:
            return '0x5bEaBAEBB3146685Dd74176f68a0721F91297D37'
    }
}

export function getFixSwapAddress(chainId) {
    switch (chainId) {
        // case 1:
        //     return '0x73282A63F0e3D7e9604575420F777361ecA3C86A'
        // case 4:
        //     return '0x4392E75b5f44f1943f4bA8BE9fa6e14931F0630d'
        // case 56:
        //     return '0x4Fc4bFeDc5c82644514fACF716C7F888a0C73cCc'
        default:
            return '0x006BFeeFF623B1F3b606bb40AA12CC15E507234E'
    }
}

export function getFixSwapNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x85F3cF41ca8DdF7333605502A0E35B84f0BE02e0'
        case 4:
            return '0xE520cB16F99e59eB5d6a450F0AD6b30642Dfe56C'
        case 56:
            return ''
        default:
            return '0x85F3cF41ca8DdF7333605502A0E35B84f0BE02e0'
    }
}

export function getSealedBidAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0xF74Bf048138a2B8F825ECCaBed9e02E481A0F6C0'
        case 4:
            return '0xA1cEa127c79FE7378f68185C9BD2E97321f7c917'
        default:
            return '0xF74Bf048138a2B8F825ECCaBed9e02E481A0F6C0'
    }
}

export function getSealedBidNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x9ceCbBe32C9bEE1Df5C66e009794e4acD47aA820'
        case 4:
            return '0x9992c4141229277F6ce98Ca6a8173f2cbaDbFD99'
        case 56:
            return '0xc18692D031281277cE17320Fc72A7ebD1DA86784'
        default:
            return '0x9ceCbBe32C9bEE1Df5C66e009794e4acD47aA820'
    }
}


export function getEnglishAuctionNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x64F8047bf407Ec71AAAE6EE145F34D36d97dC416'
        case 4:
            return '0x8EeDc275F99c3Faa534d194516d4921C827451DD'
        case 56:
            return '0xECc394cd5d7F3f08C55508Ab670ee9D5d11a7F71'
        default:
            return '0x64F8047bf407Ec71AAAE6EE145F34D36d97dC416'
    }
}

export function getLotteryErc20Address(chainId) {
    switch (chainId) {
        case 1:
            return '0xD9B2f3b38A92E640Ac7159e2B78C88a60a0fc5Ba';
        case 4:
            return '0x99f656D3339bD4a257aA30527eFD212A10552B46';
        default:
            return '';
    }
}

// export function getLotteryNFTSAddress(chainId) {
//     switch (chainId) {
//         case 1:
//             return '0x4357C58994967042890eaBb80B663825C3474AFd';
//         case 4:
//             return '0xA9481Fb20363b0f8dDfb8c612c01E4d892F03B9D';
//         default:
//             return '0x4357C58994967042890eaBb80B663825C3474AFd';
//     }
// }

export function getStakingAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
        case 4:
            return '0xa77A9FcbA2Ae5599e0054369d1655D186020ECE1'
        // case 4:
        //     return '0x4911C30A885EfcdD51B351B1810b1FEA73796338'
        default:
            return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
    }
}

export function getLPStakingAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0xd17b4b476006ccd008E3FE8B9d96AF4F787fe671'
        case 4:
            return '0x2D65038E422e60FD4861467dbDC2a1C844F3e768'
        default:
            return '0xd17b4b476006ccd008E3FE8B9d96AF4F787fe671'
    }
}

export function getLPTokenAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0xE444f079e8f69ee32b2707974b5c994Fa7DD944A'
        case 4:
            return '0x3dd8ebff190feb763235ccee3107e0d8fd0d4f52'
        default:
            return '0xE444f079e8f69ee32b2707974b5c994Fa7DD944A'
    }
}

export function getLotteryNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0xE444f079e8f69ee32b2707974b5c994Fa7DD944A'
        case 4:
            return '0xA9481Fb20363b0f8dDfb8c612c01E4d892F03B9D'
        case 97:
            return ''
        case 56:
            return '0x153839Aff8467C3e25e89F155B8DD126b92b557e'
        default:
            return '0xE444f079e8f69ee32b2707974b5c994Fa7DD944A'
    }
}


export function getDutchAuction(chainId) {
    switch (chainId) {
        case 1:
            return '0xA02556A258a4bcC6582b3B1d99d5299d88b907c5'
        case 4:
            return '0x801B7385B52B27aCE6BFca25Fa1E399A065ee3E6'
        // case 97:
        //     return  ''
        // case 56:
        //     return  '0x153839Aff8467C3e25e89F155B8DD126b92b557e'
        default:
            return '0xA02556A258a4bcC6582b3B1d99d5299d88b907c5'
    }
};

export function getDutchAuction721(chainId) {
    switch (chainId) {
        case 1:
            return '0x953Bad68D1F079D4eD7Bd03e5772bA00974F776b'
        case 4:
            return '0xf43E82a8B3B636d84EbFD04885e008c3B55E232B'
        // case 97:
        //     return  ''
        // case 56:
        //     return  '0x153839Aff8467C3e25e89F155B8DD126b92b557e'
        default:
            return '0x953Bad68D1F079D4eD7Bd03e5772bA00974F776b'
    }
};

export function getDutchAuction1155(chainId) {
    switch (chainId) {
        case 1:
            return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
        case 4:
            return '0xFaF1bd19516F3aA2D1F0706e21aEB194d62c2190'
        // case 97:
        //     return  ''
        // case 56:
        //     return  '0x153839Aff8467C3e25e89F155B8DD126b92b557e'
        default:
            return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
    }
};


export function getUSDTAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0xdac17f958d2ee523a2206206994597c13d831ec7'
        case 4:
            return '0x101194a3FF67f83A05B3E15AfA52D45D588614ca'
        case 97:
            return ''
        case 56:
            return '0x55d398326f99059ff775485246999027b3197955'
        default:
            return '0xdac17f958d2ee523a2206206994597c13d831ec7'
    }
};

export function getWBTCAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'
        case 4:
            return '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'
        case 97:
            return ''
        case 56:
            return '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'
        default:
            return '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'
    }
};

export function getYFIAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e'
        case 4:
            return '0xBA8913A83C899dDB0F071bf64f7a1A299B578758'
        case 97:
            return ''
        case 56:
            return ''
        default:
            return '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e'
    }
};

export function getSVFSAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x9d1cdc14aE40b27DF757cefAB74c58dD1cc8be71'
        case 4:
            return '0x2EE24d9Ff252D7aBABEC3Bb2A539Ec558D5E45bD'
        case 97:
            return ''
        case 56:
            return '0x2EE24d9Ff252D7aBABEC3Bb2A539Ec558D5E45bD'
        default:
            return '0x9d1cdc14aE40b27DF757cefAB74c58dD1cc8be71'
    }
}

export function getSVSBAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x94e22400DCf245245c483714c12f11f29CD3617d'
        case 4:
            return '0xFB96b9f673fF64EDc787671c915277d292215989'
        case 97:
            return ''
        case 56:
            return ''
        default:
            return '0x94e22400DCf245245c483714c12f11f29CD3617d'
    }
}

export function getAlpacasNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0xC7e5e9434f4a71e6dB978bD65B4D61D3593e5f27'
        case 4:
            return '0x08385552077305da3290e76f403B3731FeBD7c27'
        case 97:
            return ''
        case 56:
            return '0x060Dd632f7Dd45cb97A86376863D623d6756e848'
        default:
            return '0xC7e5e9434f4a71e6dB978bD65B4D61D3593e5f27'
    }
}

export function getYGiftNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x020171085bcd43b6fd36ad8c95ad61848b1211a2'
        case 4:
            return '0x7396352B217cd712A81463e5397f685E1a4965a1'
        case 97:
            return ''
        case 56:
            return ''
        default:
            return '0x020171085bcd43b6fd36ad8c95ad61848b1211a2'
    }
}

export function getBounceAlpacasNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x2B251F570614D8e11A77E539e0D38955CDa418eB'
        case 4:
            return '0x130d3e869018D96C6AcbC72dae7b054D7De44Ffb'
        case 97:
            return ''
        case 56:
            return '0x357147B2494BE67af2C98D2422C3cb4002DC3b74'
        default:
            return '0x2B251F570614D8e11A77E539e0D38955CDa418eB'
    }
}

export function getBounceAlpacasFSNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0xD5c187b3074B80ab1E13BAe3346313f05765813A'
        case 4:
            return '0xB3C2C86DA023f3053Cece255B878f888aA7B7f93'
        case 97:
            return ''
        case 56:
            return '0x4B12cB452D56391C931B5442C4Ebbe7A58cA41C7'
        default:
            return '0xD5c187b3074B80ab1E13BAe3346313f05765813A'
    }
}

export function getBounceYGiftFSAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x4A22bf1D03Fc72b15C59AD38E7e538037144e4A9'
        case 4:
            return '0xA768e4742D9f9D957Ec3C6f5eC496e353107735e'
        case 97:
            return ''
        case 56:
            return ''
        default:
            return '0x4A22bf1D03Fc72b15C59AD38E7e538037144e4A9'
    }
}

export function getBounceYGiftEAAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0xAb1c38F1525704C066a180dBB1510980B0A6A80b'
        case 4:
            return '0xb446e14A7eF695fa30d866FF41f367d56dDa6f5B'
        case 97:
            return ''
        case 56:
            return ''
        default:
            return '0xAb1c38F1525704C066a180dBB1510980B0A6A80b'
    }
}

export function getBouncePredictionAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x3F3d6b8F6377183875d534c0928C73b035d628dA'
        case 4:
            return '0x2Ca994Ae24fC34aA2Ddae433AD70F10bFBc4530a'
        case 97:
            return ''
        case 56:
            return ''
        default:
            return '0x3F3d6b8F6377183875d534c0928C73b035d628dA'
    }
}

export function getBounceFactoryAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x20C580dEbF6C25094f8aE3740e12165474fE454C'
        case 4:
            return '0x4361AfD0B5F1C0aa50b6eC8EAd2B05D5C500F82a'
        case 97:
            return ''
        // case 56:
        //     return ''
        default:
            return '0x20C580dEbF6C25094f8aE3740e12165474fE454C'
    }
}

export function getLiquidityAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0xEaD466e99D00d94031b22CCEbcF758d95A7b116C'
        case 4:
            return '0x027F12d1767122F168f320C7D2C8F795F4283D62'
        case 97:
            return ''
        case 56:
            return ''
        default:
            return '0xEaD466e99D00d94031b22CCEbcF758d95A7b116C'
    }
}
