import React, { useCallback, useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';
import { ReactComponent as ProgressIcon } from './assets/progress.svg';
import { ReactComponent as ExecutedIcon } from './assets/executed.svg';
import { useHistory } from 'react-router-dom';
import { weiToNumber } from "../../../utils/numberTransform";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    width: 410,
    height: 350,
    flex: '0 0 auto',
    cursor: 'pointer',
    marginRight: 25,
    marginBottom: 25,

    [`@media (max-width: 767px)`]: {
      width: 'calc(100vw - 40px)',
      height:'380px',
    },
  },
  wrapper: {
    padding: '16px 20px 0px 20px'
  },
  head: {
    borderBottom: '4px solid #000',
    paddingBottom: 12,
  },
  status: {
    fontFamily: 'IBM Plex Mono',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 12,
    lineHeight: '16px',
  },
  id: {
    color: fade('#000', 0.5)
  },
  title: {
    font: 'Optima',
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '27px',
    color: '#000',
    marginTop: 8,
    [`@media (max-width: 767px)`]: {
      fontSize: 18,
    },
  },
  address: {
    fontFamily: 'IBM Plex Mono',
    fontSize: 13,
    lineHeight: '17px',
    color: fade('#1F191B', 0.4),
    marginTop: 7,
    overflow: 'hidden',

    '&:hover': {
      textDecoration: 'underline',
      color: fade('#1F191B', 0.6),
    }
  },
  content: {
    padding: '16px 0',
    paddingBottom: 0,
    height: 190,
    boxSizing: 'border-box',
    [`@media (max-width: 767px)`]: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 216,
    },
  },
  proposer: {
    display: 'inline-block',
    height: 100,
    fontFamily: 'Helvetica Neue',
    color: 'rgba(0, 0, 0, 0.6)',
    minHeight: 36,
    fontSize: 14,
    lineHeight: '18.27px',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    // marginTop: 63,
    fontFamily: 'IBM Plex Mono',
    fontSize: 13,
    lineHeight: '17px',
    '& dt': {
      color: fade('#1F191B', 0.4),
    },
    '& dd': {
      fontWeight: 'bold',
      color: '#1F191B',
      marginTop: '5px'
    },
    [`@media (max-width: 767px)`]: {
      marginTop: 0,
      alignSelf: 'flex-end',
    },
  },
  left: {

  },
  center: {
    width: 40,
    height: 40,
    margin: '0 38px',
    [`@media (max-width: 767px)`]: {
      margin: '0 10px',
    },
  },
  right: {

  },
  bottom: {
    width: '100%',
    height: 45,
    lineHeight: '45px',
    textAlign: 'center',
    // marginTop: 13,
    fontFamily: 'IBM Plex Mono',
    fontWeight: 500,
    fontSize: 12,
    // lineHeight: '21px',
    color: '#000',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',

    '&:hover': {
      backgroundColor: '#000',
      color: '#fff'
    }
  },
}))

const data = {
  id: '00123',
  title: 'Reduce Daily Rewards To 16',
  address: '000xxx00ddddd',
  proposer: 'Reduce daily rewards emission to 16 bots and adjust allocation.',
  from: '0,003',
  to: '0,012',
  day: '2',
  hour: '2',
  min: '2'
}

const Item = ({ gov }) => {
  const classes = useStyles();
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const [left, setLeft] = useState(null);
  let timer;
  const creator = gov.creator.slice(0, 7) + '...' + gov.creator.slice(gov.creator.length - 4)
  const renderTime = useCallback(() => {
    if (gov && gov.time) {
      const time = gov.time * 1000;
      const date = new Date(time);
      const now = new Date();
      const lefttime = date - now;
      let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
      let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
      let leftm = Math.floor(lefttime / (1000 * 60) % 60);
      let lefts = Math.floor(lefttime / 1000 % 60);
      const left = {
        days: leftd < 0 ? 0 : leftd,
        hours: lefth < 0 ? 0 : lefth,
        minutes: leftm < 0 ? 0 : leftm,
        seconds: lefts < 0 ? 0 : lefts,
      };
      setLeft(left);
      if (left < 0) {
        setLeft(null);
      }
    } else {
      setLeft(null);
    }
  }, [gov, setLeft]);

  useEffect(() => {
    if (gov) {
      timer = setInterval(() => {
        renderTime();
      }, (1000));
    } else {
      clearInterval(timer)
    }

    return () => {
      clearInterval(timer)
    }
  }, [renderTime, gov]);

  const handleEnter = () => {
    console.log('enter')
    setHover(true);
  }

  const handleLeave = () => {
    setHover(false);
  }

  const handleDetails = () => {
    history.push(`/governance/details/${gov.index}`, { id: gov.index })
  }

  return (
    <div className={classes.root}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <div className={classes.wrapper}>
        <div className={classes.head}>
          <div className={classes.status}>
            <div className={`status ${gov.status === 'Live' ? 'live' : gov.status === 'Passed' ? 'passed' : 'failed'}`}>• {gov.status}</div>
            <div className={classes.id}>
              {`#${gov.position}`}
            </div>
          </div>
          <div className={classes.title} style={{ overflow: "hidden", width: '100%', whiteSpace: "nowrap", textOverflow: 'ellipsis' }}>
            {gov.title}
          </div>
          <span className={classes.address} style={{ overflow: "hidden", width: '100%', whiteSpace: "nowrap", textOverflow: 'ellipsis' }}>
            {`proposer: ${creator}`}
          </span>
        </div>
        <div className={classes.content}>
          <div className={classes.proposer}>
            {gov.content && gov.content.summary}
          </div>
          <div className={classes.info}>
            <dl className={classes.left}>
              <dt>{`Votes For:`}</dt>
              <dd>{`${weiToNumber(gov.yesCount)} BOT`}</dd>
            </dl>
            <div className={classes.center}>
              {/* <ProgressIcon /> */}
              <CircularProgressWithLabel
                style={{ color: gov.status === 'Live' ? '#2DAB50' : gov.status === 'Passed' ? '#728AE0' : '#666' }}
                value={parseInt(parseInt(gov.yesCount) / (parseInt(gov.yesCount) + parseInt(gov.noCount)) * 100) || 0}
                size={50}
                thickness={8}
                color='inherit'
                isShowText={true}
              />
            </div>
            <dl className={classes.right}>
              <dt>{`Votes Against:`}</dt>
              <dd>{`${weiToNumber(gov.noCount)} BOT`}</dd>
            </dl>
          </div>
        </div>

      </div>
      <div className={classes.bottom}>
        {hover ?
          <div
            style={{ fontWeight: 'bold', fontSize: '14px' }}
            onClick={handleDetails}
          >{`Show More Info`}</div> :
          <>{left && `Time left: ${left.days}d : ${left.hours}h : ${left.minutes}m `}</>}
      </div>
    </div>
  )
}

export { Item };
