import React from 'react';
import { t, tHTML } from '../../utils/intl';
import failedIcon from '../../static/image/error.svg';
import { getBounce } from '../../components/utils/web3';

export const ExpiredPool = ({
  account,
  onStatusChange,
  onHashChange,
    web3
}) => {
  const handleClaim = async () => {
    const bounce = await getBounce(web3);
    onStatusChange('waiting');
    try {
      bounce.methods.fixPoolWithdraw()
        .send({from: account})
        .then(r => {
          onStatusChange('success');
          onHashChange(r.transactionHash);
        })
        .catch(err => {
          if (err.code === 4001) {
            onStatusChange('denied');
          } else {
            onStatusChange('failed');
          }
        })
      } catch (err) {
        if (err.code === 4001) {
          onStatusChange('denied');
        } else {
          onStatusChange('failed');
        }
      }
  }

  return (
    <div className='content-box'>
      <img src={failedIcon} alt='expired' />
      {tHTML('expired.title')}
      <p>{t('expired.message')}</p>
      <span className='button' onClick={handleClaim}>{t('buttons.claim-token')}</span>
    </div>
  )
}
