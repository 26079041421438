import React, { useContext, useEffect, useState } from "react";
import { LayoutFrame } from "../../components/common/Layout";
import styled from "styled-components";
import { myContext } from "../../reducer";
import { getContract, useActiveWeb3React } from "../../web3";

import Market from './Market'
import Overview from './Overview'
import Staking from './Staking'

import { StakingModal, successClaimStatus } from "../../components/common/StakingModal";
import {
    initStatus,
    approveStatus,
    confirmStatus,
    pendingStatus,
    successStakeStatus,
    successUnStakeStatus,
    errorStatus,
    cancelStatus
} from '../../components/common/StakingModal'

const ModeFrame = styled.div`
  display: flex;
  /* height: 96px; */
  align-items: center;
  margin: 34px 0 72px;
`

const StakingMode = styled.span`
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  margin-right: 24px;
  background-color: ${({ active, theme }) => (active ? theme.black : '')};
  color: ${({ active, theme }) => (active ? theme.white : theme.black)};
  cursor: pointer;

  &:hover{
      color: ${({ active, theme }) => (active ? theme.white : 'rgba(0, 0, 0, .7)')};
      opacity: 1;
  }
`

export const Statistic = () => {
    const { state, dispatch } = useContext(myContext);
    const [modalStatus, setModalStatus] = useState(initStatus);
    const { active, account, library, chainId } = useActiveWeb3React();
    const [mode, setMode] = useState(0)



    function renderStaking() {
        switch (mode) {
            case 0:
                return (
                    <Overview />
                )
            case 1:
                return (
                    <Market />
                )
            case 2:
                return (
                    <Staking />
                )
        }
    }


    return (
        <LayoutFrame style={{ backgroundColor: '#F5F5F5', minHeight: '80vh' }}>
            <ModeFrame>
                <StakingMode onClick={() => {
                    setMode(0)
                }} active={mode === 0}>Overview</StakingMode>
                <StakingMode onClick={() => {
                    setMode(1)
                }} active={mode === 1}>BOT Market</StakingMode>
                <StakingMode onClick={() => {
                    setMode(2)
                }} active={mode === 2}>Staking</StakingMode>
            </ModeFrame>

            {renderStaking()}

            <StakingModal modalStatus={modalStatus} onDismiss={() => {
                setModalStatus(initStatus)
            }} />
        </LayoutFrame>
    )
}