// tab icon
import tab_home from '../../static/image/tab_home.svg'
import tab_home_select from '../../static/image/tab_home_select.svg'
import tab_copy from '../../static/image/tab_copy.svg'
import tab_copy_select from '../../static/image/tab_copy_select.svg'
import tab_check from '../../static/image/tab_check.svg'
import tab_check_select from '../../static/image/tab_check_select.svg'
import tab_cup from '../../static/image/tab_cup.svg'
import tab_cup_select from '../../static/image/tab_cup_select.svg'
import tab_cover from '../../static/image/tab_cover.svg'
import tab_cover_select from '../../static/image/tab_cover_select.svg'
import tab_lock from '../../static/image/tab_lock.svg'
import tab_lock_select from '../../static/image/tab_lock_select.svg'

// import FS_ERC20_CT from '../../web3/abi/bounce.json'
// import SB_ERC20_CT from '../../web3/abi/bounceSealedBid.json'

export const HeaderTabConfig = [
    {
        name: 'Token Sale',
        icon: tab_home,
        icon_select: tab_home_select,
        isBSC: true,
        option: [{
            key: 0,
            value: 'Fixed Swap Auction',
            route: 'fixed-swap',
            method: "query_fixed_pools",
            isBSC: true
        }
        // , {
        //     key: 1,
        //     value: 'Sealed-Bid Auction',
        //     route: 'sealed-bid',
        //     method: "query_sealed_bid_pools",
        //     isBSC: false
        // }, {
        //     key: 6,
        //     value: 'Dutch Auction',
        //     route: 'dutch-auction',
        //     method: "query_dutch_auction_pools",
        //     isBSC: false
        // }
    ]
    }
    // , {
    //     name: 'Liquidity Lock Auction',
    //     icon: tab_lock,
    //     icon_select: tab_lock_select,
    //     isBSC: false,
    //     option: [{
    //         key: 12,
    //         value: 'Liquidity Lock Auction',
    //         route: 'liquidity-lock-auction',
    //         method: "query_secured_liquidity_pools",
    //         isBSC: false
    //     }]
    // }, {
    //     name: 'NFT Auction House',
    //     icon: tab_copy,
    //     icon_select: tab_copy_select,
    //     isBSC: true,
    //     option: [{
    //         key: 10,
    //         value: 'NFT Fixed Swap Auction',
    //         route: 'fixed-swap-nft',
    //         method: "query_fixed_nft_pools",
    //         isBSC: false
    //     }, {
    //         key: 2,
    //         value: 'NFT Sealed-Bid Auction',
    //         route: 'sealed-bid-nft',
    //         method: "query_sealed_nft_pools",
    //         isBSC: true
    //     }, {
    //         key: 3,
    //         value: 'NFT English Auction',
    //         route: 'english-auction-pool',
    //         method: "query_english_nft_pools",
    //         isBSC: true
    //     }, {
    //         key: 7,
    //         value: 'NFT Dutch Auction',
    //         route: 'dutch-auction-nft',
    //         method: "query_dutch_nft_pools",
    //         isBSC: false
    //     }]
    // }, {
    //     name: 'Social Verified Pools',
    //     icon: tab_check,
    //     icon_select: tab_check_select,
    //     isBSC: false,
    //     option: [{
    //         key: 8,
    //         value: 'Social Verified Fixed swap auction',
    //         route: 'sv-fixed-swap',
    //         method: 'query_social_fixed_pools',
    //         isBSC: false
    //     }, {
    //         key: 9,
    //         value: 'Social verified sealed-bid auction',
    //         method: 'query_social_sealed_pools',
    //         route: 'sv-sealed-bid',
    //         isBSC: false
    //     }]
    // }, {
    //     name: 'Lotteries',
    //     icon: tab_cup,
    //     icon_select: tab_cup_select,
    //     isBSC: true,
    //     option: [{
    //         key: 4,
    //         value: 'Lottery',
    //         route: 'lottery-erc20',
    //         method: 'query_lottery_pools',
    //         isBSC: false
    //     }, {
    //         key: 5,
    //         value: 'NFT Lottery',
    //         route: 'lottery-nft',
    //         method: 'query_lottery_nft_pools',
    //         isBSC: true
    //     }]
    // }, {
    //     name: 'Predictions',
    //     isBSC: false,
    //     icon: tab_cover,
    //     icon_select: tab_cover_select,
    //     option: [{
    //         key: 11,
    //         value: 'Prediction Pool',
    //         route: 'prediction',
    //         method: 'query_prediction_pools',
    //         isBSC: true
    //     }]
    // }
]
