import styled from 'styled-components'

export const PoolGroupStyled = styled.div`
    width: 1280px;
    min-height: 520px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 12px auto;

    &>div{
        margin-right: 20px;
        ${({ col = 5 }) => {
        return `
                &:nth-child(${col}n){
                    margin-right: 0;
                }
            `
    }}   
    }
    @media (max-width: 767px) {
        width: 100%;
    }
`

export const LoadingPageStyled = styled.div`
        width: 100%;
        height: 100%;
        img{
            width: 200px;
            margin: 150px auto;
        }
`

export const EmptyPageStyled = styled.div`
        width: 100%;
        height: 100%;
        img{
            width: 240px;
            margin: 200px auto;
            @media (max-width: 767px) {
                width: 100vw;
            }
        }
`

export const ERC20_Card = styled.div`
        width: 240px;
        height: 320px;
        margin-top: 20px;
        box-sizing: border-box;
        background-color: #fff;
        font-family: 'IBM Plex Mono';
        position: relative;
        @media (max-width: 767px) {
            margin: 20px 20px 20px !important;
            width: 100vw;
            height: 365px;
        }
        
        .wrapper{
            width: 100%;
            height: 100%;
            padding: 15px 20px;
            .top{
                display: flex;
                justify-content: space-between;
                p{
                    font-size: 12px;
                    font-weight: bold;
                    color: #000;
                    opacity: .5;
                }
            }

            .title{
                height: 68px;
                line-height: 68px;
                border-bottom: 3px solid #000;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                font-weight: bold;
                font-size: 22px;
                font-family: 'Optima'
            }
        }
        &:hover .join_btn{
           display: block;
        }
        

        .join_btn{
            display: none;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 45px;
            line-height: 45px;
            text-align: center;
            border-top: 1px solid rgba(0,0,0,.2);
            background-color: #fff;
            z-index: 1;
            font-family: 'Helvetica Neue';
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
            @media (max-width: 767px) {
                display: block;
            }
            &:hover{
                color: #fff;
                background-color: #000;
            }
        }


        
`

export const ProgressStyled = styled.div`
        position: relative;
        width: 100%;
        height:${({ height }) => { return height || '4px' }};
        margin-top: ${({ marginTop }) => { return marginTop || '20px' }};
        overflow: hidden;
        

        &>div{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height:${({ height }) => { return height || '4px' }};
            background-color: ${({ color }) => { return color }};
            opacity: .1;

            &:nth-child(2){
                width: ${({ plan }) => { return plan || 0 }};
                opacity: 1;
            }
        }

`

export const InfoBoxStyled = styled.div`
        margin-top: 30px;
        &>div{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            span{
                font-size: 12px;
                color: #000;
                opacity: .5; 
            }
            p{
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #1F191B;
                img{
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                }
            }
        }
`

export const StatusStyled = styled.div`
display: flex;
align-items: center;
    div{
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: ${({ color }) => { return color || '#f0f0f0' }}
    }
    span{
        font-size: 12px;
        margin-left: 5px;
        color: ${({ color }) => { return color || '#f0f0f0' }}
    }
`

export const ClostAtStyled = styled.div`
    width: 100%;
    height: 47px;
    line-height: 47px;
    border-top: 1px solid rgb(0,0,0,.2);
    display: flex;
    justify-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 30px;
    box-sizing: border-box;
    div{
        width: 100%;
        font-family: "IBM Plex Mono";
        font-size: 12px;
        color: rgb(0,0,0,.5);
        display: flex;
        justify-content: space-around;
    }
`

export const WrapperStyled = styled.div`
        width: 240px;
        height: 320px;
        margin-top: 20px;
        box-sizing: border-box;
        padding: 15px 21px;
        @media (max-width: 767px) {
            width: 100vw;
        }
        .top{
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        .title{
            margin-bottom: 20px;
        }

        .cover{

        }

        .text_info{
            /* height: 130px; */
            margin-top: 10px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

`

export const SV_Wrapper = styled.div`
    width: 410px;
    height: 336px;
    margin-top: 25px;
    box-sizing: border-box;
    padding: 16px 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 767px) {
        width: 100vw;
    }

    .top{
        display: flex;
        justify-content: space-between;
    }

    .text_info{
        height: 136px;
        .btn{
            height: 40px;
            text-align: center;
            margin-top: 20px;
            margin-left: 68px;
        }
    }

    .buttom{
        .up, .down{
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
        }
    }
`

export const SV_ERC20_Card = styled.div`
    .wrapper{
        width: 410px;
        height: 336px;
        padding: 16px 21px;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: ${({ bgColor }) => { return bgColor || '#ccc' }};
        font-family: 'IBM Plex Mono';
        .top{
            display: flex;
            justify-content: space-between;
            font-size: 12px;
        }
        .title{
            margin-top: 20px;
            border-bottom: 4px solid #000;
            h1{
                font-family: 'Optima';
                font-size: 22px;
                font-weight: bold;
            }
            h3{
                font-size: 13px;
                margin-top: 8px;
                margin-bottom: 10px;
            }
        }

        .info{
            height: 120px;
            margin-top: 15px;
            font-size: 14px;
            font-family: 'Helvetica Neue';
            line-height: 18.27px;
            box-sizing: border-box;
            overflow: hidden;
        }

        .buttom{
            .up, .down{
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: #1F191B;
                font-weight: 500;
            }
            .up{
                margin-bottom: 6px;
            }
            .down{
                margin-top: 8px;
                span{
                    opacity: 0.3;
                }
            }
        }

    }
`