import React, {useState, useEffect, useContext} from 'react';
import failedIcon from '../static/image/error.svg';
import {BidPoolItem} from './content/BidPoolItem';
import sucIcon from '../static/image/success.svg'
import {getContractLink, queryBidCreatorFilledAmount, queryBidCreatorClaimed} from './const';
import {isEqualTo, isGreaterThan} from '../utils/common'
import rotate from '../static/image/rotate.svg';
import {myContext} from '../reducer'
import {getSealedBid} from "../components/utils/web3";
import {t} from "../utils/intl";
import classNames from "classnames";



export const BidPool = ({
                          symbol,
                          pool,
                          account,
                          loading,
                          onStatusChange,
                          onHashChange,
                          onSuccessContentChange,
                        }) => {

  const {state, dispatch} = useContext(myContext);
  const [address, setAddress] = useState('');
  const [bidTokenAmount, setBidTokenAmount] = useState(0);
  const [bidEthAmount, setBidEthAmount] = useState(0);
  const [bidStatus, setBidStatus] = useState('Unfilled');
  const [claimed, setClaimed] = useState(true);





  const {web3, myAccount} = state;


  useEffect(() => {
    async function loadAddress() {
      const address = await getContractLink(pool ? pool.fromAddress : '');
      setAddress(address);
    }

    loadAddress()
  }, [pool]);

  useEffect(() => {
    if(web3 && pool){
      queryBidCreatorFilledAmount(web3, pool.index)
          .then(result=>{
            const bidTokenAmount = result[0];
            const bidEthAmount = result[1];
            const wanBidTokenAmount = web3.utils.toWei(pool.amountTotal)
            setBidTokenAmount(bidTokenAmount);
            setBidEthAmount(bidEthAmount)
            console.log('queryBidCreatorFilledAmount',pool.amountTotal, wanBidTokenAmount, result[0])
            if(isEqualTo(bidTokenAmount, '0')){
              setBidStatus('Unfilled')
            }else if(isGreaterThan(wanBidTokenAmount, bidTokenAmount)) {
              setBidStatus('Partial')
            }else {
              setBidStatus('Filled')
            }
          })


      queryBidCreatorClaimed(web3, pool.index)
          .then(result=>{
            console.log('claimed:',result)
            setClaimed(result)
          })
    }

  }, [web3,pool]);

  const onClaim = async () => {
    const bid = await getSealedBid(web3);
    onStatusChange('waiting');
    try {
      //onChangeAmount(amount, to.toFixed(4));
      await bid.methods.creatorClaim(pool.index)
          .send({from: account})
          .then(r => {
            onStatusChange('success');
            onHashChange(r.transactionHash);
          })
          // .on('transactionHash', hash => {
          //   //onStatusChange('pending')
          //   onHashChange(hash);
          // })
          // .on('confirmation', (_, receipt) => {
          //   console.log('onClaim success')
          //   onSuccessContentChange(t('stake.success.content-reward'));
          //   onStatusChange('success');
          //   dispatch({type: 'HANDLER_HAS_BID_POOL', hasBidPool: false});
          //   //onStatusChange('finish');
          //   //onReceiptChange(receipt);
          // })
          // .on('error', (err, receipt) => {
          //   onStatusChange('failed');
          //   console.log('error1', err);
          //   //onReceiptChange(receipt);
          // })
    } catch (err) {
      if (err.code === 4001) {
        onStatusChange('denied');
      } else {
        //onFailedContentChange(t('stake.fail.claim-reward'))
        onStatusChange('failed');
      }
      console.log('err', err);
    }
  }

  const renderButton = () => {
    switch (bidStatus) {
      case 'Live':
        return <></>
      case 'Filled':
        return <span onClick={onClaim} className="button">Claim your swapped tokens</span>
      case 'Partial':
        return <span onClick={onClaim} className="button">Claim your tokens and unswapped ETH</span>
      case'Unfilled':
        return <span onClick={onClaim} className="button">Claim your unswapped tokens</span>
      default:
        return <></>
    }
  };

  return (
      <>
        {loading ?
            <div className='content-box'>
              <img src={rotate} className='waiting' alt='waiting'/>
              <p>Loading</p>
            </div> :
            pool && myAccount
                ? <>
                  <ul className='form-head join-title'>
                    <li>{pool.name}</li>
                    <li><a href={address} target='_blank' rel="noopener noreferrer">{pool.fromAddress}</a></li>
                  </ul>
                  <form className='form-content'>
                    <BidPoolItem web3={web3} symbol={symbol} pool={pool}/>
                    <dl className='define'>
                      <li className={classNames('status',  pool.status === 'Live'? 'Live':  bidStatus)}>{ pool.status === 'Live'? 'Live': bidStatus}</li>
                    </dl>
                    {!claimed && pool.status !== 'Live' ?renderButton(): null}
                  </form>
                  {/*<div className='pool-status'>*/}
                  {/*<img src={sucIcon} alt='success'/>*/}
                  {/*<p className='status-text'>{`Pool is ${pool.status}`}</p>*/}
                  {/*</div>*/}
                </>
                : <div className='content-box'>
                  <img src={failedIcon} alt='no-login'/>
                  <p>You should connect to a wallet<br/> to see your bounce pool</p>
                </div>}
      </>
  )
}
