import React, { useState } from 'react'
import styled from 'styled-components'
import { Route, Switch } from "react-router-dom";

import { YGiftEANFT, MyYGiftEANFT } from "./EANFT";
import { YGiftFSNFT, MyYGiftFSNFT } from "./FSNFT";
import { DetailHeader } from "./DetailHeader";
import { ViewGiftNFT } from './ViewGiftNFT'

const DetailFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #121212;
  align-items: center;
`

export const YGitDetail = () => {

  const [searchText, setSearchText] = useState('')
  const [queryStatus, setQueryStatus] = useState('All')


  return <DetailFrame>
    <DetailHeader title={'yGift Store'} website={'NFTs for gifts, grants, and gratitude yGift.to'} onSearchChange={setSearchText} onQueryStatusChange={setQueryStatus} />
    <Switch>
      <Route path={`/store/ygift/detail/nft-english-auction`}>
        <YGiftEANFT queryStatus={queryStatus} searchText={searchText} />
      </Route>
      <Route path={`/store/ygift/detail/nft-fixed-swap`}>
        <YGiftFSNFT queryStatus={queryStatus} searchText={searchText} />
      </Route>

      <Route path={`/store/ygift/detail/account/nft-english-auction`}>
        <MyYGiftEANFT queryStatus={queryStatus} searchText={searchText} />
      </Route>
      <Route path={`/store/ygift/detail/account/nft-fixed-swap`}>
        <MyYGiftFSNFT queryStatus={queryStatus} searchText={searchText} />
      </Route>

      <Route path={`/store/ygift/detail/view-my-nft`}>
        <ViewGiftNFT />
      </Route>
    </Switch>

  </DetailFrame>
}
