import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from "../common/Button";
import md5 from "js-md5";
import Web3 from "web3";

const Frame = styled.div`
  width: 480px;
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media (max-width: 767px) {
    width:100%;
    padding: 0 20px;
  }
`
const Title = styled.div`
  font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 32px;
color: #000;
margin-bottom: 40px;
`
const Input = styled.input`
border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.3);
  outline: none;
  width: 100%;
  padding: 5px 0 9px;
`

const InputFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 38px;
`

Input.Name = styled.span`
  font-family: Helvetica Neue;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 15px;
color: #1F191B;
margin-bottom: 9px;
display: flex;
justify-content: space-between;
`

const Form = styled.form`
  width: 100%;
`


export const Step1 = ({ next, visible }) => {

  const [name, setName] = useState()
  const [describe, setDescribe] = useState()
  const [link, setLink] = useState()

  const handleSubmit = (event) => {
    event.preventDefault();
    next({ name, describe, link })
  }

  const handleChange = async event => {
    event.preventDefault();
    const {name, value} = event.target;
    switch (name) {
      case "name":
        setName(value)
        break;
      case "describe":
        setDescribe(value)
        break
      case "link":
        setLink(value)
        break
      default:
    }
  };



  return (
    <Frame style={{ height: visible ? 'fit-content' : 0 }}>
      <Title>01. Project Description</Title>
      <Form id='project-enter' onSubmit={handleSubmit}>
        <InputFrame style={{ marginTop: 0 }}>
          <Input.Name>Project Name</Input.Name>
          <Input
            maxLength={20}
            max
            name={'name'}
            onChange={handleChange}
            required
            placeholder={'Enter Your project name'} />
        </InputFrame>

        <InputFrame>
          <Input.Name>Project description</Input.Name>
          <Input
            name={'describe'}
            onChange={handleChange}
            required
            placeholder={'Describe your project'} />
        </InputFrame>

        <InputFrame>
          <Input.Name>Project Website</Input.Name>
          <Input
            name={'link'}
            onChange={handleChange}
            required
            placeholder={'Add a URL Link Of Project Website'} />
        </InputFrame>

        <Button
          // disabled={checkInput(name) && checkInput(describe) && checkInput(link) ? false : true}
          type="submit" form="project-enter" style={{ marginTop: 38 }} black>Next Step</Button>
      </Form>
    </Frame>
  )
}
