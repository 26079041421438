import React, {useState, useCallback, useEffect} from 'react';
import {t, tHTML} from '../../utils/intl';
import {TO, getBidRatio} from '../const';
import {getContract} from '../../components/utils/web3';
import {getWei} from '../../components/utils/web3'
import tip from '../../static/image/tip.svg'

import classNames from "classnames";

const BigNumber = require('bignumber.js');


export const BidPoolItem = ({
                              symbol,
                              pool,
                              onEnd,
                              web3
                            }) => {
  const [type, setType] = useState(symbol);
  const [left, setLeft] = useState(null);
  const ratio = parseInt(pool.swap / pool.amount * 100);
  let timer;

  const style = {
    width: `${ratio}%`
  }

  const renderTime = useCallback(() => {
    console.log('renderTime--->',pool.time)
    if (pool.time) {
      const time = pool.time * 1000;
      const date = new Date(time);
      const now = new Date();
      const lefttime = date - now;
      let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
      let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
      let leftm = Math.floor(lefttime / (1000 * 60) % 60);
      let lefts = Math.floor(lefttime / 1000 % 60);
      const left = {
        days: leftd < 0 ? 0 : leftd,
        hours: lefth < 0 ? 0 : lefth,
        minutes: leftm < 0 ? 0 : leftm,
        seconds: lefts < 0 ? 0 : lefts,
      };
      setLeft(left);
      if (left < 0) {
        setLeft(null);
      }
    } else {
      setLeft(null);
    }
  }, [pool, setLeft , left]);

  const renderLeft = useCallback(() => {
    if (left) {
      return <>
        <span>{left.days}</span>
        {` ${t('create-pool.placeholder.days')} : `}
        <span>{left.hours}</span>
        {` ${t('create-pool.placeholder.hours')} : `}
        <span>{left.minutes}</span>
        {` ${t('create-pool.placeholder.minuts')} : `}<span>{left.seconds}</span>
        {` ${t('create-pool.placeholder.seconds')}`}
      </>
    } else {
      return '';
    }
  }, [left]);

  useEffect(() => {
    timer = setInterval(() => {
      renderTime();
    }, (1000));
    return ()=>{clearInterval(timer)}
  }, [renderTime]);

console.log('pool.minEthPerWallet',pool.minEthPerWallet)
  return (
      <>
        <dl className='define'>
          <dt>{t('pool-item.pool-type')}</dt>
          <dd>{'Sealed bid'}</dd>
        </dl>

        <dl className='define'>
          <dt>{t('pool-item.participant')}</dt>
          <dt style={{display: 'flex'}}>
            <dd><span className='weight-grey'>{pool.password && pool.password != 0 ? 'Private': pool.onlyBot ? 'BOT holder' : "Public"}</span></dd>
          </dt>
        </dl>

        <dl className='define'>
          <dt>{'Total Allocation'}</dt>
          <dd>{web3 && pool.amountTotal && new BigNumber(new BigNumber(web3.utils.fromWei(pool.amountTotal, getWei(pool.decimals))).toFixed(8)).toNumber()} {pool.fromSymbol}</dd>
        </dl>

        <dl className='define'>
          <dt>
            <div className="tip">
              <dt>{'Floor Swap Ratio'}</dt>
              <img className='tip' src={tip}>
              </img>
              <span className="tip-message">{'This is the floor price for your token auction'}</span>
            </div>
          </dt>
          <dt style={{display: 'flex'}}>
            <dd><span className='weight-grey'>1 {TO} = {pool.amountTotal && pool.amountMin ? getBidRatio(pool) : 0} {pool.fromSymbol}</span>
            </dd>
          </dt>
        </dl>

        <dl className='define'>
          <dt>{'Minimum bid amount:'}</dt>
          <dt style={{display: 'flex'}}>
            <dd><span className='weight-grey'>{`${pool.minEthPerWallet? new BigNumber(new BigNumber(pool.minEthPerWallet).toFixed(8)).toNumber(): 0} ETH`}</span></dd>
          </dt>
        </dl>

        <dl className='define'>
          <dt>{t('pool-item.time-left')}</dt>
          <dd className='define-line'>{renderLeft()}</dd>
        </dl>
      </>
  )
}
