import styled from 'styled-components'

export const LoadingPage = styled.img`
  width: 176px;
  margin: auto;
  margin-top: ${({ top }) => (top ? top : 'auto')};
  margin-bottom: 200px;
`


export const EmptyPoolPage = styled.img`
  width: 268px;
  margin: auto;
  margin-top: 200px;
  margin-bottom: 200px;
`

