import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Tab } from './components/tab';
import { Item } from './components/item';
import { useHistory } from 'react-router-dom';
import { Vault } from './vault';
import { useGovernance } from "./Hooks";
import { weiToNumber } from "../../utils/numberTransform";
import { useIsXSDown } from '../../components/utils/themeHooks';
import { ReactComponent as AddIcon } from './components/assets/add.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  content: {
    maxWidth: 1280,
    margin: '0 auto',
    position: 'relative',

  },
  button: {
    position: 'absolute',
    top: -7,
    right: 0,
    border: `1px solid rgba(255, 255, 255, 0.3)`,
    width: 160,
    height: 48,
    fontFamily: 'Helvetica Neue',
    fontSize: 14,
    lineHeight: '48px',
    fontWeight: 700,
    color: '#fff',
    textAlign: 'center',
    cursor: 'pointer',
  },
  banner: {
    width: '100%',
    backgroundColor: '#000',
    paddingTop: 34,
    [`@media (max-width: 767px)`]: {
      height: '520px!important'
    },
  },
  data: {
    marginTop: 52,
    marginBottom: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (max-width: 767px)`]: {
      width: '100vw',
      flexWrap: 'wrap'
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    width: 352,
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid rgba(255, 255, 255, 0.4)',
    '&:first-child': {
      borderLeft: '1px solid rgba(255, 255, 255, 0.4)',
    },
    [`@media (max-width: 767px)`]: {
      padding: '40px 0',
      borderRight: 'none',
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
      '&:first-child': {
        borderLeft: 'none',
        borderTop: '1px solid rgba(255, 255, 255, 0.4)',
      },
    },
  },
  name: {
    fontFamily: 'IBM Plex Mono',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 400,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  value: {
    fontFamily: 'Optima',
    fontSize: 30,
    fontWeight: 700,
    lineHeight: '36px',
    color: '#fff',
    marginTop: 12,
  },
  list: {
    width: '100%',
    marginTop: -156,
    minHeight: 500,
    display: 'flex',
    [`@media (max-width: 767px)`]: {
      marginTop: -70,
      padding: '0 20px',
    },
  },
  listContent: {
    width: 1280,
    margin: '0 auto',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    '& :nth-child(3n)': {
      marginRight: 0,
    },
    [`@media (max-width: 767px)`]: {
      width: 'calc(100vw - 40px)',
    },

  },
  add: {
    position: 'fixed',
    bottom: 70,
    width: 64,
    right: 20,
    height: 64,
  }
}));


const Governance = () => {
  const classes = useStyles();
  const history = useHistory();
  const { govList, BOTStaked, govReward, govBOT } = useGovernance()
  const isXSDown = useIsXSDown();
  const [tab, setTab] = useState(0);

  const onTab = (tab) => {
    setTab(tab);
  }

  const createProposal = () => {
    history.replace('/governance/create-proposal');
  }

  const height = tab === 0 ? 404 : 320;

  return (
    <div className={classes.root}>
      <div className={classes.banner} style={{ height: height }}>
        <div className={classes.content}>
          {!isXSDown &&
            <div className={classes.button}
              onClick={createProposal}
            >
              {`Create Proposal`}
            </div>
          }
          <Tab tab={tab} onTab={onTab} />
          {tab === 0
            ? <div className={classes.data}>
              <div className={classes.item}>
                  <span className={classes.name}>{`Your Voting Power:`}</span>
                  <span className={classes.value}>{`${BOTStaked ? weiToNumber(BOTStaked) : ''} Votes`}</span>
                </div>
                <div className={classes.item}>
                  <span className={classes.name}>{`Your Governance Earning:`}</span>
                  <span className={classes.value}>{`${govReward ? weiToNumber(govReward): ''} BOT`}</span>
                </div>

            </div>
            : <div className={classes.data}>
              <div className={classes.item}>
                <span className={classes.name}>{`Address`}</span>
                <span className={classes.value}>{`0xda675957...2daf`}</span>
              </div>
              <div className={classes.item}>
                <span className={classes.name}>{`Balance`}</span>
                <span className={classes.value}>{govBOT ? `${weiToNumber(govBOT)}` : '0'}</span>
              </div>
            </div>}
        </div>
      </div>
      {tab === 0
        ? <div className={classes.list}>
          <div className={classes.listContent}>
            {govList.filter(item => { return item.content }).map(item => {
              return <Item gov={item} />
            })}
          </div>
        </div>
        : <Vault />}
      {isXSDown && (tab === 0) &&
        <div className={classes.add}>
          <AddIcon onClick={createProposal} />
        </div>
      }
    </div>
  )
}

export { Governance };
