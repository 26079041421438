import React, {useState, useCallback, useEffect} from 'react';
import {t} from '../../utils/intl';
import {
  getContractLink, queryReward, queryWithdraw, queryStake,
  STAKE_ADDRESS, queryUnStake, queryTotalStaked, queryGvReward
} from '../const';
import {
  getBounceERC20,
  getGovernanceContract,
  getStake
} from '../../components/utils/web3';
import {weiToNumber} from '../../utils/numberTransform'
import close_icon from '../../static/image/close.svg'
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from "@material-ui/core/styles/index";
import {isGreaterThan} from "../../utils/common";

const useStyles = makeStyles(() => ({
  root: {
    width: 480,
    margin: 'auto',
    position: 'absolute',
    borderRadius: 10,
    border: '1px solid #000000',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.12)',
    padding: 20,
    top: '20%',
    left: 0,
    right: 0
  },
}));


export const StakingForm = ({
                              account,
                              symbol,
                              poolIndex,
                              onStatusChange,
                              onHashChange,
                              onAmountChange,
                              onSuccessContentChange,
                              onLoadingStatusChange,
                              onFailedContentChange,
                              web3,
                               onStakedChange

                            }) => {
  const [hash, setHash] = useState('');
  const [address, setAddress] = useState('');
  const [rewardCount, setRewardCount] = useState('0');
  const [unStackAmount, setUnStackAmount] = useState('');
  const [withDrawCount, setWithDrawCount] = useState(0);
  const [stakeCount, setStakeCount] = useState('0');
  const [balance, setBalance] = useState('0');
  const [unStakeCount, setUnStakeCount] = useState('0');
    const [totalStaked, setTotalStaked] = useState('0');
    const [inputModal, setInputModal] = useState(false)
  const [inputAmount, setInputAmount] = useState();
  const [inputError, setInputError] = useState();
  const [actionType, setActionType] = useState(0);
  const [gvReward, setGvReward] = useState('0');



  const BN = web3.utils.BN;
  const classes = useStyles();


  useEffect(() => {
    async function loadInitData() {
      onLoadingStatusChange(true)
      const link = await getContractLink(hash);
      setAddress(link);
      const bouERC20 = await getBounceERC20(web3);
      const balance = await bouERC20.methods.balanceOf(account).call();
      setBalance(balance);
      console.log('setBalance--->', web3.utils.fromWei(new BN(balance), 'ether'))
      const reward = await queryReward(web3, account);
      setRewardCount(reward);
      console.log('reward--->', web3.utils.fromWei(new BN(reward), 'ether'))
      const withdraw = await queryWithdraw(web3, account);
      setWithDrawCount(withdraw);
      console.log('withdraw--->', web3.utils.fromWei(new BN(withdraw), 'ether'))
      const stake = await queryStake(web3, account);
      setStakeCount(stake)
        onStakedChange(stake)
      console.log('stake--->', web3.utils.fromWei(new BN(stake), 'ether'))

      const unStake = await queryUnStake(web3, account);
      setUnStakeCount(unStake)


      const total = await queryTotalStaked(web3, account);
      setTotalStaked(total)
      console.log('unstake--->', web3.utils.fromWei(new BN(unStake), 'ether'))

      const gvRewardResult = await queryGvReward(web3, account);
      setGvReward(gvRewardResult)
      console.log('gvRewardResult--->', web3.utils.fromWei(new BN(gvRewardResult), 'ether'))
      onLoadingStatusChange(false)

    }

    loadInitData()
  }, []);


  const handleStack = async () => {
    console.log('start stake--->')
    if (!inputAmount) {
      return;
    }
    setInputModal(false)
    const stack = await getStake(web3);
    const bouERC20 = await getBounceERC20(web3);
    const weiAmount = web3.utils.toWei(inputAmount, 'ether');
    console.log(`weiTo: ${weiAmount}`);
    onStatusChange('waiting');
    try {
      const result = await bouERC20.methods.approve(await STAKE_ADDRESS(web3), weiAmount).send({from: account});
      console.log('returf of contract--->', result, weiAmount)
      console.log('weiAmount--->', account, weiAmount)
      if (result.status) {
        await stack.methods.staking(weiAmount)
            .send({from: account})
            .then(r => {
              onAmountChange(inputAmount);
              console.log('stake success', r)
              onSuccessContentChange(t('stake.success.content-stake', {amount: inputAmount, symbol: 'BOT'}));
              onStatusChange('success');
              onHashChange(r.transactionHash);
            })
        // .on('transactionHash', hash => {
        //   console.log('transactionHash---->')
        //   //onStatusChange('waiting')
        //   onHashChange(hash);
        // })
        // .on('confirmation', (_, receipt) => {
        //   console.log('confirmation---->')
        //   onStatusChange('success');
        // })
        // .on('error', (err, receipt) => {
        //   onStatusChange('failed');
        //   console.log('error1', err);
        // })
      }
    } catch (err) {
      if (err.code === 4001) {
        onStatusChange('denied');
      } else {
        onFailedContentChange(t('stake.fail.stake'))
        onStatusChange('failed');
      }
      console.log('err', err);
    }
  }

  const handleUnStack = async () => {
    if (!inputAmount) {
      return;
    }
    setInputModal(false)
    console.log('handleUnStack--->', unStackAmount)
    const stack = await getStake(web3);
    const weiAmount = web3.utils.toWei(inputAmount, 'ether');
    console.log(`weiTo: ${weiAmount}`);
    onStatusChange('waiting');
    try {
      //onChangeAmount(amount, to.toFixed(4));
      console.log('unStaking weiAmount--->', account, weiAmount)
      await stack.methods.unStaking(weiAmount)
          .send({from: account})
          .then(r => {
            console.log('unstake success')
            onAmountChange(inputAmount);
            onSuccessContentChange(t('stake.success.content-un-stake', {amount: inputAmount, symbol: 'BOT'}));
            onStatusChange('success');
            onHashChange(r.transactionHash);
          })
      // .on('transactionHash', hash => {
      //   onStatusChange('pending')
      //   //onChangeHash(hash);
      // })
      // .on('confirmation', (_, receipt) => {
      //   onStatusChange('success');
      //   onStatusChange('finish');
      //   //onReceiptChange(receipt);
      // })
      // .on('error', (err, receipt) => {
      //   onStatusChange('failed');
      //   console.log('error1', err);
      //   //onReceiptChange(receipt);
      // })
    } catch (err) {
      if (err.code === 4001) {
        onStatusChange('denied');
      } else {
        onFailedContentChange(t('stake.fail.un-stake'))
        onStatusChange('failed');
      }
      console.log('err', err);
    }
  }

  const handleGvClaim = async () => {
    const gv = await getGovernanceContract(web3);
    onStatusChange('waiting');
    try {
      //onChangeAmount(amount, to.toFixed(4));
      await gv.methods.claimGovReward()
          .send({from: account})
          .then(r => {
            onHashChange(r.transactionHash);
            onSuccessContentChange(t('stake.success.content-reward', {symbol: 'BOT'}));
            onStatusChange('success');
          })
      // .on('transactionHash', hash => {
      //   onStatusChange('pending')
      //   //onChangeHash(hash);
      // })
      // .on('confirmation', (_, receipt) => {
      //   onSuccessContentChange(t('stake.success.content-withdrawn'));
      //   onStatusChange('success');
      //   onStatusChange('finish');
      //   //onReceiptChange(receipt);
      // })
      // .on('error', (err, receipt) => {
      //   onStatusChange('failed');
      //   console.log('error1', err);
      //   //onReceiptChange(receipt);
      // })
    } catch (err) {
      if (err.code === 4001) {
        onStatusChange('denied');
      } else {
        onFailedContentChange(t('stake.fail.withdraw'))
        onStatusChange('failed');
      }
      console.log('err', err);
    }
  }

  const handleClaimReward = async () => {
    const stack = await getStake(web3);
    onStatusChange('waiting');
    try {
      //onChangeAmount(amount, to.toFixed(4));
      await stack.methods.claimReward()
          .send({from: account})
          .then(r => {
            onHashChange(r.transactionHash);
            onSuccessContentChange(t('stake.success.content-reward'));
            onStatusChange('success');
          })
      // .on('transactionHash', hash => {
      //   onStatusChange('pending')
      //   //onChangeHash(hash);
      // })
      // .on('confirmation', (_, receipt) => {
      //   onSuccessContentChange(t('stake.success.content-reward'));
      //   onStatusChange('success');
      //   //onStatusChange('finish');
      //   //onReceiptChange(receipt);
      // })
      // .on('error', (err, receipt) => {
      //   onStatusChange('failed');
      //   console.log('error1', err);
      //   //onReceiptChange(receipt);
      // })
    } catch (err) {
      if (err.code === 4001) {
        onStatusChange('denied');
      } else {
        onFailedContentChange(t('stake.fail.claim-reward'))
        onStatusChange('failed');
      }
      console.log('err', err);
    }
  }


  return (
      <>
        {/*<ul className='form-head stake-title'>*/}
        {/*  <li>{t('stake-pool.name')}</li>*/}
        {/*</ul>*/}
        <form className='form-content' style={{paddingBottom: 20}}>
          <div className="staking-info" style={{display: 'flex', flexDirection: 'row'}}>
              <div className="item" style={{}}>
                  <span>Total BOT Staked:</span>
                  <span> {`${weiToNumber(totalStaked)} BOT`}
              </span>
              </div>
            <div className="item" style={{}}>
              <span>Your balance:</span>
              <span> {`${weiToNumber(balance)} BOT`}
              </span>
            </div>
            <div className="item" style={{}}>
              <span>Your Currently staked:</span>
              <span> {`${weiToNumber(stakeCount)} BOT`}</span>
            </div>
              <div className="item" style={{marginTop: 20, textAlign: 'center'}}>
                  <span>Transaction fee rewards estimation:</span>
                  <span> {`${weiToNumber(rewardCount)} BOT`}</span>
              </div>
              <div className="item" style={{marginTop: 20,textAlign: 'center'}}>
                  <span>Governance participation reward</span>
                  <span>{`${weiToNumber(gvReward)} BOT`}</span>
              </div>
          </div>
          <div className="stake-button-layout bottom" style={{position: 'static'}}>
            <span className={`stake-button ${balance == 0 ? 'button-unable' : ''}`}
                  onClick={_ => {
                    setActionType(0);
                    setInputAmount();
                    setInputModal(true)
                  }}>{'Stake BOT'}</span>
            <span style={{width: 40}}></span>

              <span className={`stake-button`}
                    onClick={_ => {
                        setActionType(1);
                        setInputAmount();
                        setInputModal(true)
                    }}>{t('Unstake BOT')}</span>


          </div>
          <div className="stake-button-layout bottom" style={{position: 'static', marginTop: 20}}>
            <span className={`stake-button`}
                  onClick={handleClaimReward}>{t('stake.claim-reward')}</span>
            <span style={{width: 40}}></span>
            <span className={`stake-button`}
                  onClick={_ => {
                    handleGvClaim()
                  }}>{'Claim governance participation Reward'}</span>

          </div>
        </form>

        <Modal open={inputModal}>
          <Card className={classes.root}>
            <CardHeader
                action={
                  <img className="modal-close" src={close_icon} alt='close' onClick={_ => {
                    setInputModal(false)
                  }}/>
                }
            />
            <CardContent>
              <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
              }}>

                <span className="modal-title">{actionType === 0 ? 'Stake BOT' : 'Unstake BOT'}</span>
              </div>
              <dl className='password'>
                <dt style={{display: 'flex'}}>
                  {'Amount'}
                  <span style={{flex: 1, textAlign: 'right'}}>
                    {`${t('balance')}: ${actionType === 0? web3.utils.fromWei(balance): web3.utils.fromWei(stakeCount)} BOT`}
                    </span>
                </dt>
                <dd style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                  <input
                      maxLength={12}
                      placeholder={`Enter the amount of BOT you want to ${actionType === 0 ? 'stake' : 'unstake'}`}
                      onChange={(e) => {
                        let amount = e.target.value.replace(/[^\d.]/g, '');
                        if(actionType === 0){
                          amount = isGreaterThan(amount, web3.utils.fromWei(balance)) ? web3.utils.fromWei(balance) : amount;
                        }else if (actionType === 1){
                          amount = isGreaterThan(amount, web3.utils.fromWei(stakeCount)) ? web3.utils.fromWei(stakeCount) : amount;
                        }
                        setInputAmount(amount)
                      }} value={inputAmount}/>
                  <span onClick={() => {
                    if(actionType === 0){
                      setInputAmount(web3.utils.fromWei(balance))
                    }else {
                      setInputAmount(web3.utils.fromWei(stakeCount))
                    }
                  }} className="balance-max">MAX</span>
                </dd>
                {inputError ? <dd className='error'>{inputError.content}</dd> : null}
                {/*<span className='weight-grey'>Transaction fee:ETH</span>*/}
              </dl>

              <span className='dark-button modal-button' onClick={() => {
                if (actionType === 0) {
                  handleStack()
                } else if (actionType === 1) {
                  handleUnStack()
                }
              }}>{'Go'}</span>
            </CardContent>
          </Card>
        </Modal>
      </>
  )
}
