import React, {useState, useEffect, useContext} from 'react';
import {
    ITextR,
    LayoutFrame, LineDivider,
    OText2, OText5,
    Pool,
    Progress,
    renderTime, Website
} from "../../components/common/Layout";
import styled from 'styled-components'
import icon_return from '../../static/image/icon-return.svg'
import {useSVFSDetail} from "./Hooks";
import BigNumber from "bignumber.js";
import {fromWei, getProgress, numToWei, weiDiv, weiToNumber} from "../../utils/numberTransform";
import classNames from "classnames";
import {useParams} from 'react-router-dom';
import {Form, Input} from "../../components/common/Form";
import icon_max from "../../static/image/icon-max.svg";
import {Button} from "../../components/common/Button";
import {useEthBalance, useTokenBalance} from "../../web3/common";
import {getContract, useActiveWeb3React} from "../../web3";
import fixSwap from "../../web3/abi/SVBounce.json";
import {getBotAddress, getSVFSAddress} from "../../web3/contractAddress";
import Web3 from 'web3'
import {useHistory} from 'react-router-dom'
import {
    BidModal,
    initStatus,
    errorStatus,
    successStatus,
    confirmStatus,
    pendingStatus,
    cancelStatus
} from "../../components/common/BidModal";
import {useLeftTime} from "../../hooks/useLeftTime";
import {PasswordModal} from "../../components/common/PasswordMpdal";
import {myContext} from "../../reducer";
import {isGreaterThan} from "../../utils/common";
import md5 from "js-md5";
import {useIsXSDown} from '../../components/utils/themeHooks';
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import {TipLink} from "../../components/common/TipLink";

const {toWei, BN} = Web3.utils

const ProgressFrame = styled.div`
  width: 560px;
  margin: auto;
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
  }
`

ProgressFrame.Title = styled.div`
font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
margin-bottom: 21px;
`
ProgressFrame.Num = styled.span`
  font-family: IBM Plex Mono;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
color: #1F191B;
margin-top: 15px;

span{
  color: rgba(31, 25, 27, 0.3);
}
`


const Project = styled.div`
  width: 1080px;
  padding: 0px 40px 60px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #C4C4C4;
`

Project.Meta = styled.div`
  display: flex;
  flex-direction: column;
`

Project.Body = styled.div`
  width: 456px;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 140px;
`

Project.Title = styled.div`
font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 22px;
color: #000;
margin-bottom: 24px;
`

Project.Footer = styled.div`
  width: 453px;
  margin-top: auto;
  margin-bottom: 0;
`


export const SVFSDetail = () => {
    const history = useHistory()
    const {state} = useContext(myContext);
    const {ethPrice} = state
    const {id} = useParams();
    const {account, library, chainId} = useActiveWeb3React()
    const {setTime, leftTime} = useLeftTime()
    const [bidAmount, setBidAmount] = useState()
    const [voteAmount, setVoteAmount] = useState()
    const [bidStatus, setBidStatus] = useState(initStatus)
    const [passwordModal, setPasswordModal] = useState(false)
    const [enterPW, setEnterPW] = useState()
    const [errorPW, setErrorPW] = useState()
    const isXSDown = useIsXSDown();

    const {
        name, symbol, decimals, limit, password, time, fromBidAmount, fromAmount,
        toAmount, status, isMine, toBidAmount, onlyBOT, claimed, toSymbol, toAddress,
        toDecimals, voteCount, voteStatus, voted, myVote, projectInfo, voteTime
    } = useSVFSDetail(id)

    const {ethBalance} = useEthBalance(toAddress)
    const {balance} = useTokenBalance(getBotAddress(chainId))
    let timer = null
    let voteTimer = null

    useEffect(() => {
        timer = setInterval(() => {
            const date = new Date(time * 1000);
            const now = new Date();
            const lefttime = date - now;
            if (lefttime > 1000) {
                setTime(lefttime)
            }else if(0<lefttime && lefttime<1000){
                window.location.reload()
            } else {
                clearInterval(timer)
            }
        }, (1000));
        return () => {
            clearInterval(timer)
        }
    }, [setTime, time]);

    useEffect(() => {
        if(voteStatus === 'Pending'){
            voteTimer = setInterval(() => {
                const date = new Date(voteTime * 1000 + 48*60*60*1000);
                const now = new Date();
                const lefttime = date - now;
                if (lefttime > 1000) {
                    setTime(lefttime)
                }else if(0<lefttime && lefttime<1000){
                    window.location.reload()
                } else {
                    clearInterval(voteTimer)
                }
            }, (1000));
        }

        return () => {
            clearInterval(voteTimer)
        }
    }, [setTime, voteTime, voteStatus]);


    useEffect(() => {
        if (password && password !== '0') {
            setPasswordModal(true)
        }
    }, [password])


    const onBid = async () => {
        if (password && password !== '0' && new BN(md5(enterPW)).toString() !== password) {
            setPasswordModal(true)
            return
        }
        // if (status === 'Closed') {
        //     return
        // }
        let tokenContract
        if (toAddress) {
            tokenContract = getContract(library, bounceERC20.abi, toAddress)
        }
        const bounceContract = getContract(library, fixSwap.abi, getSVFSAddress(chainId))
        const weiAmount = numToWei(bidAmount, toDecimals);
        console.log('on bid---->', bidAmount, weiAmount)

        setBidStatus(confirmStatus);
        try {
            if (toAddress) {
                await tokenContract.methods.approve(
                    getSVFSAddress(chainId),
                    0,
                )
                    .send({from: account});
                await tokenContract.methods.approve(
                    getSVFSAddress(chainId),
                    weiAmount,
                )
                    .send({from: account});
                setBidStatus(confirmStatus);
            }
            bounceContract.methods.fixPoolSwapV2(
                id,
                weiAmount,
                password)
                .send({from: account, value: toAddress ? 0 : weiAmount})
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid---->', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }

    const onVote = async () => {
        const tokenContract = getContract(library, bounceERC20.abi, getBotAddress(chainId))
        const bounceContract = getContract(library, fixSwap.abi, getSVFSAddress(chainId))
        const weiAmount = numToWei(voteAmount);
        console.log('on vote---->', voteAmount, weiAmount)

        setBidStatus(confirmStatus);
        try {
            const allowance = await tokenContract.methods.allowance(account, getSVFSAddress(chainId)).call()
            console.log('allowance', allowance, new BigNumber(weiAmount).minus(allowance).toString())
            await tokenContract.methods.approve(
                getSVFSAddress(chainId),
                weiAmount,
            )
                .send({from: account});
            bounceContract.methods.vote(id, weiAmount)
                .send({from: account})
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid---->', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }

    const onVoteClaim = async () => {
        const bounceContract = getContract(library, fixSwap.abi, getSVFSAddress(chainId))
        setBidStatus(confirmStatus);
        console.log('unvote amount',)
        try {
            bounceContract.methods.unvote(id, myVote)
                .send({from: account})
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid---->', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }



    const onClaim = async () => {
        const bounceContract = getContract(library, fixSwap.abi, getSVFSAddress(chainId))
        setBidStatus(confirmStatus);
        try {
            bounceContract.methods.fixPoolWithdraw()
                .send({from: account})
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }

    return (
        <LayoutFrame style={{
            marginTop: 27,
            paddingBottom: 56,
            paddingLeft: isXSDown ? '20px' : '0',
            paddingRight: isXSDown ? '20px' : '0'
        }}>
            <Pool.Return onClick={() => {
                history.goBack()
            }} src={icon_return}/>
            <LayoutFrame width={'1072px'} style={{padding: '32px 0', margin: 'auto', marginTop: 0}}>
                <Pool.Status style={{width: 'fit-content', margin: 'auto'}}
                             className={classNames('status', voteStatus)}><i
                    className={voteStatus}/>{voteStatus}</Pool.Status>
                <Pool.Header><span>{name}</span></Pool.Header>
                <Website target='_blank' href={projectInfo && projectInfo.link} style={{wordBreak: isXSDown ? 'break-all' : 'normal'}}>{projectInfo && projectInfo.link}</Website>
                {voted ? (
                    <Project>
                        <Project.Meta>
                            <Project.Body>
                                {projectInfo && projectInfo.description}
                            </Project.Body>
                            <Project.Footer>

                                <ProgressFrame.Num >Support from community: {voteCount && weiToNumber(voteCount)} BOT <span>/ 300 BOT</span></ProgressFrame.Num>

                                <Progress style={{marginTop: 16}} height={'5px'} className={classNames('progress', voteStatus)}>
                                    <Progress.Value style={{width: voteStatus === 'Successfully' ? '100%' : `${getProgress(voteCount, toWei('300'))}%`}}
                                                    className={classNames('progress-value', voteStatus)}/>
                                </Progress>

                            </Project.Footer>
                        </Project.Meta>

                        <Project.Meta>
                            <Project.Body style={{width: 432}}>
                               <Project.Title>You supported this project</Project.Title>

                                <Pool.Meta>
                                    <div>Your Vote Amount:</div>
                                    <div>{`${myVote && weiToNumber(myVote)} BOT`}</div>
                                </Pool.Meta>

                            </Project.Body>

                            <Project.Footer>
                                <Button disabled={voteStatus === 'Pending' || (voteStatus !== 'Pending' && status === 'Live')}  style={{
                                    backgroundColor: (voteStatus === 'Pending' || (voteStatus !== 'Pending' && status === 'Live')) ? '#D3D3DA' : '',
                                }} black onClick={onVoteClaim}>Claim support tokens back</Button>
                            </Project.Footer>
                        </Project.Meta>

                    </Project>
                ) : (
                    <>
                        <Pool.Description>{projectInfo && projectInfo.description}</Pool.Description>
                        <ProgressFrame>
                            <ProgressFrame.Title>Support from community:</ProgressFrame.Title>

                            <Progress height={'5px'} className={classNames('progress', voteStatus)}>
                                <Progress.Value style={{width: voteStatus === 'Successfully' ? '100%' : `${getProgress(toBidAmount, toAmount)}%`}}
                                                className={classNames('progress-value', toBidAmount === toAmount ? 'Filled' : voteStatus)}/>
                            </Progress>

                            <ProgressFrame.Num>{voteCount ? voteStatus === 'Successfully'? 300 : weiToNumber(voteCount): 0} BOT <span>/ 300 BOT</span></ProgressFrame.Num>

                        </ProgressFrame>
                    </>
                )}


                <Pool.Content style={{marginTop: 40}}>
                    {voteStatus === 'Pending' && (
                        <Pool.Content width={'auto'}
                                      style={{
                                          margin: 'auto',
                                          height: 'fit-content',
                                          width: '100%',
                                          flexDirection: 'column',
                                          padding: isXSDown ? '48px 20px' : '56px 0 72px',
                                          justifyContent: 'center',
                                          marginTop: 0,
                                          backgroundColor: 'rgba(248, 248, 251, 1)',
                                      }}>
                            <div style={{width: '320px', margin: 'auto'}}>
                                <OText2 style={{textAlign: 'center', marginTop: 0, fontSize: 26, lineHeight: '32px'}}>You
                                    have A time to Stake to support this Project</OText2>
                                {renderTime(leftTime)}
                                <LineDivider style={{marginTop: 0}}/>
                                <Pool.topInfo>
                                    <span>Your Vote Amount</span>
                                    <span>{`Balance: ${balance ? weiToNumber(balance, '18') : '--'}`} BOT</span>
                                </Pool.topInfo>
                                <Form top={'20px'} width={isXSDown ? '100%' : '320px'} input={<Input
                                    style={{
                                        padding: '8px 0',
                                        color: '#1F191B',
                                        fontSize: 16,
                                        lineHeight: '20px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: "bold"
                                    }}
                                    placeholder={'Bid Amount'}
                                    type='number'
                                    value={voteAmount}
                                    onBlur={() => {
                                        if (voteAmount && balance && isGreaterThan(numToWei(voteAmount), balance)) {
                                            setVoteAmount(weiToNumber(balance).toString())
                                        }
                                    }}
                                    onChange={(e) => {
                                        setVoteAmount(e.target.value.replace(/[^\d.]/g, ''))
                                    }}
                                />} name={' '} addonAfter={(<img onClick={() => {
                                    console.log('set max amount', ethBalance)
                                    setVoteAmount(fromWei(balance))
                                }} src={icon_max}/>)}
                                    // extra={<span style={{ bottom: 9 }}>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>}
                                />

                                <Button style={{
                                    backgroundColor: status === 'Closed' || status === 'Filled' ? '#D3D3DA' : '',
                                    marginTop: 40
                                }} black onClick={onVote}>Stake to support</Button>
                            </div>
                        </Pool.Content>
                    )}

                    {(voteStatus === 'Successfully' || isMine ) && (
                        <>
                            <Pool.Content width={isXSDown ? '100%' : '456px'} style={{marginTop: 0}}>

                                <Pool.Content width={isXSDown ? '100%' : '456px'}
                                              style={{marginTop: 0, flexDirection: 'column'}}>
                                    <Pool.Status style={{width: 'fit-content'}}
                                                 className={classNames('status', status)}><i
                                        className={status}></i>{status}</Pool.Status>
                                    <ITextR style={{
                                        marginTop: 8,
                                        textAlign: 'left'
                                    }}>{`Participant: ${onlyBOT ? 'BOT holder' : 'Public'}`}</ITextR>
                                </Pool.Content>

                                <Pool.Block style={{width: '100%'}}>
                                    <span>Fixed Swap Ratio</span>
                                    <span>{fromAmount && toAmount && `1 ${toSymbol} = ${(weiDiv(fromWei(fromAmount, decimals), fromWei(toAmount, toDecimals)))} ${symbol && symbol}`}</span>
                                </Pool.Block>

                                <Pool.Block style={{width: isXSDown ? '100%' : '200px'}}>
                                    <span>Price,$</span>
                                    <span>{(toAmount && fromAmount) && new BigNumber(weiDiv(fromWei(toAmount, toDecimals), fromWei(fromAmount, decimals))).multipliedBy(toDecimals ? '1' : ethPrice).toFixed(6).toString()}</span>
                                </Pool.Block>

                                <Pool.Block style={{width: isXSDown ? '100%' : '200px'}}>
                                    <span>Maximum Allocation per wallet</span>
                                    <span>{limit && (limit == 0 ? 'No limit' : `${weiToNumber(limit)} ${toSymbol}`)}</span>
                                </Pool.Block>

                                <OText5 style={{
                                    width: 480,
                                    marginTop: 40,
                                    fontSize: 12,
                                    fontFamily: 'IBM Plex Mono',
                                    fontWeight: 500
                                }}>Auction progress: {toBidAmount && weiToNumber(toBidAmount, toDecimals)} {toSymbol}
                                    <span
                                        style={{opacity: .3}}> / {toAmount && weiToNumber(toAmount, toDecimals)} {toSymbol}</span>
                                </OText5>
                                {fromBidAmount && fromAmount && (
                                    <Progress style={{marginTop: 16}} height={'5px'}
                                              className={classNames('progress', toBidAmount === toAmount ? 'Filled' : status)}>
                                        <Progress.Value style={{width: `${getProgress(toBidAmount, toAmount)}%`}}
                                                        className={classNames('progress-value', toBidAmount === toAmount ? 'Filled' : status)}/>
                                    </Progress>
                                )}

                            </Pool.Content>

                            <Pool.Content width={'auto'}
                                          style={{
                                              height: 'fit-content',
                                              width: isXSDown ? '100%' : 'auto',
                                              flexDirection: 'column',
                                              padding: isXSDown ? '48px 20px' : '48px 56px',
                                              justifyContent: 'center',
                                              marginTop: 0,
                                              backgroundColor: 'rgba(248, 248, 251, 1)'
                                          }}>

                                {isMine ? (
                                    <>
                                        <OText2 style={{textAlign: 'center', marginTop: 8}}>My Pool</OText2>
                                        {renderTime(leftTime)}
                                        <Pool.Meta>
                                            <div>Total amount:</div>
                                            <div>{`${toAmount && weiToNumber(toAmount, toDecimals)} ${toSymbol}`}</div>
                                        </Pool.Meta>

                                        <Pool.Meta>
                                            <div>Successful bid amount:</div>
                                            <div>{toBidAmount && `${weiToNumber(toBidAmount, toDecimals)} ${toSymbol}`}</div>
                                        </Pool.Meta>

                                        {((status === 'Closed' || voteStatus === 'Unsuccessfully') && !claimed) ?
                                            <Button black onClick={onClaim}>Claim your swapped tokens</Button> : null}

                                    </>
                                ) : (
                                    <>
                                        <OText2 style={{textAlign: 'center', marginTop: 0, fontSize: 26}}>Join The
                                            Pool</OText2>
                                        {renderTime(leftTime)}
                                        <LineDivider style={{marginTop: 0}}/>
                                        <Pool.topInfo>
                                            <span>Your Bid Amount</span>
                                            <span>{`Balance: ${ethBalance ? weiToNumber(ethBalance, toDecimals) : '--'}`} {toSymbol}</span>
                                        </Pool.topInfo>
                                        <Form top={'0px'} width={isXSDown ? '100%' : '320px'} input={<Input
                                            style={{
                                                padding: '8px 0',
                                                color: '#1F191B',
                                                fontSize: 16,
                                                lineHeight: '20px',
                                                fontFamily: 'Helvetica Neue',
                                                fontWeight: "bold"
                                            }}
                                            placeholder={'Bid Amount'}
                                            type='number'
                                            value={bidAmount}
                                            // onBlur={() => {
                                            //     if (bidAmount && ethBalance && isGreaterThan(numToWei(bidAmount, toDecimals), ethBalance)) {
                                            //         setBidAmount(weiToNumber(ethBalance, toDecimals).toString())
                                            //     }
                                            // }}
                                            onChange={(e) => {
                                                setBidAmount(e.target.value.replace(/[^\d.]/g, ''))
                                            }}
                                        />} name={' '} addonAfter={(<img onClick={() => {
                                            console.log('set max amount', ethBalance)
                                            setBidAmount(fromWei(ethBalance, toDecimals))
                                        }} src={icon_max}/>)}
                                            // extra={<span style={{ bottom: 9 }}>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>}
                                        />

                                        <Button style={{
                                            backgroundColor: status === 'Closed' || status === 'Filled' ? '#D3D3DA' : '',
                                            marginTop: 50
                                        }} black onClick={onBid}>Go</Button>
                                        <TipLink/>
                                    </>
                                )}


                            </Pool.Content>
                        </>
                    )}
                </Pool.Content>


                <Pool.Content style={{marginTop: 40}}>


                </Pool.Content>


            </LayoutFrame>


            <BidModal modalStatus={bidStatus} onDismiss={() => {
                setBidStatus(initStatus)
            }}/>

            <PasswordModal error={errorPW} onDismiss={() => {
                setPasswordModal(false)
            }} onChange={(password) => {
                setEnterPW(password)
            }} onConfirm={() => {
                console.log('password', password, new BN(md5(enterPW)).toString())
                if (new BN(md5(enterPW)).toString() === password) {
                    console.log('confirm password')
                    setPasswordModal(false)
                    setErrorPW(null)
                } else {
                    setEnterPW('password is wrong, please enter again')
                }
            }} show={passwordModal}/>
        </LayoutFrame>
    )
}
