import {useEffect, useState} from 'react';
import Web3 from 'web3';
import {InjectedConnector} from "@web3-react/injected-connector";
import {WalletConnectConnector} from "@web3-react/walletconnect-connector";
import {WalletLinkConnector} from "@web3-react/walletlink-connector";
import {LedgerConnector} from "@web3-react/ledger-connector";
import {TrezorConnector} from "@web3-react/trezor-connector";
import {FrameConnector} from "@web3-react/frame-connector";
import {PortisConnector} from "@web3-react/portis-connector";
import {SquarelinkConnector} from "@web3-react/squarelink-connector";
import {TorusConnector} from "@web3-react/torus-connector";
import {AuthereumConnector} from "@web3-react/authereum-connector";
import {FortmaticConnector} from "@web3-react/fortmatic-connector";

const POLLING_INTERVAL = 12000;
const RPC_URLS = {
    1: "https://eth-mainnet.alchemyapi.io/v2/k2--UT_xVVXMOvAyoxJYqtKhlmyBbqnX",
    4: "https://rinkeby.infura.io/v3/bd80ce1ca1f94da48e151bb6868bb150"
};

const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42, 79377087078960]
});

const walletconnect = new WalletConnectConnector({
    rpc: {1: RPC_URLS[1]},
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: POLLING_INTERVAL
});

const walletlink = new WalletLinkConnector({
    url: RPC_URLS[1],
    appName: "ygov.finance"
});

const ledger = new LedgerConnector({
    chainId: 1,
    url: RPC_URLS[1],
    pollingInterval: POLLING_INTERVAL
});

const trezor = new TrezorConnector({
    chainId: 1,
    url: RPC_URLS[1],
    pollingInterval: POLLING_INTERVAL,
    manifestEmail: "dummy@abc.xyz",
    manifestAppUrl: "https://8rg3h.csb.app/"
});

const frame = new FrameConnector({supportedChainIds: [1]});

const fortmatic = new FortmaticConnector({
    apiKey: "pk_live_F95FEECB1BE324B5",
    chainId: 1
});

const portis = new PortisConnector({
    dAppId: "790d2f80-46b8-4475-baa8-d53a7efad388",
    networks: [1, 100]
});

const squarelink = new SquarelinkConnector({
    clientId: "5f2a2233db82b06b24f9",
    networks: [1, 100]
});

const torus = new TorusConnector({chainId: 1});

const authereum = new AuthereumConnector({chainId: 1});

const wallets = {
    MetaMask: injected,
    TrustWallet: injected,
    WalletConnect: walletconnect,
    WalletLink: walletlink,
    Ledger: ledger,
    Trezor: trezor,
    Frame: frame,
    Fortmatic: fortmatic,
    Portis: portis,
    Squarelink: squarelink,
    Torus: torus,
    Authereum: authereum
}

export const initWeb3 = async () => {
    return new Web3('https://eth-02.dccn.ankr.com');
}

export const useActiveWeb3 = () => {

    const [netWorkId, setNetWorkId] = useState();
    const [web3, setWeb3] = useState(undefined);



    useEffect(() => {


        initWeb3().then(web3 => {
            console.log('web3:', web3)
            setWeb3(web3)
        });
    }, []);

    return web3
}