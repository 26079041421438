export const HANDLE_SHOW_CONNECT_MODAL = 'HANDLE_SHOW_CONNECT_MODAL';

export const HANDLE_WEB3_CONTEXT = 'HANDLE_WEB3_CONTEXT';

export const HANDLE_MY_NFTS_MODAL = 'HANDLE_MY_NFTS_MODAL';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const HANDLE_SHOW_MENU_MODAL = 'HANDLE_SHOW_MENU_MODAL';

export const HANDLE_SHOW_USER_DETAIL = 'HANDLE_SHOW_USER_DETAIL';

export const HANDLE_SHOW_SEARCH_DETAIL = 'HANDLE_SHOW_SEARCH_DETAIL';


export const BIDDER_CLAIMED_MESSAGE = 'Thank you for your participation! You have successfully claimed your tokens';
export const CREATOR_CLAIMED_MESSAGE = 'Thank you! You have successfully settled your pool and claimed your tokens';
