import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Slide from '@material-ui/core/Slide';
import icon_arrow_down from '../../static/image/icon-arrow-down.svg'
import icon_arrow_up from '../../static/image/icon-arrow-up.svg'
import icon_selected from '../../static/image/icon-selected.svg'


export const SelectFrame = styled.div`
  user-select:none;
  width: ${({ width }) => (width ? width : '320px')};
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  border-bottom: 1px ${({ theme, border }) => (border ? theme.grey3 : 'transparent')} solid;
  position: relative;
`

SelectFrame.Divider = styled.div`
  width: 1.05px;
  height: 40px;
  margin: auto 20px;
  background-color: ${({ theme }) => (theme.grey2)};
`

SelectFrame.Name = styled.span`
font-family: Helvetica Neue;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 15px;
 color: ${({ color, theme }) => (color ? color : theme.grey3)};
 height: 15px;
 position: absolute;
 margin-top: -6px;
 user-select: none;
`

const OptionFrame = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  box-shadow: 4px 14px 20px -8px rgba(0, 0, 0, 0.17);
   color: ${({ color, theme }) => (color ? color : theme.grey3)};
  position: absolute;
  margin-top: 49px;
  cursor: pointer;
  max-height: 150px;
  overflow: auto;
  z-index: 2;
`

const SelectedOption = styled.input`
  width: 100%;
  height: 36px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  line-height: 40px;
  border: none;
  color: transparent;
  text-shadow: 0 0 0 ${({ theme, selected }) => (selected ? theme.black : theme.grey3)};
  justify-content: space-between;
  &:hover{
      opacity: .7;
  }
  &:hover+img{
      opacity: .7;
  }
  opacity: 0;
`

const SelectedOptionFrame = styled.div`
  width: 90%;
  height: 40px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  line-height: 40px;
  border: none;
  text-shadow: 0 0 0 ${({ theme, selected }) => (selected ? theme.black : theme.grey3)};
  /* justify-content: space-between; */
  position: absolute;
  text-align: left;
  display: flex;
  align-items: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Option = styled.div`
    width: ${({ width }) => (width ? width : '320px')};
    height: 40px;
    line-height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    overflow: auto;
    &.selected{
        /* border-bottom: 1px solid #ccc; */
    }
    >img{
      width: 10px;
      margin: 0 10px;
    }
    &:hover{
      background-color: black;
      color: white;
      cursor: pointer;
    }

    .ImgOption{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        img{
            width: auto;
            height: 21px;

            &.defaultItem{
               display: block;
            }

            &.selectItem{
                display: none;
            }
        }

        &:hover{
            .defaultItem{
               display: none;
            }

            .selectItem{
                display: block;
            }
        }
    }
`

export const Select = ({ name, defaultContent, defaultValue, options, onSelect, width, extra, border, disabled, white, selectColor }) => {

    const [status, setStatus] = useState(false)
    const [selectItem, setSelectItem] = useState(defaultValue && defaultValue)
    // useEffect(() => {
    //     if (!defaultValue) return
    //     setSelectItem(defaultValue)
    // }, [defaultValue])

    return (
        <SelectFrame width={width} border={!defaultValue || border}
            onClick={() => {
                if (!disabled) {
                    setStatus(!status)
                }
            }}
        >
            <input style={{ width: 0, border: 'none' }} />
            <SelectFrame.Name color={white ? '#FFF' : ''}>{selectItem && defaultContent && !defaultValue}</SelectFrame.Name>

            <SelectedOptionFrame style={{ color: !selectItem ? 'rgba(31,25,27,0.3)' : white ? '#FFF' : '' }}>{extra && extra}
                <span style={{ color: selectColor, marginLeft: 5 }}>{selectItem ? selectItem.value : defaultContent}</span>
            </SelectedOptionFrame>
            <SelectedOption
                color={white ? '#FFF' : '#000'}
                selected={selectItem}
                onBlur={() => {
                    setTimeout(() => {
                        setStatus(false)
                    }, 200)
                }}
                // onFocus={() => {
                //     setStatus(true)
                // }}
                value={``}>
            </SelectedOption>
            <img src={status ? icon_arrow_up : icon_arrow_down} />
            {status && (
                <OptionFrame>
                    {options.map(item => {
                        return (<Option
                            width={width}
                            key={item.key}
                            onClick={() => {
                                onSelect && onSelect(item)
                                setSelectItem(item)
                            }}
                            className={selectItem === item ? 'selected' : ''}
                        >
                            {<img src={selectItem === item && icon_selected} />}
                            {item.value}
                        </Option>)
                    })}
                </OptionFrame>
            )}

        </SelectFrame>
    )
}

export const Select_Tabbar = ({ name, defaultContent, defaultValue, options, onSelect, width, extra, border, disabled, white, selectColor }) => {
    const [status, setStatus] = useState(false)
    const [selectItem, setSelectItem] = useState(defaultValue && defaultValue)
    useEffect(() => {
        if (!defaultValue) return
        setSelectItem(defaultValue)
    }, [defaultValue])

    return (
        <SelectFrame width={width} border={!defaultValue || border}
            onClick={() => {
                if (!disabled) {
                    setStatus(!status)
                }
            }}
        >
            <input style={{ width: 0, border: 'none' }} />
            <SelectFrame.Name color={white ? '#FFF' : ''}>{selectItem && defaultContent && !defaultValue}</SelectFrame.Name>

            <SelectedOptionFrame style={{ color: !selectItem ? 'rgba(31,25,27,0.3)' : white ? '#FFF' : '' }}>{extra && extra}
                <span style={{ color: selectColor, marginLeft: 5 }}>{selectItem ? selectItem.value : defaultContent}</span>
            </SelectedOptionFrame>
            <SelectedOption
                color={white ? '#FFF' : '#000'}
                selected={selectItem}
                onBlur={() => {
                    setTimeout(() => {
                        setStatus(false)
                    }, 200)
                }}
                // onFocus={() => {
                //     setStatus(true)
                // }}
                value={``}>
            </SelectedOption>
            <img src={status ? icon_arrow_up : icon_arrow_down} />
            {status && (
                <OptionFrame>
                    {options.map(item => {
                        return (<Option
                            width={width}
                            key={item.key}
                            onClick={() => {
                                onSelect && onSelect(item)
                                setSelectItem(item)
                            }}
                            className={selectItem === item ? 'selected' : ''}
                        >
                            {<img src={selectItem === item && icon_selected} />}
                            {item.value}
                        </Option>)
                    })}
                </OptionFrame>
            )}

        </SelectFrame>
    )
}

export const Select_img = ({ name, defaultContent, defaultValue, options, onSelect, width, extra, border, disabled, white }) => {
    // console.log('width', width)
    const [status, setStatus] = useState(false)
    const [selectItem, setSelectItem] = useState(defaultValue && defaultValue)


    return (
        <SelectFrame width={width}
            //  border={!defaultValue || border}
            onClick={() => {
                if (!disabled) {
                    setStatus(!status)
                }
            }}
        >
            <input style={{ width: 0, border: 'none' }} />
            <SelectFrame.Name color={white ? '#FFF' : ''}>{selectItem && defaultContent && !defaultValue}</SelectFrame.Name>

            <SelectedOptionFrame style={{ color: !selectItem ? 'rgba(31,25,27,0.3)' : white ? '#FFF' : '' }}>{extra && extra}&nbsp;&nbsp; {<img src={selectItem.icon} />}</SelectedOptionFrame>
            <SelectedOption
                color={white ? '#FFF' : '#000'}
                selected={selectItem}
                onBlur={() => {
                    setTimeout(() => {
                        setStatus(false)
                    }, 200)
                }}
                // onFocus={() => {
                //     setStatus(true)
                // }}
                value={``}>
            </SelectedOption>
            <img src={status ? icon_arrow_up : icon_arrow_down} />
            {status && (
                <OptionFrame>
                    {options.map(item => {
                        return (<Option
                            width={width}
                            key={item.key}
                            onClick={() => {
                                onSelect && onSelect(item)
                                setSelectItem(item)
                            }}
                            className={selectItem === item ? 'selected' : ''}
                        >
                            {<img src={selectItem === item && icon_selected} />}
                            {
                                <div className='ImgOption'>
                                    <img className='defaultItem' src={item.icon} alt="" />
                                    <img className='selectItem' src={item.icon_select} alt="" />
                                </div>
                            }
                        </Option>)
                    })}
                </OptionFrame>
            )}

        </SelectFrame>
    )
}


export const Select1 = ({ name, defaultContent, defaultValue, options, onSelect, width, extra }) => {
    const [status, setStatus] = useState(false)
    const [selectItem, setSelectItem] = useState(defaultValue && defaultValue)


    return (
        <SelectFrame width={width} border={!defaultValue}
            onClick={() => {
                setStatus(!status)
            }}
        >
            <input style={{ width: 0, border: 'none' }} />
            <SelectFrame.Name>{selectItem && defaultContent && !defaultValue}</SelectFrame.Name>
            <SelectedOptionFrame>{extra && extra} {selectItem ? selectItem.value : defaultContent}</SelectedOptionFrame>
            <SelectedOption
                selected={selectItem}
                onBlur={() => {
                    setTimeout(() => {
                        setStatus(false)
                    }, 200)
                }}
                // onFocus={() => {
                //     setStatus(true)
                // }}
                value={''}>
            </SelectedOption>
            <img src={status ? icon_arrow_up : icon_arrow_down} />
            {status && (
                <OptionFrame>
                    {options.map(item => {
                        return (<Option
                            width={width}
                            key={item.key}
                            onClick={() => {
                                onSelect && onSelect(item)
                                setSelectItem(item)
                            }}>
                            {<img src={selectItem === item && icon_selected} />}
                            {item.value}
                        </Option>)
                    })}
                </OptionFrame>
            )}

        </SelectFrame>
    )
}

export const Select2 = ({ name, defaultContent, defaultValue, options, onSelect, width, extra }) => {
    const [status, setStatus] = useState(false)
    const [selectItem, setSelectItem] = useState(defaultValue && defaultValue);

    return (
        <SelectFrame width={width} border={!defaultValue}>
            <input style={{ width: 0, border: 'none' }} />
            <SelectFrame.Name>{selectItem && defaultContent && !defaultValue}</SelectFrame.Name>
            <SelectedOptionFrame>{extra && extra} {selectItem ? selectItem.value : defaultContent}</SelectedOptionFrame>
            <SelectedOption
                selected={selectItem}
                onBlur={() => {
                    setTimeout(() => {
                        setStatus(false)
                    }, 200)

                }}
                onFocus={() => {
                    setStatus(true)
                }} value={''}>

            </SelectedOption>
            <img src={status ? icon_arrow_up : icon_arrow_down} />
            {status && (
                <OptionFrame>
                    {options.map(item => {
                        return (<Option
                            width={width}
                            key={item.key}
                            onClick={() => {
                                onSelect && onSelect(item)
                                setSelectItem(item)
                            }}>
                            {<img src={selectItem === item && icon_selected} />}
                            {item.value}
                        </Option>)
                    })}
                </OptionFrame>
            )}

        </SelectFrame>
    )
}

export const Select3 = ({ name, defaultContent, defaultValue, options, onSelect, width, extra, border, disabled }) => {

    // console.log('width', width)
    const [status, setStatus] = useState(false)
    const [selectItem, setSelectItem] = useState(defaultValue && defaultValue)


    return (
        <SelectFrame width={width} border={!defaultValue || border}
            onClick={() => {
                if (!disabled) {
                    setStatus(!status)
                }
            }}
        >
            <input style={{ width: 0, border: 'none' }} />
            <SelectFrame.Name>{selectItem && defaultContent && !defaultValue}</SelectFrame.Name>
            <SelectedOptionFrame>{extra && extra} {selectItem ? selectItem.value : defaultContent}</SelectedOptionFrame>
            <SelectedOption
                selected={selectItem}
                onBlur={() => {
                    setTimeout(() => {
                        setStatus(false)
                    }, 200)
                }}
                value={``}>

            </SelectedOption>
            <img src={status ? icon_arrow_up : icon_arrow_down} />
            {status && (
                <OptionFrame>
                    {options.map(item => {
                        return (<Option
                            width={width}
                            key={item.key}
                            onClick={() => {
                                onSelect && onSelect(item)
                                setSelectItem(item)
                            }}
                            className={selectItem === item ? 'selected' : ''}
                        >
                            {<img src={selectItem === item && icon_selected} />}
                            <>{item.value}</>
                        </Option>)
                    })}
                </OptionFrame>
            )}

        </SelectFrame>
    )
}
