import React, {useState, useCallback, useEffect, useContext} from 'react';
import {t} from '../utils/intl';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import tip from '../static/image/tip.svg'
import {RectPoolItem} from '../components/RectPoolItem'
import {useActiveWeb3} from '../hooks/useActiveWeb3'


import {
  TO,
  getContractLink,
  getRatio,
  getBidRatio,
  queryPoolListLength,
  queryPoolItem,
  queryBidPoolListLength,
  queryDutchPoolListLength,
  queryNFTPoolListLength,
  queryBidPoolItem,
  queryDutchPoolItem,
  queryNFTPoolItem,
  BOUNCE_ERC20_ADDRESS,
  loadRatio, queryNFT1155PoolListLength, queryNFT1155PoolItem, queryEnglishAuctionPoolListLength,
  queryEnglishAuctionPoolItem, querySBNFTAuctionPoolListLength, querySBNFTAuctionPoolItem
} from './const';
import {EmptyIndex} from './content/EmptyIndex';
import {Select} from '../components/Select'
import web3 from 'web3';
import {closedList} from './const'
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {myContext} from '../reducer'
import close_icon from '../static/image/close.svg'
import warning from '../static/image/warning.svg'
import down from '../static/image/down.svg'
import {useParams} from 'react-router-dom';


const BN = web3.utils.BN;

const useStyles = makeStyles(() => ({
  root: {
    width: 480,
    margin: 'auto',
    position: 'absolute',
    borderRadius: 10,
    border: '1px solid #000000',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.12)',
    padding: 20,
    top: '20%',
    left: 0,
    right: 0
  },
}));

export const IndexContent = ({
                               onPoolUpdate,
                             }) => {
  const {state, dispatch} = useContext(myContext);
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [pools, setPools] = useState([]);
  const [poolMode, setPoolMode] = useState(true);
  const [bidPools, setBidPools] = useState([]);
  const [dutchPools, setDutchPools] = useState([]);
  const [NFTPools, setNFTPools] = useState([]);
  const [NFT1155Pools, setNFT1155Pools] = useState([]);
  const [englishAuctionPools, setEnglishAuctionPools] = useState([]);
  const [SBAuctionPools, setSBAuctionPools] = useState([]);
  const [search, setSearch] = useState('');
  const [searchIng, setSearchIng] = useState(false)
  const [searchList, setListSearch] = useState([]);
  const [selectPool, setSelectPool] = useState(false);
  const [modal, setModal] = useState(false);
  const [tokenAddress, setTokenAddress] = useState('')
  const [clickIndex, setClickIndex] = useState(-1);
  const [poolStatus, setPoolStatus] = useState('All');
  const [queriedBidPool, setQueriedBidPool] = useState(false);
  const [queriedSwapPool, setQueriedSwapPool] = useState(false);
  const [queriedDutchPool, setQueriedDutchPool] = useState(false);
  const [queriedNFTPool, setQueriedNFTPool] = useState(false);
  const [queriedEnglishAuctionPools, setQueriedEnglishAuctionPools] = useState(false);
  const [queriedSBNFTAuctionPools, setQueriedSBNFTAuctionPools] = useState(false);



  const [poolType, setPoolType] = useState(-1);


  const {web3} = state;

  //console.log('useActiveWeb3:',useActiveWeb3())
  const handleClick = useCallback((index, type) => {
    onPoolUpdate(index);
    if (poolType === 0) {
      history.push(`/join/swap/${index}`, {id: index});
    } else if (poolType === 1) {
      history.push(`/join/bid/${index}`, {id: index});
    } else if (poolType === 2) {
      history.push(`/join/dutch/${index}`, {id: index});
    } else if (poolType === 3) {
      if (type === 'nft1155') {
        history.push(`/join/nft1155/1155/${index}`, {id: index});
      } else {
        history.push(`/join/nft/${index}`, {id: index});
      }
    } else if (poolType === 4) {
      history.push(`/join/english-auction-pool/${index}`, {id: index});
    }else if (poolType === 5) {
      history.push(`/join/sealed-bid-nft-auction-pool/${index}`, {id: index});
    }

  }, [history, onPoolUpdate, poolType]);


  const renderRatio = ((type, pool) => {
    switch (type) {
      case 0:
        return (
            <>
              <span style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>{`1 ${TO} = ${getRatio(pool)} ${pool.fromSymbol}`}
                  </span>
              <span className='tip-message'>
                  {`1 ${TO} = ${getRatio(pool)} ${pool.fromSymbol}`}
                  </span>
            </>
        )
      case 1:
        return (
            <>
              <span style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>{`1 ${TO} = ${getBidRatio(pool)} ${pool.fromSymbol}`}
                  </span>
              <span className='tip-message'>
                  {`1 ${TO} = ${getBidRatio(pool)} ${pool.fromSymbol}`}
                  </span>
            </>
        )
      case 2:
        return (
            <>
              <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {`1 ${pool.fromSymbol} = ${loadRatio(pool.maxPrice, pool.tokenTotal, '18', pool.decimals)} ${TO}`}
                  </span>
              <span className='tip-message'>
                  {`1 ${pool.fromSymbol} = ${loadRatio(pool.maxPrice, pool.tokenTotal, '18', pool.decimals)} ${TO}`}
                  </span>
            </>
        )
      default:
        return '...'
    }

  });

  const renderAmount = (amount) => {
    return amount + ` ${TO}`;
  }

  useEffect(() => {
    const {type} = params
    console.log('params pool type', type)
    if (type === 'bid') {
      setPoolType(1);
      setPoolMode(true);
      dispatch({type: 'HANDLER_CUR_POOL_TYPE', curPoolType: 1});
    } else if (type === 'swap') {
      setPoolType(0);
      setPoolMode(true);
      dispatch({type: 'HANDLER_CUR_POOL_TYPE', curPoolType: 0});
    } else if (type === 'dutch') {
      setPoolType(2);
      setPoolMode(true);
      dispatch({type: 'HANDLER_CUR_POOL_TYPE', curPoolType: 2});
    } else if (type === 'nft') {
      setPoolType(3);
      setPoolMode(false);
      dispatch({type: 'HANDLER_CUR_POOL_TYPE', curPoolType: 3});
    } else if (type === 'english-auction-pool') {
      setPoolType(4)
      setPoolMode(false);
      dispatch({type: 'HANDLER_CUR_POOL_TYPE', curPoolType: 4});
    }else if (type === 'sealed-bid-nft-auction-pool') {
      console.log('HANDLER_CUR_POOL_TYPE')
      setPoolType(5)
      setPoolMode(false);
      dispatch({type: 'HANDLER_CUR_POOL_TYPE', curPoolType: 5});
    } else {
      setPoolType(1);
      dispatch({type: 'HANDLER_CUR_POOL_TYPE', curPoolType: 1});
    }
  }, [params]);

  useEffect(() => {
    async function loadAddress() {
      const address = await getContractLink(selectPool.fromAddress);
      setTokenAddress(address);
    }

    loadAddress()
  }, [selectPool]);

  const renderPool = (() => {

    let poolList = [];
    switch (poolType) {
      case 0:
        poolList = pools;
        break;
      case 1:
        poolList = bidPools;
        break;
      case 2:
        poolList = dutchPools;
        break;
      case 3:
        poolList = NFT1155Pools.concat(NFTPools);
        break;
      case 4:
        poolList = englishAuctionPools;
        break;
      case 5:
        poolList = SBAuctionPools;
        break;
      default:
        poolList = pools
    }
    poolList = searchIng ? searchList : poolList;
    poolList = poolList.filter(item => {
      if (poolStatus === 'All') {
        return true
      } else {
        return item.status === poolStatus
      }
    })
    return (!poolMode) ?
        poolList.map(item => {
          return <RectPoolItem pool={item} onJoin={() => {
            setModal(true);
            setSelectPool(item)
          }}/>
        })
        : poolList.map(item => {
          const isExpired = new Date(item.time * 1000) <= Date.now();
          let status;
          if (!isExpired) {
            status = 'Live';
          } else {
            status = 'Closed';
          }
          return <div className="mobile-pool-item">
            <ul className='pool-item pool-item-home' key={item.index}>
              <li className={classNames('status', item.status)}>{item.status}</li>
              <li className='pool-id'>{item.index}</li>

              <li className='pool-auth'>
                <div className='tip'>
                  <span>{`${item.name}`}</span>
                  <span className='tip-message'>{item.name}</span>
                </div>
              </li>
              <li className='pool-contract'>
                <div className='tip'>
                  <span>{`${item.fromAddress.replace(item.fromAddress.substring(7, item.fromAddress.length - 5), "...")}`}</span>
                  <a href={item.address} rel="noopener noreferrer" className='tip-message'
                     target='_blank'>{item.fromAddress}</a>

                </div>
              </li>

              <li className='row pool-pair'>
                <div className='tip'>
                  <a href={item.address} rel="noopener noreferrer" className='tip-message'
                     target='_blank'>{item.fromAddress}</a>
                </div>
                <div className='tip'>
                  <span>{item.fromSymbol}{`/${TO}`}</span>
                  <span className='tip-message'>{`bounce level: ${renderAmount(item.amount)}`}</span>
                </div>
              </li>
              <li className='tip pool-amount'>
                {renderRatio(poolType, item)}
              </li>
              <li className='tip pool-amount'>{item.password && item.password != 0 ? 'Private' : item.onlyBot ? 'Bot holders' : 'Public'}</li>
              <li className={classNames('status', item.status)}>{item.status}</li>
              {status === 'Closed'
                  ? <li className='button disabled' style={{cursor: poolType === 0 ? '' : 'pointer'}}
                        onClick={() => {
                          if (poolType === 1) {
                            setModal(true)
                            setSelectPool(item)
                          }
                        }}>{poolType === 0 || poolType === 2 ? 'Join' : 'Result'}</li>
                  : <li className='button' onClick={() => {
                    setModal(true)
                    setSelectPool(item)
                  }}>Join</li>
              }
              <img className="more-info" onClick={() => {
                setClickIndex(item.index === clickIndex ? -1 : item.index)
              }} src={down}/>
            </ul>
            <div className={`${clickIndex === item.index ? 'expanded' : 'un-expanded'}`}>
              <div className="mobile-pool-extra-item">
                <p>ID</p>
                <p>{item.index}</p>
              </div>
              <div className="mobile-pool-extra-item">
                {renderRatio(poolType, item)}
                {/*{*/}
                {/*poolType === 0 ? (*/}
                {/*<>*/}
                {/*<p>Swap ratio</p>*/}
                {/*<p className="tip">{`1 ${TO} = ${getRatio(item)} ${item.fromSymbol}`}*/}
                {/*<p className="tip-message">{`1 ${TO} = ${getRatio(item)} ${item.fromSymbol}`}</p>*/}
                {/*</p>*/}
                {/*</>*/}
                {/*) : (*/}
                {/*<>*/}
                {/*<p>Swap ratio</p>*/}
                {/*<p className="tip">{`1 ${TO} = ${getBidRatio(item)} ${item.fromSymbol}`}*/}
                {/*<p className="tip-message">{`1 ${TO} = ${getBidRatio(item)} ${item.fromSymbol}`}</p>*/}
                {/*</p>*/}
                {/*</>*/}
                {/*)*/}
                {/*}*/}
              </div>
              <div className="mobile-pool-extra-item">
                <p>Participant:</p>
                <p>{item.onlyBot ? 'Bot holders' : 'Public'}</p>
              </div>
            </div>
          </div>
        })
  });

  // const query = useCallback((start) => {
  //   queryPools(start)
  //       .then(r => {
  //         if (start < r.pages) {
  //           query(start + 1);
  //         }
  //       })
  //       .catch(e => {
  //         console.log(e);
  //       });
  // });


  useEffect(() => {
    async function querySwapPoolList() {
      const tokenAddress = await BOUNCE_ERC20_ADDRESS(web3);
      const response = await queryPoolListLength(web3);
      let poolIdList = [];
      for (let i = 0; i < response; i++) {
        poolIdList[i] = response - i - 1;
      }
      const closedPools = await closedList();
      poolIdList = poolIdList.filter(item => {
        return !closedPools.includes(item)
      })

      if (poolIdList.length === 0) {
        setSearch([1])
      }

      let i = 0;
      let poolList;
      let queryIdList;
      let allPools = [];
      setQueriedSwapPool(true);
      while (poolIdList.length !== 0) {
        queryIdList = poolIdList.slice(0, 15);
        poolIdList.splice(0, 15);
        poolList = await Promise.all(queryIdList.map(async (item) => {
          const pool = await queryPoolItem(web3, item);
          if (pool.fromAddress) {
            pool.address = await getContractLink(pool.fromAddress)
          }
          pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
          const amount = pool.amount;
          if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
            poolIdList.unshift(item)
          }
          return pool
        }));
        poolList = poolList.filter(item => {
          const amount = web3.utils.fromWei(new BN(web3.utils.toWei(item.amount)));
          const fromSymbol = item.fromSymbol ? item.fromSymbol.toUpperCase() : '';
          const isFake = fromSymbol.toUpperCase() === 'B0T' || (fromSymbol.toUpperCase() === 'BOT' && item.fromAddress !== tokenAddress);
          return amount != 0 && item.from && item.amount && item.fromAddress && !isFake
        });
        allPools = allPools.concat(poolList)
        setPools(allPools);
        i++
      }
    }

    async function queryBidPollList() {
      const response = await queryBidPoolListLength(web3);
      let poolIdList = [];
      for (let i = 0; i < response; i++) {
        poolIdList[i] = response - i - 1;
      }

      if (poolIdList.length === 0) {
        setSearch([1])
      }

      let i = 0;
      let poolList;
      let queryIdList;
      let allPools = [];
      setQueriedBidPool(true);
      while (poolIdList.length !== 0) {
        queryIdList = poolIdList.slice(0, 15);
        poolIdList.splice(0, 15);
        poolList = await Promise.all(queryIdList.map(async (item) => {
          const pool = await queryBidPoolItem(web3, item);
          if (pool.fromAddress) {
            pool.address = await getContractLink(pool.fromAddress)
          }
          pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
          // const amount = pool.amount;
          // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
          //   poolIdList.unshift(item)
          // }
          return pool
        }));
        // poolList = poolList.filter(item => {
        //   const amount = poolType === 0?  web3.utils.fromWei(new BN(web3.utils.toWei(item.amount))): '';
        //   const fromSymbol = item.fromSymbol ? item.fromSymbol.toUpperCase() : '';
        //   const isFake = fromSymbol.toUpperCase() === 'B0T' || (fromSymbol.toUpperCase() === 'BOT' && item.fromAddress !== tokenAddress);
        //   return amount != 0 && item.from && item.amount && item.fromAddress && !isFake
        // });
        allPools = allPools.concat(poolList);

        setBidPools(allPools);
        i++
      }
    }

    async function queryDutchPollList() {
      const response = await queryDutchPoolListLength(web3);
      let poolIdList = [];
      for (let i = 0; i < response; i++) {
        poolIdList[i] = response - i - 1;
      }

      if (poolIdList.length === 0) {
        setSearch([1])
      }

      let i = 0;
      let poolList;
      let queryIdList;
      let allPools = [];
      setQueriedDutchPool(true);
      while (poolIdList.length !== 0) {
        queryIdList = poolIdList.slice(0, 15);
        poolIdList.splice(0, 15);
        poolList = await Promise.all(queryIdList.map(async (item) => {
          const pool = await queryDutchPoolItem(web3, item);
          if (pool.token0) {
            pool.address = await getContractLink(pool.token0)
          }
          pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
          // const amount = pool.amount;
          // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
          //   poolIdList.unshift(item)
          // }
          return pool
        }));
        // poolList = poolList.filter(item => {
        //   const amount = poolType === 0?  web3.utils.fromWei(new BN(web3.utils.toWei(item.amount))): '';
        //   const fromSymbol = item.fromSymbol ? item.fromSymbol.toUpperCase() : '';
        //   const isFake = fromSymbol.toUpperCase() === 'B0T' || (fromSymbol.toUpperCase() === 'BOT' && item.fromAddress !== tokenAddress);
        //   return amount != 0 && item.from && item.amount && item.fromAddress && !isFake
        // });
        allPools = allPools.concat(poolList);
        console.log('dutch pools', allPools)

        setDutchPools(allPools);
        i++
      }
    }

    async function queryNFTPollList() {
      const response = await queryNFTPoolListLength(web3);
      console.log('response:', response)
      let poolIdList = [];
      for (let i = 0; i < response; i++) {
        poolIdList[i] = response - i - 1;
      }

      if (poolIdList.length === 0) {
        setSearch([1])
      }

      let i = 0;
      let poolList;
      let queryIdList;
      let allPools = [];
      setQueriedNFTPool(true);
      while (poolIdList.length !== 0) {
        queryIdList = poolIdList.slice(0, 15);
        poolIdList.splice(0, 15);
        poolList = await Promise.all(queryIdList.map(async (item) => {
          const pool = await queryNFTPoolItem(web3, item);
          if (pool.token0) {
            pool.address = await getContractLink(pool.token0)
          }
          pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
          // const amount = pool.amount;
          // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
          //   poolIdList.unshift(item)
          // }
          return pool
        }));
        // poolList = poolList.filter(item => {
        //   const amount = poolType === 0?  web3.utils.fromWei(new BN(web3.utils.toWei(item.amount))): '';
        //   const fromSymbol = item.fromSymbol ? item.fromSymbol.toUpperCase() : '';
        //   const isFake = fromSymbol.toUpperCase() === 'B0T' || (fromSymbol.toUpperCase() === 'BOT' && item.fromAddress !== tokenAddress);
        //   return amount != 0 && item.from && item.amount && item.fromAddress && !isFake
        // });
        allPools = allPools.concat(poolList);
        console.log('dutch pools', allPools)

        setNFTPools(allPools);
        i++
      }

    }


    async function queryNFT1155PollList() {
      const response = await queryNFT1155PoolListLength(web3);
      console.log('queryNFT1155PollLength:', response)
      let poolIdList = [];
      for (let i = 0; i < response; i++) {
        poolIdList[i] = response - i - 1;
      }

      if (poolIdList.length === 0) {
        setSearch([1])
      }

      let i = 0;
      let poolList;
      let queryIdList;
      let allPools = [];
      setQueriedDutchPool(true);
      while (poolIdList.length !== 0) {
        queryIdList = poolIdList.slice(0, 15);
        poolIdList.splice(0, 15);
        poolList = await Promise.all(queryIdList.map(async (item) => {
          const pool = await queryNFT1155PoolItem(web3, item);
          if (pool.token0) {
            pool.address = await getContractLink(pool.token0)
          }
          pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
          // const amount = pool.amount;
          // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
          //   poolIdList.unshift(item)
          // }
          return pool
        }));
        // poolList = poolList.filter(item => {
        //   const amount = poolType === 0?  web3.utils.fromWei(new BN(web3.utils.toWei(item.amount))): '';
        //   const fromSymbol = item.fromSymbol ? item.fromSymbol.toUpperCase() : '';
        //   const isFake = fromSymbol.toUpperCase() === 'B0T' || (fromSymbol.toUpperCase() === 'BOT' && item.fromAddress !== tokenAddress);
        //   return amount != 0 && item.from && item.amount && item.fromAddress && !isFake
        // });
        allPools = allPools.concat(poolList);
        console.log('dutch pools', allPools)

        setNFT1155Pools(allPools);
        i++
      }
    }

    async function queryEnglishAuctionPollList() {
      const response = await queryEnglishAuctionPoolListLength(web3);
      console.log('queryEnglishAuctionPollList:', response)
      let poolIdList = [];
      for (let i = 0; i < response; i++) {
        poolIdList[i] = response - i - 1;
      }

      if (poolIdList.length === 0) {
        setSearch([1])
      }

      let i = 0;
      let poolList;
      let queryIdList;
      let allPools = [];
      setQueriedEnglishAuctionPools(true);
      while (poolIdList.length !== 0) {
        queryIdList = poolIdList.slice(0, 15);
        poolIdList.splice(0, 15);
        poolList = await Promise.all(queryIdList.map(async (item) => {
          const pool = await queryEnglishAuctionPoolItem(web3, item);
          if (pool.token0) {
            pool.address = await getContractLink(pool.token0)
          }
          pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
          // const amount = pool.amount;
          // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
          //   poolIdList.unshift(item)
          // }
          return pool
        }));
        // poolList = poolList.filter(item => {
        //   const amount = poolType === 0?  web3.utils.fromWei(new BN(web3.utils.toWei(item.amount))): '';
        //   const fromSymbol = item.fromSymbol ? item.fromSymbol.toUpperCase() : '';
        //   const isFake = fromSymbol.toUpperCase() === 'B0T' || (fromSymbol.toUpperCase() === 'BOT' && item.fromAddress !== tokenAddress);
        //   return amount != 0 && item.from && item.amount && item.fromAddress && !isFake
        // });
        allPools = allPools.concat(poolList);
        console.log('dutch pools', allPools)

        setEnglishAuctionPools(allPools);
        i++
      }
    }

    async function querySBAuctionPollList() {
      const response = await querySBNFTAuctionPoolListLength(web3);
      console.log('querySBNFTAuctionPoolListLength:', response)
      let poolIdList = [];
      for (let i = 0; i < response; i++) {
        poolIdList[i] = response - i - 1;
      }

      if (poolIdList.length === 0) {
        setSearch([1])
      }

      let i = 0;
      let poolList;
      let queryIdList;
      let allPools = [];
      setQueriedEnglishAuctionPools(true);
      while (poolIdList.length !== 0) {
        queryIdList = poolIdList.slice(0, 15);
        poolIdList.splice(0, 15);
        poolList = await Promise.all(queryIdList.map(async (item) => {
          const pool = await querySBNFTAuctionPoolItem(web3, item);
          if (pool.token0) {
            pool.address = await getContractLink(pool.token0)
          }
          pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
          // const amount = pool.amount;
          // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
          //   poolIdList.unshift(item)
          // }
          return pool
        }));
        // poolList = poolList.filter(item => {
        //   const amount = poolType === 0?  web3.utils.fromWei(new BN(web3.utils.toWei(item.amount))): '';
        //   const fromSymbol = item.fromSymbol ? item.fromSymbol.toUpperCase() : '';
        //   const isFake = fromSymbol.toUpperCase() === 'B0T' || (fromSymbol.toUpperCase() === 'BOT' && item.fromAddress !== tokenAddress);
        //   return amount != 0 && item.from && item.amount && item.fromAddress && !isFake
        // });
        allPools = allPools.concat(poolList);
        console.log('dutch pools', allPools)

        setSBAuctionPools(allPools);
        i++
      }
    }


    if (!queriedBidPool && poolType === 1) {
      console.log('queriedBidPool')
      queryBidPollList()
    } else if (!queriedSwapPool && poolType === 0) {
      console.log('queriedSwapPool')
      querySwapPoolList()
    } else if (!queriedDutchPool && poolType === 2) {
      queryDutchPollList()
      console.log('queryDutchPollList')
    } else if (!queriedNFTPool && poolType === 3) {
      queryNFTPollList()
      queryNFT1155PollList()
      console.log('queryNFTPollList')
    }else if (!queriedEnglishAuctionPools && poolType === 4) {
      queryEnglishAuctionPollList()
    } else if (!queriedSBNFTAuctionPools && poolType === 5) {
      querySBAuctionPollList()
    }

  }, [poolType]);

  const handleChange = ((event) => {
    const search = event.target.value;
    if (search === '') {
      setSearchIng(false)
    } else setSearchIng(true);
    const searchList = pools.filter(item => {
      let name = item.name;
      let symbol = item.fromSymbol
      if (typeof name === 'string' && typeof symbol) {
        name = name.toLowerCase();
        symbol = symbol.toLowerCase();
        return symbol.indexOf(search) > -1 || item.index == search || item.creator && item.creator.indexOf(search) > -1 || name && name.indexOf(search.toLowerCase()) > -1
            || item.fromAddress.indexOf(search) > -1 || item.toAddress && item.toAddress.indexOf(search) > -1;
      }
    })
    setListSearch(searchList);
  });

  const onNFTSearch = ((event) => {
    const search = event.target.value;
    if (search === '') {
      setSearchIng(false)
    } else setSearchIng(true);
    const searchList = NFTPools.filter(item => {
      return item.index == search || item.fromAddress.toLowerCase().indexOf(search.toLowerCase()) > -1;
    })
    setListSearch(searchList);
  });


  return (
      <>
        <div className='content'>

          <div className={`form home ${(!poolMode) && 'grid-box'}`}>
            <ul className='form-head' style={{
              border: (!poolMode) && '1px solid #000000',
              boxShadow: (!poolMode) && '0px 2px 24px rgba(0, 0, 0, 0.12)',
              boxSizing: (!poolMode) && 'border-box',
              borderRadius: (!poolMode) && 10
            }}>
              <li>{!poolMode ? 'Find a NFT Dutch Auction Pool' : t('index-content.title')}</li>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span className="select-tip"
                      style={{marginRight: 10, fontSize: 14}}>
                  {t('index-content.choice-pool-type')}
                  </span>
                <Select
                    optionsStyle={{width: 200}}
                    defaultOption={!params.type ||
                    params.type === 'swap' ? {'name': 'Fixed swap', 'value': 0, link: '/swap'}
                        : params.type === 'bid' ? {'name': 'Sealed  bid', 'value': 1, link: '/bid'}
                            : params.type === 'dutch' ? {'name': 'Dutch auction V1', 'value': 2, link: '/dutch'}
                                :params.type === 'nft'? {'name': 'NFT Dutch auction', 'value': 3, link: '/nft'}
                                  : params.type === 'english-auction-pool'? {'name': `NFT English auction`, 'value': 4, link: '/list/english-auction-pool'}
                                    : {'name': `Sealed bid NFT auction`, 'value': 5, link: '/list/sealed-bid-nft-auction-pool'}}
                    options={[
                      {'name': 'Fixed swap', 'value': 0, link: '/list/swap'},
                      {'name': 'Sealed  bid', 'value': 1, link: '/list/bid'},
                      {'name': 'Dutch auction V1', 'value': 2, link: '/list/dutch'},
                      {'name': `NFT Dutch auction 🔥`, 'value': 3, link: '/list/nft'},
                      {'name': `NFT English auction`, 'value': 4, link: '/list/english-auction-pool'},
                      {'name': `Sealed bid NFT auction`, 'value': 5, link: '/list/sealed-bid-nft-auction-pool'}
                    ]}
                    onSelect={(value) => {
                      console.log('on pool type selected', value)
                      setPoolMode(value === 0 || value === 1 || value === 2)
                      setPoolType(value)
                      dispatch({type: 'HANDLER_CUR_POOL_TYPE', curPoolType: value});
                    }}
                />
                <div className="filter">
                <span className="select-tip"
                      style={{marginLeft: 20, marginRight: 10, fontSize: 14}}>
                  {t('index-content.pool-status')}
                  </span>
                  <Select
                      layoutStyle={{width: 120}}
                      containerStyle={{width: 120}}
                      optionsStyle={{width: 120}}
                      onSelect={(e) => {
                        setPoolStatus(e)
                      }}
                      options={[
                        {'name': 'All', 'value': 'All'},
                        {'name': 'Live', 'value': 'Live'},
                        {'name': 'Filled', 'value': 'Filled'},
                        {'name': 'Closed', 'value': 'Closed'}]}/>
                </div>
              </div>
              {/*<span className="select">Fixed swap</span>*/}
            </ul>

            {(!poolMode)  &&
            <form className='search' style={{maxWidth: 1080, margin: '20px auto'}}>
              <input type='text' placeholder={'Pool ID, Token contract address'}
                     onChange={onNFTSearch}/>
              <span className='dark-button search-button'>{t('buttons.search')}</span>
            </form>}


            <div className='form-content' style={{padding: (!poolMode) && 0}}>
              {(poolMode) && (
                  <form className='search'>
                    <input type='text' placeholder={t('search-placeholder')}
                           onChange={handleChange}/>
                    <span className='dark-button search-button'>{t('buttons.search')}</span>
                  </form>
              )}

              {/* <p className='notice'>{tHTML('notice', {'tip': tip})}</p> */}
              {(pools.length === 0 && poolType === 0) ||
              (bidPools.length === 0 && poolType === 1) ||
              (dutchPools.length === 0 && poolType === 2) ||
              (NFTPools.length === 0 && poolType === 3) ||
              (englishAuctionPools.length === 0 && poolType === 4) ||
              (SBAuctionPools.length === 0 && poolType === 5)
                  ? <EmptyIndex isSearch={search.length > 0}/>
                  : <div className='pools'>
                    <ul className='pool-head' style={{display: (!poolMode) && 'none'}}>
                      <li/>
                      <li><span>{t('index-content.fixed-swap.id')}</span></li>
                      <li><span>{t('index-content.fixed-swap.pool-name')}</span></li>
                      <li><span>{t('index-content.fixed-swap.token-contract-address')}</span></li>
                      <li><span>{t('index-content.fixed-swap.pair')}</span></li>
                      <li><span className='tip'>
                        {poolType === 0 ? t('index-content.fixed-swap.swap-ratio') : poolType === 1 ? 'Floor Swap Ratio' : 'Start price'}
                        {poolType === 0 ? (
                            null
                        ) : (
                            <>
                              <img className='tip' src={tip}/>
                              <span
                                  className="tip-message">{'This is the floor price for your token auction'}</span>
                            </>
                        )}

                        </span>
                      </li>
                      <li><span>{t('index-content.fixed-swap.participant')}</span></li>
                      <li><span>{t('index-content.fixed-swap.status')}</span></li>
                    </ul>
                    <div className='pool-content' style={{height: (!poolMode) && 'auto'}}>
                      <div className='pool-content-container'
                           style={{display: 'flex', flexWrap: 'wrap'}}>
                        {renderPool()}
                      </div>
                    </div>
                  </div>}
            </div>
          </div>
        </div>
        <Modal open={modal}>
          {modal ? (
              <Card className={classes.root}>
                <CardHeader
                    action={
                      <img className="modal-close" src={close_icon} alt='close' onClick={_ => {
                        setModal(false)
                      }}/>
                    }
                />
                <CardContent>
                  <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 34
                  }}>
                    <img className="modal-close" src={warning} alt='warning'/><span style={{marginLeft: 20}}
                                                                                    className="modal-title">{t('index-content.token-imported')}</span>
                  </div>
                  <Typography>
                    {t('index-content.join-tip', {token: (!poolMode) ? 'ERC721' : 'ERC20'})}
                  </Typography>
                  <div style={{display: 'flex', marginTop: 10, color: 'red'}}>
                    {selectPool.fromSymbol}
                    <a style={{textDecorationLine: 'underline', marginLeft: 10}} href={tokenAddress}
                       target='_blank'
                       rel='noopener noreferrer'>{t('view-on-etherscan')}</a>
                  </div>
                  <div style={{marginTop: 10, color: 'red'}}>
                    {t('index-content.ratio-tip')}:
                    <span
                        style={{}}>{poolType === 0 ? `1 ${TO} = ${getRatio(selectPool)} ${selectPool.fromSymbol}`
                        : poolType === 1 ? `1 ${TO} = ${getBidRatio(selectPool)} ${selectPool.fromSymbol}`
                            : poolType === 2 ? `1 ${selectPool.fromSymbol} = ${loadRatio(selectPool.maxPrice, selectPool.tokenTotal, '18', selectPool.decimals)} ${TO}` :
                                poolType === 3? ` ${web3.utils.fromWei(selectPool.maxPrice)} ETH`: `${web3.utils.fromWei(selectPool.startingPrice)} ETH`}</span>
                  </div>
                  <span className='dark-button modal-button' onClick={() => {
                    handleClick(selectPool.index, selectPool.type)
                  }}>{t('index-content.confirm')}</span>
                </CardContent>
              </Card>
          ) : null}
        </Modal>
      </>
  )
}
