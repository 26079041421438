import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Pool, PoolFrame } from "../../components/common/Layout";
import icon_close from '../../static/image/icon-close.svg'
import { Form, FormStatus, Input } from "../../components/common/Form";
import icon_max from '../../static/image/icon-max.svg'
import icon_eth from '../../static/image/icon-eth-logo.svg'
import icon_wenhao from '../../static/image/wenhao.svg'
import icon_eye_open from '../../static/image/icon-eye-open.svg'

import Lottie from 'react-lottie';
import { useHandleForm } from '../../web3/common'
import { Button } from "../../components/common/Button";
import bounce_loading from '../../static/bounce-loading.json'
import Modal, { ModalContent, ModalTitle } from "../../components/common/Modal";
import { weiToNum, numToWei } from "../../utils/numberTransform";
import md5 from "js-md5";
import Web3 from 'web3'
import { getContract, useActivePlatform, useActiveWeb3React } from "../../web3";
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import dutchAuction from "../../web3/abi/dutchAuctionV1.json";
import { getDutchAuction } from "../../web3/contractAddress";
import icon_success from '../../static/image/icon-success.svg'
import icon_error from '../../static/image/icon-error.svg'
import { useIsXSDown } from '../../components/utils/themeHooks';

// 输入限制
import { isAddress } from "../../utils/address";
import { getTime, isEqualTo, isGreaterThan } from "../../utils/common";
import { validateForm } from "../../utils/form";

const BigNumber = require('bignumber.js');

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bounce_loading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const approveStatus = { status: 1, title: 'Bounce Requests Approval', content: 'Please enable Bounce to access your tokens' }
const confirmStatus = { status: 2, title: 'Bounce Requests confirm', content: 'Confirm this transaction in your wallet' }
const pendingStatus = { status: 3, title: 'Approving Bounce Finance', content: 'Your transaction was submitted and is pending' }
const successStatus = { status: 4, title: 'Transaction Confirmed', content: 'Your transaction was confirmed you can now submit your auction' }
const errorStatus = { status: -1, title: 'Transaction Failed', content: 'Your transaction was cancelled and wasn’t submitted' }
const cancelStatus = { status: 0, title: 'Transaction Failed', content: 'Your transaction was cancelled and wasn’t submitted' }



export const CreateDAPool = () => {
    const isXSDown = useIsXSDown();
    const { account, library, chainId } = useActiveWeb3React();
    const { Psymbol } = useActivePlatform()

    //0: none 1:pending 2:confirm 3: success -1:error -2:cancel
    const [launchStatus, setLaunchStatus] = useState({ status: 0, title: '', content: '' })
    const {
        address, setAddress,
        token,
        // amount, setAmount,
        amountError,
        times, setTimes,
        minAmount, setMinAmount,
        maxAmount, setMaxAmount,
        name, setName, nameError,
        onlyBot, setOnlyBot,
        password, setPassword,
        // limit, setLimit,
        days, setDays,
        hours, setHours,
        minutes, setMinutes,
        timeError,
        check, setCheck
    } = useHandleForm()
    const history = useHistory();
    const { toWei, BN } = Web3.utils
    const [isPrivate, setIsPrivate] = useState(false)

    // 输入限制
    const [errors, setErrors] = useState({ address: '', ratio: '', amount: '', limit: '' })
    const [symbol, setSymbol] = useState()
    const [balance, setBalance] = useState()
    const [decimals, setDecimals] = useState('18')
    const [ratio, setRatio] = useState()
    const [amount, setAmount] = useState()
    const [level, setLevel] = useState()
    const [limit, setLimit] = useState()

    const handleChange = async event => {
        event.preventDefault();
        const { name, value } = event.target;
        switch (name) {
            case "address":
                errors.address = ''
                setAddress(value)
                if (!isAddress(value)) {
                    errors.address = "Address is invalid"
                } else {
                    const tokenContract = getContract(library, bounceERC20.abi, value)
                    try {
                        setSymbol('Loading...')
                        const symbol = await tokenContract.methods.symbol().call()
                        setSymbol(symbol)
                        console.log('symbol:', symbol)
                    } catch (e) {
                        errors.address = 'Address is invalid'
                        console.log('get token symbol error:', e)
                    }
                    console.log('address error:')

                    try {
                        console.log('tokenContract:', tokenContract)
                        const balance = await tokenContract.methods.balanceOf(account).call()
                        if (!balance || isEqualTo(balance, '0')) {
                            errors.address = 'you have 0 balance under this token contract address'
                        }
                        setBalance(balance)
                        console.log('balance:', balance)
                        const decimals = await tokenContract.methods.decimals().call()
                        setDecimals(decimals)
                        console.log('decimals:', decimals)
                    } catch (e) {
                        console.log('getTokenInfo error:', e)
                    }
                }
                try {

                } catch (e) {
                    console.log('confirm token', e)
                    errors.tokenId = "Token ID is invalid"
                }
                break;
            case "ratio":
                errors.ratio = ''
                const ratioValue = value.replace(/[^\d.]/g, '')
                setRatio(ratioValue)
                break
            case "amount":
                errors.amount = ''
                const amountValue = value.replace(/[^\d.]/g, '')
                setAmount(amountValue)
                console.log('wei amount', numToWei(amountValue, decimals))
                if (!balance || (balance && isGreaterThan(numToWei(amountValue, decimals), balance))) {
                    errors.amount = 'you do not have enough balance'
                }
                break
            case "limit":
                errors.limit = ''
                const limitValue = value.replace(/[^\d.]/g, '')
                setLimit(limitValue)
                if (!level || (level && isGreaterThan(limitValue, level))) {
                    errors.limit = 'You need to enter a number that is smaller than your Bounce level'
                }
                break
            default:
        }
        console.log('final errors', errors)
        setErrors(errors)
    };


    const checkImgExists = (imgurl) => {
        let cookie_yes_icon = JSON.parse(window.localStorage.getItem('cookie_yes_icon')) || []
        let cookie_no_icon = JSON.parse(window.localStorage.getItem('cookie_no_icon')) || []
        if (cookie_yes_icon.includes(imgurl)) {
            return true;
        } else if (cookie_no_icon.includes(imgurl)) {
            return false
        } else {
            var ImgObj = new Image(); //判断图片是否存在
            ImgObj.src = imgurl;
            //存在图片
            ImgObj.onload = () => {
                cookie_yes_icon.push(imgurl)
                window.localStorage.setItem('cookie_yes_icon', JSON.stringify(cookie_yes_icon))
                return true;
            }

            ImgObj.onerror = () => {
                cookie_no_icon.push(imgurl)
                window.localStorage.setItem('cookie_no_icon', JSON.stringify(cookie_no_icon))
                return false;
            }
        }
    }

    const getIconImg = (token) => {
        let src = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${token}/logo.png`
        if (checkImgExists(src)) {
            return src
        } else {
            return icon_wenhao
        }
    }

    const handleLaunch = async () => {
        console.log('dutch', name, address, amount)
        if (!name || !address || !amount) {
            return;
        }
        const tokenContract = getContract(library, bounceERC20.abi, address)
        const bounceContract = getContract(library, dutchAuction.abi, getDutchAuction(chainId))
        const swapAmount = toWei(amount, 'ether');
        // const minAmount2 = toWei((new 3).div(1).toFixed(parseInt(token.decimals), 1)).toString()) // 3000000000000000000000 大
        const minAmount2 = toWei((new BigNumber(minAmount).multipliedBy(amount).toFixed(parseInt(token.decimals), 1)).toString())
        // const maxAmount2 = toWei((new 3.div(11).toFixed(parseInt(token.decimals), 1)).toString()) // 2777777777777777777 小
        const maxAmount2 = toWei((new BigNumber(maxAmount).multipliedBy(amount).toFixed(parseInt(token.decimals), 1)).toString())
        const time = getTime(days, hours, minutes);

        console.log('dutch launch',
            name,
            address,
            swapAmount,
            maxAmount2,
            minAmount2,
            times,
            time,
            onlyBot,
            !password || password === '' ? 0 : new BN(md5(password)).toString()
        )
        // return
        setCheck(!check)
        setLaunchStatus(approveStatus);
        try {
            const result = await tokenContract.methods.approve(
                getDutchAuction(chainId),
                swapAmount,
            )
                .send({ from: account });
            setLaunchStatus(confirmStatus);
            console.log('approve status', result.status)

            if (result.status) {
                await bounceContract.methods.create(
                    name,
                    address,
                    swapAmount,
                    maxAmount2,
                    minAmount2,
                    times,
                    time,
                    onlyBot,
                    !password || password === '' ? 0 : new BN(md5(password)).toString()
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setLaunchStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setLaunchStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setLaunchStatus(errorStatus)
                    })
            } else {
                setLaunchStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setLaunchStatus(cancelStatus)
            } else {
                setLaunchStatus(errorStatus)
            }
            setLaunchStatus(errorStatus)
            console.log('errdutch', err);
        }
    };

    const handelCheckMinAmount = (minPrice, maxPrice) => {
        const max = parseFloat(maxPrice) || 0
        const min = parseFloat(minPrice) || 0
        if ((max !== 0 && min !== 0) && min >= max) {
            setErrors({ ...errors, minAmount: 'The Dutch starting price must be higher than the final price' })
        } else {
            setErrors({ ...errors, minAmount: '' })
        }
    }


    return (
        <PoolFrame style={{ marginTop: 30, padding: isXSDown ? '40px 20px' : '40px 100px' }}>
            <Pool.Close
                onClick={() => {
                    history.goBack();
                }}
                src={icon_close} />
            <Pool>
                <Pool.Mode className='create'>Initial Token Offering</Pool.Mode>
                <Pool.Header className='create' style={{
                    justifyContent: 'space-between',
                    borderBottom: '4px #000 solid',
                    fontSize: isXSDown ? '26px' : '36px',
                }}>
                    Create a Dutch Auction
                <Button
                        height={'40px'}
                        width={'184px'}
                        onClick={() => {
                            window.open('https://docs.bounce.finance', '_blank')
                        }}
                    >How to Create a pool
                </Button>
                </Pool.Header>
                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div width={isXSDown ? '100%' : '480px'} style={{ height: '100%' }}>Contract information</div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                        <Form error={errors.address}
                            name={'Token Contract address'}
                            input={<Input
                                required
                                value={address}
                                name='address'
                                onChange={handleChange} />}
                        />
                        <Pool.Content style={{ marginTop: 38 }} width={isXSDown ? '100%' : '480px'}>
                            <Form
                                disabled
                                input={<Input
                                    disabled
                                    value={token.symbol}
                                    style={{ paddingLeft: 24 }}
                                />}
                                name={'Token Symbol'}
                                width={isXSDown ? '100%' : '213px'}
                                addonBefore={(<img src={icon_wenhao} />)}
                            />
                            <Form disabled
                                input={<Input
                                    disabled
                                    value={token.decimals}
                                />}
                                name={'Token decimals'}
                                width={isXSDown ? '100%' : '213px'} />
                        </Pool.Content>

                    </Pool.Content>
                </Pool.Frame>

                <Pool.Divider />

                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div width={isXSDown ? '100%' : '480px'} style={{ height: '100%' }}>Contract information</div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                        <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '480px'}>
                            <Form disabled input={<Input
                                disabled
                                value={token.symbol}
                                style={{ paddingLeft: 24 }}
                            />}
                                name={'From'}
                                width={isXSDown ? '100%' : '213px'}
                                // value={'BOT'}=
                                addonBefore={(<img src={icon_wenhao} />)}
                            />
                            <Form disabled
                                input={<Input
                                    disabled
                                    value={Psymbol}
                                    style={{ paddingLeft: 24 }}
                                />}
                                name={'To'}
                                width={isXSDown ? '100%' : '213px'}
                                addonBefore={(<img src={icon_eth} />)}
                            />
                        </Pool.Content>


                        <Form input={<Input
                            type='number'
                            value={maxAmount}
                            required
                            onChange={(e) => {
                                handelCheckMinAmount(minAmount, e.target.value)
                                setMaxAmount(e.target.value)
                                if (e.target.value === '') {
                                    setMinAmount('')
                                }
                            }}
                        />}
                            name={'Starting Price (Price Ceiling)-max price'}
                            prefix={`Starting price is 1  ${token.symbol || ''}=`}
                            suffix={Psymbol}
                            top={38}
                        />

                        <Form
                            error={errors.minAmount}
                            input={<Input
                                disabled={!maxAmount || maxAmount === '' ? true : false}
                                value={minAmount}
                                type='number'
                                name='minAmount'
                                onChange={(e) => {
                                    handelCheckMinAmount(e.target.value, maxAmount)
                                    setMinAmount(e.target.value)
                                }}
                            />} name={'Reserve Price -min price'} prefix={`Reserve Price is 1 ${token.symbol || ''}=  `} suffix={Psymbol}
                            top={38} />

                        <Form top={'43px'}
                            error={errors.amount}
                            disabled={!address || errors.address}
                            input={<Input
                                disabled={!address || errors.address}
                                required
                                name='amount'
                                type='number'
                                value={amount}
                                onChange={handleChange}
                            />} name={'Amount'} addonAfter={(<img onClick={() => {
                                balance && setAmount(weiToNum(balance, decimals, parseInt(decimals)))
                            }} src={icon_max} />)}
                            extra={
                                <span>{`Balance: ${balance ? `${weiToNum(balance, decimals)} ${symbol}` : '--'}`}</span>} />
                        <Form
                            top={38}
                            error={timeError} name={<span style={{ color: '#000', marginBottom: 8 }}>Pool running time</span>} hidden prefix={(<div>
                                <Pool.Content width={isXSDown ? '100%' : '480px'}>
                                    <Form input={<Input
                                        onChange={(e) => {
                                            setDays(e.target.value)
                                        }}
                                        type='number' />} name={'Days'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        onChange={(e) => {
                                            setHours(e.target.value)
                                        }}
                                        type='number' />} name={'Hours'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        onChange={(e) => {
                                            setMinutes(e.target.value)
                                        }}
                                        type='number' />} name={'Minutes'} width={isXSDown ? '30%' : '132px'} />

                                    <Form
                                        error={errors.times}
                                        input={<Input
                                            onChange={(e) => {
                                                setTimes(e.target.value)
                                                if (parseFloat(e.target.value) !== parseInt(e.target.value) || (parseInt(e.target.value) < 1)) {
                                                    setErrors({ ...errors, times: 'The degree must be an integer greater than or equal to 1' })
                                                } else {
                                                    setErrors({ ...errors, times: '' })
                                                }
                                            }}
                                            type='number' />}
                                        name={'Auction price decreasing times'}
                                        top={38}
                                        width={260} />
                                </Pool.Content>
                            </div>)} />


                        <Pool.Divider style={{ marginTop: 52, marginBottom: 40 }}>
                            <span>Auction price decrease rate is {new BigNumber((maxAmount - minAmount) / times).toFixed(4).toString()} ETH per {parseInt(getTime(days, hours, minutes) / times)} sec</span>
                        </Pool.Divider>

                        <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '480px'}>
                            {/* <Form
                                type='radio'
                                name={'Maximum Allocation per wallet'}
                                prefix={(<>
                                    <label style={{ marginTop: 16 }}>
                                        <input onChange={(e) => {
                                            setLimit(false)
                                        }} type='radio' name='limit' defaultChecked={true} />
                                        <i></i>
                                        {` No limits`}
                                    </label>
                                    <label style={{ marginLeft: 80, marginRight: 34, marginTop: 16 }}>
                                        <input onChange={() => {
                                            setLimit('')
                                        }} type='radio' name='limit' />
                                        <i></i>
                                        {Psymbol}
                                    </label>
                                </>)} /> */}


                            <Form
                                top={38}
                                type='radio'
                                name={<span style={{ color: '#000' }}>Participant</span>}
                                prefix={(<>
                                    <label style={{ marginTop: 16 }}>
                                        <input onChange={() => {
                                            setOnlyBot(true)
                                            setPassword(null)
                                            setIsPrivate(false)
                                        }} type='radio' name='participant' defaultChecked={true} />
                                        <i></i>
                                        {`  Bot holder`}
                                    </label>
                                    <label style={{ marginLeft: isXSDown ? '14px' : 80, marginRight: isXSDown ? '14px' : 34, marginTop: 16 }}>
                                        <input onChange={() => {
                                            setOnlyBot(false)
                                            setPassword(null)
                                            setIsPrivate(false)
                                        }} type='radio' name='participant' />
                                        <i></i>
                                        {` Public`}
                                    </label>
                                    <label style={{ marginLeft: isXSDown ? '14px' : 80, marginRight: isXSDown ? '14px' : 34, marginTop: 16 }}>
                                        <input onChange={() => {
                                            setOnlyBot(false)
                                            setIsPrivate(true)
                                        }} type='radio' name='participant' />
                                        <i></i>
                                        {` Private`}
                                    </label>
                                </>)} />


                            {isPrivate && <Form
                                top={38}
                                addonAfter={(<img src={icon_eye_open} />)}
                                input={<Input onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                />} name={'Password'} />}
                        </Pool.Content>

                        <Pool.Divider style={{ marginTop: 57, marginBottom: 40 }} />

                        <Form
                            error={nameError}
                            input={<Input
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                maxLength={15}
                            // value='Jack Dutch Auction'
                            />} name={'Pool Name'} />



                        <Form
                            disabled
                            name={'Transaction Fee'}
                            top={38}
                            hidden
                            prefix={(<div style={{ width: isXSDown ? '100%' : '480px' }}>
                                <Button disabled={!validateForm(errors) || !address || !maxAmount || !minAmount || !amount || !times || !name} style={{ marginTop: 16 }}
                                 black width={isXSDown ? '100%' : '480px'} onClick={handleLaunch}>Launch</Button>
                            </div>)}
                        />
                        <span style={{
                            fontFamily: 'Helvetica Neue',
                            fontSize: 12,
                            color: 'rgba(0,0,0,.4)',
                            textAlign: "left",
                            marginTop: 16
                        }}>Bounce contract does not accept Inflationary and deflationary tokens, please don’t create a pool with special token forms.</span>
                    </Pool.Content>
                </Pool.Frame>
            </Pool>

            <Modal isOpen={launchStatus.status !== 0} onDismiss={() => {
                setLaunchStatus({ status: 0 })
            }}>
                <ModalTitle style={{ textAlign: 'center' }}>Bounce Requests Approval</ModalTitle>

                {(launchStatus.status === 1 || launchStatus.status === 2 || launchStatus.status === 3) &&
                    <Lottie width={200} height={200} options={defaultOptions} />}

                {(launchStatus.status === 4) &&
                    <FormStatus ><img src={icon_success} /></FormStatus>}

                {(launchStatus.status === -1) &&
                    <FormStatus><img src={icon_error} /></FormStatus>}

                <ModalContent style={{ width: 300, textAlign: 'center' }}>Please enable Bounce to access your
                    tokens</ModalContent>
                <Button width={isXSDown ? '100%' : '320px'} black>Awaiting...</Button>
            </Modal>

        </PoolFrame>
    )
}
