import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from "../common/Button";
import { Select } from "../common/Select";
import icon_eth from "../../static/image/icon-eth-logo.svg";
import icon_radio from '../../static/image/radio.svg'
import icon_helper from "../../static/image/icon-helper.svg";
import { HelperLayout } from "../common/HelperLayout";
import { useActivePlatform, useActiveWeb3React } from "../../web3";
import { useAddress } from "./Hooks";
import { numToWei, weiToNum } from "../../utils/numberTransform";
import BigNumber from "bignumber.js";
import { getTime, isGreaterThan } from "../../utils/common";
import icon_eye_open from '../../static/image/icon-eye-open.svg'
import icon_eye_close from '../../static/image/icon-eye-close.svg'
import { Pool } from "../common/Layout";


const Frame = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    width:100%;
    padding: 0 20px;
  }
`
const Title = styled.div`
  font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 32px;
color: #000;
`

const SubTitle = styled(Title)`
font-size: 16px;
width: 235px;
text-align: center;
margin: 24px auto 32px;

`

const AddonAfter = styled.div`
  position: absolute;
  right: 0;
  bottom: 9px;
  cursor: pointer;
  img{
    width: 20px;
    height: 13px;
  }
`

const Input = styled.input`
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.3);
  outline: none;
  flex: 1;
`

const InputError = styled.div`
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: #E43F29;
  margin-top: 4px;
`

const InputFrame = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  position: relative;
`

const MultipleFrame = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
    .radio{
    width: 100%;
    flex: 1;
    &>div{
      display: flex;
      align-items: center;
      width: 100%;
      label{
        display: flex;
        align-items: center;
        vertical-align: middle;
        font-size: 14px;
        font-family: 'Helvetica Neue';
        font-weight: 500;
        color: #1F191B;
        cursor: pointer;
        width: 100%;
        flex: 1;

        input[type='radio'] + i{
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid #000;
          margin-right: 8px;
        }
        input{
          display: none;
          &:checked + i{
            border: none;
            background: url(${icon_radio}) no-repeat;
            background-size: contain;
            background-position: 0 0;
          }
        }
      }
    }
  }

`

Input.Name = styled.span`
  font-family: Helvetica Neue;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 15px;
color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.4)' : '#000')};
margin-bottom: 9px;
display: flex;
justify-content: space-between;
`

const Form = styled.form`
  width: 100%;
`

const TokenFrame = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  img{
    width: 18px;
    margin-right: 8px;
  }
`

const poolType = [
    {
        key: 0,
        value: 'Fixed-Swap Pool'
    }
]


export const Step2 = ({ next, visible }) => {

    const { active, account, library, chainId } = useActiveWeb3React();
    const [amount, setAmount] = useState()
    const [ratio, setRatio] = useState()
    const [level, setLevel] = useState()
    const [days, setDays] = useState()
    const [hours, setHours] = useState()
    const [minutes, setMinutes] = useState()
    const [onlyBOT, setOnlyBOT] = useState(false)
    const [password, setPassword] = useState()
    const [isPrivate, setIsPrivate] = useState()
    const [limit, setLimit] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [limitAmount, setLimitAmount] = useState(0)

    const [errors, setErrors] = useState({ address: '', amount: '', ratio: '', time: '', limit_amount: '' })


    const { Psymbol } = useActivePlatform()

    const tokenOptions = [
        {
            key: '0x0000000000000000000000000000000000000000',
            value: <TokenFrame><img src={icon_eth} /><span>{Psymbol}</span></TokenFrame>,
            symbol: Psymbol,
            decimals: '18'

        },
        // {
        //     key: getUSDTAddress(chainId),
        //     value: <TokenFrame><img src={icon_usdt}/><span>USDT</span></TokenFrame>,
        //     symbol: 'USDT',
        //     decimals: '6'
        // }
    ]

    const [selectedToToken, setSelectedToToken] = useState(tokenOptions[0])


    const { fromSymbol, fromDecimals, fromBalance, setAddress, address, addressError } = useAddress()

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('step 2')
        next({
            fromAddress: address,
            toAddress: selectedToToken.key,
            fromSymbol,
            fromDecimals,
            toSymbol: selectedToToken.symbol,
            toDecimals: selectedToToken.decimals,
            ratio,
            amount,
            onlyBOT,
            limit: limit ? limitAmount : 0,
            password: isPrivate ? password : null,
            time: getTime(days, hours, minutes),
            level
        })
    }

    useEffect(() => {
        console.log('level', amount, ratio)
        if (amount && ratio) {
            setLevel(new BigNumber(new BigNumber(amount).dividedBy(ratio).dividedBy(1000).toFixed(2, BigNumber.ROUND_UP)).toNumber().toString())
        }
    }, [amount, ratio])

    const checkTime = (days = 0, hours = 0, minutes = 0) => {
        const time1 = parseInt(days) * 24 * 60 * 60
        const time2 = parseInt(hours) * 60 * 60
        const time3 = parseInt(minutes) * 60
        if (time1 + time2 + time3 > 0) {
            return true
        }
        return false
    }

    const handleChange = async event => {
        event.preventDefault();
        const { name, value } = event.target;

        switch (name) {
            case "address":
                setAddress(value)
                break;
            case 'amount':
                const amount = value.replace(/[^\d.]/g, '')
                setAmount(amount)
                break
            case 'ratio':
                const ratio = value.replace(/[^\d.]/g, '')
                setRatio(ratio)
                break
            case 'password':
                setPassword(value)
                break
            case 'days':
                let days = value.replace(/^0(0+)|[^\d]+/g, '')
                if (days > 20) {
                    days = 20
                }
                setDays(days)
                break
            case 'hours':
                let hours = value.replace(/^0(0+)|[^\d]+/g, '')
                if (hours > 24) {
                    hours = 24
                }
                setHours(hours)
                break
            case 'minutes':
                let minutes = value.replace(/^0(0+)|[^\d]+/g, '')
                if (minutes > 60) {
                    minutes = 60
                }
                setMinutes(minutes)
                break
            case 'limit_amount':
                const limitAmount = value.replace(/[^\d.]/g, '')
                setLimitAmount(limitAmount)
                break

            default:
        }
    };


    return (
        <Frame style={{ display: visible ? 'flex' : 'none' }}>
            <Title>02. Pool Creation</Title>
            {/*<SubTitle>Please select the type of auction you want to create:</SubTitle>*/}
            <Form id='pool-enter' onSubmit={handleSubmit}>

                {/*<InputFrame style={{marginTop: 0}}>*/}
                {/*    <Input.Name>types of Auctions</Input.Name>*/}
                {/*    <Select defaultValue={poolType[0]} border width={'480px'} options={poolType} onSelect={(value) => {*/}
                {/*    }}/>*/}
                {/*</InputFrame>*/}

                <SubTitle>Contract information:</SubTitle>

                <InputFrame>
                    <Input.Name>Token Contract address</Input.Name>
                    <Input
                        name={'address'}
                        onChange={handleChange}
                        required
                        placeholder={''} />
                    {addressError && <InputError>{addressError}</InputError>}

                </InputFrame>

                <MultipleFrame>
                    <InputFrame width={'224px'}>
                        <Input.Name disabled>Token Symbol</Input.Name>
                        <Input
                            value={fromSymbol}
                            disabled
                            required
                            placeholder={''} />
                    </InputFrame>

                    <InputFrame width={'224px'}>
                        <Input.Name disabled>Token decimals</Input.Name>
                        <Input
                            value={fromDecimals}
                            disabled
                            required
                            placeholder={''} />
                    </InputFrame>
                </MultipleFrame>

                <SubTitle>Pool settings:</SubTitle>

                <MultipleFrame>
                    <InputFrame width={'224px'} style={{ marginTop: 0 }}>
                        <Input.Name disabled>From</Input.Name>
                        <Input
                            value={fromSymbol}
                            disabled
                            required
                            placeholder={''} />
                    </InputFrame>

                    <InputFrame width={'224px'} style={{ marginTop: 0 }}>
                        <Input.Name style={{ marginBottom: -10 }} disabled>To</Input.Name>
                        <Select border width={'213px'}
                            options={tokenOptions} onSelect={(value) => {
                                setSelectedToToken(value)
                            }} defaultValue={tokenOptions[0]} />
                    </InputFrame>
                </MultipleFrame>

                <InputFrame>
                    <Input.Name>Swap Ratio</Input.Name>
                    <MultipleFrame>
                        {`1  ${selectedToToken.symbol}=`}&nbsp;
                        <Input
                            name={'ratio'}
                            onChange={handleChange}
                            required
                            placeholder={'Enter number'} />
                        &nbsp;{fromSymbol}
                    </MultipleFrame>
                </InputFrame>

                <InputFrame>
                    <Input.Name>Amount <span>{`Balance: ${weiToNum(fromBalance)}`}</span></Input.Name>
                    <MultipleFrame>
                        <Input
                            onBlur={() => {
                                if (amount && fromBalance && isGreaterThan(numToWei(amount, fromDecimals), fromBalance)) {
                                    setAmount(weiToNum(fromBalance, fromDecimals))
                                }
                            }}
                            value={amount}
                            name={'amount'}
                            onChange={handleChange}
                            required
                            placeholder={'Enter Your token amount'} />
                        &nbsp;{fromSymbol}
                    </MultipleFrame>
                </InputFrame>

                <InputFrame>
                    <Input.Name>Allocation</Input.Name>
                    <MultipleFrame>
                        <Input
                            name={'level'}
                            value={level}
                            disabled
                            required
                            placeholder={''} />
                        &nbsp;{selectedToToken.symbol}
                    </MultipleFrame>
                </InputFrame>

                <InputFrame>
                    <Input.Name>Maximum Allocation per Wallet</Input.Name>
                    <MultipleFrame>
                        <div className='radio'>
                            <div>
                                <label style={{ width: 120 }}>
                                    <input
                                        onChange={(e) => {
                                            setLimit(false)
                                        }} type='radio' name='limit' defaultChecked={true} />
                                    <i></i>
                                    {` No limits`}
                                </label>

                                <label style={{ width: 120 }}>
                                    <input
                                        onChange={(e) => {
                                            setLimit(true)
                                        }} type='radio' name='limit' />
                                    <i></i>
                                    {` ETH`}
                                </label>
                            </div>
                        </div>

                        <Input
                            style={{ width: 146, visibility: limit ? 'visible' : 'hidden' }}
                            onBlur={() => {
                                if (limitAmount && amount && ratio && isGreaterThan(limitAmount, numToWei((new BigNumber(amount).div(new BigNumber(ratio))), selectedToToken.decimals))) {
                                    setLimitAmount(new BigNumber(amount).div(new BigNumber(ratio)))
                                }
                            }}
                            value={limitAmount}
                            name={'limit_amount'}
                            onChange={handleChange}
                            required={limit}
                            placeholder={'Enter Your token amount'} />
                    </MultipleFrame>
                </InputFrame>

                <Pool.Divider />

                <InputFrame>
                    <Input.Name>Participant</Input.Name>
                    <MultipleFrame>
                        <div className='radio'>
                            <div>
                                <label>
                                    <input
                                        onChange={(e) => {
                                            setOnlyBOT(true)
                                            setIsPrivate(false)
                                        }} type='radio' name='participant' />
                                    <i></i>
                                    {` BOT holders`}
                                </label>

                                <label>
                                    <input
                                        onChange={(e) => {
                                            setOnlyBOT(false)
                                            setIsPrivate(false)
                                        }} type='radio' name='participant' defaultChecked={true} />
                                    <i></i>
                                    {` Public`}
                                </label>

                                {/*<label>*/}
                                {/*    <input*/}
                                {/*        onChange={(e) => {*/}
                                {/*            setOnlyBOT(false)*/}
                                {/*            setIsPrivate(true)*/}
                                {/*        }} type='radio' name='participant'/>*/}
                                {/*    <i></i>*/}
                                {/*    {` Private`}*/}
                                {/*</label>*/}
                            </div>
                        </div>
                    </MultipleFrame>

                    {isPrivate && (
                        <InputFrame>
                            <Input.Name>Password</Input.Name>
                            <Input
                                name='password'
                                required
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange} />
                            <AddonAfter onClick={() => {
                                setShowPassword(!showPassword)
                            }}>
                                <img src={showPassword ? icon_eye_open : icon_eye_close} />
                            </AddonAfter>
                        </InputFrame>
                    )}

                </InputFrame>

                <Pool.Divider />

                <InputFrame style={{ marginTop: 0, marginBottom: 48 }}>
                    <Input.Name>Pool running time</Input.Name>
                    <MultipleFrame>
                        <InputFrame width={'136px'} style={{ marginTop: 0 }}>
                            <Input.Name disabled>Days</Input.Name>
                            <Input
                                max={100}
                                name='days'
                                value={days}
                                onChange={handleChange}
                                placeholder={'Days'} />
                        </InputFrame>

                        <InputFrame width={'136px'} style={{ marginTop: 0 }}>
                            <Input.Name disabled>Hours</Input.Name>
                            <Input
                                name='hours'
                                value={hours}
                                onChange={handleChange}
                                placeholder={'Hours'} />
                        </InputFrame>

                        <InputFrame width={'136px'} style={{ marginTop: 0 }}>
                            <Input.Name disabled>Minutes</Input.Name>
                            <Input
                                name='minutes'
                                value={minutes}
                                onChange={handleChange}
                                placeholder={'Minutes'} />
                        </InputFrame>
                    </MultipleFrame>
                </InputFrame>


                <HelperLayout
                    content={`bounce charge 1% fee to pool creator based on the amount of the successfully swapped tokens`}> {`Transaction Fee `}<img
                        src={icon_helper} /></HelperLayout>

                <Button
                    disabled={checkTime(days, hours, minutes) ? false : true}
                    type="submit" form="pool-enter" style={{ marginTop: 90 }} black>Next Step</Button>

                <Input.Name style={{ marginTop: 16 }} disabled>Bounce contract does not accept Inflationary and
                    deflationary tokens, please don’t create a pool with special token forms.</Input.Name>

            </Form>
        </Frame>
    )
}
