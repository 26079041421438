import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Cover, Pool, PoolFrame } from "../../components/common/Layout";
import icon_close from '../../static/image/icon-close.svg'
import { Form, Input } from "../../components/common/Form";
import { useHandleForm } from '../../web3/common'
import { Button } from "../../components/common/Button";
import { ZERO_ADDRESS } from "../../const";
import { useHistory } from 'react-router-dom'
import { getTime } from "../../utils/common";
import { getContract, useActivePlatform, useActiveWeb3React } from "../../web3";
import { getLotteryNFTAddress, } from "../../web3/contractAddress";
import icon_helper from '../../static/image/icon-helper.svg'
import Web3 from 'web3'
import {
    CreateModal,
    initStatus,
    approveStatus,
    pendingStatus,
    confirmStatus,
    successStatus,
    errorStatus,
    cancelStatus
} from "../../components/common/CreateModal";
import { HelperLayout } from "../../components/common/HelperLayout";
import { PoolCover } from "../../components/DetailCover";
import rect_logo from "../../static/image/rect-logo.svg";
import bounceERC721 from "../../web3/abi/bounceERC721.json";
import bounceLotteryNFT from "../../web3/abi/bounceLotteryNFT.json";
import { useIsXSDown } from '../../components/utils/themeHooks';
const { toWei } = Web3.utils


export const CreateLotteryPool = () => {
    const history = useHistory()
    const isXSDown = useIsXSDown();
    const { active, account, library, chainId } = useActiveWeb3React();
    const [modalStatus, setModalStatus] = useState(initStatus)
    const { Psymbol } = useActivePlatform()


    const {
        address, setAddress, addressError,
        token,
        ratio, setRatio, ratioError,
        name, setName, nameError,
        limit, setLimit,
        days, setDays,
        hours, setHours,
        minutes, setMinutes,
        timeError,
        nftID, setNFTID, idError, setIDError,
        nft, setNFT, setNFTType
    } = useHandleForm()

    useEffect(() => {
        setNFTType('nft-721')
    }, [])

    const onCreate = async () => {
        const time = getTime(days, hours, minutes);

        console.log(name, address, nftID, ZERO_ADDRESS, toWei(ratio), limit, time)
        const bounceAddress = getLotteryNFTAddress(chainId)
        const tokenContract = getContract(library, bounceERC721.abi, address)
        const bounceContract = getContract(library, bounceLotteryNFT.abi, bounceAddress)
        setModalStatus(approveStatus);
        try {
            const result = await tokenContract.methods.approve(
                bounceAddress,
                nftID,
            )
                .send({ from: account });
            setModalStatus(confirmStatus);
            if (result.status) {
                await bounceContract.methods.create(
                    name,
                    address,
                    nftID,
                    ZERO_ADDRESS,
                    toWei(ratio),
                    limit,
                    time,
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            } else {
                setModalStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    };

    const wrapperSpace = (str) => {
        if (!str) return false
        if (String(str).trim().length === 0) return false
        return true
    }

    return (
        <PoolFrame style={{ marginTop: 32, padding: isXSDown ? '40px 20px' : '40px 100px' }}>
            <Pool.Close onClick={() => {
                history.goBack()
            }} src={icon_close} />
            <Pool>
                <Pool.Mode style={{ textAlign: 'left' }}>Initial Token Offering</Pool.Mode>
                <Pool.Header style={{
                    justifyContent: 'flex-start',
                    borderBottom: '4px #000 solid',
                    fontSize: isXSDown ? '26px' : '36px',
                }}>{'Create a NFT Lottery Pool'}</Pool.Header>
                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div width={isXSDown ? '100%' : '480px'} style={{ height: '100%' }}>
                        Contract information
                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>

                        <Form top={'38px'} error={addressError} input={<Input
                            onChange={(e) => {
                                setAddress(e.target.value)
                                console.log('address blur:', e.target.value)
                            }} />} name={'Token Contract address'} />

                        <Form top={'38px'} error={idError} input={<Input
                            onFocus={(e) => {
                                setNFTID(e.target.value)
                            }}
                            onChange={(e) => {
                                setNFTID(e.target.value)
                                console.log('nft id:', e.target.value)
                            }} />} name={'ERC-721 Token ID'} />


                    </Pool.Content>
                </Pool.Frame>

                <Pool.Divider />

                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div style={{ width: isXSDown ? '100%' : 480, height: '100%' }}>
                        Pool settings

                        <Pool.Content width={isXSDown ? '100%' : '512px'} style={{ marginTop: 48 }}>
                            <PoolCover cover={nft && (nft.cover ? nft.cover : rect_logo)} />
                        </Pool.Content>

                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                        <Form top={'38px'} error={ratioError} input={<Input
                            type='number'
                            value={ratio}
                            onChange={(e) => {
                                setRatio(e.target.value.replace(/[^\d.]/g, ''))
                            }}
                        />}
                            name={'NFT Price'}
                            prefix={token.symbol && `Just 1 ${token.symbol} =  `}
                            suffix={Psymbol} />


                        <Form
                            top={'38px'}
                            input={<Input
                                type='number' value={limit} onChange={(e) => {
                                    setLimit(e.target.value.replace(/[^\d]+/g, ''))
                                }}
                            />} name={'Max participant allowed'} />


                        <Pool.Divider />

                        <Form top={'40px'} error={nameError} input={<Input
                            maxLength={15}
                            onBlur={(e) => {
                                setName(e.target.value)
                            }}
                        />} name={'Pool Name'} />

                        <Form top={'49px'} error={timeError}
                            name={<span style={{ color: '#000', marginBottom: 9 }}>Pool running time</span>} hidden
                            prefix={(<div>
                                <Pool.Content width={isXSDown ? '100%' : '480px'}>
                                    <Form input={<Input
                                        onChange={(e) => {
                                            setDays(e.target.value)
                                        }}
                                        type='number' />} name={'Days'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        value={hours}
                                        onChange={(e) => {
                                            let hours = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                            if (hours > 24) {
                                                hours = 24
                                            }
                                            setHours(hours);
                                        }}
                                        type='number' />} name={'Hours'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        value={minutes}
                                        onChange={(e) => {
                                            let minutes = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                            if (minutes > 60) {
                                                minutes = 60
                                            }
                                            setMinutes(minutes);
                                        }}
                                        type='number' />} name={'Minutes'} width={isXSDown ? '30%' : '132px'} />
                                </Pool.Content>
                            </div>)} />

                        <Form top={'38px'} disabled name={<><HelperLayout
                            content={`bounce charge 2% fee to pool creator based on the amount of the successfully swapped tokens`}> {`Transaction Fee `}<img
                                src={icon_helper} /></HelperLayout></>} hidden prefix={(
                                    <div style={{ width: isXSDown ? '100%' : '480px' }}>
                                        <Button
                                            disabled={idError === '' && wrapperSpace(name) && wrapperSpace(ratio) && wrapperSpace(limit) && (wrapperSpace(days) || wrapperSpace(hours) || wrapperSpace(minutes)) ? false : true}
                                            style={{ marginTop: 12 }} black width={isXSDown ? '100%' : '480px'} onClick={onCreate}>Launch</Button>
                                    </div>)} />
                    </Pool.Content>
                </Pool.Frame>
            </Pool>

            <CreateModal onOK={() => {
                setModalStatus(initStatus)
                history.goBack()
            }} onDismiss={() => {
                setModalStatus(initStatus)
            }} modalStatus={modalStatus} />

        </PoolFrame>
    )
}
