import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from "../../components/common/Button";
import default_img from '../../static/image/default_img.svg'

const Frame = styled.div`
  width: 480px;
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media (max-width: 767px) {
    width:100%;
    padding: 0 20px;
  }

  .subTitile{
    font-size: 16px;
  }
`
const Title = styled.div`
  font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 32px;
color: #000;
margin-bottom: 40px;
text-align: center;
`
const Input = styled.input`
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.3);
  outline: none;
  width: 100%;
  font-weight: 500;
  padding: 5px 0 9px;
`

const InputFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 38px;

  .upload_file_label{
    display: block;
    width: 480px;
    height: 148px;
    box-sizing: border-box;
    border: 1px dotted rgba(0,0,0,.3);
    position: relative;

    input{
      display: none;
    }

    &>div{
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      cursor: pointer;
      img{
        width: 40px;
        height: 28px;
        margin: 36px auto 16px;
      }

      p{
        font-family: 'Helvetica Neue';
        font-size: 12px;
        font-weight: 500;
        color: #1F191B;

        &:last-child{
          font-size: 11px;
          opacity: .3;
          font-weight: 400;
        }

        span{
          text-decoration: underline;
        }
      }
    }
  }
`

Input.Name = styled.span`
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #1F191B;
  margin-bottom: 9px;
  display: flex;
  justify-content: space-between;
  opacity: .7;
`

const Form = styled.form`
  width: 100%;
`


export const Step1 = ({ next, visible }) => {

  const [name, setName] = useState(null)
  const [describe, setDescribe] = useState(null)
  const [website, setWebsite] = useState(null)
  const [facebook, setFacebook] = useState(null)
  const [instagram, setInstagram] = useState(null)
  const [image, setImage] = useState('https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg1.qunarzz.com%2Ftravel%2Fd8%2F1602%2Fbf%2F7c39364acff8fef7.jpg_r_640x426x70_795e3fa3.jpg&refer=http%3A%2F%2Fimg1.qunarzz.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1611221381&t=44f8eccc25d75348a03389829b2f54f2')

  const handleSubmit = (event) => {
    event.preventDefault();
    next({ name, describe, website, facebook, instagram, image })
  }

  const handleChange = async event => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value)
        break;
      case "describe":
        setDescribe(value)
        break
      case "website":
        setWebsite(value)
        break
      case "facebook":
        setFacebook(value)
        break
      case "instagram":
        setInstagram(value)
        break
      default:
    }
  };

  const checkInput = (str) => {
    if (!str || String(str).trim().length === 0) return false
    return true
  }

  return (
    <Frame style={{ height: visible ? 'fit-content' : 0 }}>
      <Title>01. Store Information
        <p className='subTitile'>
          Please add information about your store
        </p>
      </Title>
      <Form id='project-enter' onSubmit={handleSubmit}>
        <InputFrame style={{ marginTop: 0 }}>
          <Input.Name>Store Name</Input.Name>
          <Input
            maxLength={20}
            max
            name={'name'}
            onChange={handleChange}
            placeholder={'Enter Your Store name'} />
        </InputFrame>

        <InputFrame>
          <Input.Name>Store description</Input.Name>
          <Input
            name={'describe'}
            onChange={handleChange}
            placeholder={'Describe your Store'} />
        </InputFrame>

        <InputFrame>
          <Input.Name>{`store official website (optional)`}</Input.Name>
          <Input
            name={'website'}
            onChange={handleChange}
            placeholder={'Add A URL Link Of Store Official Website'} />
        </InputFrame>

        <InputFrame>
          <Input.Name>{`store Facebook Link (optional)`}</Input.Name>
          <Input
            name={'facebook'}
            onChange={handleChange}
            placeholder={'Add A URL Link Of Store Facebook'} />
        </InputFrame>

        <InputFrame>
          <Input.Name>{`store Instagram Link (optional)`}</Input.Name>
          <Input
            name={'instagram'}
            onChange={handleChange}
            placeholder={'Add A URL Link Of Store Instagram'} />
        </InputFrame>

        <InputFrame>
          <Input.Name>{`storefront picture`}</Input.Name>
          <label for="upload_file" className='upload_file_label'>
            <div>
              <img src={default_img} alt="" />
              <p>Drop Auction Image Here Or <span onClick={(e) => {
                e.preventDefault()

              }}>Browse</span></p>
              <p>Supports JPG, PNG, JPEG2000</p>
            </div>
            <Input
              type='file'
              id='upload_file'
              onChange={handleChange}
            />
            <img src="" alt="" />
          </label>
        </InputFrame>

        <Button
          disabled={checkInput(name) && checkInput(describe) ? false : true}
          type="submit" form="project-enter" style={{ marginTop: 38 }} black>Next Step</Button>
      </Form>
    </Frame>
  )
}
