import React, {useState, useCallback, useEffect, useContext} from 'react';
import rectLogo from '../static/image/rect-logo.svg'
import classNames from 'classnames';
import Web3 from "web3";

export const RectPoolItem = ({pool, onJoin}) => {
  const [hover, setHover] = useState(false);

  return (
      <div className='grid-pool-item'
           onMouseLeave={() => {
             if(hover){
               setHover(false)
             }
           }}
           onMouseEnter={() => {
             if(!hover){
               setHover(true)
             }
           }}>
        {hover ? (
                <>
                  <div className='header'>
                    <span className='name'>{pool.name}</span>
                    <span className='address'>{`${pool.fromAddress.replace(pool.fromAddress.substring(7, pool.fromAddress.length - 5), "...")}`}</span>
                  </div>

                  <div className='desc'>
                    <span className=''>{'Starting Price'}</span>
                    {/*<span style={{marginTop: 6}}>{`${Web3.utils.fromWei(pool.type === 'englishAuction'? pool.startingPrice :pool.maxPrice)} ETH`}</span>*/}
                    <span style={{marginTop: 20}}>{'Participant'}</span>
                    <span style={{marginTop: 6}}>{'Public'}</span>
                    <span style={{marginTop: 20}}>{'Status'}</span>
                    <span style={{marginTop: 6}} className={classNames('status', pool.status)}>
          {pool.status}
          </span>
                    <span style={{marginTop: 28, height: 30}} className='dark-button' onClick={onJoin}>Join</span>
                  </div>
                </>
            )
            : (
                <>
                  <img className='cover' src={pool.cover ? pool.cover : rectLogo} onClick={() => {
                    //history.push(`/join/nft/${pool.index}`, {id: pool.index});
                  }}/>
                  <span className='name'>{pool.name}</span>
                  <span className='address'>{`${pool.fromAddress.replace(pool.fromAddress.substring(7, pool.fromAddress.length - 5), "...")}`}</span>
                    <div style={{marginTop: 4, marginLeft: 16, display:'flex', justifyContent: 'space-between',marginRight: 16}}>
                        <span  className={classNames('status', pool.status)}>{pool.status}</span>
                        <span className='pool-num'>{`Pool ID: ${pool.index}`}</span>
                    </div>

                </>
            )}

      </div>)
}
