import React, { useContext, useEffect, useState } from 'react';
import { myContext } from './reducer'
import intl from 'react-intl-universal';
import { ChainId, Token, WETH, Fetcher, Route } from '@uniswap/sdk'
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import { Wallets } from "./components/wallet/Wallets";
import Modal, { ModalContent, ModalTitle } from "./components/common/Modal";
import { Web3Provider } from "@ethersproject/providers";
import { HANDLE_SHOW_CONNECT_MODAL } from "./const";
import { walletList } from "./components/wallet/connects";
import { getBotAddress } from "./web3/contractAddress";


export const AppBase = ({ children, locale, translations }) => {
    const { state, dispatch } = useContext(myContext);
    const context = useWeb3React();

    const { activate } = context;

    const [initDone, setInitDone] = useState(false);
    const USDT = new Token(ChainId.MAINNET, '0xdac17f958d2ee523a2206206994597c13d831ec7', 6)



    useEffect(() => {
        const localContent = window.localStorage.getItem('DEFAULT_WEB3')
        console.log('wallet content', localContent)

        if (localContent) {
            if (localContent === 'BinanceWallet') {
                setTimeout(() => {
                    activate(walletList()[localContent]);
                    dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: false });
                }, 1500)
            } else {
                console.log('default content', localContent)
                activate(walletList()[localContent]);
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: false });
            }

        }


        async function queryETHPrice() {
            const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18)

            // note that you may want/need to handle this async code differently,
            // for example if top-level await is not an option
            const pair = await Fetcher.fetchPairData(DAI, WETH[DAI.chainId])

            const route = new Route([pair], WETH[DAI.chainId])

            dispatch({ type: 'HANDLER_ETH_PRICE', ethPrice: route.midPrice.toSignificant(6) });

            console.log('eth price', route.midPrice.toSignificant(6)) // 201.306
        }

        async function queryBOTPrice() {
            console.log('bot address', getBotAddress(ChainId.MAINNET))
            const BOT = await Fetcher.fetchTokenData(ChainId.MAINNET, getBotAddress(ChainId.MAINNET))
            const ETHWETHPair = await Fetcher.fetchPairData(WETH[ChainId.MAINNET], BOT)
            const USDTWETHPair = await Fetcher.fetchPairData(USDT, WETH[ChainId.MAINNET])
            const ETHRoute = new Route([ETHWETHPair, USDTWETHPair], BOT)
            const BOTPrice = ETHRoute.midPrice.toSignificant(6)
            console.log('BOT price', BOTPrice)
            dispatch({ type: 'HANDLER_BOT_PRICE', BOTPrice });
        }



        async function queryBTCPrice() {
            const BTC = await Fetcher.fetchTokenData(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599')
            const BTCPair = await Fetcher.fetchPairData(USDT, BTC)
            const BTCRoute = new Route([BTCPair], BTC)
            const BTCPrice = BTCRoute.midPrice.toSignificant(6)
            console.log('cur meme price', BTCPrice)
            dispatch({ type: 'HANDLER_BTC_PRICE', BTCPrice });
        }

        try {
            queryETHPrice()
            queryBOTPrice()
            queryBTCPrice()
        } catch (e) {
            console.log('query eth price error', e)
        }
    }, [])

    // componentWillReceiveProps(nextProps) {
    //   this.setState({
    //     ...this.state,
    //     locale: nextProps.locale
    //   })
    //   this.loadLocales(nextProps.locale);
    // }


    // componentDidMount() {
    //   this.loadLocales();
    // }


    const loadLocales = (currentLocale) => {
        intl
            .init({
                currentLocale: currentLocale ? currentLocale : locale,
                locales: translations,
                warningHandler: message => {
                    // console.log(message);
                },
                commonLocaleDataUrls: {},
            })
            .then(() => {
                setInitDone(true);
            });
    };

    useEffect(() => {
        loadLocales()
    }, []);

    function getLibrary(provider) {
        const library = new Web3Provider(provider);
        library.pollingInterval = 8000;
        return library;
    }

    return (
        <>
            <Modal isOpen={state.showConnectModal} onDismiss={() => {
                console.log('on close:')
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: false });
            }}
            >
                <ModalTitle>Connect A Wallet</ModalTitle>
                <ModalContent>To participate in Bounce you first need to connect a wallet. Please select an option
                    Below. You can also connect a Ledger via your Metamask</ModalContent>
                <Wallets />
            </Modal>
            {children}
        </>
    )
}

export default AppBase;
