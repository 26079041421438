import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Return} from './Return';
import {queryMyDutchPool, getTransactionLink} from './const';
import '../static/image/success.svg';
import {DutchPool} from './DutchPool';
import {WaitContent} from '../components/common/WaitContent';
import {RewardSuccess} from './content/RewardSuccess';
import {RewardFailed} from './content/RewardFailed';
import {DeniedContent} from './content/DeniedContent';
import {myContext} from '../reducer'
import {useHistory} from 'react-router-dom';
import failedIcon from '../static/image/error.svg';
import {t, tHTML} from "../utils/intl";
import {getDutchAuctionV1} from "../components/utils/web3";


export const DutchPoolContent = ({
                                   symbol,
                                   account,
                                 }) => {
  const {state, dispatch} = useContext(myContext);
  const history = useHistory();
  const [pool, setPool] = useState();
  const [poolStatus, setPoolStatus] = useState('Live');
  const [status, setStatus] = useState('init');
  const [hash, setHash] = useState('');
  const [isFilled, setIsFilled] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);

  const {web3, myAccount, curPoolType} = state;


  useEffect(() => {
    async function loadAddress() {
      const address = await getTransactionLink(pool ? pool.fromAddress : '')
      setAddress(address);
    }

    loadAddress()
  }, [hash]);

  useEffect(() => {
    if (status === 'success') {
      dispatch({type: 'HANDLER_HAS_BID_POOL', hasBidPool: false});
    }
  }, [status]);

  useEffect(() => {
    if (myAccount && web3) {
      setLoading(false);
      queryMyDutchPool(web3, myAccount)
          .then(r => {
            console.log('queryMyDutchPool:', r)
            if (!r) {
              setLoading(false)
              setIsExpired(false)
            } else {
              const time = r.time * 1000;
              const now = new Date().getTime();
              setIsExpired(time < now);

              setPool(r);
              setLoading(false)
            }
          })
          .catch(e => {
            setLoading(false)
            console.log(e);
          })
    }
  }, [setIsFilled, setPool, myAccount, web3]);


  const handleClaim = async () => {
    const dutch = await getDutchAuctionV1(web3);
    setStatus('waiting');
    try {
      dutch.methods.creatorClaim(pool.index)
          .send({from: myAccount})
          .then(r => {
            dispatch({type: 'HANDLER_HAS_DUTCH_POOL', hasDutchPool: false});
            setStatus('success');
            setHash(r.transactionHash);
          })
          .catch(err => {
            console.log('err--->',err)
            if (err.code === 4001) {
              setStatus('denied');
            } else {
              setStatus('failed');
            }
          })
    } catch (err) {
      if (err.code === 4001) {
        setStatus('denied');
      } else {
        setStatus('failed');
      }
    }
  }


  const handleClose = useCallback(() => {
    history.push('/list/dutch')
    setStatus('init');
  }, [setStatus]);


  const renderPool = useCallback(() => {
    if (status === 'init') {
      if (isExpired) {
        return(
        <div className='content-box'>
          <img src={failedIcon} alt='expired'/>
          {tHTML('expired.title')}
          <p>{t('expired.message')}</p>
          <span className='button' onClick={handleClaim}>{t('buttons.claim-token')}</span>
        </div>)
      } else {
        return <DutchPool
            onStatusChange={setStatus}
            symbol={symbol}
            pool={pool}
            onHashChange={setHash}
            account={myAccount}
            loading={loading}
        />
      }

    } else {
      if (status === 'waiting') {
        return <WaitContent content={true}/>
      } else if (status === 'success') {
        return <RewardSuccess link={address}
                              onClose={handleClose}/>
      } else if (status === 'failed') {
        return <RewardFailed onClose={handleClose}/>
      } else if (status === 'denied') {
        return <DeniedContent onClose={handleClose}/>
      }
    }
  }, [isExpired, isFilled, pool, account, symbol,
    handleClose, hash, status, setStatus
  ]);

  return (
      <div className='content join'>
        <Return type={curPoolType}/>
        <div className='form'>
          {renderPool()}
        </div>
      </div>
  )
}
