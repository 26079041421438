import React, {useState, useEffect, useContext} from 'react';
import failedIcon from '../static/image/error.svg';
import {PoolItem} from './content/PoolItem';
import sucIcon from '../static/image/success.svg'
import {getContractLink} from './const';
import rotate from '../static/image/rotate.svg';
import {myContext} from '../reducer'


export const Pool = ({
                       symbol,
                       pool,
                       account,
                       status,
                       loading
                     }) => {

  const {state, dispatch} = useContext(myContext);
  const [address, setAddress] = useState('');

  const {web3, myAccount} = state;


  useEffect(() => {
    async function loadAddress() {
      const address = await getContractLink(pool ? pool.fromAddress : '');
      setAddress(address);
    }
    loadAddress()
  }, [pool]);

  console.log('my pool--->',pool, myAccount)

  return (
      <>
        {loading ?
            <div className='content-box'>
              <img src={rotate} className='waiting' alt='waiting'/>
              <p>Loading</p>
            </div> :
            pool && myAccount
                ? <>
                  <ul className='form-head join-title'>
                    <li>{pool.name}</li>
                    <li><a href={address} target='_blank' rel="noopener noreferrer">{pool.fromAddress}</a></li>
                  </ul>
                  <form className='form-content'>
                    <PoolItem symbol={symbol} pool={pool}/>
                  </form>
                  <div className='pool-status'>
                    <img src={sucIcon} alt='success'/>
                    <p className='status-text'>{`Pool is ${status}`}</p>
                  </div>
                </>
                : <div className='content-box'>
                  <img src={failedIcon} alt='no-login'/>
                  <p>You should connect to a wallet<br/> to see your bounce pool</p>
                </div>}
      </>
  )
}
