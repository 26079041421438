import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Header } from "./Header";
import { AccountFrame } from './styled'
import { ViewMyNFT } from "./ViewMyNFT";

// new Account
import { FS_ERC20, SB_ERC20, DA_ERC20, LT_ERC20, PRED_ERC20, SV_FS_ERC20, SV_SB_ERC20 } from '../A_poolList/usePage/ERC20'
import { FS_NFT, SB_NFT, EA_NFT, DA_NFT, LT_NFT } from '../A_poolList/usePage/ERC-NFT'



export const Account = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [queryData, setQueryData] = useState([])
  // new Account

  useEffect(() => {

  }, [queryData])

  return (
    <AccountFrame>
      <Header setQueryData={setQueryData} setIsLoading={setIsLoading} setIsEmpty={setIsEmpty} />
      <AccountFrame.Background />
      <Switch>
        <Route path={`/account/fixed-swap`}>
          <FS_ERC20 queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/sealed-bid`}>
          <SB_ERC20 queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/dutch-auction`}>
          <DA_ERC20 queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/fixed-swap-nft`}>
          <FS_NFT queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/sealed-bid-nft`}>
          <SB_NFT queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/english-auction-nft`}>
          <EA_NFT queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/dutch-auction-nft`}>
          <DA_NFT queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/lottery`}>
          <LT_ERC20 queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/lottery-nft`}>
          <LT_NFT queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/predictions`}>
          <PRED_ERC20 queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/sv-fixed-swap`}>
          <SV_FS_ERC20 queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>

        <Route path={`/account/sv-sealed-bid`}>
          <SV_SB_ERC20 queryListArr={queryData} isLoading={isLoading} isEmpty={isEmpty} />
        </Route>


        <Route path={`/account/view-my-nft`}>
          <ViewMyNFT pools={[]} />
        </Route>

      </Switch>

    </AccountFrame>
  )
}
