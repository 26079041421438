import React, { useState } from 'react'
import Grow from '@material-ui/core/Grow';
import Modal from "../../../components/common/Modal";
import { JoinTipModal } from '../../../components/common/JoinTipModal'

import { useActiveWeb3React } from "../../../web3";

import { ERC20_Skeleton, ERC_NFT_Skeleton, PRED_Skeleton, SV_Skeleton } from './Skeleton'
import { FS_CardItem, PRED_CardItem, SV_CardItem } from './ERC20_CardItem'
import { NFT_CardItem } from './ERC_NFT_CardItem'

import img_loading from '../../../static/image/icon-loading.svg';
import img_empty from '../../../static/image/empty-pool.svg';

import { PoolGroupStyled, LoadingPageStyled, EmptyPageStyled } from './styled'



export const ERC20_PoolGroup = ({ poolCount, poolsDate, poolType, isLoad }) => {
    const [isShowTip, setIsShowTip] = useState(false)
    const [tipInfo, setTipInfo] = useState({})
    const { active } = useActiveWeb3React()

    const renderPoolList = () => {
        switch (poolType) {
            case 'FS':
                return poolsDate.map((item, index) => {
                    return <Grow in={true} timeout={1000}>
                        <FS_CardItem key={index} data={item} detailsRoute='/fixed-swap/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='FS' />
                    </Grow>
                })

            case 'SB':
                return poolsDate.map((item, index) => {
                    return <Grow in={true}>
                        <FS_CardItem key={index} data={item} detailsRoute='/sealed-bid/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='SB' />
                    </Grow>
                })

            case 'DA':
                return poolsDate.map((item, index) => {
                    return <Grow in={true}>
                        <FS_CardItem key={index} data={item} detailsRoute='/dutch-auction/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='DA' />
                    </Grow>
                })

            case 'LT':
                return poolsDate.map((item, index) => {
                    return <Grow in={true}>
                        <FS_CardItem key={index} data={item} detailsRoute='/lottery-erc20/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='LT' />
                    </Grow>
                })

            case 'LL':
                return poolsDate.map((item, index) => {
                    return <Grow in={true}>
                        <FS_CardItem key={index} data={item} detailsRoute='/liquidity-lock-auction/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='LL' />
                    </Grow>
                })


            default:
                break;
        }
    }

    return (
        <PoolGroupStyled>
            {isLoad === true ?
                [...new Array(poolCount)].map((_item, index) => {
                    return <ERC20_Skeleton key={index} />
                })
                :
                renderPoolList()
            }

            <Modal
                closeable
                isOpen={active && isShowTip}
                onDismiss={() => {
                    setIsShowTip(false)
                }}
                maxWidth={'450px'}

            >
                {/* <ModalTitle
                    style={{ textAlign: 'center', border: 'none' }}
                >Token imported</ModalTitle> */}
                <JoinTipModal
                    onJoinInfo={tipInfo}
                />
            </Modal>
        </PoolGroupStyled>
    )
}

export const ERC_NFT_PoolGroup = ({ poolCount, poolsDate, poolType, isLoad }) => {
    const [isShowTip, setIsShowTip] = useState(false)
    const [tipInfo, setTipInfo] = useState({})
    const { active } = useActiveWeb3React()

    const renderPoolList = () => {
        switch (poolType) {
            case 'FS':
                return poolsDate.map((item, index) => {
                    return <Grow in={true} key={index}>
                        <NFT_CardItem  data={item} detailsRoute='/fixed-swap-nft/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='FS' />
                    </Grow>
                })

            case 'SB':
                return poolsDate.map((item, index) => {
                    return <Grow in={true} key={index} >
                        <NFT_CardItem data={item} detailsRoute='/sealed-bid-nft/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='SB' />
                    </Grow>
                })

            case 'EA':
                return poolsDate.map((item, index) => {
                    return <Grow in={true} key={index}>
                        <NFT_CardItem  data={item} detailsRoute='/english-auction-nft/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='EA' />
                    </Grow>
                })

            case 'DA':
                return poolsDate.map((item, index) => {
                    return <Grow in={true} key={index}>
                        <NFT_CardItem data={item} detailsRoute='/dutch-auction-nft/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='DA' />
                    </Grow>
                })

            case 'LT':
                return poolsDate.map((item, index) => {
                    return <Grow in={true} key={index}>
                        <NFT_CardItem data={item} detailsRoute='/lottery-nft/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='LT' />
                    </Grow>
                })

            default:
                break;
        }
    }

    return (
        <PoolGroupStyled>
            {isLoad === true ?
                [...new Array(poolCount)].map((_item, index) => {
                    return <ERC_NFT_Skeleton key={index} />
                })
                :
                renderPoolList()
            }

            <Modal
                closeable
                isOpen={active && isShowTip}
                onDismiss={() => {
                    setIsShowTip(false)
                }}
                maxWidth={'450px'}

            >
                {/* <ModalTitle
                    style={{ textAlign: 'center', border: 'none' }}
                >Token imported</ModalTitle> */}
                <JoinTipModal
                    onJoinInfo={tipInfo}
                />
            </Modal>
        </PoolGroupStyled>
    )
}

export const PRED_PoolGroup = ({ poolCount, poolsDate, isLoad }) => {
    const [isShowTip, setIsShowTip] = useState(false)
    const [tipInfo, setTipInfo] = useState({})
    const { active } = useActiveWeb3React()

    const renderPoolList = () => {
        return poolsDate.map((item, index) => {
            return <Grow in={true}>
                <PRED_CardItem key={index} data={item} detailsRoute='/prediction/detail/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='ERC20' />
            </Grow>
        })
    }

    return (
        <PoolGroupStyled>
            {isLoad === true ?
                [...new Array(poolCount)].map((_item, index) => {
                    return <PRED_Skeleton key={index} />
                })
                :
                renderPoolList()
            }

            <Modal
                closeable
                isOpen={active && isShowTip}
                onDismiss={() => {
                    setIsShowTip(false)
                }}
                maxWidth={'450px'}

            >
                <JoinTipModal
                    onJoinInfo={tipInfo}
                />
            </Modal>
        </PoolGroupStyled>
    )
}

export const SV_PoolGroup = ({ poolCount, poolsDate, poolType, isLoad }) => {
    const [isShowTip, setIsShowTip] = useState(false)
    const [tipInfo, setTipInfo] = useState({})
    const { active } = useActiveWeb3React()

    const renderPoolList = () => {
        switch (poolType) {
            case 'FS':
                return poolsDate.map((item, index) => {
                    return <Grow in={true}>
                        <SV_CardItem key={index} data={item} detailsRoute='/sv-fixed-swap/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='FS' />
                    </Grow>
                })

            case 'SB':
                return poolsDate.map((item, index) => {
                    return <Grow in={true}>
                        <SV_CardItem key={index} data={item} detailsRoute='/sv-sealed-bid/' setIsShowTip={setIsShowTip} setTipInfo={setTipInfo} poolType='SB' />
                    </Grow>
                })
            default:
                break;
        }
    }

    return (
        <PoolGroupStyled col={3}>
            {isLoad === true ?
                [...new Array(poolCount)].map((_item, index) => {
                    return <SV_Skeleton key={index} />
                })
                :
                renderPoolList()
            }

            <Modal
                closeable
                isOpen={active && isShowTip}
                onDismiss={() => {
                    setIsShowTip(false)
                }}
                maxWidth={'450px'}
            >
                {/* <ModalTitle
                    style={{ textAlign: 'center', border: 'none' }}
                >Token imported</ModalTitle> */}
                <JoinTipModal
                    onJoinInfo={tipInfo}
                />
            </Modal>
        </PoolGroupStyled>
    )
}

export const Loading_PoolPage = () => {


    return (
        <PoolGroupStyled>
            <LoadingPageStyled>
                <img src={img_loading} alt="" />
            </LoadingPageStyled>
        </PoolGroupStyled>
    )
}

export const Empty_PoolPage = () => {
    return (
        <PoolGroupStyled>
            <EmptyPageStyled>
                <img src={img_empty} alt="" />
            </EmptyPageStyled>
        </PoolGroupStyled>
    )
}

