import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useYGiftEANFTList } from "../../../web3/storeEANFT";
import rect_logo from "../../../static/image/rect-logo.svg";
import { weiToNum } from "../../../utils/numberTransform";
import { PoolCard } from "../../../components/PoolCard";
import { useActivePlatform, useActiveWeb3React } from "../../../web3";
import { myContext } from "../../../reducer";
import { LayoutFrame } from "../../../components/common/Layout";
import { JoinTipModal } from "../../../components/common/JoinTipModal";
import Modal from "../../../components/common/Modal";
import { HANDLE_SHOW_CONNECT_MODAL } from "../../../const";

import { EmptyPoolPage, LoadingPage } from "../../../components/common/LoadingPage";
import icon_loading from '../../../static/image/icon-loading-white.svg';
import empty_pool from '../../../static/image/empty-pool-white.svg';

export const YGiftEANFT = ({ searchText, queryStatus }) => {

  const history = useHistory()
  const { active, library, account } = useActiveWeb3React()
  const { poolList, setLoaded } = useYGiftEANFTList()
  const { state, dispatch } = useContext(myContext);
  const { Psymbol } = useActivePlatform()
  const [isJoinTip, setIsJoinTip] = useState(false)
  const [onJoinInfo, setOnJoinInfo] = useState({})

  useEffect(() => {
    setLoaded(false)
  }, [])



  const onJoin = (index, fromSymbol, adress) => {
    console.log('onJoin', index, isJoinTip)
    if (isJoinTip) {
      if (active) {
        history.push(`/store/english-auction-nft/${index}`, { id: index });
      } else {
        dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
      }
      return
    }
    setTimeout(() => {
      setOnJoinInfo({
        index,
        fromSymbol,
        adress
      })
    }, 50);
    setIsJoinTip(true)

  }


  return (
    <LayoutFrame style={{ backgroundColor: '#121212', flexDirection: 'row', display: 'flex', paddingBottom: 64 }}>
      {!poolList ? <LoadingPage style={{ marginTop: 200 }} src={icon_loading} /> : poolList.length === 0 ? <EmptyPoolPage src={empty_pool} /> : (
        poolList.filter(item => {
          return (searchText === '' || item.name.includes(searchText) || item.index.toString() == searchText)
        }).map(item => {
          return (
            <PoolCard column={5} key={item.tokenId} onJoin={() => {
              if (active) {
                history.push(`/store/ygift/english-auction-nft/${item.index}`, { id: item.index });
              } else {
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
              }
            }} cover={item.cover ? item.cover : rect_logo} pool={item}
              poolMeta={[{
                title: 'Current Bid',
                content: `${weiToNum(item.amount)} ${Psymbol}`,
              }, {
                title: 'Token ID',
                content: item.tokenId
              }]} />
          )
        })
      )}

      <Modal
        closeable
        isOpen={isJoinTip}
        onDismiss={() => {
          setIsJoinTip(false)
        }}
        maxWidth={'450px'}

      >

        {/* <ModalTitle
                    style={{ textAlign: 'center', border: 'none' }}
                >Token imported</ModalTitle> */}
        <JoinTipModal
          onJoin={onJoin}
          onJoinInfo={onJoinInfo}
        />
      </Modal>
    </LayoutFrame>
  )


}

export const MyYGiftEANFT = ({ searchText, queryStatus }) => {

  const history = useHistory()
  const { active, library, account } = useActiveWeb3React()
  const { poolList, setLoaded } = useYGiftEANFTList()
  const { state, dispatch } = useContext(myContext);
  const { Psymbol } = useActivePlatform()
  const [isJoinTip, setIsJoinTip] = useState(false)
  const [onJoinInfo, setOnJoinInfo] = useState({})

  useEffect(() => {
    setLoaded(false)
  }, [])



  const onJoin = (index, fromSymbol, adress) => {
    // console.log('onJoin', index, isJoinTip)
    if (isJoinTip) {
      if (active) {
        history.push(`/store/english-auction-nft/${index}`, { id: index });
      } else {
        dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
      }
      return
    }
    setTimeout(() => {
      setOnJoinInfo({
        index,
        fromSymbol,
        adress
      })
    }, 50);
    setIsJoinTip(true)

  }


  return (
    <LayoutFrame style={{ backgroundColor: '#121212', flexDirection: 'row', display: 'flex', paddingBottom: 64 }}>
      {!poolList ? <LoadingPage style={{ marginTop: 200 }} src={icon_loading} /> : (
        poolList.filter(item => {
          return ((item[0] === account) && (searchText === '' || item.name.includes(searchText) || item.index.toString() == searchText))
        }).map(item => {
          return (
            <PoolCard column={5} key={item.tokenId} onJoin={() => {
              if (active) {
                history.push(`/store/ygift/english-auction-nft/${item.index}`, { id: item.index });
              } else {
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
              }
            }} cover={item.cover ? item.cover : rect_logo} pool={item}
              poolMeta={[{
                title: 'Current Bid',
                content: `${weiToNum(item.amount)} ${Psymbol}`,
              }, {
                title: 'Token ID',
                content: item.tokenId
              }]} />
          )
        })
      )}

      {poolList && poolList.filter(item => {
        return ((item[0] === account) && (searchText === '' || item.name.includes(searchText) || item.index.toString() == searchText))
      }).length === 0 ? <EmptyPoolPage src={empty_pool} /> : ''
      }


      < Modal
        closeable
        isOpen={isJoinTip}
        onDismiss={() => {
          setIsJoinTip(false)
        }}
        maxWidth={'450px'}

      >

        {/* <ModalTitle
                    style={{ textAlign: 'center', border: 'none' }}
                >Token imported</ModalTitle> */}
        <JoinTipModal
          onJoin={onJoin}
          onJoinInfo={onJoinInfo}
        />
      </Modal>
    </LayoutFrame >
  )


}
