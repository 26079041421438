import { getContract, useActiveWeb3React } from "../../web3";
import { useEffect, useState } from "react";
import bounceStake from "../../web3/abi/bounceStake.json";
import {
    getBotAddress,
    getFixSwapAddress,
    getLPStakingAddress,
    getLPTokenAddress,
    getStakingAddress
} from "../../web3/contractAddress";
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import bounce from "../../web3/abi/bounce.json";
import liquidityPool from "../../web3/abi/liquidityPool.json";

import { endOfDay } from "date-fns";


export const useQueryBotStaking = () => {
    const { active, account ,library, chainId } = useActiveWeb3React();
    const [botBalance, setBotBalance] = useState()
    const [rewards, setRewards] = useState()
    const [stakedAmount, setStakedAmount] = useState()
    const [unStakedAmount, setUnStakedAmount] = useState()
    const [stakedTotal, setStakedTotal] = useState()
    const [govReward, setGovReward] = useState()



    const loadBotStaking = async () => {
        const botContract = getContract(library, bounceERC20.abi, getBotAddress(chainId))
        const stakingContract = getContract(library, bounceStake.abi, getStakingAddress(chainId))
        try {
            botContract.methods.balanceOf(account).call().then((res) => {
                setBotBalance(res)
            })
        } catch (e) {
            console.log('balanceOf error:', e)
        }

        try {
            stakingContract.methods.calculateRewardInBot(account).call().then((res) => {
                setRewards(res)
            })
        } catch (e) {
            console.log('calculateRewardInBot error:', e)

        }

        try {
            stakingContract.methods.myTotalStake(account).call().then((res) => {
                setStakedAmount(res)
            })
        } catch (e) {
            console.log('myTotalStake error:', e)

        }

        try {
            stakingContract.methods.calculateUnStake(account).call().then((res) => {
                setUnStakedAmount(res)
            })
        } catch (e) {
            console.log('calculateUnStake error:', e)

        }


        try {
            stakingContract.methods.totalStake().call().then((res) => {
                setStakedTotal(res)
            })
        } catch (e) {
            console.log('totalStake error:', e)

        }

        try {
            stakingContract.methods.getGovReward(account).call().then((res) => {
                setGovReward(res)
            })
        } catch (e) {
            console.log('getGovReward error:', e)

        }

    }

    useEffect(() => {
        if (active) {
            loadBotStaking()
        }

    }, [active])

    return { botBalance, rewards, stakedAmount, unStakedAmount, stakedTotal, govReward }
}


export const useQueryMining = () => {
    const { active, account, library, chainId } = useActiveWeb3React();
    const [rewards, setRewards] = useState()
    const [left, setLeft] = useState()
    const [progress, setProgress] = useState()


    const loadMing = async () => {
        const bounceContract = getContract(library, bounce.abi, getFixSwapAddress(chainId))
        try {
            bounceContract.methods.totalBonus().call().then((res) => {
                setRewards(res)
            })
        } catch (e) {
            console.log('balanceOf error:', e)
        }

    }


    const getTime = () => {
        const now = new Date();
        const offset = now.getTimezoneOffset() * 60 * 1000;
        const nowtime = new Date(now.getTime() + offset);
        const endtime = endOfDay(nowtime);

        let lefttime = endtime.getTime() - nowtime.getTime();
        let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
        let leftm = Math.floor(lefttime / (1000 * 60) % 60);
        if (leftm < 10) {
            leftm = '0' + leftm;
        }
        let lefts = Math.floor(lefttime / 1000 % 60);
        if (lefts < 10) {
            lefts = '0' + lefts;
        }
        const left = lefth + " : " + leftm + " : " + lefts;

        const pro = 100 - parseInt(lefttime / (7 * 24 * 60 * 60 * 1000) * 100)

        setProgress(pro)
        setLeft(left);
    }

    useEffect(() => {
        if (active) {
            loadMing()
            setInterval(() => {
                getTime();
            }, (1000));
        }

    }, [active])


    return { rewards, left, progress }
}


export const useQueryLPStaking = () => {
    const { active, account, library, chainId } = useActiveWeb3React();
    const [lpBalance, setLPBalance] = useState()
    const [rewards, setRewards] = useState()
    const [rewardsPre, setRewardsPre] = useState()

    const [stakedAmount, setStakedAmount] = useState()
    const [stakingReward, setStakingReward] = useState()
    const [stakedTotal, setStakedTotal] = useState()


    const loadLPStaking = async () => {
        const lpTokenContract = getContract(library, bounceERC20.abi, getLPTokenAddress(chainId))
        const lpStakingContract = getContract(library, liquidityPool.abi, getLPStakingAddress(chainId))
        try {
            lpTokenContract.methods.balanceOf(account).call().then((res) => {
                setLPBalance(res)
            })
        } catch (e) {
            console.log('balanceOf error:', e)
        }

        try {
            lpStakingContract.methods.totalStaking().call().then((res) => {
                setStakedTotal(res)
            })
        } catch (e) {
            console.log('balanceOf error:', e)
        }

        try {
            lpStakingContract.methods.stakingOf(account).call().then((res) => {
                setStakedAmount(res)
            })
        } catch (e) {
            console.log('balanceOf error:', e)
        }

        try {
            lpStakingContract.methods.getBonusPerDay().call().then((res) => {
                setRewardsPre(res)
            })
        } catch (e) {
            console.log('balanceOf error:', e)
        }

        try {
            lpStakingContract.methods.harvestCapacity(account).call().then((res) => {
                setStakingReward(res)
            })
        } catch (e) {
            console.log('balanceOf error:', e)
        }

    }

    useEffect(() => {
        if (active) {
            loadLPStaking()
        }

    }, [active])

    return { lpBalance, rewards, stakedAmount, stakingReward, stakedTotal, rewardsPre }
}
