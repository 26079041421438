import styled from 'styled-components'

export const AccountFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
AccountFrame.Background = styled.div`
  background-color: #121212;
  /* height: 95vh; */
`

AccountFrame.SelectFrame = styled.div`
  width: 1280px;
  margin: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`
