import React, {useState, useEffect, useContext} from 'react';
import failedIcon from '../static/image/error.svg';
import {NFTPoolItem} from './content/NFTPoolItem';
import {getContractLink, queryBidCreatorFilledAmount, queryBidCreatorClaimed} from './const';
import {isEqualTo, isGreaterThan} from '../utils/common'
import rotate from '../static/image/rotate.svg';
import {myContext} from '../reducer'
import {getDutchAuctionV1} from "../components/utils/web3";
import classNames from "classnames";



export const NFTPool = ({
                          symbol,
                          pool,
                          account,
                          loading,
                          onStatusChange,
                          onHashChange,
                            onRoundChange,
                        }) => {

  const {state, dispatch} = useContext(myContext);
  const [address, setAddress] = useState('');
  const [bidTokenAmount, setBidTokenAmount] = useState(0);
  const [bidEthAmount, setBidEthAmount] = useState(0);
  const [bidStatus, setBidStatus] = useState('');





  const {web3, myAccount} = state;


  useEffect(() => {
    async function loadAddress() {
      const address = await getContractLink(pool ? pool.fromAddress : '');
      setAddress(address);
    }

    loadAddress()
  }, [pool]);

  useEffect(() => {

  }, [web3,pool]);


  return (
      <>
        {loading ?
            <div className='content-box'>
              <img src={rotate} className='waiting' alt='waiting'/>
              <p>Loading</p>
            </div> :
            pool && myAccount
                ? <>
                  <ul className='form-head join-title'>
                    <li>{pool.name}</li>
                    <li><a href={address} target='_blank' rel="noopener noreferrer">{pool.fromAddress}</a></li>
                  </ul>
                  <form className='form-content'>
                    <NFTPoolItem
                        web3={web3}
                        symbol={symbol}
                        pool={pool}
                        onRoundChange={()=>{onRoundChange()}}/>
                    <dl className='define' style={{marginTop: 10}}>
                      <li className={classNames('status',  pool.status === 'Live'? 'Live':  bidStatus)}>{ pool.status === 'Live'? 'Live': bidStatus}</li>
                    </dl>
                  </form>
                  {/*<div className='pool-status'>*/}
                  {/*<img src={sucIcon} alt='success'/>*/}
                  {/*<p className='status-text'>{`Pool is ${pool.status}`}</p>*/}
                  {/*</div>*/}
                </>
                : <div className='content-box'>
                  <img src={failedIcon} alt='no-login'/>
                  <p>You should connect to a wallet<br/> to see your bounce pool</p>
                </div>}
      </>
  )
}
