import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from "styled-components";
import icon_home_page from '../../static/image/icon_home_page.svg'
import icon_twitter from '../../static/image/icon_twitter.svg'
import icon_instagram from '../../static/image/icon_instagram.svg'



const StoreItem = styled.div`
  width: 620px;
  height: 316px;
  padding: 32px 24px 24px;
  background: #FFF;
  margin: 24px 0;
`

StoreItem.Title = styled.span`
font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 26px;
color: #000;
display: flex;
justify-content: space-between;
align-items: center;
`

StoreItem.CoverFrame = styled.div`
  width: 572px;
  height: 204px;
  margin-top: 24px;
  position: relative;
`

StoreItem.Modal = styled.div`
width: 100%;
height: 100%;
position: absolute;
top: 0;
background: linear-gradient(0deg, rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.88));
display: flex;
align-items: center;
justify-content: center;
`

StoreItem.ModalContent = styled.div`
  width: 544px;
  height: 176px;
  color: #fff;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
font-size: 18px;
cursor: pointer;
`

StoreItem.Cover = styled.img`
  width: 100%;
  height: 100%;
  background: rgba(211,211,218,0.5);
`

const SocialFrame = styled.div`
  display: flex;
`

const Social = styled.img`
  cursor: pointer;
  margin-left: 30px;
`

export const StoreCard = ({store}) => {

  const history = useHistory()
  const [hovered, setHovered] = useState(false)

  return (
      <StoreItem onMouseEnter={()=>{setHovered(true)}} onMouseLeave={()=>{setHovered(false)}}>
        <StoreItem.Title active={store.active}>
          {store.name}
          {store.active && (
              <SocialFrame>
                {store.homePage && <Social onClick={()=>{window.open(store.homePage)}} src={icon_home_page}/>}
                {store.twitter && <Social onClick={()=>{window.open(store.twitter)}} src={icon_twitter}/>}
                {store.instagram && <Social onClick={()=>{window.open(store.instagram)}} src={icon_instagram}/>}
              </SocialFrame>
          )}
        </StoreItem.Title>
        <StoreItem.CoverFrame>
          <StoreItem.Cover src={store.cover}/>
          {hovered && store.active && (
              <StoreItem.Modal>
                <StoreItem.ModalContent onClick={()=>{
                  console.log('detail click')
                  history.push(store.link)
                }}>Visit Store</StoreItem.ModalContent>
              </StoreItem.Modal>
          )}
        </StoreItem.CoverFrame>
      </StoreItem>
      )
}
