import React, { useState, useCallback, useEffect, useContext } from 'react';
import styled from 'styled-components'
import {
    Address,
    ITextR,
    LayoutFrame, LineDivider,
    OText2,
    Pool,
    PoolFrame,
    Progress,
    renderTime
} from "../../components/common/Layout";
import icon_return from '../../static/image/icon-return.svg'
import { usePoolDetail } from "./Hooks";
import BigNumber from "bignumber.js";
import { fromWei, getProgress, numToWei, weiDiv, weiToNum, weiToNumber, weiSub, weiMul } from "../../utils/numberTransform";
import classNames from "classnames";
import { useHistory, useParams } from 'react-router-dom';
import { Form, FormStatus, Input } from "../../components/common/Form";
import icon_max from "../../static/image/icon-max.svg";
import { Button } from "../../components/common/Button";
import { useEthBalance, useTokenBalance } from "../../web3/common";
import { getContract, useActivePlatform, useActiveWeb3React } from "../../web3";
import dutchAuctionV1 from "../../web3/abi/dutchAuctionV1.json";

import { getDutchAuction } from "../../web3/contractAddress";
import Web3 from 'web3'
import {
    BidModal,
    initStatus,
    errorStatus,
    successStatus,
    confirmStatus,
    pendingStatus,
    cancelStatus
} from "../../components/common/BidModal";
import { useLeftTime } from "../../hooks/useLeftTime";
import { isGreaterThan } from "../../utils/common";
import { myContext } from "../../reducer";
import md5 from "js-md5";
import { PasswordModal } from "../../components/common/PasswordMpdal";
import { useIsXSDown } from '../../components/utils/themeHooks';
import { de } from 'date-fns/esm/locale';
import { rgba } from 'polished';

const { toWei, BN } = Web3.utils

BigNumber.config({ EXPONENTIAL_AT: [-40, 40] })


export const DAPoolDetail = () => {
    const history = useHistory()
    const { id } = useParams();
    const {
        name, symbol, decimals, address, password, amount, minAmount, maxAmount, isMyPool, amountSwap0P, duration,
        closeAt, status, isOnlyBot, currentPrice, creatorClaimed, isSwaped,
    } = usePoolDetail(id)

    const { account, library, chainId } = useActiveWeb3React()
    const [bidAmount, setBidAmount] = useState()
    const [leftTimes, setLeftTimes] = useState()
    const [leftTimes_2, setLeftTimes_2] = useState()
    const [bidRatio, setBidRatio] = useState()
    const [bidStatus, setBidStatus] = useState(initStatus)
    const [passwordModal, setPasswordModal] = useState(false)
    const [enterPW, setEnterPW] = useState()
    const [errorPW, setErrorPW] = useState()
    const [errorBidAmount, setErrorBidAmount] = useState()
    const [currentAmount, setCurrentAmount] = useState()
    const [leftReducer, setLeftReducer] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
    const [leftReducer_2, setLeftReducer_2] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
    const isXSDown = useIsXSDown();
    const { Psymbol } = useActivePlatform()
    const [pro_plan, setPro_plan] = useState(0)


    console.log('dutch hooks', usePoolDetail(id))

    const { ethBalance } = useEthBalance()

    const { balance } = useTokenBalance()

    let timer = null

    useEffect(() => {
        if (closeAt && duration) {
            const newTime = new Date().getTime() / 1000
            const duration_2 = duration - newTime
            setLeftTimes(duration_2)
            setLeftTimes_2(closeAt)
        }
    }, [closeAt, duration]);

    useEffect(() => {
        if (!currentPrice || !amount || !minAmount || !maxAmount) return
        const currentPrice_2 = Number(fromWei(currentPrice, decimals).toFixed(6).toString())
        const amount_2 = Number(fromWei(amount, decimals).toFixed(6).toString())
        const minAmount_2 = Number(fromWei(minAmount, decimals).toFixed(6).toString())
        const maxAmount_2 = Number(fromWei(maxAmount, decimals).toFixed(6).toString())

        const pro_plan = (maxAmount_2 - currentPrice_2 * amount_2) / (maxAmount_2 - minAmount_2)
        const cur_price = Number(parseFloat(currentPrice_2).toFixed(4))

        setPro_plan(pro_plan)
        setCurrentAmount(cur_price)

    }, [currentPrice, amount, minAmount, maxAmount])

    useEffect(() => {
        timer = setInterval(() => {
            if (leftTimes > 0) {
                setLeftTimes(leftTimes - 1)
                setLeftTimes_2(leftTimes_2 - 1)
                console.log('leftTimes', leftTimes)
                renderNextTime()
            } else {
                clearInterval(timer)
            }
        }, (1000));
        return () => {
            clearInterval(timer)
        }
    }, [leftTimes, leftTimes_2]);

    useEffect(() => {
        if (isOnlyBot && isGreaterThan(toWei('0.1'), balance)) {
            setErrorBidAmount('Sorry! You are not qualified as bot holder.')
        }
    }, [ethBalance, isOnlyBot, balance])

    useEffect(() => {
        if (password && password !== '0') {
            setPasswordModal(true)
        }
    }, [password])

    const renderNextTime = () => {
        let leftd = Math.floor(leftTimes / (60 * 60) / 24);
        let lefth = Math.floor(leftTimes / (60 * 60) % 24);
        let leftm = Math.floor(leftTimes / (60) % 60);
        let lefts = Math.floor(leftTimes % 60);

        const left = {
            days: leftd < 0 ? 0 : leftd,
            hours: lefth < 0 ? 0 : lefth,
            minutes: leftm < 0 ? 0 : leftm,
            seconds: lefts < 0 ? 0 : lefts,
        };

        setLeftReducer(left)

        let leftd_2 = Math.floor(leftTimes_2 / (60 * 60) / 24);
        let lefth_2 = Math.floor(leftTimes_2 / (60 * 60) % 24);
        let leftm_2 = Math.floor(leftTimes_2 / (60) % 60);
        let lefts_2 = Math.floor(leftTimes_2 % 60);
        const left_2 = {
            days: leftd_2 < 0 ? 0 : leftd_2,
            hours: lefth_2 < 0 ? 0 : lefth_2,
            minutes: leftm_2 < 0 ? 0 : leftm_2,
            seconds: lefts_2 < 0 ? 0 : lefts_2,
        };

        console.log('setLeftReducer', left, left_2)
        setLeftReducer_2(left_2)
    }

    const onBid = async () => {
        if (password && password !== '0' && new BN(md5(enterPW)).toString() !== password) {
            setPasswordModal(true)
            return
        }
        console.log('on bid--->', bidRatio, bidAmount)
        if (!bidAmount || !bidRatio) {
            return
        }

        const bounceContract = getContract(library, dutchAuctionV1.abi, getDutchAuction(chainId))

        const bidFromAmount = numToWei(new BigNumber(1).dividedBy(bidAmount).multipliedBy(new BigNumber(bidRatio)).toString(), decimals);
        const bidToAmount = toWei(bidAmount, 'ether');
        console.log('bid:', bidFromAmount, bidToAmount)

        setBidStatus(confirmStatus);
        try {
            bounceContract.methods.bid(id, bidFromAmount, bidToAmount, password)
                .send({ from: account, value: bidToAmount })
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    // setQueryStatus(true)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid error', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }


    const onClaim = async () => {
        const bounceContract = getContract(library, dutchAuctionV1.abi, getDutchAuction(chainId))
        // const claimFunc = isMyPool ? 'creatorClaim' : 'bidderClaim'
        setBidStatus(confirmStatus);
        try {
            bounceContract.methods['creatorClaim'](id)
                .send({ from: account })
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    // setQueryStatus(true)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }
    }

    return (
        <LayoutFrame style={{ marginTop: 27 }}>
            <Pool.Return src={icon_return} onClick={() => {
                history.goBack()
            }} />
            <LayoutFrame width={'1072px'} style={{ padding: isXSDown ? '40px 20px' : '0 0 40px', margin: 'auto', marginTop: 32, }}>
                <Pool.Mode>Dutch-Auction</Pool.Mode>
                <Pool.Header><span>{name}</span></Pool.Header>
                <Address>{address}</Address>
                <Pool.Content style={{ marginTop: 40, marginBottom: 50 }}>
                    <Pool.Content width={isXSDown ? '100%' : '520px'} style={{ marginTop: 0 }}>

                        <Pool.Content width={isXSDown ? '100%' : '456px'} style={{ marginTop: 0, flexDirection: 'column' }}>
                            <Pool.Status style={{ width: 'fit-content' }} className={classNames('status', status)}><i className={status}></i>{status}</Pool.Status>

                            <ITextR style={{ marginTop: 8, textAlign: 'left' }}>{`Participant: ${isOnlyBot ? 'BOT holder' : 'Public'}`}</ITextR>
                        </Pool.Content>

                        {/* <Pool.Block style={{ width: '100%' }}>
                            <span>Starting Price</span>
                            <span>{floor && `1 ETH = ${weiDiv(fromWei(floor.from, decimals), fromWei(floor.to))} ${symbol}`}</span>
                        </Pool.Block> */}
                        <Pool.Block style={{ width: isXSDown ? '100%' : '100%' }}>
                            <span>Total Allocation</span>
                            <span>{Number(amount && fromWei(amount, decimals).toFixed(6).toString() || 0)} {symbol && symbol}</span>
                        </Pool.Block>

                        <Pool.Block style={{ width: isXSDown ? '100%' : '200px' }}>
                            <span>Starting Price</span>
                            <span>1 {symbol} = {Number(maxAmount && fromWei(maxAmount, decimals).dividedBy(fromWei(amount, decimals)).toFixed(3).toString() || 0)} {Psymbol}</span>
                        </Pool.Block>

                        <Pool.Block style={{ width: isXSDown ? '100%' : '200px' }}>
                            <span>Reserve Price</span>
                            <span>1 {symbol} = {Number(minAmount && fromWei(minAmount, decimals).dividedBy(fromWei(amount, decimals)).toFixed(3).toString() || 0)} {Psymbol}</span>
                        </Pool.Block>
                        <DAProgress
                            // plan={'0.4'}
                            plan={pro_plan && pro_plan}
                            curPrice={parseFloat(1 / currentAmount).toFixed(2)}
                            time={leftReducer_2}
                            Psymbol={Psymbol}
                            symbol={symbol}
                            amount={weiToNum(amount, decimals)}
                            amountSwap0P={weiToNum(amountSwap0P, decimals)}
                            status={status}
                        />


                        {/* <Pool.Block style={{ width: isXSDown ? '100%' : '200px' }}>
                            <span>Cruuent Price</span>
                            <span>1 {Psymbol || 'ETH'} =  {Number(currentPrice && weiDiv(1, fromWei(currentPrice).toFixed(6)).toString() || 0)} {symbol}</span>
                        </Pool.Block> */}
                    </Pool.Content>

                    <Pool.Content width={isXSDown ? '100%' : '432px'}
                        style={{
                            flexDirection: 'column',
                            padding: isXSDown ? '48px 20px' : '48px 56px',
                            justifyContent: 'center',
                            marginTop: 0,
                            backgroundColor: 'rgba(248, 248, 251, 1)'
                        }}>


                        {status && status === 'Live' ? (
                            // join
                            <>

                                {(!isMyPool) && (
                                    // 参与别人的池子
                                    <>
                                        <OText2>Join The Pool</OText2>

                                        {renderTime(leftReducer)}
                                        <LineDivider style={{ marginTop: 0 }} />
                                        <Pool.topInfo>
                                            <span style={{ opacity: .3 }}>Swap ratio for Bid</span>
                                        </Pool.topInfo>

                                        <Form top={'0px'} width={isXSDown ? '100%' : '320px'} input={<Input
                                            style={{ padding: '8px 0', color: '#1F191B', fontSize: 16, lineHeight: '20px', fontFamily: 'Helvetica Neue', fontWeight: "bold", marginLeft: 8 }}
                                            placeholder={''}
                                            type='number'
                                            value={bidRatio}
                                            onBlur={() => {
                                                // if (bidRatio && floor && isGreaterThan(bidRatio, weiDiv(fromWei(floor.from, decimals), fromWei(floor.to)))) {
                                                //     setBidRatio(weiDiv(fromWei(floor.from, decimals), fromWei(floor.to)))
                                                // }
                                            }}
                                            onChange={(e) => {
                                                setBidRatio(e.target.value.replace(/[^\d.]/g, ''))
                                            }}
                                        />} name={' '} prefix={`1 ${symbol} =`} suffix={Psymbol} />


                                        <Pool.topInfo>
                                            <span>Your Bid Amount</span>
                                            <span>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>
                                        </Pool.topInfo>
                                        <Form error={errorBidAmount} top={'0px'} width={isXSDown ? '100%' : '320px'} input={<Input
                                            style={{ padding: '8px 0', color: '#1F191B', fontSize: 16, lineHeight: '20px', fontFamily: 'Helvetica Neue', fontWeight: "bold" }}
                                            placeholder={''}
                                            type='number'
                                            value={bidAmount}
                                            onBlur={() => {
                                                // console.log('amount blur')
                                                // if (bidAmount && ethBalance && isGreaterThan(toWei(bidAmount), ethBalance)) {
                                                //     console.log('check--->')
                                                //     setBidAmount(weiToNumber(ethBalance))
                                                // } else if ((bidAmount && limit) && isGreaterThan(limit, toWei(bidAmount))) {
                                                //     setBidAmount(fromWei(limit))
                                                // }
                                            }}
                                            onChange={(e) => {
                                                setBidAmount(e.target.value.replace(/[^\d.]/g, ''))
                                            }} />} name={' '} addonAfter={(<img src={icon_max} />)}
                                        //   extra={
                                        //       <span>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>}
                                        />
                                        <Button black onClick={
                                            onBid
                                        } style={{ marginTop: 50 }}>Go</Button>
                                    </>
                                )}

                                {(isMyPool) && (

                                    <>
                                        <OText2>My Pool</OText2>

                                        <div style={{ marginTop: 9 }}>
                                            {renderTime(leftReducer)}
                                        </div>
                                        <LineDivider style={{ marginTop: 24 }} />

                                        <Pool.Meta style={{ marginTop: 16, display: 'block', textAlign: 'left' }}>
                                            <div>Current Auction Price:</div>
                                            {/* <div>{totalBid && `${weiToNumber(totalBid.to)} ETH`}</div> */}
                                            <div style={{
                                                fontSize: 24,
                                                fontFamily: 'Optima',
                                                fontWeight: "bold",
                                                width: '100%',
                                                marginTop: 16
                                            }}>1 {Psymbol || 'ETH'} =  {Number(currentPrice && weiDiv(1, fromWei(currentPrice).toFixed(3)).toString() || 0)} {symbol}</div>
                                        </Pool.Meta>
                                        {<Button className='display'>This Pool Is Runing</Button>}
                                    </>
                                )}

                            </>
                        ) : (
                                // not join
                                <>
                                    {isMyPool ? <>
                                        <OText2>My Pool</OText2>
                                        <div style={{ marginTop: 9 }}>
                                            {renderTime(leftReducer)}
                                        </div>
                                        <LineDivider style={{ marginTop: 24 }} width={isXSDown ? '100%' : '320px'} />
                                        <Pool.Meta style={{ marginTop: 16, display: 'block', textAlign: 'left' }}>
                                            <div>Current Auction Price:</div>
                                            {/* <div>{totalBid && `${weiToNumber(totalBid.to)} ETH`}</div> */}
                                            <div style={{
                                                fontSize: 20,
                                                fontFamily: 'Optima',
                                                fontWeight: "bold",
                                                width: '100%',
                                                marginTop: 16
                                            }}> 1 {Psymbol || 'ETH'} =  {Number(currentPrice && weiDiv(1, fromWei(currentPrice).toFixed(4)).toString() || 0)} {symbol}</div>
                                        </Pool.Meta>
                                        {!creatorClaimed && status !== 'Live' && <Button black onClick={onClaim}>Claim your {isSwaped ? Psymbol : 'TOKEN'}</Button>}
                                        {!creatorClaimed && status === 'Live' && <Button className='display'>This Pool Is Runing</Button>}
                                        {creatorClaimed && <Button className='disable display'>You Already Claim Token</Button>}

                                    </> : <>
                                            <OText2>Join The Pool</OText2>
                                            <div style={{ marginTop: 9 }}>
                                                {renderTime(leftReducer)}
                                            </div>
                                            <LineDivider style={{ marginTop: 24 }} width={isXSDown ? '100%' : '320px'} />
                                            <Pool.Meta style={{ marginTop: 16, display: 'block', textAlign: 'left' }}>
                                                <div>Current Auction Price:</div>
                                                {/* <div>{totalBid && `${weiToNumber(totalBid.to)} ETH`}</div> */}
                                                <div style={{
                                                    fontSize: 24,
                                                    fontFamily: 'Optima',
                                                    fontWeight: "bold",
                                                    width: '100%',
                                                    marginTop: 16
                                                }}>1 {Psymbol || 'ETH'} =  {currentPrice && Number(new BigNumber(1).dividedBy(fromWei(currentPrice)).toFixed(4).toString()) || 0} {symbol}</div>
                                            </Pool.Meta>
                                            <Button className='disable display'>This Pool Is Closed</Button>
                                        </>

                                    }
                                </>
                            )}
                    </Pool.Content>
                </Pool.Content>
            </LayoutFrame>


            {/*{fromBidAmount && fromAmount && (*/}
            {/*    <Progress height={'8px'} className={classNames('progress', status)}>*/}
            {/*        <Progress.Value style={{width: `${getProgress(fromBidAmount, fromAmount)}%`}}*/}
            {/*                        className={classNames('progress-value', status)}/>*/}
            {/*    </Progress>*/}
            {/*)}*/}


            <BidModal modalStatus={bidStatus} onDismiss={() => {
                setBidStatus(initStatus)
            }} />


            <PasswordModal error={errorPW} onDismiss={() => {
                setPasswordModal(false)
            }} onChange={(password) => {
                setEnterPW(password)
            }} onConfirm={() => {
                console.log('password', password, new BN(md5(enterPW)).toString())
                if (new BN(md5(enterPW)).toString() === password) {
                    console.log('confirm password')
                    setPasswordModal(false)
                    setErrorPW(null)
                } else {
                    setErrorPW('password is wrong, please enter again')
                }
            }} show={passwordModal} />
        </LayoutFrame >
    )
}

const DAProgress = ({ plan, curPrice, time, Psymbol, symbol, amountSwap0P, amount, status }) => {


    const DAProgressStyled = styled.div`
        width: 100%;
        height: 80px;
        /* border: 1px solid #ccc; */
        box-sizing: border-box;

        .ProgressBox{
            width: 100%;
            height: 12px;
            box-sizing: border-box;
            position: relative;
            background-color: #f5f5f5;

            .pro{
                width: ${({ r_plan }) => { return r_plan + '%' }};
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background-color: #555; /* 不支持线性的时候显示 */
                background-image: linear-gradient(to right, #000 , #D3D3DA);
            }
        }

        .infoBox{
            position: relative;
            width: 100%;
            height: 68px;
            .info{
                width:  150px;
                height: 100%;
                position: absolute;
                top: 0;
                box-sizing: border-box;

                &.left{
                    left: ${({ l_plan }) => { return l_plan + '%' }};
                    border-left: 1px solid #000;
                    text-align: left;
                    padding-left: 5px;
                }

                &.right{
                    border-right: 1px solid #000;
                    text-align: right;
                    padding-right: 5px;
                    right: ${({ r_plan }) => { return r_plan + '%' }};
                }

                h5{
                    font-family: 'IBM Plex Mono';
                    font-size: 11px;
                    color: #1F191B;
                    opacity: .5;
                    margin-top: 8px;
                }
                p{
                    font-family: 'Optima';
                    font-size: 14px;
                    font-weight: bold;
                    color: #1F191B;
                    margin-top: 8px;
                }

                
            }
        }

        .auction_pro{
            width: 100%;
            margin-top: 20px;
            border-top: 1px solid rgba(0,0,0,.2);
            padding-top: 10px;
            .info_2{
                display: flex;
                font-size: 12px;
                font-family: 'IBM Plex Mono';
                color: #1F191B;
                p{
                    &:nth-child(2){
                        opacity: .3;
                    }
                }
            }

            .pro_2{
                position: relative;
                width: 100;
                height: 5px;
                background-color: ${({ status }) => { return status === 'Live' ? rgba(45, 171, 80, .1) : status === 'Filled' ? rgba(114, 138, 224, .1) : rgba(211, 211, 218, .1) }};
                margin-top: 10px;

                div{
                    position: absolute;
                    background-color:${({ status }) => { return status === 'Live' ? '#2DAB50' : status === 'Filled' ? '#728AE0' : '#D3D3DA' }};
                    height: 100%;
                    width: ${({ a_plan }) => { return a_plan }};
                }
            }
        }
    `
    return <DAProgressStyled l_plan={parseFloat(plan) * 100 || 0} r_plan={(1 - parseFloat(plan)) * 100 || 0} status={status} a_plan={(weiDiv(amountSwap0P, amount) * 100 || 0) + '%'}>
        <div className='ProgressBox'>
            <div className='pro'></div>
        </div>
        <div className='infoBox'>
            <div className={parseFloat(plan) > 0.6 ? 'info right' : 'info left'}>
                <h5>Current Price</h5>
                <p>1 {symbol} = {Number(new BigNumber(1).dividedBy(curPrice).toFixed(4).toString())} {Psymbol}</p>
                <h5>{renderTime(time, null, true)}</h5>
                {/* <h5>{'0d : 32h : 12m : 10s'}</h5> */}
            </div>
        </div>
        <div className='auction_pro'>
            <div className='info_2'>
                <p>Auction progress: {amountSwap0P || 0} ETH</p>
                <p>/ {amount || 0} ETH</p>
            </div>
            <div className='pro_2'>
                <div></div>
            </div>
        </div>
    </DAProgressStyled >
}
