import React, { useState, useEffect } from 'react';
import BounceFSNFT from '../web3/abi/AlpacaFixedSwapNFT.json'
import BounceNFT from '../web3/abi/BounceFSNFT.json'
import yGift from '../web3/abi/yGift.json'
import { useActiveWeb3React } from "./index";
import { getContract } from "../web3";
import { getBounceAlpacasFSNFTAddress, getBounceYGiftFSAddress } from "./contractAddress";
import { queryData } from "./common";
import { englishAuctionKeyMap, getContractLink } from "../Content/const";
import Web3 from "web3";
import axios from "axios";


export const keyMap = [{
    key: 'creatorP',
    name: 'creator',
    needTrans: false,
}, {
    key: 'nameP',
    name: 'name',
    needTrans: false,
}, {
    key: 'token0P',
    name: 'fromAddress',
    needTrans: false,
}, {
    key: 'passwordP',
    name: 'password',
    needTrans: false,
}, {
    key: 'amountTotal0P',
    name: 'fromAmount',
    needTrans: false,
}, {
    key: 'amountTotal1',
    name: 'toAmount',
    needTrans: true,
}, {
    key: 'closeAtP',
    name: 'time',
}, {
    key: 'onlyBotHolder',
    name: 'onlyBot',
    needTrans: false
}, {
    key: 'minEthBidP',
    name: 'minEthPerWallet',
    needTrans: true
}];




export const useFSNFTList = () => {
    const [loading, setLoading] = useState(true)
    const [FSNFTPoolList, setFSNFTPoolList] = useState()
    const [loadFSNFTed, setLoadFSNFTed] = useState(true)

    const { active, library, chainId } = useActiveWeb3React();

    const queryPoolItem = async (library, bounceContract, index) => {
        let data = await queryData(bounceContract, 'pools', index);
        data = JSON.parse(JSON.stringify(data));
        data.index = index;
        englishAuctionKeyMap.forEach(item => {
            data[item.name] = data[item.key]
        })

        try {
            const alpaca = await axios.get(`${chainId === 56 ? 'https://apibsc.alpaca.city/metadata/' : 'https://api.alpaca.city/metadata/'}${data.tokenId}`)
            data.cover = alpaca.data ? alpaca.data.image : ''
        } catch (e) {

        }

        data.type = 'fixedswapnft';
        let status = 'Closed';
        const isExpired = new Date(data.closeAt * 1000) <= Date.now();
        data.password = await queryData(bounceContract, 'passwordP', index);

        let networkID = 1
        if (!active) {
            const web3 = await new Web3(Web3.givenProvider);
            // noConnect.provider = web3.eth.currentProvider
            networkID = await web3.eth.getChainId();
        }

        const contract = getContract(library, BounceFSNFT.abi, getBounceAlpacasFSNFTAddress(active ? chainId : networkID))
        try {
            // console.log('FSNFTPoolList', contract, index)
            const swappedP_res = await contract.methods.swappedP(index).call()
            // console.log('FSNFTPoolList', swappedP_res)
            if (swappedP_res === true) {
                status = 'Filled';
            } else {
                if (!isExpired) {
                    status = 'Live';
                } else {
                    status = 'Closed';
                }
            }
            data.status = status

            return data;
        } catch (error) {
            // console.log('FSNFTPoolList', data)
        }

        return data;
    };


    useEffect(() => {

        async function querySBNFTPollList() {
            setLoadFSNFTed(true)
            try {
                let noConnect = {};
                let networkID = 1
                if (!active) {
                    const web3 = await new Web3(Web3.givenProvider);
                    noConnect.provider = web3.eth.currentProvider
                    networkID = await web3.eth.getChainId();
                }
                const curLibrary = active ? library : noConnect
                //const botContract = getContract(active? library: noConnect, BounceFSNFT20.abi, getBotAddress(active? chainId: networkID))
                const bounceContract = getContract(curLibrary, BounceFSNFT.abi, getBounceAlpacasFSNFTAddress(active ? chainId : networkID))
                const poolCount = await bounceContract.methods.getPoolCount().call();
                console.log('SB NFT poolCount', poolCount)
                let poolIdList = [];

                for (let i = 0; i < poolCount; i++) {
                    poolIdList[i] = poolCount - i - 1;
                }

                let i = 0;
                let poolList;
                let queryIdList;
                let allPools = [];
                while (poolIdList.length !== 0) {
                    console.log('poolIdList--->',poolIdList)

                    queryIdList = poolIdList.slice(0, 15);
                    poolIdList.splice(0, 15);
                    poolList = await Promise.all(queryIdList.map(async (item) => {
                        const pool = await queryPoolItem(curLibrary, bounceContract, item);
                        if (pool.fromAddress) {
                            pool.address = await getContractLink(pool.fromAddress)
                        }
                        pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
                        return pool
                    }));
                    allPools = allPools.concat(poolList)
                    console.log('allPools', allPools)
                    setFSNFTPoolList(allPools);
                    i++
                }
            } catch (e) {
                console.log('query SB nft pool list error', e)
            }

        }
        if (!loadFSNFTed && active) {
            querySBNFTPollList()
        }

    }, [loadFSNFTed, library])
    return { loading, FSNFTPoolList, setLoadFSNFTed }
}

export const useMyFSNFTList = () => {
    const [loading, setLoading] = useState(true)
    const [FSNFTPoolList, setFSNFTPoolList] = useState()
    const [loadFSNFTed, setLoadFSNFTed] = useState(true)

    const { active, library, chainId, account } = useActiveWeb3React();

    const queryPoolItem = async (library, bounceContract, index) => {
        let data = await queryData(bounceContract, 'pools', index);
        data = JSON.parse(JSON.stringify(data));
        data.index = index;
        englishAuctionKeyMap.forEach(item => {
            data[item.name] = data[item.key]
        })

        try {
            const alpaca = await axios.get(`${chainId === 56 ? 'https://apibsc.alpaca.city/metadata/' : 'https://api.alpaca.city/metadata/'}${data.tokenId}`)
            data.cover = alpaca.data ? alpaca.data.image : ''
        } catch (e) {

        }

        data.type = 'fixedswapnft';
        let status = 'Closed';
        const isExpired = new Date(data.closeAt * 1000) <= Date.now();
        data.password = await queryData(bounceContract, 'passwordP', index);

        let networkID = 1
        if (!active) {
            const web3 = await new Web3(Web3.givenProvider);
            // noConnect.provider = web3.eth.currentProvider
            networkID = await web3.eth.getChainId();
        }

        const contract = getContract(library, BounceFSNFT.abi, getBounceAlpacasFSNFTAddress(active ? chainId : networkID))
        try {
            console.log('FSNFTPoolList', contract, index)
            const swappedP_res = await contract.methods.swappedP(index).call()
            console.log('FSNFTPoolList', swappedP_res)
            if (swappedP_res === true) {
                status = 'Filled';
            } else {
                if (!isExpired) {
                    status = 'Live';
                } else {
                    status = 'Closed';
                }
            }
            data.status = status

            return data;
        } catch (error) {
            // console.log('FSNFTPoolList', data)
        }

        return data;
    };


    useEffect(() => {

        async function querySBNFTPollList() {
            setLoadFSNFTed(true)
            try {
                let noConnect = {};
                let networkID = 1
                if (!active) {
                    const web3 = await new Web3(Web3.givenProvider);
                    noConnect.provider = web3.eth.currentProvider
                    networkID = await web3.eth.getChainId();
                }
                const curLibrary = active ? library : noConnect
                //const botContract = getContract(active? library: noConnect, BounceFSNFT20.abi, getBotAddress(active? chainId: networkID))
                const bounceContract = getContract(curLibrary, BounceFSNFT.abi, getBounceAlpacasFSNFTAddress(active ? chainId : networkID))
                const poolCount = await bounceContract.methods.getPoolCount().call();
                console.log('SB NFT poolCount', poolCount)
                let poolIdList = [];

                for (let i = 0; i < poolCount; i++) {
                    poolIdList[i] = poolCount - i - 1;
                }


                let i = 0;
                let poolList;
                let queryIdList;
                let allPools = [];
                console.log('useMyFSNFTList', queryIdList)
                while (poolIdList.length !== 0) {
                    queryIdList = poolIdList.slice(0, 100);
                    poolIdList.splice(0, 100);
                    poolList = await Promise.all(queryIdList.map(async (item) => {
                        const pool = await queryPoolItem(curLibrary, bounceContract, item);

                        if (pool.fromAddress) {
                            pool.address = await getContractLink(pool.fromAddress)
                        }
                        pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
                        return pool
                    }));
                    poolList = poolList.filter(item => {
                        return item[0] === account
                        // return item[0] === '0xa276d6527Eb7eB55E9dE778c4776e8752DC7bd03'
                    })

                    allPools = allPools.concat(poolList)
                    console.log('allPools___', allPools)
                    i++
                }
                setFSNFTPoolList(allPools);
            } catch (e) {
                console.log('query SB nft pool list error', e)
            }

        }
        if (!loadFSNFTed && active) {
            querySBNFTPollList()
        }
    }, [loadFSNFTed, library])
    return { loadFSNFTed, FSNFTPoolList, setLoadFSNFTed }
}

export const useYGiftFSNFTList = () => {
    const [loading, setLoading] = useState(true)
    const [FSNFTPoolList, setFSNFTPoolList] = useState()
    const [loadFSNFTed, setLoadFSNFTed] = useState(true)

    const { active, library, chainId } = useActiveWeb3React();

    const queryPoolItem = async (library, bounceContract, index) => {
        let data = await queryData(bounceContract, 'pools', index);
        data = JSON.parse(JSON.stringify(data));
        data.index = index;
        englishAuctionKeyMap.forEach(item => {
            data[item.name] = data[item.key]
        })
        try {
            const tokenContract = getContract(library, yGift.abi, data.fromAddress)
            const gift = await tokenContract.methods.gifts(data.tokenId).call()
            data.cover = gift.url
            console.log('nft cover:', gift)
        } catch (e) {

        }

        data.type = 'fixedswapnft';
        let status = 'Closed';
        const isExpired = new Date(data.closeAt * 1000) <= Date.now();
        data.password = await queryData(bounceContract, 'passwordP', index);

        let networkID = 1
        if (!active) {
            const web3 = await new Web3(Web3.givenProvider);
            // noConnect.provider = web3.eth.currentProvider
            networkID = await web3.eth.getChainId();
        }

        const contract = getContract(library, BounceNFT.abi, getBounceYGiftFSAddress(active ? chainId : networkID))
        try {
            const swappedP_res = await contract.methods.swappedP(index).call()
            if (swappedP_res === true) {
                status = 'Filled';
            } else {
                if (!isExpired) {
                    status = 'Live';
                } else {
                    status = 'Closed';
                }
            }
            data.status = status

            return data;
        } catch (error) {
            // console.log('FSNFTPoolList', data)
        }

        return data;
    };


    useEffect(() => {

        async function querySBNFTPollList() {
            setLoadFSNFTed(true)
            try {
                let noConnect = {};
                let networkID = 1
                if (!active) {
                    const web3 = await new Web3(Web3.givenProvider);
                    noConnect.provider = web3.eth.currentProvider
                    networkID = await web3.eth.getChainId();
                }
                const curLibrary = active ? library : noConnect
                //const botContract = getContract(active? library: noConnect, BounceFSNFT20.abi, getBotAddress(active? chainId: networkID))
                const bounceContract = getContract(curLibrary, BounceNFT.abi, getBounceYGiftFSAddress(active ? chainId : networkID))
                const poolCount = await bounceContract.methods.getPoolCount().call();
                console.log('ygift fs pool count', poolCount)
                let poolIdList = [];

                for (let i = 0; i < poolCount; i++) {
                    poolIdList[i] = poolCount - i - 1;
                }


                let i = 0;
                let poolList;
                let queryIdList;
                let allPools = [];
                while (poolIdList.length !== 0) {
                    queryIdList = poolIdList.slice(0, 15);
                    poolIdList.splice(0, 15);
                    poolList = await Promise.all(queryIdList.map(async (item) => {

                        const pool = await queryPoolItem(curLibrary, bounceContract, item);

                        if (pool.fromAddress) {
                            pool.address = await getContractLink(pool.fromAddress)
                        }
                        pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
                        const amount = pool.amount;
                        // if (!pool.from || !pool.amount || !pool.fromAddress || amount == 0) {
                        //     poolIdList.unshift(item)
                        // }
                        return pool
                    }));

                    allPools = allPools.concat(poolList)
                    console.log('ygift pool list', allPools)
                    setLoadFSNFTed(false)
                    setFSNFTPoolList(allPools);
                    i++
                }
                // setLoading(false)

            } catch (e) {
                console.log('query SB nft pool list error', e)
            }

        }
        if (!loadFSNFTed) {
            querySBNFTPollList()
        }

    }, [loadFSNFTed, library])
    return { loadFSNFTed, FSNFTPoolList, setLoadFSNFTed }
}



