import { useEffect, useState } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import { getLotteryErc20Address } from "../../../web3/contractAddress";

import { queryPoolItem_pools, queryERC20Token } from './Request_CT'
import { getPoolStatus, weiToNumber } from './Request_FUN'

import LT_ERC20_ABI from '../../../web3/abi/BounceLotteryERC20.json'

export const useLT_List_ERC20 = (queryListArr = []) => {
    const { active, library, chainId } = useActiveWeb3React()
    const [poolsDate, setPoolsDate] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    // const noConnect = new Web3(new Web3.providers.HttpProvider("https://api.infura.io/v1/jsonrpc/mainnet")).currentProvider
    // console.log('A_console_noConnect', noConnect)
    useEffect(() => {
        if (!active || !queryListArr) return
        setIsLoad(true)
        console.log('A_console_queryListArr', queryListArr)
        queryPoolDate(queryListArr)
    }, [active, queryListArr, chainId])

    const queryPoolDate = async (queryListArr) => {


        const LT_ERC20_CT = getContract(library, LT_ERC20_ABI.abi, getLotteryErc20Address(chainId))

        const dataList = await Promise.all(queryListArr.map(async (poolID) => {
            try {
                const poolItemInfo = await queryPoolItem_pools(LT_ERC20_CT, poolID - 1)
                const poolItemInfo_2 = await LT_ERC20_CT.methods.getLotteryPoolInfo(poolID).call()

                // 通过获取到的池子信息筛选出列表需要的信息
                /**
                 * 1. 状态          √
                 * 2. poolID        √
                 * 3. 池子名称      √
                 * 4. 合约地址      √   
                 * 5. 交易对        √
                 * 6. 价格          - ETH的当前价格 * 参数
                 * 7. Swap Ratio    - 
                 * 8. 池子类型      √
                 * 9. 剩余进度  
                 * *、创建者       √
                 */
                const poolItemData = {
                    poolID: poolItemInfo.poolID,
                    status: getPoolStatus(poolItemInfo.closeAt),
                    progress: '100%',
                    poolName: poolItemInfo.name,
                    fromToken: await queryERC20Token(library, poolItemInfo.token0, chainId),
                    toToken: await queryERC20Token(library, poolItemInfo.token1, chainId),
                    Participant: 'Public',
                    creator: poolItemInfo.creator
                }
                poolItemData.winnerNum = poolItemInfo_2.nShare
                poolItemData.ticket = weiToNumber(poolItemInfo.amount1)

                return poolItemData
            } catch (error) {
                // console.log('C_console', error)
                // console.log('C_console', poolID)
                return false
            }
        }))

        const dataList_2 = dataList.filter(item => {
            return item
        })
        // console.log('C_console', dataList_2)

        setPoolsDate(dataList_2)
        setIsLoad(false)
    }

    return { poolsDate, isLoad }
}
