import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { Address, LayoutFrame, Pool } from "../../components/common/Layout";
import icon_return from "../../static/image/icon_return_light.svg";
import icon_search from "../../static/image/icon_search_light.svg";
import { Select, Select1, Select3, SelectFrame } from "../../components/common/Select";
import { useIsXSDown } from '../../components/utils/themeHooks';
import { HANDLE_SHOW_CONNECT_MODAL } from "../../const";
import { Button } from "../../components/common/Button";
import { useActiveWeb3React } from "../../web3";
import { myContext } from "../../reducer";
import { PoolTypeModal } from "../../components/common/PoolTypeMpdal";

const AccountHeader = styled.div`
  background-color: #121212;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const AuctionFrame = styled.div`
  width: 1280px;
  display: flex;
  height: 91px;
  align-items: center;
  margin: auto;
  justify-content: left;
  @media (max-width: 767px) {
    width: 100%;
    overflow-x:scroll;
  }
`

const Auction = styled(Link)`
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 25px;
  line-height: 25px;
  padding: 2px 9px;
  margin-right: 32px;
  background-color: ${({ active, theme }) => (active ? theme.white : '')};
  color: ${({ active, theme }) => (active ? theme.black : theme.white)};
  cursor: pointer;

  &:hover{
    opacity: ${({ active }) => (active ? 1 : 0.7)};
  };
  @media (max-width: 767px) {
    white-space:nowrap;
  }
`
export const FilterFrame = styled.div`
  width: 1280px;
  height: 84px;
  border-bottom: 2px solid rgba(255,255,255,0.6);
  border-top: 2px solid rgba(255,255,255,0.6);
  font-family: Optima;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  display: flex;
  align-items: center;
  .search_title{
      cursor: pointer;
      &:hover{
          opacity: .7;
      }
  }
  @media (max-width: 767px) {
    width: 100%;
  } 
`
export const SearchInput = styled.input`
  width: 18px;
  margin: 24px;
  flex: 1;
  margin-left: 10px;
  border: none;
  color: #FFF;
  &::placeholder{
    font-family: Optima;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255,255,255, 0.4);
  }
`
export const SearchIcon = styled.img`
  width: 18px;
  margin: 24px;
`

const CreateButton = styled(Button)`
  background-color: #121212;
  color: #FFF;
  border: 1px solid #FFFFFF;
box-sizing: border-box;
  @media (max-width: 767px) {
    display:none;
  }
`

export const Website = styled.a`
height: fit-content;
font-family: IBM Plex Mono;
font-style: normal;
font-weight: normal;
width: fit-content;
font-size: 14px;
line-height: 18px;
margin: auto;
color: ${({ theme }) => (theme.grey4)};
text-align: center;
cursor: pointer;
margin-top: 16px;
/* margin-bottom: 40px; */
&:hover{
    color: ${({ theme }) => (theme.black)};
};
@media (max-width: 767px) {
  margin-top: 0px;
}

`

const poolTypes = [{ key: 0, value: 'Fixed-swap Pool' }, { key: 1, value: 'NFT English Auction' }]


export const DetailHeader = ({ title, website, onSearchChange, onQueryStatusChange }) => {

  const path = window.location.pathname

  const history = useHistory()

  const createOptions = [{ key: 101, value: 'NFT Fixed Swap' }, { key: 100, value: 'NFT English Auction' }]

  const { active, library } = useActiveWeb3React()
  const { state, dispatch } = useContext(myContext);
  const [auction, setAuction] = useState()
  const [showSelectModal, setShowSelectModal] = useState(false)
  const isXSDown = useIsXSDown();
  const [poolType, setPoolType] = useState('EA')

  useEffect(() => {
    const auc_0 = ['/store/detail/nft-english-auction', '/store/detail/nft-fixed-swap']
    const auc_1 = ['/store/detail/account/nft-english-auction', '/store/detail/account/nft-fixed-swap']

    if (auc_0.includes(path)) {
      setAuction(0)
    } else if (auc_1.includes(path)) {
      setAuction(1)
    }
  }, [path])


  const renderSelect = () => {
    return <Select
      white
      width={'220px'}
      extra={'Pool Type:'}
      onSelect={item => {
        if (auction === 0) {
          switch (item.key) {
            case 0:
              setPoolType('FS')
              history.push(`/store/detail/nft-fixed-swap`);
              break
            case 1:
              setPoolType('EA')
              history.push(`/store/detail/nft-english-auction`);
              break
            default:
          }
        } else if (auction === 1) {
          switch (item.key) {
            case 0:
              setPoolType('FS')
              history.push(`/store/detail/account/nft-fixed-swap`);
              break
            case 1:
              setPoolType('EA')
              history.push(`/store/detail/account/nft-english-auction`);
              break
            default:
          }
        }
      }}
      defaultContent={'types of auctions'}
      defaultValue={path === '/store/detail/nft-fixed-swap' || path === '/store/detail/account/nft-fixed-swap' ? poolTypes[0] : poolTypes[1]}
      options={poolTypes} />
  }

  return (
    <AccountHeader>

      <LayoutFrame width={'1181px'}
        style={{ padding: '40px 0', margin: 'auto', marginTop: 32, backgroundColor: '#121212' }}>
        <Pool.Return src={icon_return} onClick={() => {
          history.push('/store')
        }} />
        <Pool.Mode style={{ color: '#FFF' }}>Store</Pool.Mode>
        <Pool.Header
          style={{ justifyContent: 'center', color: '#FFF' }}><span>{title}</span></Pool.Header>
        <Website style={{
          wordBreak: isXSDown ? 'break-all' : 'normal',
          marginTop: 16,
          color: '#FFF'
        }}>{website}</Website>
      </LayoutFrame>

      <AuctionFrame>
        <Auction onClick={() => {
          setAuction(0)
        }} active={auction === 0}
          to={poolType === 'EA' ? '/store/detail/nft-english-auction' : '/store/detail/nft-fixed-swap'}
        >Auction Alpacas</Auction>
        {/*<Auction onClick={() => {*/}
        {/*  setAuction(1)*/}
        {/*}} active={path === '/account/sealed-bid' || auction === 1}>My Alpacas Collection</Auction>*/}
        {/* <Auction onClick={() => { setAuction(2) }} active={path === '/store/mynft' || auction === 2} to='/store/mynft'>View My NFT</Auction> */}
        <Auction onClick={() => {
          setAuction(1)
        }}
          active={auction === 1}
          to={poolType === 'EA' ? '/store/detail/account/nft-english-auction' : '/store/detail/account/nft-fixed-swap'}
        >My Account</Auction>
        <Auction onClick={() => {
          window.open('https://alpaca.city/alpacas', '_blank')
        }}>View My NFT</Auction>
        <CreateButton
          width='160px'
          onClick={() => {
            if (active) {
              setShowSelectModal(true)
            } else {
              dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
            }
          }} style={{ marginLeft: 'auto', marginRight: 0 }} width={'160px'}>Create
            auction</CreateButton>
      </AuctionFrame>
      {
        (auction === 0 || auction === 1) &&
        <FilterFrame>
          <div className='search_title' style={{ display: "flex", alignItems: "center", color: '#fff' }}>
            <SearchIcon src={icon_search} />
            Search by
          </div>
          <SearchInput onChange={(e) => {
            onSearchChange(e.target.value)
          }} placeholder={'Pool ID, Pool Name'} />
          <SelectFrame.Divider />
          {renderSelect()}
          <SelectFrame.Divider style={{ backgroundColor: 'rgba(255,255,255,0.4)' }} />
          <Select
            white
            theme={'white'}
            width={'148px'}
            onSelect={item => {
              onQueryStatusChange(item.value)
            }}
            extra={'Status:'}
            defaultContent={'types of auctions'}
            defaultValue={{ key: 0, value: 'All' }}
            options={[
              { key: 0, value: 'All' },
              { key: 1, value: 'Live' },
              { key: 2, value: 'Filled' },
              { key: 3, value: 'Closed' },
            ]} />
          <SelectFrame.Divider style={{ backgroundColor: 'rgba(255,255,255,0.4)', marginRight: 0 }} />
        </FilterFrame>
      }

      <PoolTypeModal options={createOptions} onConfirm={(poolType) => {
        if (!poolType) {
          return
        }
        switch (poolType.key) {
          case 100:
            history.push(`/store/crate/english-auction-pool`);
            break
          case 101:
            history.push(`/store/crate/fixed-swap-nft-pool`);
            break
          default:
        }

      }} show={showSelectModal} onDismiss={() => {
        setShowSelectModal(false)
      }} />
    </AccountHeader>
  )
}


