import React, { useState } from "react";
import styled from "styled-components";
import { StakingFrame } from "../../components/Staking";
import { OText1, OText3, OText4, OText5 } from "../../components/common/Layout";
import { Button } from "../../components/common/Button";
import { weiToNumber } from "../../utils/numberTransform";
import { useIsXSDown } from '../../components/utils/themeHooks';
import { useQueryMining } from "./Hooks";
import BigNumber from "bignumber.js";

const TextFrame = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  justify-content: space-between;
`

const Progress = styled.div`
  width: 280px;
  height: 8px;
  background-color: rgba(0,0,0,.1);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
`

Progress.Value = styled.div`
 height: 8px;
 border-radius: 5px;
 background-color: #000;
`

export const Mining = ({ onClaim }) => {
    const isXSDown = useIsXSDown();
    const { rewards, left, progress } = useQueryMining()

    return (
        <>
            {isXSDown &&
                <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: 'row' }}>
                    <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                        <StakingFrame padding={'44px 44px 20px'} height={'144px'} style={{ flexDirection: 'row' }}>
                            <TextFrame>
                                <OText5>Total Transaction Number</OText5>
                                <OText4>10 353 678</OText4>
                            </TextFrame>
                        </StakingFrame>

                        <StakingFrame padding={'44px'} height={'300px'} style={{ flexDirection: 'column' }}>
                            <TextFrame style={{ width: '100%', marginBottom: '30px' }}>
                                <OText5>Time Left</OText5>
                                <OText3>{left && `${left}`}</OText3>
                            </TextFrame>

                            <TextFrame style={{ flex: 2 }}>
                                <OText5>Your Liquidity Provision Rewards</OText5>
                                <OText3>{rewards && `${weiToNumber(rewards)} `}<OText5>BOT</OText5></OText3>
                                <Button style={{ marginTop: '30px' }} onClick={onClaim}>Claim Rewards</Button>
                            </TextFrame>
                        </StakingFrame>
                    </StakingFrame>
                </StakingFrame>
            }
            {!isXSDown &&
                <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: 'row' }}>
                    <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                        <StakingFrame padding={'44px 40px'} height={'144px'} style={{ flexDirection: 'row' }}>
                            <TextFrame>
                                <OText5>Total Transaction Number</OText5>
                                <OText3 style={{ marginTop: 16 }}>10 353 678</OText3>
                            </TextFrame>
                        </StakingFrame>

                        <StakingFrame height={'248px'}>
                            <TextFrame style={{ padding: '44px 40px', width: '28%' }}>
                                <OText5>Time Left</OText5>
                                <OText3 style={{ fontSize: 36 }}>{left && `${left}`}</OText3>
                                <Progress>
                                    <Progress.Value style={{ width: progress + '%' }} />
                                </Progress>
                            </TextFrame>

                            <TextFrame style={{ padding: '44px 40px', borderLeft: '2px solid #F5F5F5', width: '72%' }}>
                                <OText5>Your Liquidity Provision Rewards</OText5>
                                <OText3 style={{ fontSize: 40 }}>{rewards && `${weiToNumber(rewards)} `}<OText5>BOT</OText5></OText3>
                                <Button style={{ fontSize: 14 }} onClick={onClaim}>Claim Rewards</Button>
                            </TextFrame>
                        </StakingFrame>
                    </StakingFrame>
                </StakingFrame>
            }

        </>
    )
}