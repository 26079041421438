import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Address, LayoutFrame, LineDivider, OText3, Pool, renderTime } from '../../components/common/Layout';
import icon_return from '../../static/image/icon-return.svg'
import { usePoolDetail } from './Hooks';
import classNames from 'classnames';
import { BigNumber } from 'bignumber.js'
import {
  BidModal,
  initStatus,
  errorStatus,
  successStatus,
  confirmStatus,
  pendingStatus,
  cancelStatus
} from '../../components/common/BidModal';
import { useLeftTime } from '../../hooks/useLeftTime';
import { weiToNumber } from '../../utils/numberTransform';
import { Button } from "../../components/common/Button";
import { getLotteryErc20Address } from '../../web3/contractAddress';
import { getContract, useActivePlatform, useActiveWeb3React } from '../../web3';
import LotteryERC20ABI from "../../web3/abi/BounceLotteryERC20.json";
import { useIsXSDown } from '../../components/utils/themeHooks';
import { Message } from "../../components/common/message";
import { isEqualTo, isGreaterThan } from "../../utils/common";
import { BIDDER_CLAIMED_MESSAGE, CREATOR_CLAIMED_MESSAGE } from "../../const";

BigNumber.config({ EXPONENTIAL_AT: [-30, 30] })

export const LotteryERC20Detail = ({ token2 }) => {
  const history = useHistory();
  const { id } = useParams();

  const { account, library, chainId } = useActiveWeb3React();

  const { name, address, isLive, time, price, winner, toDecimals, playStatus, isMine, claimed, isWinner, isJoined, pool, curPlayer, symbol } = usePoolDetail(parseInt(id) + 1);
  const [bidStatus, setBidStatus] = useState(initStatus);
  const [disable, setDisable] = useState(false);
  const [status, setStatus] = useState();
  const [display, setDisplay] = useState('');
  const { setTime, leftTime } = useLeftTime();
  const isXSDown = useIsXSDown();
  const { Psymbol } = useActivePlatform()

  let timer = null;
  useEffect(() => {
    if (isLive) {
      setStatus('Live');
      if (isMine) {
        setDisplay('Lottery is live');
        setDisable(true);
      } else {
        if (isJoined) {
          setDisplay('You Joined');
          setDisable(true);
        } else {
          setDisplay('Join The Lottery');
          setDisable(false);
        }
      }

    } else {
      if (isMine) {
        setDisplay('My Pool');
        setDisable(true);
      } else {
        if (isWinner) {
          setDisplay('You are a Winner');
        } else {
          setDisplay('Lottery Completed');
        }
      }
      setStatus('Closed');
    }
    timer = setInterval(() => {

      const date = new Date(time * 1000);
      const now = new Date();
      const lefttime = date - now;
      if (lefttime > 1000) {
        setTime(lefttime)
      } else if (0 < lefttime && lefttime < 1000) {
        window.location.reload()
      } else {
        clearInterval(timer)
      }
    }, (1000));
    return () => {
      clearInterval(timer)
    }
  }, [setTime, time, isMine, isJoined, isWinner]);

  const renderButtonText = () => {
    let text = '';
    if (isLive) {
      if (isMine) {
        text = ''
      } else {
        text = 'GO';
        if (isJoined) {
          text = 'You are in the draw...';
        }
      }
    } else {
      if (isWinner && !claimed) {
        text = 'Claim your tokens';
      } else if (!isWinner && !claimed && isJoined) {
        text = 'Claim your tokens Back';
      }
    }
    return text;
  }

  const handleClaim = async () => {
    const contract = getContract(library, LotteryERC20ABI.abi, getLotteryErc20Address(chainId));
    let method;
    if (playStatus === '1') {
      method = 'creatorClaim';
    } else if (playStatus === '2') {
      method = 'playerClaim';
    }
    setBidStatus(confirmStatus);
    try {
      contract.methods[method](parseInt(id) + 1)
        .send({ from: account })
        .on('transactionHash', hash => {
          setBidStatus(pendingStatus)
        })
        .on('receipt', (_, receipt) => {
          setBidStatus(successStatus)
        })
        .on('error', (err, receipt) => {
          setBidStatus(errorStatus)
        })
    } catch (e) {
      console.log('lottery erc20 claim error', e)
      if (e.code === 4001) {
        setBidStatus(cancelStatus)
      } else {
        setBidStatus(errorStatus)
      }
    }
  }

  const handleJoin = () => {
    const contract = getContract(library, LotteryERC20ABI.abi, getLotteryErc20Address(chainId));
    setBidStatus(confirmStatus);
    try {
      console.log('contract', contract)
      contract.methods.bet(parseInt(id) + 1)
        .send({ from: account, value: price })
        .on('transactionHash', hash => {
          setBidStatus(pendingStatus)
        })
        .on('receipt', (_, receipt) => {
          setBidStatus(successStatus)
        })
        .on('error', (err, receipt) => {
          setBidStatus(errorStatus)
        })
    } catch (e) {
      console.log('join error', e);
      if (e.code === 4001) {
        setBidStatus(cancelStatus)
      } else {
        setBidStatus(errorStatus)
      }
    }
  }

  const handleClick = () => {
    if (isLive) {
      if (!isJoined) {
        handleJoin();
      }
    } else {
      if (!claimed) {
        handleClaim();
      }
    }
  }

  return (
    <LayoutFrame style={{
      marginTop: 27,
      paddingBottom: 56,
      paddingLeft: isXSDown ? '20px' : '0',
      paddingRight: isXSDown ? '20px' : '0'
    }}>

      {isMine ?
        <>
          {status === 'Live' && (
            <Message type={'success'}
              content={`The lottery is still live, please wait patiently until your lottery is closed. The current number of people in the draw is ${pool.curPlayer}`} />
          )}
          {status === 'Closed' && isEqualTo(pool.curPlayer, '0') && !claimed && (
            <Message type={'error'}
              content={'The lottery is closed. Unfortunately, no one participated. Please claim back your principle tokens.'} />
          )}
          {status === 'Closed' && isGreaterThan(pool.curPlayer, '0') && !claimed && (
            <Message type={'success'}
              content={'The lottery is closed. Please claim your swapped tokens.'} />
          )}
          {status === 'Close' && claimed && (
            <Message type={'success'}
              content={CREATOR_CLAIMED_MESSAGE} />
          )}
        </>
        : null}

      {!isMine ?
        <>
          {status === 'Live' && isJoined && (
            <Message type={'success'}
              content={'You are in the draw. Please come back to check result when the lottery is closed.'} />
          )}
          {status === 'Closed' && isWinner && !claimed && (
            <Message type={'success'}
              content={'Congratulations! You win the lottery. Please claim your lottery rewards.'} />
          )}
          {status === 'Closed' && !isWinner && !claimed && (
            <Message type={'error'}
              content={'Unfortunately, you are not selected as a winner. Please claim your principle back.'} />
          )}

          {status === 'Closed' && isJoined && claimed && (
            <Message
              content={BIDDER_CLAIMED_MESSAGE} />
          )}

          {status === 'Closed' && !isJoined && claimed && (
            <Message
              content={'This auction is finished, please visit other live auctions.'} />
          )}
        </>
        : null}

      <Pool.Return onClick={() => {
        history.goBack()
      }} src={icon_return} />
      <LayoutFrame width={'1072px'} style={{ padding: '40px 0', margin: 'auto', marginTop: 32 }}>
        <Pool.Mode>Lotteries</Pool.Mode>
        <Pool.Header><span>{name}</span></Pool.Header>
        <Address style={{ wordBreak: isXSDown ? 'break-all' : 'normal', marginBottom: 16 }}>{address}</Address>
        <Pool.Content width={isXSDown ? '100%' : '432px'}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.04)', flexDirection: 'column',
            padding: isXSDown ? '48px 20px' : '48px 56px', position: 'relative', margin: '0 auto',
            alignItems: 'center', justifyContent: 'flex-start'
          }}
        >
          <Pool.Status style={{
            position: 'absolute', top: '16px', left: '20px',
            backgroundColor: '#D3D3DA', width: '82px', height: '26px', lineHeight: '26px'
          }}
            className={classNames('status', status)}>• {status}</Pool.Status>
          <OText3 style={{ textAlign: 'center', margin: '0 auto' }}>{display}</OText3>
          {renderTime(leftTime)}
          <LineDivider style={{ width: '100%' }} />
          <Pool.Meta style={{ width: '100%', marginTop: '16px' }}>
            <div>Token:</div>
            <div>{symbol ? symbol : ''}</div>
          </Pool.Meta>
          <Pool.Meta style={{ width: '100%', marginTop: '16px' }}>
            <div>Ticket Size:</div>
            <div>1 Ticket
                = {(pool && winner) ? weiToNumber((new BigNumber(pool.amount0).dividedBy(winner)).toFixed(0).toString()) : ''} {symbol ? symbol : ''}</div>
          </Pool.Meta>
          <Pool.Meta style={{ width: '100%', marginTop: '16px' }}>
            <div>Ticket Price:</div>
            <div>{`${pool && weiToNumber(price, toDecimals)}`} {Psymbol}</div>
          </Pool.Meta>
          <Pool.Meta style={{ width: '100%', marginTop: '16px' }}>
            <div>Number of Winner:</div>
            <div>{winner}</div>
          </Pool.Meta>
          <Pool.Meta style={{ width: '100%', marginTop: '16px' }}>
            <div>Participants:</div>
            <div>{pool && pool.curPlayer} / {pool && pool.maxPlayer}</div>
          </Pool.Meta>
          {renderButtonText() !== '' &&
            <Button black style={{ width: '100%', marginTop: '30px' }} onClick={handleClick}>
              {renderButtonText()}
            </Button>}

        </Pool.Content>
      </LayoutFrame>

      <BidModal modalStatus={bidStatus} onDismiss={() => {
        setBidStatus(initStatus);
      }} />
    </LayoutFrame>
  )
}
