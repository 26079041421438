import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const PageStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    margin-top: 50px;
    font-family: 'IBM Plex Mono';
    font-size: 12px;
    font-weight: 700;
    color: '#1F191B';
    user-select: none;
    @media (max-width: 767px) {
        justify-content: center;
    }
    

    .left,.right,.prev,.next{
        display: flex;
    }
    .left{
        @media (max-width: 767px) {
            display:none;
        }
    }
    .left span{
        cursor: pointer;

        &:hover{
            opacity: .6;
        }

        &.disable{
            opacity: .4;
        }
    }
    

    .prev{
        margin-right: 55px;
        img{
            margin-right: 16px;
        }
        @media (max-width: 767px) {
            display:none;
        }
    }

    .next{
        img{
            margin-left: 16px;
        }
        @media (max-width: 767px) {
            display:none;
        }
    }

    .right{
        height: 100%;
        li{
            height: 100%;
            line-height: 38px;
            padding: 0 18px;
            cursor: pointer;

            &.active{
                background-color: #000;
                color: #fff;
            }
        }
        @media (max-width: 767px) {
            width:90%;
        }
    }
`



export default function Pagination({ pagenum, setPagenum, total, setLoading }) {

    const [pages, setPages] = useState([1])

    useEffect(() => {
        let pageArr = []
        if (total <= 6) {
            for (let i = 1; i <= total; i++) {
                pageArr.push(i)
            }
        } else {
            if (pagenum < 5) {
                pageArr = [1, 2, 3, 4, '...', total]
            } else if (pagenum > total - 5 && total > 5) {
                pageArr = [1, '...', total - 3, total - 2, total - 1, total]
            } else {
                pageArr = [1, '...', pagenum - 2, pagenum - 1, pagenum, pagenum + 1, '...', total]
            }
        }

        setPages(pageArr)
    }, [total, pagenum])

    const handelClickPrev = () => {
        if (pagenum <= 1) {
            return
        }

        setPagenum(pagenum - 1)
    }

    const handelClickNext = () => {
        if (pagenum >= total) {
            return
        }

        setPagenum(pagenum + 1)
    }

    const handelClickPageIndex = (item) => {
        if (typeof (item) !== 'number') {
            return
        }

        setPagenum(item)
    }



    return (
        <PageStyled>
            <div className='left'>
                <div className='prev'>
                    <img src={require('../static/image/page-prev.svg')} alt="" />
                    <span onClick={() => { handelClickPrev() }} className={pagenum === 1 ? 'disable' : ''}>Prev</span>
                </div>
                <div className='next'>
                    <span onClick={() => { handelClickNext() }} className={pagenum === total ? 'disable' : ''}> Next</span>
                    <img src={require('../static/image/page-next.svg')} alt="" />
                </div>

            </div>

            <ul className='right'>
                {
                    pages.map((item, index) => {
                        return <li key={item + index}
                            className={item === pagenum ? 'active' : ''}
                            onClick={() => {
                                handelClickPageIndex(item)
                            }}
                        >{item}</li>
                    })
                }
            </ul>
        </PageStyled>
    )
}
