import React from 'react';
import { t } from '../../utils/intl';
import denied from '../../static/image/denied.svg';
import './content-box.scss';

export const DeniedContent = ({
  onClose,
}) => {
  return (
    <div className='content-box denied-content'>
      <img src={denied} alt='denied' />
      <p>{t('denied.title')}</p>
      <span className='button' onClick={onClose}>{t('buttons.close')}</span>
    </div>
  )
}