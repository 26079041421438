import React from 'react';
import { t } from '../../utils/intl';
import sucIcon from '../../static/image/success.svg';

export const RewardSuccess = ({
  onClose,
  link,
}) => {
  return (
    <div className='content-box'>
      <img src={sucIcon} alt='success' />
      <p>{t('reward.success.title')}</p>
      <a className='link' href={link} target='_blank' rel="noopener noreferrer">{t('view-on-etherscan')}</a>
      <span className='button' onClick={onClose}>{t('buttons.close')}</span>
    </div>
  )    
}