import React, {useState, useCallback, useEffect} from 'react';
import {t, tHTML} from '../../utils/intl';
import {weiToNumber} from '../../utils/numberTransform';
import {
  queryNFTBiddenPrice, queryEACurBiddenAddress, queryEACurPrice, queryEACurRound
} from '../const'
import rectLogo from '../../static/image/rect-logo.svg'


export const EnglishAuctionPoolItem = ({
                                         symbol,
                                         pool,
                                         onEnd,
                                         closed,
                                         onRoundChange,
                                         web3
                                       }) => {
  const [left, setLeft] = useState(null);
  const [leftReducer, setLeftReducer] = useState(null);
  const [curTime, setCurTime] = useState();
  const [curPrice, setCurPrice] = useState();
  const [coverHover, setCoverHover] = useState(false);
  const [curRound, setCurRound] = useState(0);




  let timer;
  let reducerTimer;


  const renderTime = useCallback(() => {
    console.log('curTime',curTime)
    if (pool.time) {
      const time = pool.time * 1000;
      const date = new Date(time);
      const now = new Date();
      const lefttime = date - now;
      let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
      let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
      let leftm = Math.floor(lefttime / (1000 * 60) % 60);
      let lefts = Math.floor(lefttime / 1000 % 60);
      const left = {
        days: leftd < 0 ? 0 : leftd,
        hours: lefth < 0 ? 0 : lefth,
        minutes: leftm < 0 ? 0 : leftm,
        seconds: lefts < 0 ? 0 : lefts,
      };
      setLeft(left);
      if (left < 0) {
        onRoundChange();
        setLeft(null);
      }
    } else {
      setLeft(null);
    }
  }, [pool, setLeft, left]);

  const renderLeft = useCallback(() => {
    if (left) {
      return <>
        {left.hours}
        {` H : `}
        {left.minutes}
        {` min : `}
        {left.seconds}
        {` sec `}
      </>
    } else {
      return '';
    }
  }, [left]);


  useEffect(() => {
    timer = setInterval(() => {
      renderTime();
    }, (1000));
    return () => {
      clearInterval(timer)
    }
  }, [renderTime]);
  //
  // useEffect(() => {
  //   if (web3 && pool.index && !closed) {
  //     reducerTimer = setInterval(() => {
  //       const time = pool.curTime - 1;
  //       if (time === 0) {
  //         onRoundChange()
  //         setCurTime(pool.curTime - 1);
  //         queryEACurBiddenAddress(web3, pool.index)
  //             .then(address => {
  //               console.log('pool item bidden address', address)
  //               if (address == 0) {
  //                 setCurPrice(pool.startingPrice)
  //               } else {
  //                 queryEACurPrice(web3, pool.index)
  //                     .then(r => {
  //                       console.log('queryNFTBiddenPrice', r)
  //                       setCurPrice(r)
  //                     });
  //               }
  //             })
  //
  //
  //         clearInterval(reducerTimer)
  //       } else {
  //         setCurTime(pool.curTime - 1)
  //       }
  //
  //     }, (1000));
  //   }
  //
  //   return () => {
  //     clearInterval(reducerTimer)
  //   }
  // }, [renderNextTime, curPrice, pool, closed]);


  useEffect(() => {
    if (web3 && pool.index) {
      queryEACurBiddenAddress(web3, pool.index)
          .then(address => {
            console.log('pool item bidden address', address)
            if (address == 0) {
              setCurPrice(pool.startingPrice)
            } else {
              queryEACurPrice(web3, pool.index)
                  .then(r => {
                    console.log('queryNFTBiddenPrice', r)
                    setCurPrice(r)
                  });
            }
          })

      queryEACurRound(web3, pool.index)
          .then(round => {
            setCurRound(round)
          })

    }
  }, [pool]);

  return (
      <div className='nft-item'>
        <div className='cover-box'
             onMouseLeave={() => {
               if (pool.cover) {
                 setCoverHover(false)
               }
             }}
             onMouseEnter={() => {
               if (pool.cover) {
                 setCoverHover(true)
               }
             }}>
          <img className='nft-cover' src={pool.cover ? pool.cover : rectLogo}/>
          <a target='_Blank' href={pool.cover} style={{display: coverHover ? 'flex' : 'none'}}
             className='cover-mask'>{'Click to see the original content of the NFT'}</a>
        </div>
        <div className='nft-content'>
          <dl className='define'>
            <dt>{t('pool-item.pool-type')}</dt>
            <dd>{'NFT english auction'}</dd>
          </dl>

          {/*<dl className='define'>*/}
          {/*  <dt>{t('pool-item.participant')}</dt>*/}
          {/*  <dt style={{display: 'flex'}}>*/}
          {/*    <dd><span*/}
          {/*        className='weight-grey'>{pool.password && pool.password != 0 ? 'Private' : pool.onlyBot ? 'BOT holder' : "Public"}</span>*/}
          {/*    </dd>*/}
          {/*  </dt>*/}
          {/*</dl>*/}


          <dl className='define'>
            <dt>{'Starting price:'}</dt>
              <dd>
                <span>{`1 ${pool && pool.fromSymbol} = ${pool && web3.utils.fromWei(pool.startingPrice)} ETH`}</span>
              </dd>
          </dl>

          <dl className='define'>
            <dt>{'Minimal Bid price increment:'}</dt>
            <dd>
              <span>{`1 ${pool && pool.fromSymbol} = ${pool && web3.utils.fromWei(pool.incr)} ETH`}</span>
            </dd>
          </dl>

          <dl className='define'>
            <dt>{'The current round:'}</dt>
            <dd>
              <span>{`round ${curRound}`}</span>
            </dd>
          </dl>

          <dl className='define'>
            <dt>{'Current highest bid and time left\n'}</dt>
            <dt style={{display: 'flex', alignItems: 'center'}}>
              <span>{curPrice && `${weiToNumber(curPrice)} ETH`}</span>
              <dd style={{marginLeft: 20}}
              ><span className='weight-grey' style={{flexDirection: 'row', padding: 0, background: '#fff'}}>
              {renderLeft()}
              </span>
              </dd>
            </dt>
          </dl>
        </div>
      </div>
  )
}
