import { getContract, useActiveWeb3React } from "../../web3";
import { useContext, useEffect, useState } from "react";
import { myContext } from "../../reducer";
import dutchAuctionNFT from "../../web3/abi/bounceDutchAuctionNFT.json";
import { getDutchAuction721, getDutchAuction1155 } from "../../web3/contractAddress";
import bounceERC721 from "../../web3/abi/bounceERC721.json";
import bounceERC1155 from "../../web3/abi/bounceERC1155.json";
import { HANDLE_SHOW_CONNECT_MODAL } from "../../const";
import { isEqualTo, isGreaterThan } from "../../utils/common";

const BigNumber = require('bignumber.js');


export const usePoolDetail = (id = 0) => {
    const { active, account, library, chainId } = useActiveWeb3React();
    const { state, dispatch } = useContext(myContext);
    const [name, setName] = useState(null)
    const [symbol, setSymbol] = useState(null)
    const [address, setAddress] = useState(null)
    const [startPrice, setStartPrice] = useState()
    const [reservePrice, setReservePrice] = useState()
    const [NFT, setNFT] = useState()


    const [limit, setLimit] = useState(null)
    const [isMine, setIsMine] = useState()
    const [password, setPassword] = useState()
    const [time, setTime] = useState()
    const [fromBidAmount, setFromBidAmount] = useState()
    const [toBidAmount, setToBidAmount] = useState()
    const [fromAmount, setFromAmount] = useState()
    const [claimButtonText, setClaimButtonText] = useState()
    const [isClaim, setIsClaim] = useState(false)
    const [myBid, setMyBid] = useState()
    const [floor, setFloor] = useState()
    const [bidden, setBidden] = useState()
    const [totalBid, setTotalBid] = useState()
    const [joined, setJoined] = useState()
    const [myBidden, setMyBidden] = useState()
    const [tokenId, setTokenId] = useState()
    const [winnerAmount, setWinnerAmount] = useState()

    const [amountMinIncr, setAmountMinIncr] = useState()

    const [curBiddenAmount, setCurBiddenAmount] = useState()


    const [round, setRound] = useState()
    const [currentPrice, setCurrentPrice] = useState()
    const [status, setStatus] = useState()
    const [dutchNFTType] = useState(window.localStorage.getItem('dutchNFTType'))

    async function queryPoolDetail () {
        try {
            const contractAddress = dutchNFTType === '1155' ? getDutchAuction1155(chainId) : getDutchAuction721(chainId)
            const contract = getContract(library, dutchAuctionNFT.abi, contractAddress)
            contract.methods.pools(id).call().then((res) => {
                console.log('ea nft detail:', res)
                setStartPrice(res.amountMax1)
                setReservePrice(res.amountMin1)
                // setAmountMinIncr(res.amountMinIncr1)
                setName(res.name)
                setTokenId(res.tokenId)
                setTime(res.closeAt)
                const nftAddress = res.token0
                setAddress(nftAddress)
                setLimit(res.times)

                const time = res.closeAt;
                //setTime(time)
                const date = new Date(time * 1000);
                const now = new Date();
                const leftTime = date - now;
                const status = leftTime > 0 ? 'Live' : 'Closed'
                setStatus(status);

                const mine = res.creator.toLowerCase() === account.toLowerCase();
                setIsMine(mine)

                contract.methods.passwordP(id).call().then((res) => {
                    console.log('query fs password:', res)
                    setPassword(res)
                })

                const nftType = dutchNFTType

                if (nftType === '721') {
                    console.log('nft type', nftType)
                    try {
                        const tokenContract = getContract(library, bounceERC721.abi, nftAddress)
                        tokenContract.methods.tokenURI(res.tokenId).call().then(uriResult => {
                            console.log('uriResult', uriResult.toString())
                            fetch(uriResult).then(response => {
                                response.json().then(nft => {
                                    try {
                                        setNFT({
                                            title: nft.title,
                                            name: nft.name ? nft.name : nft.properties.name.description,
                                            image: nft.image ? nft.image : nft.properties.image.description,
                                            description: nft.description ? nft.description : nft.properties.description.description
                                        })
                                    } catch (e) {

                                    }

                                })
                            })
                        })
                    } catch (e) {
                        console.log('nft detail')
                    }

                } else if (nftType === '1155') {
                    try {
                        const tokenContract = getContract(library, bounceERC1155.abi, nftAddress)
                        tokenContract.methods.uri(res.tokenId).call().then(uriResult => {
                            console.log('uriResult', uriResult)
                            fetch(uriResult, { method: "get" }).then(response => {
                                response.json().then(nft => {
                                    try {
                                        setNFT({
                                            title: nft.title,
                                            name: nft.name ? nft.name : nft.properties.name.description,
                                            image: nft.image ? nft.image : nft.properties.image.description,
                                            description: nft.description ? nft.description : nft.properties.description.description
                                        })
                                    } catch (e) {

                                    }

                                })
                            })
                        })
                    } catch (e) {
                        console.log('nft detail')
                    }

                }
            })
        } catch (e) {
            console.log('queryPoolDetail error', e)
        }
    }


    function queryRound () {
        const contractAddress = dutchNFTType === '1155' ? getDutchAuction1155(chainId) : getDutchAuction721(chainId)
        const contract = getContract(library, dutchAuctionNFT.abi, contractAddress)
        contract.methods.currentRound(id).call().then((res) => {
            console.log('query round', res)
            setRound(res)
        })

        contract.methods.currentPrice(id).call().then((res) => {
            console.log('query price', res)
            setCurrentPrice(res)
        })


    }


    function queryPoolStatus () {
        const contractAddress = dutchNFTType === '1155' ? getDutchAuction1155(chainId) : getDutchAuction721(chainId)
        const contract = getContract(library, dutchAuctionNFT.abi, contractAddress)
        contract.methods.currentBidderP(id).call().then((address) => {
            console.log('curren bidden address', address)
            if (address != 0) {
                setBidden(address)
                setStatus('Closed')

                contract.methods.myPriceP(address, id).call().then((amount) => {
                    console.log('curren bidden amount', amount)
                    setCurBiddenAmount(amount)
                })

            } else {
                setCurBiddenAmount('0')
                // contract.methods.currentRoundP(id).call().then((amount) => {
                //     console.log('curren bidden amount', amount)
                //     setCurBiddenAmount(amount)
                // })
            }
        })
    }

    function queryMyStatus () {
        const contractAddress = dutchNFTType === '1155' ? getDutchAuction1155(chainId) : getDutchAuction721(chainId)
        const contract = getContract(library, dutchAuctionNFT.abi, contractAddress)
        contract.methods.creatorClaimedP(id).call().then((claimed) => {
            console.log('my claimed', claimed)
            if (!claimed) {
                setIsClaim(false)
                contract.methods.currentBidderP(id).call().then((address) => {
                    if (address != 0) {
                        setClaimButtonText('Claim your swapped ETH')
                    } else {
                        setClaimButtonText('Claim your unswapped token')
                    }
                })
            } else {
                setIsClaim(true)
            }

        })
    }



    useEffect(() => {
        if (active) {
            queryPoolDetail()
        } else {
            dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
        }
    }, [active])

    useEffect(() => {
        if (active) {
            queryRound()
        }
    }, [active])


    // useEffect(() => {
    //     console.log('starting to queryJointStatus', isMine)
    //     if (active && account && status && !isMine) {
    //         queryJointStatus()
    //     }
    // }, [active, status, isMine, joined])

    useEffect(() => {
        if (active) {
            queryPoolStatus()
        }
    }, [active])


    useEffect(() => {
        if (active && isMine && status === 'Closed') {
            console.log('query my status')
            queryMyStatus()
        }
    }, [active, status, isMine])


    return {
        name,
        address,
        symbol,
        startPrice,
        reservePrice,
        NFT,
        status,
        isMine,
        round,
        currentPrice,
        limit,
        curBiddenAmount,
        bidden,
        isClaim,

        floor,
        time,
        totalBid,
        password,
        fromBidAmount,
        toBidAmount,
        fromAmount,
        claimButtonText,
        myBid,
        joined,
        tokenId,
        winnerAmount,
        amountMinIncr,
        setJoined,
        setIsMine
    }
}