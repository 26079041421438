export const TabMenu = [{
    name: 'Fixed Swap',
    method: 'query_account_fixed_pools',
    route: '/account/fixed-swap',
    isBSC: true
}, {
    name: 'Sealed Bid',
    method: 'query_account_sealed_bid_pools',
    route: '/account/sealed-bid'
}, {
    name: 'Dutch Auction',
    method: 'query_account_dutch_auction_pools',
    route: '/account/dutch-auction'
}, {
    name: 'NFT Fixed Swap',
    method: 'query_account_fixed_nft_pools',
    route: '/account/fixed-swap-nft'
}, {
    name: 'NFT Sealed Bid',
    method: 'query_account_sealed_nft_pools',
    route: '/account/sealed-bid-nft',
    isBSC: true
}, {
    name: 'NFT English Auction',
    method: 'query_account_english_nft_pools',
    route: '/account/english-auction-nft',
    isBSC: true
}, {
    name: 'NFT Dutch Auction',
    method: 'query_account_dutch_nft_pools',
    route: '/account/dutch-auction-nft'
}, {
    name: 'Lottery',
    method: 'query_account_lottery_pools',
    route: '/account/lottery'
}, {
    name: 'NFT Lottery',
    method: 'query_account_lottery_nft_pools',
    route: '/account/lottery-nft',
    isBSC: true
}, {
    name: 'Predictions',
    method: 'query_account_prediction_pools',
    route: '/account/predictions'
}, {
    name: 'Social Fixed Swap',
    method: 'query_account_social_fixed_pools',
    route: '/account/sv-fixed-swap'
}, {
    name: 'Social Sealed Bid',
    method: 'query_account_social_sealed_pools',
    route: '/account/sv-sealed-bid'
}, {
    name: 'View My NFT',
    method: 'View My NFT',
    route: '/account/view-my-nft',
    isBSC: true
}]