import React, { useState, useCallback,useEffect, useContext } from 'react';
import { Return } from './Return';
import { ClaimForm } from './content/ClaimForm';
import { ClaimSucces } from './content/ClaimSuccess';
import { ClaimFailed } from './content/ClaimFailed';
import './content/content-box.scss';
import { DeniedContent } from './content/DeniedContent';
import { WaitContent } from './content/WaitContent';
import { getTransactionLink } from './const';
import {myContext} from '../reducer'


export const ClaimContent = ({
  symbol,
  account,
}) => {
  const {state, dispatch} = useContext(myContext);
  const [status, setStatus] = useState('init');
  const [hash, setHash] = useState('');
  const [address, setAddress] = useState('');

  const {web3, myAccount, curPoolType} = state;


  useEffect(()=>{
    async function loadAddress() {
      const address = await getTransactionLink(hash)
      setAddress(address);
    }
    loadAddress()
  },[hash]);

  const handleClose = useCallback(() => {
    setStatus('init');
  }, [setStatus]);

  const renderClaim = useCallback(() => {
    switch(status) {
      case 'init':
      default:
        return <ClaimForm
        symbol={symbol}
        account={myAccount}
        onStatusChange={setStatus}
        onHashChange={setHash}
        web3={web3}/>
      case 'waiting':
        return <WaitContent isCommon={true} symbol={symbol} />;
      case 'success':

        return <ClaimSucces onClose={handleClose} link={address} />
      case 'failed':
        return <ClaimFailed onClose={handleClose} />
      case 'denied':
        return <DeniedContent onClose={handleClose} />
    }
  }, [symbol, hash, status, account, handleClose, address]);

  return (
    <div className='content claim'>
      <Return type={curPoolType}/>
      <div className='form'>
        {renderClaim(status)}
      </div>
    </div>
  )
}
