import React, { useState, useEffect } from 'react';
import { Pool, PoolFrame } from "../../components/common/Layout";
import icon_close from '../../static/image/icon-close.svg'
import { Form, FormStatus, Input } from "../../components/common/Form";
import icon_max from '../../static/image/icon-max.svg'

import icon_eye_open from '../../static/image/icon-eye-open.svg'
import icon_eye_close from '../../static/image/icon-eye-close.svg'
import { useParams } from 'react-router-dom';
import { useHandleForm, useTokenList } from '../../web3/common'
import { Button } from "../../components/common/Button";
import { numToWei, weiToNum } from "../../utils/numberTransform";
import md5 from "js-md5";
import Web3 from 'web3'
import { useHistory } from 'react-router-dom'
import { getTime, isGreaterThan } from "../../utils/common";
import { getContract, useActiveWeb3React } from "../../web3";
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import bounceERC721 from "../../web3/abi/bounceERC721.json";
import bounceERC1155 from "../../web3/abi/bounceERC1155.json";
import bounceSealedBidNFT from "../../web3/abi/bounceSealedBidNFT.json";
import bounceDutchAuctionNFT from "../../web3/abi/bounceDutchAuctionNFT.json";
import bounceDutchAuctionNFT1155 from "../../web3/abi/BounceDutchAuctionErc1155.json";

import {
    getFixSwapAddress,
    getSealedBidAddress,
    getSealedBidNFTAddress,
    getDutchAuction721,
    getDutchAuction1155,
} from "../../web3/contractAddress";
import fixSwap from "../../web3/abi/bounce.json";
import sealedBid from "../../web3/abi/bounceSealedBid.json";
import icon_helper from '../../static/image/icon-helper.svg';
import { useIsXSDown } from '../../components/utils/themeHooks';

import {
    CreateModal,
    initStatus,
    approveStatus,
    pendingStatus,
    confirmStatus,
    successStatus,
    errorStatus,
    cancelStatus
} from "../../components/common/CreateModal";
import { HelperLayout } from "../../components/common/HelperLayout";
import { PoolCover } from "../../components/DetailCover";
import rect_logo from "../../static/image/rect-logo.svg";
import { Select } from "../../components/common/Select";
import { AuctionTipModal } from "../../components/common/AuctionTipModal";
import Modal from "../../components/common/Modal";

const BigNumber = require('bignumber.js');
const { toWei, BN } = Web3.utils


export const CreateSBNFTPool = () => {
    const history = useHistory()
    const isXSDown = useIsXSDown();
    const { account, library, chainId } = useActiveWeb3React();
    const tokenOptions = useTokenList()
    const [modalStatus, setModalStatus] = useState(initStatus)

    const [isPrivate, setIsPrivate] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [hasLimit, setHasLimit] = useState(false)
    const [selectedToken, setSelectedToken] = useState(tokenOptions[0])
    const [isDutchNFT1155, setIsDutchNFT1155] = useState(false)
    const [showTip, setShowTip] = useState()


    const {
        address, setAddress, addressError,
        token,
        amount, setAmount, amountError,
        ratio, setRatio, ratioError,
        level,
        name, setName, nameError,
        onlyBot, setOnlyBot,
        password, setPassword,
        limit, setLimit,
        days, setDays,
        hours, setHours,
        minutes, setMinutes,
        timeError,
        check, setCheck,
        nftType, setNFTType,
        nftID, setNFTID, idError, setIDError,
        nft,
        minAmount, setMinAmount,
        maxAmount, setMaxAmount,
        times, setTimes
    } = useHandleForm()

    const onSB721Create = async () => {
        console.log('onSB721Create', name, address, ratio)
        if (!name || !address || !ratio) {
            return;
        }
        const bounceAddress = getSealedBidNFTAddress(chainId)
        const tokenContract = getContract(library, bounceERC721.abi, address)
        const bounceContract = getContract(library, bounceSealedBidNFT.abi, bounceAddress)
        const time = getTime(days, hours, minutes);
        setModalStatus(approveStatus);
        try {
            const result = await tokenContract.methods.approve(
                bounceAddress,
                nftID,
            )
                .send({ from: account });
            setModalStatus(confirmStatus);
            if (result.status) {
                await bounceContract.methods.createErc721(
                    name,
                    address,
                    nftID,
                    toWei(ratio),
                    time,
                    onlyBot,
                    !password || password === '' ? 0 : new BN(md5(password)).toString(),
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            } else {
                setModalStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    };

    const onSB1155Create = async () => {
        setCheck(!check)
        if (!name || !address || !amount || !ratio) {
            return;
        }
        const bounceAddress = getSealedBidNFTAddress(chainId)
        const tokenContract = getContract(library, bounceERC1155.abi, address)
        const bounceContract = getContract(library, bounceSealedBidNFT.abi, bounceAddress)
        const time = getTime(days, hours, minutes);

        setModalStatus(approveStatus);
        try {
            const result = await tokenContract.methods.setApprovalForAll(
                bounceAddress,
                true,
            )
                .send({ from: account });
            setModalStatus(confirmStatus);
            if (result.status) {
                await bounceContract.methods.createErc1155(
                    name,
                    address,
                    nftID,
                    amount,
                    toWei(ratio),
                    time,
                    onlyBot,
                    !password || password === '' ? 0 : new BN(md5(password)).toString(),
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        history.push('/')
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            } else {
                setModalStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    };

    useEffect(() => {
        setNFTType('nft-721')
    }, [])

    const wrapperSpace = (str) => {
        if (!str) return false
        if (String(str).trim().length === 0) return false
        return true
    }

    return (
        <PoolFrame style={{ marginTop: 32, padding: isXSDown ? '40px 20px' : '40px 100px' }}>
            <Pool.Close onClick={() => {
                history.goBack()
            }} src={icon_close} />
            <Pool>
                <Pool.Mode style={{ textAlign: 'left' }}>Initial Token Offering</Pool.Mode>
                <Pool.Header style={{
                    justifyContent: 'space-between',
                    borderBottom: '4px #000 solid',
                    fontSize: isXSDown ? '26px' : '36px',
                }}>{'Create a Sealed-Bid NFT Auction'}
                    <Button
                        height={'40px'}
                        width={'184px'}
                        onClick={() => {
                            window.open('https://docs.bounce.finance', '_blank')
                        }}
                    >How to Create a pool</Button>
                </Pool.Header>
                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div width={isXSDown ? '100%' : '480px'} style={{ height: '100%' }}>
                        Contract information
                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                        {
                            <Form
                                type='radio'
                                prefix={(
                                    <>
                                        <label>
                                            <input onChange={(e) => {
                                                setNFTType('nft-721')
                                            }} type='radio' name='nft-type' defaultChecked={true} />
                                            <i></i>
                                            {` erc721`}
                                        </label>
                                        <label style={{ marginLeft: 80, marginRight: 34 }}>
                                            <input onChange={() => {
                                                setNFTType('nft-1155')
                                            }} type='radio' name='nft-type' />
                                            <i></i>
                                            {` erc1155`}
                                        </label>
                                    </>
                                )} name={<span style={{ marginBottom: 9 }}>Token Type</span>} />
                        }
                        <Form top={'38px'}
                            error={addressError}
                            input={<Input
                                // value={'0x49963648B69a9d318e7CA9F36d2b890d0fef9b5f'}
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                }} />} name={'Token Contract address'} />

                        <Form top={'38px'}
                            error={idError}
                            input={<Input
                                onFocus={(e) => {
                                    setNFTID(e.target.value)
                                }}
                                onChange={(e) => {
                                    setNFTID(e.target.value)
                                }} />} name={nftType === 'nft-721' ? 'ERC-721 Token ID' : 'ERC-1155 Token ID'} />
                        {nftType === 'nft-1155' && (
                            <Form top={'43px'} error={amountError} input={<Input
                                type='number'
                                value={amount}
                                onChange={(e) => {
                                    setAmount(e.target.value.replace(/[^\d.]/g, ''))
                                }}
                                onBlur={() => {
                                    if (amount && token.balance && isGreaterThan(numToWei(amount, token.decimals), token.balance)) {
                                        console.log('check--->')
                                        setAmount(weiToNum(token.balance, token.decimals))
                                    }

                                }}
                            />} name={'Amount'}
                                // addonAfter={(<img onClick={() => {
                                //     token && setAmount(token.balance)
                                // }}
                                //     src={icon_max} />)}
                                // extra={<span>{`Balance: ${token ? `${weiToNum(token.balance, token.decimals)}` : '--'}`}</span>}
                            />
                        )}

                    </Pool.Content>
                </Pool.Frame>

                <Pool.Divider />

                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div style={{ width: isXSDown ? '100%' : 480, height: '100%' }}>
                        Pool settings
                            <Pool.Content width={isXSDown ? '100%' : '512px'} style={{ marginTop: 48 }}>
                            <PoolCover cover={nft && (nft.cover ? nft.cover : rect_logo)}></PoolCover>
                        </Pool.Content>
                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                        <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '480px'}>
                            <Form disabled
                                input={<Input disabled value={nftType === 'nft-1155' ? 'ERC1155_TOKEN' : token.symbol} />}
                                name={'From'}
                                width={isXSDown ? '100%' : '213px'} />
                            <Form name={'To'}
                                width={isXSDown ? '100%' : '213px'}
                                input={<Select
                                    border
                                    width={'213px'}
                                    options={tokenOptions}
                                    onSelect={(value) => {
                                        console.log('selected', value)
                                        setSelectedToken(value)
                                    }} defaultValue={tokenOptions[0]} />} />

                            {/*<Form disabled input={<Input disabled value={Psymbol} style={{ paddingLeft: 24 }} />} name={'To'}*/}
                            {/*  width={isXSDown?'100%':'213px'}*/}
                            {/*  addonBefore={(<img src={icon_eth} />)} />*/}
                        </Pool.Content>
                        {<Form top={'38px'} error={ratioError} input={<Input
                            type='number'
                            value={ratio}
                            onBlur={() => {

                            }}
                            onChange={(e) => {
                                setRatio(e.target.value.replace(/[^\d.]/g, ''))

                            }}
                        />}
                            name={'Starting price (Floor price)'}
                            // prefix={type !== 'sealed-bid-nft' && `1 ${selectedToken.symbol} =  `}
                            suffix={selectedToken.symbol} />}


                        <Pool.Content width={isXSDown ? '100%' : '480px'}>
                            {<Form
                                type='radio'
                                top={'48px'}
                                name={<HelperLayout
                                    content={'You can set a maximum allocation per wallet to prevent monopoly activities during the token swap.'
                                    }>
                                    {`Maximum Allocation per Wallet `}<img
                                        src={icon_helper} /></HelperLayout>} prefix={(<>
                                            <label>
                                                <input
                                                    onChange={(e) => {
                                                        setHasLimit(false)
                                                        setLimit(false)
                                                    }} type='radio' name='limit' defaultChecked={true} />
                                                <i></i>
                                                {` No limits`}
                                            </label>
                                            <label style={{ marginLeft: 80, marginRight: 34 }}>
                                                <input
                                                    onChange={() => {
                                                        setHasLimit(true)
                                                        setLimit('0')

                                                    }} type='radio' name='limit' />
                                                <i></i>
                                                {` ${selectedToken.symbol}`}
                                            </label>
                                        </>)} />}

                            {hasLimit && (
                                <Form
                                    top={'38px'}
                                    suffix={selectedToken.symbol}
                                    input={<Input disabled={!hasLimit} onBlur={() => {
                                        if ((ratio && amount) && new BigNumber(amount).dividedBy(ratio).dividedBy(1000).isGreaterThan(limit)) {
                                            setLimit(new BigNumber(amount).dividedBy(ratio).dividedBy(1000).toString())
                                        } else if (amount && ratio && new BigNumber(limit).isGreaterThan(new BigNumber(amount).dividedBy(ratio))) {
                                            setLimit(new BigNumber(amount).dividedBy(ratio).toString())
                                        }
                                    }} type='number' value={limit} onChange={(e) => {
                                        setLimit(e.target.value.replace(/[^\d.]/g, ''))
                                    }}
                                    />} name={'Allocation'} />
                            )}
                        </Pool.Content>

                        <Pool.Divider />

                        <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '480px'}>
                            <Form
                                type='radio'
                                top={'38px'}
                                name={<span style={{ color: '#000' }}>Participant</span>}
                                prefix={(<div style={{ marginTop: 9, display: 'flex', alignItems: 'center' }}>
                                    <label>
                                        <input onChange={() => {
                                            setOnlyBot(true)
                                            setPassword(null)
                                            setIsPrivate(false)
                                        }} type='radio' name='participant' defaultChecked={true} />
                                        <i></i>
                                        {`  BOT holders`}
                                    </label>
                                    <label style={{
                                        marginLeft: isXSDown ? '14px' : 60,
                                        marginRight: isXSDown ? '14px' : 34
                                    }}>
                                        <input onChange={() => {
                                            setOnlyBot(false)
                                            setPassword(null)
                                            setIsPrivate(false)
                                        }} type='radio' name='participant' />
                                        <i></i>
                                        {` Public`}
                                    </label>
                                    <label style={{
                                        marginLeft: isXSDown ? '14px' : 60,
                                        marginRight: isXSDown ? '14px' : 34
                                    }}>
                                        <input onChange={() => {
                                            setOnlyBot(false)
                                            setIsPrivate(true)
                                        }} type='radio' name='participant' />
                                        <i></i>
                                        {` Private`}
                                    </label>
                                </div>)} />
                            {isPrivate && (
                                <Form
                                    top={'38px'}
                                    addonAfter={(<img onClick={() => {
                                        setShowPassword(!showPassword)
                                    }} src={showPassword ? icon_eye_open : icon_eye_close} />)}
                                    input={<Input type={!showPassword && 'password'} onChange={(e) => {
                                        setPassword(e.target.value)
                                        console.log('password', new BN(md5(e.target.value)).toString())
                                    }}
                                    />} name={'Password'} />
                            )}

                        </Pool.Content>

                        <Pool.Divider />

                        <Form top={'38px'} error={nameError} input={<Input
                            maxLength={15}
                            onBlur={(e) => {
                                setName(e.target.value)
                            }}
                        />} name={'Pool Name'} />

                        <Form top={'49px'} error={timeError}
                            name={<span style={{ color: '#000', marginBottom: 9 }}>Pool running time</span>} hidden
                            prefix={(<div>
                                <Pool.Content width={isXSDown ? '100%' : '480px'}>
                                    <Form input={<Input
                                        onChange={(e) => {
                                            setDays(e.target.value)
                                        }}
                                        type='number' />} name={'Days'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        value={hours}
                                        onChange={(e) => {
                                            let hours = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                            if (hours > 24) {
                                                hours = 24
                                            }
                                            setHours(hours);
                                        }}
                                        type='number' />} name={'Hours'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        value={minutes}
                                        onChange={(e) => {
                                            let minutes = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                            if (minutes > 60) {
                                                minutes = 60
                                            }
                                            setMinutes(minutes);
                                        }}
                                        type='number' />} name={'Minutes'} width={isXSDown ? '30%' : '132px'} />
                                </Pool.Content>
                            </div>)} />

                        <Form top={'38px'} disabled name={<><HelperLayout
                            content={`bounce charge ${'1.5%'} fee to pool creator based on the amount of the successfully swapped tokens`}> {`Transaction Fee `}<img
                                src={icon_helper} /></HelperLayout></>} hidden prefix={(<div
                                    style={{ width: isXSDown ? '100%' : '480px' }}>
                                    <Button
                                        disabled={idError === '' && wrapperSpace(name) && wrapperSpace(ratio) && (wrapperSpace(days) || wrapperSpace(hours) || wrapperSpace(minutes)) ? false : true}
                                        style={{ marginTop: 12 }} black width={isXSDown ? '100%' : '480px'} onClick={() => {
                                            setShowTip(true)
                                        }}>Launch</Button>
                                </div>)} />
                    </Pool.Content>
                </Pool.Frame>
            </Pool>

            <CreateModal onOK={() => {
                setModalStatus(initStatus)
                history.goBack()
            }} onDismiss={() => {
                setModalStatus(initStatus)
            }} modalStatus={modalStatus} />

            <Modal
                closeable
                isOpen={showTip}
                onDismiss={() => {
                    setShowTip(false)
                }}
                maxWidth={'450px'}

            >
                <AuctionTipModal type={1} auction={() => {
                    setShowTip(false)
                    if (nftType === 'nft-721') {
                        onSB721Create()
                    } else {
                        onSB1155Create()
                    }
                }} />

            </Modal>

        </PoolFrame>
    )
}
