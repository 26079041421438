import { getContract, useActivePlatform, useActiveWeb3React } from "../../web3";
import { useContext, useEffect, useState } from "react";
import BouncePredict from "../../web3/abi/BouncePredict.json";
import AggregatorV3Interface from '../../web3/abi/AggregatorV3Interface.json'
import { myContext } from '../../reducer'
import { HANDLE_SHOW_CONNECT_MODAL } from "../../const";
import {getBouncePredictionAddress} from "../../web3/contractAddress";
import {isGreaterThan} from "../../utils/common";


export const usePoolDetail = (id = 0) => {
  const { active, account, library, chainId } = useActiveWeb3React();

  const { dispatch } = useContext(myContext);

  const [pool, setPool] = useState()
  const [totalVoteUp, setTotalVoteUp] = useState('0')
  const [totalVoteDown, setTotalVoteDown] = useState('0')
  const [myVoteUp, setMyVoteUp] = useState('0')
  const [myVoteDown, setMyVoteDown] = useState('0')
  const [endPrice, setEndPrice] = useState('0')
  const [curPrice, setCurPrice] = useState('0')
  const [bonus, setBonus] = useState('0')
  const [origin, setOrigin] = useState('0')
  const [pair, setPair] = useState()
  const [decimals, setDecimals] = useState()

  const [result, setResult] = useState()


  const [status, setStatus] = useState()

  const [claimed, setClaimed] = useState(true)

  const [resultStatus, setResultStatus] = useState()







  async function getPoolDetail() {
    try {

      const bounceContract = getContract(library, BouncePredict.abi, getBouncePredictionAddress(chainId))

      bounceContract.methods.result(id).call().then((res) => {
        console.log('pool result:', res)
        setResultStatus(res)
      })

      bounceContract.methods.pools(id).call().then((res) => {
        console.log('pool:', res)
        const aggregatorContract = getContract(library, AggregatorV3Interface.abi, res.priceFeed)
        aggregatorContract.methods.latestRoundData().call().then((res) => {
          console.log('pool current price:', res)
          setCurPrice(res.answer)
        })

        aggregatorContract.methods.description().call().then(res =>{
          console.log('pool description:', res)
          setPair(res)
        });

        aggregatorContract.methods.decimals().call().then(res =>{
          console.log('pool decimals:', res)
          setDecimals(res)
        });

        setPool(res)
        const duration = res.duration  * 1000
        const closeAt = res.closeAt * 1000
        const now = new Date();
        const startAt = closeAt - duration
        const pastTime = now - startAt
        if((duration - 2*60*60*1000)>=pastTime){
          setStatus('Live')
        }else if( duration>pastTime && pastTime> (duration - 2*60*60*1000)){
          setStatus('Settlement')
        }else {
          setStatus('Closed')
          bounceContract.methods.endPrice(id).call().then((res) => {
            console.log('pool endPrice:', res)
            setEndPrice(res)
            if(isGreaterThan(res.startPrice, result)){
              setResult('DOWN')
            }else {
              setResult('UP')
            }
          })

          bounceContract.methods.getMyResult(id, account).call().then((res) => {
            console.log('pool my result:', res)
            setBonus(res.bonus)
            setOrigin(res.origin)
          })

          bounceContract.methods.myClaimed(account, id).call().then((res) => {
            console.log('pool my myClaimed:', res)
            setClaimed(res)
          })

        }
      })

      bounceContract.methods.totalVoteUp(id).call().then((res) => {
        console.log('pool totalVoteUp:', res)
        setTotalVoteUp(res)
      })

      bounceContract.methods.totalVoteDown(id).call().then((res) => {
        console.log('pool totalVoteDown:', res)
        setTotalVoteDown(res)
      })

      bounceContract.methods.myVoteUp(account, id).call().then((res) => {
        console.log('pool myVoteUp:', res)
        setMyVoteUp(res)
      })

      bounceContract.methods.myVoteDown(account, id).call().then((res) => {
        console.log('pool myVoteDown:', res)
        setMyVoteDown(res)
      })




    } catch (e) {
      console.log('pool error:', e)
    }
  }

  useEffect(() => {
    if (active && chainId &&  account) {
      getPoolDetail()
    } else {
      dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
    }
  }, [active, chainId, account])


  return {
    pool,
    totalVoteUp,
    totalVoteDown,
    myVoteUp,
    myVoteDown,
    status,
    endPrice,
    curPrice,
    result,
    bonus,
    origin,
    claimed,
    pair,
    decimals,
    resultStatus
  }
}

