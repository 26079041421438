import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useActiveWeb3React } from "../../web3";
import { AccountHeader, AuctionFrame, Auction } from './styled'
import xiahua from '../../static/image/xiahua.svg'
import { TabMenu } from './config'
import { requestSearch } from '../A_poolList/useHook/Request_API'

export const Header = ({ setQueryData, setIsLoading, setIsEmpty }) => {
  const history = useHistory()
  const [pathname, setPathname] = useState(history.location.pathname)
  const { active, account, chainId } = useActiveWeb3React()

  useEffect(() => {
    if (!active || !pathname) return
    setIsLoading(true)
    const method = getMethodByPath(pathname)
    try {
      requestApi(method)
    } catch (error) {
      setQueryData([])
      setIsLoading(false)
      setIsEmpty(true)
    }
  }, [pathname, active])

  const requestApi = async (method) => {
    const params = {
      "method": method,
      "user_address": account
    }

    const res = await requestSearch(chainId, params)
    if (res.data.code !== 200) {
      setQueryData([])
      setIsLoading(false)
      setIsEmpty(true)
    }
    let arr = [...res.data.data.created_ids, ...res.data.data.participated_ids]
    const index = arr.findIndex(item => item === 301)
    // 测试网的 301 是一个脏数据，查询会报错，直接过滤掉
    if (index !== -1 && chainId === 4) {
      arr.splice(index, 1)
    }

    const sortArr = arr.sort((a1, a2) => a2 - a1)

    // console.log('R_console', sortArr)
    setQueryData(sortArr)
    setIsLoading(false)
    if (sortArr.length === 0) {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }
  }

  const getMethodByPath = (pathname) => {
    const tar = TabMenu.filter(item => {
      return item.route === pathname
    })

    return tar[0] && tar[0].method
  }

  return (
    <AccountHeader>
      <AuctionFrame>
        {TabMenu.filter((item) => {
          return item.isBSC
        }).map((item, index) => {
          return <Auction
            key={index}
            onClick={() => {
              setQueryData([])
              setPathname(item.route)
            }}
            active={item.route === pathname}
            to={item.route}>
            {item.name}
          </Auction>
        })}
        <img src={xiahua} alt="" />
      </AuctionFrame>
    </AccountHeader>
  )
}


