import Web3 from "web3";
import {getWei} from "../components/utils/web3";

const {numberToHex} = Web3.utils

const BigNumber = require('bignumber.js');

export const getTime = (day, hour, min) => {
  let time = 0;
  if ( day ) {
    time += 24 * 60 * 60 * day;
  }
  if ( hour ) {
    time += 60* 60 * hour;
  }
  if ( min ) {
    time += 60 * min;
  }
  return time;
}

export const isGreaterThan = (value1, value2) => {
  return   new BigNumber(value1).isGreaterThan(new BigNumber(value2))
}


export const isEqualTo = (value1, value2) => {
  return   new BigNumber(value1).isEqualTo(new BigNumber(value2))
}

export const formatAddress = (address) => {
  return address.replace(address.substring(7, address.length - 5), "...")
}

export const getRatio = (fromTotal, decimals, toTotal) => {
  const fromBN = new BigNumber(Web3.utils.fromWei(fromTotal, getWei(decimals)))
  const toBN = new BigNumber(Web3.utils.fromWei(toTotal))
  return new BigNumber(fromBN.div(toBN).toFixed(8)).toNumber();
}

export function int2hex(num, width) {
  num = numberToHex(num)
  num = num.slice(2)
  var delta = width - num.length;
  var padding = "";
  while(delta-- > 0) {
    padding += "0";
  }
  return "0x" + padding + num;
}
