import React, { Component } from 'react'
import Lottie from 'react-lottie'
import wenhao from './wenhao.json'
import giftBox from './giftBox.json'

export class Wenhao extends Component {


    render() {

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: wenhao,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div>
                <Lottie options={defaultOptions}
                    height={40}
                    width={40}
                />
            </div>
        )
    }
}


export class GiftBox extends Component {


    render() {

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: giftBox,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div>
                <Lottie options={defaultOptions}
                    height={'100%'}
                    width={'100%'}
                />
            </div>
        )
    }
}
