import { useEffect, useState } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import { getDutchAuction } from "../../../web3/contractAddress";

import { queryPoolItem_pools, queryERC20Token, callPoolDataByID } from './Request_CT'
import { getPoolStatus, getParticipant, getDAPrice, getProgress } from './Request_FUN'

import DA_ERC20_ABI from '../../../web3/abi/dutchAuctionV1.json'

export const useDA_List_ERC20 = (queryListArr = []) => {
    const { active, library, chainId } = useActiveWeb3React()
    const [poolsDate, setPoolsDate] = useState([])
    const [isLoad, setIsLoad] = useState(true)


    useEffect(() => {
        if (!active) return
        setIsLoad(true)
        console.log('A_console_queryListArr', queryListArr)
        queryPoolDate(queryListArr)
    }, [active, queryListArr, chainId])

    const queryPoolDate = async (queryListArr) => {

        const DA_ERC20_CT = getContract(library, DA_ERC20_ABI.abi, getDutchAuction(chainId))

        const dataList = await Promise.all(queryListArr.map(async (poolID) => {
            const poolItemInfo = await queryPoolItem_pools(DA_ERC20_CT, poolID)
            poolItemInfo.isSwap = await callPoolDataByID(DA_ERC20_CT, 'creatorClaimedP', poolID)
            poolItemInfo.swapAmount = await callPoolDataByID(DA_ERC20_CT, 'amountSwap0P', poolID)
            poolItemInfo.onlyBot = await callPoolDataByID(DA_ERC20_CT, 'onlyBotHolderP', poolID)
            poolItemInfo.password = await callPoolDataByID(DA_ERC20_CT, 'passwordP', poolID)

            // 通过获取到的池子信息筛选出列表需要的信息
            /**
             * 1. 状态          √
             * 2. poolID        √
             * 3. 池子名称      √
             * 4. 合约地址      √   
             * 5. 交易对        √
             * 6. 价格          - ETH的当前价格 * 参数
             * 7. Swap Ratio    - 
             * 8. 池子类型      √
             * 9. 剩余进度  
             * *、创建者       √
             */
            // console.log('A_console_poolItemInfo', poolItemInfo)
            const poolItemData = {
                poolID: poolItemInfo.poolID,
                status: getPoolStatus(poolItemInfo.closeAt, poolItemInfo.amountTotal0, poolItemInfo.swapAmount),
                progress: getProgress(poolItemInfo.amountTotal0, poolItemInfo.swapAmount),
                poolName: poolItemInfo.name,
                fromToken: await queryERC20Token(library, poolItemInfo.token0, chainId),
                toToken: await queryERC20Token(library, '0x00', chainId),
                Participant: getParticipant(poolItemInfo.password, poolItemInfo.onlyBot),
                creator: poolItemInfo.creator,
                startPrice: getDAPrice(poolItemInfo.amountMax1, poolItemInfo.amountTotal0),
                endPrice: getDAPrice(poolItemInfo.amountMin1, poolItemInfo.amountTotal0)
            }

            // console.log('A_console_queryListArr', poolItemData)
            return poolItemData
        }))

        setPoolsDate(dataList)
        setIsLoad(false)
    }

    return { poolsDate, isLoad }
}
