import React, { useContext, useState, useEffect } from 'react'
import { myContext } from '../../../reducer'
import { ERC20_Card, ProgressStyled, InfoBoxStyled, StatusStyled, ClostAtStyled, SV_ERC20_Card } from './styled'
import Grow from '@material-ui/core/Grow';
import icon_wenhao from '../../../static/image/wenhao.svg'
import icon_eth from '../../../static/image/icon-eth-logo.svg'
import icon_usdt from '../../../static/image/icon-usdt.png'
import icon_bnb from '../../../static/image/icon-bnb.svg'


export const FS_CardItem = ({ data, detailsRoute, setIsShowTip, setTipInfo, poolType }) => {
    const { state } = useContext(myContext);
    return (
        <Grow in={true} timeout={500}>
            <ERC20_Card>
                <div className='wrapper'>
                    <div className='top'>
                        <Status status={data.status} />
                        <p>{`# ${data.poolID}`}</p>
                    </div>
                    <div className='title'>
                        {data.poolName}
                    </div>
                    <InfoBox
                        // account 
                        address={data.fromToken.adress}
                        Pair={[{ symbol: data.fromToken.symbol, cover: getIconImg(data.fromToken.adress) }, { symbol: data.toToken.symbol, cover: data.toToken.cover }]}
                        Participants={data.Participant}
                        // fixed-swap、sealed-bid
                        SwapRatio={data.swapRatio}
                        Price={data.currentPrice ? (Number(parseFloat(state.ethPrice / data.currentPrice).toFixed(4)) < 0.0001 ? '<0.0001' : Number(parseFloat(state.ethPrice / data.currentPrice).toFixed(4))) : null}
                        // duucth-auction
                        StartPrice={data.startPrice}
                        endPrice={data.endPrice}
                        // lottory
                        winnerNum={data.winnerNum}
                        ticket={data.ticket}
                    />
                    <Progress status={data.status} plan={data.progress} />
                </div>
                <div className='join_btn' onClick={() => {
                    setIsShowTip(true)
                    setTipInfo({
                        index: data.poolID,
                        fromSymbol: data.fromToken.symbol,
                        adress: data.fromToken.adress,
                        poolType: poolType,
                        detailsRoute: `${detailsRoute}${data.poolID}`
                    })
                }}>
                    {data.status === 'Live' ? 'Join' : 'Show Result'}
                </div>
            </ERC20_Card>
        </Grow>
    )
}

export const PRED_CardItem = ({ data, detailsRoute, setIsShowTip, setTipInfo, poolType }) => {
    return (
        <>
            <ERC20_Card>
                <div className='wrapper' onClick={() => {
                    setIsShowTip(true)
                    setTipInfo({
                        index: data.poolID,
                        fromSymbol: data.pair,
                        adress: data.priceFeed,
                        poolType: poolType,
                        detailsRoute: `${detailsRoute}${data.poolID}`
                    })
                }}>
                    <div className='top'>
                        <Status status={data.status} />
                        <p>{`# ${data.poolID}`}</p>
                    </div>
                    <div className='title'>
                        {data.poolName}
                    </div>
                    <PRED_InfoBox
                        pair={data.pair}
                        price={data.price}
                        totalVoteUp={data.totalVoteUp}
                        totalVoteDown={data.totalVoteDown}
                    />
                    <ClostAt closeAt={data.closeAt} />
                </div>
            </ERC20_Card>
        </>
    )
}

export const SV_CardItem = ({ data, detailsRoute, setIsShowTip, setTipInfo, poolType }) => {
    const [time, setTime] = useState({
        h: 0,
        m: 0,
        s: 0
    })

    useEffect(() => {
        if (data && !data.closeAt) return
        let timer = null
        const nowTime = new Date().getTime()
        const tarTime = new Date(data.closeAt * 1000).getTime()
        const diffTime = parseInt((tarTime - nowTime) / 1000)

        if (diffTime > 0) {
            timer = setInterval(() => {
                const time = {}
                time.h = parseInt(diffTime / (60 * 60))
                time.m = parseInt(diffTime / 60) % 60
                time.s = diffTime % 60

                setTime(time)
                diffTime--
            }, 1000)
        }

        return () => {
            clearInterval(timer)
        }
    }, [data])
    const { state } = useContext(myContext);
    return (
        <Grow in={true} timeout={500}>
            <SV_ERC20_Card bgColor={getSVbgcolorByStatus(data.status)}>
                <div className='wrapper' onClick={() => {
                    setIsShowTip(true)
                    setTipInfo({
                        index: data.poolID,
                        fromSymbol: data.fromToken.symbol,
                        adress: data.fromToken.adress,
                        poolType: poolType,
                        detailsRoute: `${detailsRoute}${data.poolID}`
                    })
                }}>
                    <div className='top'>
                        <Status status={data.status} />
                        <p>{`# ${data.poolID}`}</p>
                    </div>
                    <div className='title'>
                        <h1>{data.poolName}</h1>
                        <h3>{data.link}</h3>
                    </div>
                    <div className='info'>
                        {data.description}
                    </div>
                    <div className='buttom'>
                        <div className="up">
                            <p>Time left:</p>
                            <p>{time.h}h : {time.m}m : {time.s}s</p>
                        </div>
                        <Progress status={data.status} plan={data.progress} height={'6px'} marginTop={'0px'} />
                        <div className="down">
                            <p>Project progress:</p>
                            {data.status === 'Filled' ?
                                <p>{data.toBidAmount} {data.toToken.symbol}<span>/ {data.toAmount} {data.toToken.symbol}</span></p> :
                                <p>{data.totalVotes} {'BOT'}<span>/ {'300 BOT'}</span></p>
                            }
                        </div>
                    </div>
                </div>
            </SV_ERC20_Card>
        </Grow>
    )
}

const getColorByStatus = (status) => {
    switch (status) {
        case 'Live':
            return '#2DAB50'
        case 'Filled':
            return '#728AE0'
        case 'Closed':
            return '#8f8f8f8f'
        case 'Pending':
            return '#8f8f8f8f'
        case 'Unsuccessfully Closed':
            return '#8f8f8f8f'
        case 'Successfully Closed':
            return '#728AE0'
        default:
            return '#000000'
    }
}

const getSVbgcolorByStatus = (status) => {
    switch (status) {
        case 'Live':
            return '#E2F0E6'
        case 'Pending':
            return '#EDEEF1'
        case 'Closed':
            return '#EDEEF1'
        case 'Unsuccessfully Closed':
            return '#EDEEF1'
        case 'Filled':
            return '#E9EDF9'
        case 'Successfully Closed':
            return '#E9EDF9'
        default:
            return '#ffeaea'
    }
}

const checkImgExists = (imgurl) => {
    let cookie_yes_icon = JSON.parse(window.localStorage.getItem('cookie_yes_icon')) || []
    let cookie_no_icon = JSON.parse(window.localStorage.getItem('cookie_no_icon')) || []
    if (cookie_yes_icon.includes(imgurl)) {
        return true;
    } else if (cookie_no_icon.includes(imgurl)) {
        return false
    } else {
        var ImgObj = new Image(); //判断图片是否存在
        ImgObj.src = imgurl;
        //存在图片
        ImgObj.onload = () => {
            cookie_yes_icon.push(imgurl)
            window.localStorage.setItem('cookie_yes_icon', JSON.stringify(cookie_yes_icon))
            return true;
        }

        ImgObj.onerror = () => {
            cookie_no_icon.push(imgurl)
            window.localStorage.setItem('cookie_no_icon', JSON.stringify(cookie_no_icon))
            return false;
        }
    }
}

const getIconImg = (token) => {
    let src = ''
    switch (token) {
        case 'eth':
            src = icon_eth
            break;
        case 'bnb':
            src = icon_bnb
            break;
        case 'usdt':
            src = icon_usdt
            break;
        default:
            src = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${token}/logo.png`
            break;
    }
    if (checkImgExists(src)) {
        return src
    } else {
        return icon_wenhao
    }
}

const Progress = ({ status, plan, marginTop, height }) => {
    return <ProgressStyled color={getColorByStatus(status)} plan={plan} marginTop={marginTop} height={height}>
        <div></div>
        <div></div>
    </ProgressStyled>
}

const InfoBox = ({ address, Pair, SwapRatio, Price, Participants, StartPrice, endPrice, winnerNum, ticket }) => {

    const simpAdress = (adress) => {
        const start = String(address).substr(0, 6)
        const end = String(address).substr(-4)
        return `${start}...${end}`
    }

    return <InfoBoxStyled>
        {address && <div><span>Address</span><p>{simpAdress(address)}</p></div>}
        {Pair && <div><span>Pair</span>
            <p>
                <img src={Pair[0].cover} alt="" />
                {`${Pair[0].symbol} /  `}
                <img src={Pair[1].cover} alt="" />
                {`${Pair[1].symbol}`}
            </p>
        </div>}
        {SwapRatio && <div><span>Swap Ratio</span><p>{SwapRatio} : 1</p></div>}
        {Price && !ticket && <div><span>Price</span><p>$ {Price}</p></div>}
        {StartPrice && <div><span>Start Price</span><p>{StartPrice} {Pair[1].symbol}</p></div>}
        {endPrice && <div><span>Reserve Price</span><p>{endPrice} {Pair[1].symbol}</p></div>}
        {winnerNum && <div><span>Number Of Winner</span><p>{winnerNum}</p></div>}
        {ticket && <div><span>Ticket Price</span><p>{ticket} {Pair[0].symbol}</p></div>}
        {Participants && <div><span>Participants</span><p>{Participants}</p></div>}
    </InfoBoxStyled>
}

const PRED_InfoBox = ({ price, pair, totalVoteUp, totalVoteDown }) => {
    return <InfoBoxStyled>
        {pair && <div><span>Asset</span><p>{pair}</p></div>}
        {price && <div><span>Oracle Price</span><p>{price} USDT</p></div>}
        {totalVoteUp && <div><span>Call</span><p>{totalVoteUp} BOT</p></div>}
        {totalVoteDown && <div><span>Put</span><p>{totalVoteDown} BOT</p></div>}
    </InfoBoxStyled>
}

const Status = ({ status }) => {

    return <StatusStyled color={getColorByStatus(status)}>
        <div></div>
        <span>{status}</span>
    </StatusStyled>
}

const ClostAt = ({ closeAt }) => {
    const [time, setTime] = useState({
        d: 0,
        h: 0,
        m: 0,
        s: 0
    })

    useEffect(() => {
        const nowTime = new Date().getTime()
        const tarTime = new Date(closeAt * 1000).getTime()
        const times = (tarTime - nowTime) / 1000
        if (nowTime > tarTime) {
            return
        }

        const timer = setInterval(() => {
            times = times - 1


            const time = {
                d: parseInt(times / (24 * 60 * 60)),
                h: parseInt((times / (60 * 60))) % 24,
                m: parseInt((times / (60))) % 60,
                s: parseInt(times % 60)
            }
            setTime(time)
        }, 1000)
        return () => {
            // clearInterval(timer)
        }
    }, [closeAt])

    return <ClostAtStyled>
        <div>
            <span>{time.d}d</span>
            <span>:</span>
            <span>{time.h}h</span>
            <span>:</span>
            <span>{time.m}m</span>
            <span>:</span>
            <span>{time.s}s</span>
        </div>
    </ClostAtStyled>
}

