import React, { useContext, useEffect, useState } from 'react';
import bounceERC20 from '../web3/abi/bounceERC20.json'
import bounceERC721 from '../web3/abi/bounceERC721.json'
import bounceERC1155 from '../web3/abi/bounceERC1155.json'
import { useActivePlatform, useActiveWeb3React } from "./index";
import { getContract } from "../web3";
import { getBotAddress, getUSDTAddress, getWBTCAddress, getYFIAddress } from "./contractAddress";
import { isGreaterThan } from "../utils/common";
import BigNumber from "bignumber.js";
import Web3 from 'web3'
import { myContext } from '../reducer'
import { HANDLE_SHOW_CONNECT_MODAL } from "../const";
import { weiToNum } from "../utils/numberTransform";
import { TokenFrame } from "../components/common/Layout";
import icon_usdt from '../static/image/icon-usdt.png'
import bounce_token from '../static/image/bounce_token.png'
import wbtc_icon from '../static/image/wbtc_icon.png'
import yfi_icon from '../static/image/yfi_icon.png'

import icon_bnb from '../static/icon/icon_bnb.svg'
import icon_btc from '../static/icon/icon_btc.svg'
import icon_cbp from '../static/icon/icon_cbp.svg'
import icon_comp from '../static/icon/icon_comp.svg'
import icon_eth from '../static/icon/icon_eth.svg'
import icon_jpy from '../static/icon/icon_jpy.svg'
import icon_link from '../static/icon/icon_link.svg'
import icon_snx from '../static/icon/icon_snx.svg'
import icon_xrp from '../static/icon/icon_xrp.svg'

const { toWei } = Web3.utils




export const useTokenList = () => {
  const { chainId } = useActiveWeb3React();
  const { Psymbol } = useActivePlatform()

  return [{
    key: '0x0000000000000000000000000000000000000000',
    value: <TokenFrame><img src={icon_eth} /><span>{Psymbol}</span></TokenFrame>,
    symbol: Psymbol,
    decimals: '18'

  }, {
    key: getUSDTAddress(chainId),
    value: <TokenFrame><img src={icon_usdt} /><span>USDT</span></TokenFrame>,
    symbol: 'USDT',
    decimals: '6'
  }, {
    key: getBotAddress(chainId),
    value: <TokenFrame><img src={bounce_token} /><span>BOT</span></TokenFrame>,
    symbol: 'BOT',
    decimals: '18'
  }, {
    key: getWBTCAddress(chainId),
    value: <TokenFrame><img src={wbtc_icon} /><span>WBTC</span></TokenFrame>,
    symbol: 'WBTC',
    decimals: '8'
  }, {
    key: getYFIAddress(chainId),
    value: <TokenFrame><img src={yfi_icon} /><span>YFI</span></TokenFrame>,
    symbol: 'YFI',
    decimals: '18'
  }]
}

export const usePredictTokenList = () => {
  const { chainId } = useActiveWeb3React();

  return chainId === 1 ?
    [{
      key: '0x14e613AC84a31f709eadbdF89C6CC390fDc9540A',
      value: <TokenFrame><img src={icon_bnb} /><span>{'BNB/USD'}</span></TokenFrame>,
      symbol: 'BNB',
    }, {
      key: '0xF4030086522a5bEEa4988F8cA5B36dbC97BeE88c',
      value: <TokenFrame><img src={icon_btc} /><span>{'BTC/USD'}</span></TokenFrame>,
      symbol: 'BTC',
    }, {
      key: '0xdbd020CAeF83eFd542f4De03e3cF0C28A4428bd5',
      value: <TokenFrame><img src={icon_comp} /><span>{'COMB/USD'}</span></TokenFrame>,
      symbol: 'COMB',
    }, {
      key: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
      value: <TokenFrame><img src={icon_eth} /><span>{'ETH/USD'}</span></TokenFrame>,
      symbol: 'ETH',
    }, {
      key: '0xDC3EA94CD0AC27d9A86C180091e7f78C683d3699',
      value: <TokenFrame><img src={icon_snx} /><span>{'SNX/USD'}</span></TokenFrame>,
      symbol: 'SNX',
    }, {
      key: '0xCed2660c6Dd1Ffd856A5A82C67f3482d88C50b12',
      value: <TokenFrame><img src={icon_xrp} /><span>{'XRP/USD'}</span></TokenFrame>,
      symbol: 'XRP',
    }, {
      key: '0x2c1d072e956AFFC0D435Cb7AC38EF18d24d9127c',
      value: <TokenFrame><img src={icon_link} /><span>{'LINK/USD'}</span></TokenFrame>,
      symbol: 'LINK',
    }, {
      key: '0xBcE206caE7f0ec07b545EddE332A47C2F75bbeb3',
      value: <TokenFrame><img src={icon_jpy} /><span>{'JPY/USD'}</span></TokenFrame>,
      symbol: 'JPY',
    }, {
      key: '0x5c0Ab2d9b5a7ed9f470386e82BB36A3613cDd4b5',
      value: <TokenFrame><img src={icon_cbp} /><span>{'GBP/USD'}</span></TokenFrame>,
      symbol: 'GBP',
    }] :
    [{
      key: '0xd8bD0a1cB028a31AA859A21A3758685a95dE4623',
      value: <TokenFrame><img src={icon_eth} /><span>{'LINK/USD'}</span></TokenFrame>,
      symbol: 'LINK',
      decimals: '18'

    }, {
      key: '0xE96C4407597CD507002dF88ff6E0008AB41266Ee',
      value: <TokenFrame><img src={icon_eth} /><span>{'SNX/USD'}</span></TokenFrame>,
      symbol: 'SNX',
      decimals: '18'

    }, {
      key: '0xECe365B379E1dD183B20fc5f022230C044d51404',
      value: <TokenFrame><img src={wbtc_icon} /><span>WBTC/USD</span></TokenFrame>,
      symbol: 'WBTC',
      decimals: '8'
    }]
}


export const getIndexList = (count) => {
  let poolIdList = [];
  for (let i = 0; i < count; i++) {
    poolIdList[i] = count - i - 1;
  }
  return poolIdList
}

export const queryData = async (bounce, method, index) => {
  return await bounce.methods[method](index).call()
}

export const useEthBalance = (token) => {
  const { active, account, library, chainId } = useActiveWeb3React();
  const [ethBalance, setEthBalance] = useState(null)


  useEffect(() => {
    console.log('token---->', token)

    if (active) {
      if (token) {
        const tokenContract = getContract(library, bounceERC20.abi, token)
        tokenContract.methods.balanceOf(account).call().then(res => {
          console.log('token balance', res)
          setEthBalance(res)
        })
      } else {
        const web3 = new Web3(library.provider)
        web3.eth.getBalance(account)
          .then(balance => {
            console.log('eth balance', balance)
            console.log(ethBalance)
            setEthBalance(balance)
          })
      }

    } else {
      setEthBalance(null)
    }

  }, [active, token])

  return { ethBalance }
}

export const useTokenBalance = (address) => {
  const { active, account, library, chainId } = useActiveWeb3React();
  const [balance, setBalance] = useState(null)


  useEffect(() => {

    async function getBalance() {
      try {
        const tokenContract = getContract(library, bounceERC20.abi, address ? address : getBotAddress(chainId))
        const balance = await tokenContract.methods.balanceOf(account).call()
        setBalance(balance)
      } catch (e) {
        console.log('useTokenBalance:', e)
        setBalance(null)
      }
    }

    if (active) {
      getBalance()
    } else {
      setBalance(null)
    }

  }, [active, account])


  return { balance }
}


export const useHandleForm = () => {
  const { active, account, library, chainId } = useActiveWeb3React();
  const { state, dispatch } = useContext(myContext);


  const [name, setName] = useState()
  const [address, setAddress] = useState()
  const [token, settToken] = useState({ symbol: null, decimals: null, balance: null })
  const [participant, setParticipant] = useState()
  const [password, setPassword] = useState()
  const [ratio, setRatio] = useState()
  const [level, setLevel] = useState()
  const [minAmount, setMinAmount] = useState()
  const [maxAmount, setMaxAmount] = useState()
  const [times, setTimes] = useState()
  const [amount, setAmount] = useState()
  const [onlyBot, setOnlyBot] = useState(true)
  const [days, setDays] = useState()
  const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  const [limit, setLimit] = useState()
  const [startPrice, setStartPrice] = useState()
  const [reservePrice, setReservePrice] = useState()
  const [balance, setBalance] = useState('0')
  const [nftType, setNFTType] = useState()

  const [nftID, setNFTID] = useState()


  const [nft, setNFT] = useState()


  const [addressError, setAddressError] = useState(false)
  const [amountError, setAmountError] = useState(false)
  const [ratioError, setRatioError] = useState(false)
  const [timeError, setTimeError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [idError, setIDError] = useState(false)
  const [startPriceError, setStartPriceError] = useState(false)
  const [reservePricePriceError, setReservePricePriceError] = useState(false)


  const [check, setCheck] = useState(null)


  useEffect(() => {
    if (!active) {
      dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
    }
  }, [active])

  useEffect(() => {
    const initToken = { symbol: null, decimals: null, balance: null }

    async function getTokenInfo() {
      try {
        console.log('account:', account)
        const tokenContract = getContract(library, bounceERC20.abi, address)
        console.log('tokenContract:', tokenContract)
        const balance = await tokenContract.methods.balanceOf(account).call()
        setBalance(balance)
        console.log('balance:', balance)
        const symbol = await tokenContract.methods.symbol().call()
        console.log('symbol:', symbol)
        const decimals = await tokenContract.methods.decimals().call()
        console.log('decimals:', decimals)
        setAddressError(false)
        settToken({ balance, symbol, decimals })
      } catch (e) {
        console.log('getTokenInfo error:', e)
        settToken(initToken)
        setAddressError('Address is invalid')
      }
    }

    if (address) {
      if (!nftType) {
        getTokenInfo()
      }
    } else {
      settToken(initToken)
    }

  }, [address])


  useEffect(() => {

    async function queryNFT() {
      try {
        let tokenUrl = ''
        if (nftType === 'nft-721') {
          const contract = getContract(library, bounceERC721.abi, address)
          const symbol = await contract.methods.symbol().call()
          console.log('nft 721 symbol', symbol)
          settToken({ symbol })
          tokenUrl = await contract.methods.tokenURI(nftID).call()
          console.log('nft 721 token url', tokenUrl)
        } else {

          settToken({ symbol: '' })
          const contract = getContract(library, bounceERC1155.abi, address)
          console.log('tokenUrl1')
          contract.events.URI({
            filter: {},
            fromBlock: 0
          }, function (error, event) {
          })
            .on('data', function (event) {
              console.log('DATA:', event); // same results as the optional callback above
            })
            .on('changed', function (event) {
              // remove event from local database
            })
            .on('error', console.error);
          tokenUrl = await contract.methods.uri(nftID).call()
          console.log('tokenUrl2', tokenUrl)
        }

        try {
          const res = await fetch(tokenUrl, { mode: 'cors' })
          console.log('token url result', (res))
          const result = await res.json();
          if (result.image) {
            setNFT({ cover: result.image })
          } else if (result.properties.image.description) {
            setNFT({ cover: result.properties.image.description })
          }
        } catch (e) {
          console.log('fetch error', e)
        }
      } catch (e) {
        setNFT(null)
      }

    }

    async function queryNFTID() {

      try {
        if (nftType === 'nft-721') {
          const contract = getContract(library, bounceERC721.abi, address)
          const ownerAddress = await contract.methods.ownerOf(nftID).call()
          if (ownerAddress.toLowerCase() !== account.toLowerCase()) {
            setIDError('You are not the owner of this token')
          } else {
            setIDError('')
          }
        } else {
          const contract = getContract(library, bounceERC1155.abi, address)
          const balance = await contract.methods.balanceOf(account, nftID).call()
          console.log('nft1155 balance', balance)
          settToken({ balance: toWei(balance) })
          setBalance(toWei(balance))
          if (balance === 0) {
            setIDError('You are not the owner of this token')
          } else {
            setIDError('')
          }
        }
      } catch (e) {
        setIDError('Token ID is invalid')
      }
      
    }


    if (nftType && address && nftID) {
      queryNFTID()
      queryNFT()
    }
  }, [nftType, nftID, address])


  function checkAmount() {
    if (amount === '') {
      setAmountError('Amount is required')
    } else if (isGreaterThan(amount, weiToNum(balance, token.decimals))) {
      setAmountError('Insufficient balance')
    } else {
      setAmountError(null)
    }
  }

  function checkRatio() {
    if (ratio === '') {
      setRatioError('Ratio is required')
    } else {
      setRatioError(null)
    }
  }


  useEffect(() => {
    console.log('balance & amount:', amount, balance)
    checkAmount()
  }, [amount, token])


  useEffect(() => {
    checkRatio()
  }, [ratio])


  useEffect(() => {
    if (amount, ratio) {
      setLevel(new BigNumber(amount).dividedBy(new BigNumber(ratio)))
    }
  }, [amount, ratio])

  function checkName() {
    if (name === '') {
      setNameError('Pool name is required')
    } else {
      setNameError(null)
    }
  }

  useEffect(() => {
    checkName()
  }, [name])


  useEffect(() => {
    console.log('check:')
    checkName()
    checkRatio()
    checkAmount()
  }, [check])

  return {
    address, setAddress, addressError,
    token,
    amount, setAmount, amountError,setAmountError,
    ratio, setRatio, ratioError,
    times, setTimes,
    minAmount, setMinAmount,
    maxAmount, setMaxAmount,
    level, setLevel,
    name, setName, nameError,
    onlyBot, setOnlyBot,
    password, setPassword,
    limit, setLimit,
    days, setDays,
    hours, setHours,
    minutes, setMinutes,
    timeError,
    check, setCheck,
    startPrice, setStartPrice, startPriceError,
    reservePrice, setReservePrice, reservePricePriceError,
    nftType, setNFTType,
    nftID, setNFTID, idError, setIDError,
    nft, setNFT
  }
}


export const useStoreHandleForm = (poolAdress, tokenAdress) => {
  const { active, account, library, chainId } = useActiveWeb3React();
  const { state, dispatch } = useContext(myContext);


  const [name, setName] = useState()
  const [address, setAddress] = useState()
  const [token, settToken] = useState({ symbol: null, decimals: null, balance: null })
  const [participant, setParticipant] = useState()
  const [password, setPassword] = useState()
  const [ratio, setRatio] = useState()
  const [level, setLevel] = useState()
  const [minAmount, setMinAmount] = useState()
  const [maxAmount, setMaxAmount] = useState()
  const [times, setTimes] = useState()
  const [amount, setAmount] = useState()
  const [onlyBot, setOnlyBot] = useState(true)
  const [days, setDays] = useState()
  const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  const [limit, setLimit] = useState()
  const [startPrice, setStartPrice] = useState()
  const [reservePrice, setReservePrice] = useState()
  const [balance, setBalance] = useState('0')
  const [nftType, setNFTType] = useState()

  const [nftID, setNFTID] = useState()


  const [nft, setNFT] = useState()


  const [addressError, setAddressError] = useState(false)
  const [amountError, setAmountError] = useState(false)
  const [ratioError, setRatioError] = useState(false)
  const [timeError, setTimeError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [idError, setIDError] = useState(false)
  const [startPriceError, setStartPriceError] = useState(false)
  const [reservePricePriceError, setReservePricePriceError] = useState(false)


  const [check, setCheck] = useState(null)


  useEffect(() => {
    if (!active) {
      dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
    }
  }, [active])

  useEffect(() => {
    const initToken = { symbol: null, decimals: null, balance: null }

    async function getTokenInfo() {
      try {
        console.log('account:', account)
        const tokenContract = getContract(library, bounceERC20.abi, address)
        console.log('tokenContract:', tokenContract)
        const balance = await tokenContract.methods.balanceOf(account).call()
        setBalance(balance)
        console.log('balance:', balance)
        const symbol = await tokenContract.methods.symbol().call()
        console.log('symbol:', symbol)
        const decimals = await tokenContract.methods.decimals().call()
        console.log('decimals:', decimals)
        setAddressError(false)
        settToken({ balance, symbol, decimals })
      } catch (e) {
        console.log('getTokenInfo error:', e)
        settToken(initToken)
        setAddressError('Address is invalid')
      }
    }

    if (address) {
      if (!nftType) {
        getTokenInfo()
      }
    } else {
      settToken(initToken)
    }

  }, [address])


  useEffect(() => {

    async function queryNFT() {
      try {
        let tokenUrl = ''
        if (nftType === 'nft-721') {
          const contract = getContract(library, bounceERC721.abi, address)
          const symbol = await contract.methods.symbol().call()
          console.log('nft 721 symbol', symbol)
          settToken({ symbol })
          tokenUrl = await contract.methods.tokenURI(nftID).call()
          console.log('nft 721 token url', tokenUrl)
        } else {

          settToken({ symbol: '' })
          const contract = getContract(library, bounceERC1155.abi, address)
          console.log('tokenUrl1')
          contract.events.URI({
            filter: {},
            fromBlock: 0
          }, function (error, event) {
          })
            .on('data', function (event) {
              console.log('DATA:', event); // same results as the optional callback above
            })
            .on('changed', function (event) {
              // remove event from local database
            })
            .on('error', console.error);
          tokenUrl = await contract.methods.uri(nftID).call()
          console.log('tokenUrl2', tokenUrl)
        }

        try {
          const res = await fetch(tokenUrl, { mode: 'cors' })
          console.log('token url result', (res))
          const result = await res.json();
          if (result.image) {
            setNFT({ cover: result.image })
          } else if (result.properties.image.description) {
            setNFT({ cover: result.properties.image.description })
          }
        } catch (e) {
          console.log('fetch error', e)
        }
      } catch (e) {
        setNFT(null)
      }

    }

    async function queryNFTID() {

      try {
        if (nftType === 'nft-721') {
          const contract = getContract(library, bounceERC721.abi, address)
          const ownerAddress = await contract.methods.ownerOf(nftID).call()
          if (ownerAddress.toLowerCase() !== account.toLowerCase()) {
            setIDError('You are not the owner of this token')
          } else {
            setIDError('')
          }
        } else {
          const contract = getContract(library, bounceERC1155.abi, address)
          const balance = await contract.methods.balanceOf(account, nftID).call()
          console.log('nft1155 balance', balance)
          settToken({ balance: toWei(balance) })
          setBalance(toWei(balance))
          if (balance == 0) {
            setIDError('You are not the owner of this token')
          } else {
            setIDError('')
          }
        }
      } catch (e) {
        setIDError('Token ID is invalid')
      }

    }


    if (nftType && address && nftID) {
      queryNFTID()
      queryNFT()
    }
  }, [nftType, nftID, address])


  function checkAmount() {
    if (amount === '') {
      setAmountError('Amount is required')
    } else if (isGreaterThan(amount, weiToNum(balance, token.decimals))) {
      setAmountError('Insufficient balance')
    } else {
      setAmountError(null)
    }
  }

  function checkRatio() {
    if (ratio === '') {
      setRatioError('Ratio is required')
    } else {
      setRatioError(null)
    }
  }


  useEffect(() => {
    console.log('balance & amount:', amount, balance)
    checkAmount()
  }, [amount, token])


  useEffect(() => {
    checkRatio()
  }, [ratio])


  useEffect(() => {
    if (amount, ratio) {
      setLevel(new BigNumber(amount).dividedBy(new BigNumber(ratio)))
    }
  }, [amount, ratio])

  function checkName() {
    if (name === '') {
      setNameError('Pool name is required')
    } else {
      setNameError(null)
    }
  }

  useEffect(() => {
    checkName()
  }, [name])


  useEffect(() => {
    console.log('check:')
    checkName()
    checkRatio()
    checkAmount()
  }, [check])

  return {
    address, setAddress, addressError,
    token,
    amount, setAmount, amountError,setAmountError,
    ratio, setRatio, ratioError,
    times, setTimes,
    minAmount, setMinAmount,
    maxAmount, setMaxAmount,
    level, setLevel,
    name, setName, nameError,
    onlyBot, setOnlyBot,
    password, setPassword,
    limit, setLimit,
    days, setDays,
    hours, setHours,
    minutes, setMinutes,
    timeError,
    check, setCheck,
    startPrice, setStartPrice, startPriceError,
    reservePrice, setReservePrice, reservePricePriceError,
    nftType, setNFTType,
    nftID, setNFTID, idError, setIDError,
    nft, setNFT
  }
}
