import Modal, { ModalContent, ModalIcon, ModalTitle } from "./Modal";
import { useHistory } from 'react-router-dom'
import { Button } from "./Button";
import React, { useState } from "react"
import styled from 'styled-components'
import tether_usdt from '../../static/image/tether_usdt.svg'
import checkbox from '../../static/image/checkbox.svg'
import icon_wenhao from '../../static/image/wenhao.svg'
import { useActiveWeb3React } from "../../web3";

const JoinTipStyle = styled.div`
    .title{
        font-family: 'Optima';
        font-size: 26px;
        line-height: 31.5px;
        font-weight: 700;
        color: #1F191B;
        margin-bottom: 20px;
    }
    .text_content{
        width: 360px;
        border-top: 4px solid #FD531E;
        padding: 21px 24px;
        box-sizing: border-box;
        color: #FD531D;
        font-family: 'Helvetica Neue';
        font-size: 14px;
        line-height: 1.3em;
        text-align: left;
        background-color: rgb(255,246,244);
    
        p{
            margin-bottom: 20px;
        }
        h4{
            font-weight: bold;
        }
    }

    .currency{
        width: 360px;
        height: 16px;
        display: flex;
        align-items: center;
        margin-top: 35px;
        img{
            width: 28px;
            height: 28px;
            margin-right: 33px;
        }
        &>div{
            text-align: left;
            h3{
                font-family: 'Optima';
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                color: #000;
            }
            span{
                font-family: 'IBM Plex Mono';
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
            }
        }
        @media (max-width: 767px) {
            width: 100%;
            padding:0 20px;
        }
    }

    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 56px;
        label{
            display: flex;
            align-items: center;
            user-select: none;
            i{
                display: block;
                width: 14px;
                height: 14px;
                border: 1px solid #000000;
                margin-right: 8px;
                cursor: pointer;
            }
            input{
                display: none;
                &:checked + i{
                    border: none;
                    background: url(${checkbox}) no-repeat;
                    background-size: contain;
                    background-position: 0 0;
                }
            }
            
            span{
                font-family: 'Helvetica Neue';
                font-weight: 500;
                font-size: 14px;
                line-height: 17.09px;
            }
        }
        @media (max-width: 767px) {
            padding:0 20px;
        }
    }
`

export const JoinTipModal = ({ onJoin,onJoinInfo }) => {
    const history = useHistory()
    // const {active, account, library, chainId} = useActiveWeb3React();
    const [isAgree, setIsAgree] = useState(false)
    const { chainId } = useActiveWeb3React();

    const handelClickContinue = () => {
        if (!isAgree) {
            return
        }
        if(onJoinInfo.detailsRoute){
            history.push(onJoinInfo.detailsRoute)
        }else{
            onJoin(onJoinInfo.index)
        }
        
    }

    const checkImgExists = (imgurl) => {
        let cookie_yes_icon = JSON.parse(window.localStorage.getItem('cookie_yes_icon')) || []
        let cookie_no_icon = JSON.parse(window.localStorage.getItem('cookie_no_icon')) || []
        if (cookie_yes_icon.includes(imgurl)) {
            return true;
        } else if (cookie_no_icon.includes(imgurl)) {
            return false
        } else {
            var ImgObj = new Image(); //判断图片是否存在
            ImgObj.src = imgurl;
            //存在图片
            ImgObj.onload = () => {
                cookie_yes_icon.push(imgurl)
                window.localStorage.setItem('cookie_yes_icon', JSON.stringify(cookie_yes_icon))
                return true;
            }

            ImgObj.onerror = () => {
                cookie_no_icon.push(imgurl)
                window.localStorage.setItem('cookie_no_icon', JSON.stringify(cookie_no_icon))
                return false;
            }
        }
    }

    const getIconImg = (token) => {
        let src = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${token}/logo.png`
        if (checkImgExists(src)) {
            return src
        } else {
            return icon_wenhao
        }
    }

    const getShortAdress = (adress) => {
        if (!adress) {
            return null
        }

        let str1 = new String(adress).substr(0, 6)
        let str2 = new String(adress).substr(-4)
        return `${str1}...${str2}`
    }

    const getLink = (contract) => {
        let link = 'https://etherscan.io/'
        switch (chainId) {
            case 1:
                link = `https://etherscan.io/token/${contract}`
                break;
            case 4:
                link = `https://rinkeby.etherscan.io/token/${contract}`
                break;
            case 56:
                link = `https://bscscan.com/token/${contract}`
                break;
            case 97:
                link = `https://test.bscscan.com/token/${contract}`
                break;
            default:
                link = 'https://etherscan.io/'
                break;
        }
        console.log('link', link)
        return link
    }


    return (
        <JoinTipStyle>
            <div className="title">
                {onJoinInfo.poolType === 11 ? 'Disclaimer' : 'Token imported'}
            </div>
            {onJoinInfo.poolType === 11 ? (
                <div className="text_content">
                    <p>Bounce prediction contract uses chainlink oracles to query prices of predictive assets. The starting price and the final price are average prices from queried prices. Please be aware that Bounce is not responsible for price disputes.
                    </p>
                </div>
            ) : (
                    <div className="text_content">
                        <p>Anyone can create an ERC20 token on Ethereum with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.</p>
                        <p>This interface can load arbitrary tokens by token address. Please take extra caution and do your research when interacting with arbitrary ERC20 tokens.</p>
                        <h4>If you purchase an arbitrary token,
                        you may be inable to sell it back.</h4>
                    </div>
                )}

            <div className='currency'>
                <img src={getIconImg(onJoinInfo.adress)} alt="" />
                <div>
                    <h3>{onJoinInfo.fromSymbol}</h3>
                    <span>{getShortAdress(onJoinInfo.adress)} <a href={getLink(onJoinInfo.adress)} target="_blank" rel="noopener noreferrer"> (View on Etherscan)</a></span>
                </div>
            </div>
            <div className="footer">
                <label for="checkbox" onClick={(e) => {
                    e.preventDefault()
                    setIsAgree(!isAgree)
                 }} >
                    <input type="checkbox" id='checkbox' checked={isAgree}/>
                    <i></i>
                    <span>I understand</span>
                </label>
                <Button
                    children='Continue'
                    black
                    style={{ width: 152 }}
                    onClick={
                        // console.log()
                        () => {
                            handelClickContinue()
                        }
                    }
                />
            </div>
        </JoinTipStyle>
    )
}
