import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { LedgerConnector } from "@web3-react/ledger-connector";
import { TrezorConnector } from "@web3-react/trezor-connector";
import { FrameConnector } from "@web3-react/frame-connector";
import { FortmaticConnector } from "@web3-react/fortmatic-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import { SquarelinkConnector } from "@web3-react/squarelink-connector";
import { TorusConnector } from "@web3-react/torus-connector";
import { AuthereumConnector } from "@web3-react/authereum-connector";
import {BscConnector} from "./BscConnector";

const POLLING_INTERVAL = 12000;
const RPC_URLS = {
    1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    4: "https://rinkeby.infura.io/v3/8f6d3d5d7a1442a38d9e8050c31c1884"
};

const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42, 97, 56, 79377087078960]
});

const bscConnector = new BscConnector({supportedChainIds: [97, 56]})

const walletconnect = new WalletConnectConnector({
    rpc: { 1: RPC_URLS[1] },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: POLLING_INTERVAL
});

const walletlink = new WalletLinkConnector({
    url: RPC_URLS[1],
    appName: "Bounce"
});

const ledger = new LedgerConnector({
    chainId: 1,
    url: RPC_URLS[1],
    pollingInterval: POLLING_INTERVAL
});

const trezor = new TrezorConnector({
    chainId: 1,
    url: RPC_URLS[1],
    pollingInterval: POLLING_INTERVAL,
    manifestEmail: "dummy@abc.xyz",
    manifestAppUrl: "https://8rg3h.csb.app/"
});

const frame = new FrameConnector({ supportedChainIds: [1] });

const fortmatic = new FortmaticConnector({
    apiKey: "pk_live_F95FEECB1BE324B5",
    chainId: 1
});

const portis = new PortisConnector({
    dAppId: "790d2f80-46b8-4475-baa8-d53a7efad388",
    networks: [1, 100]
});

const squarelink = new SquarelinkConnector({
    clientId: "5f2a2233db82b06b24f9",
    networks: [1, 100]
});

const torus = new TorusConnector({ chainId: 1 });

const authereum = new AuthereumConnector({ chainId: 1 });

// export const wallets = {
//     MetaMask: injected,
//     BinanceWallet: bscConnector,
//     //TrustWallet: injected,
//     WalletConnect: walletconnect,
//     WalletLink: walletlink,
//     Ledger: ledger,
//     Trezor: trezor,
//     Frame: frame,
//     //Fortmatic: fortmatic,
//     //Portis: portis,
//     //Squarelink: squarelink,
//     //Torus: torus,
//     //Authereum: authereum
// }

export const walletList = ()=>{
    return  {
        MetaMask: new InjectedConnector({
            supportedChainIds: [1, 3, 4, 5, 42, 97, 56, 79377087078960]
        }),
        BinanceWallet: new BscConnector({supportedChainIds: [97, 56]}),
        //TrustWallet: injected,
        WalletConnect: new WalletConnectConnector({
            rpc: { 1: RPC_URLS[1] },
            bridge: "https://bridge.walletconnect.org",
            qrcode: true,
            pollingInterval: POLLING_INTERVAL
        }),
        WalletLink: new WalletLinkConnector({
            url: RPC_URLS[1],
            appName: "Bounce"
        }),
        Ledger: new LedgerConnector({
            chainId: 1,
            url: RPC_URLS[1],
            pollingInterval: POLLING_INTERVAL
        }),
        Trezor: new TrezorConnector({
            chainId: 1,
            url: RPC_URLS[1],
            pollingInterval: POLLING_INTERVAL,
            manifestEmail: "dummy@abc.xyz",
            manifestAppUrl: "https://8rg3h.csb.app/"
        }),
        Frame: new FrameConnector({ supportedChainIds: [1] }),
        //Fortmatic: fortmatic,
        //Portis: portis,
        //Squarelink: squarelink,
        //Torus: torus,
        //Authereum: authereum
    }
}