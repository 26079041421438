import { useEffect, useState, useContext } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import { getSealedBidAddress } from "../../../web3/contractAddress";
import { queryPoolItem, queryERC20Token } from './Request_CT'
import { getPoolStatus, getParticipant, getSwapRatio } from './Request_FUN'

import SB_ERC20_ABI from '../../../web3/abi/bounceSealedBid.json'

const keyMap = [{
    key: 'creatorP',
    name: 'creator',
    needTrans: false,
}, {
    key: 'nameP',
    name: 'name',
    needTrans: false,
}, {
    key: 'token0P',
    name: 'fromAddress',
    needTrans: false,
}, {
    key: 'token1P',
    name: 'toAddress',
    needTrans: false,
}, {
    key: 'passwordP',
    name: 'password',
    needTrans: false,
}, {
    key: 'amountTotal0P',
    name: 'fromAmount',
    needTrans: false,
}, {
    key: 'amountMin1P',
    name: 'toAmount',
    needTrans: true,
}, {
    key: 'closeAtP',
    name: 'time',
}, {
    key: 'onlyBotHolder',
    name: 'onlyBot',
    needTrans: false
}, {
    key: 'minEthBidP',
    name: 'minEthPerWallet',
    needTrans: true
}];

export const useSB_List_ERC20 = (queryListArr = []) => {
    const { active, library, chainId } = useActiveWeb3React()
    const [poolsDate, setPoolsDate] = useState([])
    const [isLoad, setIsLoad] = useState(true)


    useEffect(() => {
        if (!active) return
        setIsLoad(true)
        console.log('A_console_queryListArr', queryListArr)
        queryPoolDate(queryListArr)
    }, [active, queryListArr, chainId])

    const queryPoolDate = async (queryListArr) => {

        const SB_ERC20_CT = getContract(library, SB_ERC20_ABI.abi, getSealedBidAddress(chainId))

        const dataList = await Promise.all(queryListArr.map(async (poolID) => {
            const poolItemInfo = await queryPoolItem(SB_ERC20_CT, poolID, keyMap)

            // 通过获取到的池子信息筛选出列表需要的信息
            /**
             * 1. 状态          √
             * 2. poolID        √
             * 3. 池子名称      √
             * 4. 合约地址      √   
             * 5. 交易对        √
             * 6. 价格          - ETH的当前价格 * 参数
             * 7. Swap Ratio    - 
             * 8. 池子类型      √
             * 9. 剩余进度  
             * *、创建者       √
             */
            const poolItemData = {
                poolID: poolItemInfo.poolID,
                status: getPoolStatus(poolItemInfo.time),
                progress: '100%',
                poolName: poolItemInfo.name,
                fromToken: await queryERC20Token(library, poolItemInfo.fromAddress, chainId),
                toToken: await queryERC20Token(library, poolItemInfo.toAddress, chainId),
                Participant: getParticipant(poolItemInfo.password, poolItemInfo.onlyBot),
                creator: poolItemInfo.creator
            }
            poolItemData.swapRatio = getSwapRatio(poolItemInfo.fromAmount, poolItemInfo.toAmount, poolItemData.fromToken.decimals, poolItemData.toToken.decimals)
            poolItemData.currentPrice = poolItemData.swapRatio === '<0.0001' ? 0.0001 : parseFloat(poolItemData.swapRatio)
            return poolItemData
        }))

        setPoolsDate(dataList)
        setIsLoad(false)
    }

    return { poolsDate, isLoad }
}
