import React from 'react';
import styled, { css } from 'styled-components';
import { animated, useTransition, useSpring } from 'react-spring';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { isMobile } from 'react-device-detect';
import { useGesture } from 'react-use-gesture';
import { transparentize } from 'polished';
import '@reach/dialog/styles.css';
import icon_close from '../../static/image/close.svg';
import logo from '../../static/image/logo.svg';
import { useHistory } from 'react-router-dom';

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
  &[data-reach-dialog-overlay] {
    z-index: 9;
    background-color: transparent;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    top:142px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`
const StyledDialogContent = styled(({ minHeight, maxHeight, mobile, isOpen, ...rest }) => (
    <AnimatedDialogContent {...rest} />
)).attrs({
    'aria-label': 'dialog'
})`
  overflow-y: ${({ mobile }) => (mobile ? 'scroll!important' : 'hidden')};

  &[data-reach-dialog-content] {
    margin: 0 0 2rem 0;
    background-color: ${({ theme }) => theme.bg1};
    box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.95, '#2F80ED')};
    padding: 0px;
    width: 100vw;
    overflow-y: ${({ mobile }) => (mobile ? 'scroll!important' : 'hidden')};
    overflow-x: hidden;
    
    align-self: 'center';
    @media (max-width: 767px) {
      height: calc(80vh - 140px);
    }
    max-width: 420px;
    ${({ maxHeight }) =>
    maxHeight &&
    css`
        max-height: ${maxHeight}vh;
      `}
    ${({ minHeight }) =>
    minHeight &&
    css`
        min-height: ${minHeight}vh;
      `}
    display: flex;
    flex-direction: column;
    text-align: center;
    height:90vh;
    align-items: center;
    padding-bottom: 47px;
    padding-top: 50px;
    overflow-y:scroll !important;
  }
`
const Logo = styled.img`
  width: 18px;
  cursor: pointer;
  @media (max-width: 767px) {
    margin-left: 22px;
  }
`

export const ModalMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ModalTitle = styled.span`
  font-family: Optima;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  border-bottom: 4px solid #000000;
  width: 320px;
  padding-bottom: 20px;
  padding-top: 14px;
  text-align: left;
`
export const ModalIcon = styled.span`
  width: 320px;
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CloseIcon = styled.img`
  width: 16px;
  margin: 0 26px 0 0;
  align-self: flex-end;
  cursor: pointer;
`
export const ModalContent = styled.span`
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  width: 320px;
  margin-top: 20px;
  margin-bottom: 27px;
  text-align: left;
`
const DialogHeader = styled.div`
  display:flex;
  justify-content: space-between;
  width: 100%;
`

export default function SearchModal({
    isOpen,
    onDismiss,
    minHeight = false,
    maxHeight = 90,
    initialFocusRef,
    closeable,
    children
}){
  const fadeTransition = useTransition(isOpen, null, {
      config: { duration: 200 },
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 }
  })

  const history = useHistory()

  const [{ y }, set] = useSpring(() => ({ y: 0, config: { mass: 1, tension: 210, friction: 20 } }))
  const bind = useGesture({
    onDrag: state => {
        set({
            y: state.down ? state.movement[1] : 0
        })
        if (state.movement[1] > 300 || (state.velocity > 3 && state.direction[1] > 0)) {
            onDismiss()
        }
    }
  })

  return (
    <>
      {fadeTransition.map(
        ({ item, key, props }) =>
          item && (
            <StyledDialogOverlay key={key} style={props} onDismiss={onDismiss} initialFocusRef={initialFocusRef}>
                <StyledDialogContent
                    style={{paddingTop: 26}}
                    {...(isMobile
                        ? {
                            ...bind(),
                            style: {transform: y.interpolate(y => `translateY(${y > 0 ? y : 0}px)`) }
                        }
                        : {})}
                    aria-label="dialog content"
                    minHeight={minHeight}
                    maxHeight={maxHeight}
                    mobile={isMobile}
                >
                    {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
                    {children}
                </StyledDialogContent>
            </StyledDialogOverlay>
          )
      )}
    </>
  )
}