import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import BounceFSNFT from "../../../../web3/abi/BounceFSNFT.json";
import bounceEnglishAuctionNFT from "../../../../web3/abi/bounceEnglishAuctionNFT.json";
import bounceERC1155 from "../../../../web3/abi/bounceERC1155";

import { getContract, useActiveWeb3React } from "../../../../web3";
import {
    getBounceAlpacasNFTAddress, getBounceFactoryAddress
} from "../../../../web3/contractAddress";
import { getIndexList, queryData } from "../../../../web3/common";
import { englishAuctionKeyMap, getContractLink } from "../../../../Content/const";
import Web3 from "web3";
import axios from "axios";

import bounceFactory from '../../../../components/utils/bounceFactory.json'
import default_img from '../../../../static/image/rect-logo.svg'


export const keyMap = [{
    key: 'creatorP',
    name: 'creator',
    needTrans: false,
}, {
    key: 'nameP',
    name: 'name',
    needTrans: false,
}, {
    key: 'token0P',
    name: 'fromAddress',
    needTrans: false,
}, {
    key: 'passwordP',
    name: 'password',
    needTrans: false,
}, {
    key: 'amountTotal0P',
    name: 'fromAmount',
    needTrans: false,
}, {
    key: 'amountMin1P',
    name: 'toAmount',
    needTrans: true,
}, {
    key: 'closeAtP',
    name: 'time',
}, {
    key: 'onlyBotHolder',
    name: 'onlyBot',
    needTrans: false
}, {
    key: 'minEthBidP',
    name: 'minEthPerWallet',
    needTrans: true
}];


export const useFSNFTList = (proxy, poolType) => {
    console.log('E_console', poolType)
    const { storeID } = useParams()
    const [loading, setLoading] = useState(true)
    const [poolList, setPoolList] = useState()
    const [loaded, setLoaded] = useState(true)

    const { active, account, library, chainId } = useActiveWeb3React();

    const queryPoolItem = async (library, bounceContract, index) => {
        let data = await queryData(bounceContract, 'pools', index);
        console.log('querySBNFTAuctionPoolItem:', data)
        data = JSON.parse(JSON.stringify(data));
        data.index = index;
        englishAuctionKeyMap.forEach(item => {
            data[item.name] = data[item.key]
        })

        try {
            if (data.nftType === '0') {

            } else {
                const bounceERC1155_CT = getContract(library, bounceERC1155.abi, data.token0)
                const uri = await bounceERC1155_CT.methods.uri(index).call()

                const res = await axios.get(uri)
                data.cover = res.data.properties.image.description
            }

        } catch (e) {
            data.cover = default_img
        }

        data['fromSymbol'] = 'NFT1155';

        // data.type = 'englishAuction';
        let status = 'Closed';
        const isExpired = new Date(data.closeAt * 1000) <= Date.now();
        data.password = await queryData(bounceContract, 'passwordP', index);
        if (!isExpired) {
            status = 'Live';
        } else {
            status = 'Closed';
        }
        data.status = status
        return data;
    };


    useEffect(() => {
        // console.log('E_console', proxy, active)
        if (!proxy || !active) return

        async function queryEANFTPollList() {
            setLoaded(true)
            try {
                let noConnect = {};
                let networkID = 1
                if (!active) {
                    const web3 = await new Web3(Web3.givenProvider);
                    noConnect.provider = web3.eth.currentProvider
                    networkID = await web3.eth.getChainId();
                }
                // const baseInfo = await initBaseInfo()
                const storeAdress = proxy
                const contract_ABI = poolType === 'EA' ? bounceEnglishAuctionNFT : BounceFSNFT
                const curLibrary = active ? library : noConnect
                const bounceContract = getContract(curLibrary, contract_ABI.abi, storeAdress)
                let poolCount = await bounceContract.methods.getPoolCount().call()
                // console.log('E_console', poolCount, storeAdress)

                poolCount = parseInt(poolCount)

                let poolIdList = [];
                for (let i = 0; i < poolCount; i++) {
                    poolIdList[i] = poolCount - i - 1;
                }

                let i = 0;
                let poolList;
                let queryIdList;
                let allPools = [];

                if (poolCount === 0) {
                    return setPoolList(allPools)
                }

                while (poolIdList.length !== 0) {
                    queryIdList = poolIdList.slice(0, 15);
                    poolIdList.splice(0, 15);
                    poolList = await Promise.all(queryIdList.map(async (item) => {
                        const pool = await queryPoolItem(curLibrary, bounceContract, item);
                        if (pool.fromAddress) {
                            pool.address = await getContractLink(pool.fromAddress)
                        }
                        console.log('pool', pool)
                        pool.name = pool.name ? pool.name.replace(/0x[0-9a-fA-F]{40}$/, '') : pool.name
                        return pool
                    }));
                    allPools = allPools.concat(poolList)
                    setLoading(false)
                    console.log('EA POOL LIST', allPools)
                    setPoolList(allPools);
                    i++
                }

            } catch (e) {
                console.log('E_console', e)
            }

        }

        if (!loaded && active) {
            console.log('load store data')
            queryEANFTPollList()
        } else {
            if (proxy) {
                queryEANFTPollList()
            }
        }
    }, [loaded, library, proxy])



    return { loading, poolList, setLoaded }
}