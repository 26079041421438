import {useEffect, useState} from "react";
import {getContract, useActiveWeb3React} from "../../web3";
import bounceERC20 from "../../web3/abi/bounceERC20.json";



export const useAddress = () => {
    const {active, account, library, chainId} = useActiveWeb3React();

    const [address, setAddress] = useState()
    const [addressError, setAddressError] = useState()
    const [fromBalance, setFromBalance] = useState('0')
    const [fromSymbol, setFromSymbol] = useState()
    const [fromDecimals, setFromDecimals] = useState()




    function getTokenInfo() {
        let  tokenContract
        setAddressError(null)
        try {
            tokenContract = getContract(library, bounceERC20.abi, address)
        }catch (e) {
            console.log('')
            setAddressError('Address is invalid')
        }

        try {
            tokenContract.methods.balanceOf(account).call().then(res =>{
                setFromBalance(res)
                console.log('token balance', res)
            })
        }catch (e) {
            console.log('')
            setAddressError('Address is invalid')
        }

        try {
            tokenContract.methods.symbol().call().then(res =>{
                console.log('token symbol', res)
                setFromSymbol(res)
            })
        }catch (e) {
            console.log('')
            setAddressError('Address is invalid')
        }


        try {
             tokenContract.methods.decimals().call().then(res =>{
                 console.log('token decimals', res)
                 setFromDecimals(res)
            })
        }catch (e) {
            console.log('')
            setAddressError('Address is invalid')
        }
    }

    useEffect(()=>{
        if(active && address){
            getTokenInfo()
        }
    },[active, address])


    return {fromBalance, fromDecimals, fromSymbol, setAddress,  address, addressError}
}