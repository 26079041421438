import React, { useState } from 'react';
import { Pool, PoolFrame } from "../../components/common/Layout";
import icon_close from '../../static/image/icon-close.svg'
import { Form, Input } from "../../components/common/Form";
import { useParams } from 'react-router-dom';
import { useHandleForm, usePredictTokenList } from '../../web3/common'
import { Button } from "../../components/common/Button";
import { useHistory } from 'react-router-dom'
import { getTime } from "../../utils/common";
import { getContract, useActiveWeb3React } from "../../web3";

import {
  getBouncePredictionAddress,
} from "../../web3/contractAddress";
import BouncePredict from "../../web3/abi/BouncePredict.json";
import { useIsXSDown } from '../../components/utils/themeHooks';

import {
  CreateModal,
  initStatus,
  pendingStatus,
  confirmStatus,
  errorStatus,
  cancelStatus, predictionSuccessStatus
} from "../../components/common/CreateModal";
import { Select, Select_Tabbar } from "../../components/common/Select";
import { Message } from "../../components/common/message";


export const CreatePredictionPool = () => {
  const history = useHistory()
  const { type } = useParams();
  const tokenOptions = usePredictTokenList()
  const isXSDown = useIsXSDown();
  const { account, library, chainId } = useActiveWeb3React();
  const [modalStatus, setModalStatus] = useState(initStatus)
  const [name, setName] = useState()
  const [days, setDays] = useState()
  const [selectedToken, setSelectedToken] = useState()



  const {
    timeError,
  } = useHandleForm()

  const handleChange = async event => {
    event.preventDefault();
    const { name, value } = event.target;

    switch (name) {
      case "name":
        setName(value)
        break;
      case 'days':
        let days = value.replace(/^0(0+)|[^\d]+/g, '')
        if (days > 30) {
          days = 30
        }
        setDays(days)
        break
      default:
    }

  };


  const handleSubmit = async (event) => {
    console.log('selectedToken.key', selectedToken.key)
    event.preventDefault();
    const bounceContract = getContract(library, BouncePredict.abi, getBouncePredictionAddress(chainId))
    const time = getTime(days, 0, 0);
    try {
      setModalStatus(confirmStatus);
      await bounceContract.methods.create(
        name,
        selectedToken.key,
        time,
      )
        .send({ from: account })
        .on('transactionHash', hash => {
          setModalStatus(pendingStatus)
        })
        .on('receipt', (_, receipt) => {
          setModalStatus(predictionSuccessStatus)
        })
        .on('error', (err, receipt) => {
          setModalStatus(errorStatus)
        })
    } catch (err) {
      if (err.code === 4001) {
        setModalStatus(cancelStatus)
      } else {
        setModalStatus(errorStatus)
      }
      console.log('err', err);
    }
  };


  return (
    <form id='pool-create' onSubmit={handleSubmit}>
      <PoolFrame style={{ marginTop: 32, padding: isXSDown ? '40px 20px' : '40px 100px' }}>
        <Pool.Close onClick={() => {
          history.push('/store/detail/nft-fixed-swap')
        }} src={icon_close} />
        <Pool>
          <Pool.Mode style={{ textAlign: 'left' }}>Predictions</Pool.Mode>
          <Pool.Header style={{
            justifyContent: 'flex-start',
            borderBottom: '4px #000 solid',
            fontSize: isXSDown ? '26px' : '36px',
          }}>Create a Prediction Pool</Pool.Header>

          <Pool.Frame style={{
            flexDirection: isXSDown ? 'column' : 'initial',
          }}>
            <div style={{ width: isXSDown ? '100%' : 480, height: '100%' }}>
              Pool settings
              </div>
            <Pool.Content width={isXSDown ? '100%' : '480px'}>

              <Form name={'To'}
                width={'100%'}
                input={<Select_Tabbar
                  width={'480px'}
                  defaultContent={'Select the asset you want to predict or Paste Token address'}
                  // disabled={type !== 'fixed-swap' && type !== 'sealed-bid'}
                  border
                  options={tokenOptions} onSelect={(value) => {
                    setSelectedToken(value)
                  }} />} />


              <Form top={'38px'} input={<Input
                required
                name={'name'}
                maxLength={15}
                onBlur={handleChange}
              />} name={'Pool Name'} />

              <Form top={'49px'} error={timeError}
                name={<span style={{ color: '#000', marginBottom: 9 }}>Prediction time period</span>} hidden
                prefix={(<div>
                  <Pool.Content width={isXSDown ? '100%' : '480px'}>
                    <Form input={<Input
                      required
                      name={'days'}
                      value={days}
                      onChange={handleChange}
                      type='number' />} name={'Days'} width={isXSDown ? '30%' : '100%'} />
                  </Pool.Content>
                </div>)} />

              <Form top={'38px'} disabled hidden prefix={(<div
                style={{ width: isXSDown ? '100%' : '480px' }}>
                <Button
                  disabled={selectedToken ? false : true}
                  type="submit" form="pool-create" style={{ marginTop: 12 }} black
                  width={isXSDown ? '100%' : '480px'}>Launch</Button>
                {type === 'dutch-auction-nft' ? <p style={{
                  fontFamily: 'Helvetica Neue',
                  fontSize: 12,
                  lineHeight: '16.8px',
                  color: 'rgba(0, 0, 0, .4)',
                  marginTop: 12,
                  textAlign: "left"
                }}>
                  Bounce contract does not accept Inflationary and deflationary tokens, please don’t create a pool
                  with
                  special token forms.
                  </p> : ''}
              </div>)} />
            </Pool.Content>
          </Pool.Frame>
        </Pool>

        <CreateModal onOK={() => {
          setModalStatus(initStatus)
          history.push('/')
        }} onDismiss={() => {
          setModalStatus(initStatus)
        }} modalStatus={modalStatus} />

      </PoolFrame>
    </form>
  )
}
