import React, { useState, useCallback, useEffect, useContext } from 'react';
import {
    Address, Cover,
    ITextR,
    LayoutFrame, NFTInfo,
    OText2, OText3,
    OText5,
    Pool,
    renderTime
} from "../../components/common/Layout";
import icon_return from '../../static/image/icon-return.svg'
import { usePoolDetail } from "./Hooks";
import { useHistory } from "react-router-dom";
import { getProgress, weiDiv, weiToNumber } from "../../utils/numberTransform";
import classNames from "classnames";
import { useParams } from 'react-router-dom';
import { Form, FormStatus, Input } from "../../components/common/Form";
import icon_max from "../../static/image/icon-max.svg";
import { Button } from "../../components/common/Button";
import { useEthBalance } from "../../web3/common";
import { getContract, useActiveWeb3React } from "../../web3";
import dutchAuctionNFT from "../../web3/abi/bounceDutchAuctionNFT.json";
import rect_logo from '../../static/image/rect-logo.svg'
import { getDutchAuction721, getDutchAuction1155 } from "../../web3/contractAddress";
import Web3 from 'web3'
import { useIsXSDown } from '../../components/utils/themeHooks';
import {
    BidModal,
    initStatus,
    errorStatus,
    successStatus,
    confirmStatus,
    pendingStatus,
    cancelStatus
} from "../../components/common/BidModal";
import { useLeftTime } from "../../hooks/useLeftTime";
import { PoolCover } from "../../components/DetailCover";
import { isGreaterThan } from "../../utils/common";
import BigNumber from 'bignumber.js';
import { TipLink } from "../../components/common/TipLink";

const { toWei, fromWei } = Web3.utils


export const DANFTPoolDetail = () => {

    const { id } = useParams();
    const { active, account, library, chainId } = useActiveWeb3React()
    const { setTime, leftTime } = useLeftTime()
    const [bidAmount, setBidAmount] = useState()
    const [bidStatus, setBidStatus] = useState(initStatus)
    const history = useHistory()
    const isXSDown = useIsXSDown();
    const [dutchNFTType] = useState(window.localStorage.getItem('dutchNFTType'))

    const {
        name, address, startPrice, reservePrice, status, NFT, round, currentPrice, limit, curBiddenAmount, bidden,isClaim,
        symbol, floor, password, time, totalBid, isMine, claimButtonText, myBid, joined, tokenId, winnerAmount, amountMinIncr, setJoined, setIsMine
    } = usePoolDetail(id)
    console.log('DANFTPoolDetail', usePoolDetail(id))
    const { ethBalance } = useEthBalance()

    let timer = null
    useEffect(() => {
        timer = setInterval(() => {
            console.log('set time', time)
            const date = new Date(time * 1000);
            const now = new Date();
            const lefttime = date - now;
            if (lefttime > 0) {
                setTime(lefttime)
            } else {
                clearInterval(timer)
            }
        }, (1000));
        return () => {
            clearInterval(timer)
        }
    }, [setTime, time]);


    const onBid = async () => {
        const contractAddress = dutchNFTType === '1155' ? getDutchAuction1155(chainId) : getDutchAuction721(chainId)
        const bounceContract = getContract(library, dutchAuctionNFT.abi, contractAddress)
        // const bidToAmount = toWei(currentPrice, 'ether');
        const bidToAmount = currentPrice
        setBidStatus(confirmStatus);
        try {
            bounceContract.methods.bid(id, password)
                .send({ from: account, value: bidToAmount })
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setJoined(true)

                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid error', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }


    const onClaim = async () => {
        const contractAddress = dutchNFTType === '1155' ? getDutchAuction1155(chainId) : getDutchAuction721(chainId)
        const bounceContract = getContract(library, dutchAuctionNFT.abi, contractAddress)
        const claimFunc = isMine ? 'creatorClaim' : 'bidderClaim'
        setBidStatus(confirmStatus);
        try {
            bounceContract.methods[claimFunc](id)
                .send({ from: account })
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }

    return (
        <LayoutFrame style={{ marginTop: 27 }}>
            <Pool.Return src={icon_return} onClick={() => {
                history.goBack()
            }} />
            <LayoutFrame width={'1181px'} style={{ padding: '40px 0', margin: 'auto', marginTop: 32 }}>
                <Pool.Mode>NFT dutch auction</Pool.Mode>
                <Pool.Header style={{ justifyContent: 'center' }}><span>{name}</span></Pool.Header>
                <Address style={{ wordBreak: isXSDown ? 'break-all' : 'normal', marginBottom: 40 }}>{address}</Address>
                {!isXSDown &&
                    <Pool.Content>
                        <Pool.Content width={'272px'} style={{ marginTop: 0, height: 'fit-content' }}>

                            <Pool.Content width={'456px'} style={{ marginTop: 0, flexDirection: 'column' }}>
                                <Pool.Status style={{ width: 'fit-content' }}
                                    className={classNames('status', status)}>• {status}</Pool.Status>

                                <ITextR style={{ marginTop: 8, textAlign: 'left' }}>{'Participant: Public'}</ITextR>
                            </Pool.Content>

                            <Pool.Block style={{ height: 100, padding: '14px 0' }}>
                                <span>Starting Price</span>
                                <span>{startPrice && `1 BOT = ${weiToNumber(startPrice)} ETH`}</span>
                            </Pool.Block>

                            <Pool.Block style={{ height: 100, padding: '14px 0' }}>
                                <span>Reserve Price</span>
                                <span>{reservePrice && `1 BOT = ${weiToNumber(reservePrice)} ETH`}</span>
                            </Pool.Block>

                            <Pool.Block style={{ height: 100, padding: '14px 0' }}>
                                <span>Current Price</span>
                                <span>{currentPrice && `1 BOT = ${weiToNumber(currentPrice)} ETH`}</span>
                            </Pool.Block>

                        </Pool.Content>

                        <Pool.Content width={'512px'} style={{ marginTop: 0 }}>
                            <PoolCover cover={NFT && (NFT.image ? NFT.image.replace(/ipfs:\/\//, 'https://ipfs.io/') : rect_logo)} />
                        </Pool.Content>

                        <Pool.Content width={'272px'} heigth={'502px'} style={{ marginTop: 0 }}>
                            <NFTInfo>
                                <NFTInfo.Artist>{NFT && NFT.name}</NFTInfo.Artist>
                                <NFTInfo.Title>{NFT && NFT.title}</NFTInfo.Title>
                                <NFTInfo.Desc>{NFT && NFT.description}</NFTInfo.Desc>
                            </NFTInfo>

                            {renderTime(leftTime)}

                        </Pool.Content>

                        <Pool.Content width={'1181px'}
                            style={{
                                flexDirection: 'column',
                                padding: 56,
                                alignItems: 'center',
                                marginTop: 100,

                                backgroundColor: 'rgba(248, 248, 251, 1)'
                            }}>
                            {(!isMine && !joined && status === 'Live') && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>Join The Pool</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round / Total Round</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{round && round} / {limit && parseInt(limit) + 1}</OText2>
                                        </Pool.Meta>

                                        <Pool.MetaDivider />

                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Current Price</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{`${currentPrice && fromWei(currentPrice)} ETH`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>
                                    {/* <Form disabled top={'49px'} width={'320px'} input={<Input
                                        placeholder={'Bid Amount'}
                                        type='number'
                                        value={bidAmount}

                                        onBlur={() => {
                                            if (bidAmount && ethBalance && isGreaterThan(toWei(bidAmount), ethBalance)) {
                                                setBidAmount(weiToNumber(ethBalance))
                                            }
                                        }}
                                        onChange={(e) => {
                                            setBidAmount(e.target.value.replace(/[^\d.]/g, ''))
                                        }}
                                    />} name={'Your Bid Amount '} addonAfter={(<img onClick={() => {
                                        console.log('set max amount', ethBalance)
                                        setBidAmount(fromWei(ethBalance))
                                    }} src={icon_max} />)}
                                        extra={<span
                                            style={{ top: 0 }}>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>} /> */}
                                    <Button black width={'320px'} onClick={onBid}>Go</Button>
                                    <TipLink />
                                </>
                            )}

                            {(!isMine && (joined || status === 'Closed')) && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>You Joined</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round / Total Round</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{round && round} / {limit && parseInt(limit) + 1}</OText2>
                                        </Pool.Meta>

                                        <Pool.MetaDivider />

                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Your bid amount:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{`${(account === bidden) && curBiddenAmount ? fromWei(curBiddenAmount) : 0} ETH`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>
                                    {<Button disabled black width={'320px'}>This Pool Is Closed</Button>}
                                </>
                            )}

                            {(isMine) && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>My Pool</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round / Total Round</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{round && round} / {limit && parseInt(limit) + 1}</OText2>
                                        </Pool.Meta>
                                        <Pool.MetaDivider />
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Current Auction Price:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{currentPrice && `${weiToNumber(currentPrice)} ETH`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>

                                    {claimButtonText && <Button black width={'320px'} onClick={onClaim}>{claimButtonText}</Button>}
                                </>
                            )}

                        </Pool.Content>
                    </Pool.Content>
                }
                {isXSDown &&
                    <Pool.Content>
                        <Pool.Content width={'100%'} style={{ marginTop: 0, height: 'fit-content' }}>
                            <Pool.Content width={'456px'} style={{ marginTop: 0, flexDirection: 'column', padding: '0 20px' }}>
                                <Pool.Status style={{ width: 'fit-content' }}
                                    className={classNames('status', status)}>• {status}</Pool.Status>

                                <ITextR style={{ marginTop: 8, textAlign: 'left' }}>{'Participant: Public'}</ITextR>
                            </Pool.Content>

                            <Pool.Block style={{ width: '100%', alignItems: "flex-start", marginLeft: "20px", marginRight: "20px", height: '100px' }}>
                                <span>Starting Price</span>
                                <span>{startPrice && `${weiToNumber(startPrice)} ETH`}</span>
                            </Pool.Block>

                            <Pool.Block style={{ width: '100%', alignItems: "flex-start", marginLeft: "20px", marginRight: "20px", height: '100px' }}>
                                <span>Minimal Bid price increment:</span>
                                <span>{amountMinIncr && weiToNumber(amountMinIncr)}</span>
                            </Pool.Block>

                            <Pool.Block style={{ width: '100%', alignItems: "flex-start", marginLeft: "20px", marginRight: "20px", height: '100px' }}>
                                <span>Current Highest Bid: </span>
                                <span>{curBiddenAmount && weiToNumber(curBiddenAmount)}</span>
                            </Pool.Block>

                        </Pool.Content>

                        <Pool.Content width={'512px'} style={{ marginTop: 0, padding: '0 20px' }}>
                            <PoolCover cover={NFT && (NFT.image ? NFT.image.replace(/ipfs:\/\//, 'https://ipfs.io/') : rect_logo)} />
                        </Pool.Content>

                        <Pool.Content width={'100%'} heigth={'502px'} style={{ marginTop: 0, padding: '0 20px' }}>
                            <NFTInfo>
                                <NFTInfo.Artist>{NFT && NFT.name}</NFTInfo.Artist>
                                <NFTInfo.Title>{NFT && NFT.title}</NFTInfo.Title>
                                <NFTInfo.Desc>{NFT && NFT.description}</NFTInfo.Desc>
                            </NFTInfo>
                            <div style={{ fontSize: '12px', color: '#1F191B', opacity: '0.5', width: '100%', 'textAlign': 'left', marginBottom: '-20px' }}>Time left</div>
                            {renderTime(leftTime)}

                        </Pool.Content>

                        <Pool.Content width={'1181px'}
                            style={{ height: 'fit-content', flexDirection: 'column', padding: '40px 16px', justifyContent: 'center', margin: '40px 20px', backgroundColor: 'rgba(248, 248, 251, 1)' }}>
                            {(!isMine && !joined && status === 'Live') && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>Join The Pool</OText3>
                                    <Form disabled top={'49px'} width={'320px'} input={<Input
                                        placeholder={'Bid Amount'}
                                        type='number'
                                        value={bidAmount}

                                        onBlur={() => {
                                            if (bidAmount && ethBalance && isGreaterThan(toWei(bidAmount), ethBalance)) {
                                                setBidAmount(weiToNumber(ethBalance))
                                            }
                                        }}
                                        onChange={(e) => {
                                            setBidAmount(e.target.value.replace(/[^\d.]/g, ''))
                                        }}
                                    />} name={'Your Bid Amount '} addonAfter={(<img onClick={() => {
                                        console.log('set max amount', ethBalance)
                                        setBidAmount(fromWei(ethBalance))
                                    }} src={icon_max} />)}
                                        extra={<span
                                            style={{ top: 0 }}>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>} />
                                    <Button black width={'320px'} onClick={onBid}>Go</Button>
                                </>
                            )}

                            {(!isMine && (joined || status === 'Closed')) && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>You Joined</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round / Total Round</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{round && round} / {limit && parseInt(limit) + 1}</OText2>
                                        </Pool.Meta>

                                        <Pool.MetaDivider />

                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Your bid amount:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{`${myBid ? weiToNumber(myBid) : 0} ETH`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>
                                    {claimButtonText && <Button black width={'320px'} onClick={onClaim}>{claimButtonText}</Button>}

                                </>
                            )}

                            {(isMine) && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>My Pool</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round / Total Round</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{round && round} / {limit && parseInt(limit) + 1}</OText2>
                                        </Pool.Meta>
                                        <Pool.MetaDivider />
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Current Auction Price:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{currentPrice && `${weiToNumber(currentPrice)} ETH`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>

                                    {claimButtonText && <Button black width={'320px'} onClick={onClaim}>{claimButtonText}</Button>}
                                </>
                            )}

                        </Pool.Content>
                    </Pool.Content>
                }



            </LayoutFrame>

            {/*{fromBidAmount && fromAmount && (*/}
            {/*    <Progress height={'8px'} className={classNames('progress', status)}>*/}
            {/*        <Progress.Value style={{width: `${getProgress(fromBidAmount, fromAmount)}%`}}*/}
            {/*                        className={classNames('progress-value', status)}/>*/}
            {/*    </Progress>*/}
            {/*)}*/}


            <BidModal modalStatus={bidStatus} onDismiss={() => {
                setBidStatus(initStatus)
            }} />
        </LayoutFrame>
    )
}
