import Modal, {ModalContent, ModalTitle} from "./Modal";
import Lottie from "react-lottie";
import {FormStatus} from "./Form";
import icon_wait from "../../static/image/icon-wait.svg";

import icon_success from "../../static/image/success.svg";
import icon_error from "../../static/image/icon-error.svg";
import {Button} from "./Button";
import React from "react";
import bounce_loading from "../../static/bounce-loading.json";


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bounce_loading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const confirmStatus = {status: 1, title: 'Submitting your proposal', content: 'Please confirm your votes in metamask'}
export const pendingStatus = {status: 2, title: 'Waiting for confirmation…', content: 'Submitting your proposal'}
export const successStatus = {status: 3, title: 'Congratulations!', content: 'Submitting your vote decision'}
export const failStatus = {status: -1, title: 'Oops!', content: 'Something is wrong and please try again.'}

export const cancelStatus = {status: -2, title: 'Canceling your pool creation', content: 'You cancel your pool creation'}
export const initStatus = {status: 0, title: '', content: ''}



export const ProposeModal = ({modalStatus, onSuccess ,onDismiss}) =>{
    console.log('modalStatus',modalStatus)
    const {status, title, content}  = modalStatus

    return (
        <Modal isOpen={status !== 0} onDismiss={() => {
            onDismiss()
        }}>
            <ModalTitle style={{textAlign: 'center'}}>{title}</ModalTitle>

            {(status === 1 || status === 2) &&
            <Lottie width={200} height={200} options={defaultOptions}/>}

            {(status === 3) &&
            <FormStatus ><img onClick={onSuccess} src={icon_success}/></FormStatus>}

            {(status === -1 ) &&
            <FormStatus><img src={icon_error}/></FormStatus>}

            <ModalContent style={{width: 300, textAlign: 'center'}}>{content}</ModalContent>
            {(status === 1 || status === 2 ) && <Button width={'320px'} black>Awaiting...</Button>}
            {(status === 3 ) && <Button width={'320px'} black onClick={()=>{
                onDismiss()
            }}>Close</Button>}
            {(status === -1 || status === -2) && <Button onClick={()=>{onDismiss()}} width={'320px'} black>Try again</Button>}
        </Modal>
    )
}
