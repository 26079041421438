import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from "../../components/common/Button";
import tab_check from '../../static/image/checkbox.svg'


const Frame = styled.div`
  width: 480px;
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media (max-width: 767px) {
    width:100%;
    padding: 0 20px;
  }

  .subTitile{
    font-size: 16px;
  }
`
const Title = styled.div`
  font-family: Optima;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: #000;
  margin-bottom: 40px;
  text-align: center;
`
const Input = styled.input`
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.3);
  outline: none;
  width: 100%;
  font-weight: 500;
  padding: 5px 0 9px;
`

const InputFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 38px;

  .select_type{
      li{
          display: flex;
          align-items: center;
          margin-top: 15px;
          cursor: pointer;

          p{
            font-family: 'Helvetica Neue';
            font-size: 14px;
            font-weight: 500;
            color: #000;
            height: 17px;
            line-height: 17px;
          }

          &>div{
              width: 14px;
              height: 14px;
              margin-right: 8px;
              box-sizing: border-box;
              border: 1px solid #000;

              &.checked{
                background: url(${tab_check}) no-repeat;
                background-size: contain;
                background-position: 0 0;
              }
          }
      }
  }
`

Input.Name = styled.span`
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #1F191B;
  margin-bottom: 9px;
  display: flex;
  justify-content: space-between;
  opacity: .7;
`

const Form = styled.form`
  width: 100%;
`


export const Step2 = ({ next, visible }) => {

  const TypeArr = [{ key: 1, name: 'Fixed Swap NFT' }, { key: 2, name: 'English Auction' }]
  const [selectType, setSelectType] = useState([false, false, false])
  const [contract, setContract] = useState()
  const [refresh, setRefresh] = useState(0)

  const handleSubmit = (event) => {
    event.preventDefault();
    next({ storeType: selectType, contract })
  }

  const handleChange = async event => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "contract":
        setContract(value)
        break;
      default:
    }
  };

  const checkInput = (str) => {
    if (!str || String(str).trim().length === 0) return false
    return true
  }


  return (
    <Frame style={{ height: visible ? 'fit-content' : 0 }}>
      <Title>02. Tokens Types
        <p className='subTitile'>
          Please choose token types and add addresses
        </p>
      </Title>
      <Form id='project-step2' onSubmit={handleSubmit}>
        <InputFrame>
          <Input.Name>{`storefront picture`}</Input.Name>
          <ul className='select_type'>
            {TypeArr.map((item, index) => {
              return (<li key={index}>
                <div
                  className={selectType[item.key] === true ? 'checked' : ''}
                  onClick={() => {
                    let selectMap = selectType

                    selectMap[item.key] = !selectMap[item.key]
                    setSelectType(selectMap)
                    let count = 0
                    selectMap.forEach(item => {
                      if (item === true) {
                        count++
                      }
                    })
                    setRefresh(count)
                  }}
                ></div>
                <p>{item.name}</p>
              </li>)
            })}
          </ul>
        </InputFrame>
        <InputFrame>
          <Input.Name>{`Token Contact Address`}</Input.Name>
          <Input
            required
            name={'contract'}
            onChange={handleChange} />
        </InputFrame>

        <Button
          disabled={refresh > 0 && checkInput(contract) ? false : true}
          type="submit" form="project-step2" style={{ marginTop: 38 }} black>Next Step</Button>
      </Form>
    </Frame>
  )
}
