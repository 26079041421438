import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import icon_copy from "../../static/image/icon-copy.svg";
import styled from 'styled-components'

import Modal, { ModalContent, ModalIcon, ModalTitle } from "../../components/common/Modal";
import { Form, Input } from "../../components/common/Form";
import { Button } from "../../components/common/Button";
import { GiftBox } from '../../components/media'
import Pagination from '../../components/Pagination'

import icon_error from '../../static/image/icon-error.svg'
import icon_success from '../../static/image/icon-success.svg'
import icon_loading from '../../static/image/icon_loading.svg'

import { useMyNFTList } from '../../web3/ViewMyNFT'

export function ViewMyNFT() {
    const [inputVal, setInputVal] = useState(null)
    const { nftList, modalType, setModalType, setContractAdress } = useMyNFTList()

    const CardFrame = styled.div`
        display: flex;
        flex-wrap: wrap;
        width: 1280px;
        min-height: 70vh;
        margin: 35px auto;
        /* margin-top: -158px; */
        @media (max-width: 767px) {
            width: 100%;
        }

        ul.list_ul{
            display: flex;
            flex-wrap: wrap;
            @media (max-width: 767px) {
                padding-top: 20px;
            }

            li.list_li{
                width: 240px;
                height: 340px;
                box-sizing: border-box;
                background-color: #fff;
                padding: 15px 20px;
                margin-bottom: 32px;
                margin-right: 20px;
                &:nth-child(5n){
                    margin-right: 0;
                }
                @media (max-width: 767px) {
                    width: 100vw;
                }

                img{
                    width: 200px;
                    height: 200px;
                    margin-bottom: 10px;
                    @media (max-width: 767px) {
                        margin: 0 auto 10px;
                    }
                }
                @media (max-width: 767px) {
                    width:100%;
                    margin-left: 20px;
                    text-align: center;
                    svg{
                        width:70%!important;
                    }
                }

                div.list_div{
                    margin-bottom: 12px;
                    h5{
                        font-family: 'IBM Plex Mono';
                        font-size: 12px;
                        line-height: 15.6px;
                        color: rgba(0,0,0,.3);
                        margin-bottom: 8px;
                        @media (max-width: 767px) {
                            text-align: left;
                        }
                    }

                    p{
                        font-family: 'IBM Plex Mono';
                        font-size: 12px;
                        line-height: 15.6px;
                        color: '#1F191B';
                        display: flex;
                        align-items: center;

                        .copy_icon{
                            width: 12px;
                            height: 12px;
                            margin: 0;
                            margin-left: 5px;
                            opacity: 0.3;
                            cursor: pointer;
                            user-select: none;

                            &:hover{
                                opacity: 1;
                            }
                        }
                    }
                }

                button.add_btn{
                    display: block;
                    width: 100px;
                    height: 20px;
                    font-family: 'Helvetica Neue';
                    font-size: 17px;
                    font-weight: 500;
                    margin: 160px auto;

                    &:hover{
                        font-weight: bold;
                    }
                }
            }
        }
    `

    useEffect(() => {

    }, [])



    const omitAdress = (adress) => {
        if (adress.indexOf('0x') !== -1) {
            const start = adress.substr(0, 6)
            const end = adress.substr(-4)
            return `${start}...${end}`
        } else {
            return 'Illegal address'
        }
    }


    const renderModal = () => {
        switch (modalType) {
            case 'oops':
                return <Modal closeable isOpen onDismiss={() => {
                    setModalType('default')
                }}>
                    <ModalTitle style={{ textAlign: 'center' }}>Oops!</ModalTitle>
                    <ModalIcon><img src={icon_error} /></ModalIcon>
                    <ModalContent>Something Went wrong, please try again</ModalContent>
                    <div style={{ width: 320, display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => {
                            setModalType('default')
                        }} width={'154px'} >Cancel</Button>
                        <Button onClick={() => {
                            setModalType('inputContract')
                        }} black width={'154px'}>Try Again</Button>
                    </div>
                </Modal>
            case 'success':
                return <Modal closeable isOpen onDismiss={() => {
                    setModalType('default')
                }}>
                    <ModalTitle style={{ textAlign: 'center' }}>Success!</ModalTitle>
                    <ModalIcon style={{ marginTop: 20 }}><img src={icon_success} /></ModalIcon>
                    <ModalContent>your nFT was Added successfully</ModalContent>
                    <div style={{ width: 320, display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => {
                            setModalType('default')
                        }} width={'320px'} >Close</Button>
                    </div>
                </Modal>
            case 'loading':
                return <Modal closeable isOpen onDismiss={() => {
                    setModalType('default')
                }}>
                    <ModalTitle style={{ textAlign: 'center' }}>Adding A NFT</ModalTitle>
                    <ModalIcon><img src={icon_loading} /></ModalIcon>
                    <ModalContent>Please wait a Little</ModalContent>
                    <div style={{ width: 320, display: 'flex', justifyContent: 'space-between' }}>
                        <Button black onClick={() => {
                        }} width={'320px'} >Awaiting...</Button>
                    </div>
                </Modal>
            case 'inputContract':
                return <Modal closeable isOpen onDismiss={() => {
                    setModalType('default')
                }}>
                    <ModalTitle style={{ textAlign: 'left' }}>Please Fill Token Contact Address to Add a New NFT In NFT Inventory</ModalTitle>
                    <ModalContent>
                        <Form top={'38px'} width={320} input={<Input
                            onBlur={(e) => {
                                setInputVal(e.target.value)
                            }} />} name={'Token Contract address'} />
                    </ModalContent>
                    <div style={{ width: 320, display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => {
                            setModalType('default')
                        }} width={'154px'} >Cancel</Button>
                        <Button onClick={() => {
                            setContractAdress(inputVal)
                        }} black width={'154px'}>Confirm</Button>
                    </div>
                </Modal>
            default:
                return <></>
        }

    }


    const renderNFTList = () => {
        return <>
            <CardFrame>
                <ul className='list_ul'>
                    {nftList.map((item, index) => {
                        return <li className='list_li' key={item.tokenId}>
                            <img src={item.cover} alt="" />
                            <div className='list_div'>
                                <h5>Token Contract Address</h5>
                                <p>{omitAdress(item.adress)}
                                    <CopyToClipboard
                                        text={item.adress}
                                        onCopy={() => {
                                            // alert('copy success!')
                                        }}>
                                        <img className='copy_icon' src={icon_copy} alt="点击复制" />
                                    </CopyToClipboard>
                                </p>
                            </div>
                            <div className='list_div'>
                                <h5>Token ID</h5>
                                <p>{item.tokenId}
                                    <CopyToClipboard
                                        text={item.tokenId}
                                        onCopy={() => {
                                            // alert('copy success!')
                                        }}>
                                        <img className='copy_icon' src={icon_copy} alt="点击复制" />
                                    </CopyToClipboard>
                                </p>
                            </div>
                        </li>
                    })}
                    <li className='list_li'>
                        <button className='add_btn' onClick={() => {
                            setModalType('inputContract')
                        }}>+ Add New</button>
                    </li>
                    <li className='list_li'>
                        <div >
                            <GiftBox style={{ width: '100%' }} />
                        </div>
                        <p style={{ textAlign: "center", marginTop: 15 }}>
                            <a href="https://ygift.to/create-gift" target='_blank'>
                                Create a NFT Gift powered by ygift.to
                        </a>
                        </p>
                    </li>
                </ul>


                {/* <Pagination pagenum={1} setPagenum={() => { }} total={8} /> */}
            </CardFrame>
        </>
        // if (nftList.length !== 0) {
        //     return <>
        //         <CardFrame>
        //             <ul className='list_ul'>
        //                 {nftList.map((item, index) => {
        //                     return <li className='list_li' key={item.tokenId}>
        //                         <img src={item.cover} alt="" />
        //                         <div className='list_div'>
        //                             <h5>Token Contract Address</h5>
        //                             <p>{omitAdress(item.adress)}
        //                                 <CopyToClipboard
        //                                     text={item.adress}
        //                                     onCopy={() => {
        //                                         // alert('copy success!')
        //                                     }}>
        //                                     <img className='copy_icon' src={icon_copy} alt="点击复制" />
        //                                 </CopyToClipboard>
        //                             </p>
        //                         </div>
        //                         <div className='list_div'>
        //                             <h5>Token ID</h5>
        //                             <p>{item.tokenId}
        //                                 <CopyToClipboard
        //                                     text={item.tokenId}
        //                                     onCopy={() => {
        //                                         // alert('copy success!')
        //                                     }}>
        //                                     <img className='copy_icon' src={icon_copy} alt="点击复制" />
        //                                 </CopyToClipboard>
        //                             </p>
        //                         </div>
        //                     </li>
        //                 })}
        //                 <li className='list_li'>
        //                     <button className='add_btn' onClick={() => {
        //                         setModalType('inputContract')
        //                     }}>+ Add New</button>
        //                 </li>
        //                 <li className='list_li'>
        //                     <div >
        //                         <GiftBox style={{ width: '100%' }} />
        //                     </div>
        //                     <p style={{ textAlign: "center", marginTop: 15 }}>
        //                         <a href="https://lottiefiles.com/21251-gift-box" target='_blank'>
        //                             Create a NFT Gift powered by ygift.to
        //                         </a>
        //                     </p>
        //                 </li>
        //             </ul>


        //             {/* <Pagination pagenum={1} setPagenum={() => { }} total={8} /> */}
        //         </CardFrame>
        //     </>
        // } else {
        //     return <CardFrame>
        //         <div style={{
        //             fontFamily: 'IBM Plex Mono',
        //             fontSize: 16,
        //             marginTop: 60,
        //             color: '#FFFFFF',
        //             width: '100%',
        //             textAlign: "center"
        //         }}>You have no NFT</div>
        //     </CardFrame>
        // }

    }



    return (
        <>
            {renderNFTList()}
            {renderModal()}
        </>
    )
}
