import React, {useEffect, useContext} from 'react';
import {t} from '../utils/intl';
import {MetaMaskItem} from './MetaMaskItem';
import {WalletConnect} from './WalletConnect';
import WalletConnectProvider from "@walletconnect/web3-provider";
import {myContext} from '../reducer'
import Web3 from 'web3';
import {
    Web3ReactProvider,
} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";
import {Wallets} from "../components/wallet/Wallets";
import Modal from "../components/common/Modal";


export const ConnectContent = ({
                                   changeIsConnect,
                                   changeAccount,
                                   changeProvider
                               }) => {

    const {state, dispatch} = useContext(myContext);


    const connectMetaMask = async () => {
        const web3 = await new Web3(Web3.givenProvider);

        window.ethereum.request({method: 'eth_requestAccounts'})
            .then((accounts) => {
                console.log('load default account--->')
                dispatch({type: 'HANDLER_MY_ACCOUNT', myAccount: accounts[0]});
                dispatch({type: 'HANDLER_WEB3', web3});
                changeIsConnect(true)
            })
            .catch(() => {
                if (window.localStorage) {
                    const storage = window.localStorage;
                    storage.removeItem('CONNECT_TYPE');
                }
            });


        window.ethereum.on('networkChanged', function (network) {
            console.log('networkChanged', network)
            if (network == 79 || network == 56) {
                //window.location.href = 'http://bsc.bounce.finance';
            } else {
                //window.location.href = 'https://bounce.finance';
            }
        })

    };


    const connectWallet = async () => {
        try {
            let provider = new WalletConnectProvider({
                infuraId: "8F4158180A7AF2C0584808FF0DCF6A2DDE020EC60B8@AK"
            });

            const accounts = await provider.enable();
            if (accounts) {
                dispatch({type: 'HANDLER_MY_ACCOUNT', myAccount: accounts[0]});
                const web3 = new Web3(provider);
                dispatch({type: 'HANDLER_WEB3', web3});
                changeIsConnect(true)
            }

            provider.on("accountsChanged", (accounts) => {
                dispatch({type: 'HANDLER_MY_ACCOUNT', myAccount: accounts[0]});
                console.log('accountsChanged--->', accounts)
            });

            provider.on("chainChanged", (chainId) => {
                console.log('chainChanged----->', chainId);
            });

            provider.on("networkChanged", (networkId) => {
                console.log('chainChanged---->', networkId);
            });

            provider.on("open", () => {
                console.log("open---->");
            });

            provider.on("close", (code, reason) => {
                console.log(code, reason);

            });
        } catch (err) {
            console.log(err);

        }
    };


    useEffect(() => {
        if (window.localStorage) {
            const storage = window.localStorage;
            const connectType = storage['CONNECT_TYPE'];
            console.log('CONNECT_TYPE--->', connectType)
            switch (connectType) {
                case 'METAMASK':
                    connectMetaMask();
                    break;
                case 'WALLET':
                    connectWallet()
                    break
                default:
            }
        }
    }, []);

    function getLibrary(provider) {
        const library = new Web3Provider(provider);
        library.pollingInterval = 8000;
        return library;
    }


    return (
        <>
            <div className='content connect'>
                <div className='form'>
                    <div className='form-head'>
                        {t('header-button.connect-wallet')}
                    </div>
                    <div className='form-content'>
                        {/*<Modal isOpen={true}>*/}
                        {/*    <Web3ReactProvider getLibrary={getLibrary}>*/}
                        {/*        <Wallets/>*/}
                        {/*    </Web3ReactProvider>*/}
                        {/*</Modal>*/}

                        {/*<MetaMaskItem*/}
                        {/*    onIsConnectChange={changeIsConnect}*/}
                        {/*    onAccountChange={changeAccount}*/}
                        {/*/>*/}
                        {/*<WalletConnect*/}
                        {/*    onIsConnectChange={changeIsConnect}*/}
                        {/*    onAccountChange={changeAccount}*/}
                        {/*    onSetProvider={changeProvider}*/}
                        {/*/>*/}
                    </div>
                </div>
            </div>
        </>
    )
}
