import React, {useState, useCallback, useEffect, useContext} from 'react';
import {CreateEnglishAuctionForm} from './content/CreateEnglishAuctionForm';
import {WaitContent} from '../components/common/WaitContent';
import {DeniedContent} from './content/DeniedContent';
import { Pending } from './content/Pending';
import {getTransactionLink} from './const';
import {useHistory} from 'react-router-dom';
import {myContext} from '../reducer'
import './pool.scss';
import { Pool } from '../components/common/Layout';
import icon_close from '../static/image/icon-close.svg';


export const CreateEnglishAuctionContent = ({
                                      account,
                                      onReceiptChange,
                                      onTransactionStatusChange,
                                      symbol,
                                      decimals,
                                      onSymbolChange,
                                      onDecimalsChange,
                                    }) => {
  const {state, dispatch} = useContext(myContext);
  const history = useHistory();
  const [status, setStatus] = useState('init');
  const [hash, setHash] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [address, setAddress] = useState('');
  const [waitContent, setWaitContent] = useState(null);
  const [pendingContent, setPendingContent] = useState();


  const {web3, myAccount, curPoolType} = state;


  useEffect(() => {
    async function loadAddress() {
      const address = await getTransactionLink(hash)
      setAddress(address);
    }

    loadAddress()
  }, [hash]);

  useEffect(() => {
    if (status === 'success') {
      dispatch({type: 'HANDLER_HAS_NFT_ENGLISH_AUCTION_POOL', hasNFTEnglishAuctionPool: true});
    }
  }, [status]);

  const handleStatusChange = useCallback((status) => {
    setStatus(status);
    onTransactionStatusChange(status);
  }, [status, onTransactionStatusChange, address]);

  const handleClose = useCallback(() => {
    setStatus('init');
  }, [setStatus]);

  const handleAmountChange = useCallback((from, to) => {
    setFrom(from);
    setTo(to);
  }, [setFrom, setTo]);

  const handleReceiptChange = useCallback((receipt) => {
    onReceiptChange(receipt, from, to)
  }, [onReceiptChange, from, to]);

  const renderContent = useCallback(status => {
    if (status === 'init') {
      return <CreateEnglishAuctionForm
          account={myAccount}
          web3={web3}
          symbol={symbol}
          decimals={decimals}
          onSymbolChange={onSymbolChange}
          onDecimalsChange={onDecimalsChange}
          onReceiptChange={handleReceiptChange}
          onStatusChange={handleStatusChange}
          onChangeHash={setHash}
          onChangeAmount={handleAmountChange}
          onWaitContentChange={setWaitContent}
          onPendingContentChange={setPendingContent}

      />
    } else if ( status === 'waiting') {
      return  <WaitContent content={waitContent} />
    } else if ( status === 'pending') {
      return  <Pending content={pendingContent} link={address} />
    }else if (status === 'denied') {
      return <DeniedContent onClose={handleClose}/>
    } else if (status === 'success' || status === 'failed') {
      dispatch({type: 'HANDLER_HAS_BID_POOL', hasBidPool: true});
      history.push('/');
    }
  }, [handleStatusChange, setHash, handleAmountChange, history,
    from, to, hash, account, handleClose, handleReceiptChange,
    symbol, decimals, onSymbolChange, onDecimalsChange
  ]);

  return (
      <>
        <div className='content create-pool'>
          <Pool.Close src={icon_close} />
          <div className='form'>
            {renderContent(status)}
          </div>
        </div>
      </>
  )
}
