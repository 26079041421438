import React, {useState} from 'react';
import { fade, makeStyles } from '@material-ui/core';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { useHistory } from 'react-router-dom';
import Web3 from 'web3'
import { Timing } from './timing';
import {ProposeModal, failStatus, cancelStatus, successStatus, pendingStatus, confirmStatus, initStatus} from "../../../components/common/ProposeModal";
import {getContract, useActiveWeb3React} from "../../../web3";
import bounceStake from "../../../web3/abi/bounceStake.json";
import {getStakingAddress} from "../../../web3/contractAddress";
import {useGovernance} from "../Hooks";

const {fromAscii} = Web3.utils


const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
    width: '100%',
    maxWidth: 1280,
    margin: '28px auto',
    padding: '58px 104px 80px 104px',
    position: 'relative',
    color: '#000',
    [`@media (max-width: 767px)`]: {
      width: '100vw',
      padding:'58px 0 80px 0',
    },
  },
  close: {
    position: 'absolute',
    width: 16,
    height: 16,
    top: 27,
    right: 24,
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Optima',
    paddingBottom: 16,
    borderBottom: '4px solid #000',
    fontSize: 36,
    lineHeight: '44px',
    fontWeight: 'bold',
  },
  subtitle: {
    fontFamily: 'Optima',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '27px',
    marginBottom: 54,
  },
  item: {
    width: '100%',
    paddingTop: 52,
    paddingBottom: 20,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [`@media (max-width: 767px)`]: {
      flexFlow: 'column',
    },
  },
  left: {
    fontFamily: 'Optima',
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 'bold',
    width: '50%',
    [`@media (max-width: 767px)`]: {
      width: 'calc(100vw - 40px)',
      marginLeft:20
    },
  },
  right: {
    fontFamily: 'Helvetica Neue',
    fontSize: 12,
    lineHeight: '15px',
    width: '50%',
    [`@media (max-width: 767px)`]: {
      width: 'calc(100vw - 40px)',
      marginLeft:20
    },
  },
  input: {
    marginBottom: 38,
    '& dt': {
      fontFamily: 'Helvetica Neue',
      marginBottom: 9,
      color: '#1F191B',
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'capitalize',
    },
    '& input': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
      color: fade('#000', 0.3),
      border: '1px solid rgba(0, 0, 0, 0.3)',
      borderWidth: '0 0 1px 0',
      paddingBottom: 5,
    },
    '& input:placeholder': {
      color: fade('#000', 0.3),
    },
  },
  divid: {
    width: '100%',
    height: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  button: {
    fontFamily: 'Helvetica Neue',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 50,
    width: '100%',
    height: 48,
    lineHeight: '48px',
    color: '#fff',
    backgroundColor: '#000',
    textAlign: 'center',
    cursor: 'pointer',
  }
}));

const CreateProposal = ({}) => {

  const {active, account, library, chainId} = useActiveWeb3React();


  const [title, setTile] = useState();
  const [summary, setSummary] = useState();
  const [details, setDetails] = useState();
  const [motivations, setMotivations] = useState();
  const [agreeFor, setAgreeFor] = useState();
  const [againstFor, setAgainstFor] = useState();
  const [day, setDay] = useState(3);

  const [modalStatus, setModalStatus] = React.useState(initStatus)

  const {govList} = useGovernance()



  const classes = useStyles();
  const history = useHistory();

  const [index, setIndex] = React.useState(3);

  const handleClose = () => {
    history.push('/governance')
  }

  const handleClick = (index) => {
    setIndex(index);
    console.log('day--->',index)
    setDay(index)
  }


  const onCrete = async () => {
    if (!title || !summary || !agreeFor || !againstFor) {
      return;
    }
    const contract = getContract(library, bounceStake.abi, getStakingAddress(chainId))

    const content = JSON.stringify({type: 0, summary, details, motivations, agreeFor, againstFor})
    setModalStatus(confirmStatus)
    try {
      contract.methods.propose(
          fromAscii(`bounce${govList.length}`),
          title,
          content,
          60 * 60 * 24 * day,
          fromAscii('0'),
          0
      )
          .send({from: account})
          .on('transactionHash', hash => {
            setModalStatus(pendingStatus)
          })
          .on('receipt', (_, receipt) => {
            console.log('confirmation', receipt)
            setModalStatus(successStatus)
          })
          .on('error', (err, receipt) => {
            setModalStatus(failStatus)

            console.log('error1', err);
          })
    } catch (err) {
      if (err.code === 4001) {
        setModalStatus(cancelStatus)
      } else {
        setModalStatus(failStatus)
      }
      console.log('err', err);
    }
  };


  return (
    <div className={classes.root}>
      <CloseIcon className={classes.close}
        onClick={handleClose}
       />
      <div className={classes.title}>
        {`Create a New Proposal`}
      </div>
      <div className={classes.item}>
        <div className={classes.left}>{`Proposal Description`}</div>
        <div className={classes.right}>
          <dl className={classes.input}><dt>{'Title'}</dt>
          <dd><input placeholder='Enter your project name (Keep it Below 10 words)' onChange={(e)=>{
            setTile(e.target.value)
          }} /></dd>
          </dl>
          <dl className={classes.input}><dt>{'Summary'}</dt>
          <dd><input placeholder='What will be done if the proposal is implement (Keep it below 200 words)' onChange={(e)=>{
            setSummary(e.target.value)
          }}/></dd>
          </dl>
          <dl className={classes.input}><dt>{'Details'}</dt>
          <dd><input placeholder='Write a Longer motivation with links and references if necessary' onChange={(e)=>{
            setDetails(e.target.value)
          }}/></dd>
          </dl>
        </div>
      </div>
      <div className={classes.divid}></div>
      <div className={classes.item}>
        <div className={classes.left}>{`Proposal settings`}</div>
        <div className={classes.right}>
          <dl className={classes.input}><dt>{'For'}</dt>
          <dd><input placeholder='Formulate clear for position' onChange={(e)=>{
            setAgreeFor(e.target.value)
          }}/></dd>
          </dl>
          <dl className={classes.input}><dt>{'Against'}</dt>
          <dd><input placeholder='Formulate clear Against position' onChange={(e)=>{
            setAgainstFor(e.target.value)
          }}/></dd>
          </dl>
        </div>
      </div>
      <div className={classes.divid}></div>
      <div className={classes.item}>
        <div className={classes.left}>{`Proposal Timing`}</div>
        <div className={classes.right}>
          <div className={classes.subtitle}>{'Please set a time frame for the proposal. Select the number of days below'}</div>
          <Timing index={index} onClick={handleClick} />
          <div className={classes.button} onClick={onCrete}>{'Launch'}</div>
        </div>
      </div>
      <ProposeModal modalStatus={modalStatus} onSuccess={()=>{    history.push('/governance')
      }}  onDismiss={()=>{setModalStatus(initStatus)}}/>
    </div>
  )
}

export { CreateProposal };
