import React from 'react'
import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton';
import Grow from '@material-ui/core/Grow';
import { WrapperStyled, SV_Wrapper } from './styled'

export const ERC20_Skeleton = () => {


    return (
        <Grow in={true} timeout={500}>
            <WrapperStyled>
                <div className="top">
                    <Skeleton variant="rect" width={80} height={16} />
                    <Skeleton variant="rect" width={50} height={16} />
                </div>
                <div className="title">
                    <Skeleton variant="rect" width={'100%'} height={40} />
                </div>
                <div className="text_info">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </div>
                <div className="buttom">
                    <Skeleton variant="rect" width={'100%'} height={5} />
                </div>
            </WrapperStyled>
        </Grow>
    )
}

export const ERC_NFT_Skeleton = () => {


    return (
        <Grow in={true} timeout={500}>
            <WrapperStyled style={{ height: 440 }}>
                <div className="top">
                    <Skeleton variant="rect" width={80} height={16} />
                    <Skeleton variant="rect" width={50} height={16} />
                </div>
                <div className="title">
                    <Skeleton variant="rect" width={'100%'} height={40} />
                </div>
                <div className='cover'>
                    <Skeleton variant="rect" width={'100%'} height={200} />
                </div>
                <div className="text_info">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </div>
                <div className="buttom">
                    <Skeleton variant="rect" width={'100%'} height={5} />
                </div>
            </WrapperStyled>
        </Grow>
    )
}

export const PRED_Skeleton = () => {


    return (
        <Grow in={true} timeout={500}>
            <WrapperStyled>
                <div className="top">
                    <Skeleton variant="rect" width={80} height={16} />
                    <Skeleton variant="rect" width={50} height={16} />
                </div>
                <div className="title">
                    <Skeleton variant="rect" width={'100%'} height={40} />
                </div>
                <div className="text_info" style={{ height: 106 }}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </div>
                <div className="buttom">
                    <Skeleton variant="rect" width={'100%'} height={47} />
                </div>
            </WrapperStyled>
        </Grow>
    )
}

export const SV_Skeleton = () => {
    return (
        <Grow in={true} timeout={500}>
            <SV_Wrapper>
                <div className="top">
                    <Skeleton variant="rect" width={80} height={16} />
                    <Skeleton variant="rect" width={50} height={16} />
                </div>
                <div className="title">
                    <Skeleton variant="rect" width={'100%'} height={60} />
                </div>
                <div className="text_info">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width={250} />
                    <div className='btn'>
                        <Skeleton variant="rect" width={'228px'} height={40} />
                    </div>
                </div>
                <div className="buttom">
                    <div className="up">
                        <Skeleton variant="text" width={60} height={16} />
                        <Skeleton variant="text" width={140} height={16} />
                    </div>
                    <Skeleton variant="rect" width={'100%'} height={5} />
                    <div className="down">
                        <Skeleton variant="text" width={123} height={16} />
                        <Skeleton variant="text" width={108} height={16} />
                    </div>
                </div>
            </SV_Wrapper>
        </Grow>
    )
}