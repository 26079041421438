import React, {useState, useCallback, useEffect} from 'react';
import {t} from '../../utils/intl';
import {getBounce} from '../../components/utils/web3';
import {endOfDay} from 'date-fns'


export const ClaimForm = ({
                            symbol,
                            account,
                            onStatusChange,
                            onHashChange,
                            web3
                          }) => {
  const [reward, setReward] = useState('');
  const [left, setLeft] = useState('');

  const getReward = useCallback(async () => {
    const bounce = await getBounce(web3);
    const reward = await bounce.methods.totalBonus()
        .call({from: account});
    const weiReward = web3.utils.fromWei(reward, 'ether');
    setReward(weiReward)
  }, [setReward, account]);
  getReward();

  const handleClaim = async () => {
    const bounce = await getBounce(web3);
    onStatusChange('waiting');
    bounce.methods.bonusClaim()
        .send({from: account})
        .then(r => {
          onStatusChange('success');
          onHashChange(r.transactionHash);
        })
        .catch(err => {
          if (err.code === 4001) {
            onStatusChange('denied');
          } else {
            onStatusChange('failed');
          }
          console.log(err);
        })
  }

  const getTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60 * 1000;
    const nowtime = new Date(now.getTime() + offset);
    const endtime = endOfDay(nowtime);

    let lefttime = endtime.getTime() - nowtime.getTime();
    let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
    let leftm = Math.floor(lefttime / (1000 * 60) % 60);
    if (leftm < 10) {
      leftm = '0' + leftm;
    }
    let lefts = Math.floor(lefttime / 1000 % 60);
    if (lefts < 10) {
      lefts = '0' + lefts;
    }
    const left = lefth + ":" + leftm + ":" + lefts;
    setLeft(left);
  }

  useEffect(() => {
    setInterval(() => {
      getTime();
    }, (1000));
  }, []);

  const renderRward = (reward) => {
    if (reward) {
      return Number(reward).toFixed(4) + ` BOT`;
    }
  }

  return (
      <div className='content-box'>
        <p>{t('claim.title')}</p>
        <h4>{renderRward(reward)}</h4>
        <dl className='count-down'>
          <dt>{t('claim.time-left')}</dt>
          <dd>{left}</dd>
        </dl>
        <span className='button' onClick={handleClaim}>{t('buttons.claim')}</span>
      </div>
  )
}
