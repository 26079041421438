
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFSNFTList } from "./useList";
import { useEANFTList } from "../EnglishAuction/useList";
import rect_logo from "../../../../static/image/rect-logo.svg";
import { weiToNum } from "../../../../utils/numberTransform";
import { PoolCard } from "../../../../components/PoolCard";
import { HANDLE_SHOW_CONNECT_MODAL } from "../../../../const";
import { useActivePlatform, useActiveWeb3React, getContract } from "../../../../web3";
import { myContext } from "../../../../reducer";
import { LayoutFrame } from "../../../../components/common/Layout";
import { JoinTipModal } from "../../../../components/common/JoinTipModal";
import Modal from "../../../../components/common/Modal";

import { EmptyPoolPage, LoadingPage } from "../../../../components/common/LoadingPage";
import icon_loading from '../../../../static/image/icon-loading-white.svg';
import empty_pool from '../../../../static/image/empty-pool-white.svg';

import styled from 'styled-components'
import { DetailHeader } from "../DetailHeader";

import bounceFactory from '../../../../components/utils/bounceFactory.json'
import { getBounceFactoryAddress } from "../../../../web3/contractAddress"



export const FS_List = ({ searchText, poolType, proxy }) => {
    const history = useHistory()
    const param = useParams()
    const { active, library, account, chainId } = useActiveWeb3React()
    const { poolList, setLoaded } = useFSNFTList(proxy, poolType)
    const { state, dispatch } = useContext(myContext);
    const { Psymbol } = useActivePlatform()
    const [isJoinTip, setIsJoinTip] = useState(false)
    const [onJoinInfo, setOnJoinInfo] = useState({})

    useEffect(() => {
        setLoaded(false)
    }, [])

    useEffect(() => {
        // console.log('E_console_FS', poolList)
    }, [poolList])


    const onJoin = (index, fromSymbol, adress) => {
        if (isJoinTip) {
            if (active) {
                if(poolType==='EA'){
                    history.push(`/store/custom/english-auction-nft/${proxy}/${index}`);
                }else{
                    history.push(`/store/custom/fixed-swap-nft/${proxy}/${index}`);
                }
                
            } else {
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
            }
            return
        }
        setTimeout(() => {
            setOnJoinInfo({
                index,
                fromSymbol,
                adress
            })
        }, 50);
        setIsJoinTip(true)

    }

    return (
        <LayoutFrame style={{ backgroundColor: '#121212', flexDirection: 'row', display: 'flex', paddingBottom: 64 }}>
            {!poolList ? <LoadingPage style={{ marginTop: 200 }} src={icon_loading} /> : poolList.length === 0 ? <EmptyPoolPage src={empty_pool} /> : (
                poolList.filter(item => {
                    return (searchText === '' || item.name.includes(searchText) || item.index.toString() == searchText)
                }).map(item => {
                    return (
                        <PoolCard column={5} key={item.tokenId} onJoin={() => {
                            if (active) {
                                history.push(`/store/custom/fixed-swap-nft/${proxy}/${item.index}`);
                            } else {
                                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
                            }
                        }} cover={item.cover ? item.cover : rect_logo} pool={item}
                            poolMeta={[{
                                title: 'Price',
                                content: `${weiToNum(item.amountTotal1)} ${Psymbol}`,
                            }, {
                                title: 'Token ID',
                                content: item.tokenId
                            }]} />
                    )
                }))}
            <Modal
                closeable
                isOpen={isJoinTip}
                onDismiss={() => {
                    setIsJoinTip(false)
                }}
                maxWidth={'450px'}
            >

                {/* <ModalTitle
                    style={{ textAlign: 'center', border: 'none' }}
                >Token imported</ModalTitle> */}
                <JoinTipModal
                    onJoin={onJoin}
                    onJoinInfo={onJoinInfo}
                />
            </Modal>
        </LayoutFrame>
    )


}

export const EA_List = ({ searchText, queryStatus, proxy }) => {
    const history = useHistory()
    const param = useParams()
    const { active, library, account, chainId } = useActiveWeb3React()
    const { poolList, setLoaded } = useEANFTList(proxy)
    const { state, dispatch } = useContext(myContext);
    const { Psymbol } = useActivePlatform()
    const [isJoinTip, setIsJoinTip] = useState(false)
    const [onJoinInfo, setOnJoinInfo] = useState({})

    useEffect(() => {
        setLoaded(false)
    }, [])

    useEffect(() => {
        // console.log('E_console_FS', poolList)
    }, [poolList])


    const onJoin = (index, fromSymbol, adress) => {
        if (isJoinTip) {
            if (active) {
                history.push(`/store/custom/fixed-swap-nft/${proxy}/${index}`);
            } else {
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
            }
            return
        }
        setTimeout(() => {
            setOnJoinInfo({
                index,
                fromSymbol,
                adress
            })
        }, 50);
        setIsJoinTip(true)

    }

    return (
        <LayoutFrame style={{ backgroundColor: '#121212', flexDirection: 'row', display: 'flex', paddingBottom: 64 }}>
            {!poolList ? <LoadingPage style={{ marginTop: 200 }} src={icon_loading} /> : poolList.length === 0 ? <EmptyPoolPage src={empty_pool} /> : (
                poolList.filter(item => {
                    return (searchText === '' || item.name.includes(searchText) || item.index.toString() == searchText)
                }).map(item => {
                    return (
                        <PoolCard column={5} key={item.tokenId} onJoin={() => {
                            if (active) {
                                history.push(`/store/custom/fixed-swap-nft/${proxy}/${item.index}`);
                            } else {
                                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
                            }
                        }} cover={item.cover ? item.cover : rect_logo} pool={item}
                            poolMeta={[{
                                title: 'Current Bid',
                                content: `${weiToNum(item.amount)} ${Psymbol}`,
                            }, {
                                title: 'Token ID',
                                content: item.tokenId
                            }]} />
                    )
                }))}
            <Modal
                closeable
                isOpen={isJoinTip}
                onDismiss={() => {
                    setIsJoinTip(false)
                }}
                maxWidth={'450px'}
            >

                {/* <ModalTitle
                    style={{ textAlign: 'center', border: 'none' }}
                >Token imported</ModalTitle> */}
                <JoinTipModal
                    onJoin={onJoin}
                    onJoinInfo={onJoinInfo}
                />
            </Modal>
        </LayoutFrame>
    )


}


const DetailFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #121212;
  align-items: center;
`

export const StoreFa_FS = () => {
    const params = useParams()
    const { active, library, account, chainId } = useActiveWeb3React()
    const [searchText, setSearchText] = useState('')
    const [poolType, setPoolType] = useState(0)
    const [queryStatus, setQueryStatus] = useState('All')
    const [baseInfo, setBaseInfo] = useState({})
    const [proxy, setProxy] = useState(null)

    useEffect(() => {
        if (!active) return
        initBaseInfo(poolType)
    }, [active, poolType])

    const initBaseInfo = async (poolIndex) => {
        const bounceFac = getContract(library, bounceFactory.abi, getBounceFactoryAddress(chainId))
        const res = await bounceFac.methods.stores(params.id).call({ from: account })
        const pools = await bounceFac.methods.pools(params.id, poolIndex).call({ from: account })
        const info = JSON.parse(res)
        console.log('E_console', poolType)
        setProxy(pools.proxy)
        setBaseInfo({ ...info, ...pools })
    }

    const getOptionItem = (item) => {
        switch (item) {
            case 1:
                return {
                    key: 1,
                    value: 'fixed-swap-nft'
                }
            case 2:
                return {
                    key: 2,
                    value: 'english-auction-nft'
                }
            default:
                break;
        }
    }

    const getOptions = () => {
        let options = []
        const storeType_2 = baseInfo.storeType || []
        storeType_2.forEach((item, index) => {
            const option = getOptionItem(item)

            option.createUrl = `/store/crate/custom/${option.value}/${params.id}/${index}`
            options.push(option)
        })
        return options
    }

    const getType = () => {
        console.log('E_console', poolType)
        switch (poolType) {
            case 0:
                return 'FS'
            case 1:
                return 'EA'
            default:
                return 'FS'
        }
    }


    return <DetailFrame>
        <DetailHeader
            baseInfo={baseInfo && baseInfo} title={baseInfo && baseInfo.name}
            website={baseInfo && baseInfo.describe}
            setPoolType={setPoolType}
            poolTypeArr={baseInfo && baseInfo.storeType}
            storeID={baseInfo && baseInfo.storeID}
            // createURL={baseInfo && `/store/crate/custom/${getTypeByNum(baseInfo.storeType)}/${params.id}`}
            options={getOptions()}
            onSearchChange={setSearchText}
            onQueryStatusChange={setQueryStatus}
        />
        {/* {poolType && renderPoolList()} */}
        {<FS_List queryStatus={queryStatus} searchText={searchText} proxy={proxy && proxy} poolType={getType()} />}
    </DetailFrame>
}