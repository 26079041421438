import React, { useState } from 'react';
import { Link } from 'react-router-dom';


export const Select = ({ title, options, defaultOption, onSelect, onlyIcon, layoutStyle, containerStyle, optionsStyle }) => {

  const [selectedOption, setSelectedOption] = useState(defaultOption ? defaultOption : options[0]);
  const [hovered, setHovered] = useState(false);


  return <div style={layoutStyle} className={`select-container ${onlyIcon ? 'only-icon-container' : ''}`}>
    <div
      onMouseOver={_ => {
        setHovered(true)
      }}
      onMouseOut={_ => {
        setHovered(false)
      }}
      className={`select ${onlyIcon ? 'only-icon' : hovered ? 'hover' : ''}`}
      style={containerStyle}>
      <>
        {onlyIcon ? (
          <div className="icon gear" />
        ) : (<div className="select-default">{title ? title : selectedOption.name}</div>)}
        <div className="options" style={optionsStyle}>
          {options.map(item => {
            return item.link ?
              <Link
                key={item.value}
                onClick={() => {
                  onSelect && onSelect(item.value)
                  setSelectedOption(item)
                  onSelect(item.value)
                }}
                className="option"
                to={item.link}>{item.name}</Link>
              :
              <span className="option"
                onClick={() => {
                  setSelectedOption(item)
                  onSelect(item.value)
                }}>{item.name}</span>
          })}
        </div>
      </>
    </div>
  </div>
}
