import React, {useState, useCallback, useEffect, useContext} from 'react';
import {
    Address,
    ITextR,
    LayoutFrame, LineDivider,
    OText2, OText5,
    Pool,
    PoolFrame,
    Progress,
    renderTime, Website
} from "../../components/common/Layout";
import styled from 'styled-components'
import icon_return from '../../static/image/icon-return.svg'
import {useSVSBDetail} from "./Hooks";
import BigNumber from "bignumber.js";
import {fromWei, getProgress, numToWei, weiDiv, weiToNum, weiToNumber} from "../../utils/numberTransform";
import classNames from "classnames";
import {useParams} from 'react-router-dom';
import {Form, FormStatus, Input} from "../../components/common/Form";
import icon_max from "../../static/image/icon-max.svg";
import {Button} from "../../components/common/Button";
import {useEthBalance, useTokenBalance} from "../../web3/common";
import {getContract, useActivePlatform, useActiveWeb3React} from "../../web3";
import fixSwap from "../../web3/abi/SVSealedBid.json";
import {getBotAddress, getSealedBidAddress, getSVSBAddress} from "../../web3/contractAddress";
import Web3 from 'web3'
import {useHistory} from 'react-router-dom'
import {
    BidModal,
    initStatus,
    errorStatus,
    successStatus,
    confirmStatus,
    pendingStatus,
    cancelStatus
} from "../../components/common/BidModal";
import {useLeftTime} from "../../hooks/useLeftTime";
import {PasswordModal} from "../../components/common/PasswordMpdal";
import {myContext} from "../../reducer";
import {isEqualTo, isGreaterThan} from "../../utils/common";
import md5 from "js-md5";
import {useIsXSDown} from '../../components/utils/themeHooks';
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import {approveStatus} from "../../components/common/CreateModal";
import sealedBid from "../../web3/abi/bounceSealedBid.json";

const {toWei, BN} = Web3.utils

const ProgressFrame = styled.div`
  width: 560px;
  margin: auto;
  margin-top: 56px;
  display: flex;
  flex-direction: column;   
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
  }
`

ProgressFrame.Title = styled.div`
    font-family: Optima;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 21px;
`
ProgressFrame.Num = styled.span`
  font-family: IBM Plex Mono;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
color: #1F191B;
margin-top: 15px;

span{
  color: rgba(31, 25, 27, 0.3);
}
`


const Project = styled.div`
  width: 1080px;
  padding: 0px 40px 60px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #C4C4C4;
`

Project.Meta = styled.div`
  display: flex;
  flex-direction: column;
`

Project.Body = styled.div`
  width: 456px;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 140px;
`

Project.Title = styled.div`
font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 22px;
color: #000;
margin-bottom: 24px;
`

Project.Footer = styled.div`
  width: 453px;
  margin-top: auto;
  margin-bottom: 0;
`


export const SVSBDetail = () => {
    const history = useHistory()
    const {state, dispatch} = useContext(myContext);
    const {ethPrice} = state
    const {id} = useParams();
    const {active, account, library, chainId} = useActiveWeb3React()
    const {setTime, leftTime} = useLeftTime()
    const [bidAmount, setBidAmount] = useState()
    const [voteAmount, setVoteAmount] = useState()
    const [bidStatus, setBidStatus] = useState(initStatus)
    const [passwordModal, setPasswordModal] = useState(false)
    const [enterPW, setEnterPW] = useState()
    const [errorPW, setErrorPW] = useState()
    const {Psymbol} = useActivePlatform()
    const isXSDown = useIsXSDown();
    const [bidRatio, setBidRatio] = useState()
    const [errorBidAmount, setErrorBidAmount] = useState()

    const {
        name, address, symbol, decimals, floor, password, time, totalBid, onlyBOT, voteTime,
        limit, status, isMine, claimButtonText, myBid, setQueryStatus, toSymbol, toAddress, toDecimals, voteCount, voteStatus, voted, myVote, projectInfo
    } = useSVSBDetail(id)

    const {ethBalance} = useEthBalance(toAddress)
    const {balance} = useTokenBalance(getBotAddress(chainId))
    let timer = null
    let voteTimer = null
    useEffect(() => {
        timer = setInterval(() => {
            const date = new Date(time * 1000);
            const now = new Date();
            const lefttime = date - now;
            if (lefttime >1000) {
                setTime(lefttime)
            }else if(0<lefttime && lefttime<1000){
                window.location.reload()
            } else {
                clearInterval(timer)
            }
        }, (1000));
        return () => {
            clearInterval(timer)
        }
    }, [setTime, time]);

    useEffect(() => {
        if(voteStatus === 'Pending'){
            voteTimer = setInterval(() => {
                const date = new Date(voteTime * 1000 + 48*60*60*1000);
                const now = new Date();
                const lefttime = date - now;
                if (lefttime > 1000) {
                    setTime(lefttime)
                }else if(0<lefttime && lefttime<1000){
                    window.location.reload()
                } else {
                    clearInterval(voteTimer)
                }
            }, (1000));
        }

        return () => {
            clearInterval(voteTimer)
        }
    }, [setTime, voteTime, voteStatus]);


    useEffect(() => {
        if (password && password !== '0') {
            setPasswordModal(true)
        }
    }, [password])


    const onBid = async () => {
        if (password && password !== '0' && new BN(md5(enterPW)).toString() !== password) {
            setPasswordModal(true)
            return
        }
        if (!bidAmount || !bidRatio) {
            return
        }

        const bounceContract = getContract(library, sealedBid.abi, getSVSBAddress(chainId))

        const bidFromAmount = numToWei(new BigNumber(bidAmount).multipliedBy(new BigNumber(bidRatio)).toString(), decimals);
        const bidToAmount = numToWei(bidAmount, toDecimals);
        console.log('bid:', bidFromAmount, bidToAmount)

        setBidStatus(confirmStatus);
        try {
            if (toAddress) {
                const tokenContract = getContract(library, bounceERC20.abi, toAddress)
                const allowance = await tokenContract.methods.allowance(account, getSVSBAddress(chainId)).call()
                console.log('on bid--->', allowance)

                if (isGreaterThan(allowance, '0')) {
                    await tokenContract.methods.approve(
                        getSealedBidAddress(chainId),
                        0,
                    ).send({ from: account });
                }

                await tokenContract.methods.approve(
                    getSealedBidAddress(chainId),
                    bidToAmount,
                )
                    .send({ from: account });
                setBidStatus(confirmStatus);
            }
            bounceContract.methods.bid(id, bidFromAmount, bidToAmount, password)
                .send({ from: account, value: toAddress ? 0 : bidToAmount })
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setQueryStatus(true)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid error', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }


    const onVote = async () => {
        const tokenContract = getContract(library, bounceERC20.abi, getBotAddress(chainId))
        const bounceContract = getContract(library, fixSwap.abi, getSVSBAddress(chainId))
        const weiAmount = numToWei(voteAmount);
        console.log('on vote---->', voteAmount, weiAmount)

        setBidStatus(confirmStatus);
        try {
            const allowance = await tokenContract.methods.allowance(account, getSVSBAddress(chainId)).call()
            console.log('allowance', allowance, new BigNumber(weiAmount).minus(allowance).toString())
            await tokenContract.methods.approve(
                getSVSBAddress(chainId),
                weiAmount,
            )
                .send({from: account});
            bounceContract.methods.vote(id, weiAmount)
                .send({from: account})
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid---->', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }

    const onVoteClaim = async () => {
        const bounceContract = getContract(library, fixSwap.abi, getSVSBAddress(chainId))
        setBidStatus(confirmStatus);
        console.log('unvote amount',)
        try {
            bounceContract.methods.unvote(id, myVote)
                .send({from: account})
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid---->', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }



    const onClaim = async () => {
        const bounceContract = getContract(library, sealedBid.abi, getSVSBAddress(chainId))
        const claimFunc = isMine ? 'creatorClaim' : 'bidderClaim'
        setBidStatus(confirmStatus);
        try {
            bounceContract.methods[claimFunc](id)
                .send({ from: account })
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setQueryStatus(true)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }


    return (
        <LayoutFrame style={{
            marginTop: 27,
            paddingBottom: 56,
            paddingLeft: isXSDown ? '20px' : '0',
            paddingRight: isXSDown ? '20px' : '0'
        }}>
            <Pool.Return onClick={() => {
                history.goBack()
            }} src={icon_return}/>
            <LayoutFrame width={'1072px'} style={{padding: '32px 0', margin: 'auto', marginTop: 0}}>
                <Pool.Status style={{width: 'fit-content', margin: 'auto'}}
                             className={classNames('status', voteStatus)}><i
                    className={voteStatus}/>{voteStatus}</Pool.Status>
                <Pool.Header><span>{name}</span></Pool.Header>
                <Website target='_blank' href={projectInfo && projectInfo.link} style={{wordBreak: isXSDown ? 'break-all' : 'normal'}}>{projectInfo && projectInfo.link}</Website>
                {voted ? (
                    <Project>
                        <Project.Meta>
                            <Project.Body>
                                {projectInfo && projectInfo.description}
                            </Project.Body>
                            <Project.Footer>

                                <ProgressFrame.Num >Support from community: {voteCount && weiToNumber(voteCount)} BOT <span>/ 300 BOT</span></ProgressFrame.Num>

                                <Progress style={{marginTop: 16}} height={'5px'} className={classNames('progress', voteStatus)}>
                                    <Progress.Value style={{width: voteStatus === 'Successfully' ? '100%' : `${getProgress(voteCount, toWei('300'))}%`}}
                                                    className={classNames('progress-value', voteStatus)}/>
                                </Progress>

                            </Project.Footer>
                        </Project.Meta>

                        <Project.Meta>
                            <Project.Body style={{width: 432}}>
                               <Project.Title>You supported this project</Project.Title>

                                <Pool.Meta>
                                    <div>Your Vote Amount:</div>
                                    <div>{`${myVote && weiToNumber(myVote)} BOT`}</div>
                                </Pool.Meta>

                            </Project.Body>

                            <Project.Footer>
                                <Button disabled={voteStatus === 'Pending' || (voteStatus !== 'Pending' && status === 'Live')}  style={{
                                    backgroundColor: (voteStatus === 'Pending' || (voteStatus !== 'Pending' && status === 'Live')) ? '#D3D3DA' : '',
                                }} black onClick={onVoteClaim}>Claim support tokens back</Button>
                            </Project.Footer>
                        </Project.Meta>

                    </Project>
                ) : (
                    <>
                        <Pool.Description>{projectInfo && projectInfo.description}</Pool.Description>
                        <ProgressFrame>
                            <ProgressFrame.Title>Support from community:</ProgressFrame.Title>

                            <Progress height={'5px'} className={classNames('progress', voteStatus)}>
                                <Progress.Value style={{width: `100%`}}
                                                className={classNames('progress-value', voteStatus)}/>
                            </Progress>

                            <ProgressFrame.Num>{voteCount ? voteStatus === 'Successfully'? 300 : weiToNumber(voteCount): 0} BOT <span>/ 300 BOT</span></ProgressFrame.Num>

                        </ProgressFrame>
                    </>
                )}


                <Pool.Content style={{marginTop: 40}}>
                    {voteStatus === 'Pending' && (
                        <Pool.Content width={'auto'}
                                      style={{
                                          margin: 'auto',
                                          height: 'fit-content',
                                          width: '100%',
                                          flexDirection: 'column',
                                          padding: isXSDown ? '48px 20px' : '56px 0 72px',
                                          justifyContent: 'center',
                                          marginTop: 0,
                                          backgroundColor: 'rgba(248, 248, 251, 1)',
                                      }}>
                            <div style={{width: '320px', margin: 'auto'}}>
                                <OText2 style={{textAlign: 'center', marginTop: 0, fontSize: 26, lineHeight: '32px'}}>You
                                    have A time to Stake to support this Project</OText2>
                                {renderTime(leftTime)}
                                <LineDivider style={{marginTop: 0}}/>
                                <Pool.topInfo>
                                    <span>Your Vote Amount</span>
                                    <span>{`Balance: ${balance ? weiToNumber(balance, '18') : '--'}`} BOT</span>
                                </Pool.topInfo>
                                <Form top={'20px'} width={isXSDown ? '100%' : '320px'} input={<Input
                                    style={{
                                        padding: '8px 0',
                                        color: '#1F191B',
                                        fontSize: 16,
                                        lineHeight: '20px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: "bold"
                                    }}
                                    placeholder={'Bid Amount'}
                                    type='number'
                                    value={voteAmount}
                                    onBlur={() => {
                                        if (voteAmount && balance && isGreaterThan(numToWei(voteAmount), balance)) {
                                            setVoteAmount(weiToNumber(balance).toString())
                                        }
                                    }}
                                    onChange={(e) => {
                                        setVoteAmount(e.target.value.replace(/[^\d.]/g, ''))
                                    }}
                                />} name={' '} addonAfter={(<img onClick={() => {
                                    console.log('set max amount', ethBalance)
                                    setVoteAmount(fromWei(balance))
                                }} src={icon_max}/>)}
                                    // extra={<span style={{ bottom: 9 }}>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>}
                                />

                                <Button style={{
                                    backgroundColor: status === 'Closed' || status === 'Filled' ? '#D3D3DA' : '',
                                    marginTop: 40
                                }} black onClick={onVote}>Stake to support</Button>
                            </div>
                        </Pool.Content>
                    )}

                    {(voteStatus === 'Successfully' || isMine ) && (
                        <LayoutFrame width={'1072px'}
                                     style={{ padding: isXSDown ? '40px 20px' : '0 0 40px', margin: 'auto', marginTop: 32, }}>
                            <Pool.Mode>Sealed-Bid</Pool.Mode>
                            <Pool.Header><span>{name}</span></Pool.Header>
                            <Address style={{ wordBreak: isXSDown ? 'break-all' : 'normal' }}>{address}</Address>
                            <Pool.Content style={{ marginTop: 40 }}>
                                <Pool.Content width={isXSDown ? '100%' : '520px'} style={{ marginTop: 0 }}>

                                    <Pool.Content width={isXSDown ? '100%' : '456px'}
                                                  style={{ marginTop: 0, flexDirection: 'column' }}>
                                        <Pool.Status style={{ width: 'fit-content' }} className={classNames('status', status)}><i
                                            className={status}></i>{status}</Pool.Status>

                                        <ITextR style={{
                                            marginTop: 8,
                                            textAlign: 'left'
                                        }}>{`Participant: ${onlyBOT ? 'BOT holder' : 'Public'}`}</ITextR>
                                    </Pool.Content>

                                    <Pool.Block style={{ width: '100%' }}>
                                        <span>Floor Swap Ratio</span>
                                        <span>{floor && `1 ${toSymbol} = ${weiDiv(fromWei(floor.from, decimals), fromWei(floor.to, toDecimals))} ${symbol}`}</span>
                                    </Pool.Block>

                                    <Pool.Block style={{ width: isXSDown ? '100%' : '200px' }}>
                                        <span>Price,$</span>
                                        {/* <span>{floor && new BigNumber(0.0000000004 / 1).toString()}</span> */}
                                        <span>{floor && Number(new BigNumber(weiDiv(fromWei(floor.to, toDecimals), fromWei(floor.from, decimals))).multipliedBy(toSymbol === 'ETH' ? ethPrice : '1').toFixed(6)).toString()}</span>
                                    </Pool.Block>

                                    <Pool.Block style={{ width: isXSDown ? '100%' : '200px' }}>
                                        <span>Total Allocation</span>
                                        <span>{(floor && symbol) && `${weiToNumber(floor.from)} ${symbol}`}</span>
                                    </Pool.Block>

                                    <Pool.Block style={{ width: isXSDown ? '100%' : '200px' }}>
                                        <span>Minimum Bid amount</span>
                                        <span>{limit && (limit === 0 ? 'No limit' : `${weiToNumber(limit, toDecimals)} ${toSymbol}`)}</span>
                                    </Pool.Block>
                                </Pool.Content>

                                <Pool.Content width={isXSDown ? '100%' : '432px'}
                                              style={{
                                                  flexDirection: 'column',
                                                  padding: isXSDown ? '48px 20px' : '48px 56px',
                                                  justifyContent: 'center',
                                                  marginTop: 0,
                                                  backgroundColor: 'rgba(248, 248, 251, 1)'
                                              }}>


                                    {isMine || (myBid && isEqualTo(myBid.from, '0') && status === 'Live') ? (
                                        <>

                                            {(!isMine) && (
                                                <>
                                                    <OText2>Join The Pool</OText2>

                                                    {renderTime(leftTime)}
                                                    <LineDivider style={{ marginTop: 0 }} />
                                                    <Pool.topInfo>
                                                        <span style={{ opacity: .3 }}>Swap ratio for Bid</span>
                                                    </Pool.topInfo>
                                                    <Form top={'0px'} width={isXSDown ? '100%' : '320px'} input={<Input
                                                        style={{
                                                            padding: '8px 0',
                                                            color: '#1F191B',
                                                            fontSize: 16,
                                                            lineHeight: '20px',
                                                            fontFamily: 'Helvetica Neue',
                                                            fontWeight: "bold",
                                                            marginLeft: 8
                                                        }}
                                                        placeholder={''}
                                                        type='number'
                                                        value={bidRatio}
                                                        onBlur={() => {
                                                            if (bidRatio && floor && isGreaterThan(bidRatio, weiDiv(fromWei(floor.from, decimals), fromWei(floor.to, toDecimals)))) {
                                                                setBidRatio(weiDiv(fromWei(floor.from, decimals), fromWei(floor.to, toDecimals)))
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setBidRatio(e.target.value.replace(/[^\d.]/g, ''))
                                                        }}
                                                    />} name={' '} prefix={`1 ${toSymbol} =`} suffix={symbol} />
                                                    <Pool.topInfo>
                                                        <span>Your Bid Amount</span>
                                                        <span>{`Balance: ${ethBalance ? weiToNumber(ethBalance, toDecimals) : '--'} ${toSymbol}`}</span>
                                                    </Pool.topInfo>
                                                    <Form error={errorBidAmount} top={'0px'} width={isXSDown ? '100%' : '320px'}
                                                          input={<Input
                                                              style={{
                                                                  padding: '8px 0',
                                                                  color: '#1F191B',
                                                                  fontSize: 16,
                                                                  lineHeight: '20px',
                                                                  fontFamily: 'Helvetica Neue',
                                                                  fontWeight: "bold"
                                                              }}
                                                              placeholder={''}
                                                              type='number'
                                                              value={bidAmount}
                                                              onBlur={() => {
                                                                  console.log('amount blur')
                                                                  if (bidAmount && ethBalance && isGreaterThan(bidAmount, fromWei(ethBalance, toDecimals))) {
                                                                      console.log('check--->')
                                                                      setBidAmount(weiToNumber(ethBalance, toDecimals))
                                                                  } else if ((bidAmount && limit) && isGreaterThan(limit, toWei(bidAmount))) {
                                                                      setBidAmount(fromWei(limit))
                                                                  }
                                                              }}
                                                              onChange={(e) => {
                                                                  setBidAmount(e.target.value.replace(/[^\d.]/g, ''))
                                                              }} />} name={' '} addonAfter={(<img src={icon_max} />)}
                                                        //   extra={
                                                        //       <span>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>}
                                                    />
                                                    <Button black onClick={onBid} style={{ marginTop: 50 }}>Go</Button>
                                                </>
                                            )}

                                            {(isMine) && (
                                                <>
                                                    <OText2>My Pool</OText2>

                                                    <div style={{ marginTop: 9 }}>
                                                        {renderTime(leftTime)}
                                                    </div>
                                                    <LineDivider style={{ marginTop: 24 }} />

                                                    <Pool.Meta>
                                                        <div>total bid token:</div>
                                                        <div>{totalBid && `${weiToNum(totalBid.from, decimals)} ${symbol}`}</div>
                                                    </Pool.Meta>

                                                    <Pool.Meta>
                                                        <div>total bid eth:</div>
                                                        <div>{totalBid && `${weiToNumber(totalBid.to)} ${toSymbol}`}</div>
                                                    </Pool.Meta>
                                                </>
                                            )}
                                            {claimButtonText && <Button black onClick={onClaim}>{claimButtonText}</Button>}
                                        </>
                                    ) : (
                                        <>
                                            <OText2>You Joined</OText2>
                                            <div style={{ marginTop: 9 }}>
                                                {renderTime(leftTime)}
                                            </div>
                                            <LineDivider style={{ marginTop: 24 }} width={isXSDown ? '100%' : '320px'} />

                                            <Pool.Meta>
                                                <div>swap ratio for Bid:</div>
                                                <div>{myBid && `1 ${toSymbol} = ${weiDiv(fromWei(myBid.from, decimals), fromWei(myBid.to, toDecimals))} ${symbol}`}</div>
                                            </Pool.Meta>

                                            <Pool.Meta>
                                                <div>Your bid amount:</div>
                                                <div>{myBid && `${weiToNumber(myBid.to, toDecimals)} ${toSymbol}`}</div>
                                            </Pool.Meta>

                                            {claimButtonText && <Button black onClick={onClaim}>{claimButtonText}</Button>}

                                        </>
                                    )}
                                </Pool.Content>
                            </Pool.Content>


                        </LayoutFrame>
                    )}
                </Pool.Content>


                <Pool.Content style={{marginTop: 40}}>


                </Pool.Content>


            </LayoutFrame>


            <BidModal modalStatus={bidStatus} onDismiss={() => {
                setBidStatus(initStatus)
            }}/>

            <PasswordModal error={errorPW} onDismiss={() => {
                setPasswordModal(false)
            }} onChange={(password) => {
                setEnterPW(password)
            }} onConfirm={() => {
                console.log('password', password, new BN(md5(enterPW)).toString())
                if (new BN(md5(enterPW)).toString() === password) {
                    console.log('confirm password')
                    setPasswordModal(false)
                    setErrorPW(null)
                } else {
                    setEnterPW('password is wrong, please enter again')
                }
            }} show={passwordModal}/>
        </LayoutFrame>
    )
}
