import { getContract, useActivePlatform, useActiveWeb3React } from "../../web3";
import { useContext, useEffect, useState } from "react";
import Web3 from 'web3'
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import fixSwap from '../../web3/abi/bounce.json'
import { myContext } from '../../reducer'

import BigNumber from "bignumber.js";
import { getBotAddress, getFixSwapAddress, getSVFSAddress } from "../../web3/contractAddress";
import { isEqualTo, isGreaterThan } from "../../utils/common";
import { HANDLE_SHOW_CONNECT_MODAL } from "../../const";
import fsABI from "../../web3/abi/bounce.json";
import SVBounce from "../../web3/abi/SVBounce.json";
import { weiToNum } from "../../utils/numberTransform";
import { useTokenList } from "../../web3/common";


export const usePoolDetail = (id = 0) => {
    const { active, account, library, chainId } = useActiveWeb3React();
    const tokenOptions = useTokenList()

    const { Psymbol } = useActivePlatform()

    const { state, dispatch } = useContext(myContext);

    const [joinStatus, setJoinStatus] = useState(false)
    const [name, setName] = useState(null)
    const [symbol, setSymbol] = useState('')
    const [toSymbol, setToSymbol] = useState()
    const [address, setAddress] = useState(null)
    const [toAddress, setToAddress] = useState(null)
    const [decimals, setDecimals] = useState()
    const [toDecimals, setToDecimals] = useState()
    const [toAmount, setToAmount] = useState(null)
    const [fromTotal, setFromTotal] = useState(null)
    const [limit, setLimit] = useState(null)
    const [isMine, setIsMine] = useState(false)
    const [password, setPassword] = useState()
    const [time, setTime] = useState()
    const [fromBidAmount, setFromBidAmount] = useState()
    const [toBidAmount, setToBidAmount] = useState()
    const [fromAmount, setFromAmount] = useState()
    const [status, setStatus] = useState('Live')
    const [onlyBOT, setOnlyBOT] = useState(false)
    const [toTokenBalance, setToTokenBalance] = useState(0)

    const [claimed, setClaimed] = useState(true)


    const checkMyFSPool = async () => {
        const fsContract = getContract(library, fsABI.abi, getFixSwapAddress(chainId))
        let myPoolIndex = await fsContract.methods.myFP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const fromAmount = await fsContract.methods.amountTotal0FP(myPoolIndex).call()
            const bidAmount = await fsContract.methods.amountSwap0FP(myPoolIndex).call()
            if (fromAmount === bidAmount) {
                setClaimed(true)
            } else {
                setClaimed(false)
            }
        } else {
            setClaimed(true)
        }
    }

    useEffect(() => {
        if (!toAddress) return
        if (new BigNumber(toAddress).isEqualTo(0)) return
        queryTokenBalance(toAddress)
    }, [toAddress])


    const queryTokenBalance = async (toAddress) => {
        const Contract = getContract(library, bounceERC20.abi, toAddress)
        const balance = await Contract.methods.balanceOf(account).call()
        setToTokenBalance(balance)
    }

    async function getFSPoolDetail() {
        try {

            const fsContract = getContract(library, fixSwap.abi, getFixSwapAddress(chainId))

            fsContract.methods.myAmountSwapped1(account, id).call().then((res) => {
                console.log('query fs myAmountSwapped1:', res)
                setJoinStatus(isGreaterThan(res, '0'))
            })

            fsContract.methods.passwordFP(id).call().then((res) => {
                console.log('query fs password:', res)
                setPassword(res)
            })

            fsContract.methods.closeAtFP(id).call().then((res) => {

                const date = new Date(res * 1000);
                const now = new Date();
                const leftTime = date - now;
                console.log('query fs closeAt:', res, leftTime)

                setTime(res)
                setStatus(leftTime > 0 ? 'Live' : 'Closed')
                fsContract.methods.amountSwap1FP(id).call().then((bidAmount) => {
                    console.log('query fs to bid amount:', bidAmount)
                    setToBidAmount(bidAmount)
                    fsContract.methods.amountTotal1FP(id).call().then((total) => {
                        console.log('query fs to total:', total)
                        setToAmount(total)
                        if (bidAmount === total) {
                            setStatus('Filled')
                        }
                    })
                })


            })

            fsContract.methods.creatorFP(id).call().then((res) => {
                console.log('query fs creator:', res)
                setIsMine((res.toLowerCase() === account.toLowerCase()))
                if (res.toLowerCase() === account.toLowerCase()) {
                    checkMyFSPool()
                }
            })

            fsContract.methods.nameFP(id).call().then((res) => {
                console.log('query fs name:', res)
                setName(res)
            })

            fsContract.methods.onlyBotHolder(id).call().then((res) => {
                console.log('query fs name:', res)
                setOnlyBOT(res)
            })

            fsContract.methods.token0FP(id).call().then((res) => {
                console.log('query fs address:', res)
                setAddress(res)
                const tokenContract = getContract(library, bounceERC20.abi, res)
                tokenContract.methods.symbol().call().then((res) => {
                    console.log('query fs symbol:', res)
                    setSymbol(res)
                })
                tokenContract.methods.decimals().call().then((res) => {
                    console.log('query fs decimals:', res)
                    setDecimals(res)
                })
            })

            fsContract.methods.token1FP(id).call().then((res) => {
                console.log('query fs to address:', res)

                const toToken = tokenOptions.find(item => {
                    return res.toLowerCase() === item.key.toLowerCase()
                })

                if (res === '0x0000000000000000000000000000000000000000') {
                    setToAddress(null)
                    setToSymbol(Psymbol)
                    setToDecimals('18')
                } else if (toToken) {
                    setToAddress(res)
                    setToDecimals(toToken.decimals)
                    setToSymbol(toToken.symbol)
                } else {
                    setToAddress(null)
                    setToSymbol(Psymbol)
                    setToDecimals('18')
                }

                // if(new BigNumber(res).isEqualTo('0')){
                //     setToAddress(null)
                //     setToSymbol(Psymbol)
                // }else if(res.toLowerCase() === '0xdac17f958d2ee523a2206206994597c13d831ec7'.toLowerCase() || res.toLowerCase() === '0x101194a3FF67f83A05B3E15AfA52D45D588614ca'.toLowerCase()) {
                //     setToAddress(res)
                //     setToDecimals('6')
                //     setToSymbol('USDT')
                // }else if(res.toLowerCase() === '0x55d398326f99059ff775485246999027b3197955'.toLowerCase()){
                //     setToAddress(res)
                //     setToDecimals('18')
                //     setToSymbol('USDT')
                // }else {
                //     setToAddress(res)
                //     setToDecimals('18')
                //     setToSymbol('ETH')
                // }

            })

            fsContract.methods.amountTotal0FP(id).call().then((res) => {
                console.log('query fs from total:', res)
                setFromAmount(res)
            })

            fsContract.methods.amountSwap0FP(id).call().then((res) => {
                console.log('query fs to bid amount:', res)
                setFromBidAmount(res)
            })


            fsContract.methods.maxEthPerWalletFP(id).call().then((res) => {
                console.log('query fs limit max:', res)
                setLimit(res)
            })


        } catch (e) {
            console.log('getTokenInfo:', e)
        }
    }

    useEffect(() => {

        if (active, chainId, account) {
            console.log('chainId', chainId)
            getFSPoolDetail()
        } else {
            dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
        }

    }, [active, chainId, account])

    // console.log('FS_BUG', fromAmount, fromBidAmount, toAmount, toBidAmount)
    return {
        name,
        claimed,
        address,
        symbol,
        toAddress,
        toSymbol,
        toDecimals,
        decimals,
        fromTotal,
        toAmount,
        limit,
        time,
        password,
        fromBidAmount,
        toBidAmount,
        fromAmount,
        status,
        isMine,
        onlyBOT,
        toTokenBalance,
        joinStatus
    }
}

export const useSVFSDetail = (id = 0) => {
    const { active, account, library, chainId } = useActiveWeb3React();
    const { Psymbol } = useActivePlatform()

    const { state, dispatch } = useContext(myContext);

    const [name, setName] = useState(null)
    const [symbol, setSymbol] = useState('')
    const [toSymbol, setToSymbol] = useState()
    const [address, setAddress] = useState(null)
    const [toAddress, setToAddress] = useState(null)
    const [decimals, setDecimals] = useState()
    const [toDecimals, setToDecimals] = useState()
    const [toAmount, setToAmount] = useState(null)
    const [fromTotal, setFromTotal] = useState(null)
    const [limit, setLimit] = useState(null)
    const [isMine, setIsMine] = useState(false)
    const [password, setPassword] = useState()
    const [time, setTime] = useState()
    const [voteTime, setVoteTime] = useState()
    const [fromBidAmount, setFromBidAmount] = useState()
    const [toBidAmount, setToBidAmount] = useState()
    const [fromAmount, setFromAmount] = useState()
    const [status, setStatus] = useState()
    const [onlyBOT, setOnlyBOT] = useState(false)

    const [claimed, setClaimed] = useState(true)

    const [voteStatus, setVoteStatus] = useState()
    const [voteCount, setVoteCount] = useState()

    const [myVote, setMyVote] = useState('0')

    const [voted, setVoted] = useState(false)

    const [projectInfo, setProjectInfo] = useState()


    const checkMyFSPool = async () => {
        const fsContract = getContract(library, SVBounce.abi, getSVFSAddress(chainId))
        let myPoolIndex = await fsContract.methods.myFP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const fromAmount = await fsContract.methods.amountTotal0FP(myPoolIndex).call()
            const bidAmount = await fsContract.methods.amountSwap0FP(myPoolIndex).call()
            console.log('bid amount', fromAmount, bidAmount)
            if (fromAmount === bidAmount) {
                setClaimed(true)
            } else {
                setClaimed(false)
            }
        } else {
            setClaimed(true)
        }
    }


    async function getFSPoolDetail() {
        try {

            const fsContract = getContract(library, SVBounce.abi, getSVFSAddress(chainId))

            fsContract.methods.poolInfo(id).call().then((res) => {
                console.log('query fs poolInfo:', res)
                setProjectInfo(JSON.parse(res))
            })

            fsContract.methods.myVotes(account, id).call().then((res) => {
                console.log('query fs password:', res)
                setMyVote(res)
                if (!isEqualTo(res, '0')) {
                    setVoted(true)
                }
            })

            fsContract.methods.passwordFP(id).call().then((res) => {
                console.log('query fs password:', res)
                setPassword(res)
            })

            fsContract.methods.totalVotes(id).call().then((res) => {
                setVoteCount(res)
            })

            fsContract.methods.enabled(id).call().then((res) => {
                console.log('query fs password:', res)

                if (res) {
                    setVoteStatus('Successfully')

                    fsContract.methods.closeAtFP(id).call().then((res) => {

                        const date = new Date(res * 1000);
                        const now = new Date();
                        const leftTime = date - now;
                        console.log('query fs closeAt:', res, leftTime)

                        setTime(res)
                        setStatus(leftTime > 0 ? 'Live' : 'Closed')
                        fsContract.methods.amountSwap1FP(id).call().then((bidAmount) => {
                            console.log('query fs to bid amount:', bidAmount)
                            setToBidAmount(bidAmount)
                            fsContract.methods.amountTotal1FP(id).call().then((total) => {
                                console.log('query fs to total:', total)
                                setToAmount(total)
                                if (bidAmount === total) {
                                    setStatus('Filled')
                                }
                            })
                        })


                    })

                } else {
                    fsContract.methods.createAt(id).call().then(time => {
                        // const date = new Date(time * 1000 + 48*60*60*1000);
                        const date = new Date(time * 1000 + 48 * 60 * 60 * 1000);
                        setVoteTime(time)
                        const now = new Date();
                        const leftTime = date - now;
                        if (leftTime > 0) {
                            console.log('Pending')
                            setVoteStatus('Pending')
                        } else {
                            console.log('Unsuccessfully')
                            setVoteStatus('Unsuccessfully')
                        }
                    })
                    fsContract.methods.creatorFP(id).call().then((res) => {
                        console.log('query fs creator:', res)
                        setIsMine((res.toLowerCase() === account.toLowerCase()))
                        if (res.toLowerCase() === account.toLowerCase()) {
                            fsContract.methods.closeAtFP(id).call().then((res) => {

                                const date = new Date(res * 1000);
                                const now = new Date();
                                const leftTime = date - now;
                                console.log('query fs closeAt:', res, leftTime)

                                setTime(res)
                                setStatus(leftTime > 0 ? 'Live' : 'Closed')
                                fsContract.methods.amountSwap1FP(id).call().then((bidAmount) => {
                                    console.log('query fs to bid amount:', bidAmount)
                                    setToBidAmount(bidAmount)
                                    fsContract.methods.amountTotal1FP(id).call().then((total) => {
                                        console.log('query fs to total:', total)
                                        setToAmount(total)
                                        if (bidAmount === total) {
                                            setStatus('Filled')
                                        }
                                    })
                                })


                            })
                        }
                    })

                }
            })


            fsContract.methods.creatorFP(id).call().then((res) => {
                console.log('query fs creator:', res)
                setIsMine((res.toLowerCase() === account.toLowerCase()))
                if (res.toLowerCase() === account.toLowerCase()) {
                    checkMyFSPool()
                }
            })

            fsContract.methods.nameFP(id).call().then((res) => {
                console.log('query fs name:', res)
                setName(res)
            })

            fsContract.methods.onlyBotHolder(id).call().then((res) => {
                console.log('query fs name:', res)
                setOnlyBOT(res)
            })

            fsContract.methods.token0FP(id).call().then((res) => {
                console.log('query fs address:', res)
                setAddress(res)
                const tokenContract = getContract(library, bounceERC20.abi, res)
                tokenContract.methods.symbol().call().then((res) => {
                    console.log('query fs symbol:', res)
                    setSymbol(res)
                })
                tokenContract.methods.decimals().call().then((res) => {
                    console.log('query fs decimals:', res)
                    setDecimals(res)
                })
            })

            fsContract.methods.token1FP(id).call().then((res) => {
                console.log('query fs to address:', res)
                if (new BigNumber(res).isEqualTo('0')) {
                    setToAddress(null)
                    setToSymbol(Psymbol)
                } else if (res.toLowerCase() === '0xdac17f958d2ee523a2206206994597c13d831ec7'.toLowerCase() || res.toLowerCase() === '0x101194a3FF67f83A05B3E15AfA52D45D588614ca'.toLowerCase()) {
                    setToAddress(res)
                    setToDecimals('6')
                    setToSymbol('USDT')
                } else if (res.toLowerCase() === '0x55d398326f99059ff775485246999027b3197955'.toLowerCase()) {
                    setToAddress(res)
                    setToDecimals('18')
                    setToSymbol('USDT')
                } else {
                    setToAddress(res)
                    setToDecimals('18')
                    setToSymbol('ETH')
                }

            })

            fsContract.methods.amountTotal0FP(id).call().then((res) => {
                console.log('query fs from total:', res)
                setFromAmount(res)
            })

            fsContract.methods.amountSwap0FP(id).call().then((res) => {
                console.log('query fs to bid amount:', res)
                setFromBidAmount(res)
            })


            fsContract.methods.maxEthPerWalletFP(id).call().then((res) => {
                console.log('query fs to total:', res)
                setLimit(res)
            })


        } catch (e) {
            console.log('getTokenInfo:', e)
        }
    }

    useEffect(() => {

        if (active, chainId, account) {
            console.log('chainId', chainId)
            getFSPoolDetail()
        } else {
            dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
        }

    }, [active, chainId, account])


    return {
        name,
        claimed,
        address,
        symbol,
        toAddress,
        toSymbol,
        toDecimals,
        decimals,
        fromTotal,
        toAmount,
        limit,
        time,
        password,
        fromBidAmount,
        toBidAmount,
        fromAmount,
        status,
        isMine,
        onlyBOT,
        voteCount,
        voteStatus,
        myVote,
        voted,
        projectInfo,
        voteTime
    }
}
