import {
  getBounce, getSealedBid, initWeb3, getContract, getStake, getAccountAddress, getLPToken, getLiquidity,
  getDutchAuctionV1, getDutchAuctionNFT, get721Contract, getGovernanceContract, getDutchAuctionERC1155, get1155Contract,
  getEnglishAuctionContract, getSBNFTAuctionContract
} from "../components/utils/web3";
import ClosedPools from '../components/utils/closed-pools';
import {getWei} from '../components/utils/web3'
import Web3 from "web3";
const {fromAscii, numberToHex, soliditySha3} = Web3.utils


const BigNumber = require('bignumber.js');


const BN = Web3.utils.BN;
export const TO = 'ETH';
export const ETH = 'ETH';
export const BOT = 'BOT';


export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const closedList = async () => {
  const web3 = await initWeb3();
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return ClosedPools
  } else if (id === 4) {
    return []
  }
  return []
};

//export const BOUNCE_ADDRESS = '0x4392E75b5f44f1943f4bA8BE9fa6e14931F0630d';
export const BOUNCE_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0x73282A63F0e3D7e9604575420F777361ecA3C86A'
  } else if (id === 4) {
    return '0x4392E75b5f44f1943f4bA8BE9fa6e14931F0630d'
  } else if (id === 97) {
    return '0x4ecF3A487E94D0e7eaBe5c8095921716317e737E'
  }
  return '0x73282A63F0e3D7e9604575420F777361ecA3C86A'
};


//last address 0x17059c7A6783AD5A102Fb3ee109e376E8613C229
export const STAKE_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
  } else if (id === 4) {
    return '0xa77A9FcbA2Ae5599e0054369d1655D186020ECE1'
  } else if (id === 97) {
    return '0xa1294459a47cBE0351eb26Bd84e0c0BcED42e824'
  }
  return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
};

export const BOUNCE_ERC20_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0x5bEaBAEBB3146685Dd74176f68a0721F91297D37'
  } else if (id === 4) {
    return '0xAbF690E2EbC6690c4Fdc303fc3eE0FBFEb1818eD'
  } else if (id === 97) {
    return '0x36587E2a0f890396F2B67CfEfDa5e3C475e4371e'
  }
};


export const BOUNCE_SEALED_BID_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0xF74Bf048138a2B8F825ECCaBed9e02E481A0F6C0'
  } else if (id === 4) {
    return '0xA1cEa127c79FE7378f68185C9BD2E97321f7c917'
  } else if (id === 97) {
    return '0xA1cEa127c79FE7378f68185C9BD2E97321f7c917'
  }
};

export const BOUNCE_DUTCH_AUCTION_V1ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0xA02556A258a4bcC6582b3B1d99d5299d88b907c5'
  } else if (id === 4) {
    return '0x801B7385B52B27aCE6BFca25Fa1E399A065ee3E6'
  } else if (id === 97) {
    return ''
  }
};

export const BOUNCE_DUTCH_AUCTION_NFT_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0x953Bad68D1F079D4eD7Bd03e5772bA00974F776b'
  } else if (id === 4) {
    return '0xf43E82a8B3B636d84EbFD04885e008c3B55E232B'
  } else if (id === 97) {
    return ''
  }
};

// ERC1155
export const BOUNCE_DUTCH_AUCTION_ERC1155_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
  } else if (id === 4) {
    return '0xFaF1bd19516F3aA2D1F0706e21aEB194d62c2190'
  } else if (id === 97) {
    return ''
  }
};


export const LIQUIDITY_POOL_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0xd17b4b476006ccd008E3FE8B9d96AF4F787fe671'
  } else if (id === 4) {
    return '0x2D65038E422e60FD4861467dbDC2a1C844F3e768'
  } else if (id === 97) {
    return '0xd17b4b476006ccd008E3FE8B9d96AF4F787fe671'
  }
};

export const LP_TOKEN_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0xE444f079e8f69ee32b2707974b5c994Fa7DD944A'
  } else if (id === 4) {
    return '0x3dd8ebff190feb763235ccee3107e0d8fd0d4f52'
  } else if (id === 97) {
    return '0x3dd8ebff190feb763235ccee3107e0d8fd0d4f52'
  }
  return '0xE444f079e8f69ee32b2707974b5c994Fa7DD944A'
};

export const BOUNCE_GOVERNANCE_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
  } else if (id === 4) {
    return '0xa77A9FcbA2Ae5599e0054369d1655D186020ECE1'
  } else if (id === 97) {
    return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
  }
  return '0x98945BC69A554F8b129b09aC8AfDc2cc2431c48E'
};

export const BOUNCE_ENGLISH_AUCTION_NFT_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0x64F8047bf407Ec71AAAE6EE145F34D36d97dC416'
  } else if (id === 4) {
    return '0x8EeDc275F99c3Faa534d194516d4921C827451DD'
  } else if (id === 97) {
    return '0x64F8047bf407Ec71AAAE6EE145F34D36d97dC416'
  }
  return '0x64F8047bf407Ec71AAAE6EE145F34D36d97dC416'
};

export const BOUNCE_SEALED_BID_NFT_AUCTION_ADDRESS = async (web3) => {
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return '0x9ceCbBe32C9bEE1Df5C66e009794e4acD47aA820'
  } else if (id === 4) {
    return '0x9992c4141229277F6ce98Ca6a8173f2cbaDbFD99'
  } else if (id === 97) {
    return '0x9992c4141229277F6ce98Ca6a8173f2cbaDbFD99'
  }
  return '0x9992c4141229277F6ce98Ca6a8173f2cbaDbFD99'
};

//export const BOUNCE_ERC20_ADDRESS = '0xAbF690E2EbC6690c4Fdc303fc3eE0FBFEb1818eD';
//export const BOUNCE_ERC20_ADDRESS = '0x5bEaBAEBB3146685Dd74176f68a0721F91297D37';

export const getSummaryAccount = (account) => {
  if (!account) {
    return '';
  }
  const first6 = account.substring(0, 6);
  const last4 = account.substring(account.length - 4);
  return `${first6}...${last4}`;
}

export const handleNetwork = (network) => {
  switch (network) {
    case '1':
      return 'Ethereum Mainnet';
    case '2':
      return 'Morden Testnet (deprecated)';
    case '3':
      return 'Ropsten Testnet';
    case '4':
      return 'Rinkeby Testnet';
    case '42':
      return 'Kovan Testnet';
    default:
      return 'Unknow Network';
  }
}

export const getTransactionLink = async (hash) => {
  // return `https://rinkeby.etherscan.io/tx/${hash}`;
  //return `https://etherscan.io/tx/${hash}`;
  const web3 = await initWeb3();
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return `https://etherscan.io/tx/${hash}`;
  } else if (id === 4) {
    return `https://rinkeby.etherscan.io/tx/${hash}`;
  }
}

export const getContractLink = async (hash) => {
  //return `https://etherscan.io/token/${hash}`
  const web3 = await initWeb3();
  const id = await web3.eth.net.getId();
  if (id === 1) {
    return `https://etherscan.io/token/${hash}`
  } else if (id === 4) {
    return `https://rinkeby.etherscan.io/token/${hash}`;
  }
}

export const getRatio = (pool) => {
  const from = Web3.utils.toBN(Web3.utils.toWei(pool.from))
  const amount = Web3.utils.toBN(Web3.utils.toWei(pool.amount))
  return Web3.utils.fromWei(from.div(amount), getWei(pool.decimals));
}

export const getBidRatio = (pool) => {
  const amountTotal = new BigNumber(Web3.utils.fromWei(pool.amountTotal, getWei(pool.decimals)))
  const amountMin = new BigNumber(pool.amountMin)
  return new BigNumber(amountTotal.div(amountMin).toFixed(8)).toNumber();
}

export const loadRatio = (amount1, amount2, decimals1, decimals2) => {
  const number1 = new BigNumber(Web3.utils.fromWei(amount1, getWei(decimals1)))
  const number2 = new BigNumber(Web3.utils.fromWei(amount2, getWei(decimals2)))
  return new BigNumber(number1.dividedBy(number2).toFixed(8)).toNumber();
}

const queryData = async (bounce, method, index) => {
  return await bounce.methods[method](index)
}

const queryGovernanceData = async (governance, item, id) => {
  try {
    let data;
    if (item.beString) {
      data = await governance.methods.getConfigString(Web3.utils.fromAscii(item.key), id).call();
    } else {
      data = await governance.methods.getConfig(Web3.utils.fromAscii(item.key), id).call();
    }
    if (item.isJson) {
      data = JSON.parse(data)
    }
    if (item.isByte) {
      data = Web3.utils.toAscii(Web3.utils.numberToHex(data))
    }
    return data
  } catch (e) {

  }

}

export const queryToken = async () => {
  const bounce = await getBounce();
  const count = await bounce.methods.tokenCount()
      .call()
      .then(r => r)
      .catch(err => {
        console.log(err);
      });
  return count;
}

export const keyMap = [{
  key: 'creatorFP',
  name: 'creator',
  needTrans: false,
}, {
  key: 'nameFP',
  name: 'name',
  needTrans: false,
}, {
  key: 'token0FP',
  name: 'fromAddress',
  needTrans: false,
}, {
  key: 'token1FP',
  name: 'toAddress',
  needTrans: false,
}, {
  key: 'passwordFP',
  name: 'password',
  needTrans: false,
}, {
  key: 'amountTotal0FP',
  name: 'from',
  needTrans: false,
}, {
  key: 'amountSwap0FP',
  name: 'to',
  needTrans: false,
}, {
  key: 'amountTotal1FP',
  name: 'amount',
  needTrans: true,
}, {
  key: 'maxEthPerWalletFP',
  name: 'maxEthPreWallet',
  needTrans: true,
}, {
  key: 'amountSwap1FP',
  name: 'swap',
  needTrans: true,
}, {
  key: 'closeAtFP',
  name: 'time',
}, {
  key: 'onlyBotHolder',
  name: 'onlyBot',
  needTrans: false
}];

export const bidKeyMap = [{
  key: 'creatorP',
  name: 'creator',
  needTrans: false,
}, {
  key: 'nameP',
  name: 'name',
  needTrans: false,
}, {
  key: 'token0P',
  name: 'fromAddress',
  needTrans: false,
}, {
  key: 'passwordP',
  name: 'password',
  needTrans: false,
}, {
  key: 'amountTotal0P',
  name: 'amountTotal',
  needTrans: false,
}, {
  key: 'amountMin1P',
  name: 'amountMin',
  needTrans: true,
}, {
  key: 'closeAtP',
  name: 'time',
}, {
  key: 'onlyBotHolder',
  name: 'onlyBot',
  needTrans: false
}, {
  key: 'creatorClaimedP',
  name: 'creatorClaimed',
  needTrans: false
}, {
  key: 'minEthBidP',
  name: 'minEthPerWallet',
  needTrans: true
}];


export const dutchKeyMap = [{
  key: 'creator',
  name: 'creator',
}, {
  key: 'name',
  name: 'name',
}, {
  key: 'token0',
  name: 'fromAddress',
}, {
  key: 'amountTotal0',
  name: 'tokenTotal',
}, {
  key: 'amountMax1',
  name: 'maxPrice',
}
  , {
    key: 'amountMin1',
    name: 'minPrice',
  }, {
    key: 'times',
    name: 'times',
  }, {
    key: 'closeAt',
    name: 'time',
  }];

export const englishAuctionKeyMap = [{
  key: 'creator',
  name: 'creator',
}, {
  key: 'name',
  name: 'name',
}, {
  key: 'token0',
  name: 'fromAddress',
}, {
  key: 'amountTotal0',
  name: 'tokenTotal',
}, {
  key: 'amountMin1',
  name: 'startingPrice',
}, {
  key: 'amountMinIncr1',
  name: 'incr',
}, {
  key: 'confirmTime',
  name: 'curTime',
}, {
  key: 'nftType',
  name: 'nftType',
}, {
  key: 'closeAt',
  name: 'time',
}];

export const governanceKeyMap = [{
  key: soliditySha3('proposer'),
  name: 'creator'
}, {
  key: soliditySha3('proposeSubject'),
  name: 'title',
  beString: true,
}, {
  key: soliditySha3('proposeContent'),
  name: 'content',
  beString: true,
  isJson: true
}, {
  key: soliditySha3('timePropose'),
  name: 'time',
}, {
  key: soliditySha3('proposeStatus'),
  name: 'voteResult',
  isByte: true
}];

export const queryReward = async (web3, account) => {
  const stake = await getStake(web3);
  const reward = await stake.methods.calculateRewardInBot(account).call();
  console.log('reward:', reward)
  return reward
}

export const queryGvReward = async (web3, account) => {
  const gv = await getGovernanceContract(web3);
  const gvReward = await gv.methods.getGovReward(account).call();
  console.log('reward:', gvReward)
  return gvReward
}

export const queryWithdraw = async (web3, account) => {
  const stake = await getStake(web3);
  const withdraw = await stake.methods.calculateWithdraw(account).call();
  console.log('queryWithdraw:', withdraw)
  return withdraw
}

export const queryStake = async (web3, account) => {
  const stake = await getStake(web3);
  const stackData = await stake.methods.myTotalStake(account).call();
  console.log('queryStake:', stackData)
  return stackData
}

export const queryLPStakedAmount = async (web3, account) => {
  const liquidity = await getLiquidity(web3);
  const amount = await liquidity.methods.stakingOf(account).call();
  console.log('queryStake:', amount)
  return amount
}

export const queryTotalLPStaked = async (web3, account) => {
  const liquidity = await getLiquidity(web3);
  const amount = await liquidity.methods.totalStaking().call();
  console.log('queryStake:', amount)
  return amount
}

export const queryBotRewardsPerCycle = async (web3, account) => {
  const liquidity = await getLiquidity(web3);
  const amount = await liquidity.methods.getBonusPerDay().call();
  console.log('queryStake:', amount)
  return amount
}

export const queryHarvestCapacity = async (web3, account) => {
  const liquidity = await getLiquidity(web3);
  const capacity = await liquidity.methods.harvestCapacity(account).call();
  console.log('queryStake:', capacity)
  return capacity
}

export const queryUnStake = async (web3, account) => {
  const stake = await getStake(web3);
  const unStackData = await stake.methods.calculateUnStake(account).call();
  console.log('queryUnStake:', unStackData)
  return unStackData
}

export const queryTotalStaked = async (web3, account) => {
  const stake = await getStake(web3);
  const totalStaked = await stake.methods.totalStake().call();
  console.log('queryUnStake:', totalStaked)
  return totalStaked
}

export const queryBotBalance = async () => {
  const stake = await getStake();
  const currentAccountAddress = await getAccountAddress();
  const stackData = await stake.methods.calculateStake(currentAccountAddress).call();
  console.log('queryStake:', stackData)
  return stackData
}

export const queryLPTokenBalance = async (web3, account) => {
  const lpToken = await getLPToken(web3);
  const balance = await lpToken.methods.balanceOf(account).call();
  console.log('LP Token balance:', balance)
  return balance
}


export const queryGovernanceList = async (web3, account) => {
  const governanceContract = await getGovernanceContract(web3);
  const index = await governanceContract.methods.getConfig(web3.utils.fromAscii('proposes'), 0).call();
  let id = index;
  let position = 0;
  let governanceList = [];
  let governance = null;
  let governanceID = 0;
  while (id != 0) {
    governanceID = await governanceContract.methods.getConfig(web3.utils.fromAscii('proposes'), id).call();
    governance = await queryGovernanceItem(web3, id)

    if ((governance.voteResult.slice(0,'PROPOSE_STATUS_PASS'.length)) === 'PROPOSE_STATUS_PASS') {
      governance.status = 'Passed'
    } else if (governance.voteResult.slice(0, 'PROPOSE_STATUS_FAIL'.length) === 'PROPOSE_STATUS_FAIL') {
      governance.status = 'Failed'
    } else {
      governance.status = 'Live'
    }
    governance.position = position;
    console.log('queryGovernance creator', id, Web3.utils.numberToHex(governance.creator))
    governance.yesCount = await governanceContract.methods.getVotes(Web3.utils.numberToHex(id), Web3.utils.fromAscii('VOTE_YES')).call()
    governance.noCount = await governanceContract.methods.getVotes(Web3.utils.numberToHex(id), Web3.utils.fromAscii('VOTE_NO')).call()
    governance.cancelCount = await governanceContract.methods.getVotes(Web3.utils.numberToHex(id), Web3.utils.fromAscii('VOTE_CANCEL')).call()
    console.log('query governance detail', (id))
    governanceList = governanceList.concat(governance)
    console.log('query governance', governance)
    id = governanceID
    position++
  }
  console.log('queryGovernanceList:', index)
  return governanceList
}


export const queryBidPoolItem = async (web3, index) => {
  const bounce = await getSealedBid(web3);
  const info = await Promise.all(bidKeyMap.map(async item => {
    const pool = {};
    const data = await queryData(bounce, item.key, index);
    const value = item.needTrans ? web3.utils.fromWei(new BN(data)) : data;
    pool[item.name] = value;
    return pool;
  }));

  const data = {};
  data['index'] = index;
  for (let i = 0; i < info.length; i++) {
    const item = info[i];
    const name = bidKeyMap[i]['name'];
    data[name] = item[name];
  }
  if (data.fromAddress) {
    const contract = await getContract(web3, data.fromAddress);
    const symbol = await contract.methods.symbol()
        .call()
        .then(r => r)
        .catch(e => {
          console.log(e);
        });
    const decimals = await contract.methods.decimals().call()
    data['fromSymbol'] = symbol;
    data['decimals'] = decimals
  } else {
    data['fromSymbol'] = '';
  }
  data.type = 'bid';
  let status;
  let bidStatus;
  const isExpired = new Date(data.time * 1000) <= Date.now();

  if (!isExpired) {
    status = 'Live';
  } else {
    status = 'Closed';
  }
  data.status = status;
  data.bidStatus = bidStatus

  return data;
};

export const queryDutchPoolItem = async (web3, index) => {
  const dutch = await getDutchAuctionV1(web3);
  let data = await queryData(dutch, 'pools', index);
  data = JSON.parse(JSON.stringify(data));
  data.index = index;
  dutchKeyMap.forEach(item => {
    data[item.name] = data[item.key]
  })
  console.log('data', data)
  if (data.token0) {
    const contract = await getContract(web3, data.token0);
    const symbol = await contract.methods.symbol()
        .call()
        .then(r => r)
        .catch(e => {
          console.log(e);
        });
    data['fromSymbol'] = symbol;
    data['decimals'] = await contract.methods.decimals().call()
  } else {
    data['fromSymbol'] = '';
  }
  data.type = 'dutch';
  let status = 'Closed';
  const isExpired = new Date(data.time * 1000) <= Date.now();
  const biddenAmount = await queryDutchBiddenAmount(web3, index);
  data.biddenAmount = biddenAmount;
  data.password = await queryData(dutch, 'passwordP', index);
  if (!isExpired) {
    if (biddenAmount === data.tokenTotal) {
      status = 'Filled';
    } else {
      status = 'Live';
    }
  } else {
    status = 'Closed';
  }
  data.status = status
  return data;
};

export const queryMyNFT1155Pool = async (web3, account) => {
  const nft = await getDutchAuctionERC1155(web3);
  const poolIndex = await nft.methods.myCreatedP(account).call();
  if (poolIndex > 0) {
    const pool = await queryNFT1155PoolItem(web3, poolIndex - 1);
    return pool
  } else {
    return null
  }
}

export const queryNFTPoolItem = async (web3, index) => {
  const nft = await getDutchAuctionNFT(web3);
  let data = await queryData(nft, 'pools', index);
  data = JSON.parse(JSON.stringify(data));
  data.index = index;
  dutchKeyMap.forEach(item => {
    data[item.name] = data[item.key]
  })
  console.log('data', data)
  if (data.token0) {
    const contract = await get721Contract(web3, data.token0);
    const symbol = await contract.methods.symbol().call()
    const tokenURl = await contract.methods.tokenURI(data['3']).call()
    if (tokenURl) {
      try {
        const res = await fetch(tokenURl)
        const result = await res.json();
        if (result.image) {
          data.cover = result.image
        } else if (result.properties.image.description) {
          data.cover = result.properties.image.description
        }
      } catch (e) {
        console.log('fetch error', tokenURl, e)
      }
    }

    data['fromSymbol'] = symbol;
  } else {
    data['fromSymbol'] = '';
  }
  data.type = 'nft';
  let status = 'Closed';
  const isExpired = new Date(data.time * 1000) <= Date.now();
  const biddenAmount = await queryDutchBiddenAmount(web3, index);
  data.biddenAmount = biddenAmount;
  data.password = await queryData(nft, 'passwordP', index);
  if (!isExpired) {
    if (biddenAmount === data.tokenTotal) {
      status = 'Filled';
    } else {
      status = 'Live';
    }
  } else {
    status = 'Closed';
  }
  data.status = status
  return data;
};

export const queryPoolItem = async (web3, index) => {
  const bounce = await getBounce(web3);
  const info = await Promise.all(keyMap.map(async item => {
    const pool = {};
    const data = await queryData(bounce, item.key, index);
    const value = item.needTrans ? web3.utils.fromWei(new BN(data)) : data;
    pool[item.name] = value;
    return pool;
  }));
  const data = {};
  data['index'] = index;
  for (let i = 0; i < info.length; i++) {
    const item = info[i];
    const name = keyMap[i]['name'];
    data[name] = item[name];
  }
  if (data.fromAddress) {
    const contract = await getContract(web3, data.fromAddress);
    const symbol = await contract.methods.symbol()
        .call()
        .then(r => r)
        .catch(e => {
          console.log(e);
        });
    data['fromSymbol'] = symbol;
    data['decimals'] = await contract.methods.decimals().call()
  } else {
    data['fromSymbol'] = '';
  }
  data.type = 'swap';
  let status;
  const isExpired = new Date(data.time * 1000) <= Date.now();
  const swap = data.swap;
  const amount = data.amount;
  const isFilled = new BN(web3.utils.toWei(swap)).gte(new BN(web3.utils.toWei(amount)));
  if (!isFilled && !isExpired) {
    status = 'Live';
  } else if (isFilled) {
    if (!isExpired) {
      status = 'Filled';
    } else {
      status = 'Closed';
    }
  } else {
    status = 'Closed';
  }
  data.status = status
  return data;
};

export const queryNFT1155PoolItem = async (web3, index) => {
  const nft = await getDutchAuctionERC1155(web3);
  let data = await queryData(nft, 'pools', index);
  console.log('queryNFT1155PoolItem:', data)
  data = JSON.parse(JSON.stringify(data));
  data.index = index;
  dutchKeyMap.forEach(item => {
    data[item.name] = data[item.key]
  })
  console.log('data', data)
  if (data.token0) {
    const contract = await get1155Contract(web3, data.token0);
    const tokenURl = await contract.methods.uri(data.tokenId).call()
    if (tokenURl) {
      try {
        const res = await fetch(tokenURl, {mode: 'cors'})
        const result = await res.json();
        if (result.image) {
          data.cover = result.image
        } else if (result.properties.image.description) {
          data.cover = result.properties.image.description
        }
      } catch (e) {
        console.log('fetch error', tokenURl, e)
      }
    }

    data['fromSymbol'] = 'NFT1155';
  } else {
    data['fromSymbol'] = 'NFT1155';
  }
  data.type = 'nft1155';
  let status = 'Closed';
  const isExpired = new Date(data.closeAt * 1000) <= Date.now();
  const biddenAmount = await queryDutchBiddenAmount(web3, index);
  data.biddenAmount = biddenAmount;
  data.password = await queryData(nft, 'passwordP', index);
  if (!isExpired) {
    if (biddenAmount === data.tokenTotal) {
      status = 'Filled';
    } else {
      status = 'Live';
    }
  } else {
    status = 'Closed';
  }
  data.status = status
  return data;
};

export const queryEnglishAuctionPoolItem = async (web3, index) => {
  const auctionContract = await getEnglishAuctionContract(web3);
  let data = await queryData(auctionContract, 'pools', index);
  console.log('queryEnglishAuctionPoolItem:', data, auctionContract)
  data = JSON.parse(JSON.stringify(data));
  data.index = index;
  englishAuctionKeyMap.forEach(item => {
    data[item.name] = data[item.key]
  })
  if (data.nftType === '0') {
    console.log('token 0---->', data.token0, data.tokenId)
    const contract = await get721Contract(web3, data.token0);
    const symbol = await contract.methods.symbol().call()
    const tokenURl = await contract.methods.tokenURI(data.tokenId).call()
    if (tokenURl) {
      try {
        const res = await fetch(tokenURl)
        const result = await res.json();
        if (result.image) {
          data.cover = result.image
        } else if (result.properties.image.description) {
          data.cover = result.properties.image.description
        }
      } catch (e) {
        console.log('fetch error', tokenURl, e)
      }
    }

    data['fromSymbol'] = symbol;
  } else {

    try {
      const contract = await get1155Contract(web3, data.token0);
      const tokenURl = await contract.methods.uri(data.tokenId).call()
      const res = await fetch(tokenURl)
      const result = await res.json();
      if (result.image) {
        data.cover = result.image
      } else if (result.properties.image.description) {
        data.cover = result.properties.image.description
      }
    } catch (e) {
      console.log('fetch error', e)
    }
    data['fromSymbol'] = 'NFT1155';
  }
  data.type = 'englishAuction';
  let status = 'Closed';
  const isExpired = new Date(data.closeAt * 1000) <= Date.now();
  data.password = await queryData(auctionContract, 'passwordP', index);
  if (!isExpired) {
    status = 'Live';
  } else {
    status = 'Closed';
  }
  data.status = status
  return data;
};

export const querySBNFTAuctionPoolItem = async (web3, index) => {
  const auctionContract = await getSBNFTAuctionContract(web3);
  let data = await queryData(auctionContract, 'pools', index);
  console.log('querySBNFTAuctionPoolItem:', data, auctionContract)
  data = JSON.parse(JSON.stringify(data));
  data.index = index;
  englishAuctionKeyMap.forEach(item => {
    data[item.name] = data[item.key]
  })
  if (data.nftType === '0') {
    console.log('token 0---->', data.token0, data.tokenId)
    const contract = await get721Contract(web3, data.token0);
    const symbol = await contract.methods.symbol().call()
    const tokenURl = await contract.methods.tokenURI(data.tokenId).call()
    if (tokenURl) {
      try {
        const res = await fetch(tokenURl)
        const result = await res.json();
        if (result.image) {
          data.cover = result.image
        } else if (result.properties.image.description) {
          data.cover = result.properties.image.description
        }
      } catch (e) {
        console.log('fetch error', tokenURl, e)
      }
    }

    data['fromSymbol'] = symbol;
  } else {

    try {
      const contract = await get1155Contract(web3, data.token0);
      const tokenURl = await contract.methods.uri(data.tokenId).call()
      const res = await fetch(tokenURl)
      const result = await res.json();
      if (result.image) {
        data.cover = result.image
      } else if (result.properties.image.description) {
        data.cover = result.properties.image.description
      }
    } catch (e) {
      console.log('fetch error', e)
    }
    data['fromSymbol'] = 'NFT1155';
  }
  data.type = 'englishAuction';
  let status = 'Closed';
  const isExpired = new Date(data.closeAt * 1000) <= Date.now();
  data.password = await queryData(auctionContract, 'passwordP', index);
  if (!isExpired) {
    status = 'Live';
  } else {
    status = 'Closed';
  }
  data.status = status
  return data;
};


export const queryGovernanceItem = async (web3, index) => {
  const governance = await getGovernanceContract(web3);
  const info = await Promise.all(governanceKeyMap.map(async item => {
    const boj = {};
    console.log('queryGovernanceItem key', item.key)
    const data = await queryGovernanceData(governance, item, index);
    console.log('queryGovernanceItem data:', data)
    boj[item.name] = data;
    return boj;
  }));
  const data = {};
  data['index'] = index;
  for (let i = 0; i < info.length; i++) {
    const item = info[i];
    const name = governanceKeyMap[i]['name'];
    data[name] = item[name];
  }
  return data;
};

export const queryPools = async (start) => {
  const tokenAddress = await BOUNCE_ERC20_ADDRESS();
  const bounce = await getBounce();
  const count = await bounce.methods.countFP()
      .call()
      .then(r => r)
      .catch(e => {
        console.log(e);
      })
  const prePage = 15;
  const pages = Math.ceil(count / prePage);
  const from = count;
  const to = count - prePage * start - prePage;
  const end = to >= 0 ? to : 0;
  let list = [];

  for (let i = from - 1; i >= end; --i) {
    if (!ClosedPools.includes(i)) {
      list.push(i);
    }
  }
  const info = await Promise.all(list.map(async (index) => {
    const data = await queryPoolItem(index);
    return data;
  }));
  const result = [];
  info.forEach(item => {
    console.log('isFake--->', item.fromSymbol)
    const isFake = item.fromSymbol.toUpperCase() === 'B0T'
        || (item.fromSymbol.toUpperCase() === 'BOT' && item.fromAddress !== tokenAddress);
    if (!isFake) {
      result.push(item);
    }
  })
  return {info: result, pages: pages};
}

export const queryPoolListLength = async (web3) => {
  const bounce = await getBounce(web3);
  return await bounce.methods.countFP()
      .call()
      .then(r => r)
      .catch(e => {
        console.log(e);
      });
}

export const queryBidPoolListLength = async (web3) => {
  const bounce = await getSealedBid(web3);
  return await bounce.methods.countP()
      .call()
      .then(r => r)
      .catch(e => {
        console.log(e);
      });
}

export const queryDutchPoolListLength = async (web3) => {
  const dutch = await getDutchAuctionV1(web3);
  return await dutch.methods.getPoolCount().call()
}

export const queryNFTPoolListLength = async (web3) => {
  const dutch = await getDutchAuctionNFT(web3);
  return await dutch.methods.getPoolCount().call()
}

export const queryNFT1155PoolListLength = async (web3) => {
  const nft = await getDutchAuctionERC1155(web3);
  return await nft.methods.getPoolCount().call()
}

export const queryEnglishAuctionPoolListLength = async (web3) => {
  const auction = await getEnglishAuctionContract(web3);
  return await auction.methods.getPoolCount().call()
}

export const querySBNFTAuctionPoolListLength = async (web3) => {
  const auction = await getSBNFTAuctionContract(web3);
  return await auction.methods.getPoolCount().call()
}

export const queryFPIndex = async (web3, account) => {
  const bounce = await getBounce(web3);
  return await bounce.methods.myFP(account)
      .call()
      .then(r => r)
      .catch(e => {
        console.log(e);
      });
}

export const queryBidFPIndex = async (web3, account) => {
  const bounce = await getSealedBid(web3);
  return await bounce.methods.myCreatedP(account)
      .call()
      .then(r => r)
      .catch(e => {
        console.log(e);
      });
}

export const querySBNFTFPIndex = async (web3, account) => {
  const bounce = await getSBNFTAuctionContract(web3);
  return await bounce.methods.myCreatedP(account)
      .call()
      .then(r => r)
      .catch(e => {
        console.log(e);
      });
}

export const queryMyPool = async (web3, account) => {
  const index = await queryFPIndex(web3, account);
  if (index > 0) {
    return await queryPoolItem(web3, index - 1);
  } else {
    return null;
  }
}

export const queryMyBidPool = async (web3, account) => {
  const index = await queryBidFPIndex(web3, account);
  if (index > 0) {
    return await queryBidPoolItem(web3, index - 1);
  } else {
    return null;
  }
}

export const queryMySBNFTPool = async (web3, account) => {
  const index = await querySBNFTFPIndex(web3, account);
  if (index > 0) {
    return await querySBNFTAuctionPoolItem(web3, index - 1);
  } else {
    return null;
  }
}

export const queryDutchBiddenAmount = async (web3, index) => {
  const dutch = await getDutchAuctionV1(web3);
  return await dutch.methods.amountSwap0P(index).call();
}

export const queryNFTBiddenAmount = async (web3, account, index) => {
  const nft = await getDutchAuctionNFT(web3);
  return await nft.methods.myPriceP(account, index).call();
}

export const queryNFT1155BiddenAmount = async (web3, account, index) => {
  const nft = await getDutchAuctionERC1155(web3);
  return await nft.methods.myPriceP(account, index).call();
}

export const queryEABiddenAmount = async (web3, account, index) => {
  const nft = await getEnglishAuctionContract(web3);
  return await nft.methods.myBidderAmount1P(account, index).call();
}

export const queryNFTCurBiddenAddress = async (web3, index) => {
  const nft = await getDutchAuctionNFT(web3);
  return await nft.methods.currentBidderP(index).call();
}

export const queryNFT1155CurBiddenAddress = async (web3, index) => {
  const nft = await getDutchAuctionERC1155(web3);
  return await nft.methods.currentBidderP(index).call();
}

export const queryEACurBiddenAddress = async (web3, index) => {
  const nft = await getEnglishAuctionContract(web3);
  return await nft.methods.currentBidderP(index).call();
}

export const queryEABiddenAddress = async (web3, index) => {
  const nft = await getEnglishAuctionContract(web3);
  return await nft.methods.currentBidderP(index).call();
}


export const checkNFTClaimStatus = async (web3, account, index) => {
  const nft = await getDutchAuctionNFT(web3);
  return await nft.methods.myClaimedP(account, index).call();
}

export const checkNFT1155ClaimStatus = async (web3, account, index) => {
  const nft = await getDutchAuctionERC1155(web3);
  return await nft.methods.myClaimedP(account, index).call();
}

export const checkEAClaimStatus = async (web3, account, index) => {
  const nft = await getEnglishAuctionContract(web3);
  return await nft.methods.myClaimedP(account, index).call();
}

export const checkNFTCreatorClaimStatus = async (web3, account, index) => {
  const nft = await getDutchAuctionNFT(web3);
  return await nft.methods.creatorClaimedP(index).call();
}

export const checkEACreatorClaimStatus = async (web3, account, index) => {
  const nft = await getEnglishAuctionContract(web3);
  return await nft.methods.creatorClaimedP(index).call();
}

export const checkNFT1155CreatorClaimStatus = async (web3, account, index) => {
  const nft = await getDutchAuctionERC1155(web3);
  return await nft.methods.creatorClaimedP(index).call();
}


export const queryMyDutchPool = async (web3, account) => {
  const dutch = await getDutchAuctionV1(web3);
  const poolIndex = await dutch.methods.myCreatedP(account).call();
  if (poolIndex > 0) {
    const swappedTokens = await dutch.methods.amountSwap0P(poolIndex - 1).call();
    const pool = await queryDutchPoolItem(web3, poolIndex - 1);
    console.log('queryMyDutchPool 1:', swappedTokens, pool.tokenTotal, pool);
    if (swappedTokens !== pool.tokenTotal) {
      return pool
    } else {
      return null
    }
  } else {
    return null
  }
}

export const queryMyNFTPool = async (web3, account) => {
  const nft = await getDutchAuctionNFT(web3);
  const poolIndex = await nft.methods.myCreatedP(account).call();
  console.log('queryMyNFTPool poolIndex', poolIndex)
  if (poolIndex > 0) {
    const pool = await queryNFTPoolItem(web3, poolIndex - 1);
    return pool
  } else {
    return null
  }
}

export const queryMyEAPool = async (web3, account) => {
  const nft = await getEnglishAuctionContract(web3);
  const poolIndex = await nft.methods.myCreatedP(account).call();
  console.log('queryMyNFTPool poolIndex', poolIndex)
  if (poolIndex > 0) {
    const pool = await queryEnglishAuctionPoolItem(web3, poolIndex - 1);
    return pool
  } else {
    return null
  }
}

export const checkNFTPoolStatus = async (web3, poolIndex) => {
  const pool = await queryNFTPoolItem(web3, poolIndex);
  const isExpired = new Date(pool.time * 1000) <= Date.now();
  const nftContract = await getDutchAuctionNFT(web3);
  const bidRound = await nftContract.methods.currentRoundP(poolIndex).call();
  const poolRound = await nftContract.methods.currentRound(poolIndex).call();
  console.log('checkNFTPoolStatus:', bidRound, poolRound)
  if ((bidRound != 0 && bidRound != poolRound) || isExpired) {
    return false
  } else {
    return true
  }
}

export const checkNFT1155PoolStatus = async (web3, poolIndex) => {
  const pool = await queryNFT1155PoolItem(web3, poolIndex);
  const isExpired = new Date(pool.time * 1000) <= Date.now();
  const nftContract = await getDutchAuctionERC1155(web3);
  const bidRound = await nftContract.methods.currentRoundP(poolIndex).call();
  const poolRound = await nftContract.methods.currentRound(poolIndex).call();
  console.log('checkNFTPoolStatus:', bidRound, poolRound)
  if ((bidRound != 0 && bidRound != poolRound) || isExpired) {
    return false
  } else {
    return true
  }
}

export const checkEAPoolStatus = async (web3, poolIndex) => {
  const pool = await queryEnglishAuctionPoolItem(web3, poolIndex);
  const isExpired = new Date(pool.time * 1000) <= Date.now();
  return !isExpired;
}

export const queryEACurRound = async (web3, poolIndex) => {
  const dutch = await getEnglishAuctionContract(web3);
  return await dutch.methods.bidCountP(poolIndex).call()
}

export const queryDutchCurPrice = async (web3, index) => {
  const dutch = await getDutchAuctionV1(web3);
  return await dutch.methods.currentPrice(index).call()
}

export const queryDutchCurTime = async (web3, index) => {
  console.log('queryDutchCurTime', index)
  const dutch = await getDutchAuctionV1(web3);
  return await dutch.methods.nextRoundInSeconds(index).call()
}

export const queryNFTCurPrice = async (web3, index) => {
  const dutch = await getDutchAuctionNFT(web3);
  return await dutch.methods.currentPrice(index).call()
}

export const queryNFT1155CurPrice = async (web3, index) => {
  const dutch = await getDutchAuctionERC1155(web3);
  return await dutch.methods.currentPrice(index).call()
}

export const queryEACurPrice = async (web3, index) => {
  const dutch = await getEnglishAuctionContract(web3);
  return await dutch.methods.currentBidderAmount1P(index).call()
}

export const queryNFTBiddenPrice = async (web3, index) => {
  const dutch = await getDutchAuctionNFT(web3);
  return await dutch.methods.currentBidderPriceP(index).call()
}

export const queryNFT1155BiddenPrice = async (web3, index) => {
  const dutch = await getDutchAuctionERC1155(web3);
  return await dutch.methods.currentBidderPriceP(index).call()
}

export const queryNFTCurTime = async (web3, index) => {
  console.log('queryDutchCurTime', index)
  const dutch = await getDutchAuctionNFT(web3);
  return await dutch.methods.nextRoundInSeconds(index).call()
}

export const queryNFT1155CurTime = async (web3, index) => {
  console.log('queryDutchCurTime', index)
  const dutch = await getDutchAuctionERC1155(web3);
  return await dutch.methods.nextRoundInSeconds(index).call()
}

export const queryEACurTime = async (web3, index) => {
  const dutch = await getEnglishAuctionContract(web3);
  console.log('queryDutchCurTime', dutch)

  return await dutch.methods.confirmTime(index).call()
}


export const queryBidenPool = async (web3, account, index) => {
  const bid = await getSealedBid(web3);
  console.log('biden pool account:', account)
  console.log('biden pool index:', index)
  return await bid.methods.myBidP(account, index).call()
}

export const queryBidenEth = async (web3, account, index) => {
  const bid = await getSealedBid(web3);
  return await bid.methods.myAmountBid1P(account, index).call()
}

export const querySBNFTBidenEth = async (web3, account, index) => {
  const bid = await getSBNFTAuctionContract(web3);
  return await bid.methods.myAmountBid1P(account, index).call()
}

export const queryBidenToken = async (web3, account, index) => {
  const bid = await getSealedBid(web3);
  return await bid.methods.myAmountBid0P(account, index).call()
}

export const querySBBidenAmount = async (web3, account, index) => {
  const bid = await getSBNFTAuctionContract(web3);
  return await bid.methods.myAmountBid1P(account, index).call()
}

export const queryMyPrice = async (web3, account, index) => {
  const bid = await getSealedBid(web3);
  return await bid.methods.myPrice(account, index).call()
}


export const queryMyBidCount = async (web3, account) => {
  const bid = await getSealedBid(web3);
  console.log('queryMyBidCount:', account)
  return await bid.methods.myBidCountP(account).call()
}

export const queryMySwapAmount = async (web3, account, index) => {
  const bounce = await getBounce(web3);
  console.log('queryMySwapAmount:', account)
  return await bounce.methods.myAmountSwapped1(account, index).call()
}

export const queryBidAddressCount = async (web3, index) => {
  console.log('queryBidAddressCount:', index)
  const bid = await getSealedBid(web3);
  return await bid.methods.bidderListCountP(index).call()
}

export const queryBidAddress = async (web3, index, poolIndex) => {
  console.log('queryBidAddress:', index)
  const bid = await getSealedBid(web3);
  return await bid.methods.bidderListP(index, poolIndex).call()
}

export const queryBidCreatorFilledAmount = async (web3, poolIndex) => {
  console.log('queryBidCreatorFilledAmount:', poolIndex)
  const bid = await getSealedBid(web3);
  return await bid.methods.creatorFilledAmount(poolIndex).call()
}

export const querySBNFTBidenSuccessAddress = async (web3, poolIndex) => {
  console.log('querySBNFTBidenAddress:', poolIndex)
  const bid = await getSBNFTAuctionContract(web3);
  console.log()
  const header = await bid.methods.bidderListHeaderP(poolIndex).call();
  if(new BigNumber(header).isGreaterThan(new BigNumber('10000000000'))){
    return 0
  }
  console.log('bidden header',poolIndex, header)
  return await bid.methods.bidderListP(poolIndex, header).call()
}

export const queryBidCreatorBidderFilledAmount = async (web3, poolIndex, account) => {
  console.log('queryBidCreatorBidderFilledAmount:', poolIndex)
  const bid = await getSealedBid(web3);
  return await bid.methods.bidderFilledAmount(account, poolIndex).call()
}

export const queryBidderClaimed = async (web3, account, poolIndex) => {
  console.log('queryBidCreatorBidderFilledAmount:', poolIndex)
  const bid = await getSealedBid(web3);
  return await bid.methods.myClaimedP(account, poolIndex).call()
}

export const querySBNFTBidderClaimed = async (web3, account, poolIndex) => {
  console.log('querySBNFTBidderClaimed:', poolIndex)
  const bid = await getSBNFTAuctionContract(web3);
  return await bid.methods.myClaimedP(account, poolIndex).call()
}

export const queryBidCreatorClaimed = async (web3, poolIndex) => {
  console.log('queryBidCreatorBidderFilledAmount:', poolIndex)
  const bid = await getSealedBid(web3);
  return await bid.methods.creatorClaimedP(poolIndex).call()
}

export const querySBNFTCreatorClaimed = async (web3, poolIndex) => {
  console.log('querySBNFTCreatorClaimed:', poolIndex)
  const bid = await getSBNFTAuctionContract(web3);
  return await bid.methods.creatorClaimedP(poolIndex).call()
}


