import FixedSwapABI from '../web3/abi/bounce.json'
import SealedBidABI from '../web3/abi/bounceSealedBid.json'

import {getContract, useActiveWeb3React} from "../web3";
import {getFixSwapAddress, getSealedBidAddress, getSVFSAddress} from "../web3/contractAddress";
import {useEffect, useState} from "react";
import SVBounce from "../web3/abi/SVBounce.json";
import sbABI from "../web3/abi/bounceSealedBid.json";

export const useMyFSPoolStatus = () => {
    const {active, account, library, chainId} = useActiveWeb3React();
    const [isLive, setIsLive] = useState(false)
    const [poolIndex, setPoolIndex] = useState()


    async function queryStatus() {
        const contract = getContract(library, FixedSwapABI.abi, getFixSwapAddress(chainId))
        let myPoolIndex = await contract.methods.myFP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const fromAmount = await contract.methods.amountTotal0FP(myPoolIndex).call()
            const bidAmount = await contract.methods.amountSwap0FP(myPoolIndex).call()
            console.log('my pool index', fromAmount, bidAmount)
            if (fromAmount !== bidAmount) {
                setIsLive(true)
                console.log('my pool index', myPoolIndex)
                setPoolIndex(myPoolIndex)
            }
        }
    }

    useEffect(() => {
        if (active) {
            queryStatus()
        }
    }, [account, active])

    return {isLive, poolIndex}
}

export const useMySBPoolStatus = () => {
    const {active, account, library, chainId} = useActiveWeb3React();
    const [isLive, setIsLive] = useState(false)
    const [poolIndex, setPoolIndex] = useState()

    const queryMySBPool = async () => {
        const contract = getContract(library, SealedBidABI.abi, getSealedBidAddress(chainId))
        console.log('my sealed bid pool id', contract, account)
        let myPoolIndex = await contract.methods.myCreatedP(account).call()
        console.log('my sealed bid pool id',myPoolIndex)
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await contract.methods.creatorClaimedP(myPoolIndex).call()
            if (!claimed) {
                setIsLive(true)
                setPoolIndex(myPoolIndex)
            }
        }
    }

    useEffect(() => {
        if (active) {
            queryMySBPool()
        }
    }, [account, active])

    return {isLive, poolIndex}
}