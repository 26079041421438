import { makeStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Helvetica Neue',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  item: {
    width: 'auto',
    fontSize: 16,
    fontWeight: 700,
    height: 24,
    marginRight: 16,
    lineHeight: '24px',
    padding: '0 8px',
    color: '#fff',
    backgroundColor: '#000',
    cursor: 'pointer',
  },
  current: {
    color: '#000',
    backgroundColor: '#fff',
    cursor: 'default',
  },
}));

const tabs = ['Voting', 'Governance Vault'];

const Tab = ({tab, onTab}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {tabs.map((item, index) => {
        return <div key={item}
          className={classNames(classes.item, index === tab && classes.current)}
          onClick={() => onTab(index)}
        >
          {item}
        </div>
      })}
    </div>
  )
}

export { Tab };