import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Return} from './Return';
import {queryMyPool, getTransactionLink} from './const';
import {ExpiredPool} from './content/ExpiredPool';
import '../static/image/success.svg';
import {Pool} from './Pool';
import {WaitContent} from './content/WaitContent';
import {RewardSuccess} from './content/RewardSuccess';
import {RewardFailed} from './content/RewardFailed';
import {DeniedContent} from './content/DeniedContent';
import web3 from 'web3';
import {myContext} from '../reducer'
import {useHistory} from 'react-router-dom';


const BN = web3.utils.BN;

export const PoolContent = ({
                              symbol,
                              account,
                            }) => {
  const {state, dispatch} = useContext(myContext);
  const history = useHistory();
  const [pool, setPool] = useState();
  const [status, setStatus] = useState('init');
  const [hash, setHash] = useState('');
  const [isFilled, setIsFilled] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);

  const {web3, myAccount, curPoolType} = state;


  useEffect(() => {
    async function loadAddress() {
      const address = await getTransactionLink(pool ? pool.fromAddress : '')
      setAddress(address);
    }

    loadAddress()
  }, [hash]);

  useEffect(() => {
    if (status === 'success') {
      dispatch({type: 'HANDLER_HAS_POOL', hasPool: false});
    }
  }, [status]);

  useEffect(() => {
    if (myAccount) {
      setLoading(false);
      queryMyPool(web3, myAccount)
          .then(r => {
            if (!r) {
              setLoading(false)
              setIsExpired(false)
            } else {
              const amount = r.amount;
              const swap = r.swap;
              const isFilled = new BN(web3.utils.toWei(swap)).gte(new BN(web3.utils.toWei(amount)));
              if (isFilled) {
                dispatch({type: 'HANDLER_HAS_POOL', hasPool: false});
              }
              setIsFilled(isFilled);
              const time = r.time * 1000;
              const now = new Date().getTime();
              setIsExpired(time < now);
              setPool(r);
              setLoading(false)
            }
          })
          .catch(e => {
            setLoading(false)
            console.log(e);
          })
    }
  }, [setIsFilled, setPool, myAccount, web3]);

  const handleClose = useCallback(() => {
    history.push('/')
    setStatus('init');
  }, [setStatus]);

  const renderPool = useCallback(() => {
    if (status === 'init') {
      if (isExpired) {
        return <ExpiredPool
            account={myAccount}
            onStatusChange={setStatus}
            onHashChange={setHash}
            web3={web3}
        />
      } else {
        const status = isFilled ? 'filled' : 'live';
        return <Pool
            symbol={symbol}
            pool={pool}
            account={account}
            status={status}
            loading={loading}
        />
      }
    } else {
      if (status === 'waiting') {
        return <WaitContent isCommon={true}/>
      } else if (status === 'success') {
        return <RewardSuccess link={address}
                              onClose={handleClose}/>
      } else if (status === 'failed') {
        return <RewardFailed onClose={handleClose}/>
      } else if (status === 'denied') {
        return <DeniedContent onClose={handleClose}/>
      }
    }
  }, [isExpired, isFilled, pool, account, symbol,
    handleClose, hash, status,
  ]);

  return (
      <div className='content join'>
        <Return type={curPoolType}/>
        <div className='form'>
          {renderPool()}
        </div>
      </div>
  )
}
