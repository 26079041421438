import Modal, { ModalContent, ModalIcon, ModalTitle } from "./Modal";
import { Button } from "./Button";
import React, { useState } from "react";
import { Select } from "./Select";
import { useHistory } from 'react-router-dom'
import fsABI from "../../web3/abi/bounce.json";
import daABI from "../../web3/abi/dutchAuctionV1.json";
import SVBounce from "../../web3/abi/SVBounce.json";
import AlpacaEnglishAuctionNFT from "../../web3/abi/AlpacaEnglishAuctionNFT.json";
import AlpacaFixedSwapNFT from "../../web3/abi/AlpacaFixedSwapNFT.json";
import BounceFSNFT from "../../web3/abi/BounceFSNFT.json";
import BounceSecuredLiquidity from "../../web3/abi/BounceSecuredLiquidity.json";

import {
    getBounceAlpacasFSNFTAddress,
    getBounceAlpacasNFTAddress, getBounceYGiftEAAddress, getBounceYGiftFSAddress,
    getEnglishAuctionNFTAddress,
    getFixSwapAddress, getFixSwapNFTAddress, getLiquidityAddress,
    getDutchAuction,
    getSealedBidAddress,
    getSealedBidNFTAddress,
    getSVFSAddress, getSVSBAddress
} from "../../web3/contractAddress";
import sbABI from "../../web3/abi/bounceSealedBid.json";
import SVSealedBid from "../../web3/abi/SVSealedBid.json";
import sbNFTABI from "../../web3/abi/bounceSealedBidNFT.json";
import eaNFTABI from "../../web3/abi/bounceEnglishAuctionNFT.json";
import { getContract, useActiveWeb3React } from "../../web3";
import icon_error from '../../static/image/icon-error.svg'


export const PoolTypeModal = ({ type, show, onConfirm, onDismiss, options }) => {
    const { active, account, library, chainId } = useActiveWeb3React();

    const [poolType, setPoolType] = useState();
    const [error, setError] = useState(false);
    const [checking, setChecking] = useState(false);
    const [liveIndex, setLiveIndex] = useState();
    const history = useHistory()


    const checkMyFSPool = async () => {
        setChecking(true)
        const fsContract = getContract(library, fsABI.abi, getFixSwapAddress(chainId))
        let myPoolIndex = await fsContract.methods.myFP(account).call()
        console.log('myPoolIndex:', myPoolIndex)
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const fromAmount = await fsContract.methods.amountTotal0FP(myPoolIndex).call()
            const bidAmount = await fsContract.methods.amountSwap0FP(myPoolIndex).call()
            const toAmount = await fsContract.methods.amountSwap1FP(myPoolIndex).call()
            const toBidAmount = await fsContract.methods.amountSwap1FP(myPoolIndex).call()
            // console.log('my fs pool', fromAmount, bidAmount)
            if (fromAmount === bidAmount || toAmount === toBidAmount) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkMyLLPool = async () => {
        setChecking(true)
        const llContract = getContract(library, BounceSecuredLiquidity.abi, getLiquidityAddress(chainId))
        let myPoolIndex = await llContract.methods.myP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const pools = await llContract.methods.pools(myPoolIndex).call()
            const fromAmount = pools.amountTotal0
            const bidAmount = pools.amountTotal1
            const toAmount = await llContract.methods.amountSwap0P(myPoolIndex).call()
            const toBidAmount = await llContract.methods.amountSwap1P(myPoolIndex).call()

            console.log('O_console:', fromAmount, bidAmount, toAmount, toBidAmount)
            // console.log('my fs pool', fromAmount, bidAmount)
            if (fromAmount === bidAmount || toAmount === toBidAmount) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkMyDAPool = async () => {
        setChecking(true)
        const daContract = getContract(library, daABI.abi, getDutchAuction(chainId))
        let myPoolIndex = await daContract.methods.myCreatedP(account).call()
        // console.log('checkMyDAPool:', myPoolIndex)
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const creatorClaimedP = await daContract.methods.creatorClaimedP(myPoolIndex).call()
            if (creatorClaimedP) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkMySVFSPool = async () => {
        setChecking(true)
        const fsContract = getContract(library, SVBounce.abi, getSVFSAddress(chainId))
        let myPoolIndex = await fsContract.methods.myFP(account).call()
        console.log('mysvPoolIndex:', myPoolIndex)
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const fromAmount = await fsContract.methods.amountTotal0FP(myPoolIndex).call()
            const bidAmount = await fsContract.methods.amountSwap0FP(myPoolIndex).call()
            console.log('my fs pool', fromAmount, bidAmount)
            if (fromAmount === bidAmount) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkmyFSNFTPool = async () => {
        setChecking(true)
        const fsContract = getContract(library, BounceFSNFT.abi, getFixSwapNFTAddress(chainId))
        let myPoolIndex = await fsContract.methods.myCreatedP(account).call()
        // console.log('myPoolIndex:', myPoolIndex)
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await fsContract.methods.creatorClaimedP(myPoolIndex).call()
            if (claimed) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkmyLLNFTPool = async () => {
        setChecking(true)
        const llContract = getContract(library, BounceSecuredLiquidity.abi, getFixSwapNFTAddress(chainId))
        let myPoolIndex = await llContract.methods.myCreatedP(account).call()
        // console.log('myPoolIndex:', myPoolIndex)
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await llContract.methods.creatorClaimedP(myPoolIndex).call()
            if (claimed) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkMyStoreFSNFTPool = async () => {
        // onConfirm(poolType)
        setChecking(true)
        const fsContract = getContract(library, AlpacaFixedSwapNFT.abi, getBounceAlpacasFSNFTAddress(chainId))
        let myPoolIndex = await fsContract.methods.myCreatedP(account).call()
        // console.log('myPoolIndex:', myPoolIndex)
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await fsContract.methods.creatorClaimedP(myPoolIndex).call()
            const swappedP = await fsContract.methods.swappedP(myPoolIndex).call()

            console.log('myPoolIndex:', claimed, swappedP)
            if (claimed || swappedP) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkMyYGiftFSNFTPool = async () => {
        onConfirm(poolType)
        setChecking(true)
        const fsContract = getContract(library, BounceFSNFT.abi, getBounceYGiftFSAddress(chainId))
        let myPoolIndex = await fsContract.methods.myCreatedP(account).call()
        console.log('myPoolIndex:', myPoolIndex)
        if (myPoolIndex > 0) {
            setLiveIndex(myPoolIndex)
            setError(true)
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkSBPool = async () => {
        setChecking(true)
        const contract = getContract(library, sbABI.abi, getSealedBidAddress(chainId))
        let myPoolIndex = await contract.methods.myCreatedP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await contract.methods.creatorClaimedP(myPoolIndex).call()
            if (claimed) {
                setLiveIndex(myPoolIndex)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkmySVSBPool = async () => {
        setChecking(true)
        const contract = getContract(library, SVSealedBid.abi, getSVSBAddress(chainId))
        let myPoolIndex = await contract.methods.myCreatedP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await contract.methods.creatorClaimedP(myPoolIndex).call()
            if (claimed) {
                setLiveIndex(myPoolIndex)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkSBNFTPool = async () => {
        setChecking(true)
        const contract = getContract(library, sbNFTABI.abi, getSealedBidNFTAddress(chainId))
        let myPoolIndex = await contract.methods.myCreatedP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await contract.methods.creatorClaimedP(myPoolIndex).call()
            if (claimed) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkEANFTPool = async () => {
        setChecking(true)
        const contract = getContract(library, eaNFTABI.abi, getEnglishAuctionNFTAddress(chainId))
        let myPoolIndex = await contract.methods.myCreatedP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await contract.methods.creatorClaimedP(myPoolIndex).call()
            if (claimed) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkStoreEANFTPool = async () => {
        setChecking(true)
        const contract = getContract(library, AlpacaEnglishAuctionNFT.abi, getBounceAlpacasNFTAddress(chainId))
        let myPoolIndex = await contract.methods.myCreatedP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await contract.methods.creatorClaimedP(myPoolIndex).call()
            if (claimed) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }

    const checkYGiftEANFTPool = async () => {
        setChecking(true)
        const contract = getContract(library, eaNFTABI.abi, getBounceYGiftEAAddress(chainId))
        let myPoolIndex = await contract.methods.myCreatedP(account).call()
        if (myPoolIndex > 0) {
            myPoolIndex = myPoolIndex - 1
            const claimed = await contract.methods.creatorClaimedP(myPoolIndex).call()
            if (claimed) {
                onConfirm(poolType)
            } else {
                setLiveIndex(myPoolIndex)
                setError(true)
            }
        } else {
            onConfirm(poolType)
        }
        setChecking(false)
    }


    const checkLotteryErc20Pool = async () => {
        onConfirm(poolType)
    }

    const loadTitle = () => {
        // const A_FilterOption = JSON.parse(window.localStorage.getItem('A_FilterOption') || null)
        // const type = A_FilterOption && A_FilterOption.poolType.key
        switch (type) {
            case 0:
                return 'Please select the type of auction you want to create in Token Sale'
            case 1:
                return 'Please select the type of auction you want to create in Token Sale'
            case 2:
                return 'Please select the type of auction you want to create in NFT Auction house'
            case 3:
                return 'Please select the type of auction you want to create in NFT Auction house'
            case 4:
                return 'please select the type of lottery you want to create in lottery'
            case 5:
                return 'please select the type of lottery you want to create in lottery'
            case 6:
                return 'Please select the type of auction you want to create in Token Sale'
            case 7:
                return 'Please select the type of auction you want to create in NFT Auction house'
            case 8:
                return 'Please select the type of auction you want to create in Social verified Pool'
            case 9:
                return 'Please select the type of auction you want to create in Social verified Pool'
            case 10:
                return 'Please select the type of auction you want to create in NFT Auction house'
            case 11:
                return 'Please select the type of prediction you want to create in prediction'
            case 12:
                return 'Please select the type of auction you want to create'
            default:
                return 'Please select the type of auction you want to create in Token Sale'
        }
    }

    return (
        <Modal closeable isOpen={show} onDismiss={() => {
            onDismiss()
        }}>
            <ModalTitle style={{ textAlign: 'left' }}>
                {loadTitle()}
            </ModalTitle>
            {error ? (
                <>
                    <ModalIcon><img src={icon_error} /></ModalIcon>
                    <ModalContent>You have an existing Pool, please come back after you close your existing pool</ModalContent>
                    <div style={{ width: 320, display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => {
                            onDismiss()
                        }} width={'154px'} >Go back</Button>
                        <Button onClick={() => {
                            switch (poolType.key) {
                                case 0:
                                    history.push(`/fixed-swap/${liveIndex}`, { id: liveIndex });
                                    break
                                case 1:
                                    history.push(`/sealed-bid/${liveIndex}`, { id: liveIndex });
                                    break
                                case 2:
                                    history.push(`/sealed-bid-nft/${liveIndex}`, { id: liveIndex });
                                    break
                                case 3:
                                    history.push(`/english-auction-nft/${liveIndex}`, { id: liveIndex });
                                    break
                                case 4:
                                    history.push(`/dutch-auction-nft/${liveIndex}`, { id: liveIndex });
                                    break
                                case 6:
                                    history.push(`/dutch-auction/${liveIndex}`, { id: liveIndex });
                                    break
                                case 8:
                                    history.push(`/sv-fixed-swap/${liveIndex}`, { id: liveIndex });
                                    break
                                case 9:
                                    history.push(`/sv-sealed-bid/${liveIndex}`, { id: liveIndex });
                                    break
                                case 10:
                                    history.push(`/fixed-swap-nft/${liveIndex}`, { id: liveIndex });
                                    break
                                case 11:
                                    // history.push(`/fixed-swap-nft/${liveIndex}`, { id: liveIndex });
                                    break
                                case 12:
                                    history.push(`/liquidity-lock-auction/${liveIndex}`, { id: liveIndex });
                                    break
                                case 100:
                                    history.push(`/store/english-auction-nft/${liveIndex}`, { id: liveIndex });
                                    break
                                case 101:
                                    history.push(`/store/fixed-swap-nft/${liveIndex}`, { id: liveIndex });
                                    break
                                case 200:
                                    history.push(`/store/ygift/english-auction-nft/${liveIndex}`, { id: liveIndex });
                                    break
                                case 201:
                                    history.push(`/store/ygift/fixed-swap-nft/${liveIndex}`, { id: liveIndex });
                                    break
                            }
                        }} black width={'154px'}>Pool status</Button>
                    </div>

                </>
            ) : (
                    <>
                        <div style={{ margin: '70px 0' }}>
                            <Select
                                onSelect={item => {
                                    setError(false)
                                    setPoolType(item)
                                }}
                                defaultContent={'Types of auctions'}
                                options={options} />
                        </div>
                        <div style={{ width: 320, display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={() => {
                                onDismiss()
                            }} width={'154px'}>Cancel</Button>
                            <Button style={{ backgroundColor: checking ? 'grey' : '' }} onClick={() => {
                                if (poolType.key === 0) {
                                    checkMyFSPool()
                                } else if (poolType.key === 1) {
                                    checkSBPool()
                                } else if (poolType.key === 2) {
                                    checkSBNFTPool()
                                } else if (poolType.key === 3) {
                                    checkEANFTPool()
                                } else if (poolType.key === 4) {
                                    onConfirm(poolType)
                                } else if (poolType.key === 5) {
                                    onConfirm(poolType)
                                } else if (poolType.key === 6) {
                                    checkMyDAPool()
                                } else if (poolType.key === 7) {
                                    onConfirm(poolType)
                                } else if (poolType.key === 8) {
                                    checkMySVFSPool(poolType)
                                } else if (poolType.key === 9) {
                                    checkmySVSBPool(poolType)
                                } else if (poolType.key === 10) {
                                    checkmyFSNFTPool(poolType)
                                } else if (poolType.key === 12) {
                                    checkMyLLPool()
                                } else if (poolType.key === 100) {
                                    checkStoreEANFTPool(poolType)
                                } else if (poolType.key === 101) {
                                    checkMyStoreFSNFTPool(poolType)
                                } else if (poolType.key === 200) {
                                    checkYGiftEANFTPool(poolType)
                                } else if (poolType.key === 201) {
                                    checkMyYGiftFSNFTPool(poolType)
                                }

                            }} width={'154px'} black>{checking ? 'Confirming' : 'Confirm'}</Button>
                        </div>
                    </>
                )}


            {/*{<Button width={'320px'} black>Go back</Button>}*/}
        </Modal>
    )
}
