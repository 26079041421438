import React, { useContext, useEffect, useState } from 'react';
import { useWeb3React } from "@web3-react/core";
import { myContext } from '../../reducer'
import styled, { css } from 'styled-components'
import { Button } from "../common/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import icon_arrow_left from '../../static/image/icon-arrow-left.svg'
import {walletList} from './connects'
import { HANDLE_SHOW_CONNECT_MODAL } from "../../const";

const WalletFrame = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  div:last-child{
    border-bottom: none;
    margin-bottom: 30px;
  }
`

const WalletItem = styled.div`
  width: 320px;
  display: flex;
  align-items: center;
  padding: 16px 10px;
  cursor: pointer;
  border-bottom: 1px black solid;
  box-sizing: border-box;
  @media (max-width: 767px) {
    min-height: 40px;
  }
`

WalletItem.Icon = styled.img`
  width: 32px;
  height: 32px;
`

WalletItem.name = styled.span`
  font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
margin-left: 14px;
`

WalletItem.arrow = styled.img`
  margin-right: 0;
  margin-left: auto;
  width: 12px;
`

WalletItem.Right = styled.div`
  margin-right: 0;
  margin-left: auto;
  width: 12px;
  display: flex;
  align-items: center;
`

WalletItem.ActiveDot = styled.span`
  background: #4caf50;
  margin-right: 0;
  margin-left: auto;
  width: 10px;
  height: 10px;
  border-radius: 10px;
`


export const Wallets = () => {

    const { dispatch } = useContext(myContext);

    const context = useWeb3React();

    const {
        connector,
        library,
        account,
        activate,
        deactivate,
        active,
        error,
        chainId
    } = context;

    const [activatingConnector, setActivatingConnector] = useState();
    const [currentConnector, setCurrentConnector] = useState();
    let localContent = window.localStorage.getItem('DEFAULT_WEB3')


    // useEffect(() => {
    //     if (activatingConnector && activatingConnector === connector) {
    //         setActivatingConnector(undefined);
    //     }
    // }, [activatingConnector]);



    useEffect(() => {
        console.log('connected--->',account, active, activatingConnector)
        if (account && active && library && activatingConnector) {
            console.log('connected--->',account, active)
            localContent = activatingConnector
            window.localStorage.setItem('DEFAULT_WEB3', activatingConnector)
            setActivatingConnector(undefined);
            setCurrentConnector(activatingConnector)
            dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: false });
        }else {
            console.log('no connected--->',activatingConnector)
            setCurrentConnector(undefined)
        }
    }, [active, activatingConnector]);

    function onConnectionClicked(name) {
        console.log('onConnectionClicked',walletList()[name])
        setActivatingConnector(name);
        window.localStorage.setItem('DEFAULT_WEB3', '')
        activate(walletList()[name], ()=>{
            console.log('connect error')
        }, ()=>{

        });
    }

    return (
        <>
            <WalletFrame>
                {Object.keys(walletList()).map(name => {
                    const activating = (name === activatingConnector);
                    const connected = (name === currentConnector ||name === localContent );
                    const disabled = !!activatingConnector || !!error;

                    var url;
                    var display = name;
                    if (name === 'MetaMask') {
                        url = require('../../static/image/icn-metamask.svg')
                    } else if (name === 'WalletConnect') {
                        url = require('../../static/image/walletConnectIcon.svg')
                    }if (name === 'BinanceWallet') {
                        url = require('../../static/image/icon-binance-wallet.svg')
                    } else if (name === 'TrustWallet') {
                        url = require('../../static/image/trustWallet.png')
                    } else if (name === 'Portis') {
                        url = require('../../static/image/portisIcon.png')
                    } else if (name === 'Fortmatic') {
                        url = require('../../static/image/fortmaticIcon.png')
                    } else if (name === 'Ledger') {
                        url = require('../../static/image/icn-ledger.svg')
                    } else if (name === 'Squarelink') {
                        url = require('../../static/image/squarelink.png')
                    } else if (name === 'Trezor') {
                        url = require('../../static/image/trezor.png')
                    } else if (name === 'Torus') {
                        url = require('../../static/image/torus.jpg')
                    } else if (name === 'Authereum') {
                        url = require('../../static/image/icn-aethereum.svg')
                    } else if (name === 'WalletLink') {
                        display = 'Coinbase Wallet'
                        url = require('../../static/image/coinbaseWalletIcon.svg')
                    } else if (name === 'Frame') {
                        return ''
                    }

                    return (
                        <WalletItem
                            key={ name }
                            onClick={() => {
                                onConnectionClicked(name)
                            }}
                            disabled={disabled}>
                            <WalletItem.Icon src={url} alt="" />

                            <WalletItem.name>{display}</WalletItem.name>

                            {!activating && !connected && <WalletItem.arrow src={icon_arrow_left} />}

                            {activating && <WalletItem.Right style={{marginBottom: 0}}><CircularProgress style={{marginBottom: 0}} size={15}  /></WalletItem.Right>}
                            {(!activating && connected) && <WalletItem.ActiveDot />}
                        </WalletItem>
                    )
                })}

            </WalletFrame>
            <Button width={'320px'} style={{marginBottom: 26,minHeight:40}} onClick={() => {
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: false });
            }}>Cancel</Button>
        </>
    )

}
