import React, { useEffect, useState, useRef } from 'react';
import { t } from '../utils/intl';
import medium from '../static/image/medium.svg';
import twitter from '../static/image/twitter.svg';
import telegram from '../static/image/telegram.svg';
import community from '../static/image/community.svg';
import CG from '../static/image/CG.svg'
import { rgba } from 'polished';


export const Social = () => {

  return (
    <div className='social-area'>
      <div className='social'>
        <a href='https://medium.com/@bouncefinance' target='_blank' rel="noopener noreferrer"><img src={medium}
          alt='medium' /></a>
        <a href='https://twitter.com/bounce_finance?s=21' target='_blank' rel="noopener noreferrer"><img src={twitter}
          alt='twitter' /></a>
        <a href='https://t.me/bounce_finance' target='_blank' rel="noopener noreferrer"><img src={telegram}
          alt='telegram' /></a>
        <a href='https://www.bounce.community' target='_blank' rel="noopener noreferrer"><img src={community}
          alt='telegram' /></a>
        <a href='https://www.coingecko.com/en/coins/bounce-token' target='_blank' rel="noopener noreferrer"><img src={CG}
          alt='coingecko' /></a>

        <span
          onClick={() => {
            window.location.href = 'https://docs.bounce.finance/'
          }}
          style={{
            fontFamily: 'IBM Plex Mono',
            fontSize: 12,
            color: rgba(0, 0, 0, .8),
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >Bounce Docs</span>
      </div>
      <span style={{
        fontFamily: 'IBM Plex Mono',
        fontSize: 12,
        color: '#000',
        opacity: .8
      }}>
        Bounce is a fully decentralized protocol. Join the auction at your own risk.
        </span>
    </div>
  )
}
