import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, fade } from '@material-ui/core';
import { ReactComponent as BackIcon } from './components/assets/back.svg';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as ProgressIcon } from './components/assets/progress-large.svg';
import classNames from 'classnames';
import { useGovDetail } from "./Hooks";
import { weiToNumber } from "../../utils/numberTransform";
import BigNumber from "bignumber.js";
import { getContract, useActiveWeb3React } from "../../web3";
import bounceStake from "../../web3/abi/bounceStake.json";
import { getStakingAddress } from "../../web3/contractAddress";
import Web3 from 'web3'
import { VoteModal, initStatus, cancelStatus, pendingStatus, successStatus, confirmStatus, failStatus } from "../../components/common/VoteModal";
import { CircularProgressWithLabel } from './components/CircularProgressWithLabel'
import { int2hex } from "../../utils/common";

const { numberToHex, hexToUtf8 } = Web3.utils

const useStyle = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 1280,
    padding: 48,
    paddingTop: 26,
    backgroundColor: '#fff',
    margin: '28px auto',
    position: 'relative',
    [`@media (max-width: 767px)`]: {
      width: '100vw',
      padding: 20,
    },
  },
  back: {
    position: 'absolute',
    top: 25,
    left: 48,
    width: 34,
    height: 34,
    borderRight: '1px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    [`@media (max-width: 767px)`]: {
      borderRight: 'none',
      top: 16,
      left: 20,
    },
  },
  info: {
    width: '100%',
    textAlign: 'center',
    [`@media (max-width: 767px)`]: {
      marginTop: 40,
    },
  },
  status: {
    fontFamily: 'IBM Plex Mono',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    lineHeight: '16px',
    marginTop: 10
  },
  title: {
    fontFamily: 'Optima',
    fontSize: 36,
    fontWeight: 'bold',
    lineHeight: '44px',
    color: '#1F191B',
    marginBottom: 20,
    marginTop: 16,
    [`@media (max-width: 767px)`]: {
      fontSize: 26,
    },
  },
  address: {
    fontFamily: 'IBM Plex Mono',
    fontSize: 14,
    lineHeight: '18px',
    color: fade('#1F191B', 0.4),
    marginBottom: 29,
    [`@media (max-width: 767px)`]: {
      fontSize: 12,
      wordBreak: 'break-all',
    },
  },
  divid: {
    width: 160,
    height: 1,
    backggroundColor: fade('#1F191B', 0.4),
    marginBottom: 32,
  },
  description: {
    fontFamily: 'Optima',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '27px',
    color: '#000',
    margin: '0 auto 56px auto',
    padding: '0 38px',
    wordBreak: 'break-all'
  },
  row: {
    width: 960,
    fontSize: 14,
    lineHeight: '154.8%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    marginBottom: 96,
    [`@media (max-width: 767px)`]: {
      width: 'calc(100vw - 40px)',
      margin: 0,
      padding: 0,
      maxWidth: 'none',
      maxHeight: 'none',
    },
  },
  column: {
    width: 440,
    fontFamily: 'IBM Plex Mono',
    textAlign: 'left',
    flex: '0 0 auto',
    margin: 'auto',
    [`@media (max-width: 767px)`]: {
      width: '100%',
    },
  },
  pair: {
    height: 240,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (max-width: 767px)`]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  name: {
    fontFamily: 'IBM Plex Mono',
    fontSize: 14,
    lineHeight: '18px',
    color: fade('#1F191B', 0.4),
    marginBottom: 8,
  },
  value: {
    fontFamily: 'Optima',
    fontWeight: 'bold',
    color: '#000',
    fontSize: 22,
    lineHeight: '27px',
  },
  center: {
    margin: '0 120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    [`@media (max-width: 767px)`]: {
      margin: '20px 120px',
    },
  },
  data: {
    width: 64,
    height: 64,
    fontFamily: 'Optima',
    fontWeight: 'bold',
    fontSize: 26,
    lineHeight: '32px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -32,
    marginLeft: -32,
  },
  action: {
    marginTop: 96,
    width: '100%',
    backgroundColor: fade('#000', 0.04),
    padding: '64px 0',
    textAlign: 'center',
  },
  label: {
    fontFamily: 'Optima',
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: '#000',
    marginBottom: 12,
  },
  time: {
    fontFamily: 'IBM Plex Mono',
    fontSize: 13,
    lineHeight: '17px',
    marginBottom: 56,
  },
  vote: {
    width: 720,
    margin: '0 auto',
    marginBottom: 40,
    [`@media (max-width: 767px)`]: {
      width: '100%',
    },
  },
  votes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #d3d3d3',
    marginBottom: 42,
  },
  item: {
    width: 240,
    textAlign: 'center',
    paddingBottom: 22,
    cursor: 'pointer',
    borderBottom: '5px solid transparent',
  },
  itemName: {
    fontFamily: 'Optima',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '27px',
    color: fade('#000', 0.3),
    marginBottom: 6,
    [`@media (max-width: 767px)`]: {
      fontSize: 15,
      lineHeight: '18px',
    },
  },
  itemValue: {
    fontFamily: 'IBM Plex Mono',
    fontSize: 13,
    lineHeight: '154.8%',
    color: fade('#000', 0.3),
  },
  current: {
    borderBottomColor: '#000',
    '& $itemName': {
      color: '#000',
    },
    '& $itemValue': {
      color: '#000',
    }
  },
  message: {
    fontFamily: 'IBM Plex Mono',
    fontSize: 13,
    lineHeight: '154.8%',
    textAlign: 'center',
    width: 320,
    minHeight: 40,
    margin: '0 auto',
  },
  button: {
    width: 320,
    height: 40,
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'Helvetica Neue',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '40px',
    backgroundColor: '#000',
    margin: '0 auto',
    cursor: 'pointer',
    [`@media (max-width: 767px)`]: {
      minHeight: 48,
    },
  }
}));

const TYPE = ['for', 'against', 'neutral'];
const VALUE = {
  'for': 'voteYes',
  'against': 'voteNo',
  'neutral': 'voteCancle',
}

const Details = ({ }) => {
  const { active, account, library, chainId } = useActiveWeb3React();

  const classes = useStyle();
  const history = useHistory();
  const { id } = useParams()
  const [type, setType] = React.useState(TYPE[0]);
  const [value, setValue] = React.useState(VALUE[type])
  const [left, setLeft] = useState(null);
  const [modalStatus, setModalStatus] = React.useState(initStatus)

  let timer;
  const { gov } = useGovDetail(id)

  const handleClick = () => {
    history.push('/governance');
  }

  const clickVote = (type) => {
    setType(type);
    setValue(VALUE[type])
  }


  const renderTime = useCallback(() => {
    if (gov && gov.time) {
      const time = gov.time * 1000;
      const date = new Date(time);
      const now = new Date();
      const lefttime = date - now;
      let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
      let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
      let leftm = Math.floor(lefttime / (1000 * 60) % 60);
      let lefts = Math.floor(lefttime / 1000 % 60);
      const left = {
        days: leftd < 0 ? 0 : leftd,
        hours: lefth < 0 ? 0 : lefth,
        minutes: leftm < 0 ? 0 : leftm,
        seconds: lefts < 0 ? 0 : lefts,
      };
      setLeft(left);
      if (left < 0) {
        setLeft(null);
      }
    } else {
      setLeft(null);
    }
  }, [gov, setLeft]);

  useEffect(() => {
    if (gov) {
      timer = setInterval(() => {
        renderTime();
      }, (1000));
    } else {
      clearInterval(timer)
    }

    return () => {
      clearInterval(timer)
    }
  }, [renderTime, gov]);


  const onVote = async () => {
    console.log('governance id', numberToHex(id))
    const contract = getContract(library, bounceStake.abi, getStakingAddress(chainId))
    try {
      setModalStatus(confirmStatus)
      console.log('onVote', int2hex(id, 64))
      contract.methods[VALUE[type]](int2hex(id, 64))
        .send({ from: account })
        .on('transactionHash', hash => {
          setModalStatus(pendingStatus)
        })
        .on('receipt', (_, receipt) => {
          setModalStatus(successStatus)
        })
        .on('error', (err, receipt) => {
          setModalStatus(failStatus)
          console.log('error1', err);
        })
    } catch (err) {
      if (err.code === 4001) {
        setModalStatus(cancelStatus)
      } else {
        setModalStatus(failStatus)
      }
      console.log('err', err);
    }
  };



  return (
    <div className={classes.root}>
      <div className={classes.back}
        onClick={handleClick}
      >
        <BackIcon style={{ width: 16, height: 16 }} />
      </div>
      <div className={classes.info}>
        <div className={classNames(classes.status, gov && gov.status)}>• {gov && gov.status}</div>
        <div className={classes.title}>{gov && gov.title}</div>
        <div className={classes.address}>{gov && gov.creator}</div>
        <div className={classes.divid}></div>
        <div className={classes.description}>{gov && gov.content.summary}</div>
        <div className={classes.row}>
          <div className={classes.column}>{gov && gov.content.details}</div>
        </div>
        <div className={classes.pair}>
          <div className={classes.left}>
            <div className={classes.name}>{'Votes For:'}</div>
            <div className={classes.value}>{`${gov ? weiToNumber(gov.yesCount) : 0} Votes`}</div>
          </div>
          <div className={classes.center}>
            {/* <ProgressIcon style={{width: 240, height: 240}} /> */}
            <CircularProgressWithLabel
              style={gov && { color: gov.status === 'Live' ? '#2DAB50' : gov.status === 'Passed' ? '#728AE0' : '#666' }}
              color='#2DAB50'
              value={gov && parseInt(parseInt(gov.yesCount) / (parseInt(gov.yesCount) + parseInt(gov.noCount)) * 100)}
              size={240}
              thickness={8}
              color='inherit'
            />
            <div className={classes.data}>{`${gov ? new BigNumber(gov.noCount).plus(gov.yesCount).dividedBy(1000000000000000000).toFixed(0) : 0} Votes`}</div>
          </div>
          <div className={classes.right}>
            <div className={classes.name}>{'Votes Against:'}</div>
            <div className={classes.value}>{`${gov ? weiToNumber(gov.noCount) : ''} Votes`}</div>
          </div>
        </div>
      </div>
      <div className={classes.action}>
        <div className={classes.label}>{'make your decision'}</div>
        <div className={classes.time}>{left && `${left.days}d : ${left.hours}h : ${left.minutes}m : ${left.seconds}s`}</div>
        <div className={classes.vote}>
          <div className={classes.votes}>
            <div className={classNames(classes.item, type === TYPE[0] && classes.current)}
              onClick={() => clickVote(TYPE[0])}>
              <div className={classes.itemName}>
                {'Vote For'}
              </div>
            </div>
            <div className={classNames(classes.item, type === TYPE[1] && classes.current)}
              onClick={() => clickVote(TYPE[1])}>
              <div className={classes.itemName}>
                {'Vote Against'}
              </div>
            </div>
            <div className={classNames(classes.item, type === TYPE[2] && classes.current)}
              onClick={() => clickVote(TYPE[2])}>
              <div className={classes.itemName}>
                {'Vote Neutral'}
              </div>
            </div>
          </div>
          {value === 'voteYes' && <div className={classes.message}>{gov ? gov.content.agreeFor : ''}</div>}
          {value === 'voteNo' && <div className={classes.message}>{gov ? gov.content.againstFor : ''}</div>}
          {(!value || value === 'voteCancle') && <div className={classes.message}>{` `}</div>}

        </div>
        <div className={classes.button}
          onClick={onVote}>
          {'Submit'}
        </div>
      </div>

      <VoteModal modalStatus={modalStatus} onDismiss={() => { setModalStatus(initStatus) }} />
    </div>
  )
}

export { Details };
