import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { PoolCard } from "../../components/PoolCard";
import { myContext } from "../../reducer";
import { weiToNumber } from "../../utils/numberTransform";
import { useActivePlatform } from '../../web3';
import { LoadingPage } from "../../components/common/LoadingPage";

import icon_loading from '../../static/image/icon-loading.svg';


const CardFrame = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1280px;
  min-height: 70vh;
  margin: auto;
  /* margin-top: -158px; */
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const Lottery = ({ pools, isLoading }) => {
    const history = useHistory()
    const { Psymbol } = useActivePlatform()

    // if (!pools) {
    //     return <></>
    // }
    return (
        <CardFrame>
            {pools && pools.length !== 0 ?
                pools.map(item => {
                    return (
                        <PoolCard
                            height='320px'
                            onJoin={() => {
                                history.push(`/lottery-erc20/${item.index}`, { id: item.index });
                            }} pool={item} key={item.name}
                            poolMeta={[
                                {
                                    title: 'Pair',
                                    // content: `${item.fromSymbol} / ${Psymbol}`,
                                    token1Adress: `${item.fromAddress}`,
                                    token1Symbol: `${item.fromSymbol}`,
                                    token2: `${Psymbol}`,
                                }, , {
                                    title: 'Number of winner',
                                    content: `${item.winner}`
                                }, {
                                    title: 'Ticket Price',
                                    content: `${weiToNumber(item.toAmount)} ${Psymbol}`
                                }, {
                                    title: 'Participants',
                                    content: `${item.participate}`
                                }
                            ]}
                        />
                    )
                }) : <div style={{
                    fontFamily: 'IBM Plex Mono',
                    fontSize: 16,
                    marginTop: 400,
                    color: '#000',
                    width: '100%',
                    textAlign: "center"
                }}>You have no live auctions at the moment</div>}
        </CardFrame>
    )


}