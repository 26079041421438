import React, {useState, useCallback, useEffect} from 'react';
import {t} from '../../utils/intl';
import {
  TO,
  queryPoolItem,
  getContractLink,
  checkEAPoolStatus, checkEAClaimStatus,
  queryEnglishAuctionPoolItem, queryEABiddenAmount, queryEACurBiddenAddress, queryEABiddenAddress, queryEACurPrice,
  queryEACurRound
} from '../const';
import {getDutchAuctionERC1155, getDutchAuctionNFT, getEnglishAuctionContract} from '../../components/utils/web3';
import {EnglishAuctionPoolItem} from './EnglishAuctionPoolItem';
import sucIcon from '../../static/image/success.svg';
import {isGreaterThan, isEqualTo} from '../../utils/common'
import close_icon from '../../static/image/close.svg'
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles/index";
import eye_open from '../../static/image/eye_open.svg'
import eye_close from '../../static/image/eye_close.svg'
import md5 from "js-md5";
import {useHistory} from 'react-router-dom'
import {weiToNumber} from "../../utils/numberTransform";

const BigNumber = require('bignumber.js');

const useStyles = makeStyles(() => ({
  root: {
    width: 480,
    margin: 'auto',
    position: 'absolute',
    borderRadius: 10,
    border: '1px solid #000000',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.12)',
    padding: 20,
    top: '20%',
    left: 0,
    right: 0
  },
}));


export const JoinEnglishAuctionForm = ({
                                         is1155,
                                         account,
                                         symbol,
                                         poolIndex,
                                         onStatusChange,
                                         onHashChange,
                                         web3
                                       }) => {
  const [pool, setPool] = useState('');
  const [amount, setAmount] = useState('0');
  const [isFilled, setIsFilled] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [address, setAddress] = useState('');
  const [curPrice, setCurPrice] = useState();
  const [bidSuccess, setBidSuccess] = useState(false);
  const [claimed, setClaimed] = useState(true);


  const [joined, setJoined] = useState(false);
  const [curBidAmount, setCurBidAmount] = useState();

  const [passwordModal, setPasswordModal] = useState(true);
  const [password, setPassword] = useState()
  const [passwordError, setPasswordError] = useState({show: false, content: ''})
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [curRound, setCurRound] = useState(0);

  const history = useHistory()


  const classes = useStyles();



  const changeRound = () => {
    console.log('round change:')
    checkEAPoolStatus(web3, poolIndex)
        .then(status => {
          if (status) {
            console.log('pool is close:', status)
            setIsClosed(false)
          } else {
            setIsClosed(true)
          }
        })

    queryEABiddenAmount(web3, account, poolIndex)
        .then((amount) => {
          setCurBidAmount(amount)
          console.log('nft english bidden amount', amount)
          if (isGreaterThan(amount, '0')) {
            setJoined(true)
          } else {
            setJoined(false)
          }
          queryEACurBiddenAddress(web3, poolIndex).then(address => {
            console.log('1155 bidden address', address.toLowerCase(), account.toLowerCase())
            if (isGreaterThan(amount, '0') && address.toLowerCase() === account.toLowerCase()) {
              setBidSuccess(true)
              //check claimed
              checkEAClaimStatus(web3, account, poolIndex)
                  .then(isClaimed => {
                    if (isClaimed) {
                      setClaimed(true)
                    } else {
                      setClaimed(false)
                    }
                  })
            } else {
              setBidSuccess(false)
            }
          })

        })

  }

  useEffect(() => {
    if (pool.creator && account && pool.creator.toLowerCase() === account.toLowerCase()) {
      history.push('/english-auction-pool')
      return
    }

    async function loadAddress() {
      const address = await getContractLink(pool.fromAddress);
      setAddress(address);
    }

    loadAddress()
    checkEAPoolStatus(web3, poolIndex)
        .then(status => {
          if (status) {
            console.log('pool is close:', status)
            setIsClosed(false)
          } else {
            setIsClosed(true)
          }
        })

    queryEACurRound(web3, poolIndex)
        .then(round => {
          setCurRound(round)
        })

    queryEABiddenAmount(web3, account, poolIndex)
        .then((amount) => {
          setCurBidAmount(amount)
          console.log('nft bidden amount', amount)

          if (isGreaterThan(amount, '0')) {
            setJoined(true)
          } else {
            setJoined(false)
          }
          queryEABiddenAddress(web3, poolIndex).then(address => {
            console.log('bidden address', address.toLowerCase(), account.toLowerCase())
            if (isGreaterThan(amount, '0') && address.toLowerCase() === account.toLowerCase()) {
              setBidSuccess(true)
              //check claimed
              checkEAClaimStatus(web3, account, poolIndex)
                  .then(isClaimed => {
                    if (isClaimed) {
                      setClaimed(true)
                    } else {
                      setClaimed(false)
                    }
                  })
            } else {
              setBidSuccess(false)
            }
          })

        })


    if (pool.password && pool.password !== '0' && pool.password !== 0) {
      setPasswordModal(true)
    } else {
      setPasswordModal(false)
    }
  }, [pool]);

  const handlePassword = ((event) => {
    const value = event.target.value;
    setPassword(value)
  });


  useEffect(() => {
    if (poolIndex > -1) {
      queryEnglishAuctionPoolItem(web3, poolIndex)
          .then(r => {
            console.log('join ea pool item',r)
            setPool(r);
          })
          .catch(err => {
            console.log(err);
          })
    }

    queryEACurPrice(web3, poolIndex)
        .then(r => {
            setCurPrice(r)
        });
  }, [web3]);

  const checkAmount = (() => {
    setHasError(false);
    let bidAmount = '';
    if(amount !== ''){
      bidAmount = new BigNumber(curPrice).isGreaterThan(new BigNumber(web3.utils.toWei(amount))) ? web3.utils.fromWei(curPrice) : amount
    }
    setAmount(bidAmount);
  });

  const handleChange = ((event) => {
    setHasError(false);
    let bidAmount = event.target.value.replace(/[^\d.]/g, '');
    setAmount(bidAmount);
  });

  const handleSwap = async () => {
    if (!amount || amount === '') {
      setHasError(true)
      return
    }
    const nft = await getEnglishAuctionContract(web3);
    const bidAmount = web3.utils.toWei(amount, 'ether');
    onStatusChange('waiting');
    nft.methods.bid(pool.index, pool.password)
        .send({from: account, value: bidAmount})
        .then(r => {
          setPassword('0');
          setAmount('0');
          onStatusChange('success');
          onHashChange(r.transactionHash);
        })
        .catch(err => {
          if (err.code === 4001) {
            onStatusChange('denied');
          } else {
            onStatusChange('failed');
          }
        })
  }

  const onClaim = async () => {
    const ndf = await getEnglishAuctionContract(web3);
    onStatusChange('waiting');
    try {
      //onChangeAmount(amount, to.toFixed(4));
      console.log('onClaim reward:', poolIndex)
      await ndf.methods.bidderClaim(poolIndex)
          .send({from: account})
          .then(_ => {
            onStatusChange('success');
          })
      // .on('transactionHash', hash => {
      //   onStatusChange('waiting')
      //   onHashChange(hash);
      // })
      // .on('confirmation', (_, receipt) => {
      //   //onSuccessContentChange(t('stake.success.content-reward'));
      //   onStatusChange('success');
      //   //onStatusChange('finish');
      //   //onReceiptChange(receipt);
      // })
      // .on('error', (err, receipt) => {
      //   onStatusChange('failed');
      //   console.log('error1', err);
      //   //onReceiptChange(receipt);
      // })
    } catch (err) {
      if (err.code === 4001) {
        onStatusChange('denied');
      } else {
        //onFailedContentChange(t('stake.fail.claim-reward'))
        onStatusChange('failed');
      }
      console.log('err', err);
    }
  };

  return (
      <>
        {poolIndex === -1
            ? <p className='noData'>{t('join-pool.no-data')}</p>
            : <>
              <ul className='form-head join-title'>
                <li>{pool.name}</li>
                <li><a href={address} target='_blank' rel="noopener noreferrer">{pool.fromAddress}</a></li>
              </ul>
              <form className='form-content'>
                <EnglishAuctionPoolItem
                    is1155={is1155}
                    web3={web3}
                    symbol={symbol}
                    pool={pool}
                    curPrice={curPrice}
                    closed={isClosed}
                    onRoundChange={() => {
                      changeRound()
                    }}/>
                {joined
                    ?
                    <>
                      <div className="nft-grey" style={{marginTop: 20}}>
                        <div className="item" style={{flex: 1}}>
                          <span style={{marginBottom: 10}}>Your bid:</span>
                          <span> {curBidAmount ? `${weiToNumber(curBidAmount)} ETH` : ''}</span>
                        </div>
                        <div className="item" style={{flex: 1}}>
                          <span style={{marginBottom: 10}}>Status:</span>
                          <span
                              style={{color: !isClosed ? '#36C98E' : bidSuccess ? '#077BE5' : '#EB2F51'}}> {!isClosed ? 'Live' : bidSuccess ? 'You get it!' : 'Failed'}</span>
                        </div>
                      </div>

                      {isClosed && bidSuccess && !claimed && <div>
                        <span style={{marginTop: 20}} className='dark-button' onClick={onClaim}>Claim your swapped tokens</span>
                      </div>}
                    </>
                    : null }

                      { !isClosed && !bidSuccess && (<>
                        <dl className='define'>
                          <dt>{'Your bid'}</dt>
                          <dd style={{display: 'flex', alignItems: 'center'}}>
                            <input type='number' name='amount'
                                   placeholder={t('join-pool.amount-placeholder', {'to': TO})}
                                   onChange={handleChange}
                                   onBlur={checkAmount} value={amount}/><span
                              style={{marginLeft: 10}}> ETH</span>
                          </dd>
                          {hasError ? <dd className='error'>{t('join-pool.amount-required')}</dd> : null}
                          {/*<span className='weight-grey'>Transaction fee:ETH</span>*/}
                        </dl>
                        <span className='dark-button' onClick={handleSwap}>Go</span>
                      </>) }

              </form>
            </>}


        <Modal open={passwordModal}>
          {passwordModal ? (
              <Card className={classes.root}>
                <CardHeader
                    action={
                      <img className="modal-close" src={close_icon} alt='close' onClick={_ => {
                        history.goBack()
                      }}/>
                    }
                />
                <CardContent>
                  <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 10
                  }}>

                    <span style={{marginLeft: 20}}
                          className="modal-title">{'This pool is private'}</span>
                  </div>
                  <Typography style={{textAlign: 'center'}}>
                    {'Please enter password to join this pool'}
                  </Typography>

                  <dl className='password'>
                    <dt>{'Password'}</dt>
                    <dd style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                      <input name='poo-password'
                             type={passwordOpen ? 'text' : 'password'}
                             maxLength={12}
                             placeholder={'Please enter the pass word of pool'}
                             onChange={(e) => {
                               setPassword(e.target.value)
                             }} value={password}/>
                      <img src={passwordOpen ? eye_open : eye_close} onClick={() => {
                        setPasswordOpen(!passwordOpen)
                      }} className="eye"/>
                    </dd>
                    {passwordError ? <dd className='error'>{passwordError.content}</dd> : null}
                    {/*<span className='weight-grey'>Transaction fee:ETH</span>*/}
                  </dl>

                  <span className='dark-button modal-button' onClick={() => {
                    //console.log('enter password--->',selectPool.password)
                    if (!password) {
                      setPasswordError({show: true, content: 'password is required'})
                    } else if (new web3.utils.BN(md5(password)).toString() !== pool.password) {
                      setPasswordError({show: true, content: 'password is wrong, please enter again'})
                    } else {
                      setPasswordError({...passwordError, show: false})
                      setPasswordModal(false)
                    }
                  }}>{'join'}</span>
                </CardContent>
              </Card>
          ) : null}
        </Modal>
      </>
  )
}
