import React, { useEffect, useState } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import bounceFactory from '../../../components/utils/bounceFactory.json'
import { getBounceFactoryAddress } from "../../../web3/contractAddress"


export function useStoreList() {
    const [storeList, setStoreList] = useState([])
    const { active, account, library, chainId } = useActiveWeb3React();

    useEffect(() => {
        if (!active) return
        queryStoreInfo()
    }, [active])


    const queryStoreInfo = async () => {
        const count = await queryStoreCount()
        const queryList = []
        for (let i = count - 1; i >= 0; i--) {
            queryList.push(i)
        }

        queryStoreList(queryList)

    }

    const getTypeByNum = (num) => {
        switch (num) {
            case 0:
                return 'fixed-swap'
            case 1:
                return 'fixed-swap-nft'
            case 2:
                return 'english-auction'
            default:
                return 'fixed-swap'
        }
    }

    const queryStoreList = async (queryList) => {
        const storeList = []
        const bounceFac = getContract(library, bounceFactory.abi, getBounceFactoryAddress(chainId))
        Promise.all(queryList.map(async (storeID, _index) => {
            const res = await bounceFac.methods.stores(storeID).call({ from: account })
            const info = JSON.parse(res)
            // console.log('F_console', storeList)
            storeList.push({
                key: storeID + 3,
                storeID: storeID,
                storeType: info.storeType,
                name: info.name,
                tokenCT: info.contract,
                describe: info.describe,
                // 跳转链接
                homePage: info.website,
                twitter: info.twitter,
                instagram: info.instagram,
                facebook: info.facebook,

                cover: info.image,
                active: true,

                link: `/store/custom/${getTypeByNum(info.storeType[0])}/${storeID}`
            })

            // console.log('F_console', storeList)
        })).then(res => {
            setStoreList(storeList)
        })
    }

    const queryStoreCount = async () => {
        const bounceFac = getContract(library, bounceFactory.abi, getBounceFactoryAddress(chainId))
        const count = bounceFac.methods.getStoreCount().call()
        return count
    }

    return { newStoreList: storeList }
}
