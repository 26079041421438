import React from 'react';
import searchIcon from '../../static/image/search.svg';
import './content-box.scss';

export const EmptyIndex = ({
  isSearch,
}) => {
  const text = isSearch ? 'No pools found with this name. Try another search.' : 'loading';
  return (
    <div className='empty'>
      {isSearch?<img src={searchIcon} alt='search' />:null}
      <p>{text}</p>
      {isSearch?null:<div className='loading'/>}
    </div>
  )
}
