import React from 'react';
import { t, tHTML } from '../../utils/intl';
import failedIcon from '../../static/image/error.svg';
import { Link } from 'react-router-dom';

export const StakeFailed = ({
                             onClose,
                              content
                           }) => {
  return (
      <div className='content-box'>
        <img src={failedIcon} alt='failed' />
        <span style={{fontSize:20,fontWeight:'bold',marginBottom:20}}>{content}</span>
        <div className='button-group' style={{position:'static'}}>
          <span className='button' onClick={onClose}>{t('buttons.try-again')}</span>
          {/*<span className='button'><Link to='/'>{t('buttons.join-another')}</Link></span>*/}
        </div>
      </div>
  )
}
