import React, { useState } from "react";
import styled from "styled-components";
import Web3 from 'web3'
import { Action, StakingFrame } from "../../components/Staking";
import { OText1, OText2, OText3, OText4, OText5 } from "../../components/common/Layout";
import { Button } from "../../components/common/Button";
import { useQueryBotStaking } from "./Hooks";
import { weiToNumber } from "../../utils/numberTransform";
import { Form, Input } from "../../components/common/Form";
import icon_max from "../../static/image/icon-max.svg";
import { HelperLayout } from "../../components/common/HelperLayout";
import icon_helper from "../../static/image/icon-helper.svg";
import icon_close from "../../static/image/icon-close.svg";
import { isGreaterThan } from "../../utils/common";
import { useIsXSDown } from '../../components/utils/themeHooks';

const { toWei } = Web3.utils

const TextFrame = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export const BotStaking = ({ onStake, onUnStake, onClaim, onGovClaim }) => {

    const { botBalance, rewards, stakedAmount, govReward, stakedTotal } = useQueryBotStaking()

    const [curPage, setCurPage] = useState(0)
    const [amount, setAmount] = useState()
    const isXSDown = useIsXSDown();

    const [] = useState()

    const renderPage = () => {
        switch (curPage) {
            case 0:
                return (<>
                    {isXSDown &&
                        <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                            <StakingFrame padding={'44px'} width={'100%'} style={{ flexDirection: 'column' }}>
                                <OText5 style={{ width: 180 }}>Your Staking Rewards Estimation</OText5>
                                <OText1 style={{ marginTop: 60 }}>{rewards && `${weiToNumber(rewards, '', 2)} `}<OText4>BOT</OText4></OText1>
                                <Button onClick={onClaim}>Claim Rewards</Button>
                            </StakingFrame>

                            <StakingFrame width={'100%'} style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                                <StakingFrame padding={'44px 44px 20px'} height={'144px'} style={{ flexDirection: 'row' }}>
                                    <TextFrame>
                                        <OText5>Estimated APY</OText5>
                                        <OText4>60%</OText4>
                                    </TextFrame>

                                    <TextFrame>
                                        <OText5>Currently Staked</OText5>
                                        <OText4>
                                            {stakedTotal && `${weiToNumber(stakedTotal)}`}<OText5>BOT</OText5>
                                        </OText4>
                                    </TextFrame>
                                </StakingFrame>

                                <StakingFrame height={'480px'} padding={'44px'} style={{ flexDirection: 'column' }}>
                                    <TextFrame style={{ marginBottom: '40px' }}>
                                        <OText5>Your Balance</OText5>
                                        <OText3>{botBalance && `${weiToNumber(botBalance)} `}<OText5>BOT</OText5></OText3>
                                        <Button onClick={() => {
                                            setCurPage(1)
                                            setAmount(null)
                                        }}>Stake BOT</Button>
                                    </TextFrame>

                                    <TextFrame style={{ marginBottom: '40px' }}>
                                        <OText5>Your Stake</OText5>
                                        <OText3>{stakedAmount && `${weiToNumber(stakedAmount)} `}<OText5>BOT</OText5></OText3>
                                        <Button onClick={() => {
                                            setCurPage(2)
                                            setAmount(null)
                                        }}>Unstake BOT</Button>
                                    </TextFrame>

                                    <TextFrame style={{ marginBottom: '40px' }}>
                                        <OText5>Your governance reward</OText5>
                                        <OText3>{govReward && `${weiToNumber(govReward)} `}<OText5>BOT</OText5></OText3>
                                        <Button onClick={onGovClaim}>Claim governance Reward</Button>
                                    </TextFrame>
                                </StakingFrame>
                            </StakingFrame>
                        </StakingFrame>
                    }
                    {!isXSDown &&
                        <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: 'row' }}>
                            <StakingFrame padding={'44px'} width={'360px'} style={{ flexDirection: 'column' }}>
                                <OText4 style={{ width: 214 }}>Your Staking Rewards Estimation</OText4>
                                <OText1 style={{ marginTop: 60, fontSize: 64 }}>{rewards && `${weiToNumber(rewards, '', 2)} `}<OText3>BOT</OText3></OText1>
                                <Button style={{ fontSize: 14 }} onClick={onClaim}>Claim Rewards</Button>
                            </StakingFrame>

                            <StakingFrame width={'872px'} style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                                <StakingFrame height={'144px'} style={{ flexDirection: 'row' }}>
                                    <TextFrame style={{ boxSizing: "border-box", padding: '44px 40px', borderLeft: '2px solid #F5F5F5' }}>
                                        <OText5>Estimated APY</OText5>
                                        <OText3 style={{ marginTop: 16 }}>60%</OText3>
                                    </TextFrame>

                                    <TextFrame style={{ boxSizing: "border-box", padding: '44px 40px', borderLeft: '2px solid #F5F5F5' }}>
                                        <OText5>Currently Staked</OText5>
                                        <OText3 style={{ marginTop: 16 }}>
                                            {stakedTotal && `${weiToNumber(stakedTotal)}`}<OText5> BOT</OText5>
                                        </OText3>
                                    </TextFrame>
                                </StakingFrame>

                                <StakingFrame padding={0} height={'248px'}>
                                    <TextFrame style={{ boxSizing: "border-box", padding: '44px 40px' }}>
                                        <OText5>Your Balance</OText5>
                                        <OText2>{botBalance && `${weiToNumber(botBalance)} `}<OText5>BOT</OText5></OText2>
                                        <Button width={'210px'} style={{ fontSize: 14 }} onClick={() => {
                                            setCurPage(1)
                                            setAmount(null)
                                        }}>Stake BOT</Button>
                                    </TextFrame>

                                    <TextFrame style={{ boxSizing: "border-box", borderLeft: '2px solid #F5F5F5', padding: '44px 40px' }}>
                                        <OText5>Your Stake</OText5>
                                        <OText2>{stakedAmount && `${weiToNumber(stakedAmount)} `}<OText5>BOT</OText5></OText2>
                                        <Button width={'210px'} style={{ fontSize: 14 }} onClick={() => {
                                            setCurPage(2)
                                            setAmount(null)
                                        }}>Unstake BOT</Button>
                                    </TextFrame>

                                    <TextFrame style={{ boxSizing: "border-box", borderLeft: '2px solid #F5F5F5', padding: '44px 40px' }}>
                                        <OText5>Your governance reward</OText5>
                                        <OText2>{govReward && `${weiToNumber(govReward)} `}<OText5>BOT</OText5></OText2>
                                        <Button width={'210px'} style={{ fontSize: 14 }} onClick={onGovClaim}>Claim governance Reward</Button>
                                    </TextFrame>
                                </StakingFrame>
                            </StakingFrame>
                        </StakingFrame>

                    }
                </>
                )
            case 1:
            case 2:
                return (
                    <>
                        {isXSDown &&
                            <Action style={{ padding: '30px 20px' }}>
                                <Action.Close onClick={() => { setCurPage(0) }} src={icon_close} />
                                <Action.Title>{curPage === 1 ? 'BOT Staking' : 'Unstake bOT'}</Action.Title>
                                <Action.Frame style={{ flexDirection: 'column' }}>
                                    <Action.Frame><OText3>Stake BOT</OText3></Action.Frame>
                                    <Action.Frame style={{ flexDirection: 'column' }}>
                                        <Form width={'100%'} error={false} input={<Input
                                            type='number'
                                            value={amount}
                                            onBlur={() => {
                                                if (curPage === 1) {
                                                    if ((amount && botBalance) && isGreaterThan(toWei(amount), botBalance)) {
                                                        setAmount(weiToNumber(botBalance).toString())
                                                    }
                                                } else {
                                                    if ((amount && stakedAmount) && isGreaterThan(toWei(amount), stakedAmount)) {
                                                        setAmount(weiToNumber(stakedAmount).toString())
                                                    }
                                                }

                                            }}
                                            onChange={(e) => {
                                                setAmount(e.target.value.replace(/[^\d.]/g, ''))
                                            }}
                                        />} name={'Amount'} addonAfter={(<img onClick={() => {
                                            if (curPage === 1) {
                                                setAmount(weiToNumber(botBalance).toString())
                                            } else {
                                                setAmount(weiToNumber(stakedAmount).toString())
                                            }

                                        }} src={icon_max} />)}
                                            extra={<span>{`Balance: ${curPage === 1 ? botBalance ? weiToNumber(botBalance) : '--' : stakedAmount ? weiToNumber(stakedAmount) : '--'}`}</span>} />

                                        <Action.MetaFrame style={{ width: '100%' }}>
                                            <Action.Meta>
                                                <span>BOT Staked</span>
                                                <span>{stakedAmount && weiToNumber(stakedAmount)}</span>
                                            </Action.Meta>

                                            <Action.Meta>
                                                <span>Total Rewards from Last 7 d</span>
                                                <span>{rewards && weiToNumber(rewards)}</span>
                                            </Action.Meta>
                                        </Action.MetaFrame>

                                        <Form width={'100%'} disabled name={<>{` `}</>} hidden prefix={(
                                            <div style={{ width: '100%' }}>
                                                <Button black width={'100%'} onClick={() => {
                                                    if (curPage === 1) {
                                                        onStake(amount)
                                                    } else {
                                                        onUnStake(amount)
                                                    }
                                                }}>{curPage === 1 ? 'Stake BOT' : 'Unstake BOT'}</Button>
                                            </div>)} />
                                    </Action.Frame>
                                </Action.Frame>
                            </Action>
                        }
                        { !isXSDown &&
                            <Action>
                                <Action.Close onClick={() => { setCurPage(0) }} src={icon_close} />
                                <Action.Title>{curPage === 1 ? 'BOT Staking' : 'Unstake bOT'}</Action.Title>
                                <Action.Frame>
                                    <Action.Frame><OText3>Stake BOT</OText3></Action.Frame>
                                    <Action.Frame style={{ flexDirection: 'column' }}>
                                        <Form width={'560px'} error={false} input={<Input
                                            type='number'
                                            value={amount}
                                            onBlur={() => {
                                                if (curPage === 1) {
                                                    if ((amount && botBalance) && isGreaterThan(toWei(amount), botBalance)) {
                                                        setAmount(weiToNumber(botBalance).toString())
                                                    }
                                                } else {
                                                    if ((amount && stakedAmount) && isGreaterThan(toWei(amount), stakedAmount)) {
                                                        setAmount(weiToNumber(stakedAmount).toString())
                                                    }
                                                }

                                            }}
                                            onChange={(e) => {
                                                setAmount(e.target.value.replace(/[^\d.]/g, ''))
                                            }}
                                        />} name={'Amount'} addonAfter={(<img onClick={() => {
                                            if (curPage === 1) {
                                                setAmount(weiToNumber(botBalance).toString())
                                            } else {
                                                setAmount(weiToNumber(stakedAmount).toString())
                                            }

                                        }} src={icon_max} />)}
                                            extra={<span>{`Balance: ${curPage === 1 ? botBalance ? weiToNumber(botBalance) : '--' : stakedAmount ? weiToNumber(stakedAmount) : '--'}`}</span>} />

                                        <Action.MetaFrame>
                                            <Action.Meta>
                                                <span>BOT Staked</span>
                                                <span>{stakedAmount && weiToNumber(stakedAmount)}</span>
                                            </Action.Meta>

                                            <Action.Meta>
                                                <span>Total Rewards from Last 7 d</span>
                                                <span>{rewards && weiToNumber(rewards)}</span>
                                            </Action.Meta>
                                        </Action.MetaFrame>

                                        <Form width={'560px'} disabled name={<>{` `}</>} hidden prefix={(<div>
                                            <Button black width={'560px'} onClick={() => {
                                                if (curPage === 1) {
                                                    onStake(amount)
                                                } else {
                                                    onUnStake(amount)
                                                }
                                            }}>{curPage === 1 ? 'Stake BOT' : 'Unstake BOT'}</Button>
                                        </div>)} />
                                    </Action.Frame>
                                </Action.Frame>
                            </Action>
                        }
                    </>
                )
        }
    }

    return (
        <>{renderPage()}</>
    )
}
