import React, { useState } from "react";
import styled from "styled-components";
import { Action, StakingFrame } from "../../components/Staking";
import { OText1, OText2, OText3, OText4, OText5 } from "../../components/common/Layout";
import { Button } from "../../components/common/Button";
import { useQueryLPStaking, useQueryMining } from "./Hooks";
import { weiToNumber } from "../../utils/numberTransform";
import icon_close from "../../static/image/icon-close.svg";
import { Form, Input } from "../../components/common/Form";
import icon_max from "../../static/image/icon-max.svg";
import { HelperLayout } from "../../components/common/HelperLayout";
import icon_helper from "../../static/image/icon-helper.svg";
import { isGreaterThan } from "../../utils/common";
import { useIsXSDown } from '../../components/utils/themeHooks';
import Web3 from "web3";

const { toWei } = Web3.utils


const TextFrame = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export const LPStaking = ({ onStake, onUnStake, onClaimStakingReward }) => {

  const { stakedTotal, rewardsPre, lpBalance, stakedAmount, stakingReward } = useQueryLPStaking()
  const isXSDown = useIsXSDown();
  const [curPage, setCurPage] = useState(0)
  const [amount, setAmount] = useState()

  const renderPage = () => {
    switch (curPage) {
      case 0:
        return (
          <>
            { isXSDown &&
              <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                  <StakingFrame padding={'44px 44px 20px'} height={'190px'} style={{ flexDirection: 'column', marginBottom: '14px' }}>
                    <TextFrame style={{ marginBottom: '30px' }}>
                      <OText5>Total LP Tokens Staked</OText5>
                      <OText3>{stakedTotal && `${weiToNumber(stakedTotal)} `}<OText5>LPT</OText5></OText3>
                    </TextFrame>

                    <TextFrame>
                      <OText5>Total Network Rewards Per Cycle</OText5>
                      <OText3>{rewardsPre && `${weiToNumber(rewardsPre)} `}<OText5>BOT</OText5></OText3>
                    </TextFrame>
                  </StakingFrame>

                  <StakingFrame padding={'44px'} height={'480px'} width={'100%'} style={{ flexDirection: 'column', flex: '0 0 auto' }}>
                    <TextFrame style={{ width: '100%', marginBottom: '30px' }}>
                      <OText5>Your Balance</OText5>
                      <OText3>{lpBalance && `${weiToNumber(lpBalance)} `}<OText5>LPT</OText5></OText3>
                      <Button width={'100%'} onClick={() => {
                        setCurPage(1)
                      }}>Stake LPT</Button>
                    </TextFrame>


                    <TextFrame style={{ width: '100%', marginBottom: '30px' }}>
                      <OText5>Your Stake</OText5>
                      <OText3>{stakedAmount && `${weiToNumber(stakedAmount)} `}<OText5>LPT</OText5></OText3>
                      <Button width={'100%'} onClick={() => {
                        setCurPage(2)
                      }}>Unstake LPT</Button>
                    </TextFrame>

                    <TextFrame style={{ width: '100%', marginBottom: '30px' }}>
                      <OText5>Your rewards estimation:</OText5>
                      <OText3>{stakingReward && `${weiToNumber(stakingReward)} `}<OText5>BOT</OText5></OText3>
                      <Button width={'100%'} onClick={onClaimStakingReward}>Claim staking reward</Button>
                    </TextFrame>
                  </StakingFrame>
                </StakingFrame>
              </StakingFrame>
            }
            { !isXSDown &&
              <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: 'row' }}>
                <StakingFrame padding={'44px'} width={'360px'} style={{ flexDirection: 'column' }}>
                  <OText4 style={{ width: 214 }}>Your Staking Rewards Estimation</OText4>
                  <OText1 style={{ marginTop: 60, fontSize: 64 }}>{stakingReward && `${weiToNumber(stakingReward, '', 2)} `}<OText3>BOT</OText3></OText1>
                  <Button style={{ fontSize: 14 }} onClick={onClaimStakingReward}>Claim Rewards</Button>
                </StakingFrame>

                <StakingFrame width={'872px'} style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                  <StakingFrame height={'144px'} style={{ flexDirection: 'row' }}>
                    <TextFrame style={{ boxSizing: "border-box", padding: '44px 40px', borderLeft: '2px solid #F5F5F5' }}>
                      <OText5>Total LP Tokens Staked</OText5>
                      <OText3 style={{ marginTop: 16 }}>{stakedTotal && `${weiToNumber(stakedTotal)} `}<OText5> LPT</OText5></OText3>
                    </TextFrame>

                    <TextFrame style={{ boxSizing: "border-box", padding: '44px 40px', borderLeft: '2px solid #F5F5F5' }}>
                      <OText5>Total Network Rewards Per Cycle</OText5>
                      <OText3 style={{ marginTop: 16 }}>
                        {rewardsPre && `${weiToNumber(rewardsPre)} `}<OText5> BOT</OText5>
                      </OText3>
                    </TextFrame>
                  </StakingFrame>

                  <StakingFrame padding={0} height={'248px'}>
                    <TextFrame style={{ boxSizing: "border-box", padding: '44px 40px' }}>
                      <OText5>Your Balance</OText5>
                      <OText2>{lpBalance && `${weiToNumber(lpBalance)} `} <OText5> LPT</OText5></OText2>
                      <Button width={'210px'} style={{ fontSize: 14 }} onClick={() => {
                        setCurPage(1)
                      }}>Stake LPT</Button>
                    </TextFrame>

                    <TextFrame style={{ boxSizing: "border-box", borderLeft: '2px solid #F5F5F5', padding: '44px 40px' }}>
                      <OText5>Your Stake</OText5>
                      <OText2>{stakedAmount && `${weiToNumber(stakedAmount)} `}<OText5> LPT</OText5></OText2>
                      <Button width={'210px'} style={{ fontSize: 14 }} onClick={() => {
                        setCurPage(2)
                      }}>Unstake LPT</Button>
                    </TextFrame>


                  </StakingFrame>
                </StakingFrame>
              </StakingFrame>

              // <StakingFrame style={{backgroundColor: '#F5F5F5', flexDirection: 'row'}}>
              //   <StakingFrame style={{backgroundColor: '#F5F5F5', flexDirection: 'column'}}>
              //     <StakingFrame padding={'44px 44px 20px'} height={'144px'} style={{flexDirection: 'row'}}>
              //       <TextFrame>
              //         <OText5>Total LP Tokens Staked</OText5>
              //         <OText3>{stakedTotal && `${weiToNumber(stakedTotal)} `}<OText5>LPT</OText5></OText3>
              //       </TextFrame>

              //       <TextFrame>
              //         <OText5>Total Network Rewards Per Cycle</OText5>
              //         <OText3>{rewardsPre && `${weiToNumber(rewardsPre)} `}<OText5>BOT</OText5></OText3>
              //       </TextFrame>
              //     </StakingFrame>

              //     <StakingFrame padding={'44px'} height={'248px'}>
              //       <TextFrame>
              //         <OText5>Your Balance</OText5>
              //         <OText3>{lpBalance && `${weiToNumber(lpBalance)} `}<OText5>LPT</OText5></OText3>
              //         <Button width={'380px'} onClick={() => {
              //           setCurPage(1)
              //         }}>Stake LPT</Button>
              //       </TextFrame>


              //       <TextFrame>
              //         <OText5>Your Stake</OText5>
              //         <OText3>{stakedAmount && `${weiToNumber(stakedAmount)} `}<OText5>LPT</OText5></OText3>
              //         <Button width={'380px'} onClick={() => {
              //           setCurPage(2)
              //         }}>Unstake LPT</Button>
              //       </TextFrame>

              //       <TextFrame>
              //         <OText5>Your rewards estimation:</OText5>
              //         <OText3>{stakingReward && `${weiToNumber(stakingReward)} `}<OText5>BOT</OText5></OText3>
              //         <Button width={'380px'} onClick={onClaimStakingReward}>Claim staking reward</Button>
              //       </TextFrame>
              //     </StakingFrame>
              //   </StakingFrame>
              // </StakingFrame>
            }
          </>
        )
      case 1:
      case 2:
        return (
          <>
            {isXSDown &&
              <Action style={{ padding: '30px 20px' }}>
                <Action.Close onClick={() => {
                  setCurPage(0)
                }} src={icon_close} />
                <Action.Title style={{ fontSize: '26px' }}>{'Uniswap LP Token Staking'}</Action.Title>
                <Action.Frame style={{ flexDirection: 'column', flex: '0 0 auto' }}>
                  <Action.Frame><OText3>Stake BOT</OText3></Action.Frame>
                  <Action.Frame style={{ flexDirection: 'column' }}>
                    <Form width={'100%'} error={false} input={<Input
                      type='number'
                      value={amount}
                      onBlur={() => {
                        if (amount && lpBalance && isGreaterThan(toWei(amount), lpBalance)) {
                          setAmount(weiToNumber(lpBalance).toString())
                        }
                      }}
                      onChange={(e) => {
                        setAmount(e.target.value.replace(/[^\d.]/g, ''))
                      }}
                    />} name={'Amount'} addonAfter={(<img onClick={() => {
                      setAmount(weiToNumber(lpBalance).toString())
                    }} src={icon_max} />)}
                      extra={<span>{`Balance: ${lpBalance ? weiToNumber(lpBalance) : '--'}`}</span>} />

                    <Action.MetaFrame style={{ width: '100%' }}>
                      <Action.Meta>
                        <span>LPT Staked</span>
                        <span>{stakedAmount && weiToNumber(stakedAmount)} LPT</span>
                      </Action.Meta>

                      <Action.Meta>
                        <span>Total Rewards from Last 7 d</span>
                        <span>10 LPT</span>
                      </Action.Meta>
                    </Action.MetaFrame>

                    <Form width={'100%'} disabled name={<>{` `}</>} hidden prefix={(
                      <div style={{ width: '100%' }}>
                        <Button black width={'100%'} onClick={() => {
                          if (curPage === 1) {
                            onStake(amount)
                          } else {
                            onUnStake(amount)
                          }
                        }}>{curPage === 1 ? 'Stake LPT' : 'Unstake LPT'}</Button>
                      </div>)} />
                  </Action.Frame>
                </Action.Frame>
              </Action>
            }
            {!isXSDown &&
              <Action>
                <Action.Close onClick={() => {
                  setCurPage(0)
                }} src={icon_close} />
                <Action.Title>{'Uniswap LP Token Staking'}</Action.Title>
                <Action.Frame>
                  <Action.Frame><OText3>Stake BOT</OText3></Action.Frame>
                  <Action.Frame style={{ flexDirection: 'column' }}>
                    <Form width={'560px'} error={false} input={<Input
                      type='number'
                      value={amount}
                      onBlur={() => {
                        if (curPage === 1 && amount && lpBalance && isGreaterThan(toWei(amount), lpBalance)) {
                          setAmount(weiToNumber(lpBalance).toString())
                        }
                        if (curPage !== 1 && amount && lpBalance && isGreaterThan(toWei(amount), stakedAmount)) {
                          setAmount(weiToNumber(stakedAmount).toString())
                        }
                      }}
                      onChange={(e) => {
                        setAmount(e.target.value.replace(/[^\d.]/g, ''))
                      }}
                    />} name={'Amount'} addonAfter={(<img onClick={() => {

                      setAmount(curPage === 1 ? weiToNumber(lpBalance).toString() : weiToNumber(stakedAmount).toString())
                    }} src={icon_max} />)}
                      extra={<span>{`Balance: ${curPage === 1 ? lpBalance ? weiToNumber(lpBalance) : '--' : stakedAmount ? weiToNumber(stakedAmount) : '--'}`}</span>} />

                    <Action.MetaFrame>
                      <Action.Meta>
                        <span>LPT Staked</span>
                        <span>{stakedAmount && weiToNumber(stakedAmount)} LPT</span>
                      </Action.Meta>

                      <Action.Meta>
                        <span>Total Rewards from Last 7 d</span>
                        <span>10 LPT</span>
                      </Action.Meta>
                    </Action.MetaFrame>

                    <Form width={'560px'} disabled name={<>{` `}</>} hidden prefix={(<div>
                      <Button black width={'560px'} onClick={() => {
                        if (curPage === 1) {
                          onStake(amount)
                        } else {
                          onUnStake(amount)
                        }
                      }}>{curPage === 1 ? 'Stake LPT' : 'Unstake LPT'}</Button>
                    </div>)} />
                  </Action.Frame>
                </Action.Frame>
              </Action>
            }
          </>

        )
    }
  }

  return (
    renderPage()
  )
}
