import React, { useState, useEffect } from "react";
import { Action, StakingFrame } from "../../components/Staking";
import { useRequestUrl, useRequestParams } from './useRequest'
import axios from 'axios'
import { OText1, OText2, OText4, OText5, OTip } from "../../components/common/Layout";
import BigNumber from "bignumber.js";
import { useActivePlatform } from "../../web3";
import { useIsXSDown } from '../../components/utils/themeHooks';

const addComma = (num) => {
    const str = String(num)
    let index = str.indexOf('.')
    const arr = []
    let s_1 = null
    let s_2 = null
    if (index === -1) {
        // 整数
        index = str.length
    }

    if (index <= 3) return str

    while (index > 3) {
        s_2 = s_2 || str
        s_1 = s_2.substr(index - 3)
        s_2 = s_2.substr(0, index - 3)
        arr.unshift(s_1)
        index = index - 3
    }

    arr.unshift(s_2)
    // console.log('O_console', arr.join(','))
    return arr.join(',')
}

function Staking() {
    const { Psymbol } = useActivePlatform()
    const URL = useRequestUrl()
    const params = useRequestParams('query_staking_info');
    const isXSDown = useIsXSDown();

    const [data, setData] = useState({
        total: '--',
        // totalRose: '6%',
        release: '--',
        // releaseRose: '1.2%',
        circulating: '--',
        circulatingmbol: ' BOT',
        // circulatingRose: '1.2%',
        uniswap: '--',
        // uniswapRose: '16%',
    })

    useEffect(() => {
        if (URL === null) return
        try {
            axios.post(URL, params).then(res => {
                res = res.data.result[0]
                setData({
                    ...data,
                    total: addComma(new BigNumber(res.totaltxfee).toFixed(2)),
                    release: addComma(new BigNumber(res.txfeelastcycle).toFixed(2)),
                    circulating: addComma(new BigNumber(res.totalbotstaked).toFixed(2)),
                    uniswap: res.apyforbot + ' %',
                })
            })
        } catch (error) {

        }
    }, [URL])

    //     Staking:
    // 1. Total transaction fee accured
    // 2. Transaction fee from last cycle
    // 3. Total number of BOT staked
    // 4. Estimated APY for BOT staking
    return (
        <StakingFrame width={'100%'} style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
            <StakingFrame height={isXSDown?'380px':'194px'} style={{ backgroundColor: '#F5F5F5', flexDirection: isXSDown?'column':'row',marginBottom:isXSDown?'10px':'0' }}>
                <StakingFrame padding={'44px 40px'} width={isXSDown?'100%':'636px'} height={'194px'} style={{ flexDirection: 'column' }}>
                    <div>
                        <OText5>Total transaction fee accured</OText5><br />
                        <OText2 style={{ display: "block", marginTop: 30 }}>{data.total}<OText4> {'ETH'}</OText4></OText2>
                        {/* <OTip>↑ {data.totalRose} Per Month</OTip> */}
                    </div>
                </StakingFrame>
                <StakingFrame padding={'44px 44px 20px'} width={isXSDown?'100%':'636px'} height={'194px'} style={{ flexDirection: 'column' }}>
                    <div>
                        <OText5>Transaction fee from last cycle</OText5><br />
                        <OText2 style={{ display: "block", marginTop: 30 }}>{data.release}<OText4> {Psymbol}</OText4></OText2>
                        {/* <OTip>↑ {data.releaseRose} Per Month</OTip> */}
                    </div>
                </StakingFrame>
            </StakingFrame>

            <StakingFrame height={isXSDown?'380px':'194px'} style={{ backgroundColor: '#F5F5F5', flexDirection: isXSDown?'column':'row' }}>
                <StakingFrame padding={'44px 40px'} width={isXSDown?'100%':'636px'} height={'194px'} style={{ flexDirection: 'column' }}>
                    <div>
                        <OText5>Total number of BOT staked</OText5><br />
                        <OText2 style={{ display: "block", marginTop: 30 }}>{data.circulating}<OText4>{'BOT'}</OText4></OText2>
                        {/* <OTip>↑ {data.circulatingRose} Per Month</OTip> */}
                    </div>
                </StakingFrame>
                <StakingFrame padding={'44px 44px 20px'} width={isXSDown?'100%':'636px'} height={'194px'} style={{ flexDirection: 'column' }}>
                    <div>
                        <OText5>Estimated APY for BOT staking</OText5><br />
                        <OText2 style={{ display: "block", marginTop: 30 }}>{data.uniswap}</OText2>
                        {/* <OTip>↑ {data.uniswapRose}Per Month</OTip> */}
                    </div>
                </StakingFrame>
            </StakingFrame>
        </StakingFrame>
    )
}

export default Staking