import React, { useState, useEffect, useContext } from "react";
import { StakingFrame } from "../../components/Staking";
import { useRequestUrl, useRequestParams } from './useRequest'
import axios from 'axios'
import {OText1, OText2, OText3, OText4, OText5, OTip} from "../../components/common/Layout";
import { useActivePlatform } from "../../web3";
import { useIsXSDown } from '../../components/utils/themeHooks';

const addComma = (num) => {
    const str = String(num)
    let index = str.indexOf('.')
    const arr = []
    let s_1 = null
    let s_2 = null
    if (index === -1) {
        // 整数
        index = str.length
    }

    if (index <= 3) return str

    while (index > 3) {
        s_2 = s_2 || str
        s_1 = s_2.substr(index - 3)
        s_2 = s_2.substr(0, index - 3)
        arr.unshift(s_1)
        index = index - 3
    }

    arr.unshift(s_2)
    // console.log('O_console', arr.join(','))
    return arr.join(',')
}

function Market() {
    const { Psymbol } = useActivePlatform()
    const URL = useRequestUrl()
    const params = useRequestParams('query_bot_info')
    const isXSDown = useIsXSDown();
    const [data, setData] = useState({
        price: '--',
        priceSymbol: ' USDT',
        priceRose: '0%',
        total: '--',
        totalSymbol: ' BOT',
        // totalRose: '1.2%',
        release: '--',
        // releaseRose: '16%',
        circulating: '--',
        // circulatingRose: '1.2%',
        uniswap: '--',
        // uniswapRose: '16%',
    })
    // 1. BOT Price:
    // 2. BOT Total supply: 100000 BOT
    // 3. BOT circulating supply:
    // 4. BOT daily  token release: 16 BOT
    // 5. Total liquidity on uniswap:

    useEffect(() => {
        if (URL === null) return
        try {
            axios.post(URL, params).then(res => {
                res = res.data.result[0]
                setData({
                    ...data,
                    price: addComma(res.botprice),
                    priceRose: addComma(res.changemonth),
                    total: addComma(100000),
                    release: addComma(3),
                    circulating: addComma(26500),
                    uniswap: addComma(res.totalliquidity)
                })
            })
        } catch (error) {

        }
    }, [URL])

    return (
        <StakingFrame style={{ backgroundColor: '#F5F5F5', flexDirection: isXSDown?'column':'row' }}>
            <StakingFrame padding={'44px'} width={isXSDown?'100%':'460px'} style={{ flexDirection: 'column',marginBottom:isXSDown?'10px':'0' }}>
                <div>
                    <OText5>BOT price</OText5><br />
                    <OText1 style={{ display: "block", marginTop: isXSDown?'30px':'132px' }}>{data.price} <OText4>{data.priceSymbol}</OText4></OText1>
                    {parseFloat(data.priceRose) < 0 ?
                        <OTip style={{ display: "block", marginTop: '20px', color: '#E43F29' }}>↓ {data.priceRose} Per Month</OTip> :
                        <OTip style={{ display: "block", marginTop: '20px' }}>↑ {data.priceRose} Per Month</OTip>
                    }
                </div>
            </StakingFrame>
            <StakingFrame width={isXSDown?'100%':'772px'}style={{ backgroundColor: '#F5F5F5', flexDirection: 'column' }}>
                <StakingFrame height={isXSDown?'380px':'194px'} style={{ backgroundColor: '#F5F5F5', flexDirection: isXSDown?'column':'row',marginBottom:isXSDown?'10px':'0' }}>
                    <StakingFrame padding={'44px 40px'} width={'382px'} height={'194px'} style={{ flexDirection: 'column' }}>
                        <div>
                            <OText5>BOT total supply</OText5><br />
                            <OText3 style={{ display: "block", marginTop: 30 }}>{data.total} <OText4>{data.totalSymbol}</OText4></OText3>
                            {/* <OTip>↑ {data.totalRose} Per Month</OTip> */}
                        </div>
                    </StakingFrame>
                    <StakingFrame padding={'44px 44px 20px'} width={'382px'} height={'194px'} style={{ flexDirection: 'column' }}>
                        <div>
                            <OText5>BOT daily token release</OText5><br />
                            <OText3 style={{ display: "block", marginTop: 30 }}>{data.release} <OText4>BOT</OText4></OText3>
                            {/* <OTip>↑ {data.releaseRose} Per Month</OTip> */}
                        </div>
                    </StakingFrame>
                </StakingFrame>

                <StakingFrame height={isXSDown?'380px':'194px'} style={{ backgroundColor: '#F5F5F5', flexDirection: isXSDown?'column':'row' }}>
                    <StakingFrame padding={'44px 40px'} width={'382px'} height={'194px'} style={{ flexDirection: 'column' }}>
                        <div>
                            <OText5>BOT circulating supply</OText5><br />
                            <OText3 style={{ display: "block", marginTop: 30 }}>{data.circulating} <OText4>BOT</OText4></OText3>
                            {/* <OTip>↑ {data.circulatingRose} Per Month</OTip> */}
                        </div>
                    </StakingFrame>
                    <StakingFrame padding={'44px 44px 20px'} width={'382px'} height={'194px'} style={{ flexDirection: 'column' }}>
                        <div>
                            <OText5>Total liquidity on uniswap</OText5><br />
                            <OText3 style={{ display: "block", marginTop: 30 }}>{data.uniswap} <OText4>USD</OText4></OText3>
                            {/* <OTip>↑ {data.uniswapRose} Per Month</OTip> */}
                        </div>
                    </StakingFrame>
                </StakingFrame>
            </StakingFrame>
        </StakingFrame>
    )
}

export default Market
