import React, { useReducer } from "react";
import {
  HANDLE_SHOW_CONNECT_MODAL,
  HANDLE_MY_NFTS_MODAL,
  HANDLE_WEB3_CONTEXT,
  HANDLE_SHOW_MENU_MODAL,
  HANDLE_SHOW_USER_DETAIL,
  HANDLE_SHOW_SEARCH_DETAIL
} from "./const";

const myContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case HANDLE_SHOW_CONNECT_MODAL:
      return { ...state, showConnectModal: action.showConnectModal }
    case HANDLE_WEB3_CONTEXT:
      return { ...state, web3Context: action.web3Context }
    case HANDLE_MY_NFTS_MODAL:
      return { ...state, NTFSModal: action.NTFSModal }
    case HANDLE_SHOW_MENU_MODAL:
      return { ...state, showMenuModal: action.showMenuModal }
    case HANDLE_SHOW_USER_DETAIL:
      return { ...state, showUserDetail: action.showUserDetail }
    case HANDLE_SHOW_SEARCH_DETAIL:
      return { ...state, showSearchDetail: action.showSearchDetail }
    case 'HANDLER_HAS_POOL':
      return { ...state, hasPool: action.hasPool };
    case 'HANDLER_HAS_BID_POOL':
      return { ...state, hasBidPool: action.hasBidPool };
    case 'HANDLER_HAS_DUTCH_POOL':
      return { ...state, hasDutchPool: action.hasDutchPool };
    case 'HANDLER_HAS_NFT_POOL':
      return { ...state, hasNFTPool: action.hasNFTPool };
    case 'HANDLER_HAS_NFT_1155_POOL':
      return { ...state, hasNFT1155Pool: action.hasNFT1155Pool };
    case 'HANDLER_HAS_NFT_ENGLISH_AUCTION_POOL':
      return { ...state, hasNFTEnglishAuctionPool: action.hasNFTEnglishAuctionPool };
    case 'HANDLER_SEALED_BID_NFT_AUCTION_POOL':
      return { ...state, hasSBNFTAuctionPool: action.hasSBNFTAuctionPool };
    case 'QUERY_POOL_LIST':
      return state
    case 'HANDLER_POOL_ID_LIST':
      return { ...state, poolIdList: action.poolIdList };
    case 'ADD_POOL_LIST':
      return { ...state, poolList: state.poolList.concat(action.poolList) };
    case 'HANDLER_MOBILE_NAV':
      return { ...state, showMobileNav: action.showMobileNav };
    case 'HANDLER_MY_ACCOUNT':
      return { ...state, myAccount: action.myAccount };
    case 'HANDLER_WEB3':
      return { ...state, web3: action.web3 };
    case 'HANDLER_POOL_TYPE':
      return { ...state, poolType: action.poolType };
    case 'HANDLER_CREATE_POOL_TYPE':
      return { ...state, createPoolType: action.createPoolType };
    case 'HANDLER_CHECK_POOL_TYPE':
      return { ...state, checkPoolType: action.checkPoolType };
    case 'HANDLER_BIDEN_POOLS':
      return { ...state, bidenPools: action.bidenPools };
    case 'HANDLER_CUR_POOL_TYPE':
      return { ...state, curPoolType: action.curPoolType };
    case 'HANDLER_ETH_PRICE':
      return { ...state, ethPrice: action.ethPrice };
    case 'HANDLER_BOT_PRICE':
      return { ...state, BOTPrice: action.BOTPrice };
    case 'HANDLER_BTC_PRICE':
      return { ...state, BBTCPrice: action.BTCPrice };
    case 'HANDLE_FIRST_TIP':
      return { ...state, firstTip: action.firstTip };
    default:

      return state
  }
}

const ContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, {
    showConnectModal: false,
    web3Context: { account: null, active: false, library: null },
    NTFSModal: false,
    showMenuModal:false,
    showUserDetail:false,
    showSearchDetail:false,
    hasPool: false,
    hasBidPool: false,
    hasDutchPool: false,
    hasNFTPool: false,
    hasNFT1155Pool: false,
    hasNFTEnglishAuctionPool: false,
    hasSBNFTAuctionPool: false,
    bidenPools: [],
    poolIdList: [],
    surplusIdList: [],
    poolList: [],
    showMobileNav: false,
    myAccount: null,
    web3: null,
    poolType: 0, //0:Fixed swap 1:Sealed bid 2:Dutch auction
    createPoolType: 0, //0:Fixed swap 1:Sealed bid 2:Dutch auction
    checkPoolType: 0,
    curPoolType: 0,
    ethPrice: 0,
    BOTPrice: 0,
    BTCPrice: 0,
    firstTip: true
  });
  return (
    <myContext.Provider value={{ state, dispatch }}>
      {props.children}
    </myContext.Provider>
  );
};

export { reducer, myContext, ContextProvider };
