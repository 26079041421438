import React, {useState, useEffect, useContext} from 'react';
import {
  Address,
  ITextR,
  LayoutFrame, NFTInfo,
  OText2, OText3,
  Pool,
  renderTime
} from "../../../../components/common/Layout";
import bounceERC20ABI from '../../../../web3/abi/bounceERC20.json';
import icon_return from '../../../../static/image/icon-return.svg'
import {usePoolDetail} from "./Hooks";
import {useHistory} from "react-router-dom";
import {numToWei, weiToNumber} from "../../../../utils/numberTransform";
import classNames from "classnames";
import {useParams} from 'react-router-dom';
import {Form, Input} from "../../../../components/common/Form";
import icon_max from "../../../../static/image/icon-max.svg";
import {Button} from "../../../../components/common/Button";
import {useEthBalance} from "../../../../web3/common";
import {getContract, useActiveWeb3React} from "../../../../web3";
import BounceFSNFT from "../../../../web3/abi/BounceFSNFT.json";
import rect_logo from '../../../../static/image/rect-logo.svg'
import {getFixSwapNFTAddress} from "../../../../web3/contractAddress";
import Web3 from 'web3'
import {useIsXSDown} from '../../../../components/utils/themeHooks';
import {
  BidModal,
  initStatus,
  errorStatus,
  successStatus,
  confirmStatus,
  pendingStatus,
  cancelStatus, claimSuccessStatus
} from "../../../../components/common/BidModal";
import {useLeftTime} from "../../../../hooks/useLeftTime";
import {PoolCover} from "../../../../components/DetailCover";
import {isGreaterThan} from "../../../../utils/common";
import {myContext} from "../../../../reducer";
import BigNumber from 'bignumber.js';
import {Message} from "../../../../components/common/message";
import {CREATOR_CLAIMED_MESSAGE} from "../../../../const";
import {TipLink} from "../../../../components/common/TipLink";

const {toWei, fromWei} = Web3.utils

const bidSuccessStatus = {status: 3, title: 'Congratulations!', content: 'You have successfully bidded and your swapped tokens are automatically sent to your wallet. You can now make more bids.'}

export const FSNFTStoreDetail = () => {
  // HANDLER_ETH_PRICE
  const { proxy, id } = useParams();
  const {state} = useContext(myContext);
  const {account, library, chainId} = useActiveWeb3React()
  const {setTime, leftTime} = useLeftTime()
  const [bidAmount, setBidAmount] = useState()
  const [bidStatus, setBidStatus] = useState(initStatus)
  const history = useHistory()
  const isXSDown = useIsXSDown();

  const {
    name, address, status, NFT, round, limit, curBiddenAmount,
    password, time, isMine, claimButtonText, myBid, joined, amountMinIncr, setJoined, toToken
  } = usePoolDetail(id, proxy)

  console.log('toToken', toToken)

  const {ethBalance} = useEthBalance()

  let timer = null
  useEffect(() => {
    timer = setInterval(() => {
      console.log('set time', time)
      const date = new Date(time * 1000);
      const now = new Date();
      const lefttime = date - now;
      if (lefttime > 0) {
        setTime(lefttime)
      } else {
        clearInterval(timer)
      }
    }, (1000));
    return () => {
      clearInterval(timer)
    }
  }, [setTime, time]);


  const onBid = async () => {
    const bounceContract = getContract(library, BounceFSNFT.abi, proxy)
    setBidStatus(confirmStatus);

    console.log('bounceContract', id, password, account, limit,proxy)
    try {
      if (toToken.toSymbol === 'ETH') {
        bounceContract.methods.swap(id, password)
            .send({from: account, value: limit})
            .on('transactionHash', hash => {
              setBidStatus(pendingStatus)
            })
            .on('receipt', (_, receipt) => {
              console.log('bid fixed swap receipt:', receipt)
              setJoined(true)

              setBidStatus(bidSuccessStatus)
            })
            .on('error', (err, receipt) => {
              setBidStatus(errorStatus)
            })
      } else {

        const tokenContract = getContract(library, bounceERC20ABI.abi, toToken.toAddress)
        const result = await tokenContract.methods.approve(
            toToken.toAddress,
            numToWei(toToken.toPrice, toToken.toDecimals)
        ).send({from: account});
        if (result.status) {
          bounceContract.methods.swap(id, password)
              .send({from: account})
              .on('transactionHash', hash => {
                setBidStatus(pendingStatus)
              })
              .on('receipt', (_, receipt) => {
                console.log('bid fixed swap receipt:', receipt)
                setJoined(true)
                setBidStatus(successStatus)
              })
              .on('error', (err, receipt) => {
                setBidStatus(errorStatus)
              })
        }
      }
    } catch (e) {
      console.log('bid error', e)
      if (e.code === 4001) {
        setBidStatus(cancelStatus)
      } else {
        setBidStatus(errorStatus)
      }
    }
  }


  const onClaim = async () => {
    const bounceContract = getContract(library, BounceFSNFT.abi, proxy)
    setBidStatus(confirmStatus);
    try {
      bounceContract.methods.creatorClaim(id)
          .send({from: account})
          .on('transactionHash', hash => {
            setBidStatus(pendingStatus)
          })
          .on('receipt', (_, receipt) => {
            console.log('bid fixed swap receipt:', receipt)
            setBidStatus(claimSuccessStatus)
          })
          .on('error', (err, receipt) => {
            setBidStatus(errorStatus)
          })
    } catch (e) {
      if (e.code === 4001) {
        setBidStatus(cancelStatus)
      } else {
        setBidStatus(errorStatus)
      }
    }

  }

  return (
      <LayoutFrame style={{marginTop: 27}}>

        {isMine ?
            <>
              {status === 'Live' && (
                  <Message type={'success'}
                           content={'The auction is still live, please wait patiently until your auction is filled or closed.'}/>
              )}
              {status === 'Filled' && claimButtonText && (
                  <Message type={'success'}
                           content={'Congratulations! Your auction is complete. Your NFT is auctioned and your fund is automatically sent to your wallet.'}/>
              )}
              {status === 'Closed' && claimButtonText && (
                  <Message
                      type={'error'}
                      content={'Unfortunately, your pool is not filled and closed. Please claim back the unswapped NFT manually.'}/>
              )}
              {status === 'Closed' && !claimButtonText && (
                  <Message
                      type={'success'}
                      content={CREATOR_CLAIMED_MESSAGE}/>
              )}
            </>
            : null}


        {/*{!isMine ?*/}
        {/*    <>*/}
        {/*      {joined && (*/}
        {/*          <Message type={'success'}*/}
        {/*                   content={'You have successfully bidded and your swapped NFT is automatically sent to your wallet. You can now make more bids.'}/>*/}
        {/*      )}*/}
        {/*      {status === 'Closed' && !joined && (*/}
        {/*          <Message content={'This auction is finished, please visit other live auctions.'}/>*/}
        {/*      )}*/}
        {/*    </>*/}
        {/*    : null}*/}


        <Pool.Return src={icon_return} onClick={() => {
          history.goBack()
        }}/>
        <LayoutFrame width={isXSDown?'100%':'1181px'} style={{padding: '40px 0', margin: 'auto', marginTop: 32}}>
          <Pool.Mode>Fixed Swap auction</Pool.Mode>
          <Pool.Header style={{justifyContent: 'center'}}><span>{name}</span></Pool.Header>
          <Address style={{wordBreak: isXSDown ? 'break-all' : 'normal', marginBottom: 40}}>{address}</Address>
          {!isXSDown &&
          <Pool.Content>
            <Pool.Content width={isXSDown?'100%':'272px'} style={{marginTop: 0, height: 'fit-content'}}>

              <Pool.Content width={isXSDown?'100%':'456px'} style={{marginTop: 0, flexDirection: 'column'}}>
                <Pool.Status style={{width: 'fit-content'}}
                             className={classNames('status', status)}>• {status}</Pool.Status>

                <ITextR style={{marginTop: 8, textAlign: 'left'}}>{'Participant: Public'}</ITextR>
              </Pool.Content>

              <Pool.Block style={{height: 100, padding: '14px 0'}}>
                <span>Fixed-Swap Price</span>
                <span>{toToken && `${toToken.toPrice || ''} ${toToken.toSymbol || ''}`}</span>
              </Pool.Block>

              <Pool.Block style={{height: 100, padding: '14px 0'}}>
                <span>Price,$</span>
                <span>{limit && state.ethPrice && `${Number(new BigNumber(state.ethPrice).multipliedBy(weiToNumber(limit)).toFixed(6).toString())}`}</span>
              </Pool.Block>

            </Pool.Content>

            <Pool.Content width={isXSDown?'100%':'512px'} style={{marginTop: 0}}>
              <PoolCover cover={NFT && (NFT.image ? NFT.image.replace(/ipfs:\/\//, 'https://ipfs.io/') : rect_logo)}/>
            </Pool.Content>

            <Pool.Content width={isXSDown?'100%':'272px'} heigth={'502px'} style={{marginTop: 0}}>
              <NFTInfo>
                <NFTInfo.Artist>{NFT && NFT.name}</NFTInfo.Artist>
                <NFTInfo.Title>{NFT && NFT.title}</NFTInfo.Title>
                <NFTInfo.Desc>{NFT && NFT.description}</NFTInfo.Desc>
              </NFTInfo>

              {renderTime(leftTime)}

            </Pool.Content>

            <Pool.Content width={isXSDown?'100%':'1181px'}
                          style={{
                            flexDirection: 'column',
                            padding: 56,
                            alignItems: 'center',
                            marginTop: 100,

                            backgroundColor: 'rgba(248, 248, 251, 1)'
                          }}>
              {(!isMine && !joined && status === 'Live') && (
                  <>
                    <Button black width={isXSDown?'100%':'320px'} onClick={onBid}>Purchase the nft</Button>
                    <TipLink/>
                  </>
              )}

              {(!isMine && (joined || status === 'Closed')) && (
                  <>
                    <Button black className='display' width={isXSDown?'100%':'320px'} disabled>Purchase the nft</Button>
                  </>
              )}


              {(isMine) && (
                  <>
                    {status === 'Live' && (
                        <OText3 style={{textAlign: 'center'}}>Your pool is live</OText3>
                    )}

                    {status === 'Filled' && (
                        <OText3 style={{textAlign: 'center'}}>Your NFT is successfully sold<br/>
                          and your fund is automatically sent to your wallet.</OText3>

                    )}

                    {status === 'Filled' && claimButtonText && (
                        <Button black width={isXSDown?'100%':'320px'} onClick={onClaim}>Claim swapped ETH</Button>
                    )}

                    {status === 'Closed' && claimButtonText && (
                        <Button black width={isXSDown?'100%':'320px'} onClick={onClaim}>Claim unswapped NFT</Button>
                    )}
                  </>
              )}

            </Pool.Content>
          </Pool.Content>
          }
          {isXSDown &&
          <Pool.Content>
            <Pool.Content width={'100%'} style={{marginTop: 0, height: 'fit-content'}}>
              <Pool.Content width={isXSDown?'100%':'456px'} style={{marginTop: 0, flexDirection: 'column', padding: '0 20px'}}>
                <Pool.Status style={{width: 'fit-content'}}
                             className={classNames('status', status)}>• {status}</Pool.Status>

                <ITextR style={{marginTop: 8, textAlign: 'left'}}>{'Participant: Public'}</ITextR>
              </Pool.Content>

              <Pool.Block style={{
                width: '100%',
                alignItems: "flex-start",
                marginLeft: "20px",
                marginRight: "20px",
                height: '100px'
              }}>
                <span>Fixed-Swap Price</span>
                <span>{limit && `${weiToNumber(limit)} ETH`}</span>
              </Pool.Block>

              <Pool.Block style={{
                width: '100%',
                alignItems: "flex-start",
                marginLeft: "20px",
                marginRight: "20px",
                height: '100px'
              }}>
                <span>Minimal Bid price increment:</span>
                <span>{amountMinIncr && weiToNumber(amountMinIncr)}</span>
              </Pool.Block>

              <Pool.Block style={{
                width: '100%',
                alignItems: "flex-start",
                marginLeft: "20px",
                marginRight: "20px",
                height: '100px'
              }}>
                <span>Current Highest Bid: </span>
                <span>{curBiddenAmount && weiToNumber(curBiddenAmount)}</span>
              </Pool.Block>

            </Pool.Content>

            <Pool.Content width={isXSDown?'100%':'512px'} style={{marginTop: 0, padding: '0 20px'}}>
              <PoolCover cover={NFT && (NFT.image ? NFT.image.replace(/ipfs:\/\//, 'https://ipfs.io/') : rect_logo)}/>
            </Pool.Content>

            <Pool.Content width={'100%'} heigth={'502px'} style={{marginTop: 0, padding: '0 20px'}}>
              <NFTInfo>
                <NFTInfo.Artist>{NFT && NFT.name}</NFTInfo.Artist>
                <NFTInfo.Title>{NFT && NFT.title}</NFTInfo.Title>
                <NFTInfo.Desc>{NFT && NFT.description}</NFTInfo.Desc>
              </NFTInfo>
              <div style={{
                fontSize: '12px',
                color: '#1F191B',
                opacity: '0.5',
                width: '100%',
                'textAlign': 'left',
                marginBottom: isXSDown?0:'-20px'
              }}>Time left
              </div>
              {renderTime(leftTime)}

            </Pool.Content>

            <Pool.Content width={isXSDown?'100%':'1181px'}
                          style={{
                            height: 'fit-content',
                            flexDirection: 'column',
                            padding: '40px 16px',
                            justifyContent: 'center',
                            margin: '40px 20px',
                            backgroundColor: 'rgba(248, 248, 251, 1)',
                            overflow:isXSDown?'hidden':'initial'
                          }}>
              {(!isMine && !joined && status === 'Live') && (
                  <>
                    <OText3 style={{textAlign: 'center'}}>Join The Pool</OText3>
                    <Form disabled top={'49px'} width={isXSDown?'100%':'320px'} input={<Input
                        placeholder={'Bid Amount'}
                        type='number'
                        value={bidAmount}

                        onBlur={() => {
                          if (bidAmount && ethBalance && isGreaterThan(toWei(bidAmount), ethBalance)) {
                            setBidAmount(weiToNumber(ethBalance))
                          }
                        }}
                        onChange={(e) => {
                          setBidAmount(e.target.value.replace(/[^\d.]/g, ''))
                        }}
                    />} name={'Your Bid Amount '} addonAfter={(<img onClick={() => {
                      console.log('set max amount', ethBalance)
                      setBidAmount(fromWei(ethBalance))
                    }} src={icon_max}/>)}
                          extra={<span
                              style={{top: 0}}>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>}/>
                    <Button black width={isXSDown?'100%':'320px'} onClick={onBid}>Purchase the nft</Button>
                  </>
              )}

              {(!isMine && (joined || status === 'Closed')) && (
                  <>
                    <OText3  style={{ textAlign: 'center',width:isXSDown?'100%':'auto' }}>You Joined</OText3>
                    <Pool.MetaFrame  style={{ textAlign: 'center',width:isXSDown?'100%':'auto' }}>
                      <Pool.Meta style={{flexDirection: 'column'}}>
                        <div>The current Round / Total Round</div>
                        <OText2
                            style={{lineHeight: '48px'}}>{round && round} / {limit && parseInt(limit) + 1}</OText2>
                      </Pool.Meta>

                      <Pool.MetaDivider/>

                      <Pool.Meta style={{flexDirection: 'column'}}>
                        <div>Your bid amount:</div>
                        <OText2
                            style={{lineHeight: '48px'}}>{`${myBid ? weiToNumber(myBid) : 0} ETH`}</OText2>
                      </Pool.Meta>
                    </Pool.MetaFrame>
                    {claimButtonText && <Button black width={isXSDown?'100%':'320px'} onClick={onClaim}>{claimButtonText}</Button>}

                  </>
              )}


              {(isMine) && (
                  <>
                    {status === 'Live' && (
                        <OText3 style={{textAlign: 'center'}}>Your pool is live</OText3>
                    )}

                    {status === 'Filled' && (
                        <OText3 style={{textAlign: 'center'}}>Your NFT is successfully sold
                          and your fund is automatically sent to your wallet.</OText3>
                    )}

                  </>
              )}

            </Pool.Content>
          </Pool.Content>
          }


        </LayoutFrame>


        {/*{fromBidAmount && fromAmount && (*/}
        {/*    <Progress height={'8px'} className={classNames('progress', status)}>*/}
        {/*        <Progress.Value style={{width: `${getProgress(fromBidAmount, fromAmount)}%`}}*/}
        {/*                        className={classNames('progress-value', status)}/>*/}
        {/*    </Progress>*/}
        {/*)}*/}


        <BidModal modalStatus={bidStatus} onDismiss={() => {
          setBidStatus(initStatus)
        }}/>
      </LayoutFrame>
  )
}
