import localeUS from './en-US.json';
import localeZH from './zh-CN.json';
import localeKR from './ko-KR.json';
import localeRU from './ru-RU.json';

export const LOCALES_DATA = {
  'en-US': localeUS,
  'zh-CN': localeZH,
  'ko-KR': localeKR,
  'ru-RU': localeRU,
}

export const locales = [
  {
    name: 'English',
    value: 'en-US',
  },
  {
    name: '简体中文',
    value: 'zh-CN',
  },
  {
    name: '한국어',
    value: 'ko-KR'
  },
  {
    name: 'Русский',
    value: 'ru-RU'
  },
]