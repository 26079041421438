import React, { useState, useEffect } from 'react';
import {
    Address,
    ITextR,
    LayoutFrame, NFTInfo,
    OText2, OText3,
    Pool,
    renderTime
} from "../../../../components/common/Layout";
import icon_return from '../../../../static/image/icon-return.svg'
import { usePoolDetail } from "./Hook";
import { useHistory } from "react-router-dom";
import { weiToNumber } from "../../../../utils/numberTransform";
import classNames from "classnames";
import { useParams } from 'react-router-dom';
import { Form, Input } from "../../../../components/common/Form";
import icon_max from "../../../../static/image/icon-max.svg";
import { Button } from "../../../../components/common/Button";
import { useEthBalance } from "../../../../web3/common";
import { getContract, useActivePlatform, useActiveWeb3React } from "../../../../web3";
import englishAuctionNFT from "../../../../web3/abi/bounceEnglishAuctionNFT.json";
import rect_logo from '../../../../static/image/rect-logo.svg'
import { getEnglishAuctionNFTAddress } from "../../../../web3/contractAddress";
import Web3 from 'web3'
import { useIsXSDown } from '../../../../components/utils/themeHooks';
import {
    BidModal,
    initStatus,
    errorStatus,
    successStatus,
    confirmStatus,
    pendingStatus,
    cancelStatus, claimSuccessStatus
} from "../../../../components/common/BidModal";
import { useLeftTime } from "../../../../hooks/useLeftTime";
import { PoolCover } from "../../../../components/DetailCover";
import { Message } from "../../../../components/common/message";
import { isGreaterThan } from "../../../../utils/common";
import { BIDDER_CLAIMED_MESSAGE, CREATOR_CLAIMED_MESSAGE } from "../../../../const";
import { TipLink } from "../../../../components/common/TipLink";

const { toWei, fromWei } = Web3.utils


export const EANFTStoreDetail = () => {
    const { proxy, id } = useParams();
    const { account, library, chainId } = useActiveWeb3React()
    const { setTime, leftTime } = useLeftTime()
    const [bidAmount, setBidAmount] = useState()
    const [bidStatus, setBidStatus] = useState(initStatus)
    const history = useHistory()
    const isXSDown = useIsXSDown();
    const { Psymbol } = useActivePlatform()

    const {
        name, address, password, time, swapped,
        startPrice, status, isMine, claimButtonText, myBid, NFT, joined, amountMinIncr, round, curBiddenAmount, setJoined, storeAdress
    } = usePoolDetail(proxy, id)

    const hasBidder = isGreaterThan(curBiddenAmount, '0')

    const { ethBalance } = useEthBalance()

    let timer = null
    useEffect(() => {
        timer = setInterval(() => {
            console.log('set time', time)
            const date = new Date(time * 1000);
            const now = new Date();
            const lefttime = date - now;
            if (lefttime > 0) {
                setTime(lefttime)
            } else {
                clearInterval(timer)
            }
        }, (1000));
        return () => {
            clearInterval(timer)
        }
    }, [setTime, time]);


    const onBid = async () => {
        const bounceContract = getContract(library, englishAuctionNFT.abi, storeAdress)
        const bidToAmount = toWei(bidAmount, 'ether');
        setBidStatus(confirmStatus);
        console.log('bid:', id, password, bidToAmount, storeAdress)
        try {
            bounceContract.methods.bid(id, bidToAmount, password)
                .send({ from: account, value: bidToAmount })
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setJoined(true)

                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid error', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }


    const onClaim = async () => {
        const bounceContract = getContract(library, englishAuctionNFT.abi, storeAdress)
        const claimFunc = isMine ? 'creatorClaim' : 'bidderClaim'
        setBidStatus(confirmStatus);
        try {
            bounceContract.methods[claimFunc](id)
                .send({ from: account })
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setBidStatus(claimSuccessStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }

    return (
        <LayoutFrame style={{ marginTop: 27 }}>

            {isMine ?
                <>
                    {status === 'Live' && (
                        <Message type={'success'}
                            content={'The auction is still live, please wait patiently until your auction is filled or closed. You can come back to claim your tokens when auction time is out.'} />
                    )}
                    {status === 'Closed' && hasBidder && claimButtonText && (
                        <Message type={'success'}
                            content={'Your auction is fully filled and successfully closed, please manually claim your swapped tokens.'} />
                    )}
                    {status === 'Closed' && !hasBidder && claimButtonText && (
                        <Message type={'error'}
                            content={'Sorry! Your auction is closed, please manually claim your unswapped tokens through the one click button below.'} />
                    )}
                    {status === 'Closed' && !claimButtonText && (
                        <Message type={'success'}
                            content={CREATOR_CLAIMED_MESSAGE} />
                    )}
                </>
                : null}

            {!isMine ?
                <>
                    {status === 'Live' && joined && (
                        <Message type={'success'}
                            content={'You have successfully made a bid. Please waiting patiently until auction is closed. '} />
                    )}
                    {status === 'Closed' && joined && swapped && claimButtonText && (
                        <Message type={'success'}
                            content={'The auction is closed and your bids are fully filled. Please manually claim your swapped tokens.'} />
                    )}
                    {status === 'Closed' && joined && !swapped && claimButtonText && (
                        <Message type={'error'}
                            content={'Sorry! Your bid was bidded out by a higher price bid, your fund is automatically sent back to your wallet. Please make a higher bid.'} />
                    )}

                    {status === 'Closed' && joined && !claimButtonText(
                        <Message
                            content={BIDDER_CLAIMED_MESSAGE} />
                    )}

                    {status === 'Closed' && !joined && (
                        <Message
                            content={'This auction is finished, please visit other live auctions.'} />
                    )}
                </>

                : null}

            <Pool.Return src={icon_return} onClick={() => {
                history.goBack()
            }} />
            <LayoutFrame width={'1181px'} style={{ padding: '40px 0', margin: 'auto', marginTop: 32 }}>
                <Pool.Mode>NFT english auction</Pool.Mode>
                <Pool.Header style={{ justifyContent: 'center' }}><span>{name}</span></Pool.Header>
                <Address style={{ wordBreak: isXSDown ? 'break-all' : 'normal', marginBottom: 40 }}>{address}</Address>
                {!isXSDown &&
                    <Pool.Content>
                        <Pool.Content width={'272px'} style={{ marginTop: 0, height: 'fit-content' }}>

                            <Pool.Content width={'456px'} style={{ marginTop: 0, flexDirection: 'column' }}>
                                <Pool.Status style={{ width: 'fit-content' }}
                                    className={classNames('status', status)}>• {status}</Pool.Status>

                                <ITextR style={{ marginTop: 8, textAlign: 'left' }}>{'Participant: Public'}</ITextR>
                            </Pool.Content>

                            <Pool.Block style={{ height: 100, padding: '14px 0' }}>
                                <span>Starting Price</span>
                                <span>{startPrice && `${weiToNumber(startPrice)} ${Psymbol}`}</span>
                            </Pool.Block>

                            <Pool.Block style={{ height: 100, padding: '14px 0', marginTop: 20 }}>
                                <span>Minimal Bid price increment:</span>
                                <span>{amountMinIncr && weiToNumber(amountMinIncr)} ETH</span>
                            </Pool.Block>

                            <Pool.Block style={{ height: 100, padding: '14px 0', border: 0, marginTop: 20 }}>
                                <span>Current Highest Bid: </span>
                                <span>{curBiddenAmount && weiToNumber(curBiddenAmount)} ETH</span>
                            </Pool.Block>

                        </Pool.Content>

                        <Pool.Content width={'512px'} style={{ marginTop: 0 }}>
                            <PoolCover cover={NFT && (NFT.image ? NFT.image.replace(/ipfs:\/\//, 'https://ipfs.io/') : rect_logo)} />
                        </Pool.Content>

                        <Pool.Content width={'272px'} heigth={'502px'} style={{ marginTop: 0 }}>
                            <NFTInfo>
                                <NFTInfo.Artist>{NFT && NFT.name}</NFTInfo.Artist>
                                <NFTInfo.Title>{NFT && NFT.title}</NFTInfo.Title>
                                <NFTInfo.Desc>{NFT && NFT.description}</NFTInfo.Desc>
                            </NFTInfo>

                            {renderTime(leftTime)}

                        </Pool.Content>

                        <Pool.Content width={'1181px'}
                            style={{
                                flexDirection: 'column',
                                padding: 56,
                                alignItems: 'center',
                                marginTop: 100,

                                backgroundColor: 'rgba(248, 248, 251, 1)'
                            }}>
                            {/* {(!isMine && !joined && status === 'Live') && ( */}
                            {(!isMine && !swapped && status === 'Live') && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>Join The Pool</OText3>
                                    <Form disabled top={'49px'} width={'320px'} input={<Input
                                        placeholder={'Bid Amount'}
                                        type='number'
                                        value={bidAmount}

                                        // onBlur={()=>{
                                        //     if(bidAmount && ethBalance && isGreaterThan(toWei(bidAmount), ethBalance)){
                                        //         setBidAmount(weiToNumber(ethBalance))
                                        //     }
                                        // }}
                                        onChange={(e) => {
                                            setBidAmount(e.target.value.replace(/[^\d.]/g, ''))
                                        }}
                                    />} name={'Your Bid Amount '} addonAfter={(<img onClick={() => {
                                        console.log('set max amount', ethBalance)
                                        setBidAmount(fromWei(ethBalance))
                                    }} src={icon_max} />)}
                                        extra={<span
                                            style={{ top: 0 }}>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>} />
                                    <Button black width={'320px'} onClick={onBid}>Go</Button>
                                    <TipLink />
                                </>
                            )}

                            {(!isMine && swapped) && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>You Joined</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{round && round}</OText2>
                                        </Pool.Meta>

                                        <Pool.MetaDivider />

                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Your bid amount:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{`${myBid ? weiToNumber(myBid) : 0} ${Psymbol}`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>
                                    {claimButtonText && <Button black width={'320px'} onClick={onClaim}>{claimButtonText}</Button>}

                                </>
                            )}

                            {(!isMine && !swapped && joined && status === 'Closed') && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>You Joined</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{round && round}</OText2>
                                        </Pool.Meta>

                                        <Pool.MetaDivider />

                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Your bid amount:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{`${myBid ? weiToNumber(myBid) : 0} ${Psymbol}`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>
                                    {claimButtonText && <Button black width={'320px'} onClick={onClaim}>{claimButtonText}</Button>}

                                </>
                            )}

                            {(!isMine && !joined && status === 'Closed') && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>You didn't Join</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{round && round}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>
                                    {claimButtonText && <Button black width={'320px'} onClick={onClaim}>{claimButtonText}</Button>}

                                </>
                            )}

                            {(isMine) && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>My Pool</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round:</div>
                                            <OText2 style={{ lineHeight: '48px' }}>{round && round}</OText2>
                                        </Pool.Meta>
                                        <Pool.MetaDivider />
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Current Auction Price:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{curBiddenAmount && `${weiToNumber(curBiddenAmount)} ${Psymbol}`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>

                                    {claimButtonText && <Button black width={'320px'} onClick={onClaim}>{claimButtonText}</Button>}
                                </>
                            )}

                        </Pool.Content>
                    </Pool.Content>
                }
                {isXSDown &&
                    <Pool.Content>
                        <Pool.Content width={'100%'} style={{ marginTop: 0, height: 'fit-content' }}>
                            <Pool.Content width={'456px'} style={{ marginTop: 0, flexDirection: 'column', padding: '0 20px' }}>
                                <Pool.Status style={{ width: 'fit-content' }}
                                    className={classNames('status', status)}>• {status}</Pool.Status>

                                <ITextR style={{ marginTop: 8, textAlign: 'left' }}>{'Participant: Public'}</ITextR>
                            </Pool.Content>

                            <Pool.Block style={{
                                width: '100%',
                                alignItems: "flex-start",
                                marginLeft: "20px",
                                marginRight: "20px",
                                height: '100px'
                            }}>
                                <span>Starting Price</span>
                                <span>{startPrice && `${weiToNumber(startPrice)} ${Psymbol}`}</span>
                            </Pool.Block>

                            <Pool.Block style={{
                                width: '100%',
                                alignItems: "flex-start",
                                marginLeft: "20px",
                                marginRight: "20px",
                                height: '100px'
                            }}>
                                <span>Minimal Bid price increment:</span>
                                <span>{amountMinIncr && weiToNumber(amountMinIncr)}</span>
                            </Pool.Block>

                            <Pool.Block style={{
                                width: '100%',
                                alignItems: "flex-start",
                                marginLeft: "20px",
                                marginRight: "20px",
                                height: '100px'
                            }}>
                                <span>Current Highest Bid: </span>
                                <span>{curBiddenAmount && weiToNumber(curBiddenAmount)}</span>
                            </Pool.Block>

                        </Pool.Content>

                        <Pool.Content width={'512px'} style={{ marginTop: 0, padding: '0 20px' }}>
                            <PoolCover cover={NFT && (NFT.image ? NFT.image.replace(/ipfs:\/\//, 'https://ipfs.io/') : rect_logo)} />
                        </Pool.Content>

                        <Pool.Content width={'100%'} heigth={'502px'} style={{ marginTop: 0, padding: '0 20px' }}>
                            <NFTInfo>
                                <NFTInfo.Artist>{NFT && NFT.name}</NFTInfo.Artist>
                                <NFTInfo.Title>{NFT && NFT.title}</NFTInfo.Title>
                                <NFTInfo.Desc>{NFT && NFT.description}</NFTInfo.Desc>
                            </NFTInfo>
                            <div style={{
                                fontSize: '12px',
                                color: '#1F191B',
                                opacity: '0.5',
                                width: '100%',
                                'textAlign': 'left',
                                marginBottom: '-20px'
                            }}>Time left
              </div>
                            {renderTime(leftTime)}

                        </Pool.Content>

                        <Pool.Content width={'1181px'}
                            style={{
                                height: 'fit-content',
                                flexDirection: 'column',
                                padding: '40px 16px',
                                justifyContent: 'center',
                                margin: '40px 20px',
                                backgroundColor: 'rgba(248, 248, 251, 1)'
                            }}>
                            {(!isMine && !joined && status === 'Live') && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>Join The Pool</OText3>
                                    <Form disabled top={'49px'} width={'320px'} input={<Input
                                        placeholder={'Bid Amount'}
                                        type='number'
                                        value={bidAmount}

                                        // onBlur={()=>{
                                        //     if(bidAmount && ethBalance && isGreaterThan(toWei(bidAmount), ethBalance)){
                                        //         setBidAmount(weiToNumber(ethBalance))
                                        //     }
                                        // }}
                                        onChange={(e) => {
                                            setBidAmount(e.target.value.replace(/[^\d.]/g, ''))
                                        }}
                                    />} name={'Your Bid Amount '} addonAfter={(<img onClick={() => {
                                        console.log('set max amount', ethBalance)
                                        setBidAmount(fromWei(ethBalance))
                                    }} src={icon_max} />)}
                                        extra={<span
                                            style={{ top: 0 }}>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>} />
                                    <Button black width={'320px'} onClick={onBid}>Go</Button>
                                </>
                            )}

                            {(!isMine && (joined || status === 'Closed')) && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>You Joined</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{round && round}</OText2>
                                        </Pool.Meta>

                                        <Pool.MetaDivider />

                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Your bid amount:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{`${myBid ? weiToNumber(myBid) : 0} ${Psymbol}`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>
                                    {claimButtonText && <Button black width={'320px'} onClick={onClaim}>{claimButtonText}</Button>}

                                </>
                            )}

                            {(isMine) && (
                                <>
                                    <OText3 style={{ textAlign: 'center' }}>My Pool</OText3>
                                    <Pool.MetaFrame>
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>The current Round:</div>
                                            <OText2 style={{ lineHeight: '48px' }}>{round && round}</OText2>
                                        </Pool.Meta>
                                        <Pool.MetaDivider />
                                        <Pool.Meta style={{ flexDirection: 'column' }}>
                                            <div>Current Auction Price:</div>
                                            <OText2
                                                style={{ lineHeight: '48px' }}>{curBiddenAmount && `${weiToNumber(curBiddenAmount)} ${Psymbol}`}</OText2>
                                        </Pool.Meta>
                                    </Pool.MetaFrame>

                                    {claimButtonText && <Button black width={'320px'} onClick={onClaim}>{claimButtonText}</Button>}
                                </>
                            )}

                        </Pool.Content>
                    </Pool.Content>
                }


            </LayoutFrame>


            {/*{fromBidAmount && fromAmount && (*/}
            {/*    <Progress height={'8px'} className={classNames('progress', status)}>*/}
            {/*        <Progress.Value style={{width: `${getProgress(fromBidAmount, fromAmount)}%`}}*/}
            {/*                        className={classNames('progress-value', status)}/>*/}
            {/*    </Progress>*/}
            {/*)}*/}


            <BidModal modalStatus={bidStatus} onDismiss={() => {
                setBidStatus(initStatus)
            }} />
        </LayoutFrame>
    )
}

