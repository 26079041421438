import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { myContext } from "../../reducer";
import { useActiveWeb3React } from "../../web3";
import { Button } from "../../components/common/Button";
import { HANDLE_SHOW_CONNECT_MODAL } from "../../const";

import { HeaderTabConfig } from './config'


const HeaderFrame = styled.div`
        display: flex;
        width: 1280px;
        height: 94px;
        margin: 0 auto;
        align-items: center;
        @media (max-width: 767px) {
            width: calc(100vw - 58px);
            white-space:nowrap;
            overflow-x:scroll;
            margin:0;
        }
    `

const AuctionMode = styled.span`
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    margin-right: 20px;
    background-color: ${({ active, theme }) => (active ? theme.black : '')};
    color: ${({ active, theme }) => (active ? theme.white : theme.black)};
    cursor: pointer;

    img{
        display: inline-block;
        margin-right: 8px;
    }

    &:hover{
    color: ${({ active, theme }) => (active ? theme.white : theme.black)};
    opacity: ${({ active }) => (active ? 1 : 0.7)};
    };
    @media (max-width: 767px) {
        font-size:14px;
        margin-right:10px;
        padding: 0 16px;
    }
`

const CreateButton = styled(Button)`
    @media (max-width: 767px) {
    display:none;
    }
`

export default function HeaderTab({ setShowSelectModal, setCreateOptions }) {
    const [auctionType, setAuctionType] = useState(window.localStorage.getItem('A_HeaderTabName') || 'Token Sale')
    const history = useHistory()
    const { dispatch } = useContext(myContext);
    const { active, chainId } = useActiveWeb3React()
    const [BSC_Tabbar, setBSC_Tabber] = useState(null)

    useEffect(() => {
        if (!BSC_Tabbar) return
        if (chainId === 56) {
            const target = HeaderTabConfig.filter(item => {
                return item.name === auctionType
            })
            const target_2 = target[0].option.filter(item => {
                return item.isBSC
            })
            setCreateOptions(target_2 || [])
        } else {
            const target = HeaderTabConfig.filter(item => {
                return item.name === auctionType
            })
            setCreateOptions(target[0].option || [])
        }
    }, [auctionType, BSC_Tabbar, chainId])

    useEffect(() => {
        if (!chainId) return
        if (chainId === 56) {
            const temp_BSC_Tabbar = HeaderTabConfig.filter((item) => {
                return item.isBSC
            })
            setBSC_Tabber(temp_BSC_Tabbar)
        } else {
            setBSC_Tabber(HeaderTabConfig)
        }
    }, [chainId])


    return (
        <HeaderFrame>
            {
                BSC_Tabbar && BSC_Tabbar.map((item, index) => {
                    return <AuctionMode
                        key={index}
                        active={auctionType === item.name}  // select status (black icon)
                        onClick={() => {
                            setAuctionType(item.name)
                            window.localStorage.setItem('A_HeaderTabName', item.name)
                        }}>
                        <img src={auctionType === item.name ? item.icon_select : item.icon} alt="" />
                        {item.name}
                    </AuctionMode>
                })
            }

            <CreateButton
                className='write_btn'
                width='160px'
                onClick={() => {
                    if (active) {
                        if (auctionType === 'Predictions') {
                            history.push('/create/prediction')
                        } else {
                            setShowSelectModal(true)
                        }
                    } else {
                        dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true }); // The create mode box pops up
                    }
                }}
                style={{ marginLeft: 'auto', marginRight: 0 }}
                width={'160px'}>
                Create auction
            </CreateButton>
        </HeaderFrame>
    )
}
