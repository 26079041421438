import React, {useState, useEffect, useContext} from 'react';
import {
    Address,
    ITextR,
    LayoutFrame, LineDivider,
    OText2,
    Pool,
    renderTime
} from "../../components/common/Layout";
import icon_return from '../../static/image/icon-return.svg'
import {usePoolDetail} from "./Hooks";
import BigNumber from "bignumber.js";
import {fromWei, numToWei, weiDiv, weiToNum, weiToNumber} from "../../utils/numberTransform";
import classNames from "classnames";
import {useHistory, useParams} from 'react-router-dom';
import {Form, Input} from "../../components/common/Form";
import icon_max from "../../static/image/icon-max.svg";
import {Button} from "../../components/common/Button";
import {useEthBalance, useTokenBalance} from "../../web3/common";
import {getContract, useActivePlatform, useActiveWeb3React} from "../../web3";
import sealedBid from "../../web3/abi/bounceSealedBid.json";
import bounceERC20 from "../../web3/abi/bounceERC20.json";


import {getSealedBidAddress} from "../../web3/contractAddress";
import Web3 from 'web3'
import {
    BidModal,
    initStatus,
    errorStatus,
    successStatus,
    confirmStatus,
    pendingStatus,
    cancelStatus, claimSuccessStatus
} from "../../components/common/BidModal";
import {useLeftTime} from "../../hooks/useLeftTime";
import {isEqualTo, isGreaterThan} from "../../utils/common";
import {myContext} from "../../reducer";
import md5 from "js-md5";
import {PasswordModal} from "../../components/common/PasswordMpdal";
import {useIsXSDown} from '../../components/utils/themeHooks';
import {Message} from "../../components/common/message";
import {BIDDER_CLAIMED_MESSAGE, CREATOR_CLAIMED_MESSAGE} from "../../const";
import {TipLink} from "../../components/common/TipLink";
import {validateForm} from "../../utils/form";
import {AuctionTipModal} from "../../components/common/AuctionTipModal";
import Modal from "../../components/common/Modal";

const {toWei, BN} = Web3.utils

BigNumber.config({EXPONENTIAL_AT: [-40, 40]})


export const SBPoolDetail = () => {
    const history = useHistory()
    const {state} = useContext(myContext);
    const {ethPrice} = state
    const {id} = useParams();
    const {account, library, chainId} = useActiveWeb3React()
    const {setTime, leftTime} = useLeftTime()
    const [bidAmount, setBidAmount] = useState()
    const [bidRatio, setBidRatio] = useState()
    const [bidStatus, setBidStatus] = useState(initStatus)
    const [passwordModal, setPasswordModal] = useState(false)
    const [enterPW, setEnterPW] = useState()
    const [errorPW, setErrorPW] = useState()
    const [showTip, setShowTip] = useState()
    const [errorBidAmount, setErrorBidAmount] = useState()
    const [errors, setErrors] = useState({amount: '', ratio: ''})

    const isXSDown = useIsXSDown();


    const {
        name, address, symbol, decimals, floor, password, time, totalBid, onlyBOT, biddenStatus,
        limit, status, isMine, claimButtonText, myBid, setQueryStatus, toSymbol, toAddress, toDecimals
    } = usePoolDetail(id)
    const {ethBalance} = useEthBalance(toAddress)
    const {balance} = useTokenBalance()

    const handleChange = async event => {
        event.preventDefault();
        const {name, value} = event.target;
        switch (name) {
            case 'amount':
                errors.amount = ''
                const amountValue = value.replace(/[^\d.]/g, '')
                setBidAmount(amountValue)
                console.log('wei amount', numToWei(amountValue, toDecimals))
                if (!ethBalance || (ethBalance && isGreaterThan(numToWei(amountValue, toDecimals), ethBalance))) {
                    errors.amount = 'you do not have enough balance'
                }
                if (limit && isGreaterThan(limit, numToWei(amountValue, toDecimals))) {
                    errors.amount = 'maximum allocation per wallet is ' + weiToNum(limit, toDecimals)
                }
                break
            case 'ratio':
                errors.ratio = ''
                const ratioValue = value
                setBidRatio(ratioValue)
                console.log('ratios:', weiDiv(fromWei(floor.from, decimals), fromWei(floor.to, toDecimals)), ratioValue)
                if (floor.from && floor.to && isGreaterThan(ratioValue, weiDiv(fromWei(floor.from, decimals), fromWei(floor.to, toDecimals)))) {
                    errors.ratio = 'floor swap ratio is ' + weiDiv(fromWei(floor.from, decimals), fromWei(floor.to, toDecimals))
                }
            default:
        }
        console.log('final errors', errors)
        setErrors(errors)
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('handleSubmit', event)
        if (validateForm(errors)) {
            setShowTip(true)
        }
    }

    let timer = null
    useEffect(() => {
        timer = setInterval(() => {
            const date = new Date(time * 1000);
            const now = new Date();
            const lefttime = date - now;
            if (lefttime > 0) {
                setTime(lefttime)
            } else if (0 < lefttime && lefttime < 1000) {
                window.location.reload()
            } else {
                clearInterval(timer)
            }
        }, (1000));
        return () => {
            clearInterval(timer)
        }
    }, [setTime, time]);

    useEffect(() => {
        if (ethBalance && limit) {
            if (new BigNumber(weiToNum(limit)).isGreaterThan(weiToNum(ethBalance, toDecimals))) {
                setErrorBidAmount('You need to have more balance to participate in this auction.')
            }
        }
        if (onlyBOT && isGreaterThan(toWei('0.1'), balance)) {
            setErrorBidAmount('Sorry! You are not qualified as bot holder.')
        }
    }, [ethBalance, limit, onlyBOT, balance])

    useEffect(() => {
        if (password && password !== '0') {
            setPasswordModal(true)
        }
    }, [password])


    const onBid = async () => {
        if (password && password !== '0' && new BN(md5(enterPW)).toString() !== password) {
            setPasswordModal(true)
            return
        }
        if (!bidAmount || !bidRatio) {
            return
        }

        const bounceContract = getContract(library, sealedBid.abi, getSealedBidAddress(chainId))

        const bidFromAmount = numToWei(new BigNumber(bidAmount).multipliedBy(new BigNumber(bidRatio)).toString(), decimals);
        const bidToAmount = numToWei(bidAmount, toDecimals);
        console.log('bid:', bidFromAmount, bidToAmount)

        setBidStatus(confirmStatus);
        try {
            if (toAddress) {
                const tokenContract = getContract(library, bounceERC20.abi, toAddress)
                const allowance = await tokenContract.methods.allowance(account, getSealedBidAddress(chainId)).call()
                console.log('on bid--->', allowance)

                if (isGreaterThan(allowance, '0')) {
                    await tokenContract.methods.approve(
                        getSealedBidAddress(chainId),
                        0,
                    ).send({from: account});
                }

                await tokenContract.methods.approve(
                    getSealedBidAddress(chainId),
                    bidToAmount,
                )
                    .send({from: account});
                setBidStatus(confirmStatus);
            }
            bounceContract.methods.bid(id, bidFromAmount, bidToAmount, password)
                .send({from: account, value: toAddress ? 0 : bidToAmount})
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setQueryStatus(true)
                    setBidStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            console.log('bid error', e)
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }


    const onClaim = async () => {
        const bounceContract = getContract(library, sealedBid.abi, getSealedBidAddress(chainId))
        const claimFunc = isMine ? 'creatorClaim' : 'bidderClaim'
        setBidStatus(confirmStatus);
        try {
            bounceContract.methods[claimFunc](id)
                .send({from: account})
                .on('transactionHash', hash => {
                    setBidStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    console.log('bid fixed swap receipt:', receipt)
                    setQueryStatus(true)
                    setBidStatus(claimSuccessStatus)
                })
                .on('error', (err, receipt) => {
                    setBidStatus(errorStatus)
                })
        } catch (e) {
            if (e.code === 4001) {
                setBidStatus(cancelStatus)
            } else {
                setBidStatus(errorStatus)
            }
        }

    }

    return (
        <LayoutFrame style={{marginTop: 27}}>

            {isMine ?
                <>
                    {status === 'Live' && (
                        <Message
                            type={'success'}
                            content={'The auction is still live, please wait patiently until your auction is filled or closed. You can come back to claim your tokens when auction time is out.'}/>
                    )}
                    {status === 'Closed' && !claimButtonText && (
                        <Message
                            type={'success'}
                            content={CREATOR_CLAIMED_MESSAGE}/>
                    )}
                    <>
                        {claimButtonText ?
                            <>
                                {status === 'Closed' && biddenStatus === 'Filled' && (
                                    <Message
                                        type={'success'}
                                        content={'Your auction is fully filled and successfully closed, please manually claim your swapped tokens.'}/>
                                )}
                                {status === 'Closed' && biddenStatus === 'Partial' && (
                                    <Message
                                        content={'Your auction is partially  filled and  closed, please manually claim your unswapped and swapped tokens through the one click button below.'}/>
                                )}
                                {status === 'Closed' && biddenStatus === 'Unfilled' && (
                                    <Message
                                        type={'error'}
                                        content={'Sorry! Your auction is closed, please manually claim your unswapped tokens through the one click button below.'}/>
                                )}
                            </>
                            : null}
                    </>
                </>
                : null}

            {!isMine ?
                <>
                    {status === 'Live' && myBid && isGreaterThan(myBid.to, '0') && (
                        <Message type={'success'}
                                 content={'You have successfully made a bid. Please waiting patiently until auction is closed. You can check your bid result and claim your swapped and unswapped tokens when the auction is closed.'}/>
                    )}
                    {status === 'Closed' && myBid && isGreaterThan(myBid.to, '0') && !claimButtonText && (
                        <Message type={'success'}
                                 content={BIDDER_CLAIMED_MESSAGE}/>
                    )}
                    <>
                        {claimButtonText ?
                            <>
                                {status === 'Closed' && biddenStatus === 'Filled' && (
                                    <Message
                                        type={'success'}
                                        content={'The auction is closed and your bids are fully filled. Please manually claim your swapped tokens.'}/>
                                )}
                                {status === 'Closed' && biddenStatus === 'Partial' && (
                                    <Message
                                        content={'The auction is closed and your bid is partially filled. Please manually claim your swapped and unswapped tokens.'}/>
                                )}
                                {status === 'Closed' && biddenStatus === 'Unfilled' && (
                                    <Message
                                        content={'The auction is closed and your bid is not filled. Please manually claim your  unswapped tokens back.'}/>
                                )}
                            </>
                            : null}
                    </>
                </>
                : null}

            {!isMine && status === 'Closed' && myBid && isEqualTo(myBid.to, '0') && !claimButtonText && (
                <Message
                    content={'This auction is finished, please visit other live auctions.'}/>
            )}


            <Pool.Return src={icon_return} onClick={() => {
                history.goBack()
            }}/>
            <LayoutFrame width={'1072px'}
                         style={{padding: isXSDown ? '40px 20px' : '0 0 40px', margin: 'auto', marginTop: 32,}}>
                <Pool.Mode>Sealed-Bid</Pool.Mode>
                <Pool.Header><span>{name}</span></Pool.Header>
                <Address style={{wordBreak: isXSDown ? 'break-all' : 'normal'}}>{address}</Address>
                <Pool.Content style={{marginTop: 40}}>
                    <Pool.Content width={isXSDown ? '100%' : '520px'} style={{marginTop: 0}}>

                        <Pool.Content width={isXSDown ? '100%' : '456px'}
                                      style={{marginTop: 0, flexDirection: 'column'}}>
                            <Pool.Status style={{width: 'fit-content'}} className={classNames('status', status)}><i
                                className={status}></i>{status}</Pool.Status>

                            <ITextR style={{
                                marginTop: 8,
                                textAlign: 'left'
                            }}>{`Participant: ${onlyBOT ? 'BOT holder' : 'Public'}`}</ITextR>
                        </Pool.Content>

                        <Pool.Block style={{width: '100%'}}>
                            <span>Floor Swap Ratio</span>
                            <span>{floor && `1 ${toSymbol} = ${weiDiv(fromWei(floor.from, decimals), fromWei(floor.to, toDecimals))} ${symbol}`}</span>
                        </Pool.Block>

                        <Pool.Block style={{width: isXSDown ? '100%' : '200px'}}>
                            <span>Price,$</span>
                            {/* <span>{floor && new BigNumber(0.0000000004 / 1).toString()}</span> */}
                            <span>{floor && Number(new BigNumber(weiDiv(fromWei(floor.to, toDecimals), fromWei(floor.from, decimals))).multipliedBy(toSymbol === 'ETH' ? ethPrice : '1').toFixed(6)).toString()}</span>
                        </Pool.Block>

                        <Pool.Block style={{width: isXSDown ? '100%' : '200px'}}>
                            <span>Total Allocation</span>
                            <span>{(floor && symbol) && `${weiToNum(floor.from, decimals)} ${symbol}`}</span>
                        </Pool.Block>

                        <Pool.Block style={{width: isXSDown ? '100%' : '200px'}}>
                            <span>Minimum Bid amount</span>
                            <span>{limit && (limit === 0 ? 'No limit' : `${weiToNumber(limit, toDecimals)} ${toSymbol}`)}</span>
                        </Pool.Block>
                    </Pool.Content>

                    <Pool.Content width={isXSDown ? '100%' : '432px'}
                                  style={{
                                      flexDirection: 'column',
                                      padding: isXSDown ? '48px 20px' : '48px 56px',
                                      justifyContent: 'center',
                                      marginTop: 0,
                                      backgroundColor: 'rgba(248, 248, 251, 1)'
                                  }}>


                        {isMine || (myBid && isEqualTo(myBid.from, '0') && status === 'Live') ? (
                            <>

                                {(!isMine) && (
                                    <form id="bid-sb-form" onSubmit={handleSubmit}>
                                        <OText2>Join The Pool</OText2>
                                        {renderTime(leftTime)}
                                        <LineDivider style={{marginTop: 0}}/>
                                        <Pool.topInfo>
                                            <span>Swap ratio for Bid</span>
                                        </Pool.topInfo>
                                        <Form
                                            style={{
                                                padding: '8px 0',
                                                color: '#1F191B',
                                                fontSize: 16,
                                                lineHeight: '20px',
                                                fontFamily: 'Helvetica Neue',
                                                fontWeight: "bold",
                                                marginLeft: 8
                                            }}
                                            error={errors.ratio}
                                            top={'0px'}
                                            width={isXSDown ? '100%' : '320px'}
                                            input={<Input
                                                name='ratio'
                                                value={bidRatio}
                                                onChange={handleChange}
                                            />} name={' '} prefix={`1 ${toSymbol} =`} suffix={symbol}/>
                                        <Pool.topInfo>
                                            <span>Your Bid Amount</span>
                                            <span>{`Balance: ${ethBalance ? weiToNumber(ethBalance, toDecimals) : '--'} ${toSymbol}`}</span>
                                        </Pool.topInfo>
                                        <Form
                                            error={errors.amount}
                                            top={'0px'} width={isXSDown ? '100%' : '320px'}
                                            input={<Input
                                                style={{
                                                    padding: '8px 0',
                                                    color: '#1F191B',
                                                    fontSize: 16,
                                                    lineHeight: '20px',
                                                    fontFamily: 'Helvetica Neue',
                                                    fontWeight: "bold"
                                                }}
                                                name='amount'
                                                value={bidAmount}
                                                onChange={handleChange}/>}
                                            name={' '}
                                            addonAfter={(<img src={icon_max}/>)}
                                            //   extra={
                                            //       <span>{`Balance: ${ethBalance ? weiToNumber(ethBalance) : '--'}`}</span>}
                                        />
                                        <Button disabled={!bidAmount || !bidRatio || errors.amount || errors.ratio} black style={{marginTop: 50}}>Go</Button>
                                        <TipLink/>
                                    </form>
                                )}

                                {(isMine) && (
                                    <>
                                        <OText2>My Pool</OText2>

                                        <div style={{marginTop: 9}}>
                                            {renderTime(leftTime)}
                                        </div>
                                        <LineDivider style={{marginTop: 24}}/>

                                        <Pool.Meta>
                                            <div>total bid token:</div>
                                            <div>{totalBid && `${weiToNum(totalBid.from, decimals)} ${symbol}`}</div>
                                        </Pool.Meta>

                                        <Pool.Meta>
                                            <div>total bid eth:</div>
                                            <div>{totalBid && `${weiToNumber(totalBid.to)} ${toSymbol}`}</div>
                                        </Pool.Meta>
                                    </>
                                )}
                                {claimButtonText && <Button black onClick={onClaim}>{claimButtonText}</Button>}
                            </>
                        ) : (
                            <>
                                <OText2>You Joined</OText2>
                                <div style={{marginTop: 9}}>
                                    {renderTime(leftTime)}
                                </div>
                                <LineDivider style={{marginTop: 24}} width={isXSDown ? '100%' : '320px'}/>

                                <Pool.Meta>
                                    <div>swap ratio for Bid:</div>
                                    <div>{myBid && `1 ${toSymbol} = ${weiDiv(fromWei(myBid.from, decimals), fromWei(myBid.to, toDecimals))} ${symbol}`}</div>
                                </Pool.Meta>

                                <Pool.Meta>
                                    <div>Your bid amount:</div>
                                    <div>{myBid && `${weiToNumber(myBid.to, toDecimals)} ${toSymbol}`}</div>
                                </Pool.Meta>

                                {claimButtonText && <Button black onClick={onClaim}>{claimButtonText}</Button>}

                            </>
                        )}
                    </Pool.Content>
                </Pool.Content>


            </LayoutFrame>


            {/*{fromBidAmount && fromAmount && (*/}
            {/*    <Progress height={'8px'} className={classNames('progress', status)}>*/}
            {/*        <Progress.Value style={{width: `${getProgress(fromBidAmount, fromAmount)}%`}}*/}
            {/*                        className={classNames('progress-value', status)}/>*/}
            {/*    </Progress>*/}
            {/*)}*/}


            <BidModal modalStatus={bidStatus} onDismiss={() => {
                setBidStatus(initStatus)
            }}/>


            <PasswordModal error={errorPW} onDismiss={() => {
                setPasswordModal(false)
            }} onChange={(password) => {
                setEnterPW(password)
            }} onConfirm={() => {
                console.log('password', password, new BN(md5(enterPW)).toString())
                if (new BN(md5(enterPW)).toString() === password) {
                    console.log('confirm password')
                    setPasswordModal(false)
                    setErrorPW(null)
                } else {
                    setErrorPW('password is wrong, please enter again')
                }
            }} show={passwordModal}/>

            <Modal
                closeable
                isOpen={showTip}
                onDismiss={() => {
                    setShowTip(false)
                }}
                maxWidth={'450px'}
            >
                <AuctionTipModal type={0} auction={onBid}/>
            </Modal>
        </LayoutFrame>
    )
}
