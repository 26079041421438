import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import alpaca from '../../static/image/alpaca.svg'
import ygift from '../../static/image/ygift.svg'
import { StoreCard } from "./Card";
import icon_search from "../../static/image/icon-search-white.svg";
import { Select, SelectFrame } from "../../components/common/Select";
import { FilterFrame, SearchIcon, SearchInput } from "./DetailHeader";
import { useStoreList } from './Factory/useStoreList'
import { useActiveWeb3React } from '../../web3';

const StoreFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #000;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 64px;

  .create_btn{
    width: 260px;
    height: 48px;
    box-sizing: border-box;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    border: 1px solid rgba(256,256,256,0.4);
    cursor: pointer;
    margin-left: auto;
  }
`

const StoreList = styled.div`
  width: 1280px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  justify-content: space-between;
  @media (max-width: 767px) {
    width: 100%;
  }
`


StoreFrame.SelectFrame = styled.div`
  width: 1280px;
  margin: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const storeList = [
  {
    key: 0,
    name: 'Alpaca City Store',
    cover: alpaca,
    active: true,
    homePage: 'https://alpaca.city/',
    twitter: 'https://twitter.com/CityAlpaca',
    instagram: 'https://instagram.com/CityAlpaca',
    link: '/store/detail/nft-fixed-swap'
  },
  {
    key: 0,
    name: 'yGift Store',
    cover: ygift,
    active: true,
    link: '/store/ygift/detail/nft-fixed-swap',
    twitter: 'https://twitter.com/iearnfinance?s=21'
  },
  // { key: 1, name: 'Coming Soon', cover: null, active: false }
]

export const Store = () => {
  const history = useHistory()
  const { chainId } = useActiveWeb3React()
  // const [isBsc, setIsBsc] = useState()
  const isBsc = chainId === 56 ? true : false

  const { newStoreList } = useStoreList()
  return (
    <StoreFrame>
      <FilterFrame>
        <div className='search_title' style={{ display: "flex", alignItems: "center", color: '#fff' }}>
          <SearchIcon src={icon_search} />
            Search by
          </div>
        <SearchInput
          onChange={(e) => {
          }} placeholder={'Store Name'} />
        {!isBsc && <button className='create_btn' onClick={() => {
          history.push('/create/store')
        }}>Register your own Bounce Store</button>}
        {/* <SelectFrame.Divider />

        <SelectFrame.Divider />
        <Select
          theme={'white'}
          width={'148px'}
          onSelect={item => {

          }}
          extra={'Status:'}
          defaultContent={'types of auctions'}
          defaultValue={{ key: 0, value: 'All' }}
          options={[
            { key: 0, value: 'All' },
            { key: 1, value: 'Live' },
            { key: 2, value: 'Filled' },
            { key: 3, value: 'Closed' },
          ]} />
        <SelectFrame.Divider /> */}

      </FilterFrame>

      <StoreList>
        {storeList.map(item => {
          return <StoreCard store={item} />
        })}

        {!isBsc && newStoreList && newStoreList.map(item => {
          return <StoreCard store={item} />
        })}
      </StoreList>
    </StoreFrame>
  )
}
