import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import {
  Address,
  LayoutFrame, LineDivider,
  OText2, OText3,
  Pool,
  renderTime
} from "../../components/common/Layout";
import icon_return from '../../static/image/icon-return.svg'
import {usePoolDetail} from "./Hooks";
import {fromWei, numToWei, weiDiv, weiToNum} from "../../utils/numberTransform";
import classNames from "classnames";
import {useParams} from 'react-router-dom';
import {Form, Input} from "../../components/common/Form";
import icon_max from "../../static/image/icon-max.svg";
import {Button} from "../../components/common/Button";
import {useTokenBalance} from "../../web3/common";
import {getContract, useActiveWeb3React} from "../../web3";
import BouncePredict from "../../web3/abi/BouncePredict.json";

import {getBotAddress, getBouncePredictionAddress} from "../../web3/contractAddress";
import {useHistory} from 'react-router-dom'
import {
  BidModal,
  initStatus,
  errorStatus,
  successStatus,
  confirmStatus,
  pendingStatus,
  cancelStatus
} from "../../components/common/BidModal";
import {useLeftTime} from "../../hooks/useLeftTime";
import {useIsXSDown} from '../../components/utils/themeHooks';
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import icon_up_black from '../../static/image/icon_up_black.svg'
import icon_down_black from '../../static/image/icon_down_black.svg'
import icon_close from '../../static/image/icon-close.svg'
import BigNumber from "bignumber.js";
import Lottie from "react-lottie";
import bounce_loading from "../../static/bounce-loading.json";
import {isEqualTo, isGreaterThan} from "../../utils/common";
import {Website} from "../store/DetailHeader";
import {AuctionTipModal} from "../../components/common/AuctionTipModal";
import Modal from "../../components/common/Modal";
import {Message} from "../../components/common/message";
import {BIDDER_CLAIMED_MESSAGE} from "../../const";

const VoteButton = styled(Button)`
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0;
  color: #FFF;
  background-color: ${({active}) => (active ? '#000000' : 'rgba(0,0,0,0.4)')};
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  
  img{
     height: 10px;
     margin-right: 4px;
  }
  span{
    
  }
`

const Vote = styled.div`
  width: 454px;
  background-color: rgba(0,0,0,0.04);
  padding: 24px 32px 32px;
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative;
`

Vote.Title = styled.div`
font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 16px;
margin-bottom: 24px;
`

Vote.Close = styled.img`
  width: 16px;
  position: absolute;
  left: auto;
  right: 24px;
  top: 24px;
`

const Progress = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  margin-top: 42px;
`
Progress.Value = styled.div`
  background-color: ${({down}) => (down ? '#D3D3DA' : '#000')};
  color: ${({down}) => (down ? '#000' : '#FFF')};
  width: ${({progress}) => (`${progress}%`)};
  text-align: ${({down}) => (down ? 'right' : 'left')};
  line-height: 32px;
  overflow: hidden;
  overflow: hidden;
`

Progress.Num = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  font-family: IBM Plex Mono;
font-style: normal;
font-weight: normal;
font-size: 12px;
color: rgba(31,25,27,0.5);
`
const Tip = styled.div`
font-family: IBM Plex Mono;
font-style: normal;
font-weight: 500;
font-size: 13px;
width: 242px;
margin: auto;
`

const Result = styled.div`
  width: 320px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  font-family: IBM Plex Mono;
font-style: normal;
font-weight: normal;
font-size: 13px;
color: #1F191B;
`

Result.Item = styled.div`
  width: 144px;
  height: 32px;
  line-height: 32px;
  background-color: rgba(211,211,218,0.6);
`

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: bounce_loading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const PredictionDetail = () => {
  const history = useHistory()
  const {id} = useParams();
  const {active, account, library, chainId} = useActiveWeb3React()
  const {balance} = useTokenBalance(getBotAddress(chainId))
  const {setTime, leftTime} = useLeftTime()
  const [bidAmount, setBidAmount] = useState()
  const [bidStatus, setBidStatus] = useState(initStatus)
  const [votingUp, setVotingUp] = useState(false)
  const [votingDown, setVotingDown] = useState(false)

  const [voteUpAmount, setVoteUpAmount] = useState()
  const [voteDownAmount, setVoteDownAmount] = useState()
  const [showTip, setShowTip] = useState(false)
  const [showUpTip, setShowUpTip] = useState(false)
  const [showDownTip, setShowDownTip] = useState(false)


  const isXSDown = useIsXSDown();

  const {
    address, time, pair, decimals, resultStatus,
    pool, totalVoteUp, totalVoteDown, myVoteUp, myVoteDown, status, endPrice, curPrice, result, bonus, origin, claimed
  } = usePoolDetail(id)

  let timer = null
  useEffect(() => {
    if (pool) {
      timer = setInterval(() => {
        const date = new Date(pool.closeAt * 1000);
        const now = new Date();
        const lefttime = date - now;
        if (lefttime >= 0) {
          setTime(lefttime)
        }else if(0<lefttime && lefttime<1000){
          window.location.reload()
        } else {
          clearInterval(timer)
        }
      }, (1000));
    }
    return () => {
      clearInterval(timer)
    }
  }, [setTime, time, pool]);

  useEffect(() => {
    if (resultStatus === '0') {
      setShowTip(true)
    }
  }, [resultStatus])


  const onClaim = async () => {
    const bounceContract = getContract(library, BouncePredict.abi, getBouncePredictionAddress(chainId))
    setBidStatus(confirmStatus);
    try {
      bounceContract.methods.claim(id)
          .send({from: account})
          .on('transactionHash', hash => {
            setBidStatus(pendingStatus)
          })
          .on('receipt', (_, receipt) => {
            console.log('bid fixed swap receipt:', receipt)
            setBidStatus(successStatus)
          })
          .on('error', (err, receipt) => {
            setBidStatus(errorStatus)
          })
    } catch (e) {
      if (e.code === 4001) {
        setBidStatus(cancelStatus)
      } else {
        setBidStatus(errorStatus)
      }
    }

  }

  const onVote = async (action) => {
    const bounceContract = getContract(library, BouncePredict.abi, getBouncePredictionAddress(chainId))
    const botContract = getContract(library, bounceERC20.abi, getBotAddress(chainId))
    setShowUpTip(false)
    setShowUpTip(false)
    const func = action === 'up' ? 'voteUp' : 'voteDown'
    const voteAmount = (action === 'up') ? numToWei(voteUpAmount) : numToWei(voteDownAmount)
    console.log('onvote', voteAmount, action)

    setBidStatus(confirmStatus);
    try {
      await botContract.methods.approve(
          getBouncePredictionAddress(chainId),
          voteAmount,
      )
          .send({from: account});
      setBidStatus(confirmStatus);
      bounceContract.methods[func](id, voteAmount)
          .send({from: account})
          .on('transactionHash', hash => {
            setBidStatus(pendingStatus)
          })
          .on('receipt', (_, receipt) => {
            console.log('bid fixed swap receipt:', receipt)
            setBidStatus(successStatus)
          })
          .on('error', (err, receipt) => {
            setBidStatus(errorStatus)
          })
    } catch (e) {
      console.log('vote error', e)
      if (e.code === 4001) {
        setBidStatus(cancelStatus)
      } else {
        setBidStatus(errorStatus)
      }
    }

  }


  return (
      <LayoutFrame style={{
        marginTop: 27,
        paddingBottom: 56,
        paddingLeft: isXSDown ? '20px' : '0',
        paddingRight: isXSDown ? '20px' : '0'
      }}>

        {status === 'Live' && isEqualTo(myVoteUp, 0) && isEqualTo(myVoteDown, 0) && (
            <Message
                linkTitle={'read more'}
                link={'https://docs.bounce.finance/bounce-prediction-market/prediction-market-mechanism'}
                content={'Welcome to Bounce prediction! You can enter your bids within the prediction time. The early you bid, the more weight of your bid in the reward sharing mechanism.'}/>
        )}

        {status === 'Live' && (isGreaterThan(myVoteUp, 0) || isGreaterThan(myVoteDown, 0)) && (
            <Message
                content={'You have successfully made the bids. Please come back to check result when the prediction pool is closed.'}/>
        )}

        {status === 'Closed' && (((result === 'UP' && isGreaterThan(myVoteUp, '0')) || (result === 'DOWN' && isGreaterThan(myVoteDown, '0')))) && !claimed && (
            <Message
                linkTitle={'Chainlink.'}
                link={'https://feeds.chain.link/'}
                content={'This prediction pool is closed and you can now check results and claim your tokens. The results are queried from '}/>
        )}

        {status === 'Closed' && (((result === 'UP' && isGreaterThan(myVoteUp, '0')) || (result === 'DOWN' && isGreaterThan(myVoteDown, '0')))) && claimed && (
            <Message
                type={'success'}
                content={BIDDER_CLAIMED_MESSAGE}/>
        )}



        <Pool.Return onClick={() => {
          history.goBack()
        }} src={icon_return}/>
        <LayoutFrame width={'1072px'} style={{padding: '32px 0', margin: 'auto', marginTop: 0}}>
          <Pool.Mode>Predictions</Pool.Mode>
          <Pool.Header><span>{pool && pool.name}</span></Pool.Header>
          <Address style={{wordBreak: isXSDown ? 'break-all' : 'normal'}}>{address}</Address>
          <Pool.Content style={{marginTop: 40}}>

            <Pool.Content width={isXSDown ? '100%' : '456px'} style={{marginTop: 0}}>

              <Pool.Content width={isXSDown ? '100%' : '456px'} style={{marginTop: 0, flexDirection: 'column'}}>
                <Pool.Status style={{width: 'fit-content'}} className={classNames('status', status)}><i
                    className={status}/>{status}</Pool.Status>
                {renderTime(leftTime)}
              </Pool.Content>

              <Pool.Block style={{width: '100%'}}>
                <span>Asset</span>
                <span>{pair && pair}</span>
              </Pool.Block>

              <Pool.Block style={{width: '100%'}}>
                <span>Oracle Price</span>
                <span>{pool && `${weiToNum(pool.startPrice, decimals, -1)} USD`}</span>
              </Pool.Block>

              {myVoteUp != 0 && (
                  <Pool.Block style={{width: '100%'}}>
                    <span>Your Call Bid</span>
                    <span>{weiToNum(myVoteUp)} BOT</span>
                  </Pool.Block>
              )}

              {myVoteDown != 0 && (
                  <Pool.Block style={{width: '100%'}}>
                    <span>Your Put Bid</span>
                    <span>{weiToNum(myVoteDown)} BOT</span>
                  </Pool.Block>
              )}


              <Pool.Block style={{width: '100%'}}>
                <span>Total Call</span>
                <span>{weiToNum(totalVoteUp)} BOT</span>

                <VoteButton active={status === 'Live'} disabled={status !== 'Live'} onClick={() => {
                  setVotingUp(true)
                }}><img src={icon_up_black}/> Call</VoteButton>

                {votingUp && (
                    <Vote>
                      <Vote.Title>The amount you want to call</Vote.Title>

                      <Form top={'0px'} width={'390px'} input={<Input
                          style={{
                            padding: '8px 0',
                            color: '#1F191B',
                            fontSize: 16,
                            lineHeight: '20px',
                            fontFamily: 'Helvetica Neue',
                            fontWeight: "bold"
                          }}
                          placeholder={'Bid Amount'}
                          type='number'
                          value={voteUpAmount}
                          onBlur={() => {
                            if (voteUpAmount && balance && isGreaterThan(numToWei(voteUpAmount, '18'), balance)) {
                              setVoteUpAmount(weiToNum(balance, 18, 18))
                            }
                          }}
                          onChange={(e) => {
                            setVoteUpAmount(e.target.value.replace(/[^\d.]/g, ''))
                          }}
                      />} name={' '} addonAfter={(<img onClick={() => {
                        console.log('set max amount', balance)
                        setVoteUpAmount(fromWei(balance))
                      }} src={icon_max}/>)}/>
                      <Button onClick={() => {
                        setShowUpTip(true)
                      }} style={{height: 40, marginTop: 38}} black>Submit</Button>

                      <Vote.Close onClick={() => {
                        setVotingUp(false)
                      }} src={icon_close}/>
                    </Vote>
                )}

              </Pool.Block>

              <Pool.Block style={{width: '100%'}}>
                <span>Total Put</span>
                <span>{weiToNum(totalVoteDown)} BOT</span>

                <VoteButton active={status === 'Live'} disabled={status !== 'Live'} down onClick={() => {
                  setVotingDown(true)
                }}><img src={icon_down_black}/>Put</VoteButton>

                {votingDown && (
                    <Vote>
                      <Vote.Title>The amount you want to put</Vote.Title>

                      <Form top={'0px'} width={'390px'} input={<Input
                          style={{
                            padding: '8px 0',
                            color: '#1F191B',
                            fontSize: 16,
                            lineHeight: '20px',
                            fontFamily: 'Helvetica Neue',
                            fontWeight: "bold"
                          }}
                          placeholder={'Bid Amount'}
                          type='number'
                          value={voteDownAmount}
                          onBlur={() => {
                            if (voteDownAmount && balance && isGreaterThan(numToWei(voteDownAmount, '18'), balance)) {
                              setVoteDownAmount(weiToNum(balance, 18, 18))
                            }
                          }}
                          onChange={(e) => {
                            setVoteDownAmount(e.target.value.replace(/[^\d.]/g, ''))
                          }}
                      />} name={' '} addonAfter={(<img onClick={() => {
                        console.log('set max amount', balance)
                        setVoteDownAmount(fromWei(balance))
                      }} src={icon_max}/>)}/>
                      <Button onClick={() => {
                        setShowDownTip(true)
                      }} style={{height: 40, marginTop: 38}} black>Submit</Button>

                      <Vote.Close onClick={() => {
                        setVotingDown(false)
                      }} src={icon_close}/>
                    </Vote>
                )}

              </Pool.Block>

            </Pool.Content>

            <Pool.Content width={'auto'}
                          style={{
                            height: 'fit-content',
                            width: isXSDown ? '100%' : '432px',
                            flexDirection: 'column',
                            padding: isXSDown ? '48px 20px' : '48px 56px',
                            justifyContent: 'center',
                            marginTop: 0,
                            backgroundColor: 'rgba(248, 248, 251, 1)'
                          }}>

              {status === 'Live' && (
                  <>
                    <Tip style={{textAlign: 'center', marginTop: 8}}>Current Price</Tip>

                    <OText2 style={{marginTop: 12}}>{weiToNum(curPrice, decimals, -1)}USD</OText2>

                    <Website target='_blank' href='https://feeds.chain.link/'>（Visit Chainlink price feed)</Website>

                    <Progress>
                      <Progress.Value
                          progress={(weiDiv(totalVoteUp, new BigNumber(totalVoteUp).plus(totalVoteDown).toString())) * 100}>
                        {` ${totalVoteUp == 0 ? 0 : (weiDiv(totalVoteUp, new BigNumber(totalVoteUp).plus(totalVoteDown).toString(), 2)) * 100}%`}
                      </Progress.Value>
                      <Progress.Value
                          down
                          progress={(weiDiv(totalVoteDown, new BigNumber(totalVoteUp).plus(totalVoteDown).toString())) * 100}>
                        {`${totalVoteDown == 0 ? 0 : (weiDiv(totalVoteDown, new BigNumber(totalVoteUp).plus(totalVoteDown).toString(), 2)) * 100}% `}
                      </Progress.Value>
                    </Progress>

                    <Progress.Num><span>{'of users call'}</span> <span>{'of users put'}</span></Progress.Num>
                  </>
              )}

              {status === 'Settlement' && (
                  <>
                    <Lottie width={200} height={200} options={defaultOptions}/>
                    <Tip>please wait while we query
                      the most accurate Final price</Tip>
                  </>
              )}

              {status === 'Closed' && (
                  <>
                    {isEqualTo(myVoteUp, '0') && isEqualTo(myVoteDown, 0) ? (
                        <>
                          <OText3 style={{textAlign: 'center', marginTop: 0, fontSize: 26}}>
                            {'You didn’t join'}
                          </OText3>

                          <LineDivider style={{marginTop: 20}}/>

                          <Tip style={{width: '100%', marginTop: 60}}>
                            Please visit other live pools to make your bids
                          </Tip>
                        </>
                    ) : (
                        <>
                          <OText3 style={{textAlign: 'center', marginTop: 0, fontSize: 26}}>
                            {((result === 'UP' && isGreaterThan(myVoteUp, '0')) || (result === 'DOWN' && isGreaterThan(myVoteDown, '0'))) ? ' Congratulations!' : 'Sorry!'}
                          </OText3>

                          <LineDivider style={{marginTop: 20}}/>

                          <Tip style={{width: '100%', marginTop: 60}}>
                            {((result === 'UP' && isGreaterThan(myVoteUp, '0')) || (result === 'DOWN' && isGreaterThan(myVoteDown, '0'))) ? 'Your Call Bid Won! Final Oracle Price is' : 'Your Call Bid Lost! Final Oracle Price is'}
                          </Tip>

                          <OText2 style={{marginTop: 12}}>{weiToNum(endPrice, decimals, -1)} USD</OText2>

                          <Result>
                            {result === 'UP' && isGreaterThan(myVoteUp, '0') &&
                            <Result.Item>you won {weiToNum(bonus)} BOT</Result.Item>}
                            {result === 'DOWN' && isGreaterThan(myVoteDown, '0') &&
                            <Result.Item>you won {weiToNum(bonus)} BOT</Result.Item>}
                            {result === 'UP' && isGreaterThan(myVoteDown, '0') &&
                            <Result.Item>you lost {weiToNum(myVoteDown)} BOT</Result.Item>}
                            {result === 'DOWN' && isGreaterThan(myVoteUp, '0') &&
                            <Result.Item>you lost {weiToNum(myVoteUp)} BOT</Result.Item>}
                          </Result>


                          {((result === 'UP' && isGreaterThan(myVoteUp, '0')) || (result === 'DOWN' && isGreaterThan(myVoteDown, '0'))) && !claimed && (
                              <Button onClick={() => {
                                if (((result === 'UP' && isGreaterThan(myVoteUp, '0')) || (result === 'DOWN' && isGreaterThan(myVoteDown, '0')))) {
                                  onClaim()
                                } else {
                                  return
                                }
                              }} style={{marginTop: 43}} black>
                                {((result === 'UP' && isGreaterThan(myVoteUp, '0')) || (result === 'DOWN' && isGreaterThan(myVoteDown, '0'))) ? ' Claim Your Principle and Rewards' : 'Make Other Prediction'}
                              </Button>
                          )}
                        </>
                    )}

                  </>
              )}

            </Pool.Content>

          </Pool.Content>


        </LayoutFrame>


        <BidModal modalStatus={bidStatus} onDismiss={() => {
          setBidStatus(initStatus)
        }}/>

        <Modal
            closeable
            isOpen={showTip && status === 'Closed' && ((result === 'UP' && isGreaterThan(myVoteUp, '0')) || (result === 'DOWN' && isGreaterThan(myVoteDown, '0'))) && !claimed}
            onDismiss={() => {
              setShowTip(false)
            }}
            maxWidth={'450px'}
        >
          <AuctionTipModal type={2} auction={onClaim}/>
        </Modal>

        <Modal
            closeable
            isOpen={showUpTip}
            onDismiss={() => {
              setShowTip(false)
            }}
            maxWidth={'450px'}
        >
          <AuctionTipModal type={3} auction={() => {
            onVote('up')
          }}/>
        </Modal>

        <Modal
            closeable
            isOpen={showDownTip}
            onDismiss={() => {
              setShowTip(false)
            }}
            maxWidth={'450px'}
        >
          <AuctionTipModal type={3} auction={() => {
            onVote('down')
          }}/>
        </Modal>

      </LayoutFrame>
  )
}
