import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useActiveWeb3React, getContract } from "../web3";
import bounceERC1155 from '../web3/abi/bounceERC1155.json'
import bounceERC721 from '../web3/abi/bounceERC721.json'

import rect_logo from "../static/image/rect-logo.svg";

export const keyMap = [{
    key: 'creatorP',
    name: 'creator',
    needTrans: false,
}, {
    key: 'nameP',
    name: 'name',
    needTrans: false,
}, {
    key: 'token0P',
    name: 'fromAddress',
    needTrans: false,
}, {
    key: 'passwordP',
    name: 'password',
    needTrans: false,
}, {
    key: 'amountTotal0P',
    name: 'fromAmount',
    needTrans: false,
}, {
    key: 'amountMin1P',
    name: 'toAmount',
    needTrans: true,
}, {
    key: 'closeAtP',
    name: 'time',
}, {
    key: 'onlyBotHolder',
    name: 'onlyBot',
    needTrans: false
}, {
    key: 'minEthBidP',
    name: 'minEthPerWallet',
    needTrans: true
}];




export const useMyNFTList = () => {
    const [nftList, setNftList] = useState([])
    const { active, chainId, account, library } = useActiveWeb3React()
    const [modalType, setModalType] = useState('loading')
    const [isOpenPop, setIsOpenPop] = useState(true)
    const [contractAdress, setContractAdress] = useState(null)

    useEffect(() => {
        if (!active) return
        request_all_721()
    }, [active, chainId, account])

    useEffect(() => {
        if (!active || !contractAdress) return

        if (checkAdress(contractAdress)) {
            setModalType('loading')
            request_1155(contractAdress)
        } else {
            setModalType('oops')
        }
    }, [active, contractAdress])

    const checkAdress = (adress) => {
        const reg = /^0x[0-9|A-z]{40}/
        if (reg.test(adress)) {
            return true
        } else {
            return false
        }
    }
    const queryNftType = async (contract_addr) => {

        const contract = getContract(library, bounceERC1155.abi, contract_addr)
        console.log('queryNftType', contract)
        let type = null
        try {
            let res = await contract.methods.supportsInterface('0xd9b67a26').call()
            if (res) {
                type = '1155'
            } else {
                res = await contract.methods.supportsInterface('0x80ac58cd').call()
                if (res) {
                    type = '721'
                }
            }
        } catch (error) {
            console.log('queryNftType', error)
        }
        console.log('queryNftType', type)
        return type
    }

    const request_1155 = async (contract_addr) => {

        const nftType = await queryNftType(contract_addr)
        if (!nftType) return setModalType('oops')
        try {
            const res = await axios.get('https://nftview.bounce.finance/erc' + nftType, {
                params: {
                    // "user_addr": '0xBCcC2073ADfC46421308f62cfD9868dF00D339a8',  // account
                    "user_addr": account,  // account
                    // "contract_addr": '0x7f15017506978517Db9eb0Abd39d12D86B2Af395',
                    "contract_addr": contract_addr,
                    "chain_id": chainId
                }
            })

            if (res.data.codeStatus === 200) {
                disposeData(res.data.data.tokens, nftType)
                setModalType('success')
            } else {
                setModalType('oops')
            }
        } catch (error) {
            console.log('useMyNFTList', error)
            setModalType('oops')
        }
        // const res = await axios.get('https://nftview.bounce.finance/erc1155?user_addr=0xBCcC2073ADfC46421308f62cfD9868dF00D339a8&contract_addr=0x7f15017506978517Db9eb0Abd39d12D86B2Af395&chain_id=4')

        return setNftList([])
    }

    const request_all_721 = async () => {
        try {
            const res = await axios.get('https://nftview.bounce.finance/nft', {
                params: {
                    "address": account,
                    "chain_id": chainId
                }
            })

            if (res.data.codeStatus === 200) {
                setModalType('')
                disposeData(res.data.data.nfts, '721')
            } else if (res.data.codeStatus === 10006) {
                setModalType('')
            } else {
                setModalType('oops')
            }
        } catch (error) {
            console.log('useMyNFTList', error)
            setModalType('oops')
        }

        return setNftList(nftList.concat([]))
    }

    const disposeData = async (data, nftType) => {
        if (!data && data.length === 0) return []

        console.log('useMyNFTList', data)
        const data_arr = []
        Promise.all(data.map(async (item, index) => {
            const image = await queryNFT(item.contract_addr, item.token_id, nftType)
            data_arr.push({
                cover: image || rect_logo,
                adress: item.contract_addr,
                tokenId: item.token_id,
                contract_addr: item.contract_addr
            })
        })).then(res => {
            console.log('useMyNFTList', data_arr)
            return setNftList(nftList.concat(data_arr))
        })
    }

    const queryNFT = async (address, nftID, nftType) => {
        try {

            let tokenUrl = ''
            let contract = null
            if (nftType === '721') {
                contract = getContract(library, bounceERC721.abi, address, nftType)
                tokenUrl = await contract.methods.tokenURI(nftID).call()
            } else {
                contract = getContract(library, bounceERC1155.abi, address, nftType)
                tokenUrl = await contract.methods.uri(nftID).call()
            }
            const res = await axios(tokenUrl, { mode: 'cors' })
            const result = res.data;

            if (result.image) {
                return result.image
            } else if (result.properties.image.description) {
                return result.properties.image.description
            }

        } catch (e) {
            // console.log('useMyNFTList', e)
            // setNFT(null)
            return null
        }
    }

    const deWeightList = (list) => {
        let obj = {}
        list.forEach((item) => {
            obj[item.contract_addr + item.tokenId] = item
        })

        let new_data_arr = []
        for (const key in obj) {
            new_data_arr.push(obj[key])
        }
        return new_data_arr
    }



    return {
        nftList: deWeightList(nftList),
        modalType, setModalType,
        isOpenPop, setIsOpenPop,
        contractAdress, setContractAdress
    }
}


