import React, { useState, useCallback, useEffect, useContext } from 'react';
import styled from 'styled-components'
import { myContext } from '../../reducer'
import logo from '../../static/image/logo.svg'
import icon_eth_logo from '../../static/image/icon-eth-logo.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import icon_copy from "../../static/image/icon-copy.svg";
import { formatAddress } from '../../utils/common'
import { useHistory } from 'react-router-dom'
import {
  HANDLE_SHOW_CONNECT_MODAL,
  HANDLE_SHOW_MENU_MODAL,
  HANDLE_SHOW_USER_DETAIL,
} from "../../const";
import { useTokenBalance } from "../../web3/common";
import { weiToNumber } from "../../utils/numberTransform";
import arrow_down from '../../static/image/icon-arrow-down.svg';
import arrow_up from '../../static/image/icon-arrow-up.svg';
import icon_eth from '../../static/image/icon-eth-logo.svg';
import icon_checked from '../../static/image/icon-checked.svg';
import icon_bnb from '../../static/image/icon-bnb.svg';
import icon_menu from '../../static/image/icon-menu.svg'
import { useActiveWeb3React } from "../../web3";
import { Button } from "../../components/common/Button";
import { rgb } from 'polished';
import { MenuModal } from '../../components/common/MenuModal';
import { UserDetailModal } from '../../components/common/UserDetailModal';

import { useIsXSDown } from '../../components/utils/themeHooks';
import { Message } from "../../components/common/message";
import Modal, { ModalButtonFrame, ModalContent, ModalTitle } from "../../components/common/Modal";
import welcome_step_1 from '../../static/image/welcome_1.png'
import welcome_step_2 from '../../static/image/welcome_2.png'
import welcome_step_3 from '../../static/image/welcome_3.png'
import welcome_step_4 from '../../static/image/welcome_4.png'
import welcome_step_5 from '../../static/image/welcome_5.png'



const HeaderFrame = styled.div`
  display: flex;
  grid-template-columns: 1fr 120px;
  align-items: center;
  flex-direction: row;
  width: 1280px;
  position: relative;
  padding: 1rem 0;
  z-index: 2;
  background-color: #fff;
  margin: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const Arrow = styled.img`
  width: 12px;
  margin-left: 40px;
  cursor: pointer;
  margin-right: 16px;
  @media (max-width: 767px) {
    display: none;
  }
`

const Logo = styled.img`
  width: 18px;
  cursor: pointer;
  @media (max-width: 767px) {
    margin-left: 22px;
  }
`

const LogoText = styled.span`
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-left: 18px;
  margin-right: 10px;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
`

const Breadcrumb = styled.a`
  font-family: 'Optima';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 45px;
  color: ${({ theme }) => theme.black};
  opacity: ${({ active }) => active ? 1 : 0.2};
  cursor: pointer;
  &:hover{
    color: ${({ active, theme }) => active ? theme.black : rgb(101, 101, 101)};;
    opacity: 1;
  };
  @media (max-width: 767px) {
    display: none;
  }
`

const BotBalance = styled.span`
  font-family: 'Optima';
  font-style: normal;
  font-weight: bold;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 16px;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #000;
  @media (max-width: 767px) {
    display: none;
  }
`

const ETHLogo = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 18px;
`

// const Line = styled.div`
//   height: 40px;
//   border: 1px solid #000000;
//   box-sizing: border-box;
//   margin: auto 20px;
// `

const Wallet = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    display: none;
  }
`

Wallet.Chain = styled.span`
  font-family: Optima;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`

Wallet.Address = styled.span`
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;

  .tip_box{
    display: none;
    position: absolute;
    width: 118px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-family: 'Helvetica Neue';
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background-color: #000;
    z-index: 2;
    top: -12px;
    right: 25px;
  }
`

Wallet.CopyIcon = styled.img`
  width: 12px;
  margin-left: 7px;
  z-index: 1;
  opacity: 0.2;
  &:hover{
    opacity: 1;
  }
`

Wallet.Status = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
`
const UserInfo = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  @media (max-width: 767px) {
    border-right: 1px solid #000000;
  };
`
const DownBox = styled.div`
  width: 265px;
  height: 130px;
  background-color: #fff;
  border: 1px solid #EAEAEA;
  position: absolute;
  top: 66px;
  left: -24px;
  box-sizing: border-box;
  box-shadow: 0 0 20px #ccc;
`
const UnderCross = styled.div`
  border: 1px solid #000000;
`
const ViewNFTs = styled.div`
  text-align: center;
  font-size: 15px;
  margin: 16px 0;
`

const MenuBox = styled.span`
  width: 32px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left:20px;
  margin-right:20px;
`

const MENUIcon = styled.img`
  width: 32px;
  height: 16px;
  cursor: pointer;
`

const BoxItem = styled.div`
  width: 100%;
  height: 65px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  &:hover{
    background: #000;
    color: #fff;
    *:nth-child(1){
      color: #fff;
    }
    *:nth-child(2){
      color: #fff;
    }
  };
  
`

BoxItem.Check = styled.img`
  width: 10px;
`

BoxItem.Icon = styled.img`
  width: 24px;
  margin-left: 12px;
`

BoxItem.Meta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
  *:nth-child(1){
    font-family: Optima;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  
  *:nth-child(2){
    font-family: Optima;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.3);
    margin-top: 4px;
  }
`
const ConnectButton = styled(Button)`
  height: 48px;
  width: 160px;
  margin-left: 20px;
  @media (max-width: 767px) {
    height: 40px;
    width: 136px;
    font-size: 14px;
  }
`

const steps = [
  {
    step: 0,
    title: 'Welcome to Bounce Finance',
    button: 'Show Tutorial',
    content: 'Bounce is a decentralized auction platform, incorporating liquidity mining, decentralized governance and staking mechanisms.\t The first principle of Bounce is scarcity of resources, which creates a competitive swap environment.'
  }, {
    step: 1,
    button: 'Next Step 1/5',
    content: 'First, you can navigate to sections based on your needs here',
    img: welcome_step_1
  },
  {
    step: 2,
    button: 'Next Step 2/5',
    content: 'Second, select the type of application you want to access',
    img: welcome_step_2
  },
  {
    step: 3,
    button: 'Next Step 3/5',
    content: 'Third, You can select the auction type, filter and search for auctions here',
    img: welcome_step_3
  },
  {
    step: 4,
    button: 'Next Step 4/5',
    content: 'You can click here to create your own auction with “Create Auction” button',
    img: welcome_step_4
  },
  {
    step: 5,
    button: 'Begin',
    content: 'Finally, you can click to join specific auction if you hover your mouse over the card',
    img: welcome_step_5
  }
]

export const Header = () => {
  const history = useHistory()
  const path = history.location.pathname

  const { state, dispatch } = useContext(myContext);
  const { web3Context, firstTip } = state;

  const [dropDown, setDropDown] = useState(false)
  const [isETH] = useState(true)


  const { balance } = useTokenBalance()

  const [crumb, setCrumb] = useState()
  const isXSDown = useIsXSDown();
  const [isCopyTip, setIsCopyTip] = useState(false)
  const [showMainTip, setShowMainTip] = useState(false)
  const [showWelcomeModal, setShowWelcomeModal] = useState(false)
  const [curStep, setCurStep] = useState(0)


  useEffect(() => {
    const data = window.localStorage.getItem('BOUNCE_MAIN_TIP')
    const welcomeModal = window.localStorage.getItem('BOUNCE_WELCOME_MODAL')
    if (!data) {
      setShowMainTip(true)
    }
    if (!welcomeModal) {
      setShowWelcomeModal(true)
    }
  }, []);


  useEffect(() => {
    if (web3Context.account) {
    }
  }, [web3Context.account]);

  useEffect(() => {
    let timeOut = null
    if (isCopyTip) {
      timeOut = setTimeout(() => {
        setIsCopyTip(false)
      }, 2000)
    }

    return () => {
      clearTimeout(timeOut)
    }
  }, [isCopyTip]);


  const { active, account, chainId } = useActiveWeb3React();

  const isBinance = chainId === 56 || chainId === 97

  const onSkip = () => {
    window.localStorage.setItem('BOUNCE_WELCOME_MODAL', 'showed')
    setShowWelcomeModal(false)
  }
  const isPoolList = (path) => {
    const index = path.indexOf('/application')
    if (index === -1) return false
    return true
  }

  return (
    <div style={{ width: '100vw', background: '#fff' }}>
      {showMainTip && (
        <Message
          onClose={() => {
            window.localStorage.setItem('BOUNCE_MAIN_TIP', 'showed')
            setShowMainTip(false)
          }}
          warning
          title={'Notice: '}
          content={' Bounce is a decentralized and open platform. Kindly be aware that auctions created in Bounce can be scams or rug pulled at any moment and may result in a loss of participants fund. Please exercise caution when trading! '} />
      )}

      <HeaderFrame>
        <Logo src={logo} onClick={() => {
          history.push('/')
        }} />
        <LogoText onClick={() => {
          history.push('/')
        }}>Bounce</LogoText>
        <Breadcrumb active={isPoolList(path) || path === '' || crumb === 0} onClick={() => {
          setCrumb(0)
          history.push('/')
        }}>/Application</Breadcrumb>

        {/* <Breadcrumb active={path === '/store' || crumb === 10} onClick={() => {
          setCrumb(10)
          history.push('/store')
        }}>/ Store</Breadcrumb>

        {(!isBinance) && (
          <Breadcrumb active={path === '/earning' || crumb === 1} onClick={() => {
            setCrumb(1)
            history.push('/earning')
          }}>/Earning</Breadcrumb>
        )}

        {(!isBinance) && (
          <Breadcrumb active={path === '/statistic' || crumb === 3} onClick={() => {
            setCrumb(3)
            history.push('/statistic')
          }}>/Statistics</Breadcrumb>
        )}

        <Breadcrumb active={path === '/governance' || crumb === 2} onClick={() => {
          setCrumb(2)
          history.push('/governance')
        }}>/Governance</Breadcrumb>

        <Breadcrumb onClick={() => {
          setCrumb(4)
          history.push('/account/fixed-swap')
        }} active={path.indexOf('/account') !== -1 || crumb === 4}>/Account</Breadcrumb> */}
        <Wallet.Status>
          {account ? (
            <>
              <BotBalance>{balance ? `${weiToNumber(balance)} BOT` : ''} </BotBalance>
              <UserInfo>
                <ETHLogo src={(chainId === 56 || chainId === 97) ? icon_bnb : icon_eth_logo}
                  onClick={() => {
                    if (isXSDown) {
                      dispatch({ type: HANDLE_SHOW_USER_DETAIL, showUserDetail: true })
                    }
                  }} />
                <Wallet onClick={() => {
                  //setDropDown(!dropDown)
                  console.log('show wallet connect')
                  dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
                }}>
                  <Wallet.Chain>{(chainId === 56 || chainId === 97) ? 'Binance Smart Chain' : 'Ethereum'}</Wallet.Chain>
                  <Wallet.Address>
                    {formatAddress(account)}
                    <CopyToClipboard
                      text={account}
                      onCopy={() => {
                        setIsCopyTip(true)
                      }}>
                      <Wallet.CopyIcon
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        src={icon_copy} />

                    </CopyToClipboard>

                    <div className='tip_box' style={{ display: isCopyTip ? 'block' : 'none' }}>Address Copied</div>
                  </Wallet.Address>
                </Wallet>
                {!dropDown && <Arrow onClick={() => {
                  setDropDown(true)
                }} src={arrow_down} />}
                {dropDown && <Arrow onClick={() => {
                  setDropDown(false)
                }} src={arrow_up} />}

                {dropDown &&
                  <DownBox>
                    <BoxItem>
                      <BoxItem.Check style={{ display: isETH ? 'flex' : 'none' }} src={icon_checked} />
                      <BoxItem.Icon src={icon_eth} />
                      <BoxItem.Meta>
                        <span>Ethereum</span>
                        <span>{balance && `${weiToNumber(balance)} ETH`}</span>
                      </BoxItem.Meta>
                    </BoxItem>
                    {/* <BoxItem>
                      <BoxItem.Check style={{ display: !isETH ? 'flex' : 'none' }} src={icon_checked} />
                      <BoxItem.Icon src={icon_bnb} />
                      <BoxItem.Meta>
                        <span>Binance Smart Chain</span>
                        <span>{balance && `${weiToNumber(balance)} BNB`}</span>
                      </BoxItem.Meta>
                    </BoxItem> */}

                    {/* <UnderCross style={{ marginTop: 8 }} />
                    <ViewNFTs onClick={() => {
                      setDropDown(false);
                      dispatch({ type: HANDLE_MY_NFTS_MODAL, NTFSModal: true });
                      history.push('/');
                    }}>
                      View my NFTS
                                    </ViewNFTs>
                    <UnderCross /> */}
                    <Button
                      className='write_btn'
                      width='90%'
                      style={{
                        margin: '5px auto'
                      }}
                      onClick={() => {
                        history.push('/account/view-my-nft')
                        setDropDown(false)
                      }}
                    >
                      View my NFTS
                      </Button>

                  </DownBox>}

              </UserInfo>
            </>
          ) : <>
              <ConnectButton black width={'160px'} onClick={() => {
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
              }}>Connect wallet</ConnectButton>
            </>}
        </Wallet.Status>

        {isXSDown && <MenuBox>
          <MENUIcon src={icon_menu} onClick={() => {
            dispatch({ type: HANDLE_SHOW_MENU_MODAL, showMenuModal: true });
          }} />
        </MenuBox>
        }
        {isXSDown &&
          <div className='mobileMenu'>
            <MenuModal />
            {active && <UserDetailModal />}
          </div>
        }

      </HeaderFrame>


      <Modal closeable={true} isOpen={showWelcomeModal} onDismiss={() => {
      }}>
        {steps[curStep].title && <ModalTitle>{steps[curStep].title}</ModalTitle>}
        {steps[curStep].img && <img style={{ width: 320, marginTop: 12 }} src={steps[curStep].img} />}
        <ModalContent>{steps[curStep].content}</ModalContent>

        <ModalButtonFrame>
          <Button width={'152px'} onClick={() => {
            if (curStep === 0 || curStep === 5) {
              onSkip()
            } else {
              setCurStep(curStep - 1)
            }
          }}>{(curStep === 0 || curStep === 5) ? 'Skip' : 'Previous'}</Button>
          <Button black width={'152px'} onClick={() => {
            if (curStep === 5) {
              onSkip()
            } else {
              setCurStep(curStep + 1)
            }
          }}>{steps[curStep].button}</Button>
        </ModalButtonFrame>
      </Modal>
    </div>
  )
}
