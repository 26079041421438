import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DetailHeader } from "./DetailHeader";
import { Route, Switch } from "react-router-dom";

import { EANFT, MyEANFT } from "./EANFT";
import { PoolTypeModal } from "../../components/common/PoolTypeMpdal";
import { FSNFT, MyFSNFT } from "./FSNFT";

const DetailFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #121212;
  align-items: center;
`

export const StoreDetail = () => {

  const [searchText, setSearchText] = useState('')
  const [queryStatus, setQueryStatus] = useState('All')


  return <DetailFrame>
    <DetailHeader title={'Alpaca City Store'} website={'A miracle city where DeFi meets Alpaca NFTs. - Official City Guide: https://guide.alpaca.city'} onSearchChange={setSearchText} onQueryStatusChange={setQueryStatus} />
    <Switch>
      <Route path={`/store/detail/nft-english-auction`}>
        <EANFT queryStatus={queryStatus} searchText={searchText} />
      </Route>

      <Route path={`/store/detail/nft-fixed-swap`}>
        <FSNFT queryStatus={queryStatus} searchText={searchText} />
      </Route>

      {/* Accounct  nft-english-auction*/}
      <Route path={`/store/detail/account/nft-english-auction`}>
        <MyEANFT queryStatus={queryStatus} searchText={searchText} />
      </Route>

      {/* Accounct  nft-fixed-swap*/}
      <Route path={`/store/detail/account/nft-fixed-swap`}>
        <MyFSNFT queryStatus={queryStatus} searchText={searchText} />
      </Route>
    </Switch>

  </DetailFrame>
}
