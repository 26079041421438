import {getContract, useActiveWeb3React} from "../../web3";
import {useContext, useEffect, useState} from "react";
import {myContext} from "../../reducer";
import {
  getAlpacasNFTAddress,
  getBounceAlpacasFSNFTAddress,
  getBounceYGiftFSAddress,
  getFixSwapNFTAddress, getYGiftNFTAddress
} from "../../web3/contractAddress";
import bounceERC20ABI from '../../web3/abi/bounceERC20.json';
import bounceERC721 from "../../web3/abi/bounceERC721.json";
import yGift from "../../web3/abi/yGift.json";
import bounceERC1155 from "../../web3/abi/bounceERC1155.json";
import AlpacaERC1155 from "../../web3/abi/AlpacaERC1155.json";
import BounceFSNFT from "../../web3/abi/BounceFSNFT.json";
import AlpacaFixedSwapNFT from "../../web3/abi/AlpacaFixedSwapNFT.json";
import {HANDLE_SHOW_CONNECT_MODAL} from "../../const";
import axios from "axios";
import {numToWei, weiToNumber} from "../../utils/numberTransform";
import BigNumber from "bignumber.js";
import {ETH, ZERO_ADDRESS} from "../../Content/const";
import {isGreaterThan} from "../../utils/common";


export const usePoolDetail = (id = 0) => {
  const {active, account, library, chainId} = useActiveWeb3React();

  const {state, dispatch} = useContext(myContext);
  const [name, setName] = useState(null)
  const [symbol, setSymbol] = useState(null)
  const [address, setAddress] = useState(null)
  const [startPrice, setStartPrice] = useState()
  const [reservePrice, setReservePrice] = useState()
  const [NFT, setNFT] = useState()


  const [limit, setLimit] = useState(null)
  const [isMine, setIsMine] = useState()
  const [password, setPassword] = useState()
  const [time, setTime] = useState()
  const [fromBidAmount, setFromBidAmount] = useState()
  const [toBidAmount, setToBidAmount] = useState()
  const [fromAmount, setFromAmount] = useState()
  const [claimButtonText, setClaimButtonText] = useState()
  const [myBid, setMyBid] = useState()
  const [floor, setFloor] = useState()
  const [bidden, setBidden] = useState()
  const [totalBid, setTotalBid] = useState()
  const [joined, setJoined] = useState()
  const [myBidden, setMyBidden] = useState()
  const [tokenId, setTokenId] = useState()
  const [winnerAmount, setWinnerAmount] = useState()

  const [amountMinIncr, setAmountMinIncr] = useState()

  const [curBiddenAmount, setCurBiddenAmount] = useState()


  const [round, setRound] = useState()
  const [currentPrice, setCurrentPrice] = useState()
  const [status, setStatus] = useState()

  // More deals
  const [toTokenAddress, setTokenAddress] = useState(null)
  const [toToken, setToken] = useState({})

  useEffect(() => {
    if (!toTokenAddress || !limit) return
    queryToTokenInfo(toTokenAddress)

  }, [toTokenAddress, limit])

  const queryToTokenInfo = async (toTokenAddress) => {
    if (new BigNumber(toTokenAddress).isEqualTo(0)) {
      return setToken({
        toAddress: ZERO_ADDRESS,
        toSymbol: ETH,
        toDecimals: 18,
        toPrice: weiToNumber(limit, 18) + ''
      })
    }
    let pool = {}
    const tokenContract = getContract(library, bounceERC20ABI.abi, toTokenAddress)
    pool.toAddress = toTokenAddress
    pool.toSymbol = await tokenContract.methods.symbol().call()
    pool.toDecimals = await tokenContract.methods.decimals().call()
    pool.toPrice = weiToNumber(limit, pool.toDecimals) + ''
    setToken(pool)
  }

  // More deals

  async function queryPoolDetail() {
    try {
      const contract = getContract(library, BounceFSNFT.abi, getFixSwapNFTAddress(chainId))
      contract.methods.pools(id).call().then((res) => {
        console.log('ea nft detail:', res)
        // setStartPrice(res.amountMin1)
        // setReservePrice(res.amountMax1)
        // setAmountMinIncr(res.amountMinIncr1)
        setTokenAddress(res.token1)

        setName(res.name)
        setTokenId(res.tokenId)
        setTime(res.closeAt)
        const nftAddress = res.token0
        setAddress(nftAddress)
        setLimit(res.amountTotal1)

        const time = res.closeAt;
        setTime(time)

        contract.methods.swappedP(id).call().then((res) => {
          if (res) {
            setStatus('Filled');
          } else {

            const date = new Date(time * 1000);
            const now = new Date();
            const leftTime = date - now;
            const status = leftTime > 0 ? 'Live' : 'Closed'
            setStatus(status);
          }
        })


        const mine = res.creator.toLowerCase() === account.toLowerCase();
        setIsMine(mine)

        contract.methods.passwordP(id).call().then((res) => {
          console.log('query fs password:', res)
          setPassword(res)
        })

        const nftType = res.nftType
        if (nftType === '0') {
          console.log('uriResult', res)
          try {
            const tokenContract = getContract(library, bounceERC721.abi, nftAddress)
            tokenContract.methods.tokenURI(res.tokenId).call().then(uriResult => {
              console.log('uriResult', uriResult.toString())
              fetch(uriResult).then(response => {
                response.json().then(nft => {
                  try {
                    setNFT({
                      title: nft.title,
                      name: nft.name ? nft.name : nft.properties.name.description,
                      image: nft.image ? nft.image : nft.properties.image.description,
                      description: nft.description ? nft.description : nft.properties.description.description
                    })
                  } catch (e) {
                    console.log('uriResult', e)
                  }

                })
              })
            })
          } catch (e) {
            console.log('uriResult', 'nft detail')
          }

        } else if (nftType === '1') {
          try {
            const tokenContract = getContract(library, bounceERC1155.abi, nftAddress)
            tokenContract.methods.uri(res.tokenId).call().then(uriResult => {
              console.log('uriResult', uriResult)
              fetch(uriResult, {method: "get"}).then(response => {
                response.json().then(nft => {
                  try {
                    setNFT({
                      title: nft.title,
                      name: nft.name ? nft.name : nft.properties.name.description,
                      image: nft.image ? nft.image : nft.properties.image.description,
                      description: nft.description ? nft.description : nft.properties.description.description
                    })
                  } catch (e) {

                  }

                })
              })
            })
          } catch (e) {
            console.log('nft detail')
          }

        }
      })
    } catch (e) {
      console.log('queryPoolDetail error', e)
    }
  }


  function queryRound() {
    const contract = getContract(library, BounceFSNFT.abi, getFixSwapNFTAddress(chainId))
    contract.methods.currentRound(id).call().then((res) => {
      console.log('query round', res)
      setRound(res)
    })

    contract.methods.currentPrice(id).call().then((res) => {
      console.log('query price', res)
      setCurrentPrice(res)
    })


  }

  function queryPoolStatus() {
    const contract = getContract(library, BounceFSNFT.abi, getFixSwapNFTAddress(chainId))
    contract.methods.swappedP(id).call().then((res) => {
      if (res === true) {
        setStatus('Filled');
      } else {

        const date = new Date(time * 1000);
        const now = new Date();
        const leftTime = date - now;
        const status = leftTime > 0 ? 'Live' : 'Closed'
        setStatus(status);
      }
    })
  }

  function queryMyStatus() {
    const contract = getContract(library, BounceFSNFT.abi, getFixSwapNFTAddress(chainId))
    contract.methods.creatorClaimedP(id).call().then((claimed) => {
      console.log('my claimed', claimed)
      if (!claimed) {
        setClaimButtonText('Claim your unswapped token')
      }
    })
  }


  useEffect(() => {
    if (active) {
      queryPoolDetail()
    } else {
      dispatch({type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true});
    }
  }, [active])

  useEffect(() => {
    if (active) {
      // queryRound()
    }
  }, [active])


  // useEffect(() => {
  //     console.log('starting to queryJointStatus', isMine)
  //     if (active && account && status && !isMine) {
  //         queryJointStatus()
  //     }
  // }, [active, status, isMine, joined])

  useEffect(() => {
    if (active) {
      queryPoolStatus()
    }
  }, [active])


  useEffect(() => {
    if (active && isMine && status === 'Closed') {
      console.log('query my status')
      queryMyStatus()
    }
  }, [active, status, isMine])


  return {
    name,
    address,
    symbol,
    startPrice,
    reservePrice,
    NFT,
    status,
    isMine,
    round,
    currentPrice,
    limit,
    curBiddenAmount,
    bidden,

    floor,
    time,
    totalBid,
    password,
    fromBidAmount,
    toBidAmount,
    fromAmount,
    claimButtonText,
    myBid,
    joined,
    tokenId,
    winnerAmount,
    amountMinIncr,
    setJoined,
    setIsMine,
    toToken
  }
}

export const useStorePoolDetail = (id = 0) => {
  const {active,account, library, chainId} = useActiveWeb3React();
  const {state, dispatch} = useContext(myContext);
  const [name, setName] = useState(null)
  const [symbol, setSymbol] = useState(null)
  const [address, setAddress] = useState(null)
  const [startPrice, setStartPrice] = useState()
  const [reservePrice, setReservePrice] = useState()
  const [NFT, setNFT] = useState()


  const [limit, setLimit] = useState(null)
  const [isMine, setIsMine] = useState()
  const [password, setPassword] = useState()
  const [time, setTime] = useState()
  const [fromBidAmount, setFromBidAmount] = useState()
  const [toBidAmount, setToBidAmount] = useState()
  const [fromAmount, setFromAmount] = useState()
  const [claimButtonText, setClaimButtonText] = useState()
  const [myBid, setMyBid] = useState()
  const [floor, setFloor] = useState()
  const [bidden, setBidden] = useState()
  const [totalBid, setTotalBid] = useState()
  const [joined, setJoined] = useState()
  const [myBidden, setMyBidden] = useState()
  const [tokenId, setTokenId] = useState()
  const [winnerAmount, setWinnerAmount] = useState()

  const [amountMinIncr, setAmountMinIncr] = useState()

  const [curBiddenAmount, setCurBiddenAmount] = useState()


  const [round, setRound] = useState()
  const [currentPrice, setCurrentPrice] = useState()
  const [status, setStatus] = useState()
  const [alpaca, setAlpaca] = useState()
  const [amount, setAmount] = useState()


  async function queryPoolDetail() {
    try {
      const contract = getContract(library, AlpacaFixedSwapNFT.abi, getBounceAlpacasFSNFTAddress(chainId))

      contract.methods.pools(id).call().then((res) => {
        console.log('Alpaca fs nft detail:', res)
        setName(res.name)
        setTokenId(res.tokenId)
        setTime(res.closeAt)
        const nftAddress = res.token0
        setAddress(nftAddress)
        setLimit(res.amountTotal1)
        setAmount(res.amountTotal0)
        const time = res.closeAt;
        setTime(time)

        contract.methods.swappedP(id).call().then((res) => {
          if (res) {
            setStatus('Filled');
          } else {

            const date = new Date(time * 1000);
            const now = new Date();
            const leftTime = date - now;
            const status = leftTime > 0 ? 'Live' : 'Closed'
            setStatus(status);
          }
        })


         const mine = res.creator.toLowerCase() === account.toLowerCase();
        setIsMine(mine)

        const tokenContract = getContract(library, AlpacaERC1155.abi, getAlpacasNFTAddress(chainId))

        tokenContract.methods.getAlpaca(res.tokenId).call().then(alpaca => {
          console.log('alpaca info---->', alpaca)
          setAlpaca(alpaca)
        })

        contract.methods.myCreatedP(account).call().then(alpaca => {
          console.log('myCreatedP---->', alpaca)
          setAlpaca(alpaca)
        })

        contract.methods.swappedP(id).call().then(alpaca => {
          console.log('swappedP---->', alpaca)
          setAlpaca(alpaca)
        })

        try {
          axios.get(`${chainId === 56 ? 'https://apibsc.alpaca.city/metadata/' : 'https://api.alpaca.city/metadata/'}${res.tokenId}`).then(response => {
            console.log('token data res', response)
            try {
              setNFT({
                title: response.data.name,
                name: response.data.title,
                image: response.data.image,
                description: response.data.description
              })
            } catch (e) {

            }

          })
        } catch (e) {
          console.log('nft detail')
        }

      })
    } catch (e) {
      console.log('queryPoolDetail error', e)
    }
  }


  function queryPoolStatus() {
    const contract = getContract(library, AlpacaFixedSwapNFT.abi, getBounceAlpacasFSNFTAddress(chainId))
    contract.methods.swappedP(id).call().then((res) => {
      if (res === true) {
        setStatus('Filled');
      } else {
        const date = new Date(time * 1000);
        const now = new Date();
        const leftTime = date - now;
        const status = leftTime > 0 ? 'Live' : 'Closed'
        setStatus(status);
      }
    })
  }

  function queryMyStatus() {
    const contract = getContract(library, AlpacaFixedSwapNFT.abi, getBounceAlpacasFSNFTAddress(chainId))
    contract.methods.creatorClaimedP(id).call().then((claimed) => {
      console.log('my claimed', claimed)
      if (!claimed) {
        setClaimButtonText('Claim your unswapped token')
      }
    })
  }


  useEffect(() => {
    if (active) {
      queryPoolDetail()
    } else {
      dispatch({type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true});
    }
  }, [active])

  useEffect(() => {
    if (active) {
      // queryRound()
    }
  }, [active])


  // useEffect(() => {
  //     console.log('starting to queryJointStatus', isMine)
  //     if (active && account && status && !isMine) {
  //         queryJointStatus()
  //     }
  // }, [active, status, isMine, joined])

  useEffect(() => {
    if (active) {
      queryPoolStatus()
    }
  }, [active])


  useEffect(() => {
    if (active && isMine && status === 'Closed') {
      console.log('query my status')
      queryMyStatus()
    }
  }, [active, status, isMine])


  return {
    name,
    address,
    symbol,
    startPrice,
    reservePrice,
    NFT,
    status,
    isMine,
    round,
    currentPrice,
    limit,
    curBiddenAmount,
    bidden,
    alpaca,
    floor,
    time,
    totalBid,
    password,
    fromBidAmount,
    toBidAmount,
    fromAmount,
    claimButtonText,
    myBid,
    joined,
    tokenId,
    winnerAmount,
    amountMinIncr,
    setJoined,
    setIsMine,
    amount
  }
}

export const useYGiftPoolDetail = (id = 0) => {
  const {active, account, library, chainId} = useActiveWeb3React();
  const {state, dispatch} = useContext(myContext);
  const [name, setName] = useState(null)
  const [symbol, setSymbol] = useState(null)
  const [address, setAddress] = useState(null)
  const [startPrice, setStartPrice] = useState()
  const [reservePrice, setReservePrice] = useState()
  const [NFT, setNFT] = useState()


  const [limit, setLimit] = useState(null)
  const [isMine, setIsMine] = useState()
  const [password, setPassword] = useState()
  const [time, setTime] = useState()
  const [fromBidAmount, setFromBidAmount] = useState()
  const [toBidAmount, setToBidAmount] = useState()
  const [fromAmount, setFromAmount] = useState()
  const [claimButtonText, setClaimButtonText] = useState()
  const [myBid, setMyBid] = useState()
  const [floor, setFloor] = useState()
  const [bidden, setBidden] = useState()
  const [totalBid, setTotalBid] = useState()
  const [joined, setJoined] = useState()
  const [myBidden, setMyBidden] = useState()
  const [tokenId, setTokenId] = useState()
  const [winnerAmount, setWinnerAmount] = useState()

  const [amountMinIncr, setAmountMinIncr] = useState()

  const [curBiddenAmount, setCurBiddenAmount] = useState()


  const [round, setRound] = useState()
  const [currentPrice, setCurrentPrice] = useState()
  const [status, setStatus] = useState()
  const [alpaca, setAlpaca] = useState()


  async function queryPoolDetail() {
    try {
      const contract = getContract(library, BounceFSNFT.abi, getBounceYGiftFSAddress(chainId))

      contract.methods.pools(id).call().then((res) => {
        console.log('ygift fs detail:', res)
        setName(res.name)
        setTokenId(res.tokenId)
        setTime(res.closeAt)
        const nftAddress = res.token0
        setAddress(nftAddress)
        setLimit(res.amountTotal1)

        const time = res.closeAt;
        setTime(time)

        contract.methods.swappedP(id).call().then((res) => {
          if (res) {
            setStatus('Filled');
          } else {

            const date = new Date(time * 1000);
            const now = new Date();
            const leftTime = date - now;
            const status = leftTime > 0 ? 'Live' : 'Closed'
            setStatus(status);
          }
        })


        const mine = res.creator.toLowerCase() === account.toLowerCase();
        setIsMine(mine)

        const tokenContract = getContract(library, yGift.abi, getYGiftNFTAddress(chainId))
        tokenContract.methods.gifts(res.tokenId).call().then(gift => {
          setNFT({
            cover: gift.url,
            title: gift.name,
            amount: gift.amount,
            message: gift.message,
            token: gift.token
          })
        })


      })
    } catch (e) {
      console.log('queryPoolDetail error', e)
    }
  }


  function queryPoolStatus() {
    const contract = getContract(library, BounceFSNFT.abi, getBounceYGiftFSAddress(chainId))
    contract.methods.swappedP(id).call().then((res) => {
      if (res === true) {
        setStatus('Filled');
      } else {

        const date = new Date(time * 1000);
        const now = new Date();
        const leftTime = date - now;
        const status = leftTime > 0 ? 'Live' : 'Closed'
        setStatus(status);
      }
    })
  }

  function queryMyStatus() {
    const contract = getContract(library, BounceFSNFT.abi, getBounceYGiftFSAddress(chainId))
    contract.methods.creatorClaimedP(id).call().then((claimed) => {
      console.log('my claimed', claimed)
      if (!claimed) {
        setClaimButtonText('Claim your unswapped token')
      }
    })
  }


  useEffect(() => {
    if (active) {
      queryPoolDetail()
    } else {
      dispatch({type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true});
    }
  }, [active])

  useEffect(() => {
    if (active) {
      // queryRound()
    }
  }, [active])


  // useEffect(() => {
  //     console.log('starting to queryJointStatus', isMine)
  //     if (active && account && status && !isMine) {
  //         queryJointStatus()
  //     }
  // }, [active, status, isMine, joined])

  useEffect(() => {
    if (active) {
      queryPoolStatus()
    }
  }, [active])


  useEffect(() => {
    if (active && isMine && status === 'Closed') {
      console.log('query my status')
      queryMyStatus()
    }
  }, [active, status, isMine])


  return {
    name,
    address,
    symbol,
    startPrice,
    reservePrice,
    NFT,
    status,
    isMine,
    round,
    currentPrice,
    limit,
    curBiddenAmount,
    bidden,
    alpaca,
    floor,
    time,
    totalBid,
    password,
    fromBidAmount,
    toBidAmount,
    fromAmount,
    claimButtonText,
    myBid,
    joined,
    tokenId,
    winnerAmount,
    amountMinIncr,
    setJoined,
    setIsMine
  }
}
