import React, {useState, useCallback, useContext, useEffect} from 'react';
import closeIcon from '../static/image/close.svg'
import classNames from "classnames";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {getSummaryAccount, queryMyPool} from "../Content/const";
import {t} from "../utils/intl";
import {myContext} from '../reducer'
import {Link} from 'react-router-dom';
import {Select} from "../components/Select";
import copy from '../static/image/copy.svg';
import {getBalance} from "../components/utils/web3";



export const MobileNav = ({index, metaMaskInfo, isConnectPage, onHandleSelect}) => {

  const {state, dispatch} = useContext(myContext);
  const isConnect = metaMaskInfo.isConnect;
  const account = metaMaskInfo.account;
  const [isCopied, setIsCopied] = useState(false);
  const [amount, setAmount] = useState('');

  const {web3, myAccount} = state;

  useEffect(() => {
    console.log('create pool my account---->',myAccount, web3)
    if (web3 && myAccount) {
      getBalance(web3, myAccount)
          .then(result => {
            let amount = '';
            if (result) {
              const index = result.indexOf('.');
              amount = result.substring(0, index + 5);
            }
            console.log('balance---->',result)
            setAmount(amount);
          })
          .catch(err => {
            console.log(err);
          })
      queryMyPool(web3, myAccount)
          .then(r => {
            console.log('query my pool',r)
            if (r && r.amount !== r.swap) {
              dispatch({type: 'HANDLER_HAS_POOL', hasPool: true});
            } else {
              dispatch({type: 'HANDLER_HAS_POOL', hasPool: false});
            }
          })
          .catch(e => {
            console.log(e);
          })
    }
  }, [myAccount, web3]);


  const handleCopied = useCallback(() => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }, [setIsCopied]);

  return (
      <div className='nav-container' style={{display: state.showMobileNav? 'flex': 'none'}}>
        <img className="close" src={closeIcon} onClick={()=>{
          dispatch({type: 'HANDLER_MOBILE_NAV', showMobileNav: false})
        }}/>
        <div className="content">
          <ul className='action'>
            {isConnect
                ? <li className='wallet-info'>
                  <div className='wallet-info-item'>
                    <span className='amount'>{`${amount} ETH`}</span>
                    <CopyToClipboard text={myAccount}
                                     onCopy={handleCopied}>
                      <div className='copyArea'>
                        <span className='account'>{getSummaryAccount(myAccount)} <img src={copy} alt='copy'/></span>
                        {isCopied && <span className='copied'>{t('header.copied')}</span>}
                      </div>
                    </CopyToClipboard>
                  </div>
                </li>
                : <Link to='/connect' onClick={()=>{
                  dispatch({type: 'HANDLER_MOBILE_NAV', showMobileNav: false})
                }}>
                  <li style={{width:250,margin: 0}} className={classNames('button', isConnectPage && 'current')}>
                    {t('header-button.connect-wallet')}
                  </li>
                </Link>
            }
            <Link to={!state.hasPool ? '/create' : '/pool'} onClick={()=>{
              dispatch({type: 'HANDLER_MOBILE_NAV', showMobileNav: false})
            }}>
              <li style={{marginLeft: 0, width: '100%', marginTop: 30}}
                  className={classNames('button', index === 0 && 'current')}>{!state.hasPool ? t('header-button.create-bounce-pool') : t('header-button.check-pool-status')}</li>
            </Link>
            <Select
                containerStyle={{width:'100%', marginTop:30}}
                optionsStyle={{width: '100%'}}
                title={t('header-button.bounce-token')}
                options={[
                  {
                    'name': t('header-button.daily-reward'),
                    'value': t('header-button.daily-reward'),
                    'link': '/claim'
                  },
                  {'name': t('stake.bot-staking'), 'value': t('stake.bot-staking'), 'link': '/staking'}
                ]}
                onSelect={(e) => {
                  dispatch({type: 'HANDLER_MOBILE_NAV', showMobileNav: false})
                }}/>
            {/*<Link to='/claim'>*/}
            {/*<li className={classNames('button', index === 1 && 'current')}>{t('header-button.claim-reward')}</li>*/}
            {/*</Link>*/}
            {/*<div style={{width: 20}}></div>*/}
            {/*<LanguageSwiter onHandleSelect={onHandleSelect}/>*/}
          </ul>
        </div>
      </div>
  )
}
