import { useEffect, useState } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import { getDutchAuction721 } from "../../../web3/contractAddress";

import { queryPoolItem_pools, queryERC20Token, queryERC721Token } from './Request_CT'
import { getNFTPoolStatus, getParticipant, getPoolType, getPriceRange } from './Request_FUN'

import BounceDANFT from '../../../web3/abi/bounceDutchAuctionNFT.json'

export const keyMap = [{
    key: 'creatorP',
    name: 'creator',
    needTrans: false,
}, {
    key: 'nameP',
    name: 'name',
    needTrans: false,
}, {
    key: 'token0P',
    name: 'fromAddress',
    needTrans: false,
}, {
    key: 'passwordP',
    name: 'password',
    needTrans: false,
}, {
    key: 'amountTotal0P',
    name: 'fromAmount',
    needTrans: false,
}, {
    key: 'amountMin1P',
    name: 'toAmount',
    needTrans: true,
}, {
    key: 'closeAtP',
    name: 'time',
}, {
    key: 'onlyBotHolder',
    name: 'onlyBot',
    needTrans: false
}, {
    key: 'minEthBidP',
    name: 'minEthPerWallet',
    needTrans: true
}];

export const useDA_List_ERC_NFT = (queryListArr = []) => {
    const { active, library, chainId } = useActiveWeb3React()
    const [poolsDate, setPoolsDate] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    // const noConnect = new Web3(new Web3.providers.HttpProvider("https://api.infura.io/v1/jsonrpc/mainnet")).currentProvider
    // console.log('A_console_noConnect', noConnect)
    useEffect(() => {
        if (!active || !queryListArr) return
        setIsLoad(true)
        queryPoolDate(queryListArr)
    }, [active, queryListArr, chainId])

    const queryPoolDate = async (queryListArr) => {

        const DA_ERC_NFT_CT = getContract(library, BounceDANFT.abi, getDutchAuction721(chainId))

        const dataList = await Promise.all(queryListArr.map(async (poolID) => {
            const poolItemInfo = await queryPoolItem_pools(DA_ERC_NFT_CT, poolID)
            poolItemInfo.onlyBot = await DA_ERC_NFT_CT.methods.onlyBotHolderP(poolID).call()
            poolItemInfo.password = await DA_ERC_NFT_CT.methods.passwordP(poolID).call()

            // 通过获取到的池子信息筛选出列表需要的信息
            console.log('DA_console', poolItemInfo)

            const poolItemData = {
                poolID: poolItemInfo.poolID,
                status: getNFTPoolStatus(poolItemInfo.closeAt, poolItemInfo.swappedP),
                poolName: poolItemInfo.name,
                creator: poolItemInfo.creator,
                progress: '100%',
                nftType: getPoolType(poolItemInfo.nftType),
                toToken: await queryERC20Token(library, '0x00', chainId),
                Participant: getParticipant(poolItemInfo.password, poolItemInfo.onlyBot),
            }
            poolItemData.fromToken = await queryERC721Token(library, poolItemInfo.token0, poolItemInfo.tokenId)
            poolItemData.price = getPriceRange(poolItemInfo.amountMin1, poolItemInfo.amountMax1, poolItemData.toToken.decimals)

            return poolItemData
        }))

        setPoolsDate(dataList)
        setIsLoad(false)
    }

    return { poolsDate, isLoad }
}
