import axios from 'axios'
import default_img from '../../../static/image/rect-logo.svg'

const getUrl = (ChainId) => {
    switch (ChainId) {
        default:
            return 'https://account.bounce.finance:16000/query_layer/api'
    }
}

export const requestSearch = async (ChainId, params) => {
    const Url = getUrl(ChainId)
    const res = await axios.post(Url, params)
    return res
}

export const getCoverByURI = async (uri) => {
    try {
        const json = await axios.get(uri)
        const cover = json.data.image || json.data.properties.image.description
        return cover.replace(/ipfs:\/\//, 'https://ipfs.io/')
    } catch (error) {
        return default_img
    }
}

export const Realy_setNFT = async (address = '0x26ab18Ca5C193722F16983D363454482Cff0cf1F', tokenId) => {
    const URL = `https://realy.swell.link/metadata/${address}/${tokenId}`
    let cover = require('../assets/realy.png')
    const res = await axios.get(URL)
    if (res.status === 200) {
        cover = res.data.image
    }
    return {
        adress: address,
        tokenID: tokenId,
        nftType: 'ERC1155',
        cover: cover
    }
}