import React from 'react';
import { makeStyles, fade } from '@material-ui/core'; 
import classNames from 'classnames';

const useStyle = makeStyles(() => ({
  root: {
    width: '100%',
    height: 83,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    position: 'relative',
  },
  line: {
    width: '100%',
    height: 1,
    position: 'absolute',
    top: 41,
    left: 0,
    backgroundColor: fade('#000', 0.3),
  },
  item: {
    position: 'relative',
    width: '24%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:nth-child(2)': {
      justifyContent: 'flex-start',
      width: '14%',
      '& div': {
        textAlign: 'left',
      }
    },
    '&:last-child': {
      justifyContent: 'flex-end',
      width: '17%',
      '& div': {
        textAlign: 'right',
      },
    },
  },
  data: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'IBM Plex Mono',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17px',
    color: fade('#1F191B', 0.3),
    position: 'absolute',
    top: 0,
    left: 0,
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: `1px solid ${fade('#1F191B', 0.3)}`,
    cursor: 'pointer',
  },
  choice: {
    '& $data': {
      top: 64,
      color: '#000',
    },
    '& $circle': {
      backgroundColor: '#000',
      position: 'relative',
      marginLeft: 5,
      '&:after': {
        content: '" "',
        width: 24,
        height: 24,
        position: 'absolute',
        top: -5,
        left: -5,
        borderRadius: '50%',
        border: '1px solid #000',
      }
    }
  }
}));

const list = [3, 4, 5, 6, 7];

const Timing = ({index, onClick}) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <div className={classes.line}></div>
      {list.map((item, i) => <div className={classNames(classes.item, index === item && classes.choice)} key={item}
        onClick={() => onClick(item)}
      >
        <div className={classes.data}>{`${item} Day`}</div>
        <div className={classes.circle}></div>
      </div>)}
    </div>
  )
}

export { Timing }