import { isEqualTo } from '../../../utils/common';
import { getFixSwapAddress } from "../../../web3/contractAddress";
import { getContract } from "../../../web3";
import { getCoverByURI } from './Request_API'
import ERC20_ABI from '../../../web3/abi/bounceERC20.json'
import ERC721_ABI from '../../../web3/abi/bounceERC721.json'
import ERC1155_ABI from '../../../web3/abi/bounceERC1155.json'
import FS_ERC20_ABI from '../../../web3/abi/bounce.json'

import icon_eth from '../../../static/image/icon-eth-logo.svg'
import icon_bnb from '../../../static/image/icon-bnb.svg'
import icon_wenhao from '../../../static/image/wenhao.svg'

import default_img from '../../../static/image/rect-logo.svg'

export const callQueryData = async (bounceContract, methodName, options = {}) => {
    if (!bounceContract || !methodName) throw new Error('Function Error: callQueryData, params error')

    try {
        const res = await bounceContract.methods[methodName]().call(options);
        return res
    } catch (error) {
        // throw new Error('request Error: callQueryData', error)

    }
}

export const callPoolDataByID = async (bounceContract, methodName, poolID) => {
    if (!bounceContract || !methodName) throw new Error('Function Error: callPoolDataByID, params error')

    try {
        const res = await bounceContract.methods[methodName](poolID).call();
        return res
    } catch (error) {
        // throw new Error('request Error: callQueryData', error)
    }
}

export const queryPoolItem = async (pool_CT, poolID, keyMap) => {
    const poolItemDate = { poolID }
    await Promise.all(keyMap.map(async item => {
        const data = await callPoolDataByID(pool_CT, item.key, poolID);
        poolItemDate[item.name] = data
    }))
    return poolItemDate
}

export const queryPoolItem_pools = async (pool_CT, poolID) => {
    const pools = await callPoolDataByID(pool_CT, 'pools', poolID)

    return { ...pools, poolID }
}

export const queryERC20Token = async (library, adress, chainID = 1) => {
    if (!adress) return { error: 'adress is  ' + adress }
    if (isEqualTo(adress, 0)) {
        if (chainID === 56) {
            return {
                symbol: 'BNB',
                decimals: 18,
                adress: adress,
                cover: icon_bnb,
                antiFake: true
            }
        } else {
            return {
                symbol: 'ETH',
                decimals: 18,
                adress: adress,
                cover: icon_eth,
                antiFake: true
            }
        }
    } else {
        const ERC20_CT = getContract(library, ERC20_ABI.abi, adress)
        const symbol = await ERC20_CT.methods.symbol().call()
        const decimals = await ERC20_CT.methods.decimals().call()
        return {
            symbol: symbol,
            decimals: decimals,
            adress: adress,
            cover: icon_wenhao,
            antiFake: null
        }
    }
}

export const queryERC721Token = async (library, token_address, token_ID) => {
    if (!token_address) return { adress: 'error' }
    const ERC721_CT = getContract(library, ERC721_ABI.abi, token_address)
    const symbol = await ERC721_CT.methods.symbol().call()
    const tokenURI = await ERC721_CT.methods.tokenURI(token_ID).call()
    const cover = await getCoverByURI(tokenURI)

    return {
        adress: token_address,
        tokenID: token_ID,
        nftType: 'ERC721',
        symbol,
        tokenURI,
        cover: cover
    }
}

export const queryERC1155Token = async (library, token_address, token_ID) => {
    const ERC1155_CT = getContract(library, ERC1155_ABI.abi, token_address)
    let cover = default_img
    try {
        const tokenURI = await ERC1155_CT.methods.uri(token_ID).call()
        cover = await getCoverByURI(tokenURI)
    } catch (error) {
    }


    return {
        adress: token_address,
        tokenID: token_ID,
        nftType: 'ERC1155',
        cover: cover
    }
}

export const replenishFS_ERC20 = async () => {
    const FS_ERC20_CT = getContract(FS_ERC20_ABI.abi, getFixSwapAddress('1'))
    let count = await FS_ERC20_CT.methods.countFP().call()
    count = parseInt(count) - 1
    console.log(count)
}