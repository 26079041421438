import React from 'react';
import { t } from '../../utils/intl';
import rotate from '../../static/image/rotate.svg';
import '../../Content/content/content-box.scss';

export const WaitContent = ({
  content
}) => {
  return (
    <div className='content-box wait-content'>
      <img src={rotate} className='waiting' alt='waiting' />
      <p>{t('waiting.title')}</p>
      <p>{content? content: ''}</p>
    </div>
  )
}
