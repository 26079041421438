import { useEffect, useState } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import { getLiquidityAddress } from "../../../web3/contractAddress";

import { queryPoolItem_pools, queryPoolItem, queryERC20Token } from './Request_CT'
import { getPoolStatus, getParticipant, getSwapRatio, getProgress } from './Request_FUN'

import LL_ERC20_ABI from '../../../web3/abi/BounceSecuredLiquidity.json'

const keyMap = [{
    key: 'passwordP',
    name: 'password',
    needTrans: false,
}, {
    key: 'amountSwap0P',
    name: 'fromBidAmount',
    needTrans: false,
}, {
    key: 'maxEthPerWalletP',
    name: 'maxEthPreWallet',
    needTrans: true,
}, {
    key: 'amountSwap1P',
    name: 'toBidAmount',
    needTrans: true,
}, {
    key: 'onlyBotHolderP',
    name: 'onlyBot',
    needTrans: false
}];

export const useLL_List_ERC20 = (queryListArr = []) => {
    const { active, library, chainId } = useActiveWeb3React()
    const [poolsDate, setPoolsDate] = useState([])
    const [isLoad, setIsLoad] = useState(true)

    useEffect(() => {
        if (!active || !queryListArr) return
        setIsLoad(true)
        queryPoolDate(queryListArr)
        return () => {
            setIsLoad(false)
        }
    }, [active, queryListArr, chainId])

    const queryPoolDate = async (queryListArr) => {
        const LL_ERC20_CT = await getContract(library, LL_ERC20_ABI.abi, getLiquidityAddress(chainId))

        const dataList = await Promise.all(queryListArr.map(async (poolID) => {
            const poolItemInfo_2 = await queryPoolItem_pools(LL_ERC20_CT, poolID)
            const poolItemInfo_1 = await queryPoolItem(LL_ERC20_CT, poolID, keyMap)
            const poolItemInfo = Object.assign(poolItemInfo_1, poolItemInfo_2)

            const poolItemData = {
                poolID: poolItemInfo.poolID,
                status: getPoolStatus(poolItemInfo.closeAt, poolItemInfo.amountTotal1, poolItemInfo.toBidAmount),
                progress: getProgress(poolItemInfo.amountTotal1, poolItemInfo.toBidAmount),
                poolName: poolItemInfo.name,
                fromToken: await queryERC20Token(library, poolItemInfo.token0, chainId),
                toToken: await queryERC20Token(library, poolItemInfo.token1, chainId),
                Participant: getParticipant(poolItemInfo.password, poolItemInfo.onlyBot),
                creator: poolItemInfo.creator
            }
            poolItemData.swapRatio = getSwapRatio(poolItemInfo.amountTotal0, poolItemInfo.amountTotal1, poolItemData.fromToken.decimals, poolItemData.toToken.decimals)
            poolItemData.currentPrice = poolItemData.swapRatio === '<0.0001' ? 0.0001 : parseFloat(poolItemData.swapRatio)

            return poolItemData
        }))
        const dataList_2 = dataList.filter(item => {
            return item.creator
        })
        setPoolsDate(dataList_2)
        console.log('J_console', dataList_2)
        setIsLoad(false)
    }

    return { poolsDate, isLoad }
}
