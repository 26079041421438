import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components'
import { useActiveWeb3React } from "../../web3";
import { myContext } from '../../reducer'
import { HANDLE_SHOW_CONNECT_MODAL } from "../../const";
import { MobileSelectModal } from '../../components/common/MobileSelectModal';

import { useIsXSDown } from '../../components/utils/themeHooks';
import add from '../governance/components/assets/add.svg'
import icon_search from '../../static/image/icon-search.svg';
import icon_close from '../../static/image/close.svg';

import HeaderTab from './HeaderTab'
import HeaderFilter from './HeaderFilter'
import { PoolTypeModal } from "../../components/common/PoolTypeMpdal";
import Pagination from './Pagination';

import { FS_ERC20, SB_ERC20, DA_ERC20, LT_ERC20, PRED_ERC20, SV_FS_ERC20, SV_SB_ERC20, LL_ERC20 } from './usePage/ERC20'
import { FS_NFT, SB_NFT, EA_NFT, DA_NFT, LT_NFT } from './usePage/ERC-NFT'

import { HANDLE_SHOW_SEARCH_DETAIL } from '../../const';

export const PoolList = () => {
  const { state, dispatch } = useContext(myContext);
  const history = useHistory()
  const [isEmpty, setIsEmpty] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { active } = useActiveWeb3React();

  const [showSelectModal, setShowSelectModal] = useState(false) // Shows the create selection box
  const [createOptions, setCreateOptions] = useState([])  // Configure the type options created
  const [requestData, setRequestData] = useState([])  // screening condition
  const [queryListArr, setQueryListArr] = useState([])  // screening condition
  const [pagenum, setPagenum] = useState(1)
  const isXSDown = useIsXSDown();

  useEffect(() => {
    if (!requestData) return
    let showNum = 10
    // 如果是social 的池子，一页只显示6条
    const path = history.location.pathname
    const six_whiteList = ['sv-fixed-swap', 'sv-sealed-bid']
    if (path.indexOf(six_whiteList[0]) !== -1 || path.indexOf(six_whiteList[1]) !== -1) {
      showNum = 6
    }
    const requestData_3 = requestData.slice((pagenum - 1) * showNum, pagenum * showNum)
    console.log('E_console', requestData_3)
    setQueryListArr(requestData_3)
  }, [requestData, pagenum])


  return (
    <PoolListWrapper>
      <HeaderTab setShowSelectModal={setShowSelectModal} setCreateOptions={setCreateOptions} />
      {!isXSDown &&
        <HeaderFilter
          setRequestData={setRequestData}   // 请求到的数据 [...pool_ids]
          setQueryListArr={setQueryListArr}
          setPagenum={setPagenum}
          setIsLoading={setIsLoading}       // loading 状态
          setIsEmpty={setIsEmpty}           // 查询结果为空是返回　Empty状态
          createOptions={createOptions}
        />
      }
      <SearchBox>
        {!state.showSearchDetail &&
          <MobileSearchIcon src={icon_search} onClick={() => {
            if (isXSDown) {
              dispatch({ type: HANDLE_SHOW_SEARCH_DETAIL, showSearchDetail: true })
            }
          }} />
        }
        {state.showSearchDetail &&
          <CloseIcon
            src={icon_close}
            style={{ width: 18, height: 18, cursor: 'pointer' }}
            onClick={() => {
              if (isXSDown) {
                dispatch({ type: HANDLE_SHOW_SEARCH_DETAIL, showSearchDetail: false })
              }
            }} />
        }
      </SearchBox>
      {isXSDown && <MobileSelectModal
        setRequestData={setRequestData}
        createOptions={createOptions}
        setIsEmpty={setIsEmpty}
        setIsLoading={setIsLoading}
      />
      }


      {isXSDown &&
        <Add>
          <AddIcon src={add}
            onClick={() => {
              if (active) {
                setShowSelectModal(true)
              } else {
                dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
              }
            }}
          />
        </Add>
      }


      <PoolTypeModal
        options={createOptions}
        onConfirm={(poolType) => {
          history.push(`/create/${poolType.route}`)
        }}
        show={showSelectModal}
        onDismiss={() => {
          setShowSelectModal(false)
        }}
      />

      <Switch>
        <Route exact path='/application' render={() => {
          return <Redirect to='/application/fixed-swap' />
        }} />

        <Route path='/application/fixed-swap'>
          <FS_ERC20 queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/liquidity-lock-auction'>
          <LL_ERC20 queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/sealed-bid'>
          <SB_ERC20 queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/dutch-auction'>
          <DA_ERC20 queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/lottery-erc20'>
          <LT_ERC20 queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/lottery-nft'>
          <LT_NFT queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/fixed-swap-nft'>
          <FS_NFT queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/sealed-bid-nft'>
          <SB_NFT queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/dutch-auction-nft'>
          <DA_NFT queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/english-auction-pool'>
          <EA_NFT queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/sv-fixed-swap'>
          <SV_FS_ERC20 queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />

        </Route>

        <Route path='/application/sv-sealed-bid'>
          <SV_SB_ERC20 queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>

        <Route path='/application/prediction'>
          <PRED_ERC20 queryListArr={queryListArr} isLoading={isLoading} isEmpty={isEmpty} />
          <Pagination total={requestData.length || 0} pagenum={pagenum} setPagenum={setPagenum} />
        </Route>
      </Switch>

    </PoolListWrapper>
  )
}




const PoolListWrapper = styled.div`
  width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 767px) {
    width: 100%;
  }
`
PoolListWrapper.Background = styled.div`
  background-color: #121212;
  height: 184px;
`

PoolListWrapper.SelectFrame = styled.div`
  width: 1280px;
  margin: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`
const Add = styled.div`
    position: fixed;
    bottom: 70px;
    width: 64px;
    right: 20px;
    height: 64px;
    z-index:8;
`
const AddIcon = styled.img`
    width: 64px;
    height: 64px;
`
const SearchBox = styled.div`
  display : none;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  @media (max-width: 767px) {
    margin-left: auto;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:-64px;
  }
`
const MobileSearchIcon = styled.img`
  width: 18px;
`
const CloseIcon = styled.img`
  width: 16px;
  align-self: flex-end;
  cursor: pointer;
`
