import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Cover, Pool, PoolFrame } from "../../components/common/Layout";
import icon_close from '../../static/image/icon-close.svg'
import { Form, FormStatus, Input } from "../../components/common/Form";
import { useParams } from 'react-router-dom';
import { useHandleForm, useTokenList } from '../../web3/common'
import { t } from "../../utils/intl";
import { Button } from "../../components/common/Button";
import { HANDLE_SHOW_CONNECT_MODAL, ZERO_ADDRESS } from "../../const";
import { numToWei, weiToNum, weiToNumber } from "../../utils/numberTransform";
import md5 from "js-md5";
import Web3 from 'web3'
import { useHistory } from 'react-router-dom'
import { getTime, isGreaterThan } from "../../utils/common";
import { getContract, useActivePlatform, useActiveWeb3React } from "../../web3";
import bounceERC721 from "../../web3/abi/bounceERC721.json";
import bounceERC1155 from "../../web3/abi/bounceERC1155.json";

import {
    getFixSwapNFTAddress,
} from "../../web3/contractAddress";
import BounceFSNFT from "../../web3/abi/BounceFSNFT.json";
import { useIsXSDown } from '../../components/utils/themeHooks';

import {
    CreateModal,
    initStatus,
    approveStatus,
    pendingStatus,
    confirmStatus,
    successStatus,
    errorStatus,
    cancelStatus
} from "../../components/common/CreateModal";
import { PoolCover } from "../../components/DetailCover";
import rect_logo from "../../static/image/rect-logo.svg";
import styled from "styled-components";
import { Select } from "../../components/common/Select";

const BigNumber = require('bignumber.js');
const { toWei, BN } = Web3.utils

const TokenFrame = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  img{
    width: 18px;
    margin-right: 8px;
  }
`


export const CreateFSNFTPool = () => {
    const history = useHistory()
    const { type } = useParams();
    console.log('create type :', type)
    const isXSDown = useIsXSDown();
    const { active, account, library, chainId } = useActiveWeb3React();
    const [modalStatus, setModalStatus] = useState(initStatus)
    const [isDutchNFT1155, setIsDutchNFT1155] = useState(false)

    // More deals
    const tokenOptions = useTokenList()
    const [selectedToken, setSelectedToken] = useState(tokenOptions[0])


    const {
        address, setAddress, addressError,
        token,
        amount, setAmount, amountError,
        ratio, setRatio, ratioError,
        name, setName, nameError,
        onlyBot, setOnlyBot,
        password, setPassword,
        days, setDays,
        hours, setHours,
        minutes, setMinutes,
        timeError,
        check, setCheck,
        nftType, setNFTType,
        nftID, setNFTID, idError,
        nft, setNFT,
        minAmount, setMinAmount,
    } = useHandleForm()



    const onFSNFTCreate721 = async () => {
        // setCheck(!check)

        if (!name || !address || !minAmount) {
            return;
        }

        const bounceAddress = getFixSwapNFTAddress(chainId)
        const tokenContract = getContract(library, bounceERC721.abi, address)
        const bounceContract = getContract(library, BounceFSNFT.abi, bounceAddress)
        const time = getTime(days, hours, minutes);
        setModalStatus(approveStatus);

        try {
            const result = await tokenContract.methods.approve(
                bounceAddress,
                nftID
            )
                .send({ from: account });
            setModalStatus(confirmStatus);
            // console.log(
            //     'onFSNFTCreate721',
            //     name,
            //     address,
            //     ZERO_ADDRESS,
            //     nftID,
            //     minAmount,
            //     time,
            //     onlyBot,
            //     0
            // )
            if (result.status) {
                await bounceContract.methods.createErc721(
                    name,
                    address,
                    // ZERO_ADDRESS,
                    selectedToken.key,
                    nftID,
                    numToWei(minAmount, selectedToken.decimals),
                    time,
                    false,
                    0
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            } else {
                setModalStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    }

    const onFSNFTCreate1155 = async () => {
        setCheck(!check)
        if (!name || !address || !amount) {
            return;
        }
        const abi = BounceFSNFT.abi;
        const bounceAddress = getFixSwapNFTAddress(chainId)
        const tokenContract = getContract(library, bounceERC1155.abi, address)
        const bounceContract = getContract(library, abi, bounceAddress)
        const fromAmount = numToWei(amount, token.decimals);
        const toAmount = numToWei((new BigNumber(amount).div(new BigNumber(ratio))), selectedToken.decimals)
        const time = getTime(days, hours, minutes);
        console.log('on crate data', fromAmount, toAmount, selectedToken)
        setModalStatus(approveStatus);
        console.log('onFSNFTCreate1155',
            name,
            address,
            selectedToken.key, // ZERO_ADDRESS,
            nftID,
            amount,
            numToWei(minAmount, selectedToken.decimals),
            time,
            false,
            (!password || password === '') ? 0 : new BN(md5(password)).toString()
            // T3 1155/2020 1 0x7f15017506978517Db9eb0Abd39d12D86B2Af395 0x101194a3FF67f83A05B3E15AfA52D45D588614ca 2020 1 6000000 600 false 0
        )
        try {
            const result = await tokenContract.methods.setApprovalForAll(
                bounceAddress,
                nftID,
            )
                .send({ from: account });
            setModalStatus(confirmStatus);

            if (result.status) {
                await bounceContract.methods.createErc1155(
                    name,   //  T3 1155/2020 1
                    address,    // 0x7f15017506978517Db9eb0Abd39d12D86B2Af395
                    // ZERO_ADDRESS,
                    selectedToken.key,  // 0x101194a3FF67f83A05B3E15AfA52D45D588614ca
                    nftID,  // 2020
                    amount, // 1
                    numToWei(minAmount, selectedToken.decimals),   // 6000000
                    time,   // 600
                    false,  // false
                    (!password || password === '') ? 0 : new BN(md5(password)).toString()   // 0
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            } else {
                setModalStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    };

    useEffect(() => {
        setNFTType('nft-721')
    }, [])

    const wrapperSpace = (str) => {
        if (!str) return false
        if (String(str).trim().length === 0) return false
        return true
    }


    return (
        <PoolFrame style={{ marginTop: 32, padding: isXSDown ? '40px 20px' : '40px 100px' }}>
            <Pool.Close onClick={() => {
                history.goBack()
            }} src={icon_close} />
            <Pool>
                <Pool.Mode style={{ textAlign: 'left' }}>Initial Token Offering</Pool.Mode>
                <Pool.Header style={{
                    justifyContent: 'flex-start',
                    borderBottom: '4px #000 solid',
                    fontSize: isXSDown ? '26px' : '36px',
                }}>Create a Fixed-Swap NFT Pool</Pool.Header>
                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div width={isXSDown ? '100%' : '480px'} style={{ height: '100%' }}>
                        Contract information
                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                        <Form
                            type='radio'
                            prefix={(
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label>
                                        <input onChange={(e) => {
                                            setNFTType('nft-721')
                                            setIsDutchNFT1155(false)
                                        }} type='radio' name='nft-type' defaultChecked={true} />
                                        <i></i>
                                        {` erc721`}
                                    </label>
                                    <label style={{ marginLeft: 80, marginRight: 34 }}>
                                        <input onChange={() => {
                                            setNFTType('nft1155')
                                            setIsDutchNFT1155(true)
                                        }} type='radio' name='nft-type' />
                                        <i></i>
                                        {` erc1155`}
                                    </label>
                                </div>
                            )} name={<span style={{ marginBottom: 9 }}>Token Type</span>} />
                        <Form top={'38px'} error={addressError} input={<Input
                            onChange={(e) => {
                                setAddress(e.target.value)
                            }} />} name={'Token Contract address'} />

                        <Form top={'38px'} error={idError} input={<Input
                            onFocus={(e) => {
                                // setIDError(false)
                                setNFTID(e.target.value)
                            }}
                            onChange={(e) => {
                                setNFTID(e.target.value)
                                console.log('nft id:', e.target.value)
                            }} />} name={isDutchNFT1155 ? 'ERC-1155 Token ID' : 'ERC-721 Token ID'} />
                        {isDutchNFT1155 &&
                            <Form top={'38px'} input={<Input
                                onChange={
                                    (e) => {
                                        setAmount(e.target.value)
                                    }
                                }
                            />} name={'Amount of token'} />}

                    </Pool.Content>
                </Pool.Frame>

                <Pool.Divider />

                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div style={{ width: isXSDown ? '100%' : 480, height: '100%' }}>
                        Pool settings
                        <Pool.Content width={isXSDown ? '100%' : '512px'} style={{ marginTop: 48 }}>
                            <PoolCover cover={nft && (nft.cover ? nft.cover : rect_logo)} />
                        </Pool.Content>
                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                        <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '480px'}>
                            <Form disabled input={<Input disabled value={isDutchNFT1155 ? 'ERC1155_TOKEN' : token.symbol} />} name={'From'}
                                width={isXSDown ? '100%' : '213px'} />
                            <Form name={'To'}
                                width={isXSDown ? '100%' : '213px'}
                                input={<Select
                                    // disabled
                                    width={isXSDown ? '100%' : '213px'}
                                    options={tokenOptions}
                                    onSelect={(value) => {
                                        setSelectedToken(value)
                                    }}
                                    defaultValue={tokenOptions[0]} />} />
                        </Pool.Content>

                        <Form top={'38px'} input={<Input
                            type='number'
                            value={minAmount}
                            onChange={(e) => {
                                // setRatio(e.target.value.replace(/[^\d.]/g, ''))
                                setMinAmount(e.target.value.replace(/[^\d.]/g, ''))
                            }}
                        />}
                            name='Fixed-Swap Price'
                            suffix={selectedToken.symbol} />



                        <Form top={'38px'} error={nameError} input={<Input
                            maxLength={15}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />} name={'Pool Name'} />

                        <Form top={'49px'} error={timeError}
                            name={<span style={{ color: '#000', marginBottom: 9 }}>Pool running time</span>} hidden
                            prefix={(<div>
                                <Pool.Content width={isXSDown ? '100%' : '480px'}>
                                    <Form input={<Input
                                        value={days}
                                        onChange={(e) => {
                                            setDays(e.target.value)
                                        }}
                                        type='number' />} name={'Days'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        value={hours}
                                        onChange={(e) => {
                                            let hours = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                            if (hours > 24) {
                                                hours = 24
                                            }
                                            setHours(hours);
                                        }}
                                        type='number' />} name={'Hours'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        value={minutes}
                                        onChange={(e) => {
                                            let minutes = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                            if (minutes > 60) {
                                                minutes = 60
                                            }
                                            setMinutes(minutes);
                                        }}
                                        type='number' />} name={'Minutes'} width={isXSDown ? '30%' : '132px'} />
                                </Pool.Content>
                            </div>)} />

                        <Form top={'38px'} disabled hidden prefix={(<div
                            style={{ width: isXSDown ? '100%' : '480px' }}>
                            <Button
                                disabled={idError === '' && wrapperSpace(name) && wrapperSpace(minAmount) && (wrapperSpace(days) || wrapperSpace(hours) || wrapperSpace(minutes)) ? false : true}
                                style={{ marginTop: 12 }} black width={isXSDown ? '100%' : '480px'} onClick={() => {
                                    if (nftType === 'nft-721') {
                                        onFSNFTCreate721()
                                    } else {
                                        onFSNFTCreate1155()
                                    }
                                }}>Launch</Button>
                            {type === 'dutch-auction-nft' ? <p style={{
                                fontFamily: 'Helvetica Neue',
                                fontSize: 12,
                                lineHeight: '16.8px',
                                color: 'rgba(0, 0, 0, .4)',
                                marginTop: 12,
                                textAlign: "left"
                            }}>
                                Bounce contract does not accept Inflationary and deflationary tokens, please don’t create a pool with special token forms.
                  </p> : ''}
                        </div>)} />
                    </Pool.Content>
                
                </Pool.Frame>
            </Pool>

            <CreateModal onOK={() => {
                setModalStatus(initStatus)
                history.goBack()
            }} onDismiss={() => {
                setModalStatus(initStatus)
            }} modalStatus={modalStatus} />

        </PoolFrame>
    )
}
