import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Return } from './Return';
import { UniswapForm } from './content/UniswapForm';
import './content/content-box.scss';
import { DeniedContent } from './content/DeniedContent';
import { getTransactionLink } from './const';
import { WaitContent } from './content/WaitContent';
import { StakeFailed } from './content/StakeFailed';
import {StakeSuccess} from './content/StakeSuccess'
import {Loading} from '../components/Loading'
import {myContext} from '../reducer'


export const UniswapContent = ({
                              account,
                            }) => {
  const {state, dispatch} = useContext(myContext);
  const [status, setStatus] = useState('init');
  const [hash, setHash] = useState();
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState(0);
  const [successContent, setSuccessContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [failedContent, setFailedContent] = useState('');


  const {web3, myAccount, curPoolType} = state;


  useEffect(()=>{
    async function loadAddress() {
      const link = await getTransactionLink(hash)
      setAddress(link);
    }
    loadAddress()
  },[hash]);

  const handleClose = useCallback(() => {
    setStatus('init');
  },[setStatus,status]);

  const renderStack = useCallback(() => {
    switch(status) {
      case 'init':
      default:
        return <UniswapForm
            account={myAccount}
            onStatusChange={setStatus}
            onHashChange={setHash}
            onAmountChange={setAmount}
            onSuccessContentChange={setSuccessContent}
            onLoadingStatusChange={setLoading}
            onFailedContentChange={setFailedContent}
            web3={web3}
        />
      case 'success':
        return <StakeSuccess link={address} amount={amount} onClose={handleClose} content={successContent}/>
      case 'failed':
        return <StakeFailed onClose={handleClose} content={failedContent}/>
      case 'denied':
        return <DeniedContent onClose={handleClose} />
      case 'waiting':
        return <WaitContent symbol={''} isCommon={true} />
    }
  }, [setStatus, handleClose, setAddress,address, amount, setAmount]);

  return (
      <div className='content stake'>
        <Return type={curPoolType}/>
        <div className='form form-stake'>
          <Loading show={loading}/>
          {renderStack()}
        </div>
      </div>
  )
}
