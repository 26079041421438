import { useEffect, useState } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import { getBouncePredictionAddress } from "../../../web3/contractAddress";

import { queryPoolItem_pools } from './Request_CT'
import { getPREDStatus, weiToNumber } from './Request_FUN'

import PRED_ERC20_ABI from '../../../web3/abi/BouncePredict.json'
import AggregatorV3Interface from "../../../web3/abi/AggregatorV3Interface.json";

export const usePRED_List_ERC20 = (queryListArr = []) => {
    const { active, library, chainId } = useActiveWeb3React()
    const [poolsDate, setPoolsDate] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    // const noConnect = new Web3(new Web3.providers.HttpProvider("https://api.infura.io/v1/jsonrpc/mainnet")).currentProvider
    // console.log('A_console_noConnect', noConnect)
    useEffect(() => {
        if (!active || !queryListArr) return
        setIsLoad(true)
        console.log('A_console_queryListArr', queryListArr)
        queryPoolDate(queryListArr)
    }, [active, queryListArr, chainId])

    const queryPoolDate = async (queryListArr) => {
        const PRED_ERC20_CT = getContract(library, PRED_ERC20_ABI.abi, getBouncePredictionAddress(chainId))

        const dataList = await Promise.all(queryListArr.map(async (poolID) => {
            const poolItemInfo = await queryPoolItem_pools(PRED_ERC20_CT, poolID)
            poolItemInfo.totalVoteUp = await PRED_ERC20_CT.methods.totalVoteUp(poolID).call()
            poolItemInfo.totalVoteDown = await PRED_ERC20_CT.methods.totalVoteDown(poolID).call()
            if (poolItemInfo.priceFeed) {
                const aggregatorContract = getContract(library, AggregatorV3Interface.abi, poolItemInfo.priceFeed)
                poolItemInfo.pair = await aggregatorContract.methods.description().call();
                poolItemInfo.decimals = await aggregatorContract.methods.decimals().call();
            }

            // 通过获取到的池子信息筛选出列表需要的信息
            console.log('PRED_console', poolItemInfo)
            const poolItemData = {
                poolID: poolItemInfo.poolID,
                status: getPREDStatus(poolItemInfo.closeAt, poolItemInfo.duration),
                poolName: poolItemInfo.name,
                creator: poolItemInfo.creator,
                totalVoteUp: weiToNumber(poolItemInfo.totalVoteUp),
                totalVoteDown: weiToNumber(poolItemInfo.totalVoteDown),
                pair: poolItemInfo.pair,
                decimals: poolItemInfo.decimals,
                closeAt: poolItemInfo.closeAt,
                price: weiToNumber(poolItemInfo.startPrice, 8, 2)
            }

            return poolItemData
        }))

        setPoolsDate(dataList)
        setIsLoad(false)

        console.log('PRED_console', dataList)
    }

    return { poolsDate, isLoad }
}
