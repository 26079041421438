import {getContract, useActiveWeb3React} from "../../web3";
import {useContext, useEffect, useState} from "react";
import Web3 from "web3";
import {governanceKeyMap} from "../../Content/const";
import bounceStake from "../../web3/abi/bounceStake.json";
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import {getBotAddress, getStakingAddress} from "../../web3/contractAddress";
import {int2hex} from "../../utils/common";

const {fromAscii, numberToHex, soliditySha3, asciiToHex, hexToBytes} = Web3.utils

const queryGovernanceData = async (contract, item, id) => {
    try {
        let data;
        if (item.beString) {
            data = await contract.methods.getConfigString((item.key), id).call();
        } else {
            data = await contract.methods.getConfig((item.key), id).call();
        }
        if (item.isJson) {
            data = JSON.parse(data)
        }
        if (item.isByte) {
            data = Web3.utils.toAscii(Web3.utils.numberToHex(data))
        }
        return data
    } catch (e) {

    }

}

const queryGovernanceItem = async (contract, index) => {
    const info = await Promise.all(governanceKeyMap.map(async item => {
        const boj = {};
        console.log('queryGovernanceItem key', item.key)
        const data = await queryGovernanceData(contract, item, index);
        console.log('queryGovernanceItem data:', data)
        boj[item.name] = data;
        return boj;
    }));
    const data = {};
    data['index'] = index;
    for (let i = 0; i < info.length; i++) {
        const item = info[i];
        const name = governanceKeyMap[i]['name'];
        data[name] = item[name];
    }
    return data;
};




export const useGovernance = () => {
    const {active, account, library, chainId} = useActiveWeb3React();

    const [govList, setGovList] = useState([])
    const [BOTStaked, setBOTStaked] = useState()
    const [govReward, setGovReward] = useState()
    const [govBOT, setGovBOT] = useState()



    function queryStaked() {
        const stakingContract = getContract(library, bounceStake.abi, getStakingAddress(chainId))
        try{
            stakingContract.methods.myTotalStake(account).call().then((res)=>{
                setBOTStaked(res)
            })
        }catch (e) {
            console.log('myTotalStake error:',e)

        }

        try{
            stakingContract.methods.getGovReward(account).call().then((res)=>{
                setGovReward(res)
            })
        }catch (e) {
            console.log('getGovReward error:',e)
        }

        const bot = getContract(library, bounceERC20.abi, getBotAddress(chainId))

        try{
            bot.methods.balanceOf('0xda67595745f74860f3360fedf744cee7293d2daf').call().then((res)=>{
                setGovBOT(res)
            })
        }catch (e) {
            console.log('getGovReward error:',e)
        }

    }


    async function queryGovList(){
        console.log('govnance----> id',soliditySha3('proposes'),'--->',fromAscii('proposes'))
        const contract = getContract(library, bounceStake.abi, getStakingAddress(chainId))
        const index = await contract.methods.getConfig(soliditySha3('proposes'), 0).call();
        let id = index;
        let position = 0;
        let governanceList = [];
        let governance = null;
        let governanceID = 0;
        while (id != 0) {
            governanceID = await contract.methods.getConfig(soliditySha3('proposes'), id).call();
            governance = await queryGovernanceItem(contract, id)
            console.log('governance detail',governance)

            if ((governance.voteResult.slice(0,'PROPOSE_STATUS_PASS'.length)) === 'PROPOSE_STATUS_PASS') {
                governance.status = 'Passed'
            } else if (governance.voteResult.slice(0, 'PROPOSE_STATUS_FAIL'.length) === 'PROPOSE_STATUS_FAIL') {
                governance.status = 'Failed'
            } else {
                governance.status = 'Live'
            }
            governance.position = position;
            console.log('queryGovernance creator', id, Web3.utils.numberToHex(governance.creator))
            governance.yesCount = await contract.methods.getVotes(int2hex(id, 64), asciiToHex('VOTE_YES')).call()
            governance.noCount = await contract.methods.getVotes(int2hex(id, 64), asciiToHex('VOTE_NO')).call()
            governance.cancelCount = await contract.methods.getVotes(int2hex(id, 64), asciiToHex('VOTE_CANCEL')).call()
            console.log('query governance detail---->', (int2hex(id, 64)))
            governanceList = governanceList.concat(governance)
            // console.log('query governance--->', governance,numberToHex(id),asciiToHex('VOTE_YES'))
            id = governanceID
            setGovList(governanceList)
            position++
        }
        console.log('governanceList:', governanceList)
    }

    useEffect(()=>{
        if(active){
            queryGovList()
            queryStaked()
        }
    },[active])

    return {govList, BOTStaked, govReward, govBOT}
}


export const useGovDetail = (id) =>{

    const {active, account, library, chainId} = useActiveWeb3React();
    const [gov, setGov] = useState()

    async function queryGovDetail(){
        const contract = getContract(library, bounceStake.abi, getStakingAddress(chainId))
        const governance = await queryGovernanceItem(contract, id)
        console.log('detail governance',governance)
        if ((governance.voteResult.slice(0,'PROPOSE_STATUS_PASS'.length)) === 'PROPOSE_STATUS_PASS') {
            governance.status = 'Passed'
        } else if (governance.voteResult.slice(0, 'PROPOSE_STATUS_FAIL'.length) === 'PROPOSE_STATUS_FAIL') {
            governance.status = 'Failed'
        } else {
            governance.status = 'Live'
        }
        console.log('queryGovernance creator', id, Web3.utils.numberToHex(governance.creator))
        governance.yesCount = await contract.methods.getVotes(int2hex(id, 64), asciiToHex('VOTE_YES')).call()
        governance.noCount = await contract.methods.getVotes(int2hex(id, 64), asciiToHex('VOTE_NO')).call()
        governance.cancelCount = await contract.methods.getVotes(int2hex(id, 64), asciiToHex('VOTE_CANCEL')).call()
        console.log('query governance detail', governance)
        setGov(governance)
    }

    useEffect(()=>{
        if(active){
            queryGovDetail()
        }
    },[active])

    return {gov}

}
