import React, {useState, useCallback, useEffect} from 'react';
import {t, tHTML} from '../../utils/intl';
import {weiToNumber} from '../../utils/numberTransform';
import {loadRatio, queryDutchCurTime, queryDutchCurPrice} from '../../Content/const'
import {TO} from "../const";
const BigNumber = require('bignumber.js');



export const DutchPoolItem = ({
                                symbol,
                                pool,
                                onEnd,
                                web3
                              }) => {
  const [type, setType] = useState(symbol);
  const [left, setLeft] = useState(null);
  const [leftReducer, setLeftReducer] = useState(null);
  const [curTime, setCurTime] = useState();
  const [curPrice, setCurPrice] = useState();

  const ratio = parseInt(pool.biddenAmount / pool.tokenTotal * 100);
  const style = {
    width: `${ratio}%`
  }



  let timer;
  let reducerTimer;


  const renderTime = useCallback(() => {
    if (pool.time) {
      const time = pool.time * 1000;
      const date = new Date(time);
      const now = new Date();
      const lefttime = date - now;
      let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
      let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
      let leftm = Math.floor(lefttime / (1000 * 60) % 60);
      let lefts = Math.floor(lefttime / 1000 % 60);
      const left = {
        days: leftd < 0 ? 0 : leftd,
        hours: lefth < 0 ? 0 : lefth,
        minutes: leftm < 0 ? 0 : leftm,
        seconds: lefts < 0 ? 0 : lefts,
      };
      setLeft(left);
      if (left < 0) {
        setLeft(null);
      }
    } else {
      setLeft(null);
    }
  }, [pool, setLeft, left]);


  const renderNextTime = useCallback(() => {
    if (curTime !== 0) {
      const time = curTime;
      let lefth = Math.floor(time / (60 * 60) % 24);
      let leftm = Math.floor(time / (60) % 60);
      let lefts = Math.floor(time % 60);
      console.log('setLeftReducer',lefth, leftm, lefts)
      const left = {
        hours: lefth < 0 ? 0 : lefth,
        minutes: leftm < 0 ? 0 : leftm,
        seconds: lefts < 0 ? 0 : lefts,
      };
      setLeftReducer(left);
      if (left < 0) {
        setLeftReducer(null);
      }
    } else {
      setLeft(null);
    }
  }, [curTime, setLeftReducer, leftReducer]);


  const renderLeft = useCallback(() => {
    if (left) {
      return <>
        <span>{left.days}</span>
        {` ${t('create-pool.placeholder.days')} : `}
        <span>{left.hours}</span>
        {` ${t('create-pool.placeholder.hours')} : `}
        <span>{left.minutes}</span>
        {` ${t('create-pool.placeholder.minuts')} : `}
        <span>{left.seconds}</span>
        {` ${t('create-pool.placeholder.seconds')}`}
      </>
    } else {
      return '';
    }
  }, [left]);

  const renderLeftReducer = useCallback(() => {
    console.log('leftReducer',leftReducer)
    if (leftReducer) {
      return <>
        <span>{leftReducer.hours}</span>
        {` ${t('create-pool.placeholder.hours')} : `}
        <span>{leftReducer.minutes}</span>
        {` ${t('create-pool.placeholder.minuts')} : `}
        <span>{leftReducer.seconds}</span>
        {` ${t('create-pool.placeholder.seconds')}`}
      </>
    } else {
      return '';
    }
  }, [leftReducer]);

  useEffect(() => {
    timer = setInterval(() => {
      renderTime();
    }, (1000));
    return () => {
      clearInterval(timer)
    }
  }, [renderTime]);

  useEffect(() => {
    if(web3 && pool.index){
      reducerTimer = setInterval(() => {
        const time =  curTime - 1;
        if(time === 0){
          setCurTime(curTime - 1);
          queryDutchCurTime(web3, pool.index)
              .then(r => {
                setCurTime(r)
              })
          queryDutchCurPrice(web3, pool.index)
              .then(r =>{
                console.log('queryDutchCurPrice',r)
                setCurPrice(r)
              });
          clearInterval(reducerTimer)
        }else {
          setCurTime(curTime - 1)
          renderNextTime();
        }

      }, (1000));
    }

    return () => {
      clearInterval(reducerTimer)
    }
  }, [renderNextTime, curTime, curPrice, pool]);


  useEffect(() => {
    if (web3 && pool.index) {
      queryDutchCurTime(web3, pool.index)
          .then(r => {
            setCurTime(r)
            console.log('queryDutchCurTime', r)
          })
      queryDutchCurPrice(web3, pool.index)
          .then(r =>{
            console.log('queryDutchCurPrice',r)
            setCurPrice(r)
          });
    }
  }, [pool]);

  console.log('pool.minEthPerWallet', pool.minEthPerWallet)
  return (
      <>
        <dl className='define'>
          <dt>{t('pool-item.pool-type')}</dt>
          <dd>{'NFT dutch auction'}</dd>
        </dl>

        <dl className='define'>
          <dt>{t('pool-item.participant')}</dt>
          <dt style={{display: 'flex'}}>
            <dd><span
                className='weight-grey'>{pool.password && pool.password != 0 ? 'Private' : pool.onlyBot ? 'BOT holder' : "Public"}</span>
            </dd>
          </dt>
        </dl>

        <dl className='define'>
          <dt>{'Total allocation'}</dt>
          <dd>{web3 && pool.tokenTotal && weiToNumber(pool.tokenTotal)} {pool.fromSymbol}</dd>
        </dl>

        <dl className='define'>
          <dt>{'Starting price:'}</dt>
          <dt style={{display: 'flex'}}>
            <dd><span
                className='weight-grey'>{`The starting price is 1 ${pool && pool.fromSymbol} = ${pool && pool.maxPrice && loadRatio(pool.maxPrice, pool.tokenTotal, '18', pool.decimals)} ETH`}</span>
            </dd>
          </dt>
        </dl>

        <dl className='define'>
          <dt>{'Reserve price:'}</dt>
          <dt style={{display: 'flex'}}>
            <dd><span
                className='weight-grey'>{`Reserve price is 1 ${pool && pool.fromSymbol} = ${pool && pool.minPrice  && loadRatio(pool.minPrice, pool.tokenTotal, '18', pool.decimals)} ETH`}</span>
            </dd>
          </dt>
        </dl>

        <dl className='define'>
          <dt>{'Current auction price'}</dt>
          <dt style={{display: 'flex', alignItems: 'center'}}>
            <span>{curPrice && `1 ${pool.fromSymbol} = ${weiToNumber(curPrice, pool.fromSymbol)} ETH`}</span>
            <dd style={{marginLeft: 20}}
            ><span className='weight-grey'>
              {renderLeftReducer()}
              </span>
            </dd>
          </dt>
        </dl>

        <dl className='define'>
          <dt>{tHTML('pool-item.available-allocation', {'to': pool.fromSymbol})}</dt>
          <dd className='define-row'>
            <div className='ratio'>
              <span className='back' style={style}/>
              <span className='text'>{`${pool.biddenAmount ? weiToNumber(pool.biddenAmount) : ''} ${pool.fromSymbol}`}</span></div>
            {t('pool-item.max-amount')} {pool.tokenTotal && web3.utils.fromWei(pool.tokenTotal)} {pool.fromSymbol}</dd>
        </dl>

        {/*<dl className='define'>*/}
          {/*<dt>{t('pool-item.time-left')}</dt>*/}
          {/*<dd className='define-line'>{renderLeft()}</dd>*/}
        {/*</dl>*/}
      </>
  )
}
