import { getContract, useActiveWeb3React } from "../../web3";
import { useContext, useEffect, useState } from "react";
import { myContext } from '../../reducer'

import { getLotteryErc20Address } from "../../web3/contractAddress";
import { HANDLE_SHOW_CONNECT_MODAL } from "../../const";
import LotteryERC20ABI from "../../web3/abi/BounceLotteryERC20.json";
import bounceERC20 from "../../web3/abi/bounceERC20.json";


export const usePoolDetail = (id = 0) => {
  const { active, account, library, chainId } = useActiveWeb3React();
  const { state, dispatch } = useContext(myContext);

  const [name, setName] = useState(null)
  const [address, setAddress] = useState(null)
  const [isLive, setIsLive] = useState()
  const [time, setTime] = useState();
  const [price, setPrice] = useState('');
  const [winner, setWinner] = useState();
  const [participate, setParticipate] = useState();
  const [isMine, setIsMine] = useState(false);
  const [claimed, setClaimed] = useState(true);
  const [isWinner, setIsWinner] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [pool, setPool] = useState();
  const [curPlayer, setCurPlayer] = useState()
  const [symbol, setSymbol] = useState()
  const [toSymbol, setToSymbol] = useState()
  const [toDecimals, setToDecimals] = useState()




  const [playStatus, setPlayStatus] = useState()

  async function getLotteryERC20Detail() {
    try {
      const lotteryERC20Contract = getContract(library, LotteryERC20ABI.abi, getLotteryErc20Address(chainId));

      const poolRes = await lotteryERC20Contract.methods.pools(parseInt(id) - 1).call();
      console.log('poolRes--->', poolRes)

      const tokenContract = getContract(library, bounceERC20.abi, poolRes.token0)
      tokenContract.methods.symbol().call().then((res) => {
        console.log('query fs symbol:', res)
        setSymbol(res)
      })


      const tokenContract1 = getContract(library, bounceERC20.abi, poolRes.token1)
      tokenContract1.methods.symbol().call().then((res) => {
        console.log('symbol', res)
        setToSymbol(res)
      })

      tokenContract1.methods.decimals().call().then((res) => {
        setToDecimals(res)
      })

      setPool(poolRes)

      const info = await lotteryERC20Contract.methods.getLotteryPoolInfo(id).call();
      setName(info.name);
      setAddress(info.token0);
      console.log(info);

      const isLive = new Date(info.closeTime * 1000).getTime() > new Date().getTime();
      console.log(new Date().getTime())
      setIsLive(isLive);
      setTime(info.closeTime);
      setWinner(info.nShare);
      setPrice(info.amount1);
      setParticipate(info.maxPlayer);


      const playStatusConst = await lotteryERC20Contract.methods.getPlayerStatus(id).call({ from: account });
      console.log('playStatusConst', playStatusConst)
      //返回1表示调用者是池子创建者并且未收割
      //返回5表示调用者是池子创建者并且已收割
      //返回2表示调用者是池子参与者并且未收割
      //返回6表示调用者是池子参与者并且已收割
      setPlayStatus(playStatusConst);
      switch (playStatusConst) {
        case '0':
          setIsJoined(false);
          break;
        case '1':
          setIsMine(true);
          setIsJoined(true);
          setClaimed(false)
          break;
        case '2':
          setIsJoined(true);
          setClaimed(false)
          break;
        case '5':
          setIsJoined(true);
          setIsMine(true);
          setClaimed(true);
          break;
        case '6':
          setIsJoined(true);
          setClaimed(true);
          break;
      }

      const winnerState = await lotteryERC20Contract.methods.isWinner(id, account)
        .call();
      setIsWinner(winnerState);
    } catch (e) {
      console.log('getTokenInfo:', e)
    }
  }
  useEffect(() => {
    if (active) {
      getLotteryERC20Detail()
    } else {
      dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
    }
  }, [active])


  return {
    name, address, isLive, time, price, winner, participate, playStatus, claimed, isMine, isWinner, isJoined, pool, curPlayer, symbol, toDecimals, toSymbol
  }
}
