import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Return} from './Return';
import {
  queryMyNFTPool,
  getTransactionLink,
  checkNFTPoolStatus,
  checkNFTCreatorClaimStatus,
  checkNFT1155PoolStatus, checkNFT1155CreatorClaimStatus, queryMyNFT1155Pool, queryMyEAPool, checkEAPoolStatus,
  checkEACreatorClaimStatus
} from './const';
import '../static/image/success.svg';
import {EAPool} from './EAPool';
import {WaitContent} from '../components/common/WaitContent';
import {RewardSuccess} from './content/RewardSuccess';
import {RewardFailed} from './content/RewardFailed';
import {DeniedContent} from './content/DeniedContent';
import {myContext} from '../reducer'
import {useHistory} from 'react-router-dom';
import failedIcon from '../static/image/error.svg';
import {t, tHTML} from "../utils/intl";
import {
  getDutchAuctionERC1155, getDutchAuctionNFT, getDutchAuctionV1,
  getEnglishAuctionContract
} from "../components/utils/web3";


export const EAPoolContent = ({
                                symbol,
                                account,
                              }) => {
  const {state, dispatch} = useContext(myContext);
  const history = useHistory();
  const [pool, setPool] = useState();
  const [poolStatus, setPoolStatus] = useState('Live');
  const [status, setStatus] = useState('init');
  const [hash, setHash] = useState('');
  const [isFilled, setIsFilled] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);


  const {web3, myAccount, curPoolType, hasNFTPool, hasNFT1155Pool} = state;


  useEffect(() => {
    async function loadAddress() {
      const address = await getTransactionLink(pool ? pool.fromAddress : '')
      setAddress(address);
    }

    loadAddress()
  }, [hash]);

  useEffect(() => {
    if (status === 'success') {
      dispatch({type: 'HANDLER_HAS_BID_POOL', hasBidPool: false});
    }
  }, [status]);

  const onChangeRound = () => {
    setLoading(false);
    if (myAccount && web3) {
      queryMyEAPool(web3, myAccount)
          .then(r => {
            if (!r) {
              console.log('has no nft pool:', r)
              setLoading(false)
              setIsExpired(false)
            } else {
              console.log('has nft pool:', r)
              setPool(r);
              setLoading(false)
              checkEAPoolStatus(web3, r.index)
                  .then(status => {
                    console.log('my nft pool status', status)
                    if (status) {
                      setIsExpired(false);
                    } else {
                      checkEACreatorClaimStatus(web3, account, r.index)
                          .then(isClaim => {
                            if (isClaim) {
                              setIsExpired(false);
                            } else {
                              setIsExpired(true);
                            }
                          })
                    }
                  })

            }
          })
          .catch(e => {
            setLoading(false)
            console.log('check my pool status error', e);
          })
    }
  }

  useEffect(() => {
    if (myAccount && web3) {
      queryMyEAPool(web3, myAccount)
          .then(r => {
            if (!r) {
              console.log('has no nft pool:', r)
              setLoading(false)
              setIsExpired(false)
            } else {
              console.log('has nft pool:', r)
              setPool(r);
              setLoading(false)
              checkEAPoolStatus(web3, r.index)
                  .then(status => {
                    console.log('my nft pool status', status)
                    if (status) {
                      setIsExpired(false);
                    } else {
                      checkEACreatorClaimStatus(web3, account, r.index)
                          .then(isClaim => {
                            if (isClaim) {
                              setIsExpired(false);
                            } else {
                              setIsExpired(true);
                            }
                          })
                    }
                  })

            }
          })
          .catch(e => {
            setLoading(false)
            console.log('check my pool status error', e);
          })

    }
  }, [setIsFilled, setPool, myAccount, web3]);


  const handleClaim = async () => {
    const nft = await getEnglishAuctionContract(web3);
    setStatus('waiting');
    try {
      nft.methods.creatorClaim(pool.index)
          .send({from: myAccount})
          .then(r => {
            dispatch({type: 'hasNFTEnglishAuctionPool', hasNFTPool: false});
            setStatus('success');
            setHash(r.transactionHash);
          })
          .catch(err => {
            console.log('err--->', err)
            if (err.code === 4001) {
              setStatus('denied');
            } else {
              setStatus('failed');
            }
          })
    } catch (err) {
      console.log('claim error',err)
      if (err.code === 4001) {
        setStatus('denied');
      } else {
        setStatus('failed');
      }
    }
  }


  const handleClose = useCallback(() => {
    history.push('/list/english-auction-pool')
    setStatus('init');
  }, [setStatus]);


  const renderPool = useCallback(() => {
    if (status === 'init') {
      if (isExpired) {
        return (
            <div className='content-box'>
              <img src={failedIcon} alt='expired'/>
              {tHTML('expired.title')}
              <p>{t('expired.message')}</p>
              <span className='button' onClick={handleClaim}>{t('buttons.claim-token')}</span>
            </div>)
      } else {
        return <EAPool
            onStatusChange={setStatus}
            symbol={symbol}
            pool={pool}
            onHashChange={setHash}
            account={myAccount}
            loading={loading}
            onRoundChange={() => onChangeRound()}
        />
      }

    } else {
      if (status === 'waiting') {
        return <WaitContent content={true}/>
      } else if (status === 'success') {
        return <RewardSuccess link={address}
                              onClose={handleClose}/>
      } else if (status === 'failed') {
        return <RewardFailed onClose={handleClose}/>
      } else if (status === 'denied') {
        return <DeniedContent onClose={handleClose}/>
      }
    }
  }, [isExpired, isFilled, pool, account, symbol,
    handleClose, hash, status, setStatus
  ]);

  return (
      <div className='content join'>
        <Return type={curPoolType}/>
        <div className='form'>
          {renderPool()}
        </div>
      </div>
  )
}
