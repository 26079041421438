import { isEqualTo } from "../../../utils/common"
import { weiToNum, weiDiv } from "../../../utils/numberTransform"

export const getPoolStatus = (time, amount1, amount2) => {
    if (!time) return 'Error'

    const nowTime = new Date().getTime()
    const thisTime = new Date(time * 1000).getTime()
    if (nowTime < thisTime) {
        if ((amount1 && amount2) && (amount1 === amount2)) return 'Filled'
        return 'Live'
    } else {
        return 'Closed'
    }
}

export const getParticipant = (password, onlyBot) => {
    if (password !== '0') {
        return 'Private'
    } else if (onlyBot === true) {
        return 'BOT Holders'
    } else {
        return 'public'
    }
}

export const getSwapRatio = (fromAmount, toAmount, fromDecimals, toDecimals) => {
    const token1 = weiToNum(fromAmount, fromDecimals)
    const token2 = weiToNum(toAmount, toDecimals)

    const ratio = Number(weiDiv(token1, token2, 4)) || '<0.0001'
    return ratio
}

export const getProgress = (fromAmount, fromBidAmount) => {
    const plan = weiDiv(fromBidAmount, fromAmount)

    return parseFloat(plan) * 100 + '%'
}

export const getDAPrice = (price, amount) => {
    const num = Number(Number(parseFloat(weiDiv(price, amount)).toFixed(4)))
    return num || '<0.0001'
}

export const getNFTPoolStatus = (time, isSwap) => {
    let status = 'Error'
    if (isSwap === true) {
        status = 'Filled';
    } else {
        const newTime = new Date().getTime()
        const tarTime = new Date(time * 1000).getTime()

        if (newTime < tarTime) {
            status = 'Live';
        } else {
            status = 'Closed';
        }
    }
    return status
}

export const getPoolType = (nftType) => {
    switch (nftType) {
        case '0':
            return 'ERC721'
        case '1':
            return 'ERC1155'
        default:
            return 'typeError'
    }
}

export const getNFTPrice = (amount, decimals) => {
    const price = Number(parseFloat(weiToNum(amount, decimals)).toFixed(4))
    return price || '0'
}

export const getPriceRange = (minAmount, maxAmount, decimals) => {
    const price1 = Number(parseFloat(weiToNum(minAmount, decimals)).toFixed(2))
    const price2 = Number(parseFloat(weiToNum(maxAmount, decimals)).toFixed(2))
    return `${price1}~${price2}`
}

export const weiToNumber = (wei, decimals = 18, fixed = 4) => {
    const num = weiToNum(wei, decimals, fixed)
    return Number(num) + ''
}

export const getLTParticipants = (p1 = 0, p2 = 0) => {
    return `${p1}/${p2}`
}

export const getPREDStatus = (duration, closeAt) => {
    let status = 'Error'
    duration = duration * 1000
    closeAt = closeAt * 1000
    const now = new Date();
    const startAt = closeAt - duration
    const pastTime = now - startAt
    if ((duration - 2 * 60 * 60 * 1000) >= pastTime) {
        status = 'Live'
    } else if (duration > pastTime && pastTime > (duration - 2 * 60 * 60 * 1000)) {
        status = 'Settlement'
    } else {
        status = 'Closed'
    }
    return status
}

export const getSVPoolStatus = (enabled, createAt, time, toBidAmount, toAmount) => {
    let status = 'Error'
    try {
        const date = new Date(createAt * 1000 + 48 * 60 * 60 * 1000);
        const now = new Date();
        const lefttime = date - now;
        if (enabled) {
            const isExpired = new Date(time * 1000) <= Date.now();
            const swap = toBidAmount;
            const amount = toAmount;
            const isFilled = isEqualTo(swap, amount)
            if (!isExpired) {
                status = 'Live';
            } else {
                status = 'Closed';
            }
            if (isFilled) {
                status = 'Filled'
            }
        } else if (!enabled && lefttime > 0) {
            status = 'Pending'
        } else if (!enabled && lefttime <= 0) {
            status = 'Unsuccessfully Closed'
        }
    } catch (error) {
        return 'Error'
    }
    return status
}

