import React, { useEffect, useState } from 'react'
import { useActiveWeb3React } from "../../web3";

export const useRequestUrl = () => {
    const [Url, setUrl] = useState(null)
    const { chainId } = useActiveWeb3React();

    useEffect(() => {
        switch (chainId) {
            case 1:
                setUrl('https://account.bounce.finance:16000/api/boudata')
                break;
            case 56:
                setUrl('https://account.bounce.finance:16000/bsc/api/boudata')
                break;
            case 97:
                setUrl('https://account.bounce.finance:16000/test/api/boudata')
                break;
            default:
                // setUrl('https://account.bounce.finance:16000/test/api/boudata')
                break;
        }
    }, [chainId])

    return Url
}


export const useRequestParams = (type) => {
    // return { "method": "query_eth_price", "id": 1, "jsonrpc": "2.0", "params": [] }
    return { "method": type, "id": 1, "jsonrpc": "2.0", "params": [] }
}
