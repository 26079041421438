import styled from "styled-components";

export const StakingFrame = styled.div`
display: flex;
justify-content: space-between;
  background-color: #fff;
  height: ${({height}) => (height ? height : '400px')};
  width: ${({width}) => (width ? width : '100%')};
  padding: ${({padding}) => (padding ? padding : 0)};
  @media (max-width: 767px) {
    height: auto;
  }
`



export const Action = styled.div`
   width: 1280px;
   height: 633px;
   background-color: #fff;
   padding: 30px 104px;
   display: flex;
   display: flex;
   flex-direction: column;
   position: relative;
   @media (max-width: 767px) {
    width: 100%;
  }
`

Action.Close = styled.img`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
`

Action.Title = styled.div`
  font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 44px;
border-bottom: 4px solid #000;
padding-bottom: 16px;
margin-bottom: 52px;
`

Action.Frame = styled.div`
  flex: 1;
  display: flex;
`

Action.MetaFrame = styled.div`
  width: 560px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 38px 0 32px;
`

Action.Meta = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(31, 25, 27, 1);
  span:nth-child(1){
      color: ${({theme}) => (theme.grey5)};
  }
  
`
