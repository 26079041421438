import React from 'react';
import { t } from '../utils/intl';
import { Link } from 'react-router-dom';
import icon from '../static/image/arrow-left.svg';

export const Return = (type) => {
  return (
    <div className='return'>
      <Link to={`${type === 0? '/list/swap': type === 1 ? '/list/bid': type === 2 ?'/list/dutch': type === 3? '/list/nft': type === 4?  '/list/english-auction-pool': '/list/sealed-bid-nft-auction-pool'}`}><img src={icon} alt='return' /> {t('buttons.back')}</Link>
    </div>
  )
}
