import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Button} from "../common/Button";
import {getContract, useActiveWeb3React} from "../../web3";
import {
    CreateModal,
    initStatus,
    approveStatus,
    pendingStatus,
    confirmStatus,
    successStatus,
    errorStatus,
    cancelStatus
} from "../common/CreateModal";
import {useHistory} from 'react-router-dom'
import {getSVFSAddress} from "../../web3/contractAddress";
import {numToWei} from "../../utils/numberTransform";
import md5 from "js-md5";
import SVBounce from "../../web3/abi/SVBounce.json";
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import BigNumber from 'bignumber.js'
import Web3 from "web3";

const {BN} = Web3.utils


const Frame = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    width:100%;
    padding: 0 20px;
  }
`
const Title = styled.div`
  font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 32px;
color: #000;
`

const PoolMeta = styled.div`
  font-size: 16px;
  width: 480px;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    width:100%;
    padding: 0 20px;
  }
`

PoolMeta.Title = styled.span`
  font-family: Optima;
font-style: normal;
font-weight: bold;
line-height: 19px;
color: black;
border-bottom: 4px solid #000;
padding-bottom: 16px;
margin: 48px 0 0;
`

PoolMeta.SubTitle = styled.span`
font-family: IBM Plex Mono;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
color: rgba(0, 0, 0, 0.4);
margin-top: 32px;
`

PoolMeta.Content = styled.span`
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1F191B;
  margin-top: 12px;
`

const SwapFrame = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
`

const SwapMeta = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: 32px;
  @media (max-width: 767px) {
    width:100%;
  }
`

SwapMeta.Title = styled.span`
  font-family: IBM Plex Mono;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
color: rgba(31, 25, 27, 0.5);
`

SwapMeta.Content = styled.span`
  font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 27px;
color: rgba(31, 25, 27, 1);
padding: 8px 0 24px;
border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`


export const Step3 = ({projectInfo, poolInfo, visible}) => {

    const {active, account, library, chainId} = useActiveWeb3React();
    const [modalStatus, setModalStatus] = useState(initStatus)
    const history = useHistory()


    const onFSCreate = async () => {
        console.log('on Create name', projectInfo.name)
        console.log('on Create from address', poolInfo.fromAddress)
        console.log('on Create to address', poolInfo.toAddress)
        console.log('on Create time', poolInfo.time)
        console.log('on Create from  amount', numToWei(poolInfo.amount, poolInfo.decimals))
        console.log('on Create to amount', numToWei((new BigNumber(poolInfo.amount).div(new BigNumber(poolInfo.ratio))), poolInfo.ToDecimals))


        const info = JSON.stringify({description: projectInfo.describe, link: projectInfo.link})
        const name = projectInfo.name
        const fromAddress = poolInfo.fromAddress
        const toAddress = poolInfo.toAddress
        const fromAmount = numToWei(poolInfo.amount, poolInfo.decimals);
        const toAmount = numToWei((new BigNumber(poolInfo.amount).div(new BigNumber(poolInfo.ratio))), poolInfo.ToDecimals);
        const time = poolInfo.time
        const onlyBOT = poolInfo.onlyBOT
        const limit = poolInfo.limit

        const abi = SVBounce.abi;
        const bounceAddress = getSVFSAddress(chainId)
        const createFunc = 'fixPoolCreateBySocial'
        const tokenContract = getContract(library, bounceERC20.abi, poolInfo.fromAddress)
        const bounceContract = getContract(library, abi, bounceAddress)
        const password = poolInfo.password? new BN(md5(poolInfo.password)).toString(): 0
        console.log('on crate data', fromAmount, toAmount)

        try {
            //const allowance = await tokenContract.methods.allowance(account, getSVFSAddress(chainId)).call()
            //console.log('allowance', allowance, new BigNumber(fromAmount).minus(allowance).toString())
            setModalStatus(confirmStatus);
            await tokenContract.methods.approve(
                bounceAddress,
                0,
            )
                .send({from: account});
            setModalStatus(approveStatus);
            await tokenContract.methods.approve(
                bounceAddress,
                fromAmount,
            )
                .send({from: account});


            setModalStatus(confirmStatus);
            await bounceContract.methods[createFunc](
                info,
                name,
                fromAddress,
                toAddress,
                fromAmount,
                toAmount,
                time,
                onlyBOT,
                password,
                limit
            )
                .send({from: account})
                .on('transactionHash', hash => {
                    setModalStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    window.localStorage.setItem('BOUNCE_POOL_AUCTION', '1')
                    window.localStorage.setItem('BOUNCE_POOL_TYPE', '0')
                    window.localStorage.setItem('BOUNCE_SALE_TYPE', '0')
                    setModalStatus(successStatus)
                })
                .on('error', (err, receipt) => {
                    setModalStatus(errorStatus)
                })
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    };


    return (
        <Frame style={{display: visible ? 'flex' : 'none'}}>
            <Title>03. Review & Confirmation</Title>

            <PoolMeta>
                <PoolMeta.Title>Project Description</PoolMeta.Title>

                <PoolMeta.SubTitle>Project Name:</PoolMeta.SubTitle>
                <PoolMeta.Content>{projectInfo && projectInfo.name}</PoolMeta.Content>

                <PoolMeta.SubTitle>Project description:</PoolMeta.SubTitle>
                <PoolMeta.Content>{projectInfo && projectInfo.describe}</PoolMeta.Content>

                <PoolMeta.SubTitle>Project Website:</PoolMeta.SubTitle>
                <PoolMeta.Content>{projectInfo && projectInfo.link}</PoolMeta.Content>


                <PoolMeta.Title>Pool settings</PoolMeta.Title>

                <PoolMeta.Content>Participant: {poolInfo && poolInfo.onlyBOT ? 'only BotHolder' : 'Public'}</PoolMeta.Content>

                <PoolMeta.SubTitle>{poolInfo && poolInfo.fromAddress}</PoolMeta.SubTitle>

                <SwapFrame>

                    <SwapMeta>
                        <SwapMeta.Title>Amount</SwapMeta.Title>
                        <SwapMeta.Content>{`${poolInfo && poolInfo.amount} ${poolInfo && poolInfo.fromSymbol}`}</SwapMeta.Content>
                    </SwapMeta>

                    <SwapMeta>
                        <SwapMeta.Title>Fixed Swap Ratio</SwapMeta.Title>
                        <SwapMeta.Content>1 {poolInfo && poolInfo.toSymbol} = {poolInfo && poolInfo.ratio} {poolInfo && poolInfo.fromSymbol}</SwapMeta.Content>
                    </SwapMeta>

                    {/*<SwapMeta>*/}
                    {/*    <SwapMeta.Title>Price,$</SwapMeta.Title>*/}
                    {/*    <SwapMeta.Content>1 ETH = 0.05 BOT</SwapMeta.Content>*/}
                    {/*</SwapMeta>*/}

                    <SwapMeta>
                        <SwapMeta.Title>Maximum Allocation per wallet</SwapMeta.Title>
                        <SwapMeta.Content>{poolInfo && poolInfo.limit} {poolInfo && poolInfo.toSymbol}</SwapMeta.Content>
                    </SwapMeta>
                </SwapFrame>
            </PoolMeta>
            <Button onClick={onFSCreate} style={{marginTop: 38}} black>Launch Project</Button>

            <CreateModal onOK={() => {
                setModalStatus(initStatus)
                history.push('/')
            }} onDismiss={() => {
                setModalStatus(initStatus)
            }} modalStatus={modalStatus}/>
        </Frame>
    )
}
