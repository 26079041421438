import { makeStyles } from '@material-ui/core';
import React from 'react';
import { logo } from '../../static/image/logo-1.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '64px 0',
    backgroundColor: '#fff',
  },
  content: {
    width: '100%',
    maxWidth: 912,
    margin: '0 auto',
    [`@media (max-width: 767px)`]: {
      width: 'calc(100vw - 40px)',
    },
  },
  title: {
    fontFamily: 'Optima',
    fontWeight: 'bold',
    fontSize: 36,
    lineHeight: '44px',
    color: '#000',
    paddingBottom: 24,
    borderBottom: '6px solid #000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 56,
    [`@media (max-width: 767px)`]: {
      fontSize: 26,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  item: {
    paddingLeft: 56,
    fontFamily: 'Helvetica Neue',
    fontWeight: 500,
    fontSize: 14,
    color: '#000',  
    width: 400,
    flex: '0 0 auto',
    height: 52,
    lineHeight: '32px',
    marginBottom: 20,
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    backgroundImage: `url(${require('../../static/image/logo-1.svg')})`,
    backgroundSize: '26px 26px',
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    [`@media (max-width: 767px)`]: {
      width: '100%',
      overflow: 'hidden',
      textOverflow:'ellipsis',
      whiteSpace: 'nowrap',
    },
  }
}));

const data = [
  '0xdD981b2a5c8B63a225f8bb436d8dE2bA9b121924', 
  '0xE37E8F1EE43D4F29E015e6A42fA3156115790a7E',
  '0xb0325DbE7fA891436E83A094f9F12848c78e449b',
  '0xDc8D6300ed8a038BaA494bADF4a76338a9a668CF',
  '0x5a28E5897b19940A488c768D5f91f17F940F9332',
  '0x64152ededa3927e7b437e0c720f48a95da41a580',
  '0x9f26964717F38b47BcBb450118E913d8649E8cEA',
  '0x1eBef03483030dd746c0BBa924E828Ce2C0534ea',
  '0x5b97680e165B4DbF5C45f4ff4241e85F418c66C2'
]

const Vault = ({}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.title}>
          <span>{'Signers:'}</span>
          <span>{data.length}</span>
        </div>
        <div className={classes.row}>
          {data.map((item, index) => <div className={classes.item} key={index}>
            {item}
            </div>)}
        </div>
      </div>
    </div>
  )
}

export { Vault }