import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CreateFrame } from "./CreateFrame";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { useActiveWeb3React, getContract } from "../../web3";
import bounceFactory from '../../components/utils/bounceFactory.json'
import { getBounceFactoryAddress, getBotAddress } from "../../web3/contractAddress"
import bounceERC20 from "../../web3/abi/bounceERC20.json"
import { numToWei } from '../../utils/numberTransform';
import {
    CreateModal,
    initStatus,
    pendingStatus,
    successStatus,
    errorStatus,
    confirmStatus
} from "../../components/common/CreateModal";

export const StoreCreate = () => {
    const { active, account, library, chainId } = useActiveWeb3React();
    const history = useHistory()
    const [modalStatus, setModalStatus] = useState(initStatus)
    const [curTab, setCurTab] = useState(0)
    const [projectInfo, setProjectInfo] = useState()
    const [poolInfo, setPoolInfo] = useState()


    useEffect(() => {
        if (!active || !poolInfo) return
        createStore()
    }, [active, poolInfo])

    const createStore = async () => {
        const bounceFac = getContract(library, bounceFactory.abi, getBounceFactoryAddress(chainId))
        const contractERC20 = getContract(library, bounceERC20.abi, getBotAddress(chainId));
        const storeType_tem = poolInfo.storeType.map((item, index) => {
            if (item) {
                return index
            } else {
                return false
            }
        })

        const storeType = storeType_tem.filter(item => {
            return item !== false
        })

        const info = JSON.stringify({ ...projectInfo, ...poolInfo, storeType })
        try {
            // 创建合约需要质押 0.5个BOT
            const result = await contractERC20.methods.approve(
                getBounceFactoryAddress(chainId),
                numToWei(0.2, 18),
            )
                .send({ from: account })
            setModalStatus(confirmStatus);
            if (result.status) {
                bounceFac.methods.createStore(info, storeType)
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            }
        } catch (error) {
            console.log('B_console', error)
            setModalStatus(errorStatus)
        }

    }

    const steps = [
        {
            key: 0, title: '01. Store Information', view: (<Step1 visible={curTab === 0} next={(projectInfo) => {
                setCurTab(1)
                setProjectInfo(projectInfo)
            }} />)
        },
        {
            key: 1, title: '02. Tokens Types', view: <Step2 visible={curTab === 1} next={(poolInfo) => {
                setPoolInfo(poolInfo)
                setModalStatus(confirmStatus);
            }} />
        }
    ]


    return (
        <>
            <CreateFrame steps={steps} curTab={curTab} setCurTab={setCurTab} projectInfo={projectInfo} poolInfo={poolInfo} />
            <CreateModal onOK={() => {
                setModalStatus(initStatus)
                history.goBack()
            }} onDismiss={() => {
                setModalStatus(initStatus)
            }} modalStatus={modalStatus} />
        </>
    )
}