import React, { useState, useEffect } from 'react';
import { Pool, PoolFrame } from "../../components/common/Layout";
import icon_close from '../../static/image/icon-close.svg'
import { Form, FormStatus, Input } from "../../components/common/Form";
import { validateForm } from "../../utils/form";

import icon_eye_open from '../../static/image/icon-eye-open.svg'
import icon_eye_close from '../../static/image/icon-eye-close.svg'
import { useParams } from 'react-router-dom';
import { useHandleForm, useTokenList } from '../../web3/common'
import { Button } from "../../components/common/Button";
import { numToWei, weiToNum } from "../../utils/numberTransform";
import md5 from "js-md5";
import Web3 from 'web3'
import { useHistory } from 'react-router-dom'
import { getTime, isGreaterThan } from "../../utils/common";
import { getContract, useActiveWeb3React } from "../../web3";
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import bounceERC721 from "../../web3/abi/bounceERC721.json";
import bounceERC1155 from "../../web3/abi/bounceERC1155.json";
import bounceSealedBidNFT from "../../web3/abi/bounceSealedBidNFT.json";
import bounceDutchAuctionNFT from "../../web3/abi/bounceDutchAuctionNFT.json";
import bounceDutchAuctionNFT1155 from "../../web3/abi/BounceDutchAuctionErc1155.json";

import {
    getFixSwapAddress,
    getSealedBidAddress,
    getSealedBidNFTAddress,
    getDutchAuction721,
    getDutchAuction1155,
} from "../../web3/contractAddress";
import fixSwap from "../../web3/abi/bounce.json";
import sealedBid from "../../web3/abi/bounceSealedBid.json";
import icon_helper from '../../static/image/icon-helper.svg';
import { useIsXSDown } from '../../components/utils/themeHooks';

import {
    CreateModal,
    initStatus,
    approveStatus,
    pendingStatus,
    confirmStatus,
    successStatus,
    errorStatus,
    cancelStatus
} from "../../components/common/CreateModal";
import { HelperLayout } from "../../components/common/HelperLayout";
import { PoolCover } from "../../components/DetailCover";
import rect_logo from "../../static/image/rect-logo.svg";
import { Select } from "../../components/common/Select";
import { AuctionTipModal } from "../../components/common/AuctionTipModal";
import Modal from "../../components/common/Modal";
import { ZERO_ADDRESS } from '../../Content/const';

const BigNumber = require('bignumber.js');
const { toWei, BN } = Web3.utils


export const CreateDANFTPool = () => {
    const history = useHistory()
    const isXSDown = useIsXSDown();
    const { account, library, chainId } = useActiveWeb3React();
    const tokenOptions = useTokenList()
    const [modalStatus, setModalStatus] = useState(initStatus)

    const [isPrivate, setIsPrivate] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [hasLimit, setHasLimit] = useState(false)
    const [selectedToken, setSelectedToken] = useState(tokenOptions[0])
    const [isDutchNFT1155, setIsDutchNFT1155] = useState(false)
    const [showTip, setShowTip] = useState()

    const [errors, setErrors] = useState({ address: '', ratio: '', amount: '', limit: '' })


    const {
        address, setAddress, addressError,
        token,
        amount, setAmount, amountError,
        ratio, setRatio, ratioError,
        level,
        name, setName, nameError,
        onlyBot, setOnlyBot,
        password, setPassword,
        limit, setLimit,
        days, setDays,
        hours, setHours,
        minutes, setMinutes,
        timeError,
        check, setCheck,
        nftType, setNFTType,
        nftID, setNFTID, idError, setIDError,
        nft,
        minAmount, setMinAmount,
        maxAmount, setMaxAmount,
        times, setTimes
    } = useHandleForm()

    const onSB721Create_DA = async () => {
        console.log('onSB721Create', name, address)
        if (!name || !address) {
            return;
        }
        const bounceAddress = getDutchAuction721(chainId)
        const tokenContract = getContract(library, bounceERC721.abi, address)
        const bounceContract = getContract(library, bounceDutchAuctionNFT.abi, bounceAddress)
        const time = getTime(days, hours, minutes);
        setModalStatus(approveStatus);
        try {
            const result = await tokenContract.methods.approve(
                bounceAddress,
                nftID,
            )
                .send({ from: account });
            setModalStatus(confirmStatus);
            console.log(
                'dutch_data',
                name,
                address,
                nftID,
                toWei(maxAmount),
                toWei(minAmount),
                times,
                time,
                onlyBot,
                !password || password === '' ? 0 : new BN(md5(password)).toString())
            if (result.status) {
                await bounceContract.methods.create(
                    name,
                    address,
                    nftID,
                    toWei(maxAmount),
                    toWei(minAmount),
                    times,
                    time,
                    onlyBot,
                    !password || password === '' ? 0 : new BN(md5(password)).toString(),
                    // test 0x49963648B69a9d318e7CA9F36d2b890d0fef9b5f 3 10 1 5 90060 true 0
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            } else {
                setModalStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    };

    const onSB1155Create_DA = async () => {
        setCheck(!check)
        if (!name || !address) {
            return;
        }
        const bounceAddress = getDutchAuction1155(chainId)
        const tokenContract = getContract(library, bounceERC1155.abi, address)
        const bounceContract = getContract(library, bounceDutchAuctionNFT1155.abi, bounceAddress)
        const time = getTime(days, hours, minutes);

        setModalStatus(approveStatus);
        try {
            const result = await tokenContract.methods.setApprovalForAll(
                bounceAddress,
                true,
            )
                .send({ from: account });
            setModalStatus(confirmStatus);

            console.log('dutch_data',
                name,
                address,
                nftID,
                amount,
                [toWei(maxAmount), toWei(minAmount)],
                times,
                time,
                onlyBot,
                !password || password === '' ? 0 : new BN(md5(password)).toString())

            if (result.status) {
                await bounceContract.methods.createV2(
                    name,
                    address,
                    ZERO_ADDRESS,
                    nftID,
                    amount,
                    maxAmount,
                    minAmount,
                    times,
                    time,
                    onlyBot,
                    !password || password === '' ? 0 : new BN(md5(password)).toString(),
                )
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        history.push('/')
                        setModalStatus(successStatus)
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus)
                    })
            } else {
                setModalStatus(errorStatus)
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus)
            } else {
                setModalStatus(errorStatus)
            }
            console.log('err', err);
        }
    };

    const handelCheckMinAmount = (minPrice, maxPrice) => {
        const max = parseFloat(maxPrice) || 0
        const min = parseFloat(minPrice) || 0
        if ((max !== 0 && min !== 0) && min >= max) {
            setErrors({ ...errors, minAmount: 'The Dutch starting price must be higher than the final price' })
        } else {
            setErrors({ ...errors, minAmount: '' })
        }
    }

    useEffect(() => {
        setNFTType('nft-721')
    }, [])

    return (
        <PoolFrame style={{ marginTop: 32, padding: isXSDown ? '40px 20px' : '40px 100px' }}>
            <Pool.Close onClick={() => {
                history.goBack()
            }} src={icon_close} />
            <Pool>
                <Pool.Mode style={{ textAlign: 'left' }}>Initial Token Offering</Pool.Mode>
                <Pool.Header style={{
                    justifyContent: 'space-between',
                    borderBottom: '4px #000 solid',
                    fontSize: isXSDown ? '26px' : '36px',
                }}>{'Create a Dutch NFT Auction'}
                    <Button
                        height={'40px'}
                        width={'184px'}
                        onClick={() => {
                            window.open('https://docs.bounce.finance', '_blank')
                        }}
                    >How to Create a pool</Button>
                </Pool.Header>
                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div width={isXSDown ? '100%' : '480px'} style={{ height: '100%' }}>
                        Contract information
                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                        <Form
                            type='radio'
                            prefix={(
                                <>
                                    <label>
                                        <input onChange={(e) => {
                                            setNFTType('nft-721')
                                            setIsDutchNFT1155(false)
                                        }} type='radio' name='nft-type' defaultChecked={true} />
                                        <i></i>
                                        {` erc721`}
                                    </label>
                                    <label style={{ marginLeft: 80, marginRight: 34 }}>
                                        <input onChange={() => {
                                            setNFTType('nft1155')
                                            setIsDutchNFT1155(true)
                                        }} type='radio' name='nft-type' />
                                        <i></i>
                                        {` erc1155`}
                                    </label>
                                </>
                            )} name={<span style={{ marginBottom: 9 }}>Token Type</span>} />

                        <Form top={'38px'}
                            error={addressError}
                            input={<Input
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                    console.log('address blur:', e.target.value)
                                }} />} name={'Token Contract address'} />

                        {
                            <Form top={'38px'}
                                error={idError}
                                input={<Input
                                    onFocus={(e) => {
                                        setNFTID(e.target.value)
                                    }}
                                    onChange={(e) => {
                                        setNFTID(e.target.value)
                                        console.log('nft id:', e.target.value)
                                    }} />} name={isDutchNFT1155 ? 'ERC-1155 Token ID' : 'ERC-721 Token ID'} />}

                        {isDutchNFT1155 &&
                            <Form top={'38px'}
                                input={<Input
                                    onChange={
                                        (e) => {
                                            setAmount(e.target.value)
                                        }
                                    }
                                />} name={'Amount of token'} />}

                    </Pool.Content>
                </Pool.Frame>

                <Pool.Divider />

                <Pool.Frame style={{
                    flexDirection: isXSDown ? 'column' : 'initial',
                }}>
                    <div style={{ width: isXSDown ? '100%' : 480, height: '100%' }}>
                        Pool settings
                        {
                            <Pool.Content width={isXSDown ? '100%' : '512px'} style={{ marginTop: 48 }}>
                                <PoolCover cover={nft && (nft.cover ? nft.cover : rect_logo)}></PoolCover>
                            </Pool.Content>
                        }

                    </div>
                    <Pool.Content width={isXSDown ? '100%' : '480px'}>
                        <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '480px'}>
                            <Form disabled
                                input={<Input disabled style={{ height: 36 }} value={isDutchNFT1155 ? 'ERC1155_TOKEN' : token.symbol} />}
                                name={'From'}
                                width={isXSDown ? '100%' : '213px'}
                                height={'36px'}
                            />
                            <Form name={'To'}
                                width={isXSDown ? '100%' : '213px'}
                                input={<Select
                                    disabled
                                    border
                                    width={'213px'}
                                    options={tokenOptions}
                                    onSelect={(value) => {
                                        console.log('selected', value)
                                        setSelectedToken(value)
                                    }} defaultValue={tokenOptions[0]} />} />

                        </Pool.Content>

                        <Form top={'38px'} input={<Input
                            type='number'
                            value={maxAmount}
                            onChange={(e) => {
                                // setMinAmount(e.target.value.replace(/[^\d.]/g, ''))
                                handelCheckMinAmount(minAmount, e.target.value)
                                setMaxAmount(e.target.value)
                                if (e.target.value === '') {
                                    setMinAmount('')
                                }
                            }}
                        />}
                            name='Starting Price (Price Ceiling)-max price'
                            prefix={`Starting price is 1 =`}
                            suffix={'ETH'} />

                        <Form
                            error={errors.minAmount}
                            top={'38px'} input={<Input
                                type='number'
                                disabled={!maxAmount || maxAmount === '' ? true : false}
                                value={minAmount}
                                onChange={(e) => {
                                    handelCheckMinAmount(e.target.value, maxAmount)
                                    setMinAmount(e.target.value)
                                }}
                            />}
                            name='reserve price'
                            prefix={`reserve price is 1 =`}
                            suffix={'ETH'} />


                        <Pool.Content width={isXSDown ? '100%' : '480px'}>
                            <Form
                                error={errors.times}
                                top={'38px'}
                                input={<Input
                                    value={times}
                                    onChange={
                                        (e) => {
                                            setTimes(e.target.value)
                                            if (parseFloat(e.target.value) !== parseInt(e.target.value) || (parseInt(e.target.value) < 1)) {
                                                setErrors({ ...errors, times: 'The degree must be an integer greater than or equal to 1' })
                                            } else {
                                                setErrors({ ...errors, times: '' })
                                            }
                                        }
                                    }
                                />}
                                name={`Auction price decreasing times `} />

                            {hasLimit && (
                                <Form
                                    top={'38px'}
                                    suffix={selectedToken.symbol}
                                    input={<Input onBlur={() => {
                                        if ((ratio && amount) && new BigNumber(amount).dividedBy(ratio).dividedBy(1000).isGreaterThan(limit)) {
                                            setLimit(new BigNumber(amount).dividedBy(ratio).dividedBy(1000).toString())
                                        } else if (amount && ratio && new BigNumber(limit).isGreaterThan(new BigNumber(amount).dividedBy(ratio))) {
                                            setLimit(new BigNumber(amount).dividedBy(ratio).toString())
                                        }
                                    }} type='number' value={limit} onChange={(e) => {
                                        setLimit(e.target.value.replace(/[^\d.]/g, ''))
                                    }}
                                    />} name={'Allocation'} />
                            )}
                        </Pool.Content>

                        <Pool.Divider />

                        <Pool.Content style={{ marginTop: 0 }} width={isXSDown ? '100%' : '480px'}>
                            <Form
                                type='radio'
                                top={'38px'}
                                name={<span style={{ color: '#000' }}>Participant</span>}
                                prefix={(<div style={{ marginTop: 9, display: 'flex', alignItems: 'center' }}>
                                    <label>
                                        <input onChange={() => {
                                            setOnlyBot(true)
                                            setPassword(null)
                                            setIsPrivate(false)
                                        }} type='radio' name='participant' defaultChecked={true} />
                                        <i></i>
                                        {`  BOT holders`}
                                    </label>
                                    <label style={{
                                        marginLeft: isXSDown ? '14px' : 60,
                                        marginRight: isXSDown ? '14px' : 34
                                    }}>
                                        <input onChange={() => {
                                            setOnlyBot(false)
                                            setPassword(null)
                                            setIsPrivate(false)
                                        }} type='radio' name='participant' />
                                        <i></i>
                                        {` Public`}
                                    </label>
                                    <label style={{
                                        marginLeft: isXSDown ? '14px' : 60,
                                        marginRight: isXSDown ? '14px' : 34
                                    }}>
                                        <input onChange={() => {
                                            setOnlyBot(false)
                                            setIsPrivate(true)
                                        }} type='radio' name='participant' />
                                        <i></i>
                                        {` Private`}
                                    </label>
                                </div>)} />
                            {isPrivate && (
                                <Form
                                    top={'38px'}
                                    addonAfter={(<img onClick={() => {
                                        setShowPassword(!showPassword)
                                    }} src={showPassword ? icon_eye_open : icon_eye_close} />)}
                                    input={<Input type={!showPassword && 'password'} onChange={(e) => {
                                        setPassword(e.target.value)
                                        console.log('password', new BN(md5(e.target.value)).toString())
                                    }}
                                    />} name={'Password'} />
                            )}

                        </Pool.Content>

                        <Pool.Divider />

                        <Form top={'38px'} error={nameError} input={<Input
                            maxLength={15}
                            onBlur={(e) => {
                                setName(e.target.value)
                            }}
                        />} name={'Pool Name'} />

                        <Form top={'49px'} error={timeError}
                            name={<span style={{ color: '#000', marginBottom: 9 }}>Pool running time</span>} hidden
                            prefix={(<div>
                                <Pool.Content width={isXSDown ? '100%' : '480px'}>
                                    <Form input={<Input
                                        onChange={(e) => {
                                            setDays(e.target.value)
                                        }}
                                        type='number' />} name={'Days'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        value={hours}
                                        onChange={(e) => {
                                            let hours = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                            if (hours > 24) {
                                                hours = 24
                                            }
                                            setHours(hours);
                                        }}
                                        type='number' />} name={'Hours'} width={isXSDown ? '30%' : '132px'} />
                                    <Form input={<Input
                                        value={minutes}
                                        onChange={(e) => {
                                            let minutes = e.target.value.replace(/^0(0+)|[^\d]+/g, '');
                                            if (minutes > 60) {
                                                minutes = 60
                                            }
                                            setMinutes(minutes);
                                        }}
                                        type='number' />} name={'Minutes'} width={isXSDown ? '30%' : '132px'} />
                                </Pool.Content>
                            </div>)} />

                        <Form top={'38px'} disabled name={<><HelperLayout
                            content={`bounce charge '1.5%' fee to pool creator based on the amount of the successfully swapped tokens`}> {`Transaction Fee `}<img
                                src={icon_helper} /></HelperLayout></>} hidden prefix={(<div
                                    style={{ width: isXSDown ? '100%' : '480px' }}>
                                    <Button
                                        disabled={!validateForm(errors) || !address || !maxAmount || !minAmount || !times || !name}
                                        style={{ marginTop: 12 }} black width={isXSDown ? '100%' : '480px'} onClick={() => {
                                            setShowTip(true)
                                        }}>Launch</Button>
                                    {<p style={{
                                        fontFamily: 'Helvetica Neue',
                                        fontSize: 12,
                                        lineHeight: '16.8px',
                                        color: 'rgba(0, 0, 0, .4)',
                                        marginTop: 12,
                                        textAlign: "left"
                                    }}>
                                        Bounce contract does not accept Inflationary and deflationary tokens, please don’t
                                        create a pool with
                                        special token forms.
                            </p>}
                                </div>)} />
                    </Pool.Content>
                </Pool.Frame>
            </Pool>

            <CreateModal onOK={() => {
                setModalStatus(initStatus)
                history.goBack()
            }} onDismiss={() => {
                setModalStatus(initStatus)
            }} modalStatus={modalStatus} />

            <Modal
                closeable
                isOpen={showTip}
                onDismiss={() => {
                    setShowTip(false)
                }}
                maxWidth={'450px'}

            >
                <AuctionTipModal type={1} auction={() => {
                    setShowTip(false)
                    if (nftType === 'nft-721') {
                        onSB721Create_DA()
                    } else {
                        onSB1155Create_DA()
                    }
                }} />

            </Modal>

        </PoolFrame>
    )
}
