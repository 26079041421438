import React, { useState } from 'react'
import styled from 'styled-components'
import { Message } from "../common/message";

const Frame = styled.div`
  width: 1280px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  align-items: center;
  padding:0 0 64px;
  @media (max-width: 767px) {
    width:100%;
  }
`
const Title = styled.span`
  font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 44px;
margin-top: 48px;
`

const TabFrame = styled.div`
  display: flex;
  margin-top: 56px;
`

const Tab = styled.div`
  font-family: IBM Plex Mono;
  width: 280px;
  margin: ${({ center }) => (center ? '0 16px' : '')};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 12px;
  color: ${({ active }) => (active ? '#000' : 'rgba(0, 0, 0, 0.2)')};
  border-bottom: 4px solid ${({ active }) => (active ? '#000' : 'rgba(0, 0, 0, 0.2)')};
  @media (max-width: 767px) {
    width:30%;
    margin-left:4px;
  }
`

export const CVCreateFrame = ({ steps, curTab, setCurTab, projectInfo, poolInfo }) => {

  const wrapperSpace = (str) => {
    if (!str) return false
    if (String(str).trim().length === 0) return false
    return true
  }

  return (
    <Frame>
      <Message
        type={'error'}
        link={'https://docs.bounce.finance/bounce-social-verified-pools/social-verified-pool-background'}
        linkTitle={'https://docs.bounce.finance/bounce-social-verified-pools/social-verified-pool-background'}
        content={'social verified pool has more complex setups. For normal pool setup, please visit other sections. Read more about social verified pool: '} />
      <Title>Create a New Project</Title>
      <TabFrame>
        {steps.map(item => {
          return (
            <Tab center={item.key === 1} key={item.key} active={curTab === item.key} onClick={() => {
              switch (item.key) {
                case 0:
                  setCurTab(item.key)
                  break
                case 1:
                  if (projectInfo) {
                    setCurTab(item.key)
                  }
                  break
                case 2:
                  if (poolInfo && projectInfo) {
                    setCurTab(item.key)
                  }
                  break
                default:
              }
            }
            }>{item.title}</Tab>
          )
        })}
      </TabFrame>
      {steps.map(item => {
        return item.view
      })}
    </Frame>
  )
}
