import React from 'react';
import rotate from '../static/image/rotate.svg'


export const Loading = ({show,title}) => {
  return (
      <div className="modal-loading" style={{display:show?'flex':'none'}}>
        <img src={rotate} className="waiting"/>
        <span>Loading</span>
      </div>)
};
