import { LayoutFrame } from "../../components/common/Layout";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { BotStaking } from "./BotStaking";
import { Mining } from "./Mining";
import { LPStaking } from "./LPStaking";
import { getContract, useActiveWeb3React } from "../../web3";
import { HANDLE_SHOW_CONNECT_MODAL } from "../../const";
import { myContext } from "../../reducer";
import { StakingModal, successClaimStatus } from "../../components/common/StakingModal";
import {
    initStatus,
    approveStatus,
    confirmStatus,
    pendingStatus,
    successStakeStatus,
    successUnStakeStatus,
    errorStatus,
    cancelStatus
} from '../../components/common/StakingModal'
import bounceStake from "../../web3/abi/bounceStake.json";
import {
    getBotAddress,
    getFixSwapAddress,
    getLPStakingAddress,
    getLPTokenAddress,
    getStakingAddress
} from "../../web3/contractAddress";
import bounceERC20 from "../../web3/abi/bounceERC20.json";
import bounce from "../../web3/abi/bounce.json";
import liquidityPool from "../../web3/abi/liquidityPool.json";
import Web3 from 'web3'
import { rgba } from "polished";

const { toWei } = Web3.utils

const StakingMode = styled.span`
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  margin-right: 24px;
  background-color: ${({ active, theme }) => (active ? theme.black : '')};
  color: ${({ active, theme }) => (active ? theme.white : theme.black)};
  cursor: pointer;

  &:hover{
      color: ${({ active, theme }) => (active ? theme.white : rgba(0, 0, 0, .7))};
      opacity: 1;
  };
  @media (max-width: 767px) {
    margin-right:0px;
    font-size: 12px;
    white-space:nowrap;
  };
`

const ModeFrame = styled.div`
  display: flex;
  /* height: 96px; */
  align-items: center;
  margin: 34px 0 72px;
`

export const Earning = () => {

    const { state, dispatch } = useContext(myContext);

    const [modalStatus, setModalStatus] = useState(initStatus);


    const { active, account, library, chainId } = useActiveWeb3React();

    const [mode, setMode] = useState(0)

    useEffect(() => {
        if (!active) {
            dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true });
        }
    }, [])


    const onStack = async (amount) => {
        console.log('to stake', amount)
        if (!amount) {
            return;
        }
        const stackContract = getContract(library, bounceStake.abi, getStakingAddress(chainId));
        const botContract = await getContract(library, bounceERC20.abi, getBotAddress(chainId));
        const weiAmount = toWei(amount, 'ether');
        console.log(`stake amount: ${weiAmount}`);
        try {
            setModalStatus(approveStatus)
            const result = await botContract.methods.approve(getStakingAddress(chainId), weiAmount).send({ from: account });
            if (result.status) {
                setModalStatus(confirmStatus)
                await stackContract.methods.staking(weiAmount)
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setModalStatus(successStakeStatus);
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus);
                        console.log('error1', err);
                    })
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus);
            } else {
                setModalStatus(errorStatus);
            }
            console.log('err', err);
        }
    }


    const onUnStack = async (amount) => {
        const stackContract = getContract(library, bounceStake.abi, getStakingAddress(chainId));
        const weiAmount = toWei(amount, 'ether');
        console.log(`un stake amount: ${weiAmount}`);
        try {
            setModalStatus(confirmStatus)
            await stackContract.methods.unStaking(weiAmount)
                .send({ from: account })
                .on('transactionHash', hash => {
                    setModalStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    setModalStatus(successUnStakeStatus);
                })
                .on('error', (err, receipt) => {
                    setModalStatus(errorStatus);
                    console.log('error1', err);
                })
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus);
            } else {
                setModalStatus(errorStatus);
            }
            console.log('err', err);
        }
    }

    const onGovClaim = async () => {
        const stackContract = getContract(library, bounceStake.abi, getStakingAddress(chainId));
        try {
            setModalStatus(confirmStatus)
            await stackContract.methods.claimGovReward()
                .send({ from: account })
                .on('transactionHash', hash => {
                    setModalStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    setModalStatus(successStakeStatus);
                })
                .on('error', (err, receipt) => {
                    setModalStatus(errorStatus);
                    console.log('error1', err);
                })
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus);
            } else {
                setModalStatus(errorStatus);
            }
            console.log('err', err);
        }
    }

    const onClaim = async () => {
        const stackContract = getContract(library, bounceStake.abi, getStakingAddress(chainId));
        console.log(`starting claim: `);
        try {
            setModalStatus(confirmStatus)
            await stackContract.methods.claimReward()
                .send({ from: account })
                .on('transactionHash', hash => {
                    setModalStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    setModalStatus(successClaimStatus);
                })
                .on('error', (err, receipt) => {
                    setModalStatus(errorStatus);
                    console.log('error1', err);
                })
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus);
            } else {
                setModalStatus(errorStatus);
            }
            console.log('err', err);
        }
    }



    const onLPStack = async (amount) => {
        console.log('to stake', amount)
        if (!amount) {
            return;
        }
        const stackContract = getContract(library, liquidityPool.abi, getLPStakingAddress(chainId));
        const botContract = await getContract(library, bounceERC20.abi, getLPTokenAddress(chainId));
        const weiAmount = toWei(amount, 'ether');
        console.log(`stake amount: ${weiAmount}`);
        try {
            setModalStatus(approveStatus)
            const result = await botContract.methods.approve(getLPStakingAddress(chainId), weiAmount).send({ from: account });
            if (result.status) {
                setModalStatus(confirmStatus)
                await stackContract.methods.farming(weiAmount)
                    .send({ from: account })
                    .on('transactionHash', hash => {
                        setModalStatus(pendingStatus)
                    })
                    .on('receipt', (_, receipt) => {
                        setModalStatus(successStakeStatus);
                    })
                    .on('error', (err, receipt) => {
                        setModalStatus(errorStatus);
                        console.log('error1', err);
                    })
            }
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus);
            } else {
                setModalStatus(errorStatus);
            }
            console.log('err', err);
        }
    }


    const onLPUnStack = async (amount) => {
        const contract = getContract(library, liquidityPool.abi, getLPStakingAddress(chainId));
        const weiAmount = toWei(amount, 'ether');
        console.log(`un stake amount: ${weiAmount}`);
        try {
            setModalStatus(confirmStatus)
            await contract.methods.unfarming(weiAmount)
                .send({ from: account })
                .on('transactionHash', hash => {
                    setModalStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    setModalStatus(successUnStakeStatus);
                })
                .on('error', (err, receipt) => {
                    setModalStatus(errorStatus);
                    console.log('error1', err);
                })
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus);
            } else {
                setModalStatus(errorStatus);
            }
            console.log('err', err);
        }
    }


    const onClaimStakingReward = async () => {
        const contract = getContract(library, liquidityPool.abi, getLPStakingAddress(chainId));
        try {
            setModalStatus(confirmStatus)
            await contract.methods.harvest()
                .send({ from: account })
                .on('transactionHash', hash => {
                    setModalStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    setModalStatus(successClaimStatus);
                })
                .on('error', (err, receipt) => {
                    setModalStatus(errorStatus);
                    console.log('error1', err);
                })
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus);
            } else {
                setModalStatus(errorStatus);
            }
            console.log('err', err);
        }
    }


    const onMingClaim = async () => {
        const contract = getContract(library, bounce.abi, getFixSwapAddress(chainId));
        console.log(`starting onMingClaim: `);
        try {
            setModalStatus(confirmStatus)
            await contract.methods.bonusClaim()
                .send({ from: account })
                .on('transactionHash', hash => {
                    setModalStatus(pendingStatus)
                })
                .on('receipt', (_, receipt) => {
                    setModalStatus(successClaimStatus);
                })
                .on('error', (err, receipt) => {
                    setModalStatus(errorStatus);
                    console.log('error1', err);
                })
        } catch (err) {
            if (err.code === 4001) {
                setModalStatus(cancelStatus);
            } else {
                setModalStatus(errorStatus);
            }
            console.log('err', err);
        }
    }




    function renderStaking() {
        switch (mode) {
            case 0:
                return (
                    <BotStaking onStake={onStack} onUnStake={onUnStack} onClaim={onClaim} onGovClaim={onGovClaim} />
                )
            case 1:
                return (
                    <Mining onClaim={onMingClaim} />
                )
            case 2:
                return (
                    <LPStaking onStake={onLPStack} onUnStake={onLPUnStack} onClaimStakingReward={onClaimStakingReward} />
                )
        }
    }

    return (
        <LayoutFrame style={{ backgroundColor: '#F5F5F5', minHeight: '80vh' }}>
            <ModeFrame style={{width:'100%',overflowX:'scroll'}}>
                <StakingMode onClick={() => {
                    setMode(0)
                }} active={mode === 0}>BOT Staking</StakingMode>
                <StakingMode onClick={() => {
                    setMode(1)
                }} active={mode === 1}>Daily Transaction Mining</StakingMode>
                <StakingMode onClick={() => {
                    setMode(2)
                }} active={mode === 2}>Uniswap LP Token Staking</StakingMode>
            </ModeFrame>

            {renderStaking()}

            <StakingModal modalStatus={modalStatus} onDismiss={() => {
                setModalStatus(initStatus)
            }} />
        </LayoutFrame>
    )
}


