import { useEffect, useState } from 'react'
import { useActiveWeb3React, getContract } from "../../../web3";
import { getSVSBAddress } from "../../../web3/contractAddress";

import { queryPoolItem, queryERC20Token } from './Request_CT'
import { getSVPoolStatus, getProgress, weiToNumber } from './Request_FUN'

import SV_SB_ERC20_ABI from '../../../web3/abi/SVSealedBid.json'

export const keyMap = [{
    key: 'creatorP',
    name: 'creator',
    needTrans: false,
  }, {
    key: 'nameP',
    name: 'name',
    needTrans: false,
  }, {
    key: 'token0P',
    name: 'fromAddress',
    needTrans: false,
  }, {
    key: 'token1P',
    name: 'toAddress',
    needTrans: false,
  }, {
    key: 'passwordP',
    name: 'password',
    needTrans: false,
  }, {
    key: 'amountTotal0P',
    name: 'fromAmount',
    needTrans: false,
  }, {
    key: 'amountMin1P',
    name: 'toAmount',
    needTrans: true,
  }, {
    key: 'closeAtP',
    name: 'time',
  }, {
    key: 'onlyBotHolder',
    name: 'onlyBot',
    needTrans: false
  }, {
    key: 'minEthBidP',
    name: 'minEthPerWallet',
    needTrans: true
  }, {
    key: 'createAt',
    name: 'createAt',
    needTrans: false
  }, {
    key: 'poolInfo',
    name: 'poolInfo',
    needTrans: false
  }, {
    key: 'totalVotes',
    name: 'totalVotes',
    needTrans: false
  }, {
    key: 'enabled',
    name: 'enabled',
    needTrans: false
  }];

export const useSV_SB_ERC20 = (queryListArr = []) => {
    const { active, library, chainId } = useActiveWeb3React()
    const [poolsDate, setPoolsDate] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    // const noConnect = new Web3(new Web3.providers.HttpProvider("https://api.infura.io/v1/jsonrpc/mainnet")).currentProvider
    // console.log('A_console_noConnect', noConnect)
    useEffect(() => {
        if (!active || !queryListArr) return
        setIsLoad(true)
        // console.log('A_console_queryListArr', queryListArr)
        queryPoolDate(queryListArr)
    }, [active, queryListArr, chainId])

    const queryPoolDate = async (queryListArr) => {


        const FS_ERC20_CT = getContract(library, SV_SB_ERC20_ABI.abi, getSVSBAddress(chainId))

        const dataList = await Promise.all(queryListArr.map(async (poolID) => {
            const poolItemInfo = await queryPoolItem(FS_ERC20_CT, poolID, keyMap)

            // 通过获取到的池子信息筛选出列表需要的信息
            const poolItemData = {
                poolID: poolItemInfo.poolID,
                status: getSVPoolStatus(poolItemInfo.enabled, poolItemInfo.createAt, poolItemInfo.time, poolItemInfo.toBidAmount, poolItemInfo.toAmount),
                poolName: poolItemInfo.name,
                fromToken: await queryERC20Token(library, poolItemInfo.fromAddress, chainId),
                toToken: await queryERC20Token(library, poolItemInfo.toAddress, chainId),
                creator: poolItemInfo.creator,
                closeAt: poolItemInfo.time,
                ...JSON.parse(poolItemInfo.poolInfo || '{}')
            }
            poolItemData.fromAmount = weiToNumber(poolItemInfo.fromAmount, poolItemData.fromToken.decimals)
            poolItemData.toAmount = weiToNumber(poolItemInfo.toAmount, poolItemData.toToken.decimals)
            poolItemData.totalVotes = weiToNumber(poolItemInfo.totalVotes, 18)
            poolItemData.progress = poolItemData.status === 'Filled' ? '100%' : getProgress(300, poolItemData.totalVotes)

            return poolItemData
        }))

        setPoolsDate(dataList)
        setIsLoad(false)
    }

    return { poolsDate, isLoad }
}
