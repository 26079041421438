import Web3 from "web3";
import {
  BOUNCE_ADDRESS,
  BOUNCE_ERC20_ADDRESS,
  STAKE_ADDRESS,
  BOUNCE_SEALED_BID_ADDRESS,
  LIQUIDITY_POOL_ADDRESS,
  LP_TOKEN_ADDRESS,
  BOUNCE_DUTCH_AUCTION_V1ADDRESS,
  BOUNCE_DUTCH_AUCTION_NFT_ADDRESS,
  BOUNCE_GOVERNANCE_ADDRESS, BOUNCE_DUTCH_AUCTION_ERC1155_ADDRESS, BOUNCE_ENGLISH_AUCTION_NFT_ADDRESS,
  BOUNCE_SEALED_BID_NFT_AUCTION_ADDRESS
} from "../../Content/const";
const bounce_abi = require('./bounce.json');
const bounce_stack_abi = require('./bounceStake.json');
const bounce_sealed_bid_abi = require('./bounceSealedBid.json');
const bounce_dutch_auction_v1_abi = require('./dutchAuctionV1.json');
const bounceDutchAuctionNFT = require('./bounceDutchAuctionNFT.json');
const bounceDutchAuctionErc1155 = require('./BounceDutchAuctionErc1155.json');
const bounce_erc20_abi = require('./bounceERC20.json');
const bounceERC721_abi = require('./bounceERC721.json');
const bounceERC1155_abi = require('./bounceERC1155.json');
const liquidity_pool_abi = require('./liquidityPool.json');
const lpERC20 = require('./lpERC20.json');
const bounceGovernance = require('./bounceGovernance.json');
const bounce_english_auction_NFT = require('./bounceEnglishAuctionNFT.json');
const bounce_sealed_bid_NFT = require('./bounceSealedBidNFT.json');







export const initWeb3 = async () => {
  return await new Web3(Web3.givenProvider || 'https://eth-02.dccn.ankr.com');
}

export const getBalance = async (web3, account) => {
  if (web3 && account) {
    let balance = await web3.eth.getBalance(account);
    balance = web3.utils.fromWei(balance);
    return balance
    // return  web3.eth.getBalance(account)
    //   .then((balance) => {
    //     return web3.utils.fromWei(balance);
    //   });
  }
}

export const getWei =  (decimals) => {
  switch (decimals){
    case '0':
      return 'nanoether'
    case '1':
      return 'wei';
    case '3':
      return 'kwei'
    case '6':
      return 'mwei'
    case '9':
      return 'gwei'
    case '12':
      return 'szabo'
    case '15':
      return 'finney'
    case '18':
      return 'ether'
    default:
      return 'ether'
  }
}

export const getBounce = async (web3) => {
     return new web3.eth.Contract(bounce_abi.abi, await BOUNCE_ADDRESS(web3));
}

export const getStake = async (web3) => {
    return new web3.eth.Contract(bounce_stack_abi.abi, await STAKE_ADDRESS(web3));
}

export const getDutchAuctionV1 = async (web3) => {
  return new web3.eth.Contract(bounce_dutch_auction_v1_abi.abi, await BOUNCE_DUTCH_AUCTION_V1ADDRESS(web3));
}

export const getDutchAuctionNFT = async (web3) => {
  return new web3.eth.Contract(bounceDutchAuctionNFT.abi, await BOUNCE_DUTCH_AUCTION_NFT_ADDRESS(web3));
}

export const getDutchAuctionERC1155 = async (web3) => {
  return new web3.eth.Contract(bounceDutchAuctionErc1155.abi, await BOUNCE_DUTCH_AUCTION_ERC1155_ADDRESS(web3));
}


export const getLiquidity = async (web3) => {
  return new web3.eth.Contract(liquidity_pool_abi.abi, await LIQUIDITY_POOL_ADDRESS(web3));
}


export const getLPToken = async (web3) => {
  return new web3.eth.Contract(lpERC20.abi, await LP_TOKEN_ADDRESS(web3));
}

export const getSealedBid = async (web3) => {
  return new web3.eth.Contract(bounce_sealed_bid_abi.abi, await BOUNCE_SEALED_BID_ADDRESS(web3));
}

export const getBounceERC20 = async (web3) => {
  return new web3.eth.Contract(bounce_erc20_abi.abi, await BOUNCE_ERC20_ADDRESS(web3));
}

export const getContract = async (web3, address)  => {
  return new web3.eth.Contract(bounce_erc20_abi.abi, address);
}

export const get721Contract = async (web3, address)  => {
  return new web3.eth.Contract(bounceERC721_abi.abi, address);
}

export const get1155Contract = async (web3, address) => {
  return new web3.eth.Contract(bounceERC1155_abi.abi, address);
}

export const getGovernanceContract = async (web3)  => {
  return new web3.eth.Contract(bounceGovernance.abi, await BOUNCE_GOVERNANCE_ADDRESS(web3));
}

export const getEnglishAuctionContract = async (web3)  => {
  return new web3.eth.Contract(bounce_english_auction_NFT.abi, await BOUNCE_ENGLISH_AUCTION_NFT_ADDRESS(web3));
}

export const getSBNFTAuctionContract = async (web3)  => {
  return new web3.eth.Contract(bounce_sealed_bid_NFT.abi, await BOUNCE_SEALED_BID_NFT_AUCTION_ADDRESS(web3));
}

export const getAccountAddress = async (web3)  => {
  return web3.currentProvider.selectedAddress;
}

export const getTime = (day, hour, min) => {
  let time = 0;
  if ( day ) {
    time += 24 * 60 * 60 * day;
  }
  if ( hour ) {
    time += 60* 60 * hour;
  }
  if ( min ) {
    time += 60 * min;
  }
  return time;
}


export const timeToSecond = (hour, min, second) => {
  let time = 0;
  if ( hour ) {
    time += 60* 60 * hour;
  }
  if ( min ) {
    time += 60 * min;
  }
  if (second) {
    time += second
  }
  return time;
}

