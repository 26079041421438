import React, { useState, useCallback } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import './static/style/reset.css';
import './static/style/style.scss';
import './static/style/font.css'
import { IndexContent } from './Content/IndexContent';
import { CreateEnglishAuctionContent } from './Content/CreateEnglishAuctionContent';
import { ClaimContent } from './Content/ClaimContent';
import { Web3Provider } from "@ethersproject/providers";
import { JoinNFTContent } from './Content/JoinNFTContent';
import { JoinEnglishAuctionContent } from './Content/JoinEnglishAuctionContent';
import { StakingContent } from './Content/StakingContent';
import { UniswapContent } from './Content/UniswapContent';
import { Social } from './Social/Social';
import { ConnectContent } from './Content/ConnectContent';
import { Web3ReactProvider } from "@web3-react/core";
import { Wenhao } from './components/media'
import { MobileNav } from './MobileNav/MobileNav'
import logo from './static/image/logo-text.svg';
import { PoolContent } from './Content/PoolContent';
import { BidPoolContent } from './Content/BidPoolContent';
import { DutchPoolContent } from './Content/DutchPoolContent';
import { NFTPoolContent } from './Content/NFTPoolContent';
import { EAPoolContent } from './Content/EAPoolContent';
// import { SBNFTPoolContent } from './Content/SBNFTPoolContent';

import { LOCALES_DATA, locales } from './locales/locales';
import AppBase from './AppBase';
// import { CreateNFTContent } from "./Content/CreateNFTContent";

import ThemeProvider from './theme'
import { Header } from './pages/header'
import { ContextProvider } from './reducer';
// import { Home } from './pages/home';
import { CreateFSPool } from './pages/fixed-swap/Create';
import { CreateLLPool } from './pages//liquidity-lock/Create';
import { CreateDAPool } from './pages/dutch-auction/Create';
import { CreateLotteryErc20Pool } from './pages/lottery-erc20/Create';
import { FSPoolDetail } from './pages/fixed-swap/Detail';
import { LLPoolDetail } from './pages/liquidity-lock/Detail';
import { SVFSDetail } from './pages/fixed-swap/SVDetail';
import { SVSBDetail } from './pages/sealed-bid/SVDetail';
import { DAPoolDetail } from './pages/dutch-auction/Detail';
import { SBPoolDetail } from "./pages/sealed-bid/Detail";
import { SBNFTPoolDetail } from "./pages/sealed-bid-nft/Detail";
import { EANFTPoolDetail } from "./pages/english-auction-nft/Detail";
import { DANFTPoolDetail } from "./pages/dutch-auction-nft/Detail";
import { FSNFTPoolDetail } from "./pages/fixed-swap-nft/Detail";
import { Earning } from "./pages/earning";
import { Store } from "./pages/store";
import { StoreCreate } from "./pages/store/Create";
import { Statistic } from './pages/statistic'
import { Governance } from './pages/governance/governance';
import { CreateProposal } from './pages/governance/components/create';
import { Details } from './pages/governance/details';
import { LotteryPoolDetail } from "./pages/lottery/Detail";
import { CreateLotteryPool } from "./pages/lottery/Create";
import { CreateFSNFTPool } from "./pages/fixed-swap-nft/Create";
import { CreateSBNFTPool } from "./pages/sealed-bid-nft/Create";
import { CreateDANFTPool } from "./pages/dutch-auction-nft/Create";
import { LotteryERC20Detail } from './pages/lottery-erc20/Detail';
import { SVFSCreate } from "./pages/fixed-swap/SVCreate";
import { SVSBCreate } from "./pages/sealed-bid/SVCreate";

import { StoreDetail } from "./pages/store/Detail";
import { Mynft } from "./pages/store/Mynft";
import { StoreCreateNFT } from "./pages/english-auction-nft/StoreCreate";
import { FactoryCareteEANFT } from "./pages/english-auction-nft/FactoryCarete";
import { StoreEANFTDetail } from "./pages/english-auction-nft/StoreDetail";
import { CreateStoreFSNFTPool } from "./pages/fixed-swap-nft/StoreCreate";
import { FactoryCareteFSNFT } from "./pages/fixed-swap-nft/FactoryCreate";
import { StoreFSNFTPoolDetail } from "./pages/fixed-swap-nft/StoreDetail";
import { YGitDetail } from "./pages/store/ygift/Detail";
import { CreateYGiftFSNFTPool } from "./pages/fixed-swap-nft/YGiftCreate";
import { YGiftFSNFTPoolDetail } from "./pages/fixed-swap-nft/YGiftDetail";
import { YGiftCreateEANFT } from "./pages/english-auction-nft/YGiftCreate";
import { YGiftEANFTDetail } from "./pages/english-auction-nft/YGiftDetail";
import { CreatePredictionPool } from "./pages/prediction/Create";
import { PredictionDetail } from "./pages/prediction/Detail";

// Store Factory
// import { StoreFa_EA } from './pages/store/Factory/EnglishAuction'
import { StoreFa_FS } from './pages/store/Factory/FixedSwapNFT'
import { EANFTStoreDetail } from "./pages/store/Factory/EnglishAuction/Detail";
import { FSNFTStoreDetail } from "./pages/store/Factory/FixedSwapNFT/Detail";

/**
 *
 */
// import { Account } from "./pages/A_mine";
import { Account } from "./pages/A_Account";
import { PoolList } from './pages/A_poolList'
import { CreateSBPool } from "./pages/sealed-bid/Create";


function App() {
    const [isConnect, setIsConnect] = useState(false);
    const [account, setAccount] = useState('');
    const [hash, setHash] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [status, setStaus] = useState('');
    const [poolIndex, setPoolIndex] = useState(-1);
    const [symbol, setSymbol] = useState();
    const [decimals, setDecimals] = useState();
    const [myPool, setMyPool] = useState();
    const [locale, setLocale] = useState(window.localStorage['DEFAULT_LANGUAGE'] ? window.localStorage['DEFAULT_LANGUAGE'] : locales[0].value)
    const [provider, setProvider] = useState('https://eth-02.dccn.ankr.com');

    const maskInfo = {
        isConnect: isConnect,
        account: account,
    };

    const transactionInfo = {
        hash: hash,
        from: from,
        to: to,
        status: status,
    };

    const handleReceiptChange = useCallback((receipt, from, to) => {
        setHash(receipt.hash);
        setFrom(from);
        setTo(to);
    }, [setHash, setFrom, setTo]);

    const handleTransactionStatusChange = useCallback(status => {
        setStaus(status);
    }, [setStaus]);

    const onPoolIndexChange = useCallback((index) => {
        setPoolIndex(index);
    }, [setPoolIndex]);

    function getLibrary(provider, connector) {
        // console.log('provider', provider, connector)
        const library = new Web3Provider(provider);
        library.pollingInterval = 8000;
        return library;
    }

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <ContextProvider>
                <ThemeProvider>
                    <AppBase
                        translations={LOCALES_DATA}
                        locale={locale}
                    >
                        <div className='container'>
                            <div className='desktop'>
                                <Router>
                                    <MobileNav metaMaskInfo={maskInfo} />
                                    <Header />
                                    <Switch>
                                        <Route path='/pools' render={() => { return <Redirect to='/application' /> }} />
                                        <Route path='/application'>
                                            <PoolList />
                                        </Route>

                                        <Route path='/account' >
                                            <Account />
                                        </Route>

                                        <Route exact path='/store'>
                                            <Store />
                                        </Route>

                                        <Route path='/store/detail/'>
                                            <StoreDetail />
                                        </Route>

                                        <Route path='/store/ygift/detail/'>
                                            <YGitDetail />
                                        </Route>

                                        <Route path='/store/mynft'>
                                            <Mynft />
                                        </Route>

                                        <Route path='/create/sv-fixed-swap'>
                                            <SVFSCreate />
                                        </Route>
                                        <Route path='/create/sv-sealed-bid'>
                                            <SVSBCreate />
                                        </Route>
                                        <Route path='/create/lottery-nft'>
                                            <CreateLotteryPool />
                                        </Route>
                                        <Route path='/create/fixed-swap-nft'>
                                            <CreateFSNFTPool />
                                        </Route>
                                        <Route path='/create/sealed-bid-nft'>
                                            <CreateSBNFTPool />
                                        </Route>
                                        <Route path='/create/dutch-auction-nft'>
                                            <CreateDANFTPool />
                                        </Route>

                                        <Route exact path='/pool'>
                                            <PoolContent symbol={symbol} account={account} />
                                        </Route>
                                        <Route exact path='/bidpool'>
                                            <BidPoolContent symbol={symbol} account={account} />
                                        </Route>
                                        <Route exact path='/dutchpool'>
                                            <DutchPoolContent symbol={symbol} account={account} />
                                        </Route>
                                        <Route exact path='/nftpool'>
                                            <NFTPoolContent symbol={symbol} account={account} />
                                        </Route>
                                        <Route exact path='/english-auction-pool'>
                                            <EAPoolContent symbol={symbol} account={account} />
                                        </Route>
                                        {/* <Route exact path='/sealed-bid-nft-auction-pool'>
                                            <SBNFTPoolContent symbol={symbol} account={account} />
                                        </Route> */}
                                        <Route exact path='/bid'>
                                            <IndexContent
                                                onPoolUpdate={onPoolIndexChange} />
                                        </Route>
                                        <Route exact path='/connect'>
                                            <ConnectContent
                                                changeIsConnect={setIsConnect}
                                                changeAccount={setAccount}
                                                changeProvider={setProvider}
                                            />
                                        </Route>
                                        <Route exact path='/staking'>
                                            <StakingContent
                                                symbol={symbol}
                                                account={account}
                                                poolIndex={poolIndex} />
                                        </Route>
                                        <Route exact path='/uniswap'>
                                            <UniswapContent
                                                symbol={symbol}
                                                account={account}
                                                poolIndex={poolIndex} />
                                        </Route>
                                        <Route exact path='/claim'>
                                            <ClaimContent
                                                symbol={symbol}
                                                account={account} />
                                        </Route>
                                        <Route exact path='/create/english-auction-pool'>
                                            <CreateEnglishAuctionContent account={account}
                                                symbol={symbol}
                                                decimals={decimals}
                                                onReceiptChange={handleReceiptChange}
                                                onTransactionStatusChange={handleTransactionStatusChange}
                                                onSymbolChange={setSymbol}
                                                onDecimalsChange={setDecimals}
                                            />
                                        </Route>

                                        <Route exact path='/store/crate/english-auction-pool'>
                                            <StoreCreateNFT />
                                        </Route>

                                        <Route exact path='/store/crate/custom/english-auction-nft/:storeID/:poolIndex'>
                                            <FactoryCareteEANFT />
                                        </Route>

                                        <Route exact path='/store/crate/custom/fixed-swap-nft/:storeID/:poolIndex'>
                                            <FactoryCareteFSNFT />
                                        </Route>

                                        <Route exact path='/store/crate/fixed-swap-nft-pool'>
                                            <CreateStoreFSNFTPool />
                                        </Route>

                                        <Route exact path='/store/ygift/crate/fixed-swap-nft-pool'>
                                            <CreateYGiftFSNFTPool />
                                        </Route>

                                        <Route exact path='/store/ygift/crate/english-auction-pool'>
                                            <YGiftCreateEANFT />
                                        </Route>
                                        {/* <Route exact path='/create/dutch-auction-nft'>
                                            <CreateDutchAuctionContent account={account}
                                                symbol={symbol}
                                                decimals={decimals}
                                                onReceiptChange={handleReceiptChange}
                                                onTransactionStatusChange={handleTransactionStatusChange}
                                                onSymbolChange={setSymbol}
                                                onDecimalsChange={setDecimals}
                                            />
                                        </Route> */}

                                        <Route exact path='/create/lottery-erc20'>
                                            <CreateLotteryErc20Pool />
                                        </Route>
                                        <Route exact path='/create/dutch-auction'>
                                            <CreateDAPool />
                                        </Route>
                                        <Route exact path='/create/fixed-swap'>
                                            <CreateFSPool />
                                        </Route>
                                        <Route exact path='/create/liquidity-lock-auction'>
                                            <CreateLLPool />
                                        </Route>
                                        <Route exact path='/create/sealed-bid'>
                                            <CreateSBPool />
                                        </Route>

                                        {/* <Route exact path='/oldcreatedutch'>
                                            <CreateDutchContent account={account}
                                                symbol={symbol}
                                                decimals={decimals}
                                                onReceiptChange={handleReceiptChange}
                                                onTransactionStatusChange={handleTransactionStatusChange}
                                                onSymbolChange={setSymbol}
                                                onDecimalsChange={setDecimals}
                                            />
                                        </Route>


                                        <Route exact path='/create/nft'>
                                            <CreateNFTContent account={account}
                                                symbol={symbol}
                                                decimals={decimals}
                                                onReceiptChange={handleReceiptChange}
                                                onTransactionStatusChange={handleTransactionStatusChange}
                                                onSymbolChange={setSymbol}
                                                onDecimalsChange={setDecimals}
                                            />
                                        </Route> */}

                                        {/* <Route exact path='/create/sealed-bid-nft-auction-pool'>
                                            <CreateSBNFTAuctionContent account={account}
                                                symbol={symbol}
                                                decimals={decimals}
                                                onReceiptChange={handleReceiptChange}
                                                onTransactionStatusChange={handleTransactionStatusChange}
                                                onSymbolChange={setSymbol}
                                                onDecimalsChange={setDecimals}
                                            />
                                        </Route> */}
                                        <Route exact path='/fixed-swap/:id'>
                                            <FSPoolDetail />
                                        </Route>
                                        {/* <Route exact path='/liquidity-lock-auction/:id'>
                                            <LLPoolDetail />
                                        </Route>
                                        <Route exact path='/sv-fixed-swap/:id'>
                                            <SVFSDetail />
                                        </Route>
                                        <Route exact path='/sv-sealed-bid/:id'>
                                            <SVSBDetail />
                                        </Route>
                                        <Route exact path='/sealed-bid/:id'>
                                            <SBPoolDetail />
                                        </Route>
                                        <Route exact path='/lottery-nft/:id'>
                                            <LotteryPoolDetail />
                                        </Route>
                                        <Route exact path='/dutch-auction/:id'>
                                            <DAPoolDetail />
                                        </Route> */}
                                        {/* <Route exact path='/join/dutch/:id'>
                                            <JoinDutchContent
                                                symbol={symbol}
                                                account={account}
                                                poolIndex={poolIndex} />
                                        </Route> */}
                                        {/* <Route exact path='/join/nft/:id'>
                                            <JoinNFTContent
                                                symbol={symbol}
                                                account={account}
                                                poolIndex={poolIndex} />
                                        </Route>
                                        <Route exact path='/join/nft1155/:type/:id'>
                                            <JoinNFTContent
                                                symbol={symbol}
                                                account={account}
                                                poolIndex={poolIndex} />
                                        </Route>
                                        <Route exact path='/join/english-auction-pool/:id'>
                                            <JoinEnglishAuctionContent
                                                symbol={symbol}
                                                account={account}
                                                poolIndex={poolIndex} />
                                        </Route> */}
                                        <Route exact path='/sealed-bid-nft/:id'>
                                            {/*<JoinSBNFTAuctionContent*/}
                                            {/*    symbol={symbol}*/}
                                            {/*    account={account}*/}
                                            {/*    poolIndex={poolIndex}/>*/}
                                            <SBNFTPoolDetail />
                                        </Route>
                                        {/* <Route exact path='/english-auction-nft/:id'>
                                            <EANFTPoolDetail />
                                        </Route>
                                        <Route exact path='/store/ygift/english-auction-nft/:id'>
                                            <YGiftEANFTDetail />
                                        </Route>
                                        <Route exact path='/store/english-auction-nft/:id'>
                                            <StoreEANFTDetail />
                                        </Route>
                                        <Route exact path='/store/custom/fixed-swap-nft/:proxy/:id'>
                                            <FSNFTStoreDetail />
                                        </Route>
                                        <Route exact path='/store/custom/english-auction-nft/:proxy/:id'>
                                            <EANFTStoreDetail />
                                        </Route>

                                        <Route exact path='/store/ygift/fixed-swap-nft/:id'>
                                            <YGiftFSNFTPoolDetail />
                                        </Route>
                                        <Route exact path='/store/fixed-swap-nft/:id'>
                                            <StoreFSNFTPoolDetail />
                                        </Route> */}
                                        <Route exact path='/dutch-auction-nft/:id'>
                                            {/*<JoinSBNFTAuctionContent*/}
                                            {/*    symbol={symbol}*/}
                                            {/*    account={account}*/}
                                            {/*    poolIndex={poolIndex}/>*/}
                                            <DANFTPoolDetail />
                                        </Route>
                                        <Route exact path='/fixed-swap-nft/:id'>
                                            <FSNFTPoolDetail />
                                        </Route>
                                        <Route exact path='/lottery-erc20/:id'>
                                            <LotteryERC20Detail />
                                        </Route>
                                        <Route exact path='/list/:type'>
                                            <IndexContent
                                                onPoolUpdate={onPoolIndexChange} />
                                        </Route>


                                        <Route exact path='/' render={() => {
                                            return <Redirect to='/application'></Redirect>
                                        }}>
                                            {/* <Home /> */}
                                        </Route>

                                        <Route exact path='/store/' >
                                            <Store />
                                        </Route>

                                        <Route path='/store/custom/fixed-swap-nft/:id' >
                                            <StoreFa_FS queryStatus={'all'} searchText={''} />
                                        </Route>

                                        <Route path='/store/custom/english-auction-nft/:id' >
                                            <StoreFa_FS queryStatus={'all'} searchText={''} />
                                        </Route>

                                        <Route path='/create/store/' >
                                            <StoreCreate />
                                        </Route>

                                        <Route exact path='/earning'>
                                            <Earning />
                                        </Route>

                                        <Route exact path='/statistic'>
                                            <Statistic />
                                        </Route>

                                        <Route exact path='/governance'>
                                            <Governance />
                                        </Route>
                                        <Route exact path='/governance/create-proposal'>
                                            <CreateProposal />
                                        </Route>
                                        <Route exact path='/governance/details/:id'>
                                            <Details />
                                        </Route>

                                        <Route exact path='/create/prediction'>
                                            <CreatePredictionPool />
                                        </Route>

                                        <Route exact path='/prediction/detail/:id'>
                                            <PredictionDetail />
                                        </Route>


                                    </Switch>
                                </Router>
                                <Social />
                            </div>
                            <div className='mobile'>
                                <img src={logo} className='mobile-logo' alt='logo' />
                                {/*<p className='mobile-hit'>Please use desktop and connect metamask chrome web extension to access bounce*/}
                                {/*pools</p>*/}
                            </div>
                            <div style={{
                                width: 40,
                                height: 40,
                                position: "fixed",
                                bottom: 20,
                                right: 20,
                                zIndex: 10,
                                cursor: "pointer"
                            }}>
                                <a href="https://docs.bounce.finance/" target="_blank"><Wenhao /></a>
                            </div>
                        </div>
                    </AppBase>
                </ThemeProvider>
            </ContextProvider>
        </Web3ReactProvider>
    );
}

export default App;
