import Modal, {ModalContent, ModalIcon, ModalTitle} from "../common/Modal";
import icon_error from "../../static/image/icon-error.svg";
import {Button} from "../common/Button";
import React from "react";

export const PoolErrorModal = ({show, onDismiss}) =>{
    return (
        <Modal closeable isOpen={show} onDismiss={onDismiss}>
            <ModalTitle style={{textAlign: 'center'}}>
                Warning
            </ModalTitle>
            <ModalIcon><img src={icon_error} /></ModalIcon>
            <ModalContent>You have an existing Pool, please come back after you close your existing pool</ModalContent>
            <div style={{ width: 320, display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={onDismiss} width={'154px'} >Go back</Button>
                <Button onClick={onDismiss} black width={'154px'}>Pool status</Button>
            </div>
        </Modal>
    )
}