import React, { useEffect, useState } from 'react'
import { ERC_NFT_PoolGroup } from '../../A_Components/PoolGroup'
import { Empty_PoolPage } from '../../A_Components/PoolGroup'
import { Loading_PoolPage } from '../../A_Components/PoolGroup'

import { useFS_List_ERC_NFT } from '../useHook/FS_ERC_NFT'
import { useSB_List_ERC_NFT } from '../useHook/SB_ERC_NFT'
import { useEA_List_ERC_NFT } from '../useHook/EA_ERC_NFT'
import { useDA_List_ERC_NFT } from '../useHook/DA_ERC_NFT'
import { useLT_List_ERC_NFT } from '../useHook/LT_ERC_NFT'


export function FS_NFT({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useFS_List_ERC_NFT(queryListArr)
    
    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC_NFT_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='FS' />
    )
}

export function SB_NFT({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useSB_List_ERC_NFT(queryListArr)
    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC_NFT_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='SB' />
    )
}


export function EA_NFT({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useEA_List_ERC_NFT(queryListArr)
    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC_NFT_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='EA' />
    )
}


export function DA_NFT({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useDA_List_ERC_NFT(queryListArr)
    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC_NFT_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='DA' />
    )
}

export function LT_NFT({ queryListArr, isLoading, isEmpty }) {
    const { poolsDate, isLoad } = useLT_List_ERC_NFT(queryListArr)
    return (
        isLoading ? <Loading_PoolPage /> :
            isEmpty ? <Empty_PoolPage /> :
                <ERC_NFT_PoolGroup poolCount={queryListArr.length} isLoad={isLoad} poolsDate={poolsDate} poolType='LT' />
    )
}