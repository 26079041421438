import React, { useState, useCallback, useEffect, useContext } from 'react';
import { t } from '../../utils/intl';
import {
  TO, ZERO_ADDRESS,
} from '../const';
import md5 from 'js-md5'
import {
  getTime,
  get721Contract,
  get1155Contract
} from '../../components/utils/web3';
import axios from 'axios'
import { isGreaterThan } from '../../utils/common'
import tip from '../../static/image/tip.svg'
import eye_open from '../../static/image/eye_open.svg'
import eye_close from '../../static/image/eye_close.svg'
import { getContract, useActiveWeb3React } from '../../web3';
import { HANDLE_SHOW_CONNECT_MODAL } from '../../const';
import { myContext } from '../../reducer';
import icon_eth from '../../static/image/icon-eth-logo.svg'
import icon_bnb from '../../static/image/icon-bnb.svg'
import { Pool } from '../../components/common/Layout';
import Web3 from 'web3';
import bounceEnglishAuctionNFT from '../../web3/abi/bounceEnglishAuctionNFT.json'
import bounceERC721 from '../../web3/abi/bounceERC721.json'
import bounceERC1155 from '../../web3/abi/bounceERC1155.json'

import { getEnglishAuctionNFTAddress } from "../../web3/contractAddress";
import {
  approveStatus, cancelStatus, confirmStatus,
  CreateModal,
  errorStatus,
  initStatus,
  pendingStatus,
  successStatus
} from "../../components/common/CreateModal";
import { useHistory } from "react-router-dom";



const BigNumber = require('bignumber.js');

BigNumber.config({ EXPONENTIAL_AT: [-20, 20] })


export const CreateEnglishAuctionForm = ({
  decimals,
  symbol,
  onSymbolChange,
  onDecimalsChange,
  onStatusChange,
  onChangeHash,
  onChangeAmount,
  onReceiptChange,
  onPendingContentChange
}) => {
  const [type, setType] = useState('');
  const history = useHistory()
  const [name, setName] = useState();
  const [ratio, setRatio] = useState();
  const [amount, setAmount] = useState();
  const [to, setTo] = useState();
  const [day, setDay] = useState();
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const [address, setAddress] = useState();
  const [hasError, setHasError] = useState(false);
  const [IDError, setIDError] = useState(false);
  const [onlyBot, setOnlyBot] = useState(true);
  const [password, setPassword] = useState();
  const [startPrice, setStartPrice] = useState();
  const [isPrivate, setIsPrivate] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [tokenID, setTokenID] = useState()
  const [balance, setBalance] = useState('0')
  const [currentFrom, setCurrentFrom] = useState('ERC721');
  const [hasMinimalIncrement, setHasMinimalIncrement] = useState(false);
  const [minimalIncrement, setMinimalIncrement] = useState('0');
  const [src, setSrc] = useState('');
  const [load, setLoad] = useState(false);
  const [modalStatus, setModalStatus] = useState(initStatus)


  const { active, library, account, chainId } = useActiveWeb3React();
  const { state, dispatch } = useContext(myContext);
  let web3;
  if (library) {
    web3 = new Web3(library.provider);
  }

  useEffect(() => {
    if (!active) {
      dispatch({ type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true })
    }
  }, [active])


  const handleNameChange = useCallback(event => {
    setName(event.target.value);
  }, [setName]);

  const handleStartPrice = ((event) => {
    let price = event.target.value.replace(/[^\d.]/g, '');
    setStartPrice(price);
  });


  const handlePassword = ((event) => {
    const value = event.target.value;
    setPassword(value)
  });

  const handleDayChange = useCallback(event => {
    let day = event.target.value.replace(/^0(0+)|[^\d]+/g, '');
    if (parseInt(day) > 1) {
      day = '1'
    }
    setDay(day);
  }, [setDay]);


  const handleAmount = ((event) => {
    let amount = event.target.value.replace(/[^\d.]/g, '');
    amount = isGreaterThan(amount, balance) ? balance : amount;
    setAmount(amount)
  });


  const handleHourChange = useCallback(event => {
    let hour = event.target.value.replace(/^0(0+)|[^\d]+/g, '');
    if (parseInt(hour) > 24) {
      hour = '24'
    }
    setHour(hour);
  }, [setHour]);

  const handleMinuteChange = useCallback(event => {
    let minute = event.target.value.replace(/^0(0+)|[^\d]+/g, '');
    if (parseInt(minute) > 60) {
      minute = '60'
    }
    setMinute(minute);
  }, [setMinute]);

  const handleLaunch = async () => {
    if (!name || !address || !tokenID || hasError || IDError) {
      return;
    }
    const dutch = await getContract(library, bounceEnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId));

    const bouERC = currentFrom == 'ERC721' ? getContract(library, bounceERC721.abi, address) : getContract(library, bounceERC1155.abi, address)

    const startPriceWei = web3.utils.toWei(startPrice);
    const increment = hasMinimalIncrement ? web3.utils.toWei(minimalIncrement) : '0.01';

    const time = getTime(day, hour, minute);
    try {
      if (currentFrom == 'ERC721') {
        setModalStatus(approveStatus);
        const result = await bouERC.methods.approve(
          getEnglishAuctionNFTAddress(chainId),
          new BigNumber(tokenID).toNumber()
        ).send({ from: account });
        if (result.status) {
          setModalStatus(confirmStatus);
          await dutch.methods.createErc721V2(
            name,
            address,
            ZERO_ADDRESS,
            new BigNumber(tokenID).toNumber(),
            startPriceWei,
            time,
            onlyBot,
            !password || password === '' ? 0 : new web3.utils.BN(md5(password)).toString(),
          )
            .send({ from: account })
            .on('transactionHash', hash => {
              setModalStatus(pendingStatus)
            })
            .on('receipt', (_, receipt) => {
              setModalStatus(successStatus)
            })
            .on('error', (err, receipt) => {
              setModalStatus(errorStatus)
            })
        }
      } else {
        setModalStatus(approveStatus);

        console.log('P_console',
          getEnglishAuctionNFTAddress(chainId),
          name,
          address,
          ZERO_ADDRESS,
          tokenID,
          amount,  // amount 参数 u256
          startPriceWei,
          web3.utils.toWei(increment),
          time,
          onlyBot,
          !password || password === '' ? 0 : new web3.utils.BN(md5(password)).toString())
        const result = await bouERC.methods.setApprovalForAll(
          getEnglishAuctionNFTAddress(chainId),
          true
        ).send({ from: account });

        if (result.status) {

          await dutch.methods.createErc1155V2(
            name,
            address,
            ZERO_ADDRESS,
            tokenID,
            amount,  // amount 参数 u256
            startPriceWei,
            web3.utils.toWei(increment),
            time,
            onlyBot,
            !password || password === '' ? 0 : new web3.utils.BN(md5(password)).toString(),
          )
            .send({ from: account })
            .on('transactionHash', hash => {
              setModalStatus(pendingStatus)
            })
            .on('receipt', (_, receipt) => {
              setModalStatus(successStatus)
            })
            .on('error', (err, receipt) => {
              setModalStatus(errorStatus)
            })
        }
      }
    } catch (err) {
      if (err.code === 4001) {
        setModalStatus(cancelStatus)
      } else {
        setModalStatus(errorStatus)
      }
      console.log('err', err);
    }
  };


  const handleAddressChange = async (event) => {
    setHasError(false);
    try {
      const address = event.target.value;
      setAddress(address);
      const contract = currentFrom === 'ERC721' ?
        await get721Contract(web3, address) :
        await get1155Contract(web3, address);
      // console.log('contract', contract)
      if (currentFrom === 'ERC721') {
        const symbolResult = await contract.methods.symbol().call();
        // console.log('symbolResult', symbolResult)
        onSymbolChange(symbolResult);
        setType(symbolResult);
      } else {
        // 1155 暂无判断方法

      }

      if (address.toLowerCase() === '0x26ab18Ca5C193722F16983D363454482Cff0cf1F'.toLowerCase() && !tokenID && currentFrom === 'ERC1155') {
        // Realy 项目方 NFT地址
        setSrc(require('../../pages/A_poolList/assets//realy.png'))
      }

    } catch (e) {
      setHasError({ address: 'Address is invalid' })
      console.log('token address error:', e);
    }
  }

  const loadImage = (async (id) => {
    setSrc('');
    setLoad(true);


    try {
      const contract = currentFrom === 'ERC721' ?
        await get721Contract(web3, address) :
        await get1155Contract(web3, address);
      const info = currentFrom === 'ERC721' ?
        await contract.methods.tokenURI(id).call() :
        await contract.methods.uri(id).call();
      try {
        fetch(info)
          .then(res => res.json())
          .then(data => {

            console.log('D_console', data)
            if (data.result && data.result.data && data.result.data.small_image) {
              setSrc(data.result.data.small_image);
            } else if (data.image) {
              const src = data.image;
              setSrc(src);
            } else {
              const src = data?.properties?.image?.description;
              console.log(src);
              setSrc(src);
            }
            setLoad(false);
          })
      } catch (error) {
        console.log('D_console', error)
      }
    } catch (e) {
      setLoad(false);
      console.log(e)
    }
  })

  const handleIDChange = (async (event) => {
    console.log('id change')
    setIDError(false);
    const id = event.target.value;
    setAddress(address);
    try {

      const contract = currentFrom === 'ERC721' ?
        await get721Contract(web3, address) :
        await get1155Contract(web3, address);


      if (currentFrom === 'ERC721') {
        const ownerAddress = await contract.methods.ownerOf(id).call()
        console.log('owner', ownerAddress)
        loadImage(id);
        if (ownerAddress.toLowerCase() !== account.toLowerCase()) {
          setIDError({ content: 'Token ID is invalid' })
        } else {
          setTokenID(id)
          loadImage(id);
        }
      } else {
        const balance = await contract.methods.balanceOf(account, id).call()

        setBalance(balance)
        loadImage(id);
        if (balance === 0) {
          setIDError({ content: 'Token ID is invalid' })
        } else {
          setTokenID(id)
          loadImage(id);
        }
      }
      console.log('C_console', address)
      if (address.toLowerCase() === '0x26ab18Ca5C193722F16983D363454482Cff0cf1F'.toLowerCase()) {
        console.log('C_console', address)
        const URL = `https://realy.swell.link/metadata/${address}/${id}`
        let cover = require('../../pages/A_poolList/assets/realy.png')
        const res = await axios.get(URL)
        if (res.status === 200) {
          cover = res.data.image
        }
        setSrc(cover)
      }

    } catch (e) {
      setIDError({ content: 'Token ID is invalid' })
      console.log('Token ID is invalid:', e);
      console.log('E_console', e)
    }
  });

  const handleCurrentChange = (currentFrom) => {
    setCurrentFrom(currentFrom);
    setSrc('');
    setTokenID('');
    setAddress('');
  }

  return (
    <>
      <div className='form-header'>
        <div class='sub-title'>{`NFT Auction House`}</div>
        <div className='title'>{'Create a NFT English Auction'}</div>
      </div>
      <form className='form-content'>
        <div className='form-item'>
          <div className='form-item-label'>
            {'Contract information'}
          </div>
          <div className='form-item-content'>
            <dl className='field'>
              <dt>{'Token type: '}</dt>
              <dd>
                <ul className='field-row type'>
                  <li>
                    <label>
                      <input onChange={() => {
                        handleCurrentChange('ERC721')
                        // handleAddressChange()
                      }} type='radio' name='token_form' defaultChecked={true} />
                      {` ERC721`}
                    </label>
                  </li>
                  <li>
                    <label>
                      <input onChange={() => {
                        handleCurrentChange('ERC1155')
                        // handleAddressChange()
                      }
                      } type='radio' name='token_form' />
                      {` ERC1155`}
                    </label>
                  </li>
                </ul>
              </dd>
            </dl>
            <dl className='field'>
              <dt>{t('create-pool.fixed-swap.token-contract-address')}</dt>
              <dd><input type='text' name='address' placeholder={t('create-pool.placeholder.token-address')}
                defaultValue={address}
                onChange={handleAddressChange} /></dd>
              {hasError && <dd className='error'>{hasError.address}</dd>}
            </dl>
            <dl className='field'>
              <dt>{currentFrom == 'ERC721' ? 'ERC-721 Token ID' : 'ERC-1155 Token ID'}</dt>
              <dd><input type='text' name='tokenID' placeholder={'ID'}
                defaultValue={tokenID}
                onChange={handleIDChange} /></dd>
              {IDError && <dd className='error'>{IDError.content}</dd>}
            </dl>
            <dl className='field'>
              <dt></dt>
              <dd className='image'>
                <img src={src} style={{ height: '100%', width: 'auto', margin: '0 auto' }} />
              </dd>
            </dl>
          </div>
        </div>
        <div className='form-item'>
          <div className='form-item-label'>
            {`Pool settings`}
          </div>
          <div className='form-item-content'>
            <dl className='field'>
              <dt></dt>
              <dd>
                <ul className='field-row token'>
                  <li className='label'>{t('create-pool.fixed-swap.from')}
                    <input name='from' value={type} readOnly={true}
                      placeholder={t('create-pool.placeholder.from-token')}
                    />
                  </li>
                  <li className='label to-label'>
                    {t('create-pool.fixed-swap.to')}
                    <img src={chainId === 56 ? icon_bnb : icon_eth} class='to-icon' />
                    <input
                      type='text'
                      defaultValue={chainId === 56 ? 'BNB' : 'ETH'}
                      name='to'
                      readOnly={true}
                      class='to-input'
                    />
                  </li>
                </ul>
              </dd>
            </dl>

            <dl className={currentFrom == 'ERC721' ? 'field hide' : 'field'}>
              <dt style={{ width: '100%' }}>{t('create-pool.fixed-swap.amount')} <span
                style={{ flex: 1, textAlign: 'right' }}>{`${t('balance')}: ${balance} ${type}`}</span></dt>
              <dd className='field' style={{ position: 'relative' }}>
                <div className="amount-input">
                  <input value={amount} type='number' name='amount'
                    className='fix'
                    placeholder={t('create-pool.placeholder.swap', { 'type': type })}
                    onChange={handleAmount} />
                  <span onClick={() => {
                    setAmount(balance)
                    if (ratio) {
                      const to = balance / ratio;
                      setTo(to);
                    }
                  }} className="balance-max">MAX</span>
                </div>
                <span>{type}</span></dd>
            </dl>
            <Pool.RevertDivider />
            <dl className='field'>
              <dt>{'Starting price (Floor price)'}</dt>
              <dd className='field-row type'>
                {/* {`Starting price is`} */}
                <input
                  type='number'
                  placeholder={'Price'}
                  onChange={handleStartPrice}
                  value={startPrice} /> {chainId === 56 ? 'BNB' : 'ETH'}
              </dd>
            </dl>

            <dl className='field'>
              <dt>{t('create-pool.fixed-swap.pool-name')}</dt>
              <dd><input type='text' maxLength={15} name='name' placeholder={t('create-pool.placeholder.name')}
                onChange={handleNameChange} /></dd>
            </dl>

            <dl className='field'>
              <dt className='tip'>
                {'Bid confirmation period'}
                <img className='tip' src={tip} />
                <span className="tip-message">
                  {'This is the time period which the bidder will win the auction if no one else bids for higher price'}
                </span>
              </dt>
              <dd className='field-time'>
                <input type='number' placeholder={t('create-pool.placeholder.days')} name='days'
                  onChange={handleDayChange} value={day} />
                <input type='number' placeholder={t('create-pool.placeholder.hours')} name='hours'
                  onChange={handleHourChange} value={hour} />
                <input type='number' placeholder={t('create-pool.placeholder.minuts')} name='minutes'
                  onChange={handleMinuteChange} value={minute} />
              </dd>
            </dl>

            <dl className='field'>
              <dt style={{ cursor: 'pointer' }}>
                <input onChange={(e) => {
                  setHasMinimalIncrement(!hasMinimalIncrement)
                }} type='checkbox' name='limit' />
                <span style={{ marginLeft: 8 }}>{` Minimal Bid price increment`}</span>
                <div className='tip'>
                  <img className='tip' src={tip} />
                  <span className="tip-message">
                    {`This is the minimum increase a bidder needs to bid than the last highest price bidder (Default: 0.01 ${chainId === 56 ? 'BNB' : 'ETH'})`}
                  </span>
                </div>
              </dt>
              <dd>
                <ul className='field-row type'>
                  <li>
                    <div style={{ display: hasMinimalIncrement ? 'flex' : 'none', alignItems: 'center' }}>
                      <input type='number' placeholder={'Enter'}
                        name='limit'
                        onChange={(e) => {
                          let increment = e.target.value.replace(/[^\d.]/g, '');
                          setMinimalIncrement(increment)
                        }} value={minimalIncrement} /> {chainId === 56 ? 'BNB' : 'ETH'}
                    </div>
                  </li>
                </ul>
              </dd>
            </dl>

            <dl className='field'>
              <dt>{t('create-pool.fixed-swap.participant')}</dt>
              <dd>
                <ul className='field-row type'>
                  <li>
                    <label>
                      <input onChange={() => {
                        setOnlyBot(true)
                        setIsPrivate(false)
                      }} type='radio' name='type' defaultChecked={true} />
                      {` ${t('create-pool.fixed-swap.bot-holders')}`}
                    </label>
                  </li>
                  <li>
                    <label>
                      <input onChange={() => {
                        setOnlyBot(false)
                        setIsPrivate(false)
                      }} type='radio' name='type' />
                      {` ${t('create-pool.fixed-swap.public')}`}
                    </label>
                  </li>
                  <li>
                    <label>
                      <input onChange={() => {
                        setIsPrivate(true)
                        setOnlyBot(false)
                      }} type='radio' name='type' />
                      {' Private'}
                    </label>
                  </li>
                </ul>
              </dd>
            </dl>

            {isPrivate ? (
              <dl className='field'>
                <dt>{'password'}</dt>
                <dd style={{ position: 'relative' }}>
                  <input type={passwordOpen ? 'text' : 'password'}
                    name='bounce-password'
                    placeholder={'Enter the password for this pool'}
                    onChange={handlePassword}
                    className='fix' />
                  <img src={passwordOpen ? eye_open : eye_close} onClick={() => {
                    setPasswordOpen(!passwordOpen)
                  }} className="eye" />
                </dd>
              </dl>
            ) : (null)}

            <div className="tip" style={{ display: 'flex', alignItems: 'center', height: 30 }}>
              <span className='tip-title'>{t('create-pool.create-tip-title')}</span>
              <img className='tip' src={tip}>
              </img>
              <span className="tip-message">{t('create-pool.create-tip-content')}</span>
            </div>

            <span style={{ marginBottom: 126 }} className='dark-button'
              onClick={handleLaunch}>{t('buttons.launch')}</span>
          </div>
        </div>
      </form>

      <CreateModal onOK={() => {
        setModalStatus(initStatus)
        history.goBack()
      }} onDismiss={() => {
        setModalStatus(initStatus)
      }} modalStatus={modalStatus} />
    </>
  )
}
