import React, { useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import BigNumber from "bignumber.js";
import { getRatio } from "../../utils/common";
import { PoolCard } from "../../components/PoolCard";
import { myContext } from "../../reducer";
import { useActivePlatform } from '../../web3';
import { LoadingPage } from "../../components/common/LoadingPage";

import icon_loading from '../../static/image/icon-loading.svg';


const CardFrame = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1280px;
  min-height: 70vh;
  margin: auto;
  /* margin-top: -158px; */
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const SealedBid = ({ pools, isLoading }) => {
    const history = useHistory()
    const { state, dispatch } = useContext(myContext);
    const { Psymbol } = useActivePlatform()
    const { ethPrice } = state
    // if (!pools) {
    //     return <></>
    // }
    return (
        <CardFrame>
            {pools && pools.length !== 0 ?
                pools.map(item => {
                    return (
                        <PoolCard 
                        height='320px'
                        onJoin={() => {
                            history.push(`/sealed-bid/${item.index}`, { id: item.index });
                        }} pool={item} poolMeta={[
                            {
                                title: 'Pair',
                                // content: `${item.fromSymbol} / ${Psymbol}`,
                                token1Adress: `${item.fromAddress}`,
                                token1Symbol: `${item.fromSymbol}`,
                                token2: `${Psymbol}`,
                            }, ,
                            {
                                title: 'Price',
                                content: `${new BigNumber(ethPrice).multipliedBy(getRatio(item.toAmount, '', item.fromAmount))} $`,
                            },
                            {
                                title: 'Floor Swap Ratio',
                                content: `${getRatio(item.fromAmount, '', item.toAmount)} : 1`,
                            }, {
                                title: 'Participants',
                                content: item.onlyBot ? 'BOT Holder' : 'Public'
                            }]} />
                    )
                }) : <div style={{
                    fontFamily: 'IBM Plex Mono',
                    fontSize: 16,
                    marginTop: 400,
                    color: '#000',
                    width: '100%',
                    textAlign: "center"
                }}>You have no live auctions at the moment</div>}
        </CardFrame>
    )


}