import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import { formatAddress } from "../utils/common";
import classNames from "classnames";
import { useActivePlatform, useActiveWeb3React } from "../web3";
import { myContext } from '../reducer'
import BigNumber from "bignumber.js";
import Grow from '@material-ui/core/Grow';
import icon_wenhao from '../static/image/wenhao.svg'
import icon_eth from '../static/image/icon-eth-logo.svg'
import icon_usdt from '../static/image/icon-usdt.png'
import icon_bnb from '../static/image/icon-bnb.svg'

export const CardFrame = styled.div`
  width: 240px;
  height: ${({ height }) => { return height ? height : 'auto' }};
  min-height: ${({ height }) => { return height ? 'auto' : '320px' }};
  /* height: 320px; */
  margin-right: 20px;
  margin-top: 20px;
  &:nth-child(${({ column }) => column ? column : 5}n) {
    margin-right: 0;
  }
  background-color: ${({ theme }) => (theme.white)};
  @media (max-width: 767px) {
    margin: 20px 20px 50px !important;
    width: 100vw;
  }
  position: relative;
`
const Card = styled.div`
  padding: 16px;

  &:hover .join_btn{
    display: block;
  }
`

Card.Header = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: ${({ theme }) => (theme.grey5)};
`

Card.Status = styled.div`
  display: block;
  text-align: left;
  line-height: 28px;
  font-size: 12px;
  i{
    display: inline-block;
    margin-right: 8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${({ theme }) => (theme.grey5)};
  }

  &.Live{
    color: #2DAB50;
    i{
      background-color: #2DAB50;
    }
  }

  &.Filled{
    color: #728AE0;
    i{
      background-color: #728AE0;
    }
  }
`

Card.Title = styled.div`
padding: 20px 0;
font-family: Optima;
font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 27px;
border-bottom: 3px solid #000000;
`

Card.Meta = styled.span`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15.6px;
  color: ${({ theme }) => (theme.grey3)};
  div:nth-child(2) {
    color: ${({ theme }) => (theme.black)};
    &.wrap{
      width: 100px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: right;
    }

    img{
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
  
  
`

Card.MetaFrame = styled.div`
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  margin-top: 40px;
  text-align: center;
  cursor: pointer;
  /* position: relative; */

  &.NFT{
    margin-top: 0px;
  }


  .join_btn{
    display: none;
    position: absolute;
    left: 0;
    bottom: -47px;
    width: 100%;
    text-align: center;
    height: 47px;
    line-height: 47px;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-weight: 700;
    border-top: 1px solid rgba(0,0,0,.2);
    background-color: #fff;
    cursor: pointer;
    transform: translateY(-47px);

    &:hover{
      background-color: #000;
      color: #fff;
    };
    @media (max-width: 767px) {
      display:block;
      bottom:-90px;
    }
  }
`

Card.Cover = styled.img`
  width: 200px;
  height: 200px;
  margin: 16px auto;
`

const Progress = styled.div`
  width: 100%;
  height: 4px;
  margin-top: 20px;
`

Progress.Value = styled.div`
 height: 4px;
`



export const PoolCard = ({ pool, poolMeta, onJoin, cover, column, height }) => {
  const [hover, setHover] = useState(false);
  const { active } = useActiveWeb3React()
  const { symbol } = useActivePlatform()
  const { state, dispatch } = useContext(myContext);
  const [show, setShow] = useState(false)
  const [fromSymbol, setFromSymbol] = useState(null)

  useEffect(() => {
    setShow(true)
  }, [show])


  const checkImgExists = (imgurl) => {
    let cookie_yes_icon = JSON.parse(window.localStorage.getItem('cookie_yes_icon')) || []
    let cookie_no_icon = JSON.parse(window.localStorage.getItem('cookie_no_icon')) || []
    if (cookie_yes_icon.includes(imgurl)) {
      return true;
    } else if (cookie_no_icon.includes(imgurl)) {
      return false
    } else {
      var ImgObj = new Image(); //判断图片是否存在
      ImgObj.src = imgurl;
      //存在图片
      ImgObj.onload = () => {
        cookie_yes_icon.push(imgurl)
        window.localStorage.setItem('cookie_yes_icon', JSON.stringify(cookie_yes_icon))
        return true;
      }

      ImgObj.onerror = () => {
        cookie_no_icon.push(imgurl)
        window.localStorage.setItem('cookie_no_icon', JSON.stringify(cookie_no_icon))
        return false;
      }
    }
  }

  const getIconImg = (token) => {
    let src = ''
    switch (token) {
      case 'eth':
        src = icon_eth
        break;
      case 'bnb':
        src = icon_bnb
        break;
      case 'usdt':
        src = icon_usdt
        break;
      default:
        src = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${token}/logo.png`
        break;
    }
    if (checkImgExists(src)) {
      return <img src={src} />
    } else {
      return <img src={icon_wenhao} />
    }
  }

  const getFromSymbol = (poolMeta) => {
    let symbol = null
    poolMeta.forEach((item) => {
      if (item.title === 'Pair') {
        symbol = item.token1Symbol
        return
      }
    })
    return symbol
  }

  return (
    <Grow in={show} timeout={500}>
      <CardFrame column={column} height={height}>
        <Card>
          <Card.Header>
            <Card.Status className={classNames('status', pool.status)}><i></i><span>{pool.status}</span></Card.Status>
            {`#${pool.index}`}
          </Card.Header>

          <Card.Title>{pool.name}</Card.Title>

          <Card.MetaFrame
            className={cover ? 'NFT' : ''}
          >
            <>
              {cover && <Card.Cover src={cover.replace(/ipfs:\/\//, 'https://ipfs.io/')} />}
              <Card.Meta>
                <div>{'address'}</div>
                <div>{formatAddress(pool.fromAddress)}</div>
              </Card.Meta>
              {poolMeta.map(item => {
                return (
                  <Card.Meta>
                    <div>{item.title}</div>
                    {item.title === 'Pair' ?
                      <div style={{ display: "flex", alignContent: "center" }}>
                        {getIconImg(item.token1Adress)}{item.token1Symbol} /&nbsp;{getIconImg(new String(item.token2).toLowerCase())}{item.token2}
                      </div> :
                      <div className={item.title === 'Participants' ? 'wrap' : ''}>{item.content}</div>}
                  </Card.Meta>
                )
              })}

              <Progress className={classNames('progress', pool.status)}>
                <Progress.Value style={{ width: `${new BigNumber(pool.fromBidAmount).dividedBy(new BigNumber(pool.fromAmount)).multipliedBy(100)}%` }} className={classNames('progress-value', pool.status)} />
              </Progress>
            </>
            <div
              className={classNames('join_btn')}
              onClick={() => {
                // if (pool.nftType) {
                //   onJoin(pool.index, getFromSymbol(poolMeta), pool.fromAddress, pool.nftType)
                // }
                if (pool.nftType) {
                  window.localStorage.setItem('dutchNFTType', pool.nftType)
                }
                onJoin(pool.index, getFromSymbol(poolMeta), pool.fromAddress)
              }}>{pool.status === 'Live' ? 'Join' : 'Show Result'}</div>
          </Card.MetaFrame>
        </Card>
      </CardFrame>
    </Grow>
  )
}
