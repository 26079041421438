import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DetailHeader } from "./DetailHeader";

import { useMyNFTList } from '../../web3/ViewMyNFT'

const DetailFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #121212;
  min-height: 100vh;
  align-items: center;
`
const CardFrame = styled.div`
        display: flex;
        flex-wrap: wrap;
        width: 1280px;
        min-height: 70vh;
        margin: auto;
        margin-top: 20px;
        @media (max-width: 767px) {
            width: 100%;
        }

        ul.list_ul{
            display: flex;
            flex-wrap: wrap;

            li.list_li{
                width: 240px;
                height: 340px;
                box-sizing: border-box;
                background-color: #fff;
                padding: 15px 20px;
                margin-bottom: 32px;
                margin-right: 20px;
                &:nth-child(5n){
                    margin-right: 0;
                }
                @media (max-width: 767px) {
                    width: 100vw;
                }

                img{
                    width: 200px;
                    height: 200px;
                    margin-bottom: 10px;
                    
                    @media (max-width: 767px) {
                        width: 100vw;
                    }
                }

                div.list_div{
                    margin-bottom: 12px;
                    h5{
                        font-family: 'IBM Plex Mono';
                        font-size: 12px;
                        line-height: 15.6px;
                        color: rgba(0,0,0,.3);
                        margin-bottom: 8px;
                    }

                    p{
                        font-family: 'IBM Plex Mono';
                        font-size: 12px;
                        line-height: 15.6px;
                        color: '#1F191B'
                    }
                }
            }
        }
    `


export const Mynft = () => {

    const { nftList } = useMyNFTList('alpacas_1155')

    const omitAdress = (adress) => {
        if (adress.indexOf('0x') !== -1) {
            const start = adress.substr(0, 6)
            const end = adress.substr(-4)
            return `${start}...${end}`
        } else {
            return 'Illegal address'
        }
    }

    const renderMyNFT = () => {
        return <CardFrame>
            <ul className='list_ul'>
                {nftList && nftList.map((item, index) => {
                    return <li className='list_li' key={item.tokenId}>
                        <img src={item.cover} alt="" />
                        <div className='list_div'>
                            <h5>Token Contract Address</h5>
                            <p>{omitAdress(item.adress)}</p>
                        </div>
                        <div className='list_div'>
                            <h5>Token ID</h5>
                            <p>{item.tokenId}</p>
                        </div>
                    </li>
                })}
            </ul>


            {/* <Pagination pagenum={1} setPagenum={() => { }} total={8} /> */}
        </CardFrame>
    }

    return <DetailFrame>
        <DetailHeader />
        {renderMyNFT()}

    </DetailFrame>
}